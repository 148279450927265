//
import { addDays } from 'date-fns';
import uuidv4 from 'src/utils/uuidv4';
import { _mock } from './_mock';

// ----------------------------------------------------------------------

// Studly Utils

export const STUDLY_ROLES = {
  company: ['SSA'],
  submittals: ['CAD', 'PWU', 'FIU', 'ARC', 'ENG', 'SCO', 'COM', 'ASC'],
  rfis: ['CAD', 'PWU', 'FIU', 'ARC', 'ENG', 'COM', 'ASC'],
  planRoom: ['CAD', 'PWU', 'FIU'],
  meetingMinutes: ['CAD', 'PWU'],
  logs: ['CAD', 'PWU', 'FIU'],
  documents: ['CAD', 'PWU', 'FIU'], // TODO: add routing
  // coi: ['CAD', 'PWU', 'FIU', 'ARC', 'ENG', 'SCO', 'COM', 'ASC'],
  projectSetting: ['CAD', 'PWU'],
};

export const STUDLY_ROLES_ACTION = {
  project: { create: ['CAD', 'PWU'] },
  documents: {
    create: ['CAD', 'PWU'],
    edit: ['CAD', 'PWU'],
    delete: ['CAD', 'PWU'],
  },
  logs: {
    create: ['CAD', 'PWU', 'FIU'],
    edit: ['CAD'],
    delete: ['CAD'],
  },
  // ['CAD', 'PWU', 'FIU', 'ARC', 'ENG', 'SCO', 'COM', 'ASC'],
};

export const CSI_CODE_TEMPLATE = {
  id: '1',
  name: 'CSI TEMPLATE',
  children: [
    {
      id: '47d47ec9-ac46-4e82-a92c-c0a447d6133b',
      name: 'Procurement and Contracting Requirements',
      children: [
        {
          id: '3247a8ae-a114-47ef-9189-c58b4b9ae296',
          name: 'Procurement and Contracting Requirements',
          children: [],
          tradeId: '00 00 00',
        },
        {
          id: 'd3bc8d17-eed8-4ed5-a688-4aeb7c52fd2c',
          name: 'Solicitation',
          children: [],
          tradeId: '00 10 00',
        },
        {
          id: 'cf31b9d1-5880-472a-9477-e018c8ce72be',
          name: 'Advertisements and Invitations',
          children: [
            {
              id: '06329330-bc1c-443a-b69d-7d2768a98027',
              name: 'Advertisements and Invitations',
              tradeId: '00 11 00',
            },
            {
              id: '729694d4-4ee9-48f2-861b-9c0fc61570dd',
              name: 'Advertisement for Bids',
              tradeId: '00 11 13',
            },
            {
              id: 'ce1a64f2-0780-48ae-9efe-6e38883eaa83',
              name: 'Invitation to Bid',
              tradeId: '00 11 16',
            },
            {
              id: '49301e46-6525-40a8-89a7-fb8179c0c1ea',
              name: 'Request for Proposal',
              tradeId: '00 11 19',
            },
            {
              id: 'b93eefee-2dd2-4911-a80c-eec2e7a58fa5',
              name: 'Request for Qualifications',
              tradeId: '00 11 53',
            },
          ],
          tradeId: '00 11 00',
        },
        {
          id: 'd18d6159-c413-4298-a8ea-0aef376de272',
          name: 'Instructions for Procurement',
          children: [],
          tradeId: '00 20 00',
        },
        {
          id: '79d245d5-072e-4b70-a3ca-f225d7ae8b45',
          name: 'Instructions',
          children: [
            {
              id: '2e4f91e3-0d86-49d7-86ef-b96756aa793c',
              name: 'Instructions',
              tradeId: '00 21 00',
            },
            {
              id: '59215990-17c1-41ff-b944-544bee441ac3',
              name: 'Instructions to Bidders',
              tradeId: '00 21 13',
            },
            {
              id: 'c4bd3513-5705-4578-86ee-453322e9f3f0',
              name: 'Instructions to Proposers',
              tradeId: '00 21 16',
            },
          ],
          tradeId: '00 21 00',
        },
        {
          id: '89b78da7-cea4-4e07-bffe-577e53a23aa7',
          name: 'Supplementary Instructions',
          children: [
            {
              id: '209365a3-b90f-43b9-9d53-1201c2688eb4',
              name: 'Supplementary Instructions',
              tradeId: '00 22 00',
            },
            {
              id: 'ae26c874-956b-4cda-b274-47eda2085b7b',
              name: 'Supplementary Instructions to Bidders',
              tradeId: '00 22 13',
            },
            {
              id: '41f9ec5e-75fa-4fbb-a479-d59758b5a0a9',
              name: 'Supplementary Instructions to Proposers',
              tradeId: '00 22 16',
            },
          ],
          tradeId: '00 22 00',
        },
        {
          id: '0a2b3b44-0359-455f-963b-a2649bad5c7b',
          name: 'Procurement Definitions',
          children: [],
          tradeId: '00 23 00',
        },
        {
          id: '77a16572-fc54-4182-87a6-7e636a9e089a',
          name: 'Procurement Scopes',
          children: [
            {
              id: 'ee145676-ab10-404f-a6c0-0e3ef514ba91',
              name: 'Procurement Scopes',
              tradeId: '00 24 00',
            },
            {
              id: '60d16172-d69c-456e-8414-2f2d3a8a1e20',
              name: 'Scopes of Bids',
              tradeId: '00 24 13',
            },
            {
              id: '560bd105-60f2-4764-b1e5-ea55c0672da4',
              name: 'Scopes of Bids (Multiple Contracts)',
              tradeId: '00 24 13.13',
            },
            {
              id: '54c9c671-1099-4c0a-81cc-4e78bf1561f7',
              name: 'Scopes of Bids (Multiple-Prime Contract)',
              tradeId: '00 24 13.16',
            },
            {
              id: '61d26928-14ef-4146-a1f3-6a4ff64c2e2e',
              name: 'Scopes of Proposals',
              tradeId: '00 24 16',
            },
            {
              id: '8afa4d2b-638a-48e5-a798-6b4df8005672',
              name: 'Scopes of Proposals (Multiple Contracts)',
              tradeId: '00 24 16.13',
            },
            {
              id: 'bc784afd-71a5-4c37-a247-0bf30cbf7c3c',
              name: 'Scopes of Proposals (Multiple-Prime Contract)',
              tradeId: '00 24 16.16',
            },
          ],
          tradeId: '00 24 00',
        },
        {
          id: '6663d1b8-f817-45d3-97a9-23dd09834300',
          name: 'Procurement Meetings',
          children: [
            {
              id: 'f612da89-040e-4ce2-9ef8-85caffa6dcce',
              name: 'Procurement Meetings',
              tradeId: '00 25 00',
            },
            {
              id: 'eda373b7-2bc7-46a3-af63-7ce766c7c810',
              name: 'Pre-Bid Meetings',
              tradeId: '00 25 13',
            },
            {
              id: '487efd33-c663-46d5-b5fe-e852175c52ad',
              name: 'Pre-Proposal Meetings',
              tradeId: '00 25 16',
            },
          ],
          tradeId: '00 25 00',
        },
        {
          id: '37e58d52-4c6a-4eda-81c0-55ce0bece39c',
          name: 'Procurement Substitution Procedures',
          children: [],
          tradeId: '00 26 00',
        },
        {
          id: '7a9e31bf-22cc-4c50-97bc-0d87bd7081e0',
          name: 'Available Information',
          children: [],
          tradeId: '00 30 00',
        },
        {
          id: '404764af-1a6e-40df-b242-a7b34f183692',
          name: 'Available Project Information',
          children: [
            {
              id: 'a0efba10-0863-4278-8227-5d009283092b',
              name: 'Available Project Information',
              tradeId: '00 31 00',
            },
            {
              id: 'e24f0c6b-29ad-49f2-a7b2-beb90f15bf15',
              name: 'Preliminary Schedules',
              tradeId: '00 31 13',
            },
            {
              id: '870a3a48-7cef-4fdd-9779-a30b7a58b9e2',
              name: 'Preliminary Project Schedule',
              tradeId: '00 31 13.13',
            },
            {
              id: '18a57b6c-c7a4-4c77-baf4-a7eba8758c29',
              name: 'Preliminary Construction Schedule',
              tradeId: '00 31 13.16',
            },
            {
              id: 'a96d59ac-8d9e-4845-8c85-b436386a8326',
              name: 'Preliminary Project Phases',
              tradeId: '00 31 13.23',
            },
            {
              id: '768abaa7-a64a-4589-a6ba-a35dac97ed94',
              name: 'Preliminary Project Sequencing',
              tradeId: '00 31 13.26',
            },
            {
              id: 'e1d72c92-0f5a-4af1-bf4c-f649f1d0f265',
              name: 'Preliminary Project Milestones',
              tradeId: '00 31 13.33',
            },
            {
              id: 'b14d80dd-87ad-4c49-835b-9444677c90b6',
              name: 'Project Budget Information',
              tradeId: '00 31 16',
            },
            {
              id: 'a4b146df-cbc4-4e97-99b5-5a0ae37f7eb7',
              name: 'Existing Condition Information',
              tradeId: '00 31 19',
            },
            {
              id: '3c6c699a-6ac5-4003-a81b-2362ff32bd3f',
              name: 'Movement and Vibration Information',
              tradeId: '00 31 19.13',
            },
            {
              id: '374da956-df57-4fe5-a155-6cfda80e55c9',
              name: 'Acoustic Information',
              tradeId: '00 31 19.16',
            },
            {
              id: 'b499b1a2-f310-418e-be31-97b2b3b44874',
              name: 'Traffic Information',
              tradeId: '00 31 19.19',
            },
            {
              id: '2dff37ff-bf7e-439c-aa7a-53320cad7f93',
              name: 'Existing Structural Information',
              tradeId: '00 31 19.23',
            },
            {
              id: 'fa57c14f-fb3a-4f18-a169-bebec96cf99d',
              name: 'Survey Information',
              tradeId: '00 31 21',
            },
            {
              id: '69bba82d-91d8-43cb-8498-2e2d97cf060c',
              name: 'Site Survey Information',
              tradeId: '00 31 21.13',
            },
            {
              id: 'a051f508-0bd3-4df5-adb8-80ad1d2a5efd',
              name: 'Measured Drawing Information',
              tradeId: '00 31 21.16',
            },
            {
              id: 'a4a91115-334c-4b8a-acea-52556014cc12',
              name: 'Photographic Information',
              tradeId: '00 31 21.19',
            },
            {
              id: 'b29a3bab-7e37-423a-8e8f-cec4921f28d8',
              name: 'Environmental Assessment Information',
              tradeId: '00 31 24',
            },
            {
              id: '7bd28c3d-7d52-4178-ae6a-76eaa353fe3b',
              name: 'Soil Contamination Report',
              tradeId: '00 31 24.13',
            },
            {
              id: '95a8ff11-0754-46a4-927a-7e1229dccac0',
              name: 'Environmental Impact Study Report',
              tradeId: '00 31 24.23',
            },
            {
              id: '4f3186a2-0f7b-4911-acee-fcd865c7852f',
              name: 'Environmental Impact Report Evaluation',
              tradeId: '00 31 24.26',
            },
            {
              id: '752517cb-14b1-441d-bdd9-987714fb84c7',
              name: 'Record of Environmental Impact Decision',
              tradeId: '00 31 24.29',
            },
            {
              id: 'f102f9b1-bad6-4ebc-9292-9a4ff0c85d0c',
              name: 'Environmental Impact Mitigation Report',
              tradeId: '00 31 24.33',
            },
            {
              id: '0ed2a3b1-9a54-4604-9df4-b946b9eaf950',
              name: 'Existing Material Information',
              tradeId: '00 31 25',
            },
            {
              id: '3d859021-4632-4823-b61c-b6a7adbe9753',
              name: 'Existing Concrete Information',
              tradeId: '00 31 25.16',
            },
            {
              id: 'bad23728-9e94-4a0a-9fab-8cff1d3f9256',
              name: 'Existing Masonry Information',
              tradeId: '00 31 25.19',
            },
            {
              id: 'f48b40fc-9c69-4dab-9644-b1ac710bb2bf',
              name: 'Existing Metals Information',
              tradeId: '00 31 25.23',
            },
            {
              id: 'bdd6c324-bc42-416a-9be7-fe0efdb24b14',
              name: 'Existing Wood, Plastics and Composites Information',
              tradeId: '00 31 25.26',
            },
            {
              id: 'a3f3f876-7b7a-4c1c-a517-e8b3a1445d29',
              name: 'Existing Thermal and Moisture Protection Information',
              tradeId: '00 31 25.29',
            },
            {
              id: 'ab9657b5-d18d-48b7-9cf3-9aa9b969b5d3',
              name: 'Existing Hazardous Material Information',
              tradeId: '00 31 26',
            },
            {
              id: '3df03fc6-e091-4d17-a286-8bf3937c3712',
              name: 'Existing Asbestos Information',
              tradeId: '00 31 26.23',
            },
            {
              id: '0d56514f-d072-4723-bec5-d3f04fda14d2',
              name: 'Existing Lead Information',
              tradeId: '00 31 26.26',
            },
            {
              id: '3c529ac2-0547-4587-a777-1c84aee09084',
              name: 'Existing Polychlorinate Biphenyl Information',
              tradeId: '00 31 26.29',
            },
            {
              id: 'f12e9216-be4d-4b19-b667-33be8c5ebaa0',
              name: 'Existing Mold Information',
              tradeId: '00 31 26.33',
            },
            {
              id: '8b849fc5-5846-4420-9c42-7ac62320cc67',
              name: 'Existing Hazardous Waste Drum Information',
              tradeId: '00 31 26.36',
            },
            {
              id: '1b87c69c-8e17-49a6-be54-857e30876db5',
              name: 'Geophysical Data',
              tradeId: '00 31 31',
            },
            {
              id: '1d63a7b0-e5fd-46cd-a946-1b06992b0921',
              name: 'Seismic Investigations Information',
              tradeId: '00 31 31.13',
            },
            {
              id: 'f3b8cdee-7c5b-4371-a2ec-4b147ae237da',
              name: 'Gravity Investigations Information',
              tradeId: '00 31 31.16',
            },
            {
              id: 'fffeb5e3-fd08-49b6-a0d2-adf7753bed9d',
              name: 'Magnetic Investigations Information',
              tradeId: '00 31 31.19',
            },
            {
              id: '894582b1-85cd-4eee-885c-a4fe25a1e5a5',
              name: 'Electromagnetic Investigations Information',
              tradeId: '00 31 31.23',
            },
            {
              id: '7c067024-f65d-4c39-bdd4-c130af669503',
              name: 'Electrical Resistivity Investigations Information',
              tradeId: '00 31 31.26',
            },
            {
              id: 'd42df661-2aaa-4d1c-be79-49107f9bcc2e',
              name: 'Magnetotelluric Investigations Information',
              tradeId: '00 31 31.29',
            },
            {
              id: 'ff1eea1c-7dfb-4727-b3b1-ebaeb0fe79e3',
              name: 'Geotechnical Data',
              tradeId: '00 31 32',
            },
            {
              id: 'eb40d9e5-8b76-4fbd-95ac-e544d233b13c',
              name: 'Subsurface Drilling and Sampling Information',
              tradeId: '00 31 32.13',
            },
            {
              id: '692604d6-7e6c-4fce-a25d-f206b1cd7771',
              name: 'Material Testing Information',
              tradeId: '00 31 32.16',
            },
            {
              id: 'dcea8447-78de-4ce0-97fc-019846ca3fbc',
              name: 'Exploratory Excavation Information',
              tradeId: '00 31 32.19',
            },
            {
              id: '3c46760b-ce28-4af5-b06f-607e7a6ba81e',
              name: 'Geotechnical Monitoring Information',
              tradeId: '00 31 32.23',
            },
            {
              id: 'c6c93a92-a5c3-4497-b4ab-2f51649dbc02',
              name: 'Permit Application',
              tradeId: '00 31 43',
            },
            {
              id: 'b36d74e8-feb9-4e04-b2cf-a52c0397d254',
              name: 'Permits',
              tradeId: '00 31 46',
            },
          ],
          tradeId: '00 31 00',
        },
        {
          id: 'c0cb1439-59c9-4aed-8640-7d251c962cc6',
          name: 'Procurement Forms and Supplements',
          children: [],
          tradeId: '00 40 00',
        },
        {
          id: '2dcde38e-9b57-48e8-9ebe-8ed67f68eea2',
          name: 'Bid Forms',
          children: [
            {
              id: '70c39220-2f8e-48b7-830d-733ccded8a2e',
              name: 'Bid Forms',
              tradeId: '00 41 00',
            },
            {
              id: '6fd43080-349c-42d4-9b5c-c0489a31a2b8',
              name: 'Bid Form – Stipulated Sum (Single-Prime Contract)',
              tradeId: '00 41 13',
            },
            {
              id: 'd95f456a-424c-48e0-b0f8-9ed67afaab81',
              name: 'Bid Form – Stipulated Sum (Multiple-Prime Contract)',
              tradeId: '00 41 16',
            },
            {
              id: '4b1b8d89-8735-4805-ab24-45ca1bf18849',
              name: 'Bid Form – Construction Management (Single-Prime Contract)',
              tradeId: '00 41 23',
            },
            {
              id: 'a2f4c45c-01f8-4871-9642-2def0fddeb06',
              name: 'Bid Form – Construction Management (Multiple-Prime Contract)',
              tradeId: '00 41 26',
            },
            {
              id: 'd013108f-8f8b-492b-b2d2-e82083ab8e4e',
              name: 'Bid Form – Cost-Plus-Fee (Single-Prime Contract)',
              tradeId: '00 41 33',
            },
            {
              id: 'cf661135-7ba6-414b-9d62-05d89dda614d',
              name: 'Bid Form – Cost-Plus-Fee (Multiple-Prime Contract)',
              tradeId: '00 41 36',
            },
            {
              id: 'e5cd995b-2b2e-4a75-b652-508cf41a2933',
              name: 'Bid Form – Unit Price (Single-Prime Contract)',
              tradeId: '00 41 43',
            },
            {
              id: '5d392ff7-3a03-46f8-916e-9248177436ee',
              name: 'Bid Form – Unit Price (Multiple-Prime Contract)',
              tradeId: '00 41 46',
            },
            {
              id: '33f15a11-26a1-44e3-a7cd-430841cb5732',
              name: 'Bid Form – Design/Build (Single-Prime Contract)',
              tradeId: '00 41 53',
            },
            {
              id: 'bcb6943f-48b3-481c-b2a1-25e030a56640',
              name: 'Bid Form – Design/Build (Multiple-Prime Contract)',
              tradeId: '00 41 56',
            },
            {
              id: '334ce758-dde7-4905-ade1-137a31548c6f',
              name: 'Bid Form – Purchase Contract',
              tradeId: '00 41 63',
            },
          ],
          tradeId: '00 41 00',
        },
        {
          id: '443b8a5d-6b7b-4602-83bc-6feb2916ca7a',
          name: 'Proposal Forms',
          children: [
            {
              id: '619312ef-66f3-4618-82b9-1717bad42d14',
              name: 'Proposal Forms',
              tradeId: '00 42 00',
            },
            {
              id: '383ac219-7772-430f-8982-9c97fb60540d',
              name: 'Proposal Form – Stipulated Sum (Single-Prime Contract)',
              tradeId: '00 42 13',
            },
            {
              id: '6a99f1b7-e1c5-4b0d-bde7-2a81d8f71e94',
              name: 'Proposal Form – Stipulated Sum (Multiple-Prime Contract)',
              tradeId: '00 42 16',
            },
            {
              id: 'da810162-0eeb-4dab-971a-0192a18a08e6',
              name: 'Proposal Form – Construction Management (Single-Prime Contract)',
              tradeId: '00 42 23',
            },
            {
              id: '340bc396-21c3-49b8-ada3-04502e199f95',
              name: 'Proposal Form – Construction Management (Multiple-Prime Contract)',
              tradeId: '00 42 26',
            },
            {
              id: '87e1b6db-882c-4699-b041-4c185c51c67f',
              name: 'Proposal Form – Cost-Plus-Fee (Single-Prime Contract)',
              tradeId: '00 42 33',
            },
            {
              id: '3025e687-5178-477c-8c3d-b4086050159e',
              name: 'Proposal Form – Cost-Plus-Fee (Multiple-Prime Contract)',
              tradeId: '00 42 36',
            },
            {
              id: 'f53b8a59-d5df-4376-8847-426306a84359',
              name: 'Proposal Form – Unit Price (Single-Prime Contract)',
              tradeId: '00 42 43',
            },
            {
              id: '5ebd7b6b-3df3-4752-a5af-fee0ecd5e417',
              name: 'Proposal Form – Unit Price (Multiple-Prime Contract)',
              tradeId: '00 42 46',
            },
            {
              id: '0c6776d5-fd64-4f13-b95c-b0bf8b46368d',
              name: 'Proposal Form – Design/Build (Single-Prime Contract)',
              tradeId: '00 42 53',
            },
            {
              id: 'd52f3535-542f-4b5d-8cc1-6eb7f61ff8e4',
              name: 'Proposal Form – Design/Build (Multiple-Prime Contract)',
              tradeId: '00 42 56',
            },
            {
              id: 'd79c794f-67e8-445b-8d41-f79f9c9d2bd2',
              name: 'Proposal Form – Purchase Contract',
              tradeId: '00 42 63',
            },
            {
              id: '102a4ee4-9a29-462d-bdc2-cbb07db25079',
              name: 'Proposal Form – Performance Contract',
              tradeId: '00 42 73',
            },
          ],
          tradeId: '00 42 00',
        },
        {
          id: 'c8555739-6f33-4fa8-a371-cf0740a08e89',
          name: 'Procurement Form Supplements',
          children: [
            {
              id: 'cc620d8d-59e0-4344-b1a8-6447de1d1bc0',
              name: 'Procurement Form Supplements',
              tradeId: '00 43 00',
            },
            {
              id: '7df6cad5-41ee-4921-a7c9-ee994e8a9702',
              name: 'Bid Security Form',
              tradeId: '00 43 13',
            },
            {
              id: '3147282c-22b4-416e-8d58-0356c3904e9e',
              name: 'Allowance Form',
              tradeId: '00 43 21',
            },
            {
              id: '47e71e7d-4c8d-48ff-9eac-9c2bac402720',
              name: 'Unit Prices Form',
              tradeId: '00 43 22',
            },
            {
              id: '216e47ba-3462-45fa-8001-f049d7e10027',
              name: 'Alternates Form',
              tradeId: '00 43 23',
            },
            {
              id: 'a8dc251f-0b83-4e72-b4a3-144c92ed60d7',
              name: 'Substitution Request Form (During Procurement)',
              tradeId: '00 43 25',
            },
            {
              id: '2c065922-9edc-46b5-92d1-6f08bce4ed43',
              name: 'Estimated Quantities Form',
              tradeId: '00 43 26',
            },
            {
              id: 'ce216549-124d-4191-82ea-0a21c4221feb',
              name: 'Separate Prices Break-Out Form',
              tradeId: '00 43 27',
            },
            {
              id: '01052b24-0835-4254-ab95-c5eed382f3d4',
              name: 'Tax Rebate Form',
              tradeId: '00 43 28',
            },
            {
              id: '40fe212c-d8ce-46f0-b938-14239380e8bc',
              name: 'Proposed Products Form',
              tradeId: '00 43 33',
            },
            {
              id: 'f946cf08-1cf6-4ed3-b8bd-7db071f8d563',
              name: 'Proposed Subcontractors Form',
              tradeId: '00 43 36',
            },
            {
              id: 'b481aef2-112e-4c11-9019-cdb1cf04a80c',
              name: 'Minority Business Enterprise Statement of Intent Form',
              tradeId: '00 43 39',
            },
            {
              id: '74ede8ff-0308-44c2-b12d-48c52ec8137d',
              name: 'Wage Rates Form',
              tradeId: '00 43 43',
            },
            {
              id: 'c90e2bef-4602-4a2f-8218-a741b771678f',
              name: 'Proposed Schedule of Values Form',
              tradeId: '00 43 73',
            },
            {
              id: '944f00c4-038c-4afb-a46c-b81a790fa56b',
              name: 'Proposed Construction Schedule Form',
              tradeId: '00 43 83',
            },
            {
              id: '992809d3-0e4a-48f0-b3b3-afd60fd029c8',
              name: 'Proposed Work Plan Schedule Form',
              tradeId: '00 43 86',
            },
            {
              id: 'b948b6f8-6236-4084-83f4-d8f9a6a286cc',
              name: 'Bid Submittal Checklist',
              tradeId: '00 43 93',
            },
          ],
          tradeId: '00 43 00',
        },
        {
          id: '0f1bc1fa-871f-4e26-b1ec-af89236b769d',
          name: 'Representations and Certifications',
          children: [
            {
              id: 'e5746218-5068-4ed3-b432-8d0110884ffd',
              name: 'Representations and Certifications',
              tradeId: '00 45 00',
            },
            {
              id: '9a790ae1-5f3e-4382-bce7-5d79aa26c116',
              name: 'Bidder’s Qualifications',
              tradeId: '00 45 13',
            },
            {
              id: '569287e0-d8e3-4a53-9ce0-8f4911b515b6',
              name: 'Proposer’s Qualifications',
              tradeId: '00 45 16',
            },
            {
              id: '21d22e64-cfae-4742-8d7e-427f6862faa8',
              name: 'Non-Collusion Affidavit',
              tradeId: '00 45 19',
            },
            {
              id: '3fdb83f7-bba0-4ee1-ab1e-6ec3f1067edd',
              name: 'Statement of Disposal Facility',
              tradeId: '00 45 23',
            },
            {
              id: '0c3934fc-8304-489b-b4e8-aa3e382d0e28',
              name: 'Workers Compensation Certificate Schedule',
              tradeId: '00 45 26',
            },
            {
              id: '50c8e11a-1b90-4e05-ba32-22b847ad74fe',
              name: 'Non-Segregated Facilities Affidavit',
              tradeId: '00 45 33',
            },
            {
              id: '8566f8bd-e519-4216-bcdf-9d87b10bc746',
              name: 'Equal Employment Opportunity Affidavit',
              tradeId: '00 45 36',
            },
            {
              id: 'd50ed5c4-0917-40e3-84f9-bda1c913f7a2',
              name: 'Minority Business Enterprise Affidavit',
              tradeId: '00 45 39',
            },
            {
              id: '4c046d09-6a86-4661-aeb3-acc0c090334d',
              name: 'Corporate Resolutions',
              tradeId: '00 45 43',
            },
            {
              id: '9fd3e4b0-917c-4670-9a35-b59dfec5d62b',
              name: 'Governmental Certifications',
              tradeId: '00 45 46',
            },
          ],
          tradeId: '00 45 00',
        },
        {
          id: '79ac27e9-4159-4190-942e-1ef85ed7892a',
          name: 'Contracting Forms and Supplements',
          children: [],
          tradeId: '00 50 00',
        },
        {
          id: 'f8526c6a-f200-4ebd-9f01-b1b4512cf1b5',
          name: 'Notice of Award',
          children: [],
          tradeId: '00 51 00',
        },
        {
          id: '9e42fe55-33f3-47b3-830f-0a98f5458385',
          name: 'Agreement Forms',
          children: [
            {
              id: '4dfed180-75d8-44aa-a724-0963f4fe781b',
              name: 'Agreement Forms',
              tradeId: '00 52 00',
            },
            {
              id: 'a6b0e989-4103-47c3-b6e4-1a398d7ffb4a',
              name: 'Agreement Form – Stipulated Sum (Single-Prime Contract)',
              tradeId: '00 52 13',
            },
            {
              id: '0b22e006-94e3-42b5-b92c-c75030948434',
              name: 'Agreement Form – Stipulated Sum (Multiple-Prime Contract)',
              tradeId: '00 52 16',
            },
            {
              id: '0d389a35-f1b3-451e-a8ed-e27931d97105',
              name: 'Agreement Form – Construction Management (Single-Prime Contract)',
              tradeId: '00 52 23',
            },
            {
              id: '95a58139-19fa-42de-814e-7b3cd581ca8e',
              name: 'Agreement Form – Construction Management (Multiple-Prime Contract)',
              tradeId: '00 52 26',
            },
            {
              id: '73351fc3-fc6f-4fc0-9c97-1db204ab01d3',
              name: 'Agreement Form – Cost Plus-Fee (Single-Prime Contract)',
              tradeId: '00 52 33',
            },
            {
              id: '7d96f5dd-e6a2-4cd5-b715-c28641536b1f',
              name: 'Agreement Form – Cost-Plus-Fee (Multiple-Prime Contract)',
              tradeId: '00 52 36',
            },
            {
              id: 'c74725a8-4226-48c3-b270-febb2d89784b',
              name: 'Agreement Form – Unit Price (Single-Prime Contract)',
              tradeId: '00 52 43',
            },
            {
              id: 'c8c453ad-4781-41d8-9331-77b57e352fbe',
              name: 'Agreement Form – Unit Price (Multiple-Prime Contract)',
              tradeId: '00 52 46',
            },
            {
              id: '3c4f8d18-919b-4248-84cd-9297990d9b2f',
              name: 'Agreement Form – Design/Build (Single-Prime Contract)',
              tradeId: '00 52 53',
            },
            {
              id: '50353fa0-d92d-4e28-95f0-be2464c7a623',
              name: 'Agreement Form – Design/Build (Multiple-Prime Contract)',
              tradeId: '00 52 56',
            },
            {
              id: 'e6525d6e-a151-46f5-b1d8-45f826fd90d3',
              name: 'Agreement Form – Purchase',
              tradeId: '00 52 63',
            },
            {
              id: '910746db-e076-4dc2-9728-ed57908e74d9',
              name: 'Agreement Form – Performance Contract',
              tradeId: '00 52 73',
            },
            {
              id: '55b827f1-977f-4a60-aff9-831ba347f511',
              name: 'Agreement Form – Integrated Project Delivery',
              tradeId: '00 52 95',
            },
          ],
          tradeId: '00 52 00',
        },
        {
          id: '862e3e59-ef8d-4df8-88fb-d60887feb4a4',
          name: 'Agreement Form Supplements',
          children: [
            {
              id: 'fe152e84-b0d7-4e2c-96c6-5d2148803395',
              name: 'Agreement Form Supplements',
              tradeId: '00 54 00',
            },
            {
              id: '310c50e3-8202-4833-adfd-75e4724c94fb',
              name: 'Supplementary Scope Statement',
              tradeId: '00 54 13',
            },
            {
              id: '90448e1d-ede0-4576-814c-1210f241dd38',
              name: 'Allowances Schedule',
              tradeId: '00 54 21',
            },
            {
              id: 'd43f83a2-131e-4efe-8d49-e1055a1ed1aa',
              name: 'Unit Prices Schedule',
              tradeId: '00 54 22',
            },
            {
              id: 'bb843699-48dc-4c26-a384-56f012fbe945',
              name: 'Digital/Electronic Data Protocol Exhibit',
              tradeId: '00 54 33',
            },
            {
              id: 'fce0f723-b188-4a46-80f3-bf959e05eea4',
              name: 'Building Information Modeling Exhibit',
              tradeId: '00 54 36',
            },
          ],
          tradeId: '00 54 00',
        },
        {
          id: '23e7e311-16cb-40a3-9775-a6b631a4c0eb',
          name: 'Notice to Proceed',
          children: [],
          tradeId: '00 55 00',
        },
        {
          id: 'ad4b6914-dbf6-46d6-8515-14d1969dfb83',
          name: 'Project Forms',
          children: [],
          tradeId: '00 60 00',
        },
        {
          id: '9004853e-cc47-4047-985b-9c3337f49688',
          name: 'Bond Forms',
          children: [
            {
              id: '19c76b21-da2a-47be-843c-de59c315e92c',
              name: 'Bond Forms',
              tradeId: '00 61 00',
            },
            {
              id: '92829882-582a-43e1-9e08-2e1013aac7a1',
              name: 'Performance and Payment Bond Form',
              tradeId: '00 61 13',
            },
            {
              id: '9baebf50-c7d8-416f-aec5-45b7a1add4ef',
              name: 'Performance Bond Form',
              tradeId: '00 61 13.13',
            },
            {
              id: 'd7ac31b7-f19a-4abd-9d1e-0da775c78c7e',
              name: 'Payment Bond Form',
              tradeId: '00 61 13.16',
            },
            {
              id: 'fa2528e0-c040-4249-a27b-630b75329106',
              name: 'Lien Bond Form',
              tradeId: '00 61 16',
            },
            {
              id: 'e7593a51-6d0d-41a8-8614-7ea849034d0f',
              name: 'Maintenance Bond Form',
              tradeId: '00 61 19',
            },
            {
              id: '3d724362-4bab-4f76-b7ee-00308207ddac',
              name: 'Retainage Bond Form',
              tradeId: '00 61 23',
            },
            {
              id: '7fcf913e-03f7-4c6b-b733-ce5eb8cb084c',
              name: 'Special Bond Form',
              tradeId: '00 61 26',
            },
          ],
          tradeId: '00 61 00',
        },
        {
          id: 'c886a81a-34d8-4e33-a480-206327f2292b',
          name: 'Certificates and Other Forms',
          children: [
            {
              id: 'd18cd6f8-35e0-4611-8389-a4adc363682b',
              name: 'Certificates and Other Forms',
              tradeId: '00 62 00',
            },
            {
              id: 'dfc82a12-1e6d-47f9-93fc-222855480289',
              name: 'Submittal Transmittal Form',
              tradeId: '00 62 11',
            },
            {
              id: '0f4610ef-e3c8-4965-96d3-51aece68bd9f',
              name: 'Certificate of Insurance Form',
              tradeId: '00 62 16',
            },
            {
              id: '62ba1eee-c7c3-4f6f-8d41-59ef23bc76f8',
              name: 'Infection Control Form',
              tradeId: '00 62 19',
            },
            {
              id: '0f734f7b-1118-4353-a4fd-7da104486b7d',
              name: 'Construction Waste Diversion Form',
              tradeId: '00 62 23',
            },
            {
              id: 'eaeb25e5-2bc2-4831-b58e-93e5f5b63f35',
              name: 'Products Form',
              tradeId: '00 62 33',
            },
            {
              id: 'b23c01c1-5f36-49b0-977b-757d50ea5073',
              name: 'Recycled Content of Materials Form',
              tradeId: '00 62 34',
            },
            {
              id: '66f2e9e7-5bb3-4793-9bb6-9cbdc30ee2c2',
              name: 'Minority Business Enterprise Certification Form',
              tradeId: '00 62 39',
            },
            {
              id: '82479b1e-0509-4a8e-8e1e-52a0177bcab8',
              name: 'Schedule of Values Form',
              tradeId: '00 62 73',
            },
            {
              id: 'd006e32b-b33f-4444-a1da-86485f05c71b',
              name: 'Application for Payment Form',
              tradeId: '00 62 76',
            },
            {
              id: 'c463917c-6609-45b7-90a6-a3144ad57dcd',
              name: 'Sales Tax Form',
              tradeId: '00 62 76.13',
            },
            {
              id: '0e10e433-4a6c-41bb-8cdb-5e2d6fcc0f6a',
              name: 'Consent of Surety to Reduction of Retainage Form',
              tradeId: '00 62 76.16',
            },
            {
              id: '1328730a-a509-4b3e-a25f-6441f3700887',
              name: 'Stored Material Form',
              tradeId: '00 62 79',
            },
            {
              id: '45e0a24e-5567-42fd-9ed3-49692cb70c1b',
              name: 'Construction Schedule Form',
              tradeId: '00 62 83',
            },
            {
              id: 'e0696c08-c790-4942-9290-f482d9ca04b4',
              name: 'Work Plan Schedule Form',
              tradeId: '00 62 86',
            },
            {
              id: '7f494c62-362c-4b3a-bb0c-311645ff2c0d',
              name: 'Construction Equipment Form',
              tradeId: '00 62 89',
            },
          ],
          tradeId: '00 62 00',
        },
        {
          id: '3c8afd41-f8c5-439f-8dac-ab0c98f8ce63',
          name: 'Clarification and Modification Forms',
          children: [
            {
              id: 'aea0073b-a5e8-4757-8ac8-f5d22f983ef5',
              name: 'Clarification and Modification Forms',
              tradeId: '00 63 00',
            },
            {
              id: 'eafb76b3-b4da-4696-a6a3-341e6803bc55',
              name: 'Request for Interpretation Form',
              tradeId: '00 63 13',
            },
            {
              id: '3299dd62-de23-4883-b027-6095c76e6225',
              name: 'Clarification Form',
              tradeId: '00 63 19',
            },
            {
              id: 'f51fdead-cdab-4945-b585-aa913293cd5c',
              name: 'Substitution Request Form (During Construction)',
              tradeId: '00 63 25',
            },
            {
              id: '98db2e6d-6096-48c8-a1f2-7fd1e695456d',
              name: 'Supplemental Instruction Form',
              tradeId: '00 63 33',
            },
            {
              id: 'b6574b66-bc1c-45b7-b868-fa6c6ce131f4',
              name: 'Field Order Form',
              tradeId: '00 63 36',
            },
            {
              id: '1523e249-f7ca-42dd-9bd2-5f884897c932',
              name: 'Written Amendment Form',
              tradeId: '00 63 43',
            },
            {
              id: '737fc8c1-5204-4a20-9682-a40709e4e199',
              name: 'Construction Change Directive Form',
              tradeId: '00 63 46',
            },
            {
              id: '2613d264-612d-400f-bebc-3c7a21956a95',
              name: 'Work Change Directive Form',
              tradeId: '00 63 49',
            },
            {
              id: 'd960454a-3d5c-491c-97d1-b3094f65149c',
              name: 'Request for Proposal Form',
              tradeId: '00 63 53',
            },
            {
              id: '5ad6b7f5-eee8-4298-81e6-ecd9608c1cc2',
              name: 'Proposal Worksheet Summary Form',
              tradeId: '00 63 54',
            },
            {
              id: '40e1a9b3-faa0-4885-82a0-502691e4ac3a',
              name: 'Proposal Worksheet Detail Form',
              tradeId: '00 63 55',
            },
            {
              id: '8e7792e9-1b78-4e3f-b71a-c5443e7b8369',
              name: 'Change Order Request Form',
              tradeId: '00 63 57',
            },
            {
              id: '184d8932-fe76-4d56-97e7-9d4e48f86fd7',
              name: 'Change Order Form',
              tradeId: '00 63 63',
            },
          ],
          tradeId: '00 63 00',
        },
        {
          id: 'ad89e087-dcd7-4632-bbdf-22c894522cb7',
          name: 'Closeout Forms',
          children: [
            {
              id: '78d7fe48-2b65-4374-b0a3-a0d264eba998',
              name: 'Closeout Forms',
              tradeId: '00 65 00',
            },
            {
              id: 'f505c08d-ab7f-4e03-b7e6-3a3e81a33e3f',
              name: 'Certificate of Compliance Form',
              tradeId: '00 65 13',
            },
            {
              id: 'f6a790b0-28a4-40d8-b602-2572b68b4c3e',
              name: 'Certificate of Substantial Completion Form',
              tradeId: '00 65 16',
            },
            {
              id: 'bb9c3875-943c-4951-950d-f155e384a11f',
              name: 'Certificate of Completion Form',
              tradeId: '00 65 19',
            },
            {
              id: '6a2d9273-6b44-4463-969c-d36cbaf1ee6b',
              name: 'Affidavit of Payment of Debts and Claims Form',
              tradeId: '00 65 19.13',
            },
            {
              id: 'd909965d-de88-4b88-a0aa-69a9cf0ad728',
              name: 'Affidavit of Release of Liens Form',
              tradeId: '00 65 19.16',
            },
            {
              id: '043d509e-7c8b-4baa-b398-aa832549c2df',
              name: 'Consent of Surety to Final Payment Form',
              tradeId: '00 65 19.19',
            },
            {
              id: '7f49bf7c-d019-44c8-b968-08ba942d5e8a',
              name: 'Acceptance Certificate Form',
              tradeId: '00 65 19.23',
            },
            {
              id: '2be7df06-d1df-4d98-9510-0348488aa4c9',
              name: 'Final Settlement Certificate Form',
              tradeId: '00 65 19.26',
            },
            {
              id: 'd3444bbc-e269-457e-8f68-4d05028740ce',
              name: 'Warranty Form',
              tradeId: '00 65 36',
            },
            {
              id: 'dedb4050-0c4a-4d61-9276-f79e8c8e02da',
              name: 'Statutory Declaration Form',
              tradeId: '00 65 73',
            },
          ],
          tradeId: '00 65 00',
        },
        {
          id: '6a792e96-cd4f-4f76-bb0e-7bd576e00b1c',
          name: 'Conditions of the Contract',
          children: [],
          tradeId: '00 70 00',
        },
        {
          id: '437ff548-2aa3-4189-82a9-c1e9e7622f6b',
          name: 'Contracting Definitions',
          children: [],
          tradeId: '00 71 00',
        },
        {
          id: '37e90e7f-27dd-4fea-8289-19eedc10e252',
          name: 'General Conditions',
          children: [
            {
              id: 'fdfea07f-75e0-49dd-899c-9ac57f766e91',
              name: 'General Conditions',
              tradeId: '00 72 00',
            },
            {
              id: '78d7088d-c2bb-49b0-9cef-44dfbd54c71a',
              name: 'General Conditions – Stipulated Sum (Single-Prime Contract)',
              tradeId: '00 72 13',
            },
            {
              id: '2d335b84-d6df-4350-a009-f630114903a7',
              name: 'General Conditions – Stipulated Sum (Multiple-Prime Contract)',
              tradeId: '00 72 16',
            },
            {
              id: '7345d7be-e35c-476f-83e0-287806cb4b14',
              name: 'General Conditions – Construction Management (Single-Prime Contract)',
              tradeId: '00 72 23',
            },
            {
              id: '57bd7532-3704-4c3e-ae6b-76804ccac045',
              name: 'General Conditions – Construction Management (Multiple-Prime Contract)',
              tradeId: '00 72 26',
            },
            {
              id: 'ff193d77-131a-4447-81fc-14ce18c529f7',
              name: 'General Conditions – Cost Plus-Fee (Single-Prime Contract)',
              tradeId: '00 72 33',
            },
            {
              id: '23f163ca-c427-4f69-8779-1708a67e1960',
              name: 'General Conditions – Cost-Plus-Fee (Multiple-Prime Contract)',
              tradeId: '00 72 36',
            },
            {
              id: 'eb963efa-6f24-4593-a1e2-fa670339bfab',
              name: 'General Conditions – Unit Price (Single-Prime Contract)',
              tradeId: '00 72 43',
            },
            {
              id: 'f8fde538-9b02-4b4b-8d28-0fe5b3fc2efb',
              name: 'General Conditions – Unit Price (Multiple-Prime Contract)',
              tradeId: '00 72 46',
            },
            {
              id: '020acdd4-c5cb-41e5-9008-1fad2aace797',
              name: 'General Conditions – Design/Build (Single-Prime Contract)',
              tradeId: '00 72 53',
            },
            {
              id: 'fdf3ea3d-e6aa-43ab-9dad-a118e3a02b24',
              name: 'General Conditions – Design/Build (Multiple-Prime Contract)',
              tradeId: '00 72 56',
            },
            {
              id: '65eff235-7eda-4475-a0d2-a576a45e6f6d',
              name: 'General Conditions – Performance Contract',
              tradeId: '00 72 73',
            },
            {
              id: 'eb3cc726-3709-4746-9838-c28d40dde78b',
              name: 'General Conditions – Integrated Project Delivery',
              tradeId: '00 72 95',
            },
          ],
          tradeId: '00 72 00',
        },
        {
          id: '2aa83497-c85a-4891-a6c7-92e2757d3715',
          name: 'Supplementary Conditions',
          children: [
            {
              id: '6978bc82-9f07-4cb1-8555-f48cb4258d6d',
              name: 'Supplementary Conditions',
              tradeId: '00 73 00',
            },
            {
              id: '82c3cff8-3718-4886-9e9a-b849b045e07e',
              name: 'Insurance Requirements',
              tradeId: '00 73 16',
            },
            {
              id: 'ca3a5644-01fa-473f-be07-2e60b63a78aa',
              name: 'Health and Safety Requirements',
              tradeId: '00 73 19',
            },
            {
              id: '610e1b3c-0b6d-4acf-8a4b-9772a6f0d51e',
              name: 'Purchase Contracts',
              tradeId: '00 73 23',
            },
            {
              id: 'e4986117-9e23-4022-9701-b10fa4bf4d9a',
              name: 'Assigned Contracts',
              tradeId: '00 73 26',
            },
            {
              id: 'b033d9d1-ddf5-41b0-9f2f-1c366ea07a15',
              name: 'Non-Segregated Facilities Requirements',
              tradeId: '00 73 33',
            },
            {
              id: '961131cd-7326-4cb0-ab5f-4d0a2e7e3af9',
              name: 'Equal Employment Opportunity Requirements',
              tradeId: '00 73 36',
            },
            {
              id: '4fa4fa8f-9b7e-472f-8bb2-54cdb033f4c9',
              name: 'Minority Business Enterprise Requirements',
              tradeId: '00 73 39',
            },
            {
              id: '7aae2eb5-d86b-4468-a153-ccbff62bfd3b',
              name: 'Wage Rate Requirements',
              tradeId: '00 73 43',
            },
            {
              id: '8fd69eed-02ba-47f0-81c5-c59b8ffe58ec',
              name: 'Wage Determination Schedule',
              tradeId: '00 73 46',
            },
            {
              id: '2f670418-a7d6-4ea4-9081-b7c83e303aa7',
              name: 'Labor Stabilization Agreement',
              tradeId: '00 73 49',
            },
            {
              id: 'a00cd95c-4808-4817-87d9-8b8d3d689bad',
              name: 'Anti-Pollution Measures',
              tradeId: '00 73 53',
            },
            {
              id: 'ef97eb4d-4905-4d1f-b3f4-0b78627d3b3c',
              name: 'Security Requirements',
              tradeId: '00 73 63',
            },
            {
              id: 'f78e727a-1756-4456-b598-29d17997f3ab',
              name: 'Statutory Requirements',
              tradeId: '00 73 73',
            },
            {
              id: '18714ec9-e693-41bf-9c0d-7ec4dd8c3004',
              name: 'Dispute Resolution',
              tradeId: '00 73 83',
            },
          ],
          tradeId: '00 73 00',
        },
        {
          id: 'e8c9f377-a35b-4f0f-b6b6-6a769ae859e2',
          name: 'Revisions, Clarifications and Modifications',
          children: [],
          tradeId: '00 90 00',
        },
        {
          id: '649d3963-ef6d-47c6-a52e-919177f88c1e',
          name: 'Precontract Revisions',
          children: [
            {
              id: '6cdb901f-bf08-47ac-afda-9e0cb86ffce6',
              name: 'Precontract Revisions',
              tradeId: '00 91 00',
            },
            {
              id: 'f0ea8390-d7d2-4a92-90df-b8221e5aaf37',
              name: 'Addenda',
              tradeId: '00 91 13',
            },
            {
              id: '48d5f69d-3693-4ec1-b690-a1bf3c7cb9a2',
              name: 'Bid Revisions',
              tradeId: '00 91 16',
            },
            {
              id: '5b85ed28-fa3a-4494-8bb4-7207c812cfb4',
              name: 'Proposal Revisions',
              tradeId: '00 91 19',
            },
          ],
          tradeId: '00 91 00',
        },
        {
          id: '092cdfe1-7d6e-480c-9288-5578df87dee4',
          name: 'Record Clarifications and Proposals',
          children: [
            {
              id: 'f908c090-52e4-4303-a82e-bbf2c9b300a9',
              name: 'Record Clarifications and Proposals',
              tradeId: '00 93 00',
            },
            {
              id: 'a6b2fa42-d776-47da-9128-88e9fad1425c',
              name: 'Record Requests for Interpretation',
              tradeId: '00 93 13',
            },
            {
              id: 'cd14e177-ab9e-427e-a99e-619034c9d11c',
              name: 'Record Clarification Notices',
              tradeId: '00 93 19',
            },
            {
              id: 'ac646187-9c37-43b6-95b7-bc73b10cc99d',
              name: 'Record Proposal Requests',
              tradeId: '00 93 53',
            },
            {
              id: '2b2a1e08-eca7-482d-bf53-91e56621eb4c',
              name: 'Record Proposal Worksheet Summaries',
              tradeId: '00 93 54',
            },
            {
              id: '03303b07-b09b-4419-8b32-6b31bc9bdb90',
              name: 'Record Change Order Requests',
              tradeId: '00 93 57',
            },
          ],
          tradeId: '00 93 00',
        },
        {
          id: 'dc196503-6291-4518-8171-1ac7eecec9c3',
          name: 'Record Modifications',
          children: [
            {
              id: '8e658524-34ce-48de-9642-387e7a71ddcc',
              name: 'Record Modifications',
              tradeId: '00 94 00',
            },
            {
              id: 'f71576d3-be42-49fa-9a2e-5520f375bc94',
              name: 'Record Minor Changes in the Work',
              tradeId: '00 94 33',
            },
            {
              id: '9bd8ebd6-e9fa-432d-ba47-60ce83fa8ecb',
              name: 'Record Supplemental Instructions',
              tradeId: '00 94 36',
            },
            {
              id: '0a52a375-e5f0-400b-b832-62e1987df481',
              name: 'Record Field Orders',
              tradeId: '00 94 39',
            },
            {
              id: 'bda121a1-d6c1-4319-80d5-2dd723a83c68',
              name: 'Record Amendments',
              tradeId: '00 94 43',
            },
            {
              id: '63dc596c-ea70-46db-b48d-405be3dca109',
              name: 'Record Construction Change Directives',
              tradeId: '00 94 46',
            },
            {
              id: '85918636-7854-4923-b05f-90050a777715',
              name: 'Record Work Change Directives',
              tradeId: '00 94 49',
            },
            {
              id: 'a52677d0-443b-4ce4-a12b-d6ca28142a30',
              name: 'Record Change Orders',
              tradeId: '00 94 63',
            },
          ],
          tradeId: '00 94 00',
        },
      ],
      tradeId: '00 00 00',
    },
    {
      id: 'a94cf3e0-69b4-4fdb-a1bd-800b1119ee45',
      name: 'General Requirements',
      children: [
        {
          id: 'ca190c81-fc55-4ff7-a0a8-40076d64a2dc',
          name: 'General Requirements',
          children: [],
          tradeId: '01 00 00',
        },
        {
          id: '45cf4d07-0be0-4b86-9f0f-67811b266b55',
          name: 'Summary',
          children: [],
          tradeId: '01 10 00',
        },
        {
          id: 'b8c5afbb-7b9c-4c65-9ffe-f4add29d20e8',
          name: 'Summary of Work',
          children: [
            {
              id: 'b28983e8-f429-4bc0-836c-f97a61453413',
              name: 'Summary of Work',
              tradeId: '01 11 00',
            },
            {
              id: 'f62de5cf-640f-4564-baeb-0d2f19579b08',
              name: 'Work Covered by Contract Documents',
              tradeId: '01 11 13',
            },
            {
              id: '55701884-b592-4dd9-beb2-c55ac0f15c7c',
              name: 'Work by Owner',
              tradeId: '01 11 16',
            },
            {
              id: '1069e50b-dbb8-4364-b42a-d538385e711b',
              name: 'Purchase Contracts',
              tradeId: '01 11 19',
            },
          ],
          tradeId: '01 11 00',
        },
        {
          id: 'f8e06b90-ebac-4b99-b930-24b2a8f18707',
          name: 'Multiple Contract Summary',
          children: [
            {
              id: '80380d35-a7a4-49b3-a7b9-7a22dc4a36ca',
              name: 'Multiple Contract Summary',
              tradeId: '01 12 00',
            },
            {
              id: '08943777-c55a-493d-be05-46ebb7811951',
              name: 'Summary of Contracts',
              tradeId: '01 12 13',
            },
            {
              id: '93f5742f-85c5-42fc-99c2-3bb7868fe419',
              name: 'Work Sequence',
              tradeId: '01 12 16',
            },
            {
              id: '6cd9151f-d8b6-4a8d-96ad-b01793fbf644',
              name: 'Contract Interface',
              tradeId: '01 12 19',
            },
          ],
          tradeId: '01 12 00',
        },
        {
          id: 'ad8d1a19-3cce-41cd-8fe5-38662e6b94c5',
          name: 'Work Restrictions',
          children: [
            {
              id: '6a81680d-9b72-4259-80e5-c0c85f023977',
              name: 'Work Restrictions',
              tradeId: '01 14 00',
            },
            {
              id: '11f5b4bc-05ea-4710-8abc-1314863537d1',
              name: 'Access to Site',
              tradeId: '01 14 13',
            },
            {
              id: '91b8d25b-9675-4261-9e4a-84c50f65adbc',
              name: 'Coordination with Occupants',
              tradeId: '01 14 16',
            },
            {
              id: '70c3f08d-cce2-405c-b0ef-56de44138e74',
              name: 'Use of Site',
              tradeId: '01 14 19',
            },
          ],
          tradeId: '01 14 00',
        },
        {
          id: 'ce9bb2b6-ae47-4790-b25a-c54e9363cad4',
          name: 'Project Utility Sources',
          children: [
            {
              id: '09cad980-3bd5-4d9a-a54b-a69360a2669a',
              name: 'Project Utility Sources',
              tradeId: '01 18 00',
            },
            {
              id: 'd28281e0-f779-4bef-a105-e9390dd08c3a',
              name: 'Utility Service Connections',
              tradeId: '01 18 13',
            },
          ],
          tradeId: '01 18 00',
        },
        {
          id: '029cf565-3974-4306-8118-3a92e0b045fe',
          name: 'Price and Payment Procedures',
          children: [],
          tradeId: '01 20 00',
        },
        {
          id: '4ac66268-1fe5-412e-8764-d1339429ebd4',
          name: 'Allowances',
          children: [
            {
              id: 'b6bfd155-6bdc-4c65-ac47-904a23b62a08',
              name: 'Allowances',
              tradeId: '01 21 00',
            },
            {
              id: '20392f78-c397-4caa-8990-a106ebdca00d',
              name: 'Cash Allowances',
              tradeId: '01 21 13',
            },
            {
              id: '6181a887-cc47-4322-b804-557a66f40714',
              name: 'Contingency Allowances',
              tradeId: '01 21 16',
            },
            {
              id: '1556ddce-f895-4ae4-a582-f3a4ccaf468c',
              name: 'Testing and Inspecting Allowances',
              tradeId: '01 21 19',
            },
            {
              id: '05b1928a-0ed9-4f58-af04-c31e6d286d23',
              name: 'Installation Allowances',
              tradeId: '01 21 23',
            },
            {
              id: '32f316d1-6bd1-494f-ba66-a190054cbed3',
              name: 'Product Allowances',
              tradeId: '01 21 26',
            },
            {
              id: 'a9987ae5-17c0-453f-9470-be4d01da1888',
              name: 'Quantity Allowances',
              tradeId: '01 21 29',
            },
            {
              id: '358e5f34-4ed9-4acb-ae29-627e01ad95d1',
              name: 'Time Allowances',
              tradeId: '01 21 43',
            },
          ],
          tradeId: '01 21 00',
        },
        {
          id: 'd6b8b2b1-0b53-497d-84b9-13f5aad22ee2',
          name: 'Unit Prices',
          children: [
            {
              id: '7b937367-2f8f-4c07-b134-844bfc34db90',
              name: 'Unit Prices',
              tradeId: '01 22 00',
            },
            {
              id: '6dde2719-28ac-46b9-bc74-07964e044e81',
              name: 'Unit Price Measurement',
              tradeId: '01 22 13',
            },
            {
              id: 'a2ebd568-5bbc-40af-9038-be54e403eb7d',
              name: 'Unit Price Payment',
              tradeId: '01 22 16',
            },
          ],
          tradeId: '01 22 00',
        },
        {
          id: '5bcc0a0c-cf05-4f50-b4e4-ce7c17ab130d',
          name: 'Alternates',
          children: [],
          tradeId: '01 23 00',
        },
        {
          id: 'f50d398c-f576-496d-8f54-9d2f89deceb0',
          name: 'Value Analysis',
          children: [
            {
              id: 'dac1c2f3-a0d8-449f-b17c-445ebd5cbca0',
              name: 'Value Analysis',
              tradeId: '01 24 00',
            },
            {
              id: '4e5847ef-c982-43de-a3f1-702e0df86b6a',
              name: 'Value Engineering',
              tradeId: '01 24 13',
            },
          ],
          tradeId: '01 24 00',
        },
        {
          id: 'e564cc50-00b6-43c0-bfea-c8541bb73aef',
          name: 'Substitution Procedures',
          children: [
            {
              id: '83ef2c9f-f4f5-44f4-ad68-0b6ab5a7ec6b',
              name: 'Substitution Procedures',
              tradeId: '01 25 00',
            },
            {
              id: 'db98335d-7696-44f9-90fe-31e2401a7f04',
              name: 'Product Substitution Procedures',
              tradeId: '01 25 13',
            },
            {
              id: 'd10c8ae1-cae6-47e1-bae6-c41fcd284fe5',
              name: 'Execution Substitution Procedures',
              tradeId: '01 25 16',
            },
          ],
          tradeId: '01 25 00',
        },
        {
          id: '5b4095e8-54ab-4f4c-acab-4ee3b354bd7a',
          name: 'Contract Modification Procedures',
          children: [
            {
              id: '5a10c87a-8fb2-4f27-9ac5-73456a2aedaa',
              name: 'Contract Modification Procedures',
              tradeId: '01 26 00',
            },
            {
              id: '22095aec-1bd1-47cb-8abe-e2e1b1fd7e3b',
              name: 'Requests for Interpretation',
              tradeId: '01 26 13',
            },
            {
              id: 'c6e5a6b5-b324-4517-a40b-7928921fd42d',
              name: 'Clarification Notices',
              tradeId: '01 26 19',
            },
            {
              id: 'e5dc234c-60d8-43a3-bd67-e74fd0328483',
              name: 'Minor Changes in the Work',
              tradeId: '01 26 33',
            },
            {
              id: '0440cf04-c40f-472f-ab3d-2a745cc42af7',
              name: 'Supplemental Instructions',
              tradeId: '01 26 36',
            },
            {
              id: 'a0ecd252-4b12-41d4-a73e-4743ea5266ae',
              name: 'Field Orders',
              tradeId: '01 26 39',
            },
            {
              id: '18f89203-3903-4eef-a5ef-aada6a3aea3e',
              name: 'Amendments',
              tradeId: '01 26 43',
            },
            {
              id: '68b969e4-259a-47a4-94cd-3011e8411659',
              name: 'Construction Change Directives',
              tradeId: '01 26 46',
            },
            {
              id: 'f32b118f-f501-499b-9e8e-193e17d641d4',
              name: 'Work Change Directives',
              tradeId: '01 26 49',
            },
            {
              id: 'dc9bc7bd-df32-4c2a-9220-3ab4ab0fe27c',
              name: 'Proposal Requests',
              tradeId: '01 26 53',
            },
            {
              id: '18d81703-0589-4d4f-b3d6-059ec5c674ff',
              name: 'Proposal Worksheet Summaries',
              tradeId: '01 26 54',
            },
            {
              id: 'd39e2f2f-9ff3-4225-be7b-64f9fca08207',
              name: 'Change Order Requests',
              tradeId: '01 26 57',
            },
            {
              id: 'd3e0eab6-5c29-4c8c-9e82-b3f901df29f5',
              name: 'Change Orders',
              tradeId: '01 26 63',
            },
          ],
          tradeId: '01 26 00',
        },
        {
          id: '2d639c29-aa59-4325-9154-acb1c6ea6ffe',
          name: 'Payment Procedures',
          children: [
            {
              id: 'df58d594-10c6-4b5f-9377-8e9ec27462ba',
              name: 'Payment Procedures',
              tradeId: '01 29 00',
            },
            {
              id: 'eeece778-d3e6-4362-94ab-9f7b3ba27477',
              name: 'Schedule of Values',
              tradeId: '01 29 73',
            },
            {
              id: 'c9a7a3df-d835-4752-9338-d3984eab57ea',
              name: 'Progress Payment Procedures',
              tradeId: '01 29 76',
            },
            {
              id: 'ceadae9c-ef45-4a98-b6b6-3fe21b8e0a3f',
              name: 'Payment Procedures for Testing Laboratory Services',
              tradeId: '01 29 83',
            },
          ],
          tradeId: '01 29 00',
        },
        {
          id: 'c8303b3c-6df2-4511-a626-54379098cf28',
          name: 'Administrative Requirements',
          children: [],
          tradeId: '01 30 00',
        },
        {
          id: 'cfa89a0c-6b02-4bc5-8400-7593c3841bc7',
          name: 'Project Management and Coordination',
          children: [
            {
              id: 'b7e328aa-fa04-4dd4-93a2-4f3bd993ff82',
              name: 'Project Management and Coordination',
              tradeId: '01 31 00',
            },
            {
              id: 'ec3a0803-0c7a-495f-9904-fa0642b96096',
              name: 'Project Coordination',
              tradeId: '01 31 13',
            },
            {
              id: '322a2230-e27d-41a4-8d45-a53299aa2056',
              name: 'Facility Services Coordination',
              tradeId: '01 31 14',
            },
            {
              id: '426147a9-f5f6-4a08-b72f-dd8840dc6706',
              name: 'Multiple Contract Coordination',
              tradeId: '01 31 16',
            },
            {
              id: 'ce1086fd-6c7c-48ce-9a1e-029160e8f13d',
              name: 'Project Meetings',
              tradeId: '01 31 19',
            },
            {
              id: '7231b842-ad97-4a9f-a54e-426430b784d6',
              name: 'Preconstruction Meetings',
              tradeId: '01 31 19.13',
            },
            {
              id: 'b75a1ef9-c54c-468e-bd96-acd91b60c345',
              name: 'Site Mobilization Meetings',
              tradeId: '01 31 19.16',
            },
            {
              id: '5fcf4b34-ddfd-4bd7-8792-8058ef8a7857',
              name: 'Progress Meetings',
              tradeId: '01 31 19.23',
            },
            {
              id: '798478af-8d13-4f56-85ed-26144d9c313d',
              name: 'Preinstallation Meetings',
              tradeId: '01 31 19.33',
            },
            {
              id: '386e227c-c9e2-427e-b390-0cce5cde817c',
              name: 'Project Web Site',
              tradeId: '01 31 23',
            },
            {
              id: '736695da-49b3-42bd-a24c-cae3498a9d3e',
              name: 'Electronic Communication Protocols',
              tradeId: '01 31 26',
            },
          ],
          tradeId: '01 31 00',
        },
        {
          id: 'df2a6622-0a5a-49a6-a502-f96c3dfcd227',
          name: 'Construction Progress Documentation',
          children: [
            {
              id: '0cc52663-fa29-4cf2-9c9c-3e8acd74c5cf',
              name: 'Construction Progress Documentation',
              tradeId: '01 32 00',
            },
            {
              id: '03a6830d-835b-4ab4-9b1d-cd05a04fad2a',
              name: 'Scheduling of Work',
              tradeId: '01 32 13',
            },
            {
              id: '0be0bcb5-129c-4a3c-967f-65afa78d1c1c',
              name: 'Construction Progress Schedule',
              tradeId: '01 32 16',
            },
            {
              id: 'ee9885dd-243d-4263-88f7-97246a84e1d0',
              name: 'Network Analysis Schedules',
              tradeId: '01 32 16.13',
            },
            {
              id: 'c3af9e1c-1a1f-475d-a971-8d73314dad6b',
              name: 'Submittals Schedule',
              tradeId: '01 32 19',
            },
            {
              id: '62a45971-7090-4dc8-b969-4f790c8e1c8e',
              name: 'Survey and Layout Data',
              tradeId: '01 32 23',
            },
            {
              id: 'c89ad54f-bb9a-4833-ae9c-44bf5a551248',
              name: 'Construction Progress Reporting',
              tradeId: '01 32 26',
            },
            {
              id: '0d06060e-f7ab-4832-a529-d61752d889b6',
              name: 'Periodic Work Observation',
              tradeId: '01 32 29',
            },
            {
              id: '59534807-be24-4a50-963b-ae497748b123',
              name: 'Photographic Documentation',
              tradeId: '01 32 33',
            },
            {
              id: 'c301adca-74f3-4848-8b70-30240ceb4e86',
              name: 'Video Monitoring and Documentation',
              tradeId: '01 32 36',
            },
            {
              id: '15eba459-b817-4c9e-95dc-4f8e303c2270',
              name: 'Purchase Order Tracking',
              tradeId: '01 32 43',
            },
          ],
          tradeId: '01 32 00',
        },
        {
          id: 'b5ba3b13-4bf6-47aa-bd09-218591340196',
          name: 'Submittal Procedures',
          children: [
            {
              id: 'c364166a-6094-4523-a5b0-01abc9590eb5',
              name: 'Submittal Procedures',
              tradeId: '01 33 00',
            },
            {
              id: '6b440e39-6b39-4119-ab64-8144bad23928',
              name: 'Certificates',
              tradeId: '01 33 13',
            },
            {
              id: 'c1521905-533c-4ba0-ab40-5550d8273338',
              name: 'Design Data',
              tradeId: '01 33 16',
            },
            {
              id: '4ab304c2-c6b9-419a-9ece-8d3492051a3c',
              name: 'Field Test Reporting',
              tradeId: '01 33 19',
            },
            {
              id: 'cdd26474-2743-4a0f-95b8-4ae126a2c181',
              name: 'Shop Drawings, Product Data and Samples',
              tradeId: '01 33 23',
            },
            {
              id: 'be5feb99-3f21-403d-b31e-51bc08c18e50',
              name: 'Source Quality Control Reporting',
              tradeId: '01 33 26',
            },
            {
              id: '78b353ba-a203-4c51-b70c-76ed6db11c4d',
              name: 'Sustainable Design Reporting',
              tradeId: '01 33 29',
            },
            {
              id: 'e226b0db-29c9-427c-8442-45e7a1d22184',
              name: 'Material Cost Summary Form',
              tradeId: '01 33 29.01',
            },
            {
              id: 'a4bbbfe6-3f37-4c01-a8be-22e5f36ed67e',
              name: 'Wood-Containing Product List',
              tradeId: '01 33 29.02',
            },
            {
              id: 'fc00c1c2-4c7c-4d1d-83ff-ee8824b023b1',
              name: 'Metal-Containing Product List',
              tradeId: '01 33 29.03',
            },
            {
              id: '07519f79-9307-414d-8a88-bf113a6fef73',
              name: 'Material Content Form',
              tradeId: '01 33 29.04',
            },
            {
              id: 'de61d529-cc39-4182-a616-ad3d3485422d',
              name: 'New Product Source Form',
              tradeId: '01 33 29.05',
            },
            {
              id: 'cf45ae9c-1ee1-4e83-9592-6ac8d5246a1e',
              name: 'Reused Product Form',
              tradeId: '01 33 29.06',
            },
            {
              id: 'b1043856-929e-4ac6-9c35-49aa2f6b6353',
              name: 'Prohibited Content Installer Certification',
              tradeId: '01 33 29.07',
            },
          ],
          tradeId: '01 33 00',
        },
        {
          id: '51f92c55-5567-4f23-bd81-6160371b1067',
          name: 'Special Procedures',
          children: [
            {
              id: '7779bb52-bf09-4d63-bb95-38f89919769d',
              name: 'Special Procedures',
              tradeId: '01 35 00',
            },
            {
              id: '8b511697-5492-4841-9926-b5ca37158a45',
              name: 'Special Project Procedures',
              tradeId: '01 35 13',
            },
            {
              id: 'c32d21af-32ed-478d-beaf-7aa5561116ec',
              name: 'Special Project Procedures for Airport Facilities',
              tradeId: '01 35 13.13',
            },
            {
              id: 'abf1b995-8b8c-45aa-afcb-c66d3594afc7',
              name: 'Special Project Procedures for Detention Facilities',
              tradeId: '01 35 13.16',
            },
            {
              id: 'a0611bb8-5fb0-4349-96ee-3f1e6e24001c',
              name: 'Special Project Procedures for Healthcare Facilities',
              tradeId: '01 35 13.19',
            },
            {
              id: '5adaf6e3-b533-4f8c-ab81-488d10ab2f76',
              name: 'Special Project Procedures for Clean Rooms',
              tradeId: '01 35 13.26',
            },
            {
              id: 'fa33430c-f4d9-4ebc-89f1-86a0bd2fc589',
              name: 'Special Project Procedures for Contaminated Sites',
              tradeId: '01 35 13.43',
            },
            {
              id: 'ae44118a-986a-4c74-b8a4-8560489a3206',
              name: 'Alteration Project Procedures',
              tradeId: '01 35 16',
            },
            {
              id: '9f8f09cd-0efd-43ff-b197-4c28aaaa8663',
              name: 'Owner Safety Requirements',
              tradeId: '01 35 23',
            },
            {
              id: '108b7ec3-8bf0-4e74-aafc-6eb95af9f0a8',
              name: 'Governmental Safety Requirements',
              tradeId: '01 35 26',
            },
            {
              id: '603a10ef-1376-402f-8396-cc82d357f600',
              name: 'Health, Safety and Emergency Response Procedures',
              tradeId: '01 35 29',
            },
            {
              id: '1d42af5a-6aee-4a3d-88e3-eb194ad09f94',
              name: 'Health, Safety and Emergency Response Procedures for Contaminated Sites',
              tradeId: '01 35 29.13',
            },
            {
              id: 'bc9731fd-74bd-4e65-b15e-d35e3d530e3f',
              name: 'Infection Control Procedures',
              tradeId: '01 35 33',
            },
            {
              id: '6598eca6-ec9e-4e4a-a204-33131bc42e8e',
              name: 'Environmental Procedures',
              tradeId: '01 35 43',
            },
            {
              id: 'a564d5c7-6ff1-46d4-8507-676588be77ff',
              name: 'Environmental Procedures for Hazardous Materials',
              tradeId: '01 35 43.13',
            },
            {
              id: 'f1e0c00e-3c37-46e9-89a4-05a4228450aa',
              name: 'Environmental Procedures for Toxic Materials',
              tradeId: '01 35 43.16',
            },
            {
              id: '24d43a2a-0b4b-4e36-b760-2d5a89660a37',
              name: 'Indoor Air Quality Procedures',
              tradeId: '01 35 46',
            },
            {
              id: 'f46b7a96-4973-4d8a-ab84-1c4c53a43a0c',
              name: 'Security Procedures',
              tradeId: '01 35 53',
            },
            {
              id: 'b3e9f7e1-f37d-4a13-a2f4-5f1d60a4158f',
              name: 'Sustainability Certification Project Requirements',
              tradeId: '01 35 63',
            },
            {
              id: '8fff2b44-d535-412d-b086-94e5118639af',
              name: 'Sustainability Certification Project Procedures',
              tradeId: '01 35 66',
            },
            {
              id: '3e6d7fea-b1f4-4989-8f1b-3cd4e4b03069',
              name: 'Historic Treatment Procedures',
              tradeId: '01 35 91',
            },
          ],
          tradeId: '01 35 00',
        },
        {
          id: '722e9ff4-8a21-41f0-b234-b0ab014edbf0',
          name: 'Quality Requirements',
          children: [],
          tradeId: '01 40 00',
        },
        {
          id: '3859e3f4-6d4d-4640-b522-c5002f1d647a',
          name: 'Regulatory Requirements',
          children: [
            {
              id: '2685af5a-842d-4641-a160-3fb97d35741a',
              name: 'Regulatory Requirements',
              tradeId: '01 41 00',
            },
            {
              id: '01fd415b-c15a-49a4-a67c-0afe33ab6a8e',
              name: 'Codes',
              tradeId: '01 41 13',
            },
            {
              id: '0f859b2a-72fb-47f6-9969-7ab488df45eb',
              name: 'Laws',
              tradeId: '01 41 16',
            },
            {
              id: '4a3d5f58-7b25-425d-b5b1-339b2f854159',
              name: 'Rules',
              tradeId: '01 41 19',
            },
            {
              id: 'fa1ec713-bc7a-4c63-8502-4fd46db4cff9',
              name: 'Fees',
              tradeId: '01 41 23',
            },
            {
              id: 'f8b5e831-2284-413e-927f-8f130145dfcf',
              name: 'Permit Requirements',
              tradeId: '01 41 26',
            },
          ],
          tradeId: '01 41 00',
        },
        {
          id: 'a1d70f78-f252-444b-aa5b-6198ba1ca179',
          name: 'References',
          children: [
            {
              id: 'a22c9350-cb4c-4337-a4de-4c42afea7085',
              name: 'References',
              tradeId: '01 42 00',
            },
            {
              id: '1a510066-7c0d-4f92-a19b-bdb620c4c228',
              name: 'Abbreviations and Acronyms',
              tradeId: '01 42 13',
            },
            {
              id: '8a98702a-29aa-426f-a55b-ab4d0626d603',
              name: 'Definitions',
              tradeId: '01 42 16',
            },
            {
              id: 'a090963a-010c-4f8f-bfc6-70497fcc5d57',
              name: 'Reference Standards',
              tradeId: '01 42 19',
            },
          ],
          tradeId: '01 42 00',
        },
        {
          id: 'eae79840-1c73-4a3a-82ab-c53005db7148',
          name: 'Quality Assurance',
          children: [
            {
              id: '97925868-a830-436d-a656-d54cd7b42b7b',
              name: 'Quality Assurance',
              tradeId: '01 43 00',
            },
            {
              id: '829d08cb-9cb5-40ce-9d4c-730f8e51cd18',
              name: 'Manufacturer Qualifications',
              tradeId: '01 43 13',
            },
            {
              id: 'f9d0f36a-0432-451a-a0fd-31866bc8811b',
              name: 'Supplier Qualifications',
              tradeId: '01 43 16',
            },
            {
              id: '8a08da8d-68b9-4c61-8d3f-3db1bb455117',
              name: 'Fabricator Qualifications',
              tradeId: '01 43 19',
            },
            {
              id: '5586351c-5ee1-42f5-b89b-19399df40539',
              name: 'Installer Qualifications',
              tradeId: '01 43 23',
            },
            {
              id: 'aedc068b-db13-433e-a2f4-7ef7cd64e563',
              name: 'Testing and Inspecting Agency Qualifications',
              tradeId: '01 43 26',
            },
            {
              id: '5457b054-6e88-4a64-baaf-6978810886f3',
              name: 'Code-Required Special Inspector Qualifications',
              tradeId: '01 43 29',
            },
            {
              id: 'a10acb88-d8b1-48be-99a8-837f0c270408',
              name: 'Manufacturer’s Field Services',
              tradeId: '01 43 33',
            },
            {
              id: 'e02d9042-3cbd-4573-a728-d5c3ac69b671',
              name: 'Field Samples',
              tradeId: '01 43 36',
            },
            {
              id: '931a1e0e-fa50-4de2-9a08-143ec1818263',
              name: 'Mockups',
              tradeId: '01 43 39',
            },
          ],
          tradeId: '01 43 00',
        },
        {
          id: '43ab5d4f-6862-4ae8-8834-4ed943211a54',
          name: 'Quality Control',
          children: [
            {
              id: 'd33745f4-b124-4c20-9270-fe9c3d6f43d4',
              name: 'Quality Control',
              tradeId: '01 45 00',
            },
            {
              id: 'c677ce5a-fd47-4145-a333-f2f36da4f9bb',
              name: 'Source Quality Control Procedures',
              tradeId: '01 45 13',
            },
            {
              id: 'b4f9aaad-10e3-493f-9ade-d3ff4ffc3736',
              name: 'Field Quality Control Procedures',
              tradeId: '01 45 16',
            },
            {
              id: '17cf3dbe-a6d4-46f2-a14c-0f0cabe6ed8e',
              name: 'Contractor Quality Control',
              tradeId: '01 45 16.13',
            },
            {
              id: 'b73659b3-0d83-4602-888d-bba07306b308',
              name: 'Testing and Inspecting Services',
              tradeId: '01 45 23',
            },
            {
              id: 'a86b0b00-36a4-4512-bf2b-68df8f1974ee',
              name: 'Plant Inspection Procedures',
              tradeId: '01 45 26',
            },
            {
              id: '0b2bec9a-01dc-4524-9685-3acf1802ce4c',
              name: 'Testing Laboratory Services',
              tradeId: '01 45 29',
            },
            {
              id: '11f7a332-5130-4510-9281-39e64ee21043',
              name: 'Code-Required Special Inspections and Procedures',
              tradeId: '01 45 33',
            },
          ],
          tradeId: '01 45 00',
        },
        {
          id: '910c6f96-b688-48c4-ab43-6e3054b171bf',
          name: 'Temporary Facilities and Controls',
          children: [],
          tradeId: '01 50 00',
        },
        {
          id: '00b31852-3fe9-447f-9139-8a6e959f889d',
          name: 'Temporary Utilities',
          children: [
            {
              id: '15a3f771-8983-42f2-a82b-895f8ab368b5',
              name: 'Temporary Utilities',
              tradeId: '01 51 00',
            },
            {
              id: '6721cddc-130e-4792-8eff-9c4d3edf65f3',
              name: 'Temporary Electricity',
              tradeId: '01 51 13',
            },
            {
              id: '3766dde7-4ae0-457a-88fb-69d287556ba5',
              name: 'Temporary Fire Protection',
              tradeId: '01 51 16',
            },
            {
              id: '8d947908-6d62-4887-99cb-d0e7bf0f4f4e',
              name: 'Temporary Fuel Oil',
              tradeId: '01 51 19',
            },
            {
              id: 'c88d6652-9b00-4fe2-a7ef-f30437776dba',
              name: 'Temporary Heating, Cooling and Ventilating',
              tradeId: '01 51 23',
            },
            {
              id: '62629840-d36c-4d3b-9d68-092c705e1c53',
              name: 'Temporary Lighting',
              tradeId: '01 51 26',
            },
            {
              id: '086edd49-02d8-422b-a54e-1620ed97c034',
              name: 'Temporary Natural-Gas',
              tradeId: '01 51 29',
            },
            {
              id: '7a88b9a2-f9e3-4965-9680-642f4b07ca94',
              name: 'Temporary Telecommunications',
              tradeId: '01 51 33',
            },
            {
              id: '2182e370-eeb0-420f-940f-36d05c17ee33',
              name: 'Temporary Water',
              tradeId: '01 51 36',
            },
          ],
          tradeId: '01 51 00',
        },
        {
          id: 'd82febcd-1288-4052-8e53-f81201bf5031',
          name: 'Construction Facilities',
          children: [
            {
              id: '8b9a4f23-53ff-44d5-90b3-f9afbc501644',
              name: 'Construction Facilities',
              tradeId: '01 52 00',
            },
            {
              id: '3876480c-6097-415e-8ef3-14993bda03ab',
              name: 'Field Offices and Sheds',
              tradeId: '01 52 13',
            },
            {
              id: '80ea5c8a-9c6a-406f-a974-4c22a13ef228',
              name: 'First Aid Facilities',
              tradeId: '01 52 16',
            },
            {
              id: '1d04cf26-774e-40db-b1c7-37e6db7940cc',
              name: 'Sanitary Facilities',
              tradeId: '01 52 19',
            },
          ],
          tradeId: '01 52 00',
        },
        {
          id: '68a6c4d6-682d-4e28-bc04-2ab437d8f7be',
          name: 'Temporary Construction',
          children: [
            {
              id: '895f7431-9ca9-4e95-8269-aaa712d36f8b',
              name: 'Temporary Construction',
              tradeId: '01 53 00',
            },
            {
              id: 'b2ec72c3-1eb3-47d4-b15c-a33685f76e0d',
              name: 'Temporary Bridges',
              tradeId: '01 53 13',
            },
            {
              id: 'ba43fd30-91e2-40ed-bd60-3963afb30980',
              name: 'Temporary Decking',
              tradeId: '01 53 16',
            },
            {
              id: 'f1ca65da-4ce1-4e27-9c99-bbc862cad326',
              name: 'Temporary Overpasses',
              tradeId: '01 53 19',
            },
            {
              id: '2445584e-7cdf-481b-be8f-647d8a9a34e4',
              name: 'Temporary Ramps',
              tradeId: '01 53 23',
            },
            {
              id: '4d9a2dae-db24-40d8-873e-f8950341b775',
              name: 'Temporary Runarounds',
              tradeId: '01 53 26',
            },
          ],
          tradeId: '01 53 00',
        },
        {
          id: 'e6d7927d-a3fe-4844-a3cb-6f2a96d76057',
          name: 'Construction Aids',
          children: [
            {
              id: 'bbc5f1c8-2f4b-4231-a049-db828e08346d',
              name: 'Construction Aids',
              tradeId: '01 54 00',
            },
            {
              id: '8808d365-2aeb-45c2-91bb-1e08c10e4c5f',
              name: 'Temporary Elevators',
              tradeId: '01 54 13',
            },
            {
              id: '2ed0439d-e44a-43e1-9d35-be5238126877',
              name: 'Temporary Hoists',
              tradeId: '01 54 16',
            },
            {
              id: 'd4848c93-fa4a-4dce-9cde-12394f658461',
              name: 'Temporary Cranes',
              tradeId: '01 54 19',
            },
            {
              id: 'b024159a-e76b-47fb-afc9-824d7efa3399',
              name: 'Temporary Scaffolding and Platforms',
              tradeId: '01 54 23',
            },
            {
              id: 'e3d67362-b527-4361-8344-095b2cb5394b',
              name: 'Temporary Swing Staging',
              tradeId: '01 54 26',
            },
          ],
          tradeId: '01 54 00',
        },
        {
          id: '3e08c64a-1a95-4d65-a434-67e777030f6a',
          name: 'Vehicular Access and Parking',
          children: [
            {
              id: 'ccbd2cb5-46ad-41fc-b8f2-2c2112043c05',
              name: 'Vehicular Access and Parking',
              tradeId: '01 55 00',
            },
            {
              id: '419ee469-c3dc-408e-8329-0bf2d67ae501',
              name: 'Temporary Access Roads',
              tradeId: '01 55 13',
            },
            {
              id: '6fa376c2-189e-48f9-9fc2-29ad993b32de',
              name: 'Haul Routes',
              tradeId: '01 55 16',
            },
            {
              id: 'b7fb8301-1c5a-4b7e-883b-e11072ceced5',
              name: 'Temporary Parking Areas',
              tradeId: '01 55 19',
            },
            {
              id: '4e31e168-edf1-4bee-9c00-f82684c30372',
              name: 'Temporary Roads',
              tradeId: '01 55 23',
            },
            {
              id: '45c881d2-722b-41ce-8644-1403c8241c30',
              name: 'Traffic Control',
              tradeId: '01 55 26',
            },
            {
              id: '539134f6-e33e-42b5-927f-31b95a3ea56a',
              name: 'Staging Areas',
              tradeId: '01 55 29',
            },
          ],
          tradeId: '01 55 00',
        },
        {
          id: '8869bb8c-08f5-4b53-a22b-fde1c68992a1',
          name: 'Temporary Barriers and Enclosures',
          children: [
            {
              id: '28ffff47-7dbc-4986-82bc-3f008b139d44',
              name: 'Temporary Barriers and Enclosures',
              tradeId: '01 56 00',
            },
            {
              id: '6e17a112-d841-4398-ba32-3871631d92e9',
              name: 'Temporary Air Barriers',
              tradeId: '01 56 13',
            },
            {
              id: '3f5e0c54-6c9d-4f55-8237-5f6402d53a8f',
              name: 'Temporary Dust Barriers',
              tradeId: '01 56 16',
            },
            {
              id: '5e0109c5-65f6-4fd8-a469-56d9c3310261',
              name: 'Temporary Noise Barriers',
              tradeId: '01 56 19',
            },
            {
              id: 'b1331fd4-2433-4093-a767-73a6f32e360f',
              name: 'Temporary Barricades',
              tradeId: '01 56 23',
            },
            {
              id: '4554abbc-f8a5-43c3-8a84-c68ceb9a81f5',
              name: 'Temporary Fencing',
              tradeId: '01 56 26',
            },
            {
              id: 'dd6d02de-7db3-46e5-a64b-8771d3733e3e',
              name: 'Temporary Protective Walkways',
              tradeId: '01 56 29',
            },
            {
              id: '4f7059b2-f9a7-49d3-93d5-fdc938ba7239',
              name: 'Temporary Security Barriers',
              tradeId: '01 56 33',
            },
            {
              id: '45767367-bb52-4879-9ed3-5adc0b5ee1a8',
              name: 'Temporary Security Enclosures',
              tradeId: '01 56 36',
            },
            {
              id: 'b21038a3-2511-4d14-bece-32b3d9f5801f',
              name: 'Temporary Tree and Plant Protection',
              tradeId: '01 56 39',
            },
          ],
          tradeId: '01 56 00',
        },
        {
          id: '9c83a984-d97d-4de7-92bd-26606642a0fc',
          name: 'Temporary Controls',
          children: [
            {
              id: '0a6e94b7-5080-44bb-ad44-bfaeebe588bc',
              name: 'Temporary Controls',
              tradeId: '01 57 00',
            },
            {
              id: 'c90f6e0d-3f34-4c64-be7f-e943572a5cca',
              name: 'Temporary Erosion and Sediment Control',
              tradeId: '01 57 13',
            },
            {
              id: '3b06087c-58f9-41ff-bfb4-8b0776be1c0d',
              name: 'Temporary Pest Control',
              tradeId: '01 57 16',
            },
            {
              id: '8e8a9f9e-42e9-400c-8fe1-397bb8f9b1f1',
              name: 'Temporary Environmental Controls',
              tradeId: '01 57 19',
            },
            {
              id: '7209c3ae-795a-40a4-8135-c1a5d8e25d33',
              name: 'Temporary Storm Water Pollution Control',
              tradeId: '01 57 23',
            },
            {
              id: 'c572cf97-9564-45cb-ad89-30264812bd44',
              name: 'Site Watering for Dust Control',
              tradeId: '01 57 26',
            },
          ],
          tradeId: '01 57 00',
        },
        {
          id: '06c60915-d66f-4b11-afbd-99c75c0b0c88',
          name: 'Project Identification',
          children: [
            {
              id: '7d36ada6-31bf-4e38-8415-f713e0edbd23',
              name: 'Project Identification',
              tradeId: '01 58 00',
            },
            {
              id: '67ab9119-4a7a-43ba-b56b-ee38d0195ea3',
              name: 'Temporary Project Signage',
              tradeId: '01 58 13',
            },
            {
              id: '88298dc1-9f78-4b4d-b608-fdf88e5ee56a',
              name: 'Temporary Interior Signage',
              tradeId: '01 58 16',
            },
          ],
          tradeId: '01 58 00',
        },
        {
          id: 'b366fb2f-c99d-4efd-9605-f661d9ba47c4',
          name: 'Product Requirements',
          children: [],
          tradeId: '01 60 00',
        },
        {
          id: 'a7bdb885-b347-4296-b7b9-810000eb4a7f',
          name: 'Common Product Requirements',
          children: [
            {
              id: '050c3195-511e-427d-b6b9-67833fb574f4',
              name: 'Common Product Requirements',
              tradeId: '01 61 00',
            },
            {
              id: '01556a71-0dbf-4a92-9cd1-6cdfc3fcf0a5',
              name: 'Software Licensing Requirements',
              tradeId: '01 61 13',
            },
          ],
          tradeId: '01 61 00',
        },
        {
          id: '4e64f353-ef14-4244-960e-8966babe646c',
          name: 'Product Options',
          children: [],
          tradeId: '01 62 00',
        },
        {
          id: '66a00ef2-def1-413c-b793-39adf8caef61',
          name: 'Owner-Furnished Products',
          children: [],
          tradeId: '01 64 00',
        },
        {
          id: '580bfc58-f61c-4d50-82c4-aecdfeeb1539',
          name: 'Product Delivery Requirements',
          children: [],
          tradeId: '01 65 00',
        },
        {
          id: '1a65a0ae-043c-4f9d-bf2c-a330c3b1b0f7',
          name: 'Product Storage and Handling Requirements',
          children: [
            {
              id: '324ab81f-ea92-411b-9f38-4272784c15e3',
              name: 'Product Storage and Handling Requirements',
              tradeId: '01 66 00',
            },
            {
              id: 'ecf639a8-8d7d-4ced-b220-dfa295c827fa',
              name: 'Product Storage and Handling Requirements for Hazardous Materials',
              tradeId: '01 66 13',
            },
            {
              id: 'debd4079-87ae-4442-bec3-8b2eb25289e9',
              name: 'Product Storage and Handling Requirements for Toxic Materials',
              tradeId: '01 66 16',
            },
          ],
          tradeId: '01 66 00',
        },
        {
          id: '7b4e805d-bf37-4add-885d-7e49444ce65f',
          name: 'Execution and Closeout Requirements',
          children: [],
          tradeId: '01 70 00',
        },
        {
          id: 'dfc1f823-7c11-4e31-b6f9-c9da5327b52d',
          name: 'Examination and Preparation',
          children: [
            {
              id: '8dc428fa-d23a-48f8-91ca-0e98d7360c9b',
              name: 'Examination and Preparation',
              tradeId: '01 71 00',
            },
            {
              id: 'e2e82c6d-b461-4640-8eec-59339e7fc17e',
              name: 'Mobilization',
              tradeId: '01 71 13',
            },
            {
              id: '4555a0f8-ceed-45f3-844e-2701e2ccabdb',
              name: 'Acceptance of Conditions',
              tradeId: '01 71 16',
            },
            {
              id: 'c61bc3ef-3a27-42de-b4ff-25269f06c125',
              name: 'Field Engineering',
              tradeId: '01 71 23',
            },
            {
              id: 'c0da6f88-1d48-4c80-bb9e-5bc5af70852a',
              name: 'Construction Layout',
              tradeId: '01 71 23.13',
            },
            {
              id: '1bf690f3-a0da-40a5-babb-92ad210c37c2',
              name: 'Construction Surveying',
              tradeId: '01 71 23.16',
            },
            {
              id: '2393bed2-0baf-4f33-a9fd-aba022881c5c',
              name: 'Protection of Adjacent Construction',
              tradeId: '01 71 33',
            },
            {
              id: '5d675c1c-d885-46d8-8a3f-67f8423e9154',
              name: 'Non-Destructive Concrete Examination',
              tradeId: '01 71 36',
            },
          ],
          tradeId: '01 71 00',
        },
        {
          id: '3aed4de2-be05-4cb0-b42f-4d347c6edd26',
          name: 'Execution',
          children: [
            {
              id: '40d89976-47d7-4ce7-8d80-8e35f373bde5',
              name: 'Execution',
              tradeId: '01 73 00',
            },
            {
              id: '9a1f603c-5d33-4a6d-83dd-ab02dd941db2',
              name: 'Application',
              tradeId: '01 73 13',
            },
            {
              id: '9abacbc6-c87b-433c-ace5-eddc4b71da94',
              name: 'Erection',
              tradeId: '01 73 16',
            },
            {
              id: '85348556-b753-4e3f-bfcc-8b0b3cb02f03',
              name: 'Installation',
              tradeId: '01 73 19',
            },
            {
              id: 'c7f652a9-5aa9-4de1-ad79-f2f765ae40ad',
              name: 'Bracing and Anchoring',
              tradeId: '01 73 23',
            },
            {
              id: 'fb305de7-9287-44d2-8dc7-ec12512c878e',
              name: 'Existing Products',
              tradeId: '01 73 26',
            },
            {
              id: 'a5d58111-dd1f-4105-881a-04b798f822ac',
              name: 'Cutting and Patching',
              tradeId: '01 73 29',
            },
          ],
          tradeId: '01 73 00',
        },
        {
          id: '827b73ba-1ad2-4b1d-871c-7b0008987179',
          name: 'Cleaning and Waste Management',
          children: [
            {
              id: '00c32cef-42c6-4e40-9dcb-da1ec93674af',
              name: 'Cleaning and Waste Management',
              tradeId: '01 74 00',
            },
            {
              id: '9f9da7ff-647b-4481-828e-21442ff22d98',
              name: 'Progress Cleaning',
              tradeId: '01 74 13',
            },
            {
              id: '0d51a883-eaff-4972-9cec-8750966c4900',
              name: 'Site Maintenance',
              tradeId: '01 74 16',
            },
            {
              id: 'afa95e0b-6a41-4bd4-a84a-3b5fcf0a03ae',
              name: 'Construction Waste Management and Disposal',
              tradeId: '01 74 19',
            },
            {
              id: 'eba164b4-4318-4cfb-9785-092db2281dbc',
              name: 'Final Cleaning',
              tradeId: '01 74 23',
            },
          ],
          tradeId: '01 74 00',
        },
        {
          id: '0b646552-4a86-4afc-8830-e0bd9178b702',
          name: 'Starting and Adjusting',
          children: [
            {
              id: '362f0a51-0fd5-461d-8148-859f643e9800',
              name: 'Starting and Adjusting',
              tradeId: '01 75 00',
            },
            {
              id: '1d098791-eed4-4c3c-8b08-4582b2d3308d',
              name: 'Checkout Procedures',
              tradeId: '01 75 13',
            },
            {
              id: 'ff8aad09-2a93-4651-8c85-483bb2734999',
              name: 'Startup Procedures',
              tradeId: '01 75 16',
            },
          ],
          tradeId: '01 75 00',
        },
        {
          id: '9c11f196-7bb5-43d2-b5a9-6d5d798d0f47',
          name: 'Protecting Installed Construction',
          children: [],
          tradeId: '01 76 00',
        },
        {
          id: 'bb9a1ed7-b760-4c20-9c1b-40dc9ddf6aff',
          name: 'Closeout Procedures',
          children: [
            {
              id: 'cf363a0d-a5ef-46e1-a2fe-c584802b9333',
              name: 'Closeout Procedures',
              tradeId: '01 77 00',
            },
            {
              id: '76ae674c-c27b-4144-8c18-413691ec8eca',
              name: 'Preliminary Closeout Reviews',
              tradeId: '01 77 13',
            },
            {
              id: '3cba40f0-0d18-41a1-9c3d-7d81606ccc44',
              name: 'Final Closeout Review',
              tradeId: '01 77 16',
            },
            {
              id: 'c68e4e28-fd19-48cc-a1af-fb590fced579',
              name: 'Closeout Requirements',
              tradeId: '01 77 19',
            },
          ],
          tradeId: '01 77 00',
        },
        {
          id: '1c58eb21-6691-4172-b6b7-fe59d034c048',
          name: 'Closeout Submittals',
          children: [
            {
              id: '6456b2a1-f138-47af-96be-19592e20e1c5',
              name: 'Closeout Submittals',
              tradeId: '01 78 00',
            },
            {
              id: '4629d58c-e8b6-4c07-b31d-ff8b50bc5607',
              name: 'Completion and Correction List',
              tradeId: '01 78 13',
            },
            {
              id: '1cf8b613-6a96-446d-b38e-feda0f3f6079',
              name: 'Maintenance Contracts',
              tradeId: '01 78 19',
            },
            {
              id: 'eb049b45-7d64-4bd4-bad3-b11b1de7a829',
              name: 'Operation and Maintenance Data',
              tradeId: '01 78 23',
            },
            {
              id: '11e0e709-bd6d-43e3-abdd-a98422d713cd',
              name: 'Operation Data',
              tradeId: '01 78 23.13',
            },
            {
              id: 'f6db9da8-3387-4593-a181-9aa16f919baa',
              name: 'Maintenance Data',
              tradeId: '01 78 23.16',
            },
            {
              id: '38e32dae-d57f-4884-af75-64dd162652e7',
              name: 'Preventative Maintenance Instructions',
              tradeId: '01 78 23.19',
            },
            {
              id: '637f9ba8-cb8f-4f7c-b75b-a32f15422b57',
              name: 'Final Site Survey',
              tradeId: '01 78 29',
            },
            {
              id: '6eb4dbcb-3688-47ec-bff3-e1c1ab502b5e',
              name: 'Bonds',
              tradeId: '01 78 33',
            },
            {
              id: 'be0f4254-cf7d-4e10-87d4-0535d95c5656',
              name: 'Warranties',
              tradeId: '01 78 36',
            },
            {
              id: '852ad63a-7458-42f8-b095-1a809bd121c6',
              name: 'Project Record Documents',
              tradeId: '01 78 39',
            },
            {
              id: 'fb95f64a-91ae-449e-b7c1-0e13358f819f',
              name: 'Spare Parts',
              tradeId: '01 78 43',
            },
            {
              id: '46637ef6-b7fd-4800-9995-d84ed17dcdb0',
              name: 'Extra Stock Materials',
              tradeId: '01 78 46',
            },
            {
              id: 'e7c10f07-39ec-4ea4-a8f2-245629c9b0f7',
              name: 'Sustainable Design Closeout Documentation',
              tradeId: '01 78 53',
            },
          ],
          tradeId: '01 78 00',
        },
        {
          id: '69ed8a66-3cfa-4980-9528-f02ddc1d7863',
          name: 'Demonstration and Training',
          children: [],
          tradeId: '01 79 00',
        },
        {
          id: '7f39362b-a9b5-4030-b33f-b2a548f3e3fd',
          name: 'Performance Requirements',
          children: [],
          tradeId: '01 80 00',
        },
        {
          id: 'bdc6dae2-0b3a-411f-ae67-5def1bd2257f',
          name: 'Facility Performance Requirements',
          children: [
            {
              id: 'd41e5220-8985-4b6e-9373-a29c36b7f1db',
              name: 'Facility Performance Requirements',
              tradeId: '01 81 00',
            },
            {
              id: '02aa36ca-6718-4980-a11f-02cfb3b625f3',
              name: 'Sustainable Design Requirements',
              tradeId: '01 81 13',
            },
            {
              id: '5d6e354e-79af-4585-a1ca-6c78fd362131',
              name: 'Facility Environmental Requirements',
              tradeId: '01 81 16',
            },
            {
              id: '8714618c-ecd6-4350-82e6-fbbb6cf24d2d',
              name: 'Indoor Air Quality Requirements',
              tradeId: '01 81 19',
            },
          ],
          tradeId: '01 81 00',
        },
        {
          id: '8ba30228-1271-49b7-bcf1-680a0b82da1d',
          name: 'Facility Substructure Performance Requirements',
          children: [
            {
              id: '820f0432-49d9-4b89-bcad-0a7ccfa4808e',
              name: 'Facility Substructure Performance Requirements',
              tradeId: '01 82 00',
            },
            {
              id: 'a4f6e30e-ef34-4e3b-afe1-2e60a94bb70b',
              name: 'Foundation Performance Requirements',
              tradeId: '01 82 13',
            },
            {
              id: 'db556d34-feca-4d5a-9315-18d4d153cfb6',
              name: 'Basement Construction Performance Requirements',
              tradeId: '01 82 16',
            },
          ],
          tradeId: '01 82 00',
        },
        {
          id: 'ea7bd3c3-fa13-4aaa-aa3f-6d531ccbb60b',
          name: 'Facility Shell Performance Requirements',
          children: [
            {
              id: 'd61385b9-69be-4f7b-a2fc-c8a2993594b2',
              name: 'Facility Shell Performance Requirements',
              tradeId: '01 83 00',
            },
            {
              id: '82dea100-82e8-4693-bee3-887289f5b7b9',
              name: 'Superstructure Performance Requirements',
              tradeId: '01 83 13',
            },
            {
              id: 'acb29b3f-be6c-478d-b174-ee71384ab0f9',
              name: 'Exterior Enclosure Performance Requirements',
              tradeId: '01 83 16',
            },
            {
              id: '1cbcaea5-fd63-4bfc-83f0-611d42d48bc2',
              name: 'Roofing Performance Requirements',
              tradeId: '01 83 19',
            },
          ],
          tradeId: '01 83 00',
        },
        {
          id: 'a58fa267-16e8-4fe5-a25d-5683ebd87bf3',
          name: 'Interiors Performance Requirements',
          children: [
            {
              id: '7433775b-fa9c-4237-bedc-a9bbf79d5d4d',
              name: 'Interiors Performance Requirements',
              tradeId: '01 84 00',
            },
            {
              id: '48a3136c-e9b0-420a-a1e1-3c69ad42abed',
              name: 'Interior Construction Performance Requirements',
              tradeId: '01 84 13',
            },
            {
              id: '66486c9f-d861-4c99-8b79-d5b589d04cbb',
              name: 'Stairways Performance Requirements',
              tradeId: '01 84 16',
            },
            {
              id: '57bcfa6f-4720-4b0e-90b9-aef6e44f5acd',
              name: 'Interior Finishes Performance Requirements',
              tradeId: '01 84 19',
            },
          ],
          tradeId: '01 84 00',
        },
        {
          id: 'c0d9f4bc-facb-488a-92cc-d2c3d9fc1dcc',
          name: 'Conveying Equipment Performance Requirements',
          children: [],
          tradeId: '01 85 00',
        },
        {
          id: 'c00983eb-3004-40b9-9785-c7fda8a83d91',
          name: 'Facility Services Performance Requirements',
          children: [
            {
              id: 'c26ddf48-ade1-4b03-b111-52cebe989a5b',
              name: 'Facility Services Performance Requirements',
              tradeId: '01 86 00',
            },
            {
              id: '780ad544-1b0c-41dd-9ace-0a4777ec6b79',
              name: 'Fire Suppression Performance Requirements',
              tradeId: '01 86 13',
            },
            {
              id: '789806e1-205b-4595-bd64-9b7ec9723507',
              name: 'Plumbing Performance Requirements',
              tradeId: '01 86 16',
            },
            {
              id: '3ed43c85-e855-4db5-9666-3b9fd1455317',
              name: 'HVAC Performance Requirements',
              tradeId: '01 86 19',
            },
            {
              id: '9b71972d-1c7f-4197-914a-f0239a49cc26',
              name: 'Integrated Automation Performance Requirements',
              tradeId: '01 86 23',
            },
            {
              id: '08b2e01b-0a0e-465b-ace3-89948f1ecba7',
              name: 'Electrical Performance Requirements',
              tradeId: '01 86 26',
            },
            {
              id: '96b2f326-d2a1-4463-8767-87684b8f5f0c',
              name: 'Communications Performance Requirements',
              tradeId: '01 86 29',
            },
            {
              id: '2fe0d241-4c3e-4e12-9815-635acadb3f4d',
              name: 'Electronic Safety and Security Performance Requirements',
              tradeId: '01 86 33',
            },
          ],
          tradeId: '01 86 00',
        },
        {
          id: 'cd9e817d-0f7c-4308-a1d2-288dc275000e',
          name: 'Equipment and Furnishings Performance Requirements',
          children: [
            {
              id: 'd9e9cffa-90ce-4e21-bdf0-545689c2bd3a',
              name: 'Equipment and Furnishings Performance Requirements',
              tradeId: '01 87 00',
            },
            {
              id: '8004dfa4-11c4-4b18-8eae-f3b6011d0156',
              name: 'Equipment Performance Requirements',
              tradeId: '01 87 13',
            },
            {
              id: '10ad321e-a032-4652-be1d-6e0cac96e392',
              name: 'Furnishings Performance Requirements',
              tradeId: '01 87 16',
            },
          ],
          tradeId: '01 87 00',
        },
        {
          id: 'd54fcf71-ba76-4107-9e01-e2e95995292c',
          name: 'Other Facility Construction Performance Requirements',
          children: [
            {
              id: '23c4f1d3-a6b0-4b9b-8c9f-cb462ec61332',
              name: 'Other Facility Construction Performance Requirements',
              tradeId: '01 88 00',
            },
            {
              id: 'c2eeb862-8a9e-4202-835c-3290dbe7adad',
              name: 'Special Construction Performance Requirements',
              tradeId: '01 88 13',
            },
            {
              id: '1211a3aa-1a32-4adf-a26d-501a80064e76',
              name: 'Selective Construction Performance Requirements',
              tradeId: '01 88 16',
            },
          ],
          tradeId: '01 88 00',
        },
        {
          id: 'cc16458d-ac66-4e5d-8968-7da7ddade2f3',
          name: 'Site Construction Performance Requirements',
          children: [
            {
              id: '6167e298-853d-4725-b45f-9ffed866e0c6',
              name: 'Site Construction Performance Requirements',
              tradeId: '01 89 00',
            },
            {
              id: '295d5755-7483-4ff9-8a8c-103a6d9f208c',
              name: 'Site Preparation Performance Requirements',
              tradeId: '01 89 13',
            },
            {
              id: '3c37dd93-c889-4461-bf88-8edd89191a82',
              name: 'Site Improvements Performance Requirements',
              tradeId: '01 89 16',
            },
            {
              id: 'a3cf6736-370c-40d0-ac04-f54849116680',
              name: 'Site Plumbing Utilities Performance Requirements',
              tradeId: '01 89 19',
            },
            {
              id: '49041648-453a-43d5-9969-342a54bcdc49',
              name: 'Site HVAC Utilities Performance Requirements',
              tradeId: '01 89 23',
            },
            {
              id: 'c53b22d7-be20-416a-9579-eaaf8b90f814',
              name: 'Site Electrical Utilities Performance Requirements',
              tradeId: '01 89 26',
            },
            {
              id: 'f68294ef-eea1-443f-a394-0546b95d9c81',
              name: 'Other Site Construction Performance Requirements',
              tradeId: '01 89 29',
            },
          ],
          tradeId: '01 89 00',
        },
        {
          id: '5d6dd457-d346-4697-80f5-351525aa58ad',
          name: 'Life Cycle Activities',
          children: [],
          tradeId: '01 90 00',
        },
        {
          id: '7ecb4693-d939-4174-bbc6-2c4da3161b1d',
          name: 'Commissioning',
          children: [
            {
              id: '68c3f5e5-f555-48dd-9e7c-c425ceee5c15',
              name: 'Commissioning',
              tradeId: '01 91 00',
            },
            {
              id: '9467fef5-5dcc-467a-85a5-967d3f8ab4f2',
              name: 'General Commissioning Requirements',
              tradeId: '01 91 13',
            },
            {
              id: '1544c20c-caa2-49b3-a9f5-2d786ee7bd0c',
              name: 'Facility Substructure Commissioning',
              tradeId: '01 91 16',
            },
            {
              id: 'd5fce727-5bed-42c0-8191-3bafa8763da7',
              name: 'Foundation Commissioning',
              tradeId: '01 91 16.13',
            },
            {
              id: '661d928e-fb29-424b-aad2-08c2171be4e1',
              name: 'Basement Construction Commissioning',
              tradeId: '01 91 16.53',
            },
            {
              id: '0b398edc-0a3e-4e0d-9e34-1dd017cb9735',
              name: 'Facility Shell Commissioning',
              tradeId: '01 91 19',
            },
            {
              id: '95076fef-7e33-46b9-a207-50037320538f',
              name: 'Superstructure Commissioning',
              tradeId: '01 91 19.13',
            },
            {
              id: 'de30e573-cea2-4abd-8b8a-4ad7067093af',
              name: 'Exterior Enclosure Commissioning',
              tradeId: '01 91 19.43',
            },
            {
              id: 'a7d99d96-a8ea-42f3-8672-c7ef5f862538',
              name: 'Roofing Commissioning',
              tradeId: '01 91 19.73',
            },
            {
              id: '33e15389-5003-43b1-b197-ea370ef9f320',
              name: 'Interiors Commissioning',
              tradeId: '01 91 23',
            },
            {
              id: 'b65dba43-c454-4b3f-bbc3-30f8c3023250',
              name: 'Interior Construction Commissioning',
              tradeId: '01 91 23.13',
            },
            {
              id: 'bb10eadb-9f74-4597-83c8-6b54755377de',
              name: 'Stairways Commissioning',
              tradeId: '01 91 23.43',
            },
            {
              id: '15f59536-508c-4dda-8540-42df2e634c9c',
              name: 'Interior Finishes Commissioning',
              tradeId: '01 91 23.73',
            },
          ],
          tradeId: '01 91 00',
        },
        {
          id: 'cb1ff8ee-7d46-473b-ab48-2d9be947a5f9',
          name: 'Facility Operation',
          children: [
            {
              id: '6a0da4b1-fe7e-483a-be9e-e0a455a2221c',
              name: 'Facility Operation',
              tradeId: '01 92 00',
            },
            {
              id: '6ea6fac4-90ce-4a59-8fbd-135efba68e50',
              name: 'Facility Operation Procedures',
              tradeId: '01 92 13',
            },
          ],
          tradeId: '01 92 00',
        },
        {
          id: '64c6803b-6773-405f-891b-ae252a3eb42c',
          name: 'Facility Maintenance',
          children: [
            {
              id: 'ccbe3501-81ea-42a0-be0a-31388abae707',
              name: 'Facility Maintenance',
              tradeId: '01 93 00',
            },
            {
              id: 'd6f00f55-433a-460e-b14a-30bbbeacfda9',
              name: 'Facility Maintenance Procedures',
              tradeId: '01 93 13',
            },
            {
              id: 'f5aed315-eaac-4672-9ec5-f4d791e373ab',
              name: 'Recycling Programs',
              tradeId: '01 93 16',
            },
          ],
          tradeId: '01 93 00',
        },
        {
          id: '15a7fe16-3804-4c97-b53c-373f4c6b25e8',
          name: 'Facility Decommissioning',
          children: [
            {
              id: '550b388d-8e98-4f30-aa4c-5269cd4d3ddb',
              name: 'Facility Decommissioning',
              tradeId: '01 94 00',
            },
            {
              id: '8d7da81c-16b4-40a5-85d3-08c7cedc9098',
              name: 'Facility Decommissioning Procedures',
              tradeId: '01 94 13',
            },
          ],
          tradeId: '01 94 00',
        },
      ],
      tradeId: '01 00 00',
    },
    {
      id: 'c83ba7aa-1853-4caf-8bda-77479c6d6d56',
      name: 'Existing Conditions',
      children: [
        {
          id: 'b27f2a3a-4215-4944-85a8-8680b7ded714',
          name: 'Existing Conditions',
          children: [],
          tradeId: '02 00 00',
        },
        {
          id: '1e15da47-54e4-43d4-a42c-734ee2f27c51',
          name: 'Maintenance of Existing Conditions',
          children: [
            {
              id: 'c7bac9de-87ba-4fd7-bb2e-fa1da3334948',
              name: 'Maintenance of Existing Conditions',
              tradeId: '02 01 00',
            },
            {
              id: '5cefa735-29bd-4f3d-83c0-5ab27663b161',
              name: 'Maintenance of Site Remediation',
              tradeId: '02 01 50',
            },
            {
              id: '101593a0-4c70-4c23-80f3-6c7908c0ad78',
              name: 'Maintenance of Underground Storage Tank Removal',
              tradeId: '02 01 65',
            },
            {
              id: 'd83d6221-19c7-49ed-8b9a-a952e9eaa0c5',
              name: 'Maintenance of Facility Remediation',
              tradeId: '02 01 80',
            },
            {
              id: 'b864f275-1409-4f7e-bbc2-4de77240e449',
              name: 'Maintenance of Hazardous Waste Drum Handling',
              tradeId: '02 01 86',
            },
          ],
          tradeId: '02 01 00',
        },
        {
          id: 'f230e914-021a-4e9a-84f0-b280d49b5cea',
          name: 'Common Work Results for Existing Conditions',
          children: [
            {
              id: 'c0e62e03-d96d-40fe-a154-6a913dd577f7',
              name: 'Common Work Results for Existing Conditions',
              tradeId: '02 05 00',
            },
            {
              id: 'cae6a9fe-8d10-4e1b-b9e2-753782cb7133',
              name: 'Geosynthetics for Existing Conditions',
              tradeId: '02 05 19',
            },
            {
              id: 'db8d8e34-f543-4bbd-b313-761fd7a93d25',
              name: 'Geotextiles for Existing Conditions',
              tradeId: '02 05 19.13',
            },
            {
              id: '4a2354f2-a0c5-46e2-afa1-13233e00b759',
              name: 'Geomembranes for Existing Conditions',
              tradeId: '02 05 19.16',
            },
            {
              id: '945f175d-cafd-48d2-bbf5-c594e64c3ba6',
              name: 'Geogrids for Existing Conditions',
              tradeId: '02 05 19.19',
            },
          ],
          tradeId: '02 05 00',
        },
        {
          id: '7889b541-4ad1-405e-b534-565388a5f6db',
          name: 'Schedules for Existing Conditions',
          children: [
            {
              id: '70a59f44-2f55-4b6d-aced-0a257e3e0084',
              name: 'Schedules for Existing Conditions',
              tradeId: '02 06 00',
            },
            {
              id: '8610f2ed-1aaf-4d64-83f0-a9be17dac4f5',
              name: 'Schedules for Subsurface Investigations',
              tradeId: '02 06 30',
            },
            {
              id: '6ede0cb6-f2a7-4881-a1e5-f848048a30fc',
              name: 'Boring or Test Pit Log Schedule',
              tradeId: '02 06 30.13',
            },
            {
              id: '2ec0cdb8-7b3f-48f5-bcb7-4afad2ec4612',
              name: 'Schedules for Site Remediation',
              tradeId: '02 06 50',
            },
            {
              id: '3542f785-d0b8-4f7d-ac74-27359bda3cc4',
              name: 'Schedules for Underground Storage Tank Removal',
              tradeId: '02 06 65',
            },
            {
              id: 'a1dab160-bbc0-4275-92db-47a96926c0ec',
              name: 'Schedules for Facility Remediation',
              tradeId: '02 06 80',
            },
            {
              id: 'fee578b4-0bd9-4553-99ee-d025b5de4066',
              name: 'Schedules for Hazardous Waste Drum Handling',
              tradeId: '02 06 86',
            },
          ],
          tradeId: '02 06 00',
        },
        {
          id: '4375bec5-b7c2-49e3-8a3d-04f2dadc594c',
          name: 'Commissioning of Existing Conditions',
          children: [],
          tradeId: '02 08 00',
        },
        {
          id: '68760f6c-1a9e-401a-85dc-a8ecadc6c6fa',
          name: 'Assessment',
          children: [],
          tradeId: '02 20 00',
        },
        {
          id: 'ff423d74-d848-4dd9-927c-47b005c4253f',
          name: 'Surveys',
          children: [
            {
              id: '09218be7-a037-408e-a414-b58f97f8da2f',
              name: 'Surveys',
              tradeId: '02 21 00',
            },
            {
              id: 'a8d1a306-c12e-4085-9b11-30c96e87ad63',
              name: 'Site Surveys',
              tradeId: '02 21 13',
            },
            {
              id: '623c96bc-ab3d-4fc9-9992-efe25342d8a3',
              name: 'Boundary and Survey Markers',
              tradeId: '02 21 13.13',
            },
            {
              id: '28fee691-b21c-4ce2-a548-c917c7ddffa8',
              name: 'Archeological and Historic Surveys',
              tradeId: '02 21 13.23',
            },
            {
              id: '20875d9c-4285-4f84-922b-39e89bef8a1a',
              name: 'Measured Drawings',
              tradeId: '02 21 16',
            },
          ],
          tradeId: '02 21 00',
        },
        {
          id: '6f824f46-e7dc-48d0-a922-ddaf8a46f5a6',
          name: 'Existing Conditions Assessment',
          children: [
            {
              id: '08b5857e-8e28-499c-a51b-a72d78f79d7b',
              name: 'Existing Conditions Assessment',
              tradeId: '02 22 00',
            },
            {
              id: '7aac53e3-631a-48a9-8a4a-45cf91bb7d75',
              name: 'Movement and Vibration Assessment',
              tradeId: '02 22 13',
            },
            {
              id: '8eee7806-ee82-4c43-86f8-5d7699dba991',
              name: 'Acoustic Assessment',
              tradeId: '02 22 16',
            },
            {
              id: 'f9448384-f702-4dad-b271-bca27d0ba4b5',
              name: 'Traffic Assessment',
              tradeId: '02 22 19',
            },
            {
              id: '19e9357a-6df4-4732-8f52-b3b079a0a064',
              name: 'Accessibility Assessment',
              tradeId: '02 22 23',
            },
          ],
          tradeId: '02 22 00',
        },
        {
          id: '5106c324-b59a-44ab-b7ae-4e376ebf8798',
          name: 'Environmental Assessment',
          children: [
            {
              id: 'eb4debe2-15ae-4391-a6dc-955b5b84566b',
              name: 'Environmental Assessment',
              tradeId: '02 24 00',
            },
            {
              id: '17bbfd6b-af8d-445c-8036-2e65679421f0',
              name: 'Natural Environment Assessment',
              tradeId: '02 24 13',
            },
            {
              id: '5544c0c8-b348-4e1c-ba58-cc6673a793c6',
              name: 'Air Assessment',
              tradeId: '02 24 13.13',
            },
            {
              id: 'bd803168-4684-4761-b6c9-ac2dfdc0d2f4',
              name: 'Water Assessment',
              tradeId: '02 24 13.43',
            },
            {
              id: '415c5168-efdb-424c-888b-0c24d4612b27',
              name: 'Land Assessment',
              tradeId: '02 24 13.73',
            },
            {
              id: 'b8dc5644-ed31-4bb6-8b83-4999a5041f25',
              name: 'Chemical Sampling and Analysis of Soils',
              tradeId: '02 24 23',
            },
            {
              id: '56c07fa0-86e7-4bc4-bb0c-638e3c429fa9',
              name: 'Transboundary and Global Environmental Aspects Assessment',
              tradeId: '02 24 43',
            },
          ],
          tradeId: '02 24 00',
        },
        {
          id: '0c0d030d-163b-427a-bccc-c1d66e1419bd',
          name: 'Existing Material Assessment',
          children: [
            {
              id: 'b3c69e98-9879-49bb-9ed6-1628e9629d71',
              name: 'Existing Material Assessment',
              tradeId: '02 25 00',
            },
            {
              id: '21e4343b-875c-4699-8e67-d1a1a9dec001',
              name: 'Existing Concrete Assessment',
              tradeId: '02 25 16',
            },
            {
              id: 'e93ed749-1452-4138-84ec-578615ee1249',
              name: 'Concrete Assessment Drilling',
              tradeId: '02 25 16.13',
            },
            {
              id: '1cffd179-300e-4cdb-a863-c5597a0a88b4',
              name: 'Existing Masonry Assessment',
              tradeId: '02 25 19',
            },
            {
              id: '960e600d-3ade-44fc-a820-de2b246fb4c5',
              name: 'Masonry Assessment Drilling',
              tradeId: '02 25 19.13',
            },
            {
              id: '68550b6d-0467-4239-adfa-325cc8f0bc4c',
              name: 'Existing Metals Assessment',
              tradeId: '02 25 23',
            },
            {
              id: 'cbfc8738-0502-4111-be91-be8f3364b96c',
              name: 'Welding Investigations',
              tradeId: '02 25 23.13',
            },
            {
              id: '2f5c12e8-add9-4c7d-9184-3e21b55bfd44',
              name: 'Existing Wood, Plastics and Composites Assessment',
              tradeId: '02 25 26',
            },
            {
              id: '06056c06-b006-45c1-9ca5-ea7b3f67593c',
              name: 'Existing Thermal and Moisture Protection Assessment',
              tradeId: '02 25 29',
            },
            {
              id: '7629de64-df9f-4ce2-a362-507937e098a2',
              name: 'Waterproofing Investigations',
              tradeId: '02 25 29.13',
            },
            {
              id: '4ab02948-d85d-447c-b250-a19fc0326611',
              name: 'Roofing Investigations',
              tradeId: '02 25 29.23',
            },
          ],
          tradeId: '02 25 00',
        },
        {
          id: 'c3b41b93-2e47-4a27-95c0-0947c2111beb',
          name: 'Hazardous Material Assessment',
          children: [
            {
              id: '27e2afba-21c9-42e0-ba05-6ca3acd79620',
              name: 'Hazardous Material Assessment',
              tradeId: '02 26 00',
            },
            {
              id: '4fda42fd-6bbb-4fb7-8143-ad7063462aab',
              name: 'Asbestos Assessment',
              tradeId: '02 26 23',
            },
            {
              id: 'c25ceee4-0352-404f-8b62-3bf7b9b4975b',
              name: 'Lead Assessment',
              tradeId: '02 26 26',
            },
            {
              id: 'bd0bff9a-6d5e-4c23-a2ce-bdca7f87336a',
              name: 'Polychlorinate Biphenyl Assessment',
              tradeId: '02 26 29',
            },
            {
              id: 'a2dee5e8-64e2-431c-bfdc-c8a73b4b31bf',
              name: 'Biological Assessment',
              tradeId: '02 26 33',
            },
            {
              id: 'ed866792-88cf-48aa-8038-13f998ea44c7',
              name: 'Mold Assessment',
              tradeId: '02 26 33.13',
            },
            {
              id: '3a14bef2-f00f-4b73-bf77-0b5c40e63287',
              name: 'Hazardous Waste Drum Assessment',
              tradeId: '02 26 36',
            },
          ],
          tradeId: '02 26 00',
        },
        {
          id: '4e2d14d8-9994-4793-b38d-050758fad28b',
          name: 'Subsurface Investigation',
          children: [],
          tradeId: '02 30 00',
        },
        {
          id: 'ced234f6-e16d-4cb6-86d9-ecca21916c61',
          name: 'Geophysical Investigations',
          children: [
            {
              id: '01fea69f-5188-4eb2-992c-d293f32e7c24',
              name: 'Geophysical Investigations',
              tradeId: '02 31 00',
            },
            {
              id: '44e3eaaa-7035-4b3f-8a54-520554b60d67',
              name: 'Seismic Investigations',
              tradeId: '02 31 13',
            },
            {
              id: '1133f8f4-6a4d-4677-aab1-e6aac8649340',
              name: 'Gravity Investigations',
              tradeId: '02 31 16',
            },
            {
              id: '85b88e4c-5b52-4d61-b253-c6c60b7a8290',
              name: 'Magnetic Investigations',
              tradeId: '02 31 19',
            },
            {
              id: 'e105f634-ee86-4ef8-8005-e45d14c14f7c',
              name: 'Electromagnetic Investigations',
              tradeId: '02 31 23',
            },
            {
              id: 'eeea3b8e-c8fe-445a-9628-339b76174472',
              name: 'Electrical Resistivity Investigations',
              tradeId: '02 31 26',
            },
            {
              id: '413c2d6c-ad62-440e-9b9c-ffb2e039bbf9',
              name: 'Magnetotelluric Investigations',
              tradeId: '02 31 29',
            },
          ],
          tradeId: '02 31 00',
        },
        {
          id: '57185f45-5eb5-40a2-9f9e-d32e8ad65b09',
          name: 'Geotechnical Investigations',
          children: [
            {
              id: 'e948f33c-2ec0-4d1c-9cde-fdd633acba82',
              name: 'Geotechnical Investigations',
              tradeId: '02 32 00',
            },
            {
              id: '5410794d-bacc-4566-97c5-d432dfbdf80e',
              name: 'Subsurface Drilling and Sampling',
              tradeId: '02 32 13',
            },
            {
              id: '85959e02-57c2-43e4-ba77-a579de6355f5',
              name: 'Material Testing',
              tradeId: '02 32 16',
            },
            {
              id: '8c32b844-5f05-441b-aebc-3dfd0b8dd53c',
              name: 'Exploratory Excavations',
              tradeId: '02 32 19',
            },
            {
              id: 'e8539e9a-e825-42ec-a1c9-e535ed8e43ae',
              name: 'Geotechnical Monitoring Before Construction',
              tradeId: '02 32 23',
            },
            {
              id: 'f8717634-7148-4b5b-903f-32d22402cbeb',
              name: 'Groundwater Monitoring Before Construction',
              tradeId: '02 32 23.13',
            },
          ],
          tradeId: '02 32 00',
        },
        {
          id: '646ce098-20bc-4856-a2d4-87299014bf45',
          name: 'Demolition and Structure Moving',
          children: [],
          tradeId: '02 40 00',
        },
        {
          id: 'f4431e0b-d18d-4949-97bb-7c9472633a4c',
          name: 'Demolition',
          children: [
            {
              id: 'b650ae37-c451-40a0-af49-7b70cb694e25',
              name: 'Demolition',
              tradeId: '02 41 00',
            },
            {
              id: '859645d7-6dd3-4a77-8630-47147dac1604',
              name: 'Selective Site Demolition',
              tradeId: '02 41 13',
            },
            {
              id: '99d04151-7bfe-4537-b061-23901e19aa97',
              name: 'Paving Removal',
              tradeId: '02 41 13.13',
            },
            {
              id: 'e6c2b150-44fd-41c3-ae19-d56152f7556b',
              name: 'Utility Line Removal',
              tradeId: '02 41 13.23',
            },
            {
              id: '1d981ed6-0806-44de-bec2-d9bac1083ec4',
              name: 'Railtrack Removal',
              tradeId: '02 41 13.33',
            },
            {
              id: '7e94f128-7962-4aee-8dcf-857bdc12b84c',
              name: 'Structure Demolition',
              tradeId: '02 41 16',
            },
            {
              id: '579d7249-a577-46e8-803a-e2100b86982c',
              name: 'Building Demolition',
              tradeId: '02 41 16.13',
            },
            {
              id: 'fb900fd9-f3de-4646-b9bb-8b6afeef393f',
              name: 'Tower Demolition',
              tradeId: '02 41 16.23',
            },
            {
              id: '08ae8052-b00a-4ce2-b2e5-91d112e8cbd0',
              name: 'Bridge Demolition',
              tradeId: '02 41 16.33',
            },
            {
              id: '86bf66aa-589f-4b59-89f1-11912d0ed19e',
              name: 'Dam Demolition',
              tradeId: '02 41 16.43',
            },
            {
              id: '5d054946-cba8-4120-a827-81f6a1933fdd',
              name: 'Selective Demolition',
              tradeId: '02 41 19',
            },
            {
              id: '19c23cdb-0cd9-4f18-8133-0d166eca4c8b',
              name: 'Selective Building Demolition',
              tradeId: '02 41 19.13',
            },
            {
              id: '01c4dc47-140b-48c8-8773-ec40625bda16',
              name: 'Selective Interior Demolition',
              tradeId: '02 41 19.16',
            },
            {
              id: '255fe5c8-316c-4668-a477-a8da60caa4c6',
              name: 'Selective Facility Services Demolition',
              tradeId: '02 41 19.19',
            },
            {
              id: '55da2d89-e9dc-4596-95c5-3a55c3cfd101',
              name: 'Selective Bridge Demolition',
              tradeId: '02 41 19.33',
            },
            {
              id: '570e5a8c-c45a-4cf1-a04a-b3ec60df4754',
              name: 'Selective Historic Demolition',
              tradeId: '02 41 91',
            },
          ],
          tradeId: '02 41 00',
        },
        {
          id: '9128db79-839e-47d1-973c-f10385de05f5',
          name: 'Removal and Salvage of Construction Materials',
          children: [
            {
              id: '377d4b21-8a36-4b38-83d4-64b9dd88bc54',
              name: 'Removal and Salvage of Construction Materials',
              tradeId: '02 42 00',
            },
            {
              id: '1742e050-096f-488f-9407-7e91fe352622',
              name: 'Deconstruction of Structures',
              tradeId: '02 42 13',
            },
            {
              id: '14b822c5-4c9b-4c8e-98e0-54210b62cdbb',
              name: 'Deconstruction of Buildings',
              tradeId: '02 42 13.13',
            },
            {
              id: '50f3a48a-e4a5-4049-879e-5c55f55ec760',
              name: 'Removal and Salvage of Historic Construction Materials',
              tradeId: '02 42 91',
            },
          ],
          tradeId: '02 42 00',
        },
        {
          id: 'fa9b9f44-d728-47b4-b0da-f5b163f7edf8',
          name: 'Structure Moving',
          children: [
            {
              id: 'd645b084-f2a6-498e-ae54-2e33dbed61ca',
              name: 'Structure Moving',
              tradeId: '02 43 00',
            },
            {
              id: '056b82e8-59f1-4367-9a46-0a9e6a503ad7',
              name: 'Structure Relocation',
              tradeId: '02 43 13',
            },
            {
              id: '31d8dcff-e297-4a58-81c8-0f6784d5c49a',
              name: 'Building Relocation',
              tradeId: '02 43 13.13',
            },
            {
              id: '83e90669-db56-4d12-8748-3e75c47e86d6',
              name: 'Structure Raising',
              tradeId: '02 43 16',
            },
            {
              id: '851ac028-21de-4394-98da-4f00256b596d',
              name: 'Building Raising',
              tradeId: '02 43 16.13',
            },
          ],
          tradeId: '02 43 00',
        },
        {
          id: '30772469-affa-4942-9475-d5da5576550b',
          name: 'Site Remediation',
          children: [],
          tradeId: '02 50 00',
        },
        {
          id: 'c2b4c626-bfa8-45e5-b46b-0172b7b1517e',
          name: 'Physical Decontamination',
          children: [
            {
              id: '5840c527-98be-4541-bb40-b798c633f2ef',
              name: 'Physical Decontamination',
              tradeId: '02 51 00',
            },
            {
              id: '710cb56b-f262-49f6-a30a-5eb604427e8d',
              name: 'Coagulation and Flocculation Decontamination',
              tradeId: '02 51 13',
            },
            {
              id: 'fdba2305-6263-4130-bb21-3d90d2445c9f',
              name: 'Reverse-Osmosis Decontamination',
              tradeId: '02 51 16',
            },
            {
              id: 'af0c31ee-6c89-468f-b3ef-2c72e67abe56',
              name: 'Solidification and Stabilization Decontamination',
              tradeId: '02 51 19',
            },
            {
              id: '347811be-e566-4b86-aeff-3d5c12327cd5',
              name: 'Mechanical Filtration Decontamination',
              tradeId: '02 51 23',
            },
            {
              id: '543c5379-827a-4bad-8c9c-b476a444e51e',
              name: 'Radioactive Decontamination',
              tradeId: '02 51 26',
            },
            {
              id: '40e1f2f8-b636-4c1b-9f10-df3bbf6072f7',
              name: 'Surface Cleaning Decontamination',
              tradeId: '02 51 29',
            },
            {
              id: 'cfc8ebed-c97f-4924-bf25-23db55b0788e',
              name: 'High-Pressure Water Cleaning Decontamination',
              tradeId: '02 51 29.13',
            },
            {
              id: '246e1087-1f78-44fb-83b4-63fed320bdaa',
              name: 'Vacuum Sweeping Cleaning Decontamination',
              tradeId: '02 51 29.16',
            },
            {
              id: 'aeef98ae-4bcc-4bd3-926f-40bf3d5348bf',
              name: 'Surface Removal Decontamination',
              tradeId: '02 51 33',
            },
            {
              id: 'df29cb33-1d0f-4b78-bcbc-07f51e0924ce',
              name: 'Surface Removal Decontamination by Grinding',
              tradeId: '02 51 33.13',
            },
            {
              id: 'a6ef61ad-ad5f-4fa9-93d4-90fa0bcde96e',
              name: 'Surface Removal Decontamination by Sand Blasting',
              tradeId: '02 51 33.16',
            },
            {
              id: '288af719-87aa-4cf8-9bed-4f9e05330ae4',
              name: 'Surface Removal Decontamination by Ultrasound',
              tradeId: '02 51 33.19',
            },
          ],
          tradeId: '02 51 00',
        },
        {
          id: 'dfe89e2d-f61b-4de1-8ea1-dd674c134db3',
          name: 'Chemical Decontamination',
          children: [
            {
              id: 'faaebbc9-1fe7-4283-8c9b-4abe4ccd3da2',
              name: 'Chemical Decontamination',
              tradeId: '02 52 00',
            },
            {
              id: 'c8396d95-a3c7-4da6-8141-410c612ae9c1',
              name: 'Chemical Precipitation Decontamination',
              tradeId: '02 52 13',
            },
            {
              id: 'aa86a0ba-d700-4b21-b86c-c5ebca5a5167',
              name: 'Ion Change Decontamination',
              tradeId: '02 52 16',
            },
            {
              id: 'a186dbc8-6762-408e-abc0-e3cd6fb6d668',
              name: 'Neutralization Decontamination',
              tradeId: '02 52 19',
            },
          ],
          tradeId: '02 52 00',
        },
        {
          id: 'df902938-2287-4db2-a1ec-4d66cff92c5c',
          name: 'Thermal Decontamination',
          children: [
            {
              id: 'ac0de805-ed3e-4fb4-b2a9-975c58fd4e3c',
              name: 'Thermal Decontamination',
              tradeId: '02 53 00',
            },
            {
              id: '870a7a50-4cab-45e0-bf09-d80a53335937',
              name: 'Incineration Decontamination',
              tradeId: '02 53 13',
            },
            {
              id: 'f48339f0-4a89-4a00-b191-e4330ccf081f',
              name: 'Remediation of Contaminated Soils and Sludges by Incineration',
              tradeId: '02 53 13.13',
            },
            {
              id: 'a324335b-fa1e-42db-bd80-f55bb61141e4',
              name: 'Thermal Desorption Decontamination',
              tradeId: '02 53 16',
            },
            {
              id: 'a455ab12-02a0-4bba-95f1-a2838c8f2bb9',
              name: 'Remediation of Contaminated Soils by Thermal Desorption',
              tradeId: '02 53 16.13',
            },
            {
              id: '60288a56-f6fb-413f-85cd-3ed40b61a3f7',
              name: 'Vitrification Decontamination',
              tradeId: '02 53 19',
            },
          ],
          tradeId: '02 53 00',
        },
        {
          id: 'bc77cd56-9c57-40f5-ab6b-b60f03a2b28c',
          name: 'Biological Decontamination',
          children: [
            {
              id: 'a6d23523-c4e5-4b78-acd7-07ef15056388',
              name: 'Biological Decontamination',
              tradeId: '02 54 00',
            },
            {
              id: '89a0ff2c-2aa0-4c6a-817c-edb84d8df158',
              name: 'Aerobic Processes Decontamination',
              tradeId: '02 54 13',
            },
            {
              id: '8181c354-5ebf-443e-a10b-3d202b630b65',
              name: 'Anaerobic Processes Decontamination',
              tradeId: '02 54 16',
            },
            {
              id: '134f65dc-6788-4a90-8581-8252de194b79',
              name: 'Bioremediation Decontamination',
              tradeId: '02 54 19',
            },
            {
              id: 'dcfc4636-e88a-48f9-b692-3573404f9fe6',
              name: 'Bioremediation Using Landfarming',
              tradeId: '02 54 19.13',
            },
            {
              id: '081edca4-28d0-487b-a369-fa127346f145',
              name: 'Bioremediation of Soils Using Windrow Composting',
              tradeId: '02 54 19.16',
            },
            {
              id: '8e60662a-e772-44cb-aa39-89aec2ebfda4',
              name: 'Bioremediation Using Bacteria Injection',
              tradeId: '02 54 19.19',
            },
            {
              id: '88457c98-be43-48ab-a574-375e02a6bd21',
              name: 'Soil Washing through Separation/Solubilization',
              tradeId: '02 54 23',
            },
            {
              id: 'b320f72d-87e2-413a-8a17-726605ac4b4d',
              name: 'Organic Decontamination',
              tradeId: '02 54 26',
            },
          ],
          tradeId: '02 54 00',
        },
        {
          id: 'c993ca9f-a938-4a43-a377-68d449fd3de4',
          name: 'Remediation Soil Stabilization',
          children: [],
          tradeId: '02 55 00',
        },
        {
          id: '109e300e-4308-4404-b64d-7027a8160db2',
          name: 'Site Containment',
          children: [
            {
              id: '08062798-7163-467b-9140-2385c6df1817',
              name: 'Site Containment',
              tradeId: '02 56 00',
            },
            {
              id: '0acaae35-fd3c-4c8d-bc64-10c03c19120f',
              name: 'Waste Containment',
              tradeId: '02 56 13',
            },
            {
              id: '85f189b9-0cfd-4902-bbe8-d07db0c3c966',
              name: 'Geomembrane Waste Containment',
              tradeId: '02 56 13.13',
            },
            {
              id: '893a1033-0371-4599-9d6b-794532a725dc',
              name: 'Gas Containment',
              tradeId: '02 56 19',
            },
            {
              id: 'd11049ca-709b-43c2-b61f-9ce978d1c4f4',
              name: 'Fluid-Applied Gas Barrier',
              tradeId: '02 56 19.13',
            },
          ],
          tradeId: '02 56 00',
        },
        {
          id: '134fcc0c-8227-400b-9a6f-e6d40ee88c47',
          name: 'Sinkhole Remediation',
          children: [
            {
              id: '9e9957cd-ba81-4d0e-9958-cc55adb877c3',
              name: 'Sinkhole Remediation',
              tradeId: '02 57 00',
            },
            {
              id: 'ed96a842-bafc-4a67-adf8-79d478b90bc2',
              name: 'Sinkhole Remediation by Grouting',
              tradeId: '02 57 13',
            },
            {
              id: 'e114f53a-3c54-4fb2-adb7-144d25c9747c',
              name: 'Sinkhole Remediation by Compaction Grouting',
              tradeId: '02 57 13.13',
            },
            {
              id: 'bf98fb2c-4b67-4e17-9929-abb5b406f047',
              name: 'Sinkhole Remediation by Cap Grouting',
              tradeId: '02 57 13.16',
            },
            {
              id: 'fc29acde-5c96-4c10-b9f6-fd1653c00046',
              name: 'Sinkhole Remediation by Backfilling',
              tradeId: '02 57 16',
            },
          ],
          tradeId: '02 57 00',
        },
        {
          id: '7ee8954f-95a5-4f74-b61b-0b4ea3839194',
          name: 'Snow Control',
          children: [
            {
              id: 'c9580f01-de80-4f32-9877-0312e7955981',
              name: 'Snow Control',
              tradeId: '02 58 00',
            },
            {
              id: '70b9bafe-b779-4c95-973d-290b7dfe4f4c',
              name: 'Snow Fencing',
              tradeId: '02 58 13',
            },
            {
              id: '36d75877-3d36-4ae6-91bd-4be4a058e7e8',
              name: 'Snow Avalanche Control',
              tradeId: '02 58 16',
            },
          ],
          tradeId: '02 58 00',
        },
        {
          id: '8a7bdb7b-f0be-415a-8fc6-5f211894384e',
          name: 'Contaminated Site Material Removal',
          children: [],
          tradeId: '02 60 00',
        },
        {
          id: 'eccfeb6c-13bf-47fd-922e-ba22757e8172',
          name: 'Removal and Disposal of Contaminated Soils',
          children: [
            {
              id: 'f2903652-0b2b-4211-933b-4604f16b67d1',
              name: 'Removal and Disposal of Contaminated Soils',
              tradeId: '02 61 00',
            },
            {
              id: '05f05cb3-d3cc-4af3-936b-dc3b9411c575',
              name: 'Excavation and Handling of Contaminated Material',
              tradeId: '02 61 13',
            },
            {
              id: '944f0454-cd17-4ec7-bc61-e0c5d9b2d8ed',
              name: 'Removal and Disposal of Polychlorinate Biphenyl Contaminated Soils',
              tradeId: '02 61 23',
            },
            {
              id: '8e23cd06-8e77-4454-9793-bf2b8770ca65',
              name: 'Removal and Disposal of Asbestos Contaminated Soils',
              tradeId: '02 61 26',
            },
            {
              id: 'fda31acf-ba9a-4697-b2fa-354c9cbcc92a',
              name: 'Removal and Disposal of Organically Contaminated Soils',
              tradeId: '02 61 29',
            },
          ],
          tradeId: '02 61 00',
        },
        {
          id: '9e021bb1-b5a4-47fc-9690-830424244369',
          name: 'Hazardous Waste Recovery Processes',
          children: [
            {
              id: 'f7bd0ed1-cd47-4872-a332-d4e943a03067',
              name: 'Hazardous Waste Recovery Processes',
              tradeId: '02 62 00',
            },
            {
              id: '9b73674a-31c0-4959-a46e-67cf5b2a84a7',
              name: 'Air and Steam Stripping',
              tradeId: '02 62 13',
            },
            {
              id: '0b886821-bbe5-47c1-b415-c4da7ad5a389',
              name: 'Soil Vapor Extraction',
              tradeId: '02 62 16',
            },
            {
              id: '76d09b44-1566-4efb-b0c9-2c111a092ceb',
              name: 'Soil Washing and Flushing',
              tradeId: '02 62 19',
            },
          ],
          tradeId: '02 62 00',
        },
        {
          id: '06dd9acb-4d50-4725-b233-79f433c1bde9',
          name: 'Underground Storage Tank Removal',
          children: [],
          tradeId: '02 65 00',
        },
        {
          id: 'd7a16dcc-39a7-46d3-950f-731d1276cf1e',
          name: 'Landfill Construction and Storage',
          children: [],
          tradeId: '02 66 00',
        },
        {
          id: '903575fc-a98a-4460-a617-7353b932daaf',
          name: 'Water Remediation',
          children: [],
          tradeId: '02 70 00',
        },
        {
          id: '65fdb8fd-f068-48c4-8d54-23ff90ffea9c',
          name: 'Groundwater Treatment',
          children: [],
          tradeId: '02 71 00',
        },
        {
          id: 'b4735162-0d6d-4d9c-af1e-b13943906ed2',
          name: 'Water Decontamination',
          children: [
            {
              id: '103d0670-785f-4a1a-9245-035a977d5d6a',
              name: 'Water Decontamination',
              tradeId: '02 72 00',
            },
            {
              id: '971a5e3c-48f2-4989-af91-09806573ecde',
              name: 'Chemical Water Decontamination',
              tradeId: '02 72 13',
            },
            {
              id: '19098420-4b68-4b12-a953-9dd94e9cb598',
              name: 'Biological Water Decontamination',
              tradeId: '02 72 16',
            },
            {
              id: '1fd73dd5-035d-4b5f-90af-48d2a7e78d58',
              name: 'Electrolysis Water Decontamination',
              tradeId: '02 72 19',
            },
          ],
          tradeId: '02 72 00',
        },
        {
          id: 'b7677f07-70e0-4666-8f2c-fad78eafaeb8',
          name: 'Facility Remediation',
          children: [],
          tradeId: '02 80 00',
        },
        {
          id: '31998535-3d5c-4953-b8f6-67d21d775b03',
          name: 'Transportation and Disposal of Hazardous Materials',
          children: [],
          tradeId: '02 81 00',
        },
        {
          id: '8ac40a4f-c674-4718-8e97-7aa46159b70a',
          name: 'Asbestos Remediation',
          children: [
            {
              id: '4de39dde-a96b-4b20-9cac-c2a69b7294b2',
              name: 'Asbestos Remediation',
              tradeId: '02 82 00',
            },
            {
              id: '4e7c0585-e24d-4479-941e-bc06d99dbb61',
              name: 'Asbestos Abatement',
              tradeId: '02 82 13',
            },
            {
              id: 'dd3bc167-4cb1-4e0e-ba95-ce2a762da384',
              name: 'Glovebag Asbestos Abatement',
              tradeId: '02 82 13.13',
            },
            {
              id: '1c40161d-8cd8-4803-bf89-c33a3320b928',
              name: 'Precautions for Asbestos Abatement',
              tradeId: '02 82 13.16',
            },
            {
              id: 'd838983c-16d8-4f09-89b5-753607980869',
              name: 'Asbestos Floor Tile and Mastic Abatement',
              tradeId: '02 82 13.19',
            },
            {
              id: '50ab8986-2902-4190-bb71-03d99ae6d310',
              name: 'Engineering Control of Asbestos Containing Materials',
              tradeId: '02 82 16',
            },
            {
              id: 'a937d837-ce52-418b-a1ab-74dc20b108e8',
              name: 'Removal and Disposal of Asbestos Containing Materials',
              tradeId: '02 82 33',
            },
          ],
          tradeId: '02 82 00',
        },
        {
          id: '7ef609f6-5080-40c3-a90a-5e8bdd70d646',
          name: 'Lead Remediation',
          children: [
            {
              id: '2b0c082f-fa09-468c-8dff-c3b4b9a24b53',
              name: 'Lead Remediation',
              tradeId: '02 83 00',
            },
            {
              id: 'da01049d-dc0a-4772-8e55-efe0f9330129',
              name: 'Lead Hazard Control Activities',
              tradeId: '02 83 13',
            },
            {
              id: '451b655f-5c4c-4ab8-88a4-d2b7908dfcf6',
              name: 'Lead-Based Paint Remediation',
              tradeId: '02 83 19',
            },
            {
              id: '24e4831c-2ebd-4fbe-a2a1-608289344f41',
              name: 'Lead-Based Paint Abatement',
              tradeId: '02 83 19.13',
            },
            {
              id: 'f2574b87-f1e1-4a05-aca2-fc20cc7253fa',
              name: 'Removal and Disposal of Material Containing Lead',
              tradeId: '02 83 33',
            },
            {
              id: '6c5bb86d-10cc-4e3a-9464-783ff31e271f',
              name: 'Lead-Based Paint Removal and Disposal',
              tradeId: '02 83 33.13',
            },
          ],
          tradeId: '02 83 00',
        },
        {
          id: '0d4af3b9-7b74-4409-90bc-ee8398f71efb',
          name: 'Polychlorinate Biphenyl Remediation',
          children: [
            {
              id: '0363891d-13b0-49a2-a274-62cea5ef9c57',
              name: 'Polychlorinate Biphenyl Remediation',
              tradeId: '02 84 00',
            },
            {
              id: '1f8dcaa5-af4a-40b4-9bdd-b76bd5c2a3d7',
              name: 'Handling of Lighting Ballasts and Lamps Containing PCBs and Mercury',
              tradeId: '02 84 16',
            },
            {
              id: '8600aa0a-82bc-48e6-9ccb-855dc13efd28',
              name: 'Removal and Disposal of Polychlorinate Biphenyls',
              tradeId: '02 84 33',
            },
          ],
          tradeId: '02 84 00',
        },
        {
          id: '4ca632ee-ff28-483f-9f1c-53bf0c87ec1d',
          name: 'Mold Remediation',
          children: [
            {
              id: '7daf67a5-6f84-4455-a84b-c4c8cd4e3e32',
              name: 'Mold Remediation',
              tradeId: '02 85 00',
            },
            {
              id: '22f51fff-aedb-4c8e-9728-e951bb551721',
              name: 'Precautions for Mold Remediation',
              tradeId: '02 85 13',
            },
            {
              id: 'db3da855-9139-4e49-a962-2f6e1dc9a8c4',
              name: 'Mold Remediation Preparation and Containment',
              tradeId: '02 85 16',
            },
            {
              id: '75b1a7a7-2454-4576-99de-25b44576db47',
              name: 'Mold Remediation Clearance Air Sampling',
              tradeId: '02 85 19',
            },
            {
              id: '80a6342a-34fa-454e-9704-c6a02ffede2a',
              name: 'Removal and Disposal of Materials with Mold',
              tradeId: '02 85 33',
            },
          ],
          tradeId: '02 85 00',
        },
        {
          id: 'f94c02a5-88f9-4356-9ae0-efea91b7de38',
          name: 'Hazardous Waste Drum Handling',
          children: [],
          tradeId: '02 86 00',
        },
      ],
      tradeId: '02 00 00',
    },
    {
      id: '0a516d8e-3596-4e3c-8ed2-b27a3134646f',
      name: 'Concrete',
      children: [
        {
          id: '9f8a0b26-e5a1-47cd-b81d-0863aa0d0fa2',
          name: 'Concrete',
          children: [],
          tradeId: '03 00 00',
        },
        {
          id: '52c3e58f-56bf-4ab9-b87b-7869b4d337ec',
          name: 'Maintenance of Concrete',
          children: [
            {
              id: '3a93064e-413c-4db3-84d4-138c35a1706a',
              name: 'Maintenance of Concrete',
              tradeId: '03 01 00',
            },
            {
              id: '7d3ade44-58ff-4b3b-bdea-eb5fd4bedd15',
              name: 'Maintenance of Concrete Forming and Accessories',
              tradeId: '03 01 10',
            },
            {
              id: '9b544045-7239-41a2-85e6-c8f029005b11',
              name: 'Maintenance of Concrete Reinforcing',
              tradeId: '03 01 20',
            },
            {
              id: '8d28016d-6e36-4d41-8fba-94169d9ff402',
              name: 'Maintenance of Stressing Tendons',
              tradeId: '03 01 23',
            },
            {
              id: '329cf236-3ae0-44ea-b768-4b24a6d96a1c',
              name: 'Maintenance of Cast-in-Place Concrete',
              tradeId: '03 01 30',
            },
            {
              id: '672a91f7-715a-4c05-aa29-9e1121fcd867',
              name: 'Cleaning of Cast-in-Place Concrete',
              tradeId: '03 01 30.51',
            },
            {
              id: '3caf9723-1543-48b8-a757-fec98f769a15',
              name: 'Resurfacing of Cast-in-Place Concrete',
              tradeId: '03 01 30.61',
            },
            {
              id: 'c577a40f-edfd-4d22-a958-ac8a186eed4b',
              name: 'Rehabilitation of Cast-in-Place Concrete',
              tradeId: '03 01 30.71',
            },
            {
              id: 'f535e9ae-38cd-4754-941c-2671fd186b08',
              name: 'Strengthening of Cast-in-Place Concrete',
              tradeId: '03 01 30.72',
            },
            {
              id: '01d413b9-aadd-4768-8ac3-213ae3ef0583',
              name: 'Maintenance of Precast Concrete',
              tradeId: '03 01 40',
            },
            {
              id: 'a75fdf7b-e3c0-4851-9c6f-7e89625bb094',
              name: 'Cleaning of Precast Concrete',
              tradeId: '03 01 40.51',
            },
            {
              id: 'cce91e76-d746-4b31-91e3-aba89e6174c9',
              name: 'Resurfacing of Precast Concrete',
              tradeId: '03 01 40.61',
            },
            {
              id: '3167462e-e14c-4819-ad7c-4cd3787d324e',
              name: 'Rehabilitation of Precast Concrete',
              tradeId: '03 01 40.71',
            },
            {
              id: '628dcf7b-c2cb-422e-ae37-faa387f6a1ee',
              name: 'Strengthening of Precast Concrete',
              tradeId: '03 01 40.72',
            },
            {
              id: '9f5167a9-4c6e-46ac-a0cf-3e7b79656fe5',
              name: 'Maintenance of Cast Decks and Underlayment',
              tradeId: '03 01 50',
            },
            {
              id: 'ecebbff1-23cf-4855-b37d-60c11529274b',
              name: 'Cleaning Cast Decks and Underlayment',
              tradeId: '03 01 50.51',
            },
            {
              id: '126af94c-5a50-43ea-90d4-5d069721fc2e',
              name: 'Resurfacing of Cast Decks and Underlayment',
              tradeId: '03 01 50.61',
            },
            {
              id: '3530edfb-1454-4337-ba3c-6c2b4fe7d306',
              name: 'Rehabilitation of Cast Decks and Underlayment',
              tradeId: '03 01 50.71',
            },
            {
              id: '6c03c56a-6481-4730-9f84-06024ccb4ed7',
              name: 'Strengthening of Cast Decks and Underlayment',
              tradeId: '03 01 50.72',
            },
            {
              id: 'ebce2bc1-a66b-423f-81cd-db65ee2e0419',
              name: 'Maintenance of Grouting',
              tradeId: '03 01 60',
            },
            {
              id: '63fa92d5-cb49-4e39-b016-43042e0fb48d',
              name: 'Maintenance of Mass Concrete',
              tradeId: '03 01 70',
            },
            {
              id: '8259c657-d985-4733-b1a2-995da20a0429',
              name: 'Maintenance of Concrete Cutting and Boring',
              tradeId: '03 01 80',
            },
          ],
          tradeId: '03 01 00',
        },
        {
          id: 'b5fe4c03-05c1-49e1-a516-7c6c05088008',
          name: 'Common Work Results for Concrete',
          children: [],
          tradeId: '03 05 00',
        },
        {
          id: 'a273e5b3-6888-4af1-b4ef-1d2bfc22a2ef',
          name: 'Schedules for Concrete',
          children: [
            {
              id: '422f3ee4-a269-46ed-901a-9ebb6f389e6b',
              name: 'Schedules for Concrete',
              tradeId: '03 06 00',
            },
            {
              id: 'ffce1a2b-e03a-40dc-89ba-23dcdcd68d7a',
              name: 'Schedules for Concrete Forming and Accessories',
              tradeId: '03 06 10',
            },
            {
              id: '45d4fda7-425f-41cc-a417-5baca6eb31ae',
              name: 'Schedules for Concrete Reinforcing',
              tradeId: '03 06 20',
            },
            {
              id: 'b11301e1-84f8-476a-8b21-3da65818abc4',
              name: 'Concrete Beam Reinforcing Schedule',
              tradeId: '03 06 20.13',
            },
            {
              id: '5fc43928-3c01-4b7c-a4b0-b6694e36c9d0',
              name: 'Concrete Slab Reinforcing Schedule',
              tradeId: '03 06 20.16',
            },
            {
              id: 'ce979814-64e7-427a-8332-8bf53576d0c3',
              name: 'Schedules for Cast-in-Place Concrete',
              tradeId: '03 06 30',
            },
            {
              id: 'c75ec281-92e5-43b1-aa0e-eda4c740be5c',
              name: 'Concrete Footing Schedule',
              tradeId: '03 06 30.13',
            },
            {
              id: '2a026efb-3070-4eb7-b2e6-867eebd76db3',
              name: 'Concrete Column Schedule',
              tradeId: '03 06 30.16',
            },
            {
              id: '8540a8fb-bbb2-4d35-bf29-4441a57f0fff',
              name: 'Concrete Slab Schedule',
              tradeId: '03 06 30.19',
            },
            {
              id: '201efebf-4fb8-4837-8157-2772ac7d6f80',
              name: 'Concrete Shaft Schedule',
              tradeId: '03 06 30.23',
            },
            {
              id: '346385e8-788a-4fdc-ba10-6b87c344fe54',
              name: 'Concrete Beam Schedule',
              tradeId: '03 06 30.26',
            },
            {
              id: '96fbcf52-5b57-4291-938e-eee26abdcc9f',
              name: 'Schedules for Precast Concrete',
              tradeId: '03 06 40',
            },
            {
              id: 'b656edf9-bd1a-4ade-a8f4-9e84d90b49fe',
              name: 'Precast Concrete Panel Schedule',
              tradeId: '03 06 40.13',
            },
            {
              id: '23ad3cb5-44d3-4cec-91b5-0364eb25c5bc',
              name: 'Schedules for Cast Decks and Underlayment',
              tradeId: '03 06 50',
            },
            {
              id: '4846fab5-1273-4baa-85a1-2ac4317d4426',
              name: 'Schedules for Grouting',
              tradeId: '03 06 60',
            },
            {
              id: 'f780cac0-8e5a-49ef-89a2-90760c741080',
              name: 'Schedules for Mass Concrete',
              tradeId: '03 06 70',
            },
            {
              id: '586e37d3-c78f-4d07-9a50-bd5b0f85bea7',
              name: 'Schedules for Concrete Cutting and Boring',
              tradeId: '03 06 80',
            },
          ],
          tradeId: '03 06 00',
        },
        {
          id: '55f64314-5328-431f-8453-ec783ff1694b',
          name: 'Commissioning of Concrete',
          children: [],
          tradeId: '03 08 00',
        },
        {
          id: 'fe0de81d-510a-4a5e-8311-be7c7278d8e0',
          name: 'Concrete Forming and Accessories',
          children: [],
          tradeId: '03 10 00',
        },
        {
          id: '0ab3d59c-eb5d-4295-a5cc-83c2b7ef1ba1',
          name: 'Concrete Forming',
          children: [
            {
              id: 'ca6d8b05-7d6e-4bac-b55b-840b9ce6fa6d',
              name: 'Concrete Forming',
              tradeId: '03 11 00',
            },
            {
              id: 'f7dda157-36b1-493a-8498-832594399a40',
              name: 'Structural Cast-in-Place Concrete Forming',
              tradeId: '03 11 13',
            },
            {
              id: 'ea9a7b71-56e5-4e4f-86be-5fca07ff7b83',
              name: 'Concrete Slip Forming',
              tradeId: '03 11 13.13',
            },
            {
              id: 'e132cc12-6597-482a-98d3-2742e71141a6',
              name: 'Concrete Shoring',
              tradeId: '03 11 13.16',
            },
            {
              id: '57176c54-80e1-4197-8539-d5262ec0a178',
              name: 'Falsework',
              tradeId: '03 11 13.19',
            },
            {
              id: 'efcb020b-4149-4e58-ae3d-1ada2802562a',
              name: 'Architectural Cast-in Place Concrete Forming',
              tradeId: '03 11 16',
            },
            {
              id: '206a2195-e0db-48c7-8bd5-e96e43b85a00',
              name: 'Concrete Form Liners',
              tradeId: '03 11 16.13',
            },
            {
              id: 'b83d509f-2f6a-41a4-9f9a-9dc9cc279a84',
              name: 'Insulating Concrete Forming',
              tradeId: '03 11 19',
            },
            {
              id: 'fbbe755b-0be9-4354-86de-9dc26a3da087',
              name: 'Permanent Stair Forming',
              tradeId: '03 11 23',
            },
          ],
          tradeId: '03 11 00',
        },
        {
          id: '366c724e-ac51-4d3f-b17e-d665a1b523d2',
          name: 'Concrete Accessories',
          children: [
            {
              id: '334aeebd-883d-4f51-8775-677b4c68827b',
              name: 'Concrete Accessories',
              tradeId: '03 15 00',
            },
            {
              id: 'b3230d8b-42f4-44bc-91e2-984733498b6d',
              name: 'Waterstops',
              tradeId: '03 15 13',
            },
            {
              id: '422506d2-38e9-441b-a01f-e7ef08dfd1cb',
              name: 'Non-Expanding Waterstops',
              tradeId: '03 15 13.13',
            },
            {
              id: '88091ce1-d33c-4a14-9f72-fa46c1100e73',
              name: 'Expanding Waterstops',
              tradeId: '03 15 13.16',
            },
            {
              id: 'c184c4d0-9bc0-4234-b817-8fa3c8d0745f',
              name: 'Combination Expanding and Injection Hose Waterstops',
              tradeId: '03 15 13.19',
            },
            {
              id: 'bea51122-a927-469b-adca-cd1e2d9e9dde',
              name: 'Injection Hose Waterstops',
              tradeId: '03 15 13.21',
            },
            {
              id: '601df01f-c85b-4c6c-a1c7-6e523a660bbb',
              name: 'Concrete Construction Joints',
              tradeId: '03 15 16',
            },
            {
              id: '32cb0401-d79d-4cb3-a084-05b3643eb8e1',
              name: 'Cast-In Concrete Anchors',
              tradeId: '03 15 19',
            },
          ],
          tradeId: '03 15 00',
        },
        {
          id: '7c2564d9-789c-49b3-b897-93ca4d0dfbe6',
          name: 'Concrete Reinforcing',
          children: [],
          tradeId: '03 20 00',
        },
        {
          id: '78d4b305-1de6-4600-9b5f-76fd92661194',
          name: 'Reinforcement  Bars',
          children: [
            {
              id: 'e87d4ada-6b9e-4b68-9f0f-47873c62955b',
              name: 'Reinforcement  Bars',
              tradeId: '03 21 00',
            },
            {
              id: '69609f88-bace-43b0-85da-d7c829134c14',
              name: 'Plain Steel Reinforcement Bars',
              tradeId: '03 21 11',
            },
            {
              id: '7bec5944-1a16-4862-afba-6490dd071455',
              name: 'Galvanized Reinforcement Steel Bars',
              tradeId: '03 21 13',
            },
            {
              id: 'bc9c87ad-dc74-4e0c-8e41-fefeb727b6d8',
              name: 'Epoxy-Coated Reinforcement Steel Bars',
              tradeId: '03 21 16',
            },
            {
              id: '2896d3d7-a144-4edb-98e8-fb87d5ba22ce',
              name: 'Stainless Steel Reinforcement Bars',
              tradeId: '03 21 19',
            },
            {
              id: '16a5e392-b1bb-4f60-a915-3d69ad7466ff',
              name: 'Composite Reinforcement Bars',
              tradeId: '03 21 21',
            },
            {
              id: '6547f070-9111-41f9-905f-57f29451bf86',
              name: 'Glass Fiber-Reinforced Polymer Reinforcement Bars',
              tradeId: '03 21 21.11',
            },
            {
              id: '8503abae-4238-49b4-9534-2be672cea7be',
              name: 'Organic Fiber-Reinforced Polymer Reinforcement Bars',
              tradeId: '03 21 21.13',
            },
            {
              id: 'de14d6f4-3a7f-41ca-94f5-886d758db67d',
              name: 'Carbon Fiber-Reinforced Polymer Reinforcement Bars',
              tradeId: '03 21 21.16',
            },
          ],
          tradeId: '03 21 00',
        },
        {
          id: 'ec534f67-7ca1-438d-b5e1-9e47150b1ea5',
          name: 'Fabric and Grid Reinforcing',
          children: [
            {
              id: '1131138a-19cf-4dc1-a7d1-4b5bf47bf7da',
              name: 'Fabric and Grid Reinforcing',
              tradeId: '03 22 00',
            },
            {
              id: '31a068d2-20e6-412b-8297-4610f80a42c5',
              name: 'Galvanized Welded Wire Fabric Reinforcing',
              tradeId: '03 22 13',
            },
            {
              id: 'c57ab9b0-e14e-43f2-b03a-d4942e61e559',
              name: 'Epoxy-Coated Welded Wire Fabric Reinforcing',
              tradeId: '03 22 16',
            },
            {
              id: '39c91f0a-90d7-4771-8d70-14bc8b829920',
              name: 'Composite Grid Reinforcing',
              tradeId: '03 22 19',
            },
          ],
          tradeId: '03 22 00',
        },
        {
          id: '0fa4a0d8-43ec-483b-89a0-5b893e2c823c',
          name: 'Stressed Tendon Reinforcing',
          children: [],
          tradeId: '03 23 00',
        },
        {
          id: '336728ff-883e-48af-81dc-a3b3041fedff',
          name: 'Fibrous Reinforcing',
          children: [],
          tradeId: '03 24 00',
        },
        {
          id: '1851b87f-4234-4175-8d8e-331d350cc967',
          name: 'Composite Reinforcing',
          children: [
            {
              id: '5b80849c-e5aa-415c-9bad-81c5232b8f88',
              name: 'Composite Reinforcing',
              tradeId: '03 25 00',
            },
            {
              id: '96ee7193-fa1a-4c94-9ea2-429f14c850f9',
              name: 'Glass Fiber-Reinforced Polymer Reinforcing',
              tradeId: '03 25 13',
            },
            {
              id: '9fdeba32-3fa1-4f8c-b539-118bd8977c43',
              name: 'Organic Fiber-Reinforced Polymer Reinforcing',
              tradeId: '03 25 16',
            },
            {
              id: '615cce1c-7ad0-49df-8515-4fc1065ad1dc',
              name: 'Carbon Fiber-Reinforced Polymer Reinforcing',
              tradeId: '03 25 19',
            },
          ],
          tradeId: '03 25 00',
        },
        {
          id: 'eb7ba554-afa9-49ef-83a7-7a4253ebef3b',
          name: 'Cast-in-Place Concrete',
          children: [
            {
              id: 'cfd6cf56-7385-4f67-9efe-6028e2202e7d',
              name: 'Cast-in-Place Concrete',
              tradeId: '03 30 00',
            },
            {
              id: '2b26d203-3ecf-4896-b9ec-0c1e432fac9b',
              name: 'Miscellaneous Cast-in-Place Concrete',
              tradeId: '03 30 53',
            },
          ],
          tradeId: '03 30 00',
        },
        {
          id: 'ce397c4e-edad-4a85-9536-c815d78cc084',
          name: 'Structural Concrete',
          children: [
            {
              id: 'a7fc7a5d-c728-4c51-a707-6492ba82424c',
              name: 'Structural Concrete',
              tradeId: '03 31 00',
            },
            {
              id: '4a148c8a-6de6-4194-a445-e037bce44da3',
              name: 'Heavyweight Structural Concrete',
              tradeId: '03 31 13',
            },
            {
              id: '38f79cd7-b231-4f6c-893b-9bb71f12cf28',
              name: 'Lightweight Structural Concrete',
              tradeId: '03 31 16',
            },
            {
              id: '72855cb5-3504-4a12-bcca-206916f50872',
              name: 'Shrinkage-Compensating Structural Concrete',
              tradeId: '03 31 19',
            },
            {
              id: 'ff33bb3b-6f18-4b47-b050-d54c1ecba1f6',
              name: 'High-Performance Structural Concrete',
              tradeId: '03 31 23',
            },
            {
              id: '413bb5e7-3178-4f34-8a44-965a5550554a',
              name: 'Ultra High-Performance Structural Concrete',
              tradeId: '03 31 24',
            },
            {
              id: '93a0d53b-f270-4d67-bc7c-a225ac2f8e62',
              name: 'Self-Compacting Concrete',
              tradeId: '03 31 26',
            },
          ],
          tradeId: '03 31 00',
        },
        {
          id: '35d99d29-a439-49e1-a9ec-c92253a8da53',
          name: 'Architectural Concrete',
          children: [
            {
              id: '31b9238e-eaa6-46ce-a284-783e94f56b2f',
              name: 'Architectural Concrete',
              tradeId: '03 33 00',
            },
            {
              id: 'bfe45048-e811-4c10-a851-40f83f86a9d4',
              name: 'Heavyweight Architectural Concrete',
              tradeId: '03 33 13',
            },
            {
              id: '4121b68e-c20c-4329-a734-3defd3419524',
              name: 'Lightweight Architectural Concrete',
              tradeId: '03 33 16',
            },
          ],
          tradeId: '03 33 00',
        },
        {
          id: '7c90ae95-d560-4a4d-ad75-a2d228b2be1e',
          name: 'Low Density Concrete',
          children: [],
          tradeId: '03 34 00',
        },
        {
          id: '477246e5-9340-43dc-af30-c195a4fdf4d7',
          name: 'Concrete Finishing',
          children: [
            {
              id: '92853c7c-69cf-4d1e-bc6f-5374f561ce50',
              name: 'Concrete Finishing',
              tradeId: '03 35 00',
            },
            {
              id: 'd9665cf9-a281-43f8-b423-f22423a1e669',
              name: 'High-Tolerance Concrete Floor Finishing',
              tradeId: '03 35 13',
            },
            {
              id: '0d4ee27f-4e88-4dd0-9831-147839420c24',
              name: 'Heavy-Duty Concrete Floor Finishing',
              tradeId: '03 35 16',
            },
            {
              id: '9b768523-51aa-4c49-a8cd-409066816c7c',
              name: 'Colored Concrete Finishing',
              tradeId: '03 35 19',
            },
            {
              id: 'afcf8628-0e5f-4991-9551-9acea334347c',
              name: 'Exposed Aggregate Concrete Finishing',
              tradeId: '03 35 23',
            },
            {
              id: '37b44344-a5b2-44ed-9920-fc2715c81a51',
              name: 'Grooved Concrete Surface Finishing',
              tradeId: '03 35 26',
            },
            {
              id: '3f3094dc-a221-4947-9f6e-cc29d20331d2',
              name: 'Tooled Concrete Finishing',
              tradeId: '03 35 29',
            },
            {
              id: 'e8beff50-209b-4197-adfd-8109c9894736',
              name: 'Stamped Concrete Finishing',
              tradeId: '03 35 33',
            },
            {
              id: '095d878b-fd92-47a1-a53e-8ede5a8359de',
              name: 'Polished Concrete Finishing',
              tradeId: '03 35 43',
            },
          ],
          tradeId: '03 35 00',
        },
        {
          id: 'b1ee9c8a-dd0b-4b2a-81dc-bdd79146efbf',
          name: 'Specialty Placed Concrete',
          children: [
            {
              id: '973674c7-df72-47d4-80ee-7c380f4d4d00',
              name: 'Specialty Placed Concrete',
              tradeId: '03 37 00',
            },
            {
              id: '7cb7d3be-ce94-46f0-b6de-da2f3c555999',
              name: 'Shotcrete',
              tradeId: '03 37 13',
            },
            {
              id: '460c7b5f-3cd5-4fa4-9cab-040da0a622e1',
              name: 'Pumped Concrete',
              tradeId: '03 37 16',
            },
            {
              id: '63e01b8a-d989-4031-abdc-a03dd460e77e',
              name: 'Pneumatically Placed Concrete',
              tradeId: '03 37 19',
            },
            {
              id: '3c014b3f-a3a9-4f36-8fb0-f7303ede81a5',
              name: 'Roller-Compacted Concrete',
              tradeId: '03 37 23',
            },
            {
              id: '92edf766-42c5-4c19-a3d3-8ee7aef1967b',
              name: 'Underwater Placed Concrete',
              tradeId: '03 37 26',
            },
          ],
          tradeId: '03 37 00',
        },
        {
          id: '9b13a636-a0cb-4147-8b02-70fb1d48c76a',
          name: 'Post-Tensioned Concrete',
          children: [
            {
              id: 'f720933a-3d17-48ac-80cb-deefa441f0ab',
              name: 'Post-Tensioned Concrete',
              tradeId: '03 38 00',
            },
            {
              id: '1ff67926-19c3-42f5-b388-ea872b8861c0',
              name: 'Post-Tensioned Concrete Preparation',
              tradeId: '03 38 13',
            },
            {
              id: '108c0f92-0cc2-4bf7-b356-c8a0d7a09ff6',
              name: 'Unbonded Post-Tensioned Concrete',
              tradeId: '03 38 16',
            },
            {
              id: '12be08e9-d7e8-4597-bf55-f22e93d16610',
              name: 'Bonded Post-Tensioned Concrete',
              tradeId: '03 38 19',
            },
          ],
          tradeId: '03 38 00',
        },
        {
          id: '06d502a3-82e2-4353-835d-2b9a667454dd',
          name: 'Concrete Curing',
          children: [
            {
              id: '167c2eb8-4dc1-4589-b422-338e44b604a8',
              name: 'Concrete Curing',
              tradeId: '03 39 00',
            },
            {
              id: '6e2fb810-4b2e-4706-811c-4b4f2edda48b',
              name: 'Water Concrete Curing',
              tradeId: '03 39 13',
            },
            {
              id: '367cae9a-b0b0-447f-b8f9-d49849e49a62',
              name: 'Sand Concrete Curing',
              tradeId: '03 39 16',
            },
            {
              id: '7e4ebba8-48a4-408f-ab55-891738d97319',
              name: 'Membrane Concrete Curing',
              tradeId: '03 39 23',
            },
            {
              id: '206f3b47-1b6c-4fea-b4d5-08d696110c40',
              name: 'Chemical Compound Membrane Concrete Curing',
              tradeId: '03 39 23.13',
            },
            {
              id: 'd58c5a75-318d-46d2-bb23-3a13bb2e243e',
              name: 'Sheet Membrane Concrete Curing',
              tradeId: '03 39 23.23',
            },
          ],
          tradeId: '03 39 00',
        },
        {
          id: '16320ca6-060a-4a50-8d73-b1f4a7829644',
          name: 'Precast Concrete',
          children: [],
          tradeId: '03 40 00',
        },
        {
          id: '42bd8122-b4ce-4920-9cfa-9513aea56774',
          name: 'Precast Structural Concrete',
          children: [
            {
              id: 'e26e8310-fa47-4bb4-8a6b-af78fec08f43',
              name: 'Precast Structural Concrete',
              tradeId: '03 41 00',
            },
            {
              id: '7a70d6f9-af84-404e-8dd7-3cbaf01e3201',
              name: 'Precast Concrete Hollow Core Planks',
              tradeId: '03 41 13',
            },
            {
              id: 'f47138b5-5d57-470a-9f83-b10741b0dcd1',
              name: 'Precast Concrete Slabs',
              tradeId: '03 41 16',
            },
            {
              id: 'd9a5ce8e-c63a-4b5b-94c2-213679b5013d',
              name: 'Precast Concrete Stairs',
              tradeId: '03 41 23',
            },
            {
              id: '9abac24a-666d-4b2d-926f-d0a1304b016f',
              name: 'Precast Structural Pretensioned Concrete',
              tradeId: '03 41 33',
            },
            {
              id: 'e968d998-c3e2-47ed-b13f-2f672e49bbae',
              name: 'Precast Structural Post-Tensioned Concrete',
              tradeId: '03 41 36',
            },
          ],
          tradeId: '03 41 00',
        },
        {
          id: 'df07302a-c423-4ad8-9706-2a29365ee1b9',
          name: 'Precast Architectural Concrete',
          children: [
            {
              id: 'fab2266a-1753-4a72-bb4f-6bd4d7c4914a',
              name: 'Precast Architectural Concrete',
              tradeId: '03 45 00',
            },
            {
              id: '4222efa6-f0b0-4056-9ebe-fd49611f6b53',
              name: 'Faced Architectural Precast Concrete',
              tradeId: '03 45 13',
            },
            {
              id: '56d6b9e0-279a-4bf0-a808-5ba9c1789222',
              name: 'Precast Architectural Pretensioned Concrete',
              tradeId: '03 45 33',
            },
            {
              id: '3be91c89-2048-4c6d-8dbd-7e1fd0cc703b',
              name: 'Precast Architectural Post-Tensioned Concrete',
              tradeId: '03 45 36',
            },
          ],
          tradeId: '03 45 00',
        },
        {
          id: 'e4127969-4853-47c7-850e-f702b0421ed1',
          name: 'Site-Cast Concrete',
          children: [
            {
              id: '4b191eee-ab3b-4046-a28d-716eee6ebea2',
              name: 'Site-Cast Concrete',
              tradeId: '03 47 00',
            },
            {
              id: 'c4986a32-2047-4a11-8aea-372052e5901a',
              name: 'Tilt-Up Concrete',
              tradeId: '03 47 13',
            },
            {
              id: 'a92a521b-951b-445e-96b5-5115e83c8bf9',
              name: 'Lift-Slab Concrete',
              tradeId: '03 47 16',
            },
          ],
          tradeId: '03 47 00',
        },
        {
          id: 'deaddeeb-0130-48e3-9994-ca768697d27f',
          name: 'Precast Concrete Specialties',
          children: [
            {
              id: 'dc63c71a-0c65-49dd-93b6-89f51cdd7f90',
              name: 'Precast Concrete Specialties',
              tradeId: '03 48 00',
            },
            {
              id: 'd08e90c2-36a3-40c5-8f7a-c26622aea027',
              name: 'Precast Concrete Bollards',
              tradeId: '03 48 13',
            },
            {
              id: 'e84b802a-1712-4196-bb08-063504db1fe0',
              name: 'Precast Concrete Splash Blocks',
              tradeId: '03 48 16',
            },
            {
              id: '5cadc495-7a04-4fd5-a873-f5ac3d1abba1',
              name: 'Precast Concrete Stair Treads',
              tradeId: '03 48 19',
            },
            {
              id: 'd7c46f19-2ec0-44cf-bb92-cb1a117c0613',
              name: 'Precast Pre-Framed Concrete Panels',
              tradeId: '03 48 33',
            },
            {
              id: 'cee9df40-d7e7-494b-b572-9e02e843a001',
              name: 'Precast Concrete Trim',
              tradeId: '03 48 43',
            },
          ],
          tradeId: '03 48 00',
        },
        {
          id: '9f02051c-149f-4a06-b754-d66dc8e5cfa8',
          name: 'Glass-Fiber-Reinforced Concrete',
          children: [
            {
              id: '3791e1e6-d334-4a96-97b1-285c2ec91b57',
              name: 'Glass-Fiber-Reinforced Concrete',
              tradeId: '03 49 00',
            },
            {
              id: '08f2bdcd-d573-4ba6-afe4-e4c3f4158ca9',
              name: 'Glass-Fiber-Reinforced Concrete Column Covers',
              tradeId: '03 49 13',
            },
            {
              id: '99716627-dbb3-4329-b7ee-abf4a02c12f6',
              name: 'Glass-Fiber-Reinforced Concrete Spandrels',
              tradeId: '03 49 16',
            },
            {
              id: 'f0a6ec38-acf4-4c32-871a-73de93813a8c',
              name: 'Glass-Fiber-Reinforced Concrete Trim',
              tradeId: '03 49 43',
            },
          ],
          tradeId: '03 49 00',
        },
        {
          id: 'ccb48e26-f2c4-4653-b513-4aea136e6cf0',
          name: 'Cast Decks and Underlayment',
          children: [],
          tradeId: '03 50 00',
        },
        {
          id: 'e6fd8171-e4c5-49b1-80d1-0c1b4ffef641',
          name: 'Cast Roof Decks',
          children: [
            {
              id: 'facdbc9b-8f74-4a4a-92a2-f3879f61bc48',
              name: 'Cast Roof Decks',
              tradeId: '03 51 00',
            },
            {
              id: 'bc4f899f-d589-4c8a-94a4-9777aa7bd18a',
              name: 'Cementitious Wood Fiber Decks',
              tradeId: '03 51 13',
            },
            {
              id: 'd7c9857b-f06c-4881-9929-9b53880a1604',
              name: 'Gypsum Concrete Roof Decks',
              tradeId: '03 51 16',
            },
          ],
          tradeId: '03 51 00',
        },
        {
          id: 'af8299e9-cd1d-45ba-a714-dbcc78fde306',
          name: 'Lightweight Concrete Roof Insulation',
          children: [
            {
              id: '799b84a7-544b-4045-9259-ee2a4ac6e422',
              name: 'Lightweight Concrete Roof Insulation',
              tradeId: '03 52 00',
            },
            {
              id: '3e998e61-b072-4c97-a1d7-01157abe9a3a',
              name: 'Composite Concrete Roof Insulation',
              tradeId: '03 52 13',
            },
            {
              id: '301ee6ec-7b6e-4d3e-b914-0563be54bcf8',
              name: 'Lightweight Insulating Concrete',
              tradeId: '03 52 16',
            },
            {
              id: '6ee2fbd8-a748-48d0-91b3-77efd2b204dc',
              name: 'Lightweight Cellular Insulating Concrete',
              tradeId: '03 52 16.13',
            },
            {
              id: '8e29b946-af69-4609-888d-684826282dc5',
              name: 'Lightweight Aggregate Insulating Concrete',
              tradeId: '03 52 16.16',
            },
          ],
          tradeId: '03 52 00',
        },
        {
          id: '7bc877c4-12b8-406d-849e-dd2be8c3b56c',
          name: 'Concrete Topping',
          children: [
            {
              id: 'c5632a86-ae14-44c5-bf79-86aa2206ec5d',
              name: 'Concrete Topping',
              tradeId: '03 53 00',
            },
            {
              id: '84a13ef9-bc7d-4f33-89fb-fba2d074a29c',
              name: 'Emery-Aggregate Concrete Topping',
              tradeId: '03 53 13',
            },
            {
              id: '4c0e8b41-7433-43d1-beee-b32630b26eb1',
              name: 'Iron-Aggregate Concrete Topping',
              tradeId: '03 53 16',
            },
          ],
          tradeId: '03 53 00',
        },
        {
          id: 'a1409a4b-f320-42d8-9dcc-16420ab1816a',
          name: 'Cast Underlayment',
          children: [
            {
              id: 'a2d48bb0-fd58-4b1b-ab48-9cabf3fecac3',
              name: 'Cast Underlayment',
              tradeId: '03 54 00',
            },
            {
              id: '86a34fd3-f2d5-4e92-9575-ae9977d29763',
              name: 'Gypsum Cement Underlayment',
              tradeId: '03 54 13',
            },
            {
              id: '0df45d11-5923-4285-b482-7acfa3968336',
              name: 'Hydraulic Cement Underlayment',
              tradeId: '03 54 16',
            },
          ],
          tradeId: '03 54 00',
        },
        {
          id: 'c516b523-f496-499c-993b-9643cfaa6001',
          name: 'Grouting',
          children: [],
          tradeId: '03 60 00',
        },
        {
          id: '3740072b-1f88-45a3-a401-299fcf1a8bf6',
          name: 'Cementitious Grouting',
          children: [
            {
              id: '9255fe71-d795-48a3-962c-669dca0fdf09',
              name: 'Cementitious Grouting',
              tradeId: '03 61 00',
            },
            {
              id: 'db623a97-026c-4245-a48a-67c26ebf9150',
              name: 'Dry-Pack Grouting',
              tradeId: '03 61 13',
            },
          ],
          tradeId: '03 61 00',
        },
        {
          id: '61e00eda-164d-4987-9c1b-8de4da94790e',
          name: 'Non-Shrink Grouting',
          children: [
            {
              id: '5575dc9a-6de3-4140-95f4-0d0ca304ee4e',
              name: 'Non-Shrink Grouting',
              tradeId: '03 62 00',
            },
            {
              id: '4526da50-71b6-4242-9dca-d3b4a176f46b',
              name: 'Non-Metallic Non-Shrink Grouting',
              tradeId: '03 62 13',
            },
            {
              id: '7d24b4bf-722a-499a-ac62-1d0af84b75e1',
              name: 'Metallic Non-Shrink Grouting',
              tradeId: '03 62 16',
            },
          ],
          tradeId: '03 62 00',
        },
        {
          id: '05ed4349-7d4d-4ef9-8607-f64165641711',
          name: 'Epoxy Grouting',
          children: [],
          tradeId: '03 63 00',
        },
        {
          id: 'cabeea3b-8c1d-452f-878d-5d2d4087c9c6',
          name: 'Injection Grouting',
          children: [
            {
              id: 'ff1301de-c176-4611-bfe9-c9af61923d83',
              name: 'Injection Grouting',
              tradeId: '03 64 00',
            },
            {
              id: '341aa3b6-7638-4c44-b7be-921c70b4a397',
              name: 'Epoxy Injection Grouting',
              tradeId: '03 64 23',
            },
          ],
          tradeId: '03 64 00',
        },
        {
          id: '0bf412ec-d8f9-44b3-9a6a-7ffe98961e14',
          name: 'Mass Concrete',
          children: [],
          tradeId: '03 70 00',
        },
        {
          id: '43c57ba9-d6f9-48d4-8fa3-93cc74148028',
          name: 'Mass Concrete for Raft Foundations',
          children: [],
          tradeId: '03 71 00',
        },
        {
          id: 'c4ae38b1-7d45-4cc3-a6d1-f133e6046cdd',
          name: 'Mass Concrete for Dams',
          children: [],
          tradeId: '03 72 00',
        },
        {
          id: 'df8c63b8-41c0-43e6-b0c0-0fb664ff0046',
          name: 'Concrete Cutting and Boring',
          children: [],
          tradeId: '03 80 00',
        },
        {
          id: '715fce69-b0ec-405d-9b0b-83ce056e9645',
          name: 'Concrete Cutting',
          children: [
            {
              id: 'bd14f0fa-96ba-4dd1-873a-8c30982db91e',
              name: 'Concrete Cutting',
              tradeId: '03 81 00',
            },
            {
              id: '3377484d-3c82-4287-9e6a-d08d0a5faf6e',
              name: 'Flat Concrete Sawing',
              tradeId: '03 81 13',
            },
            {
              id: '9bc9f446-2c4b-42cb-8a6b-448d98fed864',
              name: 'Track Mounted Concrete Wall Sawing',
              tradeId: '03 81 16',
            },
            {
              id: 'c1e51844-d8e6-43e0-8dc0-48e8383367af',
              name: 'Wire Concrete Wall Sawing',
              tradeId: '03 81 19',
            },
            {
              id: 'b58012a9-8829-45ed-a85a-848708c0f7c3',
              name: 'Hand Concrete Wall Sawing',
              tradeId: '03 81 23',
            },
            {
              id: 'fbd93af5-c102-4467-b44a-c90f7edcc77a',
              name: 'Chain Concrete Wall Sawing',
              tradeId: '03 81 26',
            },
          ],
          tradeId: '03 81 00',
        },
        {
          id: 'df6eed04-4229-4294-b880-77b9ff6a1461',
          name: 'Concrete Boring',
          children: [
            {
              id: '3d65a414-3fac-4aec-8cad-e474682c8c6d',
              name: 'Concrete Boring',
              tradeId: '03 82 00',
            },
            {
              id: 'addf8a95-4c5a-4bd7-a8ce-1433ab1bc434',
              name: 'Concrete Core Drilling',
              tradeId: '03 82 13',
            },
          ],
          tradeId: '03 82 00',
        },
      ],
      tradeId: '03 00 00',
    },
    {
      id: 'e0578e26-6825-4b5c-ae2e-513013a79b8e',
      name: 'Masonry',
      children: [
        {
          id: '0797308a-2afe-4b17-9962-fab7d7899916',
          name: 'Masonry',
          children: [],
          tradeId: '04 00 00',
        },
        {
          id: '52188ab9-3e53-4e40-a107-edd6d227e35d',
          name: 'Maintenance of Masonry',
          children: [
            {
              id: 'cab96c7c-477f-42a9-8235-32587d8ac171',
              name: 'Maintenance of Masonry',
              tradeId: '04 01 00',
            },
            {
              id: 'b4c7b9ed-6c35-423d-8f4e-b7502151df8a',
              name: 'Maintenance of Unit Masonry',
              tradeId: '04 01 20',
            },
            {
              id: '8d53fb22-e4b0-4313-ad09-60642b47a4eb',
              name: 'Unit Masonry Stabilization',
              tradeId: '04 01 20.41',
            },
            {
              id: 'af671461-f21c-4aa8-b4da-ada46afdae9c',
              name: 'Unit Masonry Maintenance',
              tradeId: '04 01 20.51',
            },
            {
              id: '5d4ac626-1ed6-4fd9-9955-3762d1243bea',
              name: 'Unit Masonry Cleaning',
              tradeId: '04 01 20.52',
            },
            {
              id: '024d97d0-7cff-4882-aa93-7af341133a01',
              name: 'Unit Masonry Restoration',
              tradeId: '04 01 20.91',
            },
            {
              id: '1847c5f7-7cd2-423a-92b5-4c119e49f35f',
              name: 'Testing and Sampling Brick Units for Restoration',
              tradeId: '04 01 20.93',
            },
            {
              id: 'ee342792-7f75-4dbb-b5c4-47c29cfa9c8c',
              name: 'Maintenance of Stone Assemblies',
              tradeId: '04 01 40',
            },
            {
              id: '16bee0eb-6096-4fee-b87c-83c17e40fd29',
              name: 'Stone Maintenance',
              tradeId: '04 01 40.51',
            },
            {
              id: 'f8f39a4c-ad07-4bf7-bc1a-5baded534369',
              name: 'Stone Cleaning',
              tradeId: '04 01 40.52',
            },
            {
              id: '87e314d7-38e8-47c8-9460-66d9b3022028',
              name: 'Stone Restoration',
              tradeId: '04 01 40.91',
            },
            {
              id: 'a36a4351-f829-436e-a972-0963bb78b766',
              name: 'Maintenance of Refractory Masonry',
              tradeId: '04 01 50',
            },
            {
              id: 'ea7c4440-8b87-4c73-ab28-e5949da942e2',
              name: 'Maintenance of Corrosion-Resistant Masonry',
              tradeId: '04 01 60',
            },
            {
              id: 'fab80dee-e864-4f4e-bdc3-e0b760d97cf5',
              name: 'Maintenance of Manufactured Masonry',
              tradeId: '04 01 70',
            },
          ],
          tradeId: '04 01 00',
        },
        {
          id: '6a695abb-0251-41b4-bc20-e32de6f53890',
          name: 'Common Work Results for Masonry',
          children: [
            {
              id: 'a9cc859a-cb17-4a45-aa6e-1d60aeaf2ed0',
              name: 'Common Work Results for Masonry',
              tradeId: '04 05 00',
            },
            {
              id: 'fc5b2038-1ed2-4d05-a798-d10b6b28807d',
              name: 'Masonry Mortaring',
              tradeId: '04 05 13',
            },
            {
              id: '1b830dcc-9edb-45ce-8584-8a3f75e20045',
              name: 'Chemical-Resistant Masonry Mortaring',
              tradeId: '04 05 13.16',
            },
            {
              id: 'fcd71f51-7ff4-4fb5-bc12-fb08de829a04',
              name: 'Epoxy Masonry Mortaring',
              tradeId: '04 05 13.19',
            },
            {
              id: '8bee0416-c057-43ea-9c39-12262c18c35b',
              name: 'Surface Bonding Masonry Mortaring',
              tradeId: '04 05 13.23',
            },
            {
              id: '8668caa3-e297-4b09-a571-d2954006e946',
              name: 'Engineered Masonry Mortaring',
              tradeId: '04 05 13.26',
            },
            {
              id: '8dd43db1-e304-4180-a893-94377473d217',
              name: 'Refractory Masonry Mortaring',
              tradeId: '04 05 13.29',
            },
            {
              id: 'c32539b2-a92e-4581-a4f2-821625b712db',
              name: 'Masonry Restoration Mortaring',
              tradeId: '04 05 13.91',
            },
            {
              id: '87c3f01f-c204-4f12-88f4-087f7438fe03',
              name: 'Masonry Grouting',
              tradeId: '04 05 16',
            },
            {
              id: 'abb69208-0c3e-42ef-b345-5db635f08e2b',
              name: 'Chemical-Resistant Masonry Grouting',
              tradeId: '04 05 16.16',
            },
            {
              id: 'ee3cbc58-43a2-4b63-8d4c-873b5cc213d2',
              name: 'Engineered Masonry Grouting',
              tradeId: '04 05 16.26',
            },
            {
              id: 'b82e048a-7894-409f-951d-02af25b8dd8a',
              name: 'Masonry Anchorage and Reinforcing',
              tradeId: '04 05 19',
            },
            {
              id: 'd5074d51-4be7-4795-a632-f3a24d30b388',
              name: 'Continuous Joint Reinforcing',
              tradeId: '04 05 19.13',
            },
            {
              id: 'fea41423-9de2-43e9-90f3-0b46da075e18',
              name: 'Masonry Anchors',
              tradeId: '04 05 19.16',
            },
            {
              id: 'd804fe7c-5c8d-4eb4-a378-89ac04b4852b',
              name: 'Masonry Reinforcing Bars',
              tradeId: '04 05 19.26',
            },
            {
              id: 'a8fd1c0a-03ac-49c6-82a5-d5ff6c26d8c7',
              name: 'Stone Anchors',
              tradeId: '04 05 19.29',
            },
            {
              id: 'f69124f4-a8b1-4c0d-99e5-a0d4633cc612',
              name: 'Masonry Strengthening',
              tradeId: '04 05 21',
            },
            {
              id: '6dd5b582-fefa-464d-a124-446e40cb22d2',
              name: 'Masonry Accessories',
              tradeId: '04 05 23',
            },
            {
              id: '1c2af14a-4f03-4dea-94d9-59abecc311ef',
              name: 'Masonry Control and Expansion Joints',
              tradeId: '04 05 23.13',
            },
            {
              id: '48e82e40-bf82-4034-b268-fbcb35b4b7ef',
              name: 'Masonry Embedded Flashing',
              tradeId: '04 05 23.16',
            },
            {
              id: 'c9163742-d186-40cd-b032-f394064a671e',
              name: 'Masonry Cavity Drainage, Weepholes and Vents',
              tradeId: '04 05 23.19',
            },
          ],
          tradeId: '04 05 00',
        },
        {
          id: 'ffa76f2d-6f3c-443e-bd35-e86d1de007a8',
          name: 'Schedules for Masonry',
          children: [
            {
              id: '891fd4ed-e620-4886-bbc5-236a38b97e83',
              name: 'Schedules for Masonry',
              tradeId: '04 06 00',
            },
            {
              id: '100078d6-4970-4aef-bceb-d46c8fb4833e',
              name: 'Schedules for Unit Masonry',
              tradeId: '04 06 20',
            },
            {
              id: '8b77058e-bba5-49d9-9474-d0439a13ac22',
              name: 'Masonry Unit Schedule',
              tradeId: '04 06 20.13',
            },
            {
              id: '2501bb5c-306d-4ff1-9bf3-422f2421b770',
              name: 'Schedules for Stone Assemblies',
              tradeId: '04 06 40',
            },
            {
              id: '5cf2df3d-8b44-460b-acbe-c8a825cd0dda',
              name: 'Schedules for Refractory Masonry',
              tradeId: '04 06 50',
            },
            {
              id: '89ef1fc4-e892-41df-aa74-5435c45a3a08',
              name: 'Schedules for Corrosion-Resistant Masonry',
              tradeId: '04 06 60',
            },
            {
              id: '2921965d-b968-48c3-a6f5-b62765a7ecbb',
              name: 'Schedules for Manufactured Masonry',
              tradeId: '04 06 70',
            },
          ],
          tradeId: '04 06 00',
        },
        {
          id: '99f6d4e9-b189-43e4-9e12-c2fe014423c6',
          name: 'Commissioning of Masonry',
          children: [],
          tradeId: '04 08 00',
        },
        {
          id: '35cd4a95-086d-41d4-a133-38694523c74a',
          name: 'Unit Masonry',
          children: [],
          tradeId: '04 20 00',
        },
        {
          id: 'bfac3b5c-541c-4e62-83b2-6a53b2c9eb03',
          name: 'Clay Unit Masonry',
          children: [
            {
              id: '74f3106d-0a5c-4f66-abd5-80680ce93796',
              name: 'Clay Unit Masonry',
              tradeId: '04 21 00',
            },
            {
              id: '145f7772-b3cb-4918-a925-f80e71040328',
              name: 'Brick Masonry',
              tradeId: '04 21 13',
            },
            {
              id: 'ba4bcf90-a4ea-4c81-b9cc-648abc9e0a9e',
              name: 'Brick Veneer Masonry',
              tradeId: '04 21 13.13',
            },
            {
              id: '99bb2970-bf36-4774-94f5-cc11b5ab7212',
              name: 'Surface-Bonded Brick Masonry',
              tradeId: '04 21 13.23',
            },
            {
              id: 'b1064c00-6f73-48aa-85b3-2c5536890825',
              name: 'Ceramic Glazed Clay Masonry',
              tradeId: '04 21 16',
            },
            {
              id: '81cac20e-9326-4e66-a340-6858ada9df93',
              name: 'Clay Tile Masonry',
              tradeId: '04 21 19',
            },
            {
              id: 'c8f8f5f6-f915-40c6-8c4e-469f96ec15c0',
              name: 'Structural Clay Tile Masonry',
              tradeId: '04 21 23',
            },
            {
              id: 'd97f4f9f-6c88-42a9-9f4b-97c2b9927f74',
              name: 'Glazed Structural Clay Tile Masonry',
              tradeId: '04 21 26',
            },
            {
              id: '7ec8a524-a2c8-4061-af0b-b756c540a2c0',
              name: 'Terra Cotta Masonry',
              tradeId: '04 21 29',
            },
          ],
          tradeId: '04 21 00',
        },
        {
          id: '1b203f5a-e1a0-4fb4-a431-c1bea3523d7b',
          name: 'Concrete Unit Masonry',
          children: [
            {
              id: 'b87a117d-7dcd-4cf9-a15f-55e641832987',
              name: 'Concrete Unit Masonry',
              tradeId: '04 22 00',
            },
            {
              id: '6621fed7-8b12-491c-a12f-bdbae45f923a',
              name: 'Concrete Unit Veneer Masonry',
              tradeId: '04 22 00.13',
            },
            {
              id: 'b4df68e2-b3f3-4652-b14a-fe244521b72c',
              name: 'Surface-Bonded Concrete Unit Masonry',
              tradeId: '04 22 00.16',
            },
            {
              id: '3a6def35-9506-4ad9-8f67-59c3995cc5a2',
              name: 'Insulated Concrete Unit Masonry',
              tradeId: '04 22 19',
            },
            {
              id: 'a6ad8236-ce40-4a03-aa0c-16dd9560e06d',
              name: 'Architectural Concrete Unit Masonry',
              tradeId: '04 22 23',
            },
            {
              id: '22efc380-3c35-4cdf-83c1-ebe08c149d49',
              name: 'Exposed Aggregate Concrete Unit Masonry',
              tradeId: '04 22 23.13',
            },
            {
              id: 'd290a5ca-da9c-4142-ae40-50efecf48313',
              name: 'Fluted Concrete Unit Masonry',
              tradeId: '04 22 23.16',
            },
            {
              id: '75db0611-a0c9-4ba3-919d-4ac10c090bea',
              name: 'Molded-Face Concrete Unit Masonry',
              tradeId: '04 22 23.19',
            },
            {
              id: 'b044f15d-3bd1-445e-a1c7-1d33517c7382',
              name: 'Prefaced Concrete Unit Masonry',
              tradeId: '04 22 23.23',
            },
            {
              id: 'ea8f8b44-74ca-442d-bde3-f3fc46a9f09f',
              name: 'Sound-Absorbing Concrete Unit Masonry',
              tradeId: '04 22 23.26',
            },
            {
              id: 'bd06cdd8-3622-4d67-9409-459d9fc2296c',
              name: 'Split-Face Concrete Unit Masonry',
              tradeId: '04 22 23.29',
            },
            {
              id: '7381cde6-b743-4aaa-b6e4-9b6cf59e0e64',
              name: 'Autoclaved Aerated Concrete Unit Masonry',
              tradeId: '04 22 26',
            },
            {
              id: 'dc421495-d5c1-495d-b531-506be7f2baec',
              name: 'Interlocking Concrete Unit Masonry',
              tradeId: '04 22 33',
            },
          ],
          tradeId: '04 22 00',
        },
        {
          id: '73f75449-0a27-41be-a505-42ce4d29e1ab',
          name: 'Concrete Unit Masonry',
          children: [
            {
              id: 'a2666996-610b-4c96-adde-2685112b69f6',
              name: 'Concrete Unit Masonry',
              tradeId: '04 22 00',
            },
            {
              id: '87bf2fa0-4470-4891-ba84-6b58ba27a2dc',
              name: 'Concrete Unit Veneer Masonry',
              tradeId: '04 22 00.13',
            },
            {
              id: '950aefcb-8a33-4327-a65e-3cb6b5ba1f2d',
              name: 'Surface-Bonded Concrete Unit Masonry',
              tradeId: '04 22 00.16',
            },
            {
              id: 'a6bf58a9-f60f-4289-858a-6edd6656f617',
              name: 'Insulated Concrete Unit Masonry',
              tradeId: '04 22 19',
            },
            {
              id: 'd6b115df-ebdd-4679-9ee7-f288ffdb15c3',
              name: 'Architectural Concrete Unit Masonry',
              tradeId: '04 22 23',
            },
            {
              id: '322518e5-b223-40fe-9b96-17c287ed6406',
              name: 'Exposed Aggregate Concrete Unit Masonry',
              tradeId: '04 22 23.13',
            },
            {
              id: 'cee824c2-feae-4870-bfc2-8e8253370f19',
              name: 'Fluted Concrete Unit Masonry',
              tradeId: '04 22 23.16',
            },
            {
              id: 'e7d356e9-dc59-4b2a-add6-b31a7f3f7664',
              name: 'Molded-Face Concrete Unit Masonry',
              tradeId: '04 22 23.19',
            },
            {
              id: 'bd052603-27fe-44db-ad60-70cc7e05d755',
              name: 'Prefaced Concrete Unit Masonry',
              tradeId: '04 22 23.23',
            },
            {
              id: '5dbe7614-8a5f-4b52-a353-17f08fe95d63',
              name: 'Sound-Absorbing Concrete Unit Masonry',
              tradeId: '04 22 23.26',
            },
            {
              id: 'c06e468f-c049-4b10-97af-253edf7b370a',
              name: 'Split-Face Concrete Unit Masonry',
              tradeId: '04 22 23.29',
            },
            {
              id: '94f7e629-80d7-4820-8ceb-e51efb206b9b',
              name: 'Autoclaved Aerated Concrete Unit Masonry',
              tradeId: '04 22 26',
            },
            {
              id: 'de474e4a-6b65-44c6-bcbc-7c225f6b0054',
              name: 'Interlocking Concrete Unit Masonry',
              tradeId: '04 22 33',
            },
          ],
          tradeId: '04 22 00',
        },
        {
          id: '406520c2-d2fc-4baf-9e49-ca6fcb115757',
          name: 'Concrete Unit Masonry',
          children: [
            {
              id: '4ad8c490-758f-4712-b16a-5a438d4a61e6',
              name: 'Concrete Unit Masonry',
              tradeId: '04 22 00',
            },
            {
              id: '2389ebb2-53c5-41f5-bc79-b5991ff1c0b1',
              name: 'Concrete Unit Veneer Masonry',
              tradeId: '04 22 00.13',
            },
            {
              id: 'dc40f153-abbb-4189-bec3-3fda4f9652fa',
              name: 'Surface-Bonded Concrete Unit Masonry',
              tradeId: '04 22 00.16',
            },
            {
              id: '6c5a1579-816d-4d50-b33f-ac5688bb8f93',
              name: 'Insulated Concrete Unit Masonry',
              tradeId: '04 22 19',
            },
            {
              id: 'd1e36351-010d-4e14-97cd-a067589fb0a6',
              name: 'Architectural Concrete Unit Masonry',
              tradeId: '04 22 23',
            },
            {
              id: '2fda163e-6a97-4aeb-b14f-9284d4315d30',
              name: 'Exposed Aggregate Concrete Unit Masonry',
              tradeId: '04 22 23.13',
            },
            {
              id: '2c50ea64-9f7f-431e-b28c-511e90cbe231',
              name: 'Fluted Concrete Unit Masonry',
              tradeId: '04 22 23.16',
            },
            {
              id: '440b06fd-f05b-4dbb-af99-0961ed1db3b2',
              name: 'Molded-Face Concrete Unit Masonry',
              tradeId: '04 22 23.19',
            },
            {
              id: '46cb4de6-992b-4a0a-8e1f-fd744c1d1af6',
              name: 'Prefaced Concrete Unit Masonry',
              tradeId: '04 22 23.23',
            },
            {
              id: '6fa26615-46e1-422c-9a69-63ca57726e4b',
              name: 'Sound-Absorbing Concrete Unit Masonry',
              tradeId: '04 22 23.26',
            },
            {
              id: '4e23cf56-68c3-4a58-bfc9-9f48f2da4d0d',
              name: 'Split-Face Concrete Unit Masonry',
              tradeId: '04 22 23.29',
            },
            {
              id: '5586a2aa-b7a1-46e9-9bab-e099be7e1b5b',
              name: 'Autoclaved Aerated Concrete Unit Masonry',
              tradeId: '04 22 26',
            },
            {
              id: 'd9287e3e-8a8b-4175-8848-045f73f2d83a',
              name: 'Interlocking Concrete Unit Masonry',
              tradeId: '04 22 33',
            },
          ],
          tradeId: '04 22 00',
        },
        {
          id: 'a463c99a-c91c-4f39-80bf-2187dca49b40',
          name: 'Glass Unit Masonry',
          children: [
            {
              id: 'cdd856fc-7cdc-4648-8aad-373550442fa9',
              name: 'Glass Unit Masonry',
              tradeId: '04 23 00',
            },
            {
              id: 'c21c0e53-f6a6-46d5-80a0-1e2878ffa4fc',
              name: 'Vertical Glass Unit Masonry',
              tradeId: '04 23 13',
            },
            {
              id: '7dbac819-6789-4a9d-aa43-cadf82a1b70d',
              name: 'Glass Unit Masonry Floors',
              tradeId: '04 23 16',
            },
            {
              id: 'a8ead30e-2a0b-4e89-9034-bcfe07901240',
              name: 'Glass Unit Masonry Skylights',
              tradeId: '04 23 19',
            },
          ],
          tradeId: '04 23 00',
        },
        {
          id: '913c3101-410d-4832-a641-19e9067d0859',
          name: 'Adobe Unit Masonry',
          children: [
            {
              id: '5c87b7ad-53c3-400a-9462-f14557f2d486',
              name: 'Adobe Unit Masonry',
              tradeId: '04 24 00',
            },
            {
              id: '97ff99d6-3ab0-41ed-abab-f234766175b2',
              name: 'Site-Cast Adobe Unit Masonry',
              tradeId: '04 24 13',
            },
            {
              id: '3cd67315-7fce-4317-b84c-cc6485df6111',
              name: 'Manufactured Adobe Unit Masonry',
              tradeId: '04 24 16',
            },
          ],
          tradeId: '04 24 00',
        },
        {
          id: 'c6279898-d54c-4a05-992b-0547ec9049e5',
          name: 'Unit Masonry Panels',
          children: [
            {
              id: '905ab182-dbd8-4784-b106-44f13fddcfdb',
              name: 'Unit Masonry Panels',
              tradeId: '04 25 00',
            },
            {
              id: '2573f9a5-336a-4706-8420-627cf27774df',
              name: 'Metal-Supported Unit Masonry Panels',
              tradeId: '04 25 13',
            },
          ],
          tradeId: '04 25 00',
        },
        {
          id: 'd73450bc-9324-4afc-afc9-ae80f3bd66c2',
          name: 'Single-Wythe Unit Masonry',
          children: [
            {
              id: 'cc499b93-84eb-44e5-ab29-28869be84beb',
              name: 'Single-Wythe Unit Masonry',
              tradeId: '04 26 00',
            },
            {
              id: '133f1868-f803-4631-a7cc-029ea4210fe6',
              name: 'Masonry Veneer',
              tradeId: '04 26 13',
            },
          ],
          tradeId: '04 26 00',
        },
        {
          id: '6d5927da-9935-46b4-b704-5120843e7f59',
          name: 'Multiple-Wythe Unit Masonry',
          children: [
            {
              id: '303d3b92-fb20-44d7-a7cc-b016dce484ed',
              name: 'Multiple-Wythe Unit Masonry',
              tradeId: '04 27 00',
            },
            {
              id: 'a42d9320-2c44-47df-b6b5-acfe95ee157d',
              name: 'Composite Unit Masonry',
              tradeId: '04 27 13',
            },
            {
              id: '30a7dcff-a7c2-4576-9e8b-c86da3818648',
              name: 'Cavity Wall Unit Masonry',
              tradeId: '04 27 23',
            },
          ],
          tradeId: '04 27 00',
        },
        {
          id: '2ffccbea-1bcb-4e8e-847e-dfea12ee7bd4',
          name: 'Concrete Form Masonry Units',
          children: [
            {
              id: 'bd9f95d8-2720-4128-9619-0953f0c75038',
              name: 'Concrete Form Masonry Units',
              tradeId: '04 28 00',
            },
            {
              id: '50608ea9-b84d-4f52-95ef-300389058795',
              name: 'Dry-Stacked, Concrete-Filled Masonry Units',
              tradeId: '04 28 13',
            },
            {
              id: '0a027ea0-827a-4e3b-b4c1-356853f6613d',
              name: 'Mortar-Set, Concrete-Filled Masonry Units',
              tradeId: '04 28 23',
            },
          ],
          tradeId: '04 28 00',
        },
        {
          id: 'b150dbd5-988b-4a7e-bb62-974757c32b04',
          name: 'Engineered Unit Masonry',
          children: [],
          tradeId: '04 29 00',
        },
        {
          id: 'd097cbff-9453-452f-be35-440896455650',
          name: 'Stone Assemblies',
          children: [],
          tradeId: '04 40 00',
        },
        {
          id: '0302bb74-d774-4b6c-9e1f-b07415c4d254',
          name: 'Dry-Placed Stone',
          children: [],
          tradeId: '04 41 00',
        },
        {
          id: 'ab1eda5f-5cce-4e65-a41b-cf015f6e1295',
          name: 'Exterior Stone Cladding',
          children: [
            {
              id: '4b7bad73-36df-4ce2-99ff-4e5b2c816667',
              name: 'Exterior Stone Cladding',
              tradeId: '04 42 00',
            },
            {
              id: '20ad4429-7836-46c0-a79e-4ea65e6b3e68',
              name: 'Masonry-Supported Stone Cladding',
              tradeId: '04 42 13',
            },
            {
              id: 'bebffb0a-7252-43fa-a14c-e466329408ad',
              name: 'Steel-Stud-Supported Stone Cladding',
              tradeId: '04 42 16',
            },
            {
              id: 'ff33317e-eb7e-45e5-afbb-eb24c1ab4d87',
              name: 'Strongback-Frame-Supported Stone Cladding',
              tradeId: '04 42 19',
            },
            {
              id: 'c0801404-34ae-4bd4-a52c-55fa13121efa',
              name: 'Truss-Supported Stone Cladding',
              tradeId: '04 42 23',
            },
            {
              id: '4bc57f58-e87f-44b3-8be7-5342dbfb6901',
              name: 'Grid-System-Supported Stone Cladding',
              tradeId: '04 42 26',
            },
            {
              id: '889c8f46-59d7-48f2-a394-dee1df71f567',
              name: 'Stone Panels for Curtain Walls',
              tradeId: '04 42 43',
            },
          ],
          tradeId: '04 42 00',
        },
        {
          id: 'cb455ed9-1ac6-4b97-9c98-9311fd3f72ed',
          name: 'Stone Masonry',
          children: [
            {
              id: '0910d416-9e21-4d53-9864-46c681a758ed',
              name: 'Stone Masonry',
              tradeId: '04 43 00',
            },
            {
              id: 'e8c616bc-ebf8-46be-9117-253a20e264f3',
              name: 'Stone Masonry Veneer',
              tradeId: '04 43 13',
            },
            {
              id: 'f217299b-182c-485b-85cd-32942dbc931e',
              name: 'Anchored Stone Masonry Veneer',
              tradeId: '04 43 13.13',
            },
            {
              id: 'eeb66734-8088-4144-9ee1-aa1e20585470',
              name: 'Adhered Stone Masonry Veneer',
              tradeId: '04 43 13.16',
            },
          ],
          tradeId: '04 43 00',
        },
        {
          id: '035ccb13-8191-45c7-a6da-2713f82b8b2b',
          name: 'Refractory Masonry',
          children: [],
          tradeId: '04 50 00',
        },
        {
          id: '1384a9fb-610c-4d87-a29d-866be1ca2efe',
          name: 'Flue Liner Masonry',
          children: [],
          tradeId: '04 51 00',
        },
        {
          id: 'd5115de2-2568-4dd9-a46f-e5bfd3b76941',
          name: 'Combustion Chamber Masonry',
          children: [],
          tradeId: '04 52 00',
        },
        {
          id: '8c75dfce-ea03-4db1-baba-16be7934f06c',
          name: 'Castable Refractory Masonry',
          children: [],
          tradeId: '04 53 00',
        },
        {
          id: '3a175b5a-105d-486b-b879-953e7ddd093c',
          name: 'Refractory Brick Masonry',
          children: [],
          tradeId: '04 54 00',
        },
        {
          id: '45826d26-de9f-4d0a-a137-220d1db63050',
          name: 'Masonry Fireplaces',
          children: [
            {
              id: '6c10a0ab-9bd3-429f-98df-5d18b638eb3c',
              name: 'Masonry Fireplaces',
              tradeId: '04 57 00',
            },
            {
              id: '6806ff3f-3c44-422a-8d34-33b1f94c50ff',
              name: 'Modular Masonry Fireplaces',
              tradeId: '04 57 33',
            },
          ],
          tradeId: '04 57 00',
        },
        {
          id: 'cd5833f3-3135-45bc-9123-62f2ed7633f3',
          name: 'Corrosion-Resistant Masonry',
          children: [],
          tradeId: '04 60 00',
        },
        {
          id: '3921b017-ae76-4295-8150-1657a391050a',
          name: 'Chemical-Resistant Brick Masonry',
          children: [],
          tradeId: '04 61 00',
        },
        {
          id: '2ab2e70b-c51d-4150-9e34-42f353f3f419',
          name: 'Vitrified Clay Liner Plate',
          children: [],
          tradeId: '04 62 00',
        },
        {
          id: 'd109b199-4aa7-4ff1-90f4-e4098ad0983f',
          name: 'Manufactured Masonry',
          children: [],
          tradeId: '04 70 00',
        },
        {
          id: '5964f900-65f0-45e4-b65c-8ea65e4b7a16',
          name: 'Manufactured Brick Masonry',
          children: [
            {
              id: '9d74b230-3b79-4367-b498-867090635f02',
              name: 'Manufactured Brick Masonry',
              tradeId: '04 71 00',
            },
            {
              id: '5d034b60-d554-44fa-be73-67a4c87ad7b4',
              name: 'Calcium Silicate Manufactured Brick Masonry',
              tradeId: '04 71 13',
            },
          ],
          tradeId: '04 71 00',
        },
        {
          id: '0302d904-40e1-4e8f-9555-815d1a28b5d6',
          name: 'Cast Stone Masonry',
          children: [],
          tradeId: '04 72 00',
        },
        {
          id: 'e1210fca-b705-4b6b-97bc-d4f2373c6d48',
          name: 'Manufactured Stone Masonry',
          children: [
            {
              id: '3fdab3ab-5745-476f-98c6-947e14b452bc',
              name: 'Manufactured Stone Masonry',
              tradeId: '04 73 00',
            },
            {
              id: '2aa95905-180d-4bcd-b150-c75a539e225d',
              name: 'Calcium Silicate Manufactured Stone Masonry',
              tradeId: '04 73 13',
            },
          ],
          tradeId: '04 73 00',
        },
      ],
      tradeId: '04 00 00',
    },
    {
      id: '3e527bbe-9bef-4708-8611-e03c6d3996be',
      name: 'Metals',
      children: [
        {
          id: 'a96e2f0a-3569-4c7b-8850-24ab118bce11',
          name: 'Metals',
          children: [],
          tradeId: '05 00 00',
        },
        {
          id: '975fcca8-ec59-4c46-8262-08bf55c145f7',
          name: 'Maintenance of Metals',
          children: [
            {
              id: 'af97de04-2f07-4f8f-9164-fd997f1e87a8',
              name: 'Maintenance of Metals',
              tradeId: '05 01 00',
            },
            {
              id: '6511d6fd-0bae-4e10-9fa6-bd36a3b222a0',
              name: 'Maintenance of Structural Metal Framing',
              tradeId: '05 01 10',
            },
            {
              id: 'a20febd5-5991-4e1b-9224-75e98ef5657a',
              name: 'Maintenance of Metal Joists',
              tradeId: '05 01 20',
            },
            {
              id: '12a1149e-6610-4046-a87b-d2905bea70b2',
              name: 'Maintenance of Metal Decking',
              tradeId: '05 01 30',
            },
            {
              id: 'a84800d8-f494-48e5-af76-7aa372fcdbd7',
              name: 'Maintenance of Cold-Formed Metal Framing',
              tradeId: '05 01 40',
            },
            {
              id: 'd644664f-4e4c-4de8-a154-1239d6235a55',
              name: 'Maintenance of Metal Fabrications',
              tradeId: '05 01 50',
            },
            {
              id: '8b5ef4d7-4edf-4378-bf05-5901edfe225d',
              name: 'Maintenance of Decorative Metal',
              tradeId: '05 01 70',
            },
            {
              id: 'b5179521-b5ee-482f-a47c-ec9af184bc17',
              name: 'Historic Treatment of Decorative Metal',
              tradeId: '05 01 70.91',
            },
          ],
          tradeId: '05 01 00',
        },
        {
          id: '311e93e2-72b7-47f5-b334-62e9f17f3b59',
          name: 'Common Work Results for Metals',
          children: [
            {
              id: 'fb1fc503-26e3-4c9e-af22-4a4f71fa89bd',
              name: 'Common Work Results for Metals',
              tradeId: '05 05 00',
            },
            {
              id: '5ee3ff59-cc81-43eb-b2c2-0ed8b07917f8',
              name: 'Shop-Applied Coatings for Metal',
              tradeId: '05 05 13',
            },
            {
              id: 'd380ac1d-fea3-422d-a268-f4a7d8966c00',
              name: 'Post-Installed Concrete Anchors',
              tradeId: '05 05 19',
            },
            {
              id: '3b5d4f3f-e8a6-4663-bb9a-93dc6b6b7d0f',
              name: 'Metal Fastenings',
              tradeId: '05 05 23',
            },
            {
              id: '36b27fdd-b28e-496d-aa58-7e3c31f89598',
              name: 'Security Metal Fastenings',
              tradeId: '05 05 53',
            },
          ],
          tradeId: '05 05 00',
        },
        {
          id: '48148c84-ca49-4ea0-96ab-c751bbceb70c',
          name: 'Schedules for Metals',
          children: [
            {
              id: 'a882c14f-0838-48c5-883c-b3f741043af6',
              name: 'Schedules for Metals',
              tradeId: '05 06 00',
            },
            {
              id: '463c9bfe-a9dd-44fb-b7c9-3717f2b1c36d',
              name: 'Schedules for Structural Metal Framing',
              tradeId: '05 06 10',
            },
            {
              id: '78731e66-5106-4fe8-a8b5-99842ff2f9da',
              name: 'Steel Column Schedule',
              tradeId: '05 06 10.13',
            },
            {
              id: '42ee28f7-7268-46e6-a0b4-e64717e46dac',
              name: 'Steel Beam Schedule',
              tradeId: '05 06 10.16',
            },
            {
              id: 'ea4cff6b-5cb5-4f57-8821-659360d2a44a',
              name: 'Schedules for Metal Joists',
              tradeId: '05 06 20',
            },
            {
              id: '81bdf702-46ed-4fa0-a4b6-23900f8a0fa6',
              name: 'Steel Joist Schedule',
              tradeId: '05 06 20.13',
            },
            {
              id: 'ac91d68d-9d09-4203-ad98-4b845dc3fd1a',
              name: 'Schedules for Metal Decking',
              tradeId: '05 06 30',
            },
            {
              id: 'd8474647-10fa-4f4c-b46c-5ab616d1edf9',
              name: 'Schedules for Cold-Formed Metal Framing',
              tradeId: '05 06 40',
            },
            {
              id: '34f0e249-4e9e-40d0-a8d6-73806772051c',
              name: 'Schedules for Metal Fabrications',
              tradeId: '05 06 50',
            },
            {
              id: '2b74322a-d89d-4927-9689-d508d73811dd',
              name: 'Schedules for Decorative Metal',
              tradeId: '05 06 70',
            },
          ],
          tradeId: '05 06 00',
        },
        {
          id: '749cff23-8de1-4f1f-8e35-77d8555dc3e0',
          name: 'Commissioning of Metals',
          children: [],
          tradeId: '05 08 00',
        },
        {
          id: 'fb0fcaaf-66c3-47c9-ab7f-03e5074e7ea1',
          name: 'Structural Metal Framing',
          children: [],
          tradeId: '05 10 00',
        },
        {
          id: '7f4a08d6-b159-47bd-b657-098d8ad6ba8a',
          name: 'Structural Steel Framing',
          children: [
            {
              id: 'eff024e7-216e-4c7c-b406-1acede3e869a',
              name: 'Structural Steel Framing',
              tradeId: '05 12 00',
            },
            {
              id: 'efc6665a-4e20-4997-9ed0-0f46682cee59',
              name: 'Architecturally-Exposed Structural Steel Framing',
              tradeId: '05 12 13',
            },
            {
              id: 'a3ebdbf0-e0c1-4793-a4b7-a62f85f5ea03',
              name: 'Fabricated Fireproofed Steel Columns',
              tradeId: '05 12 16',
            },
            {
              id: '8d09cac8-d05c-4cf2-a63a-fd80df05284a',
              name: 'Buckling Restrained Braces',
              tradeId: '05 12 19',
            },
            {
              id: 'd118a8d7-b2fa-4492-a001-5f8028e7abee',
              name: 'Structural Steel for Buildings',
              tradeId: '05 12 23',
            },
            {
              id: '7923b832-9010-4d8b-b432-50cda41df650',
              name: 'Structural Steel for Bridges',
              tradeId: '05 12 33',
            },
          ],
          tradeId: '05 12 00',
        },
        {
          id: 'f5847859-c496-4174-9c6f-ba526c5c01c5',
          name: 'Structural Stainless-Steel Framing',
          children: [],
          tradeId: '05 13 00',
        },
        {
          id: '9eef723a-978a-441d-94bc-4631969cbeb5',
          name: 'Structural Aluminum Framing',
          children: [
            {
              id: '0e7d3bef-7378-49d5-bf67-9cc8cfb19072',
              name: 'Structural Aluminum Framing',
              tradeId: '05 14 00',
            },
            {
              id: '044ef110-9473-49d5-90f1-40f35680f712',
              name: 'Architecturally-Exposed Structural Aluminum Framing',
              tradeId: '05 14 13',
            },
          ],
          tradeId: '05 14 00',
        },
        {
          id: '886cf723-0e3c-4ac7-ae7f-51c84148167e',
          name: 'Wire Rope Assemblies',
          children: [
            {
              id: '41ecda3d-9525-4300-bb8c-a29b3668271b',
              name: 'Wire Rope Assemblies',
              tradeId: '05 15 00',
            },
            {
              id: '5d765eed-e902-4709-9cc6-28e0cfa39866',
              name: 'Aluminum Wire Rope Assemblies',
              tradeId: '05 15 13',
            },
            {
              id: '0170bb04-111c-49a9-aed8-5d659597addc',
              name: 'Steel Wire Rope Assemblies',
              tradeId: '05 15 16',
            },
            {
              id: 'b32cc013-7e27-43c4-814e-a63b33755949',
              name: 'Stainless-Steel Wire Rope Assemblies',
              tradeId: '05 15 19',
            },
          ],
          tradeId: '05 15 00',
        },
        {
          id: '73cf2409-639f-4aa2-acc3-c8ea37420c59',
          name: 'Structural Cabling',
          children: [
            {
              id: '69509a13-a38b-4204-806b-a9b33bb283b1',
              name: 'Structural Cabling',
              tradeId: '05 16 00',
            },
            {
              id: '93d763e0-090e-41cc-983c-6f154b8a8eed',
              name: 'Cable Bow Truss Assemblies',
              tradeId: '05 16 13',
            },
            {
              id: '593255bb-cc6f-4b76-8fa9-fd41f77a8796',
              name: 'Bridge Cabling',
              tradeId: '05 16 33',
            },
          ],
          tradeId: '05 16 00',
        },
        {
          id: '47053d8f-3e8d-4217-baf7-e44a3623ea30',
          name: 'Structural Rod Assemblies',
          children: [],
          tradeId: '05 17 00',
        },
        {
          id: '1a216009-abca-4e59-abf4-8427cf85581e',
          name: 'Tension Rod and Cable Truss Assemblies',
          children: [
            {
              id: 'd54db9d5-6d16-42c5-9661-4687343f5365',
              name: 'Tension Rod and Cable Truss Assemblies',
              tradeId: '05 19 00',
            },
            {
              id: '47b80eae-8507-4ad0-a652-a5a91842da08',
              name: 'Façade Support Truss Assemblies',
              tradeId: '05 19 13',
            },
            {
              id: '05855bac-deea-4313-b1ad-2c21eba6ebdd',
              name: 'Canopy Support Truss Assemblies',
              tradeId: '05 19 19',
            },
          ],
          tradeId: '05 19 00',
        },
        {
          id: '28d5e00c-1f72-4112-9f9d-b732173fd9c9',
          name: 'Metal Joists',
          children: [],
          tradeId: '05 20 00',
        },
        {
          id: 'b3919289-fed7-4a93-9eb2-e381a30cd2ed',
          name: 'Steel Joist Framing',
          children: [
            {
              id: 'ba0f8520-79f0-4c36-8d8a-f68f05ef91ca',
              name: 'Steel Joist Framing',
              tradeId: '05 21 00',
            },
            {
              id: '7fca394d-323c-46ca-aa25-3af5761c4ade',
              name: 'Deep Longspan Steel Joist Framing',
              tradeId: '05 21 13',
            },
            {
              id: 'f595f4b4-360d-4c20-977e-ec816ad27cb0',
              name: 'Longspan Steel Joist Framing',
              tradeId: '05 21 16',
            },
            {
              id: '4b489fdc-95cd-4a3e-b24c-7c60048622c2',
              name: 'Open Web Steel Joist Framing',
              tradeId: '05 21 19',
            },
            {
              id: '2ecde0d9-be95-4756-a544-a44f490ae3d2',
              name: 'Steel Joist Girder Framing',
              tradeId: '05 21 23',
            },
          ],
          tradeId: '05 21 00',
        },
        {
          id: 'e7db292b-47f4-40d8-8e0b-ead171fab57c',
          name: 'Aluminum Joist Framing',
          children: [],
          tradeId: '05 25 00',
        },
        {
          id: 'f9601558-cebe-4cf4-a28f-376fb5dfc5c4',
          name: 'Metal Decking',
          children: [],
          tradeId: '05 30 00',
        },
        {
          id: '1c6098e9-a468-4be1-8bab-6ddd84817cd0',
          name: 'Steel Decking',
          children: [
            {
              id: '9d62bf70-64a8-46be-9b31-7e1e91551e16',
              name: 'Steel Decking',
              tradeId: '05 31 00',
            },
            {
              id: '1c68622c-614a-455c-85f4-0fd76017febb',
              name: 'Steel Floor Decking',
              tradeId: '05 31 13',
            },
            {
              id: '0d90d3b5-a94d-43d4-bf69-991819c33865',
              name: 'Steel Roof Decking',
              tradeId: '05 31 23',
            },
            {
              id: '2687feac-6255-43d4-9350-e84548dc3bb0',
              name: 'Steel Form Decking',
              tradeId: '05 31 33',
            },
          ],
          tradeId: '05 31 00',
        },
        {
          id: '67172a0d-1ddc-4d82-bedc-374c3f81f8da',
          name: 'Aluminum Decking',
          children: [
            {
              id: 'f1f11f0a-dc88-40f4-b85e-ed25d0b53661',
              name: 'Aluminum Decking',
              tradeId: '05 33 00',
            },
            {
              id: 'c7cc7404-27f6-4a1e-baea-8bf7dc0e1231',
              name: 'Aluminum Floor Decking',
              tradeId: '05 33 13',
            },
            {
              id: '18af64ae-4623-424c-8bd2-75facc21ff82',
              name: 'Aluminum Roof Decking',
              tradeId: '05 33 23',
            },
          ],
          tradeId: '05 33 00',
        },
        {
          id: 'cafad15b-2097-40a3-93b2-d1eb48e2ff10',
          name: 'Acoustical Metal Decking',
          children: [],
          tradeId: '05 34 00',
        },
        {
          id: 'a6b494e7-d513-44f0-80f9-107ca0a78358',
          name: 'Raceway Decking Assemblies',
          children: [],
          tradeId: '05 35 00',
        },
        {
          id: '1bd057c6-fd82-4d9f-ae1d-6665bd783d26',
          name: 'Composite Metal Decking',
          children: [
            {
              id: '77df9da6-7ec9-45aa-9fc7-06c22c8935fa',
              name: 'Composite Metal Decking',
              tradeId: '05 36 00',
            },
            {
              id: '484db4ed-0072-4143-9752-89d0f513169e',
              name: 'Composite Steel Plate and Elastomer Decking',
              tradeId: '05 36 13',
            },
          ],
          tradeId: '05 36 00',
        },
        {
          id: '7a114913-f8ae-4782-8a5f-aff807e9ceb5',
          name: 'Cold-Formed Metal Framing',
          children: [],
          tradeId: '05 40 00',
        },
        {
          id: '0040058d-b901-47e7-8f73-00e085aa01be',
          name: 'Structural Metal Stud Framing',
          children: [],
          tradeId: '05 41 00',
        },
        {
          id: 'd868c124-6d28-40d3-bc1f-4f6f73a24708',
          name: 'Cold-Formed Metal Joist Framing',
          children: [
            {
              id: '379322ac-5463-4ea0-8825-df7f77bceae0',
              name: 'Cold-Formed Metal Joist Framing',
              tradeId: '05 42 00',
            },
            {
              id: '61d83cef-67a1-4ac4-8ec9-a5597d16db7f',
              name: 'Cold-Formed Metal Floor Joist Framing',
              tradeId: '05 42 13',
            },
            {
              id: '84e3ca0f-9f34-4ea7-a25e-8f242d1deec6',
              name: 'Cold-Formed Metal Roof Joist Framing',
              tradeId: '05 42 23',
            },
          ],
          tradeId: '05 42 00',
        },
        {
          id: 'e7b68c7e-5431-44b6-b697-e2fc78cae29e',
          name: 'Slotted Channel Framing',
          children: [],
          tradeId: '05 43 00',
        },
        {
          id: 'c194b699-ff9d-4455-afac-c63d18bf50d4',
          name: 'Cold-Formed Metal Trusses',
          children: [
            {
              id: '640fd243-b6ec-4ab9-b6a0-6daa421a912b',
              name: 'Cold-Formed Metal Trusses',
              tradeId: '05 44 00',
            },
            {
              id: '1189701a-ddf5-41d9-9e96-48d9eb4e7c96',
              name: 'Cold-Formed Metal Roof Trusses',
              tradeId: '05 44 13',
            },
          ],
          tradeId: '05 44 00',
        },
        {
          id: '3d90c497-81ba-4853-a01d-750401cba672',
          name: 'Metal Support Assemblies',
          children: [
            {
              id: 'c97b89bc-2623-4195-b10f-f2c19a8e971b',
              name: 'Metal Support Assemblies',
              tradeId: '05 45 00',
            },
            {
              id: '2bc49270-7219-4ef4-9c5c-bf099478a8e8',
              name: 'Mechanical Metal Supports',
              tradeId: '05 45 13',
            },
            {
              id: '106b2cfa-f216-431a-b64b-c68a0e6372ad',
              name: 'Electrical Metal Supports',
              tradeId: '05 45 16',
            },
            {
              id: 'f7cd8bd2-0e3e-4feb-93bc-6ed9143ab0cc',
              name: 'Communications Metal Supports',
              tradeId: '05 45 19',
            },
            {
              id: '2ff6120f-f427-46c2-8d77-a6cde295e908',
              name: 'Healthcare Metal Supports',
              tradeId: '05 45 23',
            },
          ],
          tradeId: '05 45 00',
        },
        {
          id: '7c0a4a56-98de-465a-ab5c-cecef338a121',
          name: 'Metal Fabrications',
          children: [],
          tradeId: '05 50 00',
        },
        {
          id: '4757aa3d-86dc-439f-932b-b7e9948c50d7',
          name: 'Metal Stairs',
          children: [
            {
              id: '837b2351-59af-4507-a999-321ae68840ce',
              name: 'Metal Stairs',
              tradeId: '05 51 00',
            },
            {
              id: '686d4736-ab45-4b62-8a64-ec4ef2414aa4',
              name: 'Metal Pan Stairs',
              tradeId: '05 51 13',
            },
            {
              id: '26b4f3ce-2e61-4e0f-8621-b2b74bf5444a',
              name: 'Metal Floor Plate Stairs',
              tradeId: '05 51 16',
            },
            {
              id: 'e244eacc-7953-4c4f-bbcb-ad896ae24b59',
              name: 'Metal Grating Stairs',
              tradeId: '05 51 19',
            },
            {
              id: '79e07311-2234-4428-891b-8793be29efe6',
              name: 'Metal Fire Escapes',
              tradeId: '05 51 23',
            },
            {
              id: '0a3174b3-15b2-46d2-9770-b5ee89c00d35',
              name: 'Metal Ladders',
              tradeId: '05 51 33',
            },
            {
              id: '41750516-7cc1-4aae-a60c-4b43734e0868',
              name: 'Vertical Metal Ladders',
              tradeId: '05 51 33.13',
            },
            {
              id: 'd706001c-6ef7-4565-a4f8-c3998524431b',
              name: 'Inclined Metal Ladders',
              tradeId: '05 51 33.16',
            },
            {
              id: 'dabb2ae9-c98f-4afc-b1d5-d4c13bd97128',
              name: 'Alternating Tread Ladders',
              tradeId: '05 51 33.23',
            },
            {
              id: 'd477832c-e6ed-42e7-b5af-527697debb38',
              name: 'Metal Walkways',
              tradeId: '05 51 36',
            },
            {
              id: '40072d90-3920-42fb-9828-0f8939144db7',
              name: 'Metal Catwalks',
              tradeId: '05 51 36.13',
            },
            {
              id: '190b0fd5-07e0-4d66-8bcf-3ebb8af5c6f8',
              name: 'Metal Ramps',
              tradeId: '05 51 36.16',
            },
          ],
          tradeId: '05 51 00',
        },
        {
          id: 'fca2c43a-c649-4a3d-b90b-b5fccae88757',
          name: 'Metal Railings',
          children: [
            {
              id: 'e36cdf1e-74fa-4c14-8e99-2f53becdd613',
              name: 'Metal Railings',
              tradeId: '05 52 00',
            },
            {
              id: 'fd5b6913-58cc-41d6-8e75-07ef22499ee5',
              name: 'Pipe and Tube Railings',
              tradeId: '05 52 13',
            },
          ],
          tradeId: '05 52 00',
        },
        {
          id: 'd2489b0e-592b-4e0b-b33b-5f11cdb3a1b0',
          name: 'Metal Gratings',
          children: [
            {
              id: '0f4b3d10-a883-4b3d-8dda-28ca9308e2a7',
              name: 'Metal Gratings',
              tradeId: '05 53 00',
            },
            {
              id: 'eae14acc-5343-4e98-9abb-608100a46d47',
              name: 'Bar Gratings',
              tradeId: '05 53 13',
            },
            {
              id: '2bac2296-f411-44be-959f-9863999a121c',
              name: 'Plank Gratings',
              tradeId: '05 53 16',
            },
          ],
          tradeId: '05 53 00',
        },
        {
          id: '834109c6-5aa7-444b-8623-f59c7ec39a5a',
          name: 'Metal Floor Plates',
          children: [],
          tradeId: '05 54 00',
        },
        {
          id: 'dc9c88f4-5322-466f-939d-3239fc043fe1',
          name: 'Metal Stair Treads and Nosings',
          children: [
            {
              id: '52cda740-c105-4c47-b31f-d8717fec2ffc',
              name: 'Metal Stair Treads and Nosings',
              tradeId: '05 55 00',
            },
            {
              id: '43c1751b-4fbc-49e0-8779-3541f427b661',
              name: 'Metal Stair Treads',
              tradeId: '05 55 13',
            },
            {
              id: 'fa5b5abc-53aa-4788-9f90-63087f223efc',
              name: 'Metal Stair Nosings',
              tradeId: '05 55 16',
            },
          ],
          tradeId: '05 55 00',
        },
        {
          id: '87c5d663-692f-4d35-baf8-0b134966cfee',
          name: 'Metal Castings',
          children: [],
          tradeId: '05 56 00',
        },
        {
          id: 'b1a564d2-7070-4c18-8700-7163bb6a91c0',
          name: 'Formed Metal Fabrications',
          children: [
            {
              id: 'a658ab83-c387-4459-942f-473083e369e4',
              name: 'Formed Metal Fabrications',
              tradeId: '05 58 00',
            },
            {
              id: 'e86f8b52-ba8f-4586-b355-e85d90640048',
              name: 'Column Covers',
              tradeId: '05 58 13',
            },
            {
              id: 'dce15012-10d5-4230-9b90-3e599e483ffc',
              name: 'Formed Metal Enclosures',
              tradeId: '05 58 16',
            },
            {
              id: 'c4763fa3-096f-405b-8b83-b47baf0e14ed',
              name: 'Heating/Cooling Unit Covers',
              tradeId: '05 58 19',
            },
            {
              id: '832b0151-37d0-42c5-a97a-11e7264f3fe9',
              name: 'Formed Metal Guards',
              tradeId: '05 58 23',
            },
          ],
          tradeId: '05 58 00',
        },
        {
          id: '32220379-3db7-4f0f-bad4-82626fb9a0f3',
          name: 'Metal Specialties',
          children: [
            {
              id: '979ffbe6-3146-4142-8e12-844875efe706',
              name: 'Metal Specialties',
              tradeId: '05 59 00',
            },
            {
              id: 'b0e918b4-df15-4f47-8d8b-22feec4a3368',
              name: 'Metal Balconies',
              tradeId: '05 59 13',
            },
            {
              id: '6b54977f-b805-4bcd-a1a4-aa3ce672797b',
              name: 'Detention Enclosures',
              tradeId: '05 59 63',
            },
          ],
          tradeId: '05 59 00',
        },
        {
          id: '9b60a15c-0733-4794-97db-ac35dcd73279',
          name: 'Decorative Metal',
          children: [],
          tradeId: '05 70 00',
        },
        {
          id: '28436d11-d34c-4801-ab21-c3b3ef544976',
          name: 'Decorative Metal Stairs',
          children: [
            {
              id: 'db410d84-2616-4756-8475-2870dabb4d8f',
              name: 'Decorative Metal Stairs',
              tradeId: '05 71 00',
            },
            {
              id: 'bc517575-e862-47bd-9205-0471eb1e226e',
              name: 'Fabricated Metal Spiral Stairs',
              tradeId: '05 71 13',
            },
          ],
          tradeId: '05 71 00',
        },
        {
          id: '92ca0f98-e207-4f76-9b70-5209d51be40b',
          name: 'Decorative Metal Railings',
          children: [
            {
              id: 'd8780bf3-9c50-4025-9c4e-8c436d7c7a68',
              name: 'Decorative Metal Railings',
              tradeId: '05 73 00',
            },
            {
              id: '76b1647b-461b-4690-99e1-f784751cc91b',
              name: 'Glazed Decorative Metal Railings',
              tradeId: '05 73 13',
            },
            {
              id: '708f83cb-6407-4d16-8a65-d5dd44c999e2',
              name: 'Wire Rope Decorative Metal Railings',
              tradeId: '05 73 16',
            },
          ],
          tradeId: '05 73 00',
        },
        {
          id: 'a6d1cf16-2bdd-4f06-94ff-b8d073b5a6d3',
          name: 'Decorative Metal Castings',
          children: [],
          tradeId: '05 74 00',
        },
        {
          id: '06d1662f-cf9e-47e4-b2d5-a74e7026fad6',
          name: 'Decorative Formed Metal',
          children: [],
          tradeId: '05 75 00',
        },
        {
          id: '78614b93-6571-46bf-905f-0bd8830b7068',
          name: 'Decorative Forged Metal',
          children: [],
          tradeId: '05 76 00',
        },
      ],
      tradeId: '05 00 00',
    },
    {
      id: '003b4630-1c10-42e8-a2d1-5032cb41d7b9',
      name: 'Wood, Plastics and Composites',
      children: [
        {
          id: '71ec7015-5de5-4b38-ab41-324997c9eacd',
          name: 'Wood, Plastics and Composites',
          children: [],
          tradeId: '06 00 00',
        },
        {
          id: '63cf53cc-c671-454a-8c46-6a5f3189e64a',
          name: 'Maintenance of Wood, Plastics and Composites',
          children: [
            {
              id: '1f8e3c17-a8b9-49ad-929c-418fdff2b25c',
              name: 'Maintenance of Wood, Plastics and Composites',
              tradeId: '06 01 00',
            },
            {
              id: '122d9067-35d6-41cc-8c11-30a330ea2867',
              name: 'Maintenance of Rough Carpentry',
              tradeId: '06 01 10',
            },
            {
              id: '5c71da17-ede9-46ee-958c-1ccccec55a46',
              name: 'Rough Carpentry Rehabilitation',
              tradeId: '06 01 10.71',
            },
            {
              id: 'cf94b8db-0560-4e18-b371-ae03654d36a5',
              name: 'Rough Carpentry Restoration',
              tradeId: '06 01 10.91',
            },
            {
              id: 'df3be25e-a6a4-41a9-b661-7001fb96881c',
              name: 'Rough Carpentry Preservation',
              tradeId: '06 01 10.92',
            },
            {
              id: 'e164a7d4-bd38-4a68-867d-9d0ec65e4d37',
              name: 'Maintenance of Finish Carpentry',
              tradeId: '06 01 20',
            },
            {
              id: 'fbfb167a-4354-462a-a6a5-702b7b7223c7',
              name: 'Finish Carpentry Rehabilitation',
              tradeId: '06 01 20.71',
            },
            {
              id: '148719fe-369e-48cc-be2b-053a65ff0913',
              name: 'Finish Carpentry Restoration',
              tradeId: '06 01 20.91',
            },
            {
              id: '54cdf464-c753-4726-8da0-b8ec7aea4292',
              name: 'Finish Carpentry Preservation',
              tradeId: '06 01 20.92',
            },
            {
              id: 'd79b3663-2f2a-41e3-aaa3-f7aa93c0bb64',
              name: 'Maintenance of Architectural Woodwork',
              tradeId: '06 01 40',
            },
            {
              id: '06031212-fa77-4c37-a450-7f6439feb3d9',
              name: 'Architectural Woodwork Cleaning',
              tradeId: '06 01 40.51',
            },
            {
              id: '2996d741-ecc0-427f-806b-33cf78a0b6e2',
              name: 'Architectural Woodwork Refinishing',
              tradeId: '06 01 40.61',
            },
            {
              id: '2fbfc2e2-b3cb-46d4-b0d4-44d48e13e8bd',
              name: 'Architectural Woodwork Restoration',
              tradeId: '06 01 40.91',
            },
            {
              id: 'd16ca77a-3d9b-45ef-8bda-c14135584189',
              name: 'Maintenance of Structural Plastics',
              tradeId: '06 01 50',
            },
            {
              id: '490597cb-1307-4ce3-a43b-bd4ef8ba8a4c',
              name: 'Maintenance of Plastic Fabrications',
              tradeId: '06 01 60',
            },
            {
              id: '37eedd4d-d1e9-4856-a4df-16b8e7bf827a',
              name: 'Plastic Cleaning',
              tradeId: '06 01 60.51',
            },
            {
              id: '524dd479-70ae-42cc-974a-0779c2e66b4b',
              name: 'Plastic Rehabilitation',
              tradeId: '06 01 60.71',
            },
            {
              id: '5adaabae-de8d-463f-a87c-5a0a253457f3',
              name: 'Plastic Restoration',
              tradeId: '06 01 60.91',
            },
            {
              id: '77ae0055-5a27-45c6-875d-f2083663084f',
              name: 'Plastic Preservation',
              tradeId: '06 01 60.92',
            },
            {
              id: 'e4c24053-8858-4919-8b87-ba9744a2f59b',
              name: 'Maintenance of Structural Composites',
              tradeId: '06 01 70',
            },
            {
              id: '72560dae-e5d4-4141-b163-d4db2c9c52be',
              name: 'Maintenance of Composite Assemblies',
              tradeId: '06 01 80',
            },
            {
              id: '3a105ef0-81ca-4b8a-aa8f-2f8077ac3a9f',
              name: 'Composite Cleaning',
              tradeId: '06 01 80.51',
            },
            {
              id: '982de23b-1763-46c0-8077-28f53b2b0232',
              name: 'Composite Rehabilitation',
              tradeId: '06 01 80.71',
            },
            {
              id: '23b8ef99-e254-4376-81f8-ec5b5a3a0268',
              name: 'Composite Restoration',
              tradeId: '06 01 80.91',
            },
            {
              id: '12552fd3-b571-4cd4-8264-22632f4977e6',
              name: 'Composite Preservation',
              tradeId: '06 01 80.92',
            },
          ],
          tradeId: '06 01 00',
        },
        {
          id: 'd1848208-7eb0-4708-b317-1fc2054b8170',
          name: 'Common Work Results for Wood, Plastics and Composites',
          children: [
            {
              id: '44164946-1b31-478b-87be-bf93a499a7f5',
              name: 'Common Work Results for Wood, Plastics and Composites',
              tradeId: '06 05 00',
            },
            {
              id: '7aefabeb-e1ab-49c4-a2ea-6bfdc5470fc6',
              name: 'Wood, Plastic and Composite Fastenings',
              tradeId: '06 05 23',
            },
            {
              id: '76ab376e-61fe-4748-9935-4aa9f1f65381',
              name: 'Wood Treatment',
              tradeId: '06 05 73',
            },
            {
              id: '6d8d9572-8665-4884-af4c-9dbd67486e88',
              name: 'Fire-Retardant Wood Treatment',
              tradeId: '06 05 73.13',
            },
            {
              id: '8926ea9f-1f2c-486a-b903-ee0fd4ffd918',
              name: 'Preservative Wood Treatment',
              tradeId: '06 05 73.33',
            },
            {
              id: 'b8ea3ea1-1b58-44d1-88ed-c8acbd1b81a6',
              name: 'Long-Term Wood Treatment',
              tradeId: '06 05 73.91',
            },
            {
              id: '3342d729-9029-4bb4-afd3-fc170cf14e1f',
              name: 'Eradication of Insects in Wood',
              tradeId: '06 05 73.93',
            },
            {
              id: 'aac5aefd-9d6f-4ef2-b0c3-74c40f4a3c39',
              name: 'Antiseptic Treatment of Wood',
              tradeId: '06 05 73.96',
            },
            {
              id: 'fc7cdef2-a369-438a-ba99-825fe0cb2b79',
              name: 'Shop-Applied Wood Coatings',
              tradeId: '06 05 83',
            },
          ],
          tradeId: '06 05 00',
        },
        {
          id: 'b525d5a3-dc52-49e3-a9ee-eaa3cc8c7a28',
          name: 'Schedules for Wood, Plastics and Composites',
          children: [
            {
              id: '9fa4011b-e6de-44e1-a9f9-a429d7a9307b',
              name: 'Schedules for Wood, Plastics and Composites',
              tradeId: '06 06 00',
            },
            {
              id: 'dd317186-5c55-4c98-af9b-eb6c71433869',
              name: 'Schedules for Rough Carpentry',
              tradeId: '06 06 10',
            },
            {
              id: '6faf655a-f6a3-4c83-be4c-17bb2399cb1b',
              name: 'Nailing Schedule',
              tradeId: '06 06 10.13',
            },
            {
              id: '6f2779c6-e8fc-492a-a370-839b150fcd4f',
              name: 'Wood Beam Schedule',
              tradeId: '06 06 10.16',
            },
            {
              id: '7cf945aa-243c-42a6-9808-95380805dd68',
              name: 'Plywood Shear Wall Schedule',
              tradeId: '06 06 10.19',
            },
            {
              id: '5e53b502-5ac6-4b19-b9ed-90d58d63979e',
              name: 'Plywood Web Joist Schedule',
              tradeId: '06 06 10.23',
            },
            {
              id: '0b07bc44-cbe1-46ba-b15f-a3526b892d50',
              name: 'Wood Truss Schedule',
              tradeId: '06 06 10.26',
            },
            {
              id: 'ab667d5f-f24c-4682-ac99-ec669e21c280',
              name: 'Schedules for Finish Carpentry',
              tradeId: '06 06 20',
            },
            {
              id: 'cea9a0f8-c8c7-4c56-86bb-99b855551d0e',
              name: 'Schedules for Architectural Woodwork',
              tradeId: '06 06 40',
            },
            {
              id: 'db7108e3-e0d3-4d67-a9b3-3e96e2084a41',
              name: 'Schedules for Structural Plastics',
              tradeId: '06 06 50',
            },
            {
              id: '1c72cc3c-eea5-4e59-ba26-adf111d41e53',
              name: 'Schedules for Plastic Fabrications',
              tradeId: '06 06 60',
            },
            {
              id: 'c093d3db-45c1-4232-b4fd-f81ac438f2e1',
              name: 'Schedules for Structural Composites',
              tradeId: '06 06 70',
            },
            {
              id: '4618c5ba-16dc-47df-94d3-22a0ceed8ae7',
              name: 'Schedules for Composite Assemblies',
              tradeId: '06 06 80',
            },
          ],
          tradeId: '06 06 00',
        },
        {
          id: '317efe81-a951-47dc-9310-9ededcf8302f',
          name: 'Commissioning of Wood, Plastics and Composites',
          children: [],
          tradeId: '06 08 00',
        },
        {
          id: '221e3571-a524-49d7-bb5e-62dd6e7d392a',
          name: 'Rough Carpentry',
          children: [
            {
              id: 'dd1b476a-f0a3-473b-8a29-c1474d04120e',
              name: 'Rough Carpentry',
              tradeId: '06 10 00',
            },
            {
              id: '7e1f2a5e-afd6-4e5c-bd83-8fa516789f79',
              name: 'Miscellaneous Rough Carpentry',
              tradeId: '06 10 53',
            },
            {
              id: '5cba0e74-163c-497d-970d-d3513c0404c6',
              name: 'Exterior Rough Carpentry',
              tradeId: '06 10 63',
            },
          ],
          tradeId: '06 10 00',
        },
        {
          id: '14a32e9d-b9e2-48b2-9d11-b5db53f2d889',
          name: 'Wood Framing',
          children: [
            {
              id: 'a5c61937-d890-475e-8cf5-ddcb66e13bb1',
              name: 'Wood Framing',
              tradeId: '06 11 00',
            },
            {
              id: '11ceb17a-20f8-446b-b424-7e9dd4d86df8',
              name: 'Engineered Wood Products',
              tradeId: '06 11 13',
            },
            {
              id: 'ed17104f-df28-4dec-b27d-d81b6d7e9168',
              name: 'Mechanically Graded Lumber',
              tradeId: '06 11 16',
            },
          ],
          tradeId: '06 11 00',
        },
        {
          id: '03cc7076-1443-43c5-80ec-2eda94d68183',
          name: 'Structural Panels',
          children: [
            {
              id: '26ed211d-219d-4f3d-b8b0-9ef53f7be403',
              name: 'Structural Panels',
              tradeId: '06 12 00',
            },
            {
              id: '086226e0-c679-4d20-ac52-fe9c7482a5a0',
              name: 'Cementitious Reinforced Panels',
              tradeId: '06 12 13',
            },
            {
              id: 'ec94c32f-e9e3-488d-96cc-a58faf597966',
              name: 'Stressed Skin Panels',
              tradeId: '06 12 16',
            },
          ],
          tradeId: '06 12 00',
        },
        {
          id: 'e416c8dd-856a-43e4-b6b5-6a400342d8a1',
          name: 'Heavy Timber Construction',
          children: [
            {
              id: '12ca75ef-468e-4132-aefb-20c6f2a20112',
              name: 'Heavy Timber Construction',
              tradeId: '06 13 00',
            },
            {
              id: 'dc1efff9-2d6d-426b-a207-97ce8752e2c6',
              name: 'Log Construction',
              tradeId: '06 13 13',
            },
            {
              id: '781f402a-8392-414d-a2ff-82498660c1e7',
              name: 'Period Horizontal Log Work',
              tradeId: '06 13 13.91',
            },
            {
              id: '774bb62f-ac0b-401a-a257-b3cb3bf38856',
              name: 'Pole Construction',
              tradeId: '06 13 16',
            },
            {
              id: 'f82d711c-4503-40fc-b852-228b5ff96a68',
              name: 'Heavy Timber Framing',
              tradeId: '06 13 23',
            },
            {
              id: '62dcf756-6a22-48f0-8377-121cfc84a245',
              name: 'Heavy Timber Trusses',
              tradeId: '06 13 26',
            },
            {
              id: '3c48f070-de35-47de-9418-fd2b9cac03a8',
              name: 'Heavy Timber Pier Construction',
              tradeId: '06 13 33',
            },
          ],
          tradeId: '06 13 00',
        },
        {
          id: 'b3fe3ecc-5d0d-46d2-a348-034be36d35eb',
          name: 'Treated Wood Foundations',
          children: [],
          tradeId: '06 14 00',
        },
        {
          id: '0402a92b-74ef-445f-a620-b518292f786c',
          name: 'Wood Decking',
          children: [
            {
              id: 'e4baff59-58ec-42d1-a4f6-7cf5348c9d14',
              name: 'Wood Decking',
              tradeId: '06 15 00',
            },
            {
              id: 'a7a0fe18-b687-4695-b613-271f6bd34790',
              name: 'Wood Floor Decking',
              tradeId: '06 15 13',
            },
            {
              id: 'e0ef40e0-7763-4d1f-a937-b52b151fba9b',
              name: 'Carvel Planking',
              tradeId: '06 15 13.91',
            },
            {
              id: '082c72b8-90b3-4b02-b1c1-1ed777f857e2',
              name: 'Wood Roof Decking',
              tradeId: '06 15 16',
            },
            {
              id: '5529886f-6cd6-47c6-ae06-b8f1ab0e9898',
              name: 'Timber Decking',
              tradeId: '06 15 19',
            },
            {
              id: '60f1a897-915c-4226-8a0c-7f41b7688251',
              name: 'Laminated Wood Decking',
              tradeId: '06 15 23',
            },
            {
              id: '76b20107-6bc4-4d0f-94aa-658869cea9cb',
              name: 'Wood Patio Decking',
              tradeId: '06 15 33',
            },
          ],
          tradeId: '06 15 00',
        },
        {
          id: '0d5d2a25-5722-4926-af93-191109c6443e',
          name: 'Sheathing',
          children: [
            {
              id: 'cea5e2b9-e03a-436b-ab59-2671620436fd',
              name: 'Sheathing',
              tradeId: '06 16 00',
            },
            {
              id: 'adee3e3c-c237-463a-b34b-4c05447947fd',
              name: 'Insulating Sheathing',
              tradeId: '06 16 13',
            },
            {
              id: '84eb8079-b47d-45de-ac31-a996a799ec1d',
              name: 'Subflooring',
              tradeId: '06 16 23',
            },
            {
              id: '6384cc92-abf4-45d0-995e-241997153400',
              name: 'Underlayment',
              tradeId: '06 16 26',
            },
            {
              id: 'd28025b2-d891-45c4-8cc3-e5793dd4b132',
              name: 'Wood Board Sheathing',
              tradeId: '06 16 33',
            },
            {
              id: 'f1f8613c-ad03-461b-9ca3-d41eaa9035a9',
              name: 'Wood Panel Product Sheathing',
              tradeId: '06 16 36',
            },
            {
              id: '03070294-6a51-488b-b19b-9001673bb20e',
              name: 'Gypsum Sheathing',
              tradeId: '06 16 43',
            },
            {
              id: '4c869e19-52fc-42f4-91b4-fcccadc0edef',
              name: 'Moisture-Resistant Sheathing Board',
              tradeId: '06 16 53',
            },
            {
              id: 'b2914df8-f3be-46f9-8e96-20e504fb3f6a',
              name: 'Cementitious Sheathing',
              tradeId: '06 16 63',
            },
          ],
          tradeId: '06 16 00',
        },
        {
          id: 'f751cb00-0e9e-4ede-87b6-a6e0b53b94b1',
          name: 'Shop-Fabricated Structural Wood',
          children: [
            {
              id: '0787923c-d95c-4ec2-a6fe-55e8f20438f3',
              name: 'Shop-Fabricated Structural Wood',
              tradeId: '06 17 00',
            },
            {
              id: '2ca4acc5-8cf7-4175-86c1-522fab800b71',
              name: 'Laminated Veneer Lumber',
              tradeId: '06 17 13',
            },
            {
              id: 'f6504b2b-40af-4416-a5cd-91523e1348e5',
              name: 'Parallel Strand Lumber',
              tradeId: '06 17 23',
            },
            {
              id: '26b4d4bc-ab02-49b9-b846-c600a252c708',
              name: 'Wood I-Joists',
              tradeId: '06 17 33',
            },
            {
              id: '2621bb4f-1903-46b2-a9f3-d7c0be35bfeb',
              name: 'Metal-Web Wood Joists',
              tradeId: '06 17 36',
            },
            {
              id: 'ac5b146c-b084-493b-bbb7-365149930ee6',
              name: 'Rim Boards',
              tradeId: '06 17 43',
            },
            {
              id: 'e64debbf-89c1-4769-824c-b9ff206e5dc1',
              name: 'Shop-Fabricated Wood Trusses',
              tradeId: '06 17 53',
            },
          ],
          tradeId: '06 17 00',
        },
        {
          id: 'ed52ff64-2e08-44d2-af0b-74affd467bf2',
          name: 'Glued-Laminated Construction',
          children: [
            {
              id: '98f69bf8-4fd5-462d-a9de-2051a18ac153',
              name: 'Glued-Laminated Construction',
              tradeId: '06 18 00',
            },
            {
              id: 'd55570f8-376f-4d57-866f-f4d952773c97',
              name: 'Glued-Laminated Beams',
              tradeId: '06 18 13',
            },
            {
              id: '434d3450-537b-40b4-9e55-3a2078d98cde',
              name: 'Glued-Laminated Columns',
              tradeId: '06 18 16',
            },
          ],
          tradeId: '06 18 00',
        },
        {
          id: 'b8b68dec-0ea8-4eed-9c0e-6e4353f4f9db',
          name: 'Finish Carpentry',
          children: [
            {
              id: '7ab32ed0-4521-4566-a14e-4b832d87f57f',
              name: 'Finish Carpentry',
              tradeId: '06 20 00',
            },
            {
              id: 'a7796d2e-6fb4-4b85-a0ce-6b4ccf1923ab',
              name: 'Exterior Finish Carpentry',
              tradeId: '06 20 13',
            },
            {
              id: '8a2a4217-0514-476d-8c9a-24a0f29e4e84',
              name: 'Interior Finish Carpentry',
              tradeId: '06 20 23',
            },
          ],
          tradeId: '06 20 00',
        },
        {
          id: '32d4eb19-5aac-4bad-9d6a-b81bcad8feca',
          name: 'Millwork',
          children: [
            {
              id: 'cf0cd050-1cb9-4777-b102-9f2aa2b9135d',
              name: 'Millwork',
              tradeId: '06 22 00',
            },
            {
              id: '9383e09d-0b7a-480c-ae65-3238e0092ecb',
              name: 'Standard Pattern Wood Trim',
              tradeId: '06 22 13',
            },
          ],
          tradeId: '06 22 00',
        },
        {
          id: 'b9db51dd-d347-4f01-bfc8-5b5ed0e11bf1',
          name: 'Prefinished Paneling',
          children: [
            {
              id: '1992129c-d4e1-4508-854f-bf284a742cd2',
              name: 'Prefinished Paneling',
              tradeId: '06 25 00',
            },
            {
              id: 'e27c1474-5ead-4771-affa-c29e6b125a00',
              name: 'Prefinished Hardboard Paneling',
              tradeId: '06 25 13',
            },
            {
              id: 'bbb7cd29-99b9-4c87-bc6c-d3b714839fa1',
              name: 'Prefinished Plywood Paneling',
              tradeId: '06 25 16',
            },
          ],
          tradeId: '06 25 00',
        },
        {
          id: 'b425b3cb-1293-4a85-aa0d-1ada1278cd25',
          name: 'Board Paneling',
          children: [
            {
              id: 'dfa7534d-e68e-4855-8ae7-45bc73d24f6f',
              name: 'Board Paneling',
              tradeId: '06 26 00',
            },
            {
              id: '3263148f-4b25-4715-bca2-b8e7cb7ed04c',
              name: 'Profile Board Paneling',
              tradeId: '06 26 13',
            },
          ],
          tradeId: '06 26 00',
        },
        {
          id: 'c6832134-20a1-4b55-9740-2260801908f7',
          name: 'Architectural Woodwork',
          children: [
            {
              id: '7770dddc-8bb3-4250-99f1-acca8e83101c',
              name: 'Architectural Woodwork',
              tradeId: '06 40 00',
            },
            {
              id: '033baae5-d4af-417e-9f55-cae2273c9120',
              name: 'Exterior Architectural Woodwork',
              tradeId: '06 40 13',
            },
            {
              id: '25346fb0-88fa-4876-8509-783195b7ed2d',
              name: 'Interior Architectural Woodwork',
              tradeId: '06 40 23',
            },
          ],
          tradeId: '06 40 00',
        },
        {
          id: '5d875a12-b7e8-4858-80fc-7a57b10310bb',
          name: 'Architectural Wood Casework',
          children: [
            {
              id: '3711fbe6-178a-4508-b7d8-1e8d2d1899db',
              name: 'Architectural Wood Casework',
              tradeId: '06 41 00',
            },
            {
              id: 'bc47ccd7-2ab8-4094-8b6c-cd984e4cc663',
              name: 'Wood-Veneer-Faced Architectural Cabinets',
              tradeId: '06 41 13',
            },
            {
              id: 'fa447164-d053-4f14-a1d6-e1f9019baf48',
              name: 'Plastic-Laminate-Clad Architectural Cabinets',
              tradeId: '06 41 16',
            },
            {
              id: 'e1b6b654-c039-4f08-9b82-20fb17c70281',
              name: 'Cabinet and Drawer Hardware',
              tradeId: '06 41 93',
            },
          ],
          tradeId: '06 41 00',
        },
        {
          id: 'baa18292-67a2-45e6-839c-0157a07ba2b7',
          name: 'Wood Paneling',
          children: [
            {
              id: '21a58a79-c58e-4ebc-b57f-d4ff7d60738d',
              name: 'Wood Paneling',
              tradeId: '06 42 00',
            },
            {
              id: '105a144d-2451-494c-9e7c-750fbb3bb819',
              name: 'Wood Board Paneling',
              tradeId: '06 42 13',
            },
            {
              id: '58e0b161-8f2b-468b-b5ec-ee4894553dd4',
              name: 'Stile and Rail Wood Paneling',
              tradeId: '06 42 14',
            },
            {
              id: 'fbb22e14-6f5c-4622-b20c-c42a3f9760ca',
              name: 'Flush Wood Paneling',
              tradeId: '06 42 16',
            },
            {
              id: 'e1bcb9b6-09c4-42f6-a424-db8c5505d254',
              name: 'Plastic-Laminate-Faced Wood Paneling',
              tradeId: '06 42 19',
            },
          ],
          tradeId: '06 42 00',
        },
        {
          id: 'e2ef5dee-e24a-4799-960b-099bf4dadcd1',
          name: 'Wood Stairs and Railings',
          children: [
            {
              id: 'f9be40c2-0eb9-4802-9dbb-768b20c45a49',
              name: 'Wood Stairs and Railings',
              tradeId: '06 43 00',
            },
            {
              id: 'a2d8e030-d761-4ddc-bedf-866b59d89c39',
              name: 'Wood Stairs',
              tradeId: '06 43 13',
            },
            {
              id: '25c51b97-81bb-4ca4-8ffe-760686733144',
              name: 'Wood Railings',
              tradeId: '06 43 16',
            },
          ],
          tradeId: '06 43 00',
        },
        {
          id: '2e4a96f2-ede8-49d2-ac17-445710c8866c',
          name: 'Ornamental Woodwork',
          children: [
            {
              id: 'aad98f19-73ad-46fa-afc7-2f2240141258',
              name: 'Ornamental Woodwork',
              tradeId: '06 44 00',
            },
            {
              id: 'a2d02f8b-cb9c-4801-8b18-317a835486eb',
              name: 'Wood Turnings',
              tradeId: '06 44 13',
            },
            {
              id: 'e51e46bf-250a-4421-b89e-6245d85021dd',
              name: 'Wood Pilasters',
              tradeId: '06 44 16',
            },
            {
              id: 'eb4a3636-f954-4ab4-bc49-17e7d4d9c1a3',
              name: 'Wood Grilles',
              tradeId: '06 44 19',
            },
            {
              id: 'cb91ffc8-9599-4c11-8c6c-52991d0766c7',
              name: 'Wood Corbels',
              tradeId: '06 44 23',
            },
            {
              id: '2593e38f-22ed-4685-8faf-6ea8d4cc3fe1',
              name: 'Wood Cupolas',
              tradeId: '06 44 26',
            },
            {
              id: '5c9ab04f-666f-461c-930f-3d3cb6af1198',
              name: 'Wood Finials',
              tradeId: '06 44 29',
            },
            {
              id: '03e60529-35df-42c9-acea-90e6711aac66',
              name: 'Wood Mantels',
              tradeId: '06 44 33',
            },
            {
              id: '2f42ba1f-8dbc-45c9-9b5a-cc73196b833a',
              name: 'Wood Pediment Heads',
              tradeId: '06 44 36',
            },
            {
              id: '02db84dd-356d-4adb-98a5-3cd72878874b',
              name: 'Wood Posts and Columns',
              tradeId: '06 44 39',
            },
          ],
          tradeId: '06 44 00',
        },
        {
          id: '4a88bbfb-be21-491a-9a5a-50cc715922c3',
          name: 'Wood Trim',
          children: [
            {
              id: '68f2bbf5-f1fa-4112-83d9-df60d2134a37',
              name: 'Wood Trim',
              tradeId: '06 46 00',
            },
            {
              id: 'adc50d7a-809b-4038-b85a-1a4b039e050c',
              name: 'Wood Door and Window Casings',
              tradeId: '06 46 13',
            },
            {
              id: 'a6b342db-9031-4198-821b-eaeedfa91e7b',
              name: 'Wood Aprons',
              tradeId: '06 46 16',
            },
            {
              id: '0b64e4aa-6888-4fcb-b4a3-6fb018bbdcee',
              name: 'Wood Base and Shoe Moldings',
              tradeId: '06 46 19',
            },
            {
              id: '8fad2b20-0426-46d9-af04-3294d75135aa',
              name: 'Wood Chair Rails',
              tradeId: '06 46 23',
            },
            {
              id: 'cbd304e4-6644-463f-b9f5-3cd91aed758d',
              name: 'Wood Cornices',
              tradeId: '06 46 26',
            },
            {
              id: '378bbf88-3ff1-42bd-b45a-70c457bde8ef',
              name: 'Wood Fasciae and Soffits',
              tradeId: '06 46 29',
            },
            {
              id: 'b1e92e58-c5ae-4c69-8583-98e22460e9a5',
              name: 'Wood Stops, Stools and Sills',
              tradeId: '06 46 33',
            },
            {
              id: '0736ae4f-328b-4c60-9d8a-ce1e7ca5168e',
              name: 'Splicing of Wooden Components',
              tradeId: '06 46 91',
            },
          ],
          tradeId: '06 46 00',
        },
        {
          id: '200667f1-a471-4c77-b1af-ac352badfe44',
          name: 'Wood Frames',
          children: [
            {
              id: 'ec04fb43-017d-4ee7-b53a-9049f8c456b7',
              name: 'Wood Frames',
              tradeId: '06 48 00',
            },
            {
              id: 'e4e57462-ae5b-4cc0-a66a-6380322492b1',
              name: 'Exterior Wood Door Frames',
              tradeId: '06 48 13',
            },
            {
              id: 'aaa46cf8-cb5d-4dfc-8de7-63c4b8f47d64',
              name: 'Interior Wood Door Frames',
              tradeId: '06 48 16',
            },
            {
              id: '4cdebedf-17b7-4eef-92ee-685ca564a887',
              name: 'Ornamental Wood Frames',
              tradeId: '06 48 19',
            },
            {
              id: '588c58a3-521b-4e1d-85e6-99a62b22fad2',
              name: 'Stick-Built Wood Windows',
              tradeId: '06 48 23',
            },
            {
              id: '85887a3d-9789-48f1-ad06-cac7778144e3',
              name: 'Wood-Veneer Frames',
              tradeId: '06 48 26',
            },
          ],
          tradeId: '06 48 00',
        },
        {
          id: 'a43b51f8-16c0-43e6-9f35-813c252d00ba',
          name: 'Wood Screens and Exterior Wood Shutters',
          children: [
            {
              id: '39d90ec9-3e66-42a7-847f-55d6c8f00849',
              name: 'Wood Screens and Exterior Wood Shutters',
              tradeId: '06 49 00',
            },
            {
              id: '3f5f332f-d30a-4eec-8d53-d5435f9482e7',
              name: 'Wood Screens',
              tradeId: '06 49 13',
            },
            {
              id: '2e0f03eb-f9ab-469c-923f-d563372850e0',
              name: 'Exterior Wood Blinds',
              tradeId: '06 49 16',
            },
            {
              id: '25d85217-5591-485a-ab0a-638d29978127',
              name: 'Exterior Wood Shutters',
              tradeId: '06 49 19',
            },
          ],
          tradeId: '06 49 00',
        },
        {
          id: 'ff1b297c-4bbb-4cdb-9452-9968390f126c',
          name: 'Structural Plastics',
          children: [],
          tradeId: '06 50 00',
        },
        {
          id: '4e16c526-1628-4f9a-8f83-218bdf6497ef',
          name: 'Structural Plastic Shapes and Plates',
          children: [
            {
              id: '190a22ee-213b-4988-b74d-ad4d12709ed3',
              name: 'Structural Plastic Shapes and Plates',
              tradeId: '06 51 00',
            },
            {
              id: '7f902e8f-3f81-477d-a7a4-9cdd466f2e1e',
              name: 'Plastic Lumber',
              tradeId: '06 51 13',
            },
          ],
          tradeId: '06 51 00',
        },
        {
          id: '1aad83da-64cb-42e4-95fa-4012f1a72a71',
          name: 'Plastic Structural Assemblies',
          children: [],
          tradeId: '06 52 00',
        },
        {
          id: '228a12fa-34b3-43bc-9906-d8d9d6534ce0',
          name: 'Plastic Decking',
          children: [
            {
              id: 'b0002b18-f546-446e-adb5-5e9302b976ef',
              name: 'Plastic Decking',
              tradeId: '06 53 00',
            },
            {
              id: '798d6db1-e58f-46e0-a9df-be247fefac3a',
              name: 'Solid Plastic Decking',
              tradeId: '06 53 13',
            },
          ],
          tradeId: '06 53 00',
        },
        {
          id: '383bfa55-c244-4850-a72a-5366b0785ecb',
          name: 'Plastic Fabrications',
          children: [],
          tradeId: '06 60 00',
        },
        {
          id: 'b5727310-e890-4940-9115-e561454e89a0',
          name: 'Simulated Stone Fabrications',
          children: [
            {
              id: 'c6174720-edbd-4b26-9ba0-b477fb73f563',
              name: 'Simulated Stone Fabrications',
              tradeId: '06 61 00',
            },
            {
              id: 'e2e2bb3d-b5e3-429c-a242-006eea8422ed',
              name: 'Cultured Marble Fabrications',
              tradeId: '06 61 13',
            },
            {
              id: 'e703243d-c601-4bb4-b3dc-b5289ab9efd2',
              name: 'Solid Surfacing Fabrications',
              tradeId: '06 61 16',
            },
            {
              id: '8b027412-5661-4255-a1b2-df70a58c58eb',
              name: 'Quartz Surfacing Fabrications',
              tradeId: '06 61 19',
            },
          ],
          tradeId: '06 61 00',
        },
        {
          id: 'eccd46ca-244a-467f-b4ba-fd09dd2afd95',
          name: 'Plastic Railings',
          children: [],
          tradeId: '06 63 00',
        },
        {
          id: 'c4b6d7b2-022a-4e5a-938d-03e06e18512f',
          name: 'Plastic Paneling',
          children: [
            {
              id: '273af472-bfe2-4c08-b9aa-ef32ca7b5035',
              name: 'Plastic Paneling',
              tradeId: '06 64 00',
            },
            {
              id: 'd0a05e9f-9614-4812-b38b-8edd43d525af',
              name: 'Plastic Lattice Paneling',
              tradeId: '06 64 13',
            },
          ],
          tradeId: '06 64 00',
        },
        {
          id: '66ec1538-50a6-40ce-b3f1-94bf8761b3de',
          name: 'Plastic Simulated Wood Trim',
          children: [],
          tradeId: '06 65 00',
        },
        {
          id: 'a15dcc72-b007-4fb9-929a-463429430c8d',
          name: 'Custom Ornamental Simulated Woodwork',
          children: [],
          tradeId: '06 66 00',
        },
        {
          id: 'a56c3e78-b77f-4b21-88ef-0951c4e87d6f',
          name: 'Structural Composites',
          children: [],
          tradeId: '06 70 00',
        },
        {
          id: '2c692b93-de90-4cdf-be1c-df0cbbf7adb9',
          name: 'Structural Composite Shapes and Plates',
          children: [
            {
              id: 'ec4d05a0-0fa9-401d-a378-4f8937d99b0e',
              name: 'Structural Composite Shapes and Plates',
              tradeId: '06 71 00',
            },
            {
              id: '523876c9-5ead-4e4d-87df-9ac8d157ad23',
              name: 'Composite Lumber',
              tradeId: '06 71 13',
            },
          ],
          tradeId: '06 71 00',
        },
        {
          id: '56749ac8-2fa7-4250-a3b6-1de5c4e8c81c',
          name: 'Composite Structural Assemblies',
          children: [
            {
              id: 'a8ecd02e-389d-438d-a734-b62d7963056d',
              name: 'Composite Structural Assemblies',
              tradeId: '06 72 00',
            },
            {
              id: '9bb5c272-cfa0-4490-9df4-9d9df37aed6e',
              name: 'Composite Joist Assemblies',
              tradeId: '06 72 13',
            },
          ],
          tradeId: '06 72 00',
        },
        {
          id: 'c5deaba6-1023-4a82-ae1e-f982c608b272',
          name: 'Composite Decking',
          children: [
            {
              id: '04fa9634-6edf-4fa9-871b-51b866c09505',
              name: 'Composite Decking',
              tradeId: '06 73 00',
            },
            {
              id: 'df9dcc51-d249-41dc-b84e-c5de64d3e799',
              name: 'Composite Structural Decking',
              tradeId: '06 73 13',
            },
          ],
          tradeId: '06 73 00',
        },
        {
          id: 'acef0b44-2c1c-4fb9-9ac0-d049e1df3f72',
          name: 'Composite Gratings',
          children: [
            {
              id: '7a57b92f-ffb8-4217-a69b-2a4c43237440',
              name: 'Composite Gratings',
              tradeId: '06 74 00',
            },
            {
              id: 'd01b1b99-d55f-4ab3-8870-a5398b50ee92',
              name: 'Fiberglass Reinforced Gratings',
              tradeId: '06 74 13',
            },
          ],
          tradeId: '06 74 00',
        },
        {
          id: '9b1924aa-90aa-457b-a885-81fc987ec24c',
          name: 'Composite Fabrications',
          children: [],
          tradeId: '06 80 00',
        },
        {
          id: '61558f17-6222-4822-bfad-1524178d7c1f',
          name: 'Composite Railings',
          children: [
            {
              id: '9bb8c953-442e-44d8-8e7c-873a8c02cdca',
              name: 'Composite Railings',
              tradeId: '06 81 00',
            },
            {
              id: 'c4beb3ad-4abb-44cc-8e37-8d81e1224e22',
              name: 'Glass-Fiber-Reinforced Plastic Railings',
              tradeId: '06 81 13',
            },
          ],
          tradeId: '06 81 00',
        },
        {
          id: 'ab4ec1d1-6227-4b52-8585-57dbad9b6f9f',
          name: 'Composite Paneling',
          children: [
            {
              id: 'bbf02933-fec2-42a2-a13e-b03de23e0e6a',
              name: 'Composite Paneling',
              tradeId: '06 83 00',
            },
            {
              id: '0fe592a9-73d4-4d43-b53f-027cc4ca33a9',
              name: 'Resin Composite Paneling',
              tradeId: '06 83 13',
            },
            {
              id: 'd0fa019f-b556-4e0e-aa3d-2e01860f1e05',
              name: 'Fiberglass Reinforced Paneling',
              tradeId: '06 83 16',
            },
          ],
          tradeId: '06 83 00',
        },
      ],
      tradeId: '06 00 00',
    },
    {
      id: '2bf02a5e-1f47-4055-8a6c-99866889de50',
      name: 'Thermal and Moisture Protection',
      children: [
        {
          id: '4ab75086-6c3f-4537-8d32-cb66b89de2a6',
          name: 'Thermal and Moisture Protection',
          children: [],
          tradeId: '07 00 00',
        },
        {
          id: '46aedc94-0bd4-4237-8487-0c50a945d501',
          name: 'Operation and Maintenance of Thermal and Moisture Protection',
          children: [
            {
              id: '9bd0815b-710c-44e1-9ff8-e4b5ac96151a',
              name: 'Operation and Maintenance of Thermal and Moisture Protection',
              tradeId: '07 01 00',
            },
            {
              id: 'd49bebf2-b134-46a0-bd8e-f48efc50abab',
              name: 'Maintenance of Dampproofing and Waterproofing',
              tradeId: '07 01 10',
            },
            {
              id: '9308f493-135a-4290-8622-6fc850b9fc2c',
              name: 'Waterproofing Replacement',
              tradeId: '07 01 10.81',
            },
            {
              id: '9022bae5-a9d1-44cd-9260-ca17753f3343',
              name: 'Maintenance of Thermal Protection',
              tradeId: '07 01 20',
            },
            {
              id: 'd9b09836-717a-4999-b8c8-bb0b6e4c964f',
              name: 'Maintenance of Steep Slope Roofing',
              tradeId: '07 01 30',
            },
            {
              id: 'e1a1ecec-a943-4f12-9cf5-654659235802',
              name: 'Maintenance of Roofing and Siding Panels',
              tradeId: '07 01 40',
            },
            {
              id: '7d06f340-b194-4d63-a561-5e74bd88e311',
              name: 'Maintenance of Membrane Roofing',
              tradeId: '07 01 50',
            },
            {
              id: '1b51b8d5-a943-4f8d-85eb-b20926ee5ff2',
              name: 'Roof Moisture Survey',
              tradeId: '07 01 50.13',
            },
            {
              id: '5385f0c2-d924-4112-8a06-91b504021b42',
              name: 'Roof Maintenance Program',
              tradeId: '07 01 50.16',
            },
            {
              id: '35707bd3-965d-45dc-923c-54e57adff202',
              name: 'Preparation for Re-Roofing',
              tradeId: '07 01 50.19',
            },
            {
              id: '8eef4396-e854-4477-a576-d4e122efd49b',
              name: 'Roof Removal',
              tradeId: '07 01 50.23',
            },
            {
              id: '1bd4e6bd-3909-44e4-a48a-0eaf69fe3df1',
              name: 'Roof Re-Coating',
              tradeId: '07 01 50.61',
            },
            {
              id: '319249e8-3295-47bc-b996-3574b0cb5d94',
              name: 'Roof Replacement',
              tradeId: '07 01 50.81',
            },
            {
              id: 'eb89d581-d4b3-4c44-a435-f8d1e4dcc900',
              name: 'Roofing Restoration',
              tradeId: '07 01 50.91',
            },
            {
              id: '96b94c9f-6619-4770-80b5-f2efffdee8a3',
              name: 'Maintenance of Flashing and Sheet Metal',
              tradeId: '07 01 60',
            },
            {
              id: 'fd591372-757e-47c5-91de-ab5d860d1a25',
              name: 'Flashing and Sheet Metal Rehabilitation',
              tradeId: '07 01 60.71',
            },
            {
              id: 'd0b97ac7-34d6-46a4-9d33-758d74033bc3',
              name: 'Flashing and Sheet Metal Restoration',
              tradeId: '07 01 60.91',
            },
            {
              id: 'f7163211-1aca-4fd6-87c7-0f702a915758',
              name: 'Flashing and Sheet Metal Preservation',
              tradeId: '07 01 60.92',
            },
            {
              id: '88ec82cd-f754-41fe-ad8b-351e5832bfde',
              name: 'Operation and Maintenance of Roof Specialties and Accessories',
              tradeId: '07 01 70',
            },
            {
              id: 'cca1224a-4880-43f2-8746-bc6615606ad6',
              name: 'Maintenance of Fire and Smoke Protection',
              tradeId: '07 01 80',
            },
            {
              id: 'cfdd6f9b-9194-4f7e-a8ce-c79edc1a1d39',
              name: 'Maintenance of Joint Protection',
              tradeId: '07 01 90',
            },
            {
              id: '569f7c15-53a7-4478-b062-4637e25cb033',
              name: 'Joint Sealant Rehabilitation',
              tradeId: '07 01 90.71',
            },
            {
              id: '41774ffc-6b09-448c-ae72-e648e8e6ef70',
              name: 'Joint Sealant Replacement',
              tradeId: '07 01 90.81',
            },
          ],
          tradeId: '07 01 00',
        },
        {
          id: '57fb78e3-449d-4112-bf36-036e3aac860f',
          name: 'Common Work Results for Thermal and Moisture Protection',
          children: [
            {
              id: '128fc146-e7a5-47f9-81ed-a6abf8a6658c',
              name: 'Common Work Results for Thermal and Moisture Protection',
              tradeId: '07 05 00',
            },
            {
              id: '2833c57c-570f-469c-ac56-ce70058219d1',
              name: 'Fire and Smoke Assembly Identification',
              tradeId: '07 05 53',
            },
          ],
          tradeId: '07 05 00',
        },
        {
          id: 'b46a7cf1-852a-474b-97b4-4320bd598fdc',
          name: 'Schedules for Thermal and Moisture Protection',
          children: [
            {
              id: '9af6c7e3-a5d9-4b6e-bbe5-e23b7b93add7',
              name: 'Schedules for Thermal and Moisture Protection',
              tradeId: '07 06 00',
            },
            {
              id: '3769f22b-ea7d-4a06-83c2-400ba0f18465',
              name: 'Schedules for Dampproofing and Waterproofing',
              tradeId: '07 06 10',
            },
            {
              id: '0626aad6-3518-4151-8ccf-16a4ef682129',
              name: 'Schedules for Thermal Protection',
              tradeId: '07 06 20',
            },
            {
              id: 'b55edc55-7092-4f75-8887-0249b82dac58',
              name: 'Schedules for Steep Slope Roofing',
              tradeId: '07 06 30',
            },
            {
              id: '8c83e41a-0a4b-4918-90e8-1b3327c26300',
              name: 'Schedules for Roofing and Siding Panels',
              tradeId: '07 06 40',
            },
            {
              id: 'f41a4281-8b35-4d31-9228-1c87ab4dbc07',
              name: 'Schedules for Membrane Roofing',
              tradeId: '07 06 50',
            },
            {
              id: 'ae2c5cea-396c-407c-ad67-718d5342a609',
              name: 'Schedules for Flashing and Sheet Metal',
              tradeId: '07 06 60',
            },
            {
              id: '13949570-612e-4c53-a6d9-90b3d192df17',
              name: 'Schedules for Roof Specialties and Accessories',
              tradeId: '07 06 70',
            },
            {
              id: '61223ffd-0f9e-42a6-ab9c-d41dc3349c0c',
              name: 'Schedules for Fire and Smoke Protection',
              tradeId: '07 06 80',
            },
            {
              id: '51f57a53-91e4-41e3-b9a9-b7ef4069cf46',
              name: 'Fireproofing Schedule',
              tradeId: '07 06 80.13',
            },
            {
              id: '9b3c798a-3bf4-4932-befb-644fe5a6b45e',
              name: 'Firestopping Schedule',
              tradeId: '07 06 80.16',
            },
            {
              id: '021ca7a1-7faf-4da9-bddb-57db628df1c1',
              name: 'Schedules for Joint Protection',
              tradeId: '07 06 90',
            },
            {
              id: '1d3567d5-deb8-4d2c-8f70-76fb84a215f1',
              name: 'Joint Sealant Schedule',
              tradeId: '07 06 90.13',
            },
          ],
          tradeId: '07 06 00',
        },
        {
          id: '06258c67-60ee-40cc-a431-a1230772a3b6',
          name: 'Commissioning of Thermal and Moisture Protection',
          children: [],
          tradeId: '07 08 00',
        },
        {
          id: '6f74ffec-437a-466b-9c62-6df00f04ec16',
          name: 'Dampproofing and Waterproofing',
          children: [],
          tradeId: '07 10 00',
        },
        {
          id: 'bec3f97c-76bc-4519-95a5-a8fe728cd3a2',
          name: 'Dampproofing',
          children: [
            {
              id: '2b2ba1ff-2f72-473a-9ed7-aa01518848d5',
              name: 'Dampproofing',
              tradeId: '07 11 00',
            },
            {
              id: '1a54cd51-0e36-4cc2-a010-6d1ce2971e14',
              name: 'Bituminous Dampproofing',
              tradeId: '07 11 13',
            },
            {
              id: '6251fb91-bb17-4185-8011-15eb1ae3668c',
              name: 'Cementitious Dampproofing',
              tradeId: '07 11 16',
            },
            {
              id: 'f0587319-f050-4a63-8296-d1e7969c2fa3',
              name: 'Sheet Dampproofing',
              tradeId: '07 11 19',
            },
          ],
          tradeId: '07 11 00',
        },
        {
          id: '627fed67-ae55-4467-a8ba-0ae66296d414',
          name: 'Built-Up Bituminous Waterproofing',
          children: [
            {
              id: '9ac1c06a-6858-4fd2-aa2b-1d69621b8d4a',
              name: 'Built-Up Bituminous Waterproofing',
              tradeId: '07 12 00',
            },
            {
              id: 'd7596ee4-2c99-4d08-93d7-ffc2b50947ba',
              name: 'Built-Up Asphalt Waterproofing',
              tradeId: '07 12 13',
            },
            {
              id: 'eccacc1d-1f3d-4db7-9bc1-5461f71284a4',
              name: 'Built-Up Coal Tar Waterproofing',
              tradeId: '07 12 16',
            },
          ],
          tradeId: '07 12 00',
        },
        {
          id: 'e350ca9a-85e3-4cb2-a701-e1f50b4751cd',
          name: 'Sheet Waterproofing',
          children: [
            {
              id: '618fdbdd-3c5e-4b54-a433-16ca0706ae35',
              name: 'Sheet Waterproofing',
              tradeId: '07 13 00',
            },
            {
              id: 'cc6731e4-4f5f-4104-b139-c9efe5fab5fc',
              name: 'Bituminous Sheet Waterproofing',
              tradeId: '07 13 13',
            },
            {
              id: '6f0efe27-2521-41ee-b1cc-fe996c6b9b28',
              name: 'Self-Adhering Sheet Waterproofing',
              tradeId: '07 13 26',
            },
            {
              id: '229294bf-e8e6-460b-8020-2cc11ed41858',
              name: 'Modified Bituminous Sheet Waterproofing',
              tradeId: '07 13 52',
            },
            {
              id: '7055eeb1-a183-4abd-b148-4a895e7e19e4',
              name: 'Elastomeric Sheet Waterproofing',
              tradeId: '07 13 53',
            },
            {
              id: '5eefa583-2110-43d4-ba34-70f5e010239e',
              name: 'Thermoplastic Sheet Waterproofing',
              tradeId: '07 13 54',
            },
          ],
          tradeId: '07 13 00',
        },
        {
          id: 'a28ccee5-ccbd-4a89-a055-19b311979303',
          name: 'Fluid-Applied Waterproofing',
          children: [
            {
              id: '27dde63e-35b9-4695-932c-f03d38ea64e6',
              name: 'Fluid-Applied Waterproofing',
              tradeId: '07 14 00',
            },
            {
              id: 'a57ee05c-21dc-4b77-8465-d436cbf0955f',
              name: 'Hot Fluid-Applied Rubberized Asphalt Waterproofing',
              tradeId: '07 14 13',
            },
            {
              id: '0473c4ee-824a-4f0f-9104-f0b71168d1b7',
              name: 'Hot Fluid-Applied Polyurea Waterproofing',
              tradeId: '07 14 14',
            },
            {
              id: 'de5ee6b7-dffb-44d4-ae39-82ebbe52f7f8',
              name: 'Cold Fluid-Applied Waterproofing',
              tradeId: '07 14 16',
            },
          ],
          tradeId: '07 14 00',
        },
        {
          id: '66b5e6ad-3ace-4b4d-8188-8bb7fa08cd31',
          name: 'Sheet Metal Waterproofing',
          children: [
            {
              id: 'e9ddc251-859b-40ca-9347-2c77ed219fc4',
              name: 'Sheet Metal Waterproofing',
              tradeId: '07 15 00',
            },
            {
              id: '3e17a3e8-26c1-4d1e-b719-3716424086fd',
              name: 'Sheet Lead Waterproofing',
              tradeId: '07 15 13',
            },
          ],
          tradeId: '07 15 00',
        },
        {
          id: 'e3f65092-196b-428e-83fe-695371a66046',
          name: 'Cementitious and Reactive Waterproofing',
          children: [
            {
              id: 'f89749bc-5ea0-4996-99bc-3d35038ba18d',
              name: 'Cementitious and Reactive Waterproofing',
              tradeId: '07 16 00',
            },
            {
              id: 'f47432a9-eba4-481b-95e4-e76752117395',
              name: 'Polymer Modified Cement Waterproofing',
              tradeId: '07 16 13',
            },
            {
              id: '47eaf984-a5ac-4f1b-9a95-f6f731668f5a',
              name: 'Crystalline Waterproofing',
              tradeId: '07 16 16',
            },
            {
              id: '8dadd0fe-6ae5-4815-b071-7f7d6effd8e9',
              name: 'Metal Oxide Waterproofing',
              tradeId: '07 16 19',
            },
          ],
          tradeId: '07 16 00',
        },
        {
          id: 'df650ad8-2115-4441-97bf-c8164d01bf60',
          name: 'Bentonite Waterproofing',
          children: [
            {
              id: '9a520384-f1c6-48f6-8933-6981ee787b1f',
              name: 'Bentonite Waterproofing',
              tradeId: '07 17 00',
            },
            {
              id: '858ed0e9-e50f-449e-ad47-a0ec44c4ad60',
              name: 'Bentonite Panel Waterproofing',
              tradeId: '07 17 13',
            },
            {
              id: 'ee784b0f-d981-42a6-8317-b4cf2254d176',
              name: 'Bentonite Composite Sheet Waterproofing',
              tradeId: '07 17 16',
            },
          ],
          tradeId: '07 17 00',
        },
        {
          id: '3b89b603-538a-4200-afc9-30b49a0304f4',
          name: 'Traffic Coatings',
          children: [
            {
              id: 'a3f91bf1-d551-4eff-9cff-e5acd6b2826f',
              name: 'Traffic Coatings',
              tradeId: '07 18 00',
            },
            {
              id: 'ae9b0b8e-4b9f-4847-b303-7c7930911436',
              name: 'Pedestrian Traffic Coatings',
              tradeId: '07 18 13',
            },
            {
              id: '200db897-c3e7-4335-a4f1-076919f32dab',
              name: 'Vehicular Traffic Coatings',
              tradeId: '07 18 16',
            },
          ],
          tradeId: '07 18 00',
        },
        {
          id: 'cb5b7a39-1108-4a34-9a3c-42b5e9d25db5',
          name: 'Water Repellents',
          children: [
            {
              id: 'f714a3e1-f6d0-4817-998a-5429ce2b74e1',
              name: 'Water Repellents',
              tradeId: '07 19 00',
            },
            {
              id: '8b6aa153-139f-4565-ac1e-b3749148bfcf',
              name: 'Acrylic Water Repellents',
              tradeId: '07 19 13',
            },
            {
              id: 'ba1b6d67-bac8-477d-897a-eb534cc628e2',
              name: 'Silane Water Repellents',
              tradeId: '07 19 16',
            },
            {
              id: '0c612f24-cb42-4e58-b27e-bc3b6b51e0bb',
              name: 'Silicone Water Repellents',
              tradeId: '07 19 19',
            },
            {
              id: '0676b779-428e-4a10-8307-5b268ada76bb',
              name: 'Siloxane Water Repellents',
              tradeId: '07 19 23',
            },
            {
              id: '5371a930-ee55-44cc-9e94-4e242ad5d09f',
              name: 'Stearate Water Repellents',
              tradeId: '07 19 26',
            },
          ],
          tradeId: '07 19 00',
        },
        {
          id: '26f95c74-92e5-4d53-aac1-1f7fb6659029',
          name: 'Thermal Protection',
          children: [],
          tradeId: '07 20 00',
        },
        {
          id: '73350037-2936-4e24-a601-bbc52728190c',
          name: 'Thermal Insulation',
          children: [
            {
              id: '58c3ce8e-9c4a-44a4-a86d-7b62850296f4',
              name: 'Thermal Insulation',
              tradeId: '07 21 00',
            },
            {
              id: '8d91a1a0-7a8f-4b2f-849c-26fc0159ed13',
              name: 'Board Insulation',
              tradeId: '07 21 13',
            },
            {
              id: '7a9e8952-2dfc-4cf7-8de0-070f95b90cf6',
              name: 'Foam Board Insulation',
              tradeId: '07 21 13.13',
            },
            {
              id: '993170bc-b811-49aa-be46-d06c1a65ef99',
              name: 'Fibrous Board Insulation',
              tradeId: '07 21 13.16',
            },
            {
              id: '99e0d84c-7112-4265-8fae-59afd1a6dcd2',
              name: 'Mineral Board Insulation',
              tradeId: '07 21 13.19',
            },
            {
              id: '2e01b9ca-1eac-4eed-97dd-b404c6bb0ef8',
              name: 'Blanket Insulation',
              tradeId: '07 21 16',
            },
            {
              id: '90cd6eb0-dea8-4c26-b5f4-d7bc8a95eaea',
              name: 'Foamed-In-Place Insulation',
              tradeId: '07 21 19',
            },
            {
              id: '80fa3de4-1be1-4dc9-b4d6-cf30bb72d66b',
              name: 'Loose-Fill Insulation',
              tradeId: '07 21 23',
            },
            {
              id: '0f905745-bcfb-4c5d-a1b1-ccb8fd2daa95',
              name: 'Blown Insulation',
              tradeId: '07 21 26',
            },
            {
              id: '66ddb2ab-913f-433b-8783-8a63cf662c0b',
              name: 'Sprayed Insulation',
              tradeId: '07 21 29',
            },
            {
              id: '771b9448-7cb3-4669-be8d-b4a29308e46c',
              name: 'Reflective Insulation',
              tradeId: '07 21 53',
            },
          ],
          tradeId: '07 21 00',
        },
        {
          id: '2db34da1-577f-4ecd-bfbb-d6737f31313f',
          name: 'Roof and Deck Insulation',
          children: [
            {
              id: '2943654f-22ea-4b60-bfb9-d2a861365f80',
              name: 'Roof and Deck Insulation',
              tradeId: '07 22 00',
            },
            {
              id: 'faac2208-a7c9-46bd-9132-55886c2f08ce',
              name: 'Asphaltic Perlite Concrete Deck',
              tradeId: '07 22 13',
            },
            {
              id: '164421f1-c607-44b0-8260-a0ee8af26b8d',
              name: 'Roof Board Insulation',
              tradeId: '07 22 16',
            },
          ],
          tradeId: '07 22 00',
        },
        {
          id: '890da217-e3d6-4dc1-8600-54a14442ec3a',
          name: 'Exterior Insulation and Finish Systems',
          children: [
            {
              id: '95630323-286b-4e6e-ab05-6da97e1e86b8',
              name: 'Exterior Insulation and Finish Systems',
              tradeId: '07 24 00',
            },
            {
              id: 'cbaba12c-119a-4dfd-9f35-4e73c0ee5154',
              name: 'Polymer-Based Exterior Insulation and Finish System',
              tradeId: '07 24 13',
            },
            {
              id: '6d655690-1c57-41ec-8ec7-4ae5ea4e7a3b',
              name: 'Polymer-Modified Exterior Insulation and Finish System',
              tradeId: '07 24 16',
            },
            {
              id: '36f68139-95b5-4e59-b7e1-42fa01c9f356',
              name: 'Water-Drainage Exterior Insulation and Finish System',
              tradeId: '07 24 19',
            },
            {
              id: '9cef2c0f-2086-4cc9-8e09-ee1d620d9908',
              name: 'Direct-Applied Finish Systems',
              tradeId: '07 24 23',
            },
          ],
          tradeId: '07 24 00',
        },
        {
          id: 'c2e96b4a-ad46-416b-9c38-d006aeb7b3ef',
          name: 'Weather Barriers',
          children: [],
          tradeId: '07 25 00',
        },
        {
          id: '98540712-e570-438c-b0f0-f896d939d187',
          name: 'Vapor Retarders',
          children: [
            {
              id: '1d3e3957-b0c9-4731-9815-c317bb28728b',
              name: 'Vapor Retarders',
              tradeId: '07 26 00',
            },
            {
              id: '7c1de4f0-5583-4117-b695-a2385497d982',
              name: 'Above-Grade Vapor Retarders',
              tradeId: '07 26 13',
            },
            {
              id: '0cded899-be09-45cc-84a8-813d63a115bf',
              name: 'Below-Grade Vapor Retarders',
              tradeId: '07 26 16',
            },
            {
              id: 'a097ac9b-d0e1-49bc-bad8-053b4362d2ba',
              name: 'Below-Grade Gas Retarders',
              tradeId: '07 26 23',
            },
          ],
          tradeId: '07 26 00',
        },
        {
          id: 'ea2f7c99-ad60-4db9-bd64-6cd6362b688b',
          name: 'Air Barriers',
          children: [
            {
              id: '584963fd-c924-401b-8cf4-6c45af1db77f',
              name: 'Air Barriers',
              tradeId: '07 27 00',
            },
            {
              id: '2ceb9b35-92bf-4332-a107-d16f4e722763',
              name: 'Modified Bituminous Sheet Air Barriers',
              tradeId: '07 27 13',
            },
            {
              id: '9232904d-c021-4569-b1c6-03f014f0fa27',
              name: 'Sheet Metal Membrane Air Barriers',
              tradeId: '07 27 16',
            },
            {
              id: '90cb0342-d85a-40e0-98d0-4e31ca96954f',
              name: 'Plastic Sheet Air Barriers',
              tradeId: '07 27 19',
            },
            {
              id: '14ee914b-9c3c-4ba5-a81e-8986bff1fb0f',
              name: 'Board Product Air Barriers',
              tradeId: '07 27 23',
            },
            {
              id: '0bd51a12-6e4e-4d47-a06d-f6be3052af00',
              name: 'Fluid-Applied Membrane Air Barriers',
              tradeId: '07 27 26',
            },
            {
              id: 'ab19ef40-3549-4f16-b441-81a50ebe0423',
              name: 'Sprayed Foam Air Barrier',
              tradeId: '07 27 36',
            },
          ],
          tradeId: '07 27 00',
        },
        {
          id: '8c8e1981-adfa-4139-b570-6630e49abef8',
          name: 'Steep Slope Roofing',
          children: [
            {
              id: 'abae83fb-5e1b-4c66-86df-98f5111ab9fa',
              name: 'Steep Slope Roofing',
              tradeId: '07 30 00',
            },
            {
              id: '0ba2dcc2-dad8-4908-b578-5ded9abc3b92',
              name: 'Canvas Roofing',
              tradeId: '07 30 91',
            },
          ],
          tradeId: '07 30 00',
        },
        {
          id: '77db77d5-2464-41be-a17e-8b004c779051',
          name: 'Shingles and Shakes',
          children: [
            {
              id: '9af4c135-6f70-476f-b6e6-c2a8e1db927c',
              name: 'Shingles and Shakes',
              tradeId: '07 31 00',
            },
            {
              id: 'f52177e4-2bdb-4af5-9a77-304049637483',
              name: 'Asphalt Shingles',
              tradeId: '07 31 13',
            },
            {
              id: 'c5e79ac3-4782-468c-b82e-258dc1c3288d',
              name: 'Fiberglass-Reinforced Asphalt Shingles',
              tradeId: '07 31 13.13',
            },
            {
              id: 'dab4b650-b8c1-424f-97f0-af4eaca8aee8',
              name: 'Metal Shingles',
              tradeId: '07 31 16',
            },
            {
              id: '07104f12-7919-4cea-96cd-ccca1db16cff',
              name: 'Mineral-Fiber Cement Shingles',
              tradeId: '07 31 19',
            },
            {
              id: 'e83d64ec-6ff0-4f76-9652-90719bc75b61',
              name: 'Porcelain Enamel Shingles',
              tradeId: '07 31 23',
            },
            {
              id: '400ec709-a79f-4164-93cf-2668ae2a1d73',
              name: 'Slate Shingles',
              tradeId: '07 31 26',
            },
            {
              id: 'f479e29c-f271-4a57-8792-f7e5c762460f',
              name: 'Wood Shingles and Shakes',
              tradeId: '07 31 29',
            },
            {
              id: '1bc5de2f-5501-49e1-b407-12d76bb5f9aa',
              name: 'Wood Shingles',
              tradeId: '07 31 29.13',
            },
            {
              id: 'aaf836b7-1493-40b2-95ef-55233b544931',
              name: 'Wood Shakes',
              tradeId: '07 31 29.16',
            },
            {
              id: 'a5e2f67e-b6a2-4b26-80d0-ab74ed9e2aeb',
              name: 'Composite Rubber Shingles',
              tradeId: '07 31 33',
            },
            {
              id: '54743370-e83f-47e5-bf23-4dc5688e9ba2',
              name: 'Plastic Shakes',
              tradeId: '07 31 53',
            },
          ],
          tradeId: '07 31 00',
        },
        {
          id: 'ee31f10c-c73e-425a-aa34-5bde44dd303f',
          name: 'Roof Tiles',
          children: [
            {
              id: '9f343eb9-16fd-4dcf-b40d-03cbe301ce45',
              name: 'Roof Tiles',
              tradeId: '07 32 00',
            },
            {
              id: '5095c2b7-7554-47ae-8fe0-0ea3543e93ad',
              name: 'Clay Roof Tiles',
              tradeId: '07 32 13',
            },
            {
              id: '4cf3eb82-0652-4b57-b47c-b53bd19be64e',
              name: 'Ceramic and Porcelain Roof Tiles',
              tradeId: '07 32 14',
            },
            {
              id: '86e3aa39-1500-4198-899e-581f94f2a5e6',
              name: 'Concrete Roof Tiles',
              tradeId: '07 32 16',
            },
            {
              id: 'd4c40972-151b-4304-a51f-d876e4f21445',
              name: 'Metal Roof Tiles',
              tradeId: '07 32 19',
            },
            {
              id: 'd3ecc773-18d1-4580-9465-ea015524a935',
              name: 'Mineral-Fiber Cement Roof Tiles',
              tradeId: '07 32 23',
            },
            {
              id: '0ceb06ae-a13d-4f1e-8a3f-3e5614fe5e64',
              name: 'Plastic Roof Tiles',
              tradeId: '07 32 26',
            },
            {
              id: 'd8c544ae-4986-4284-8d7c-ec2c4d5c5f68',
              name: 'Rubber Tiles/Panels',
              tradeId: '07 32 29',
            },
          ],
          tradeId: '07 32 00',
        },
        {
          id: 'fcd8412a-9bd2-4827-8318-15f383b3c56c',
          name: 'Natural Roof Coverings',
          children: [
            {
              id: '5e937daf-feff-4b4b-a215-0629b32b8794',
              name: 'Natural Roof Coverings',
              tradeId: '07 33 00',
            },
            {
              id: '32bf7366-5efd-4355-8cbd-ae69e77445ad',
              name: 'Sod Roofing',
              tradeId: '07 33 13',
            },
            {
              id: 'b9b7a06f-491e-4092-96f7-1f4b9d060241',
              name: 'Thatched Roofing',
              tradeId: '07 33 16',
            },
            {
              id: 'b97b62bd-1b13-41ca-b91c-4bc7cb9a0a2d',
              name: 'Vegetated Roofing',
              tradeId: '07 33 63',
            },
          ],
          tradeId: '07 33 00',
        },
        {
          id: '3b28201c-df70-4e9e-af77-edef15ae9b71',
          name: 'Roofing and Siding Panels',
          children: [],
          tradeId: '07 40 00',
        },
        {
          id: 'd998c73d-c653-477d-a3eb-ab1e05e9d7d5',
          name: 'Roof Panels',
          children: [
            {
              id: '7c4e75b5-bd8d-400b-a870-6775d2176904',
              name: 'Roof Panels',
              tradeId: '07 41 00',
            },
            {
              id: '117d66a2-be09-428f-b16d-699166106958',
              name: 'Metal Roof Panels',
              tradeId: '07 41 13',
            },
            {
              id: '93e87b95-0b0d-4a20-85c1-e225db41b554',
              name: 'Wood Roof Panels',
              tradeId: '07 41 23',
            },
            {
              id: '56687689-e5c2-4120-a32f-a5e452831b1e',
              name: 'Plastic Roof Panels',
              tradeId: '07 41 33',
            },
            {
              id: '21a962fe-ca46-445a-9512-4024b59f9512',
              name: 'Composite Roof Panels',
              tradeId: '07 41 43',
            },
            {
              id: '01238a6c-6856-4c76-9066-986819b203ca',
              name: 'Fabricated Roof Panel Assemblies',
              tradeId: '07 41 63',
            },
          ],
          tradeId: '07 41 00',
        },
        {
          id: '7f9c5554-d9c9-4d2c-8367-e6ce6fd41982',
          name: 'Wall Panels',
          children: [
            {
              id: 'af070a30-9317-4070-894b-e43c32fe111f',
              name: 'Wall Panels',
              tradeId: '07 42 00',
            },
            {
              id: 'ceb2425c-75ef-4e21-a334-39752a8f368e',
              name: 'Metal Wall Panels',
              tradeId: '07 42 13',
            },
            {
              id: '65b34380-0ba9-4831-94b4-7589ddd8ae2b',
              name: 'Formed Metal Wall Panels',
              tradeId: '07 42 13.13',
            },
            {
              id: '8485c5d0-bb87-428b-8b6d-90ee46282337',
              name: 'Metal Plate Wall Panels',
              tradeId: '07 42 13.16',
            },
            {
              id: 'ce4f5846-79b6-432d-b206-991b09013c84',
              name: 'Insulated Metal Wall Panels',
              tradeId: '07 42 13.19',
            },
            {
              id: '8d836140-accb-44bf-b5fd-a63aea0967aa',
              name: 'Metal Composite Material Wall Panels',
              tradeId: '07 42 13.23',
            },
            {
              id: '810f1e93-0c90-4dfe-bdf9-93b382e77f7a',
              name: 'Wood Wall Panels',
              tradeId: '07 42 23',
            },
            {
              id: '15050b1d-b59b-473f-a0b2-2815aadaa6c7',
              name: 'Terracotta Wall Panels',
              tradeId: '07 42 29',
            },
            {
              id: 'f60fdee3-2c1d-4296-9d3b-720ed343af62',
              name: 'Plastic Wall Panels',
              tradeId: '07 42 33',
            },
            {
              id: 'b433c86a-c692-4bf9-9846-9077c827c24e',
              name: 'Composite Wall Panels',
              tradeId: '07 42 43',
            },
            {
              id: '9ba196e0-b6d4-442e-a737-a26697dd9fa3',
              name: 'Fabricated Wall Panel Assemblies',
              tradeId: '07 42 63',
            },
            {
              id: '35fff01b-2429-48f1-9053-cdc0d92b160f',
              name: 'Soffit Panels',
              tradeId: '07 42 93',
            },
          ],
          tradeId: '07 42 00',
        },
        {
          id: '7f7f3c8c-4769-497f-bdb8-d166d2fbf301',
          name: 'Faced Panels',
          children: [
            {
              id: '44d04198-9804-4d71-885d-f2a1e35560b3',
              name: 'Faced Panels',
              tradeId: '07 44 00',
            },
            {
              id: '161e53d7-128d-4162-a34a-4e618e31c8da',
              name: 'Aggregate Coated Panels',
              tradeId: '07 44 13',
            },
            {
              id: 'fc108170-b31f-40f7-a3da-72ad13558479',
              name: 'Porcelain Enameled Faced Panels',
              tradeId: '07 44 16',
            },
            {
              id: '1fbedd00-da41-40b2-9f38-170e78ea6dde',
              name: 'Tile-Faced Panels',
              tradeId: '07 44 19',
            },
            {
              id: 'a4340f8d-1571-427a-a981-2ce78958468f',
              name: 'Ceramic-Tile-Faced Panels',
              tradeId: '07 44 23',
            },
            {
              id: '26b3c5c2-93cd-49d8-a7aa-9dd908745011',
              name: 'Metal Faced Panels',
              tradeId: '07 44 33',
            },
            {
              id: '9affddb5-41b5-4086-8fd1-88d98ed3c7aa',
              name: 'Glass-Fiber-Reinforced Cementitious Panels',
              tradeId: '07 44 53',
            },
            {
              id: 'e0b229fd-cf51-4f16-83ef-0f58879c407b',
              name: 'Mineral-Fiber-Reinforced Cementitious Panels',
              tradeId: '07 44 56',
            },
            {
              id: 'b90cf1f9-b519-45d2-9cf6-f88ef4f9128b',
              name: 'Fabricated Faced Panel Assemblies',
              tradeId: '07 44 63',
            },
          ],
          tradeId: '07 44 00',
        },
        {
          id: 'f1fdd99e-bcf5-4508-b2ee-ce5f1307a30f',
          name: 'Siding',
          children: [
            {
              id: 'b53679e1-3f28-4bed-acfc-a3d2f6fa992b',
              name: 'Siding',
              tradeId: '07 46 00',
            },
            {
              id: 'e25028b7-9b8f-415b-9272-09efd20f0682',
              name: 'Aluminum Siding',
              tradeId: '07 46 16',
            },
            {
              id: 'c22f6e86-16a7-453d-9c2e-aea4d71212e7',
              name: 'Steel Siding',
              tradeId: '07 46 19',
            },
            {
              id: '3e924455-8caa-4dae-967c-3881a0e123ee',
              name: 'Wood Siding',
              tradeId: '07 46 23',
            },
            {
              id: '2580798a-daf2-464d-a5c8-287aa978fe2a',
              name: 'Hardboard Siding',
              tradeId: '07 46 26',
            },
            {
              id: '97fae565-7e86-458e-9167-7287671c16dd',
              name: 'Plywood Siding',
              tradeId: '07 46 29',
            },
            {
              id: 'f007bff4-2a24-43ca-aff5-827e040815cd',
              name: 'Plastic Siding',
              tradeId: '07 46 33',
            },
            {
              id: 'de668e67-694f-48cf-9c0e-263ae624b631',
              name: 'Composition Siding',
              tradeId: '07 46 43',
            },
            {
              id: 'd711451f-a31a-4abc-b7c8-21fcf79e1b0e',
              name: 'Fiber-Cement Siding',
              tradeId: '07 46 46',
            },
            {
              id: '3492d538-4041-4e22-bc8f-39b377264a6c',
              name: 'Fabricated Panel Assemblies with Siding',
              tradeId: '07 46 63',
            },
          ],
          tradeId: '07 46 00',
        },
        {
          id: 'b012ce20-b168-407a-9f44-3af7af7a8bb5',
          name: 'Membrane Roofing',
          children: [],
          tradeId: '07 50 00',
        },
        {
          id: '3a72823c-3871-4cb2-a878-91f0e0616ec6',
          name: 'Built-Up Bituminous Roofing',
          children: [
            {
              id: '7c6508aa-438e-4fc2-b6f9-f1c4027fc18f',
              name: 'Built-Up Bituminous Roofing',
              tradeId: '07 51 00',
            },
            {
              id: '0d5209e0-5bd6-40eb-b185-3a52b5619983',
              name: 'Built-Up Asphalt Roofing',
              tradeId: '07 51 13',
            },
            {
              id: '52714dc0-98d8-4d7d-b306-383b7dca9923',
              name: 'Cold-Applied Built-Up Asphalt Roofing',
              tradeId: '07 51 13.13',
            },
            {
              id: '34e5f55b-faee-41cd-9ca7-e82390d7a1bd',
              name: 'Built-Up Coal Tar Roofing',
              tradeId: '07 51 16',
            },
            {
              id: '33aff458-e578-43fd-9faf-54168a0a7ac2',
              name: 'Glass-Fiber-Reinforced Asphalt Emulsion Roofing',
              tradeId: '07 51 23',
            },
          ],
          tradeId: '07 51 00',
        },
        {
          id: '57044d21-7794-4b8e-9de0-2a492c3a536d',
          name: 'Modified Bituminous Membrane Roofing',
          children: [
            {
              id: '90c5cf55-8aa1-4821-85d6-1256eb32932a',
              name: 'Modified Bituminous Membrane Roofing',
              tradeId: '07 52 00',
            },
            {
              id: '33de151d-fc73-489a-886f-ba20fe171998',
              name: 'Atactic-Polypropylene-Modified Bituminous Membrane Roofing',
              tradeId: '07 52 13',
            },
            {
              id: 'f629cc9a-d7e7-432a-8b79-46f73203d601',
              name: 'Cold Adhesive Applied Atactic-Polypropylene-Modified Bituminous Membrane Roofing',
              tradeId: '07 52 13.11',
            },
            {
              id: '3c074d58-a1b8-4728-b0df-06c465b4019a',
              name: 'Torch-Applied Atactic-Polypropylene-Modified Bituminous Membrane Roofing',
              tradeId: '07 52 13.13',
            },
            {
              id: 'd7c36b04-e7d8-4cfc-9a8f-2d318dcdb702',
              name: 'Mechanically Fastened Atactic-Polypropylene-Modified Bituminous Membrane Roofing',
              tradeId: '07 52 13.14',
            },
            {
              id: 'b5370a8b-871f-4788-9e14-146ea9f70d76',
              name: 'Styrene-Butadiene-Styrene Modified Bituminous Membrane Roofing',
              tradeId: '07 52 16',
            },
            {
              id: '5f9a1c9f-0129-4059-9791-69ad825eed30',
              name: 'Cold Adhesive Styrene-Butadiene-Styrene Modified Bituminous Membrane Roofing',
              tradeId: '07 52 16.11',
            },
            {
              id: '2b9d4ab4-5f7d-4415-8c43-b759d789f65d',
              name: 'Hot-Mopped Styrene-Butadiene-Styrene Modified Bituminous Membrane Roofing',
              tradeId: '07 52 16.12',
            },
            {
              id: '697201d6-6768-4825-b4d8-d58863a06035',
              name: 'Torch-Applied Styrene-Butadiene-Styrene Modified Bituminous Membrane Roofing',
              tradeId: '07 52 16.13',
            },
            {
              id: 'cfdfd38e-1cdc-4abd-a9a9-347539a1c4f7',
              name: 'Mechanically Fastened Styrene-Butadiene-Styrene Modified Bituminous Membrane Roofing',
              tradeId: '07 52 16.14',
            },
          ],
          tradeId: '07 52 00',
        },
        {
          id: '7da80671-4c3e-4085-bd62-bab6617b684f',
          name: 'Elastomeric Membrane Roofing',
          children: [
            {
              id: '91852c70-be3b-4d03-a544-ba168e7c6cc1',
              name: 'Elastomeric Membrane Roofing',
              tradeId: '07 53 00',
            },
            {
              id: '09e6b6bd-c6d9-4e5c-b0ad-cf8124928cba',
              name: 'Chlorinated-Polyethylene Roofing',
              tradeId: '07 53 13',
            },
            {
              id: 'f57e6a5d-074f-42f3-bce1-c8eff60fc442',
              name: 'Chlorosulfonate-Polyethylene Roofing',
              tradeId: '07 53 16',
            },
            {
              id: 'c118a1f9-2d63-4efb-abe4-99757a98c8aa',
              name: 'Ethylene-Propylene-Diene-Monomer Roofing',
              tradeId: '07 53 23',
            },
            {
              id: '3c34598c-cf10-4810-bf54-f1db8a168696',
              name: 'Polyisobutylene Roofing',
              tradeId: '07 53 29',
            },
          ],
          tradeId: '07 53 00',
        },
        {
          id: 'a54c9417-ef74-4a44-9394-9395abd5b859',
          name: 'Thermoplastic Membrane Roofing',
          children: [
            {
              id: '1623c0fa-0a5f-4fa4-8965-7ee1a4a8928a',
              name: 'Thermoplastic Membrane Roofing',
              tradeId: '07 54 00',
            },
            {
              id: '6f56d5a3-e49c-4891-9b2c-85e5cd8ff1de',
              name: 'Copolymer-Alloy Roofing',
              tradeId: '07 54 13',
            },
            {
              id: '9c196fd5-6d9a-4034-81fd-31d7caee0412',
              name: 'Ethylene-Interpolymer Roofing',
              tradeId: '07 54 16',
            },
            {
              id: 'fb50f8a0-90a8-4613-9fb2-24aa65519855',
              name: 'Polyvinyl-Chloride Roofing',
              tradeId: '07 54 19',
            },
            {
              id: 'b0807e8a-b153-4c4a-a13a-01a27d222680',
              name: 'Thermoplastic-Polyolefin Roofing',
              tradeId: '07 54 23',
            },
            {
              id: '57fb7d7e-057a-41d9-861e-f9640c728eb4',
              name: 'Nitrile-Butadiene-Polymer Roofing',
              tradeId: '07 54 26',
            },
          ],
          tradeId: '07 54 00',
        },
        {
          id: 'b5019a24-f796-4522-821c-1bde2c34bed6',
          name: 'Protected Membrane Roofing',
          children: [
            {
              id: '63b4b267-4290-4dea-ba9c-4364fa4cb8fc',
              name: 'Protected Membrane Roofing',
              tradeId: '07 55 00',
            },
            {
              id: 'e84dc003-2ee3-4525-8c99-3176f81833d5',
              name: 'Built-Up Bituminous Protected Membrane Roofing',
              tradeId: '07 55 51',
            },
            {
              id: '1645493a-6bf5-4a40-b76c-0f113e96810b',
              name: 'Modified Bituminous Protected Membrane Roofing',
              tradeId: '07 55 52',
            },
            {
              id: '7cd8b78c-0dec-4280-b702-cbaf0d33d3e2',
              name: 'Elastomeric Protected Membrane Roofing',
              tradeId: '07 55 53',
            },
            {
              id: '0c23d8a9-641f-4ec6-a0e6-da56a4bc304d',
              name: 'Thermoplastic Protected Membrane Roofing',
              tradeId: '07 55 54',
            },
            {
              id: '3e85d632-d37b-4b60-9311-f7847b63d335',
              name: 'Fluid-Applied Protected Membrane Roofing',
              tradeId: '07 55 56',
            },
            {
              id: '436c637d-eba2-40b5-89c5-f2c216951c38',
              name: 'Hot-Applied Rubberized Asphalt Protected Membrane Roofing',
              tradeId: '07 55 56.13',
            },
            {
              id: 'a54f9156-6448-432b-a919-c1e7b89e3a74',
              name: 'Vegetated Protected Membrane Roofing',
              tradeId: '07 55 63',
            },
          ],
          tradeId: '07 55 00',
        },
        {
          id: 'e597ed44-da65-4da1-8d9a-684cf2d80302',
          name: 'Fluid-Applied Roofing',
          children: [],
          tradeId: '07 56 00',
        },
        {
          id: 'ce43a37e-d761-4a28-9c53-126f01b25cbe',
          name: 'Coated Foamed Roofing',
          children: [
            {
              id: '35d4a842-9b67-4db7-862c-929f33d31695',
              name: 'Coated Foamed Roofing',
              tradeId: '07 57 00',
            },
            {
              id: 'd0a5d127-896d-40e1-9214-7b444ec1921b',
              name: 'Sprayed Polyurethane Foam Roofing',
              tradeId: '07 57 13',
            },
          ],
          tradeId: '07 57 00',
        },
        {
          id: '33c29964-e6fe-4d0c-8552-11baccb9f89b',
          name: 'Roll Roofing',
          children: [],
          tradeId: '07 58 00',
        },
        {
          id: 'cf9ce364-3577-44a4-865a-c72cfe45d0f8',
          name: 'Flashing and Sheet Metal',
          children: [],
          tradeId: '07 60 00',
        },
        {
          id: '97a9c3e2-56ff-4c97-9256-79defd1e9e08',
          name: 'Sheet Metal Roofing',
          children: [
            {
              id: '9ef22ece-9711-4095-b8b3-57d4d321b4c8',
              name: 'Sheet Metal Roofing',
              tradeId: '07 61 00',
            },
            {
              id: '2b6a76cf-a58a-429d-8161-69ec8fc2df87',
              name: 'Standing Seam Sheet Metal Roofing',
              tradeId: '07 61 13',
            },
            {
              id: '3356db36-c401-44c1-b3d5-3f483b071d70',
              name: 'Batten Seam Sheet Metal Roofing',
              tradeId: '07 61 16',
            },
            {
              id: '1c2b6bb9-3510-4158-90dd-973b7cdebccc',
              name: 'Flat Seam Sheet Metal Roofing',
              tradeId: '07 61 19',
            },
          ],
          tradeId: '07 61 00',
        },
        {
          id: '44c4430d-1d24-4b6b-b6d1-ca6d9e6f2799',
          name: 'Sheet Metal Flashing and Trim',
          children: [],
          tradeId: '07 62 00',
        },
        {
          id: 'cd450e92-1684-4801-8dba-dcba81a94261',
          name: 'Sheet Metal Roofing Specialties',
          children: [],
          tradeId: '07 63 00',
        },
        {
          id: '63f046f3-ef9d-46b7-96be-68b73c0258d2',
          name: 'Sheet Metal Wall Cladding',
          children: [
            {
              id: '6f2e2089-24a2-47b5-9e93-81bd3b6b2b61',
              name: 'Sheet Metal Wall Cladding',
              tradeId: '07 64 00',
            },
            {
              id: '796b7bd5-c0ec-491f-b472-faf2c30b1a77',
              name: 'Standing Seam Sheet Metal Wall Cladding',
              tradeId: '07 64 13',
            },
            {
              id: 'e943f6e4-b123-4cb8-b324-89721c18fc31',
              name: 'Batten Seam Sheet Metal Wall Cladding',
              tradeId: '07 64 16',
            },
            {
              id: '5a07f2f7-8be3-4cee-bd87-a261512c9107',
              name: 'Flat Seam Sheet Metal Wall Cladding',
              tradeId: '07 64 19',
            },
          ],
          tradeId: '07 64 00',
        },
        {
          id: '36dcbe97-dea5-4809-8f68-5be0cd32a362',
          name: 'Flexible Flashing',
          children: [
            {
              id: 'c95f8d42-92a1-4154-92d6-3ca6e2c9b4ff',
              name: 'Flexible Flashing',
              tradeId: '07 65 00',
            },
            {
              id: 'a3910c6d-7a57-4a4b-8f73-863e270ef3fb',
              name: 'Laminated Sheet Flashing',
              tradeId: '07 65 13',
            },
            {
              id: '92197088-0fef-4b38-b7f7-bd4ba8181042',
              name: 'Modified Bituminous Sheet Flashing',
              tradeId: '07 65 16',
            },
            {
              id: '5ccdf8b4-60c4-43c3-8636-5895932667db',
              name: 'Plastic Sheet Flashing',
              tradeId: '07 65 19',
            },
            {
              id: '65d4c86b-1fa2-4916-98b2-f62d093323d6',
              name: 'Rubber Sheet Flashing',
              tradeId: '07 65 23',
            },
            {
              id: 'cdfff590-2192-4057-bd3b-1626376e358d',
              name: 'Self-Adhering Sheet Flashing',
              tradeId: '07 65 26',
            },
          ],
          tradeId: '07 65 00',
        },
        {
          id: '026f52e0-a50d-4ef8-9928-8b4c6e986537',
          name: 'Roof and Wall Specialties and Accessories',
          children: [],
          tradeId: '07 70 00',
        },
        {
          id: '9119057d-17d1-4f8a-bd06-40a6b2466b03',
          name: 'Roof Specialties',
          children: [
            {
              id: '320d2447-9f9f-4990-a856-cec7737cebd5',
              name: 'Roof Specialties',
              tradeId: '07 71 00',
            },
            {
              id: 'c1453d65-213f-43af-b72d-d00e2f47d4da',
              name: 'Manufactured Copings',
              tradeId: '07 71 13',
            },
            {
              id: 'ae2a3aa9-757a-4044-a4b6-5d2ef6210902',
              name: 'Manufactured Counterflashing Systems',
              tradeId: '07 71 16',
            },
            {
              id: '0b71833e-b15b-4ca4-92df-e980a7253bf7',
              name: 'Manufactured Gravel Stops and Fasciae',
              tradeId: '07 71 19',
            },
            {
              id: 'a9648918-14ca-42e0-ac71-12399e9f89df',
              name: 'Manufactured Gutters and Downspouts',
              tradeId: '07 71 23',
            },
            {
              id: '8b03054f-ddc5-44ec-8d67-bacc2870bea8',
              name: 'Gutter Debris Guards',
              tradeId: '07 71 23.13',
            },
            {
              id: '5afb86d1-86d3-4638-a5ad-913541c8c69a',
              name: 'Reglets',
              tradeId: '07 71 26',
            },
            {
              id: '18799cdc-9942-4793-9fc1-0cae485e8a67',
              name: 'Manufactured Roof Expansion Joints',
              tradeId: '07 71 29',
            },
            {
              id: '1e7dc1d3-287a-409a-954b-480329f0d693',
              name: 'Manufactured Scuppers',
              tradeId: '07 71 33',
            },
          ],
          tradeId: '07 71 00',
        },
        {
          id: '1100bfac-0c3b-4b7f-b279-12d2f91e4fc8',
          name: 'Roof Accessories',
          children: [
            {
              id: '8fbe4263-b692-4845-b5cd-885268976752',
              name: 'Roof Accessories',
              tradeId: '07 72 00',
            },
            {
              id: '7d48c713-aa83-4b28-b4db-13be386ba9d2',
              name: 'Manufactured Curbs',
              tradeId: '07 72 13',
            },
            {
              id: '81a76076-308e-4598-b781-e13c979925be',
              name: 'Relief Vents',
              tradeId: '07 72 23',
            },
            {
              id: 'e43cc4b5-cc72-40e1-82de-9d172008751b',
              name: 'Ridge Vents',
              tradeId: '07 72 26',
            },
            {
              id: 'ac599a1e-f7d0-4857-b629-fce9ce21e0e5',
              name: 'Roof Hatches',
              tradeId: '07 72 33',
            },
            {
              id: 'ae056dce-c56c-4e0b-b0b5-72befefd4b87',
              name: 'Smoke Vents',
              tradeId: '07 72 36',
            },
            {
              id: '72b51f51-3e72-4417-afde-2c58bc29e36d',
              name: 'Roof Walk Boards',
              tradeId: '07 72 43',
            },
            {
              id: 'cdc6a86c-7d49-456c-a7fa-a239aae12f85',
              name: 'Roof Walkways',
              tradeId: '07 72 46',
            },
            {
              id: '7d1d45b5-b59b-4dae-a80f-170592892c33',
              name: 'Snow Guards',
              tradeId: '07 72 53',
            },
            {
              id: '6448e8a5-ca26-4bc2-92bf-7367ce38370c',
              name: 'Waste Containment Assemblies',
              tradeId: '07 72 63',
            },
          ],
          tradeId: '07 72 00',
        },
        {
          id: '2ec9cb10-8312-45f9-8e63-4b0060f50f92',
          name: 'Roof Pavers',
          children: [
            {
              id: 'd05a40fc-b9cc-4219-bd96-5d68be6996a1',
              name: 'Roof Pavers',
              tradeId: '07 76 00',
            },
            {
              id: 'b91120ff-2909-4e47-bea0-61597c6f3bdd',
              name: 'Roof Ballast Pavers',
              tradeId: '07 76 13',
            },
            {
              id: '3bf8ca78-615e-4323-858c-650e0f22e19b',
              name: 'Roof Decking Pavers',
              tradeId: '07 76 16',
            },
          ],
          tradeId: '07 76 00',
        },
        {
          id: 'a4cd769d-51c2-407b-b409-bfbccc107439',
          name: 'Wall Specialties',
          children: [],
          tradeId: '07 77 00',
        },
        {
          id: '612f708e-6b69-412a-8e13-76083717f446',
          name: 'Fire and Smoke Protection',
          children: [],
          tradeId: '07 80 00',
        },
        {
          id: '0f5bc11c-bf57-4773-8720-c5cfae9af43c',
          name: 'Applied Fireproofing',
          children: [
            {
              id: '99f758f1-24ae-408a-94d1-d912b0901f90',
              name: 'Applied Fireproofing',
              tradeId: '07 81 00',
            },
            {
              id: '7ef080fd-e507-4479-a3f2-2fcfe2fafc5f',
              name: 'Cement Aggregate Fireproofing',
              tradeId: '07 81 13',
            },
            {
              id: 'af953d29-b665-4168-b3c1-3df4152eb163',
              name: 'Cementitious Fireproofing',
              tradeId: '07 81 16',
            },
            {
              id: '5631dac2-4197-45e4-90e4-8510c53bfecd',
              name: 'Foamed Magnesium-Oxychloride Fireproofing',
              tradeId: '07 81 19',
            },
            {
              id: 'd89d2c7c-b4dd-4215-9a4d-911ef937d091',
              name: 'Intumescent Fireproofing',
              tradeId: '07 81 23',
            },
            {
              id: 'fa2e8e72-a819-4154-a613-0406b9b1e1a1',
              name: 'Magnesium Cement Fireproofing',
              tradeId: '07 81 26',
            },
            {
              id: '807a977e-7c51-4b6a-88eb-17d4479645c9',
              name: 'Mineral-Fiber Cementitious Fireproofing',
              tradeId: '07 81 29',
            },
            {
              id: 'b1d10f17-05cf-44ab-bcd2-81b93931c862',
              name: 'Mineral-Fiber Fireproofing',
              tradeId: '07 81 33',
            },
          ],
          tradeId: '07 81 00',
        },
        {
          id: '21ba64e8-f32a-43fb-b874-4ad7ce272d63',
          name: 'Board Fireproofing',
          children: [
            {
              id: 'e48474b3-9d35-4506-bf51-2091024c4dd9',
              name: 'Board Fireproofing',
              tradeId: '07 82 00',
            },
            {
              id: '5fbaff43-392d-49c6-b79c-3945e1485f75',
              name: 'Calcium-Silicate Board Fireproofing',
              tradeId: '07 82 13',
            },
            {
              id: '65c41b54-6be2-49df-98a0-b106fee991c2',
              name: 'Slag-Fiber Board Fireproofing',
              tradeId: '07 82 16',
            },
          ],
          tradeId: '07 82 00',
        },
        {
          id: 'd9cceb92-1636-4af8-8c58-b9de3a9423db',
          name: 'Firestopping',
          children: [
            {
              id: '99d60613-975b-4709-a145-0f6026573136',
              name: 'Firestopping',
              tradeId: '07 84 00',
            },
            {
              id: '2c8d2cff-47e2-4629-9aa6-6a17bb757946',
              name: 'Penetration Firestopping',
              tradeId: '07 84 13',
            },
            {
              id: '60b31d87-f484-47a6-af10-58326b942bd3',
              name: 'Penetration Firestopping Mortars',
              tradeId: '07 84 13.13',
            },
            {
              id: 'e84f8610-85ec-4486-92a0-f9868f87dc04',
              name: 'Penetration Firestopping Devices',
              tradeId: '07 84 13.16',
            },
            {
              id: '81f92704-37d8-495f-abef-1f8e647e025c',
              name: 'Joint Firestopping',
              tradeId: '07 84 43',
            },
            {
              id: '1a11f5fa-d241-43d7-91f2-e73e2d656e8d',
              name: 'Building Perimeter Firestopping',
              tradeId: '07 84 53',
            },
          ],
          tradeId: '07 84 00',
        },
        {
          id: '8ce826ea-3bc1-4d53-a3e1-97fef8ed136d',
          name: 'Smoke Seals',
          children: [],
          tradeId: '07 86 00',
        },
        {
          id: '46c0df88-74b6-4cfc-8cb1-1b429b0a5e82',
          name: 'Smoke Containment Barriers',
          children: [],
          tradeId: '07 87 00',
        },
        {
          id: 'a6bc3582-b1e3-4b57-a25c-f9453790c3ff',
          name: 'Joint Protection',
          children: [],
          tradeId: '07 90 00',
        },
        {
          id: '926243b7-67be-462f-bd14-d59b0f172408',
          name: 'Preformed Joint Seals',
          children: [
            {
              id: '4ec0f31e-0ce9-4f18-b6f4-9a7754f36c54',
              name: 'Preformed Joint Seals',
              tradeId: '07 91 00',
            },
            {
              id: 'f01ffb94-a392-4390-ab93-7b4f743f40c3',
              name: 'Compression Seals',
              tradeId: '07 91 13',
            },
            {
              id: '4dddede7-7a9c-4c68-a41e-cc2cf427c8c2',
              name: 'Joint Gaskets',
              tradeId: '07 91 16',
            },
            {
              id: '472f1359-c8b8-4bc0-a8ec-9bcc1c3e957d',
              name: 'Backer Rods',
              tradeId: '07 91 23',
            },
            {
              id: '46e5cd71-6aa4-4f20-a26f-2116ab2c7d68',
              name: 'Joint Fillers',
              tradeId: '07 91 26',
            },
          ],
          tradeId: '07 91 00',
        },
        {
          id: '0142bec2-1fcf-4ac8-8ae2-0e292f688961',
          name: 'Joint Sealants',
          children: [
            {
              id: 'ffe98d7a-c8ca-43ad-ab05-a8505fcf98c8',
              name: 'Joint Sealants',
              tradeId: '07 92 00',
            },
            {
              id: 'bf071c27-1df5-4130-a2f3-d3f53ac2f887',
              name: 'Elastomeric Joint Sealants',
              tradeId: '07 92 13',
            },
            {
              id: '9034b9d0-3f04-41f5-bb88-ed15f20f1cb7',
              name: 'Rigid Joint Sealants',
              tradeId: '07 92 16',
            },
            {
              id: '015b983f-b221-4955-bbd4-eacf3f1f94ef',
              name: 'Acoustical Joint Sealants',
              tradeId: '07 92 19',
            },
          ],
          tradeId: '07 92 00',
        },
        {
          id: '8ccbb0bd-f93d-47a6-a78e-ced7ca990727',
          name: 'Expansion Control',
          children: [
            {
              id: '5c4fc746-ca6f-4eb8-9dd3-d124f2824080',
              name: 'Expansion Control',
              tradeId: '07 95 00',
            },
            {
              id: 'f410cf75-fee9-4152-9638-f194edd9999d',
              name: 'Expansion Joint Cover Assemblies',
              tradeId: '07 95 13',
            },
            {
              id: '51671b15-e328-431e-95a0-fd26d1cf4085',
              name: 'Joint Slide Bearings',
              tradeId: '07 95 53',
            },
            {
              id: '66bfc0de-d732-44f3-bc47-1517f8c58724',
              name: 'Bridge Expansion Joint Cover Assemblies',
              tradeId: '07 95 63',
            },
          ],
          tradeId: '07 95 00',
        },
      ],
      tradeId: '07 00 00',
    },
    {
      id: '3ecb1710-c96c-414d-9f15-4d74ac1e9e5d',
      name: 'Openings',
      children: [
        {
          id: '65149082-0871-4f2a-b922-f35f4df8dfbb',
          name: 'Openings',
          children: [],
          tradeId: '08 00 00',
        },
        {
          id: '36fba85e-40ab-4db5-913d-d254a949db62',
          name: 'Operation and Maintenance of Openings',
          children: [
            {
              id: '1112554d-763d-4fe9-ab19-c956128c0b7c',
              name: 'Operation and Maintenance of Openings',
              tradeId: '08 01 00',
            },
            {
              id: '901e706c-74a0-45d2-866c-ac978a7de23f',
              name: 'Operation and Maintenance of Doors and Frames',
              tradeId: '08 01 10',
            },
            {
              id: 'b385c788-06cf-47d7-a6e4-2ed02c1e5a89',
              name: 'Operation and Maintenance of Metal Doors and Frames',
              tradeId: '08 01 11',
            },
            {
              id: '086d6d22-4fe9-4b57-9d44-1f0c55ddd288',
              name: 'Operation and Maintenance of Wood Doors',
              tradeId: '08 01 14',
            },
            {
              id: '69ebd3b5-8344-4de7-818f-4523120ce567',
              name: 'Operation and Maintenance of Plastic Doors',
              tradeId: '08 01 15',
            },
            {
              id: 'f6f390e1-0030-4383-91fe-f35d47358b02',
              name: 'Operation and Maintenance of Composite Doors',
              tradeId: '08 01 16',
            },
            {
              id: 'eaf840cd-4c52-4871-816a-213b2d613ea8',
              name: 'Operation and Maintenance of Integrated Door Opening Assemblies',
              tradeId: '08 01 17',
            },
            {
              id: 'fca7eef1-0931-4fe1-b9dd-28a58f20111a',
              name: 'Operation and Maintenance of Specialty Doors and Frames',
              tradeId: '08 01 30',
            },
            {
              id: '8ff50647-539a-45e9-a07b-15f12d55d7d5',
              name: 'Operation and Maintenance of Sliding Glass Doors',
              tradeId: '08 01 32',
            },
            {
              id: 'b870a0f9-73c8-4c1c-91f4-4079ea9dc1fb',
              name: 'Operation and Maintenance of Coiling Doors and Grilles',
              tradeId: '08 01 33',
            },
            {
              id: 'a3e3f5b7-80aa-4383-8454-c770c0d7e9cc',
              name: 'Operation and Maintenance of Special Function Doors',
              tradeId: '08 01 34',
            },
            {
              id: 'b2b145f3-d7da-4c7d-89e1-35d66f1305ce',
              name: 'Operation and Maintenance of Folding Doors and Grilles',
              tradeId: '08 01 35',
            },
            {
              id: '309d33b3-b59a-47aa-9bc3-55f6f6ebaaa2',
              name: 'Operation and Maintenance of Panel Doors',
              tradeId: '08 01 36',
            },
            {
              id: '1fb12f64-bb37-4d76-a382-6ab407c35e6d',
              name: 'Operation and Maintenance of Traffic Doors',
              tradeId: '08 01 39',
            },
            {
              id: 'cb1a0fde-9aa1-4a92-a176-5da01ccb8fda',
              name: 'Operation and Maintenance of Entrances, Storefronts and Curtain Walls',
              tradeId: '08 01 40',
            },
            {
              id: '1b6dc0a7-f159-4ebb-a5f2-8a1a9afeadcc',
              name: 'Operation and Maintenance of Entrances',
              tradeId: '08 01 41',
            },
            {
              id: '4e878b69-41ab-4d03-aefd-508e77804689',
              name: 'Operation and Maintenance of Storefronts',
              tradeId: '08 01 42',
            },
            {
              id: 'f80ec6f6-2475-42fd-9dd3-ba3d53426d19',
              name: 'Operation and Maintenance of Curtain Walls',
              tradeId: '08 01 44',
            },
            {
              id: '155f16c1-f982-46c1-8328-d0a956232825',
              name: 'Operation and Maintenance of Windows',
              tradeId: '08 01 50',
            },
            {
              id: 'dfc28750-1f8f-49d7-953d-c267a203101e',
              name: 'Operation and Maintenance of Metal Windows',
              tradeId: '08 01 51',
            },
            {
              id: '853c0d62-959c-4d64-9484-433dbde36bb7',
              name: 'Operation and Maintenance of Wood Windows',
              tradeId: '08 01 52',
            },
            {
              id: 'a49fd97a-9721-4def-bf15-269105abcf35',
              name: 'Wood Window Repairs',
              tradeId: '08 01 52.61',
            },
            {
              id: '682059e7-d0f8-4e2b-8414-16798e3880db',
              name: 'Wood Window Rehabilitation',
              tradeId: '08 01 52.71',
            },
            {
              id: 'a763fc1a-08a6-4ffd-9bc0-e3d65e3aa1b8',
              name: 'Wood Window Replacement',
              tradeId: '08 01 52.81',
            },
            {
              id: '2bb03b7a-6b14-45e9-8542-a35106f061f5',
              name: 'Wood Window Restoration',
              tradeId: '08 01 52.91',
            },
            {
              id: '0b6ef61b-f972-44a7-b67e-d720c0e3e1eb',
              name: 'Historic Treatment of Wood Windows',
              tradeId: '08 01 52.93',
            },
            {
              id: '76cb510d-1f20-4c56-8fde-ea0ce2aa8e01',
              name: 'Operation and Maintenance of Plastic Windows',
              tradeId: '08 01 53',
            },
            {
              id: 'd3c135e7-0d52-44b0-9ec5-5200df098ea6',
              name: 'Operation and Maintenance of Composite Windows',
              tradeId: '08 01 54',
            },
            {
              id: '4eec4c7d-7bed-4e74-9aaa-9d6d6791640d',
              name: 'Operation and Maintenance of Special Function Windows',
              tradeId: '08 01 56',
            },
            {
              id: '2b82d924-07c8-499d-b80d-8e50522bf009',
              name: 'Operation and Maintenance of Skylights and Roof Windows',
              tradeId: '08 01 60',
            },
            {
              id: '44ac95f1-466a-4911-a310-6d7e10840b68',
              name: 'Operation and Maintenance of Roof Windows',
              tradeId: '08 01 61',
            },
            {
              id: '3be2a1ae-6ea4-40a0-8d76-6a853e097172',
              name: 'Operation and Maintenance of Unit Skylights',
              tradeId: '08 01 62',
            },
            {
              id: '1cc96c10-8462-40c7-be94-bd18bbcc7153',
              name: 'Operation and Maintenance of Metal-Framed Skylights',
              tradeId: '08 01 63',
            },
            {
              id: '49236de0-d0a7-4801-90d0-a393b76b337e',
              name: 'Operation and Maintenance of Plastic-Framed Skylights',
              tradeId: '08 01 64',
            },
            {
              id: '2e386991-46f4-440d-a464-b9e43fb8fd11',
              name: 'Operation and Maintenance of Hardware',
              tradeId: '08 01 70',
            },
            {
              id: '0535c18f-5c3d-495f-8dba-041cc0bc4615',
              name: 'Operation and Maintenance of Door Hardware',
              tradeId: '08 01 71',
            },
            {
              id: '3394369c-b20d-48a3-bedc-979d97d64b98',
              name: 'Operation and Maintenance of Access Control Hardware',
              tradeId: '08 01 74',
            },
            {
              id: '3207c598-d823-4d59-9e26-1e90db781ec4',
              name: 'Operation and Maintenance of Window Hardware',
              tradeId: '08 01 75',
            },
            {
              id: 'ebea0627-1236-4569-ba78-4d8825dc9a1d',
              name: 'Maintenance of Glazing',
              tradeId: '08 01 80',
            },
            {
              id: '51db526a-0204-4a3a-ac44-5c350e4d771c',
              name: 'Maintenance of Glass Glazing',
              tradeId: '08 01 81',
            },
            {
              id: '0527c267-c2c2-4b8c-a1e3-f104a40370b1',
              name: 'Maintenance of Plastic Glazing',
              tradeId: '08 01 84',
            },
            {
              id: 'b5b98324-802c-4d0f-a8ae-d550889cb18b',
              name: 'Maintenance of Special Function Glazing',
              tradeId: '08 01 88',
            },
            {
              id: '64d6a375-9f44-4d96-b936-4961097b9121',
              name: 'Operation and Maintenance of Louvers and Vents',
              tradeId: '08 01 90',
            },
            {
              id: 'cce693e6-66c1-4fc5-aeb3-04cabe3aa904',
              name: 'Operation and Maintenance of Louvers',
              tradeId: '08 01 91',
            },
            {
              id: 'e47a2582-bd39-4329-8b23-903ef534a43d',
              name: 'Operation and Maintenance of Louvered Equipment Enclosures',
              tradeId: '08 01 92',
            },
            {
              id: '578d5149-f40f-480b-aae0-dc5ca5a24255',
              name: 'Operation and Maintenance of Vents',
              tradeId: '08 01 95',
            },
          ],
          tradeId: '08 01 00',
        },
        {
          id: '23afcdc4-937c-46d9-860b-d92705389d2a',
          name: 'Common Work Results for Openings',
          children: [],
          tradeId: '08 05 00',
        },
        {
          id: '4d8d060d-a031-4128-86ba-2f0c2908eecf',
          name: 'Schedules for Openings',
          children: [
            {
              id: 'ee340b1c-ebd4-42c1-b755-a7eee92c94f9',
              name: 'Schedules for Openings',
              tradeId: '08 06 00',
            },
            {
              id: 'f20043ad-704a-4b64-b6fc-777d40657b0b',
              name: 'Door Schedule',
              tradeId: '08 06 10',
            },
            {
              id: '7a7782d8-ef53-4619-adb9-00248df8d864',
              name: 'Door Type Schedule',
              tradeId: '08 06 10.13',
            },
            {
              id: 'dc03839a-6777-4732-aaa1-3c146bbe74ef',
              name: 'Frame Type Schedule',
              tradeId: '08 06 10.16',
            },
            {
              id: '566511d9-6948-4123-996a-c2f637ba17ec',
              name: 'Schedules for Entrances, Storefronts and Curtain Walls',
              tradeId: '08 06 40',
            },
            {
              id: '01e9ea7c-f299-4e2c-afb0-990336f664fd',
              name: 'Entrance Schedule',
              tradeId: '08 06 41',
            },
            {
              id: '4d97e4dd-1d42-493f-9900-8e27e383e878',
              name: 'Storefront Schedule',
              tradeId: '08 06 42',
            },
            {
              id: '3c9e002c-b5b6-44d4-b9a3-eebdb3aabad8',
              name: 'Window Schedule',
              tradeId: '08 06 50',
            },
            {
              id: 'ce3bed3e-18eb-4e88-b8f0-0728f2e8547c',
              name: 'Skylight Schedule',
              tradeId: '08 06 60',
            },
            {
              id: '93962554-35fc-42d6-8da2-7db0e727f2ed',
              name: 'Hardware Schedule',
              tradeId: '08 06 70',
            },
            {
              id: '325853b9-b600-4e99-a2fb-68222164729d',
              name: 'Door Hardware Schedule',
              tradeId: '08 06 71',
            },
            {
              id: 'a3c634c0-4a40-4b42-87ef-5fbb32c738a8',
              name: 'Glazing Schedule',
              tradeId: '08 06 80',
            },
            {
              id: '7bc76e1e-503e-4900-a808-937607883993',
              name: 'Louver and Vent Schedule',
              tradeId: '08 06 90',
            },
          ],
          tradeId: '08 06 00',
        },
        {
          id: 'c1057952-604a-4974-bda4-6649c0962917',
          name: 'Commissioning of Openings',
          children: [],
          tradeId: '08 08 00',
        },
        {
          id: '691c3ce1-f9ee-45b7-8aac-2a15496cfac7',
          name: 'Doors and Frames',
          children: [],
          tradeId: '08 10 00',
        },
        {
          id: '7292775b-c06c-4af5-89a1-02eff34d8b39',
          name: 'Metal Doors and Frames',
          children: [
            {
              id: 'b917e851-e5fb-4b2f-b1c3-6826908dfa95',
              name: 'Metal Doors and Frames',
              tradeId: '08 11 00',
            },
            {
              id: '6ba0d51f-1c11-4011-b459-04be95bf811e',
              name: 'Hollow Metal Doors and Frames',
              tradeId: '08 11 13',
            },
            {
              id: '1b5d6019-0f08-4b24-9796-36278440a262',
              name: 'Standard Hollow Metal Doors and Frames',
              tradeId: '08 11 13.13',
            },
            {
              id: '9b82f907-542d-43f3-b90c-4648c47054ef',
              name: 'Custom Hollow Metal Doors and Frames',
              tradeId: '08 11 13.16',
            },
            {
              id: 'b18757ca-ca53-4f11-a49c-c2811b050cb1',
              name: 'Aluminum Doors and Frames',
              tradeId: '08 11 16',
            },
            {
              id: 'b1faa095-d8a0-4d70-8b61-d44fd4a48d9f',
              name: 'Stainless-Steel Doors and Frames',
              tradeId: '08 11 19',
            },
            {
              id: '5054853c-aba9-4efb-b2b3-a82177191a48',
              name: 'Bronze Doors and Frames',
              tradeId: '08 11 23',
            },
            {
              id: 'ae32cfa8-2e1d-41dd-9344-08ab07b3b8ca',
              name: 'Metal Screen and Storm Doors and Frames',
              tradeId: '08 11 63',
            },
            {
              id: '7b4e0ab3-9482-4b6c-85b9-e13394485737',
              name: 'Steel Screen and Storm Doors and Frames',
              tradeId: '08 11 63.13',
            },
            {
              id: 'f6875fde-f5eb-4718-8958-586d4205e828',
              name: 'Aluminum Screen and Storm Doors and Frames',
              tradeId: '08 11 63.23',
            },
            {
              id: 'f99f7d0d-4ad9-4794-bece-b1bb672bea79',
              name: 'Metal Screen Doors and Frames',
              tradeId: '08 11 66',
            },
            {
              id: '1ca20681-71d3-4f02-a23c-31e52a61d90f',
              name: 'Steel Screen Doors and Frames',
              tradeId: '08 11 66.13',
            },
            {
              id: '30ff3a8a-4f18-4d83-9d5b-d2b3ee184624',
              name: 'Aluminum Screen Doors and Frames',
              tradeId: '08 11 66.23',
            },
            {
              id: '81c1a648-dd5f-4559-afd1-7dc01a015f1c',
              name: 'Metal Storm Doors and Frames',
              tradeId: '08 11 69',
            },
            {
              id: 'c3cca127-b824-493f-81ac-732834631f9b',
              name: 'Steel Storm Doors and Frames',
              tradeId: '08 11 69.13',
            },
            {
              id: '6c26a2ad-9d09-4511-8d03-9856b87e2fad',
              name: 'Aluminum Storm Doors and Frames',
              tradeId: '08 11 69.23',
            },
            {
              id: 'b56ed744-d44f-42f4-9bc1-2ec8a6d1567d',
              name: 'Sliding Metal Fire Doors',
              tradeId: '08 11 73',
            },
            {
              id: '9a491f16-c5f4-4d8c-a24a-3818d43e99ad',
              name: 'Sliding Metal Grilles',
              tradeId: '08 11 74',
            },
          ],
          tradeId: '08 11 00',
        },
        {
          id: 'a61ca74c-15d2-48c5-bb3e-63b803ab0662',
          name: 'Metal Frames',
          children: [
            {
              id: 'ad8a3b7f-bf71-42f6-a1ff-d47dbb4ef61b',
              name: 'Metal Frames',
              tradeId: '08 12 00',
            },
            {
              id: '85face57-804b-405e-95d1-fb057a0fb4ca',
              name: 'Hollow Metal Frames',
              tradeId: '08 12 13',
            },
            {
              id: '45b3a00b-b848-463e-b9e8-359ce278091a',
              name: 'Standard Hollow Metal Frames',
              tradeId: '08 12 13.13',
            },
            {
              id: '67758909-df44-44c8-83d2-33dc22678a59',
              name: 'Custom Hollow Metal Frames',
              tradeId: '08 12 13.53',
            },
            {
              id: '3004a955-fac2-4d0e-8c8f-57ed1c68a0b3',
              name: 'Aluminum Frames',
              tradeId: '08 12 16',
            },
            {
              id: 'c42c9d75-b5f9-4098-8c20-2fd21facd7c3',
              name: 'Stainless-Steel Frames',
              tradeId: '08 12 19',
            },
            {
              id: '9b9161c0-81ca-46d5-acf4-7940057b43d6',
              name: 'Bronze Frames',
              tradeId: '08 12 23',
            },
          ],
          tradeId: '08 12 00',
        },
        {
          id: '46e7b9f2-ab6c-44d6-9347-8dbcdde9c5c4',
          name: 'Metal Doors',
          children: [
            {
              id: '3d2b57a1-941f-47d7-a782-db57ed3c428f',
              name: 'Metal Doors',
              tradeId: '08 13 00',
            },
            {
              id: 'ecf53116-094e-45b3-a8ff-82ae77434b9e',
              name: 'Hollow Metal Doors',
              tradeId: '08 13 13',
            },
            {
              id: '1d30cc0e-e441-42be-9f2f-c9625c1e3ac0',
              name: 'Standard Hollow Metal Doors',
              tradeId: '08 13 13.13',
            },
            {
              id: 'fbaeed89-31ea-4932-a860-0e04bac0c50b',
              name: 'Custom Hollow Metal Doors',
              tradeId: '08 13 13.53',
            },
            {
              id: '69f775ad-6518-49a8-a247-d7a970121b62',
              name: 'Aluminum Doors',
              tradeId: '08 13 16',
            },
            {
              id: '7211a546-a074-47d1-aa70-e8407a8d709b',
              name: 'Stainless-Steel Doors',
              tradeId: '08 13 19',
            },
            {
              id: 'ec198443-9803-4264-a12a-55b58a9a803e',
              name: 'Bronze Doors',
              tradeId: '08 13 23',
            },
            {
              id: '0e4deb85-ed9b-4c18-b5ba-ad31179badba',
              name: 'Sliding Metal Doors',
              tradeId: '08 13 73',
            },
            {
              id: 'f2ac98e8-4c6a-46f4-9261-0e63994b2078',
              name: 'Bifolding Metal Doors',
              tradeId: '08 13 76',
            },
          ],
          tradeId: '08 13 00',
        },
        {
          id: 'f182daf5-5184-4b98-9973-ac1c6d178c01',
          name: 'Wood Doors',
          children: [
            {
              id: 'cabacc05-a5d5-4fea-a976-1fb4dbfa31d2',
              name: 'Wood Doors',
              tradeId: '08 14 00',
            },
            {
              id: '6fbe7ef9-b4fa-48ef-8e2d-3fc4a3084bdc',
              name: 'Carved Wood Doors',
              tradeId: '08 14 13',
            },
            {
              id: 'a44235ee-39f1-4bde-8f9b-41ab5af80539',
              name: 'Flush Wood Doors',
              tradeId: '08 14 16',
            },
            {
              id: '9b97313c-c253-4b4f-89bb-9a54bb8452a3',
              name: 'Clad Wood Doors',
              tradeId: '08 14 23',
            },
            {
              id: '6c0665e0-e16f-4cc8-8ef0-f1f12282c974',
              name: 'Metal-Faced Wood Doors',
              tradeId: '08 14 23.13',
            },
            {
              id: '78f615c1-fce0-4db6-89d3-35acf0e2bf7a',
              name: 'Plastic-Laminate-Faced Wood Doors',
              tradeId: '08 14 23.16',
            },
            {
              id: 'bdacda74-3264-4cf3-a568-e615175f9b33',
              name: 'Molded-Hardboard-Faced Wood Doors',
              tradeId: '08 14 23.19',
            },
            {
              id: 'a2ae3094-c8e5-45da-b2d5-2615db050e60',
              name: 'Prefinished Wood Doors',
              tradeId: '08 14 29',
            },
            {
              id: '881ef50d-a5d3-4eee-86b8-add35c19cfb1',
              name: 'Stile and Rail Wood Doors',
              tradeId: '08 14 33',
            },
            {
              id: '6e407770-46e5-472d-8dab-c2de89f52552',
              name: 'Wood Screen Doors',
              tradeId: '08 14 66',
            },
            {
              id: '11d04c71-0c1a-4f01-b0c2-e553173bfe09',
              name: 'Wood Storm Doors',
              tradeId: '08 14 69',
            },
            {
              id: '32f970de-4569-48ba-bc89-fa0507fdaec9',
              name: 'Sliding Wood Doors',
              tradeId: '08 14 73',
            },
            {
              id: 'ae374cc8-3c7e-4bdf-a63e-390710485ad1',
              name: 'Bifolding Wood Doors',
              tradeId: '08 14 76',
            },
          ],
          tradeId: '08 14 00',
        },
        {
          id: '839facf4-63ff-4de8-9857-bb4af413289e',
          name: 'Plastic Doors',
          children: [
            {
              id: 'f8a0bb23-4eb9-4079-91fa-12c25200cc95',
              name: 'Plastic Doors',
              tradeId: '08 15 00',
            },
            {
              id: '03be84e1-a65c-454b-bb6d-2c9b14fcaaea',
              name: 'Laminated Plastic Doors',
              tradeId: '08 15 13',
            },
            {
              id: '61ad16dc-4a4e-47e5-951e-d605595f17fd',
              name: 'Solid Plastic Doors',
              tradeId: '08 15 16',
            },
            {
              id: '4cde3ef5-dba0-4279-a5f2-f19e3aebbada',
              name: 'Plastic Screen Doors',
              tradeId: '08 15 66',
            },
            {
              id: 'f303cb00-26b4-4a58-bd6b-703a19d5d5ea',
              name: 'Plastic Storm Doors',
              tradeId: '08 15 69',
            },
            {
              id: '999d73aa-b32f-4e4e-9405-394dc3c4bc27',
              name: 'Sliding Plastic Doors',
              tradeId: '08 15 73',
            },
            {
              id: '78ae3bc1-3f64-49d3-9ecb-48884813844c',
              name: 'Bifolding Plastic Doors',
              tradeId: '08 15 76',
            },
          ],
          tradeId: '08 15 00',
        },
        {
          id: 'ae86c391-8e76-48f9-b09a-c225fbe958e8',
          name: 'Composite Doors',
          children: [
            {
              id: '9eea8e1c-19ac-4999-a7fb-0131cd56ae99',
              name: 'Composite Doors',
              tradeId: '08 16 00',
            },
            {
              id: '77259969-276a-44e5-8aa5-9186eff6e079',
              name: 'Fiberglass Doors',
              tradeId: '08 16 13',
            },
            {
              id: '70faadcd-db6c-4fce-a571-d33f64ba45ec',
              name: 'Sliding Composite Doors',
              tradeId: '08 16 73',
            },
            {
              id: 'be068f36-14d6-442b-a9b6-cc06f53ed3f7',
              name: 'Bifolding Composite Doors',
              tradeId: '08 16 76',
            },
          ],
          tradeId: '08 16 00',
        },
        {
          id: '236c814a-6f4d-4b90-9ae3-618bdb7e9985',
          name: 'Integrated Door Opening Assemblies',
          children: [
            {
              id: '5877c3ad-cccf-4cd3-8865-56f8576a23aa',
              name: 'Integrated Door Opening Assemblies',
              tradeId: '08 17 00',
            },
            {
              id: 'dbbca31d-d850-4d33-b490-f04a514e7a3b',
              name: 'Integrated Metal Door Opening Assemblies',
              tradeId: '08 17 13',
            },
            {
              id: '304272b7-7d63-44e0-944b-a12045c89821',
              name: 'Integrated Wood Door Opening Assemblies',
              tradeId: '08 17 23',
            },
            {
              id: '12806bb4-5059-4a14-a9e4-2d79d3d51705',
              name: 'Integrated Plastic Door Opening Assemblies',
              tradeId: '08 17 33',
            },
            {
              id: '952651e7-34f6-48cd-b81c-0af11e0e7104',
              name: 'Integrated Composite Door Opening Assemblies',
              tradeId: '08 17 43',
            },
          ],
          tradeId: '08 17 00',
        },
        {
          id: '1c23b100-facc-4168-af12-1b0ae94d8931',
          name: 'Specialty Doors and Frames',
          children: [],
          tradeId: '08 30 00',
        },
        {
          id: '1c6dba8c-36c1-4b5d-98d9-4c4dccc4bbd6',
          name: 'Access Doors and Panels',
          children: [
            {
              id: 'd0c405b4-6b90-4783-9ab3-7c7cef52b56f',
              name: 'Access Doors and Panels',
              tradeId: '08 31 00',
            },
            {
              id: '85ca053e-a592-4ae8-8bfd-030cd495b0cb',
              name: 'Access Doors and Frames',
              tradeId: '08 31 13',
            },
            {
              id: '25ff00df-0980-4279-a85f-9b33a4b4c168',
              name: 'Security Access Doors and Frames',
              tradeId: '08 31 13.53',
            },
            {
              id: 'dc5f3d44-c997-4b92-a3ce-c49f50adddf1',
              name: 'Access Panels and Frames',
              tradeId: '08 31 16',
            },
          ],
          tradeId: '08 31 00',
        },
        {
          id: '17c4c09f-5656-4050-8e46-fc274183777d',
          name: 'Sliding Glass Doors',
          children: [
            {
              id: '43b13959-2b64-464e-9899-b6b92b62492d',
              name: 'Sliding Glass Doors',
              tradeId: '08 32 00',
            },
            {
              id: '88667ad9-870a-4f8d-92fc-54cda53b8405',
              name: 'Sliding Aluminum-Framed Glass Doors',
              tradeId: '08 32 13',
            },
            {
              id: '34ea5fb2-04e4-41b5-b897-b90d9b02a9fe',
              name: 'Sliding Plastic-Framed Glass Doors',
              tradeId: '08 32 16',
            },
            {
              id: 'd3fbdb2a-fcfd-4fa0-afa0-5034c8f40a75',
              name: 'Sliding Wood-Framed Glass Doors',
              tradeId: '08 32 19',
            },
          ],
          tradeId: '08 32 00',
        },
        {
          id: '2f96c3b3-f2c7-4ef4-bc6e-05c8c677847c',
          name: 'Coiling Doors and Grilles',
          children: [
            {
              id: '1bd3e31a-507d-4867-8950-1d66a59e4541',
              name: 'Coiling Doors and Grilles',
              tradeId: '08 33 00',
            },
            {
              id: '7f86f2a1-3197-40be-98f7-89470f62529a',
              name: 'Coiling Counter Doors',
              tradeId: '08 33 13',
            },
            {
              id: '4cd207b7-f9f6-4c30-a3d8-5f7b0820ca35',
              name: 'Coiling Counter Grilles',
              tradeId: '08 33 16',
            },
            {
              id: 'cc9a63cd-e0d6-4545-8054-b4528d0d7ef0',
              name: 'Overhead Coiling Doors',
              tradeId: '08 33 23',
            },
            {
              id: '5dd7f672-d249-4e23-a63c-2ed458755843',
              name: 'Overhead Rapid Coiling Doors',
              tradeId: '08 33 23.13',
            },
            {
              id: 'c85192a5-acf1-46ed-936e-341147782469',
              name: 'Overhead Coiling Grilles',
              tradeId: '08 33 26',
            },
            {
              id: '1ab161e5-255b-4a83-820d-f75ffe1f94e9',
              name: 'Side Coiling Doors',
              tradeId: '08 33 33',
            },
            {
              id: 'd04846c3-86c8-4e17-a7ce-7a7657740daf',
              name: 'Side Coiling Grilles',
              tradeId: '08 33 36',
            },
            {
              id: 'dc732dd1-b9e6-423f-8678-03aaffa41029',
              name: 'Overhead Coiling Smoke Curtains',
              tradeId: '08 33 43',
            },
            {
              id: '16306994-442e-467e-9329-036008cb89c1',
              name: 'Overhead Coiling Fire Curtains',
              tradeId: '08 33 44',
            },
          ],
          tradeId: '08 33 00',
        },
        {
          id: 'e445abe7-ff95-4f55-be65-6f3680a0ce7c',
          name: 'Special Function Doors',
          children: [
            {
              id: 'af940a41-9428-4b6f-8e34-6183a1ac07de',
              name: 'Special Function Doors',
              tradeId: '08 34 00',
            },
            {
              id: '94883f8a-3f9b-4d39-8f50-f203817b2952',
              name: 'Cold Storage Doors',
              tradeId: '08 34 13',
            },
            {
              id: '3396ba64-76f9-442b-8104-da6a933ebf24',
              name: 'Hangar Doors',
              tradeId: '08 34 16',
            },
            {
              id: 'f7f80e07-3a06-41a1-87a6-4d6054678e73',
              name: 'Industrial Doors',
              tradeId: '08 34 19',
            },
            {
              id: 'a3c3fb3f-4982-4446-9bec-7ea026ecf108',
              name: 'Lightproof Doors',
              tradeId: '08 34 33',
            },
            {
              id: 'e9c0ebee-8212-47fc-b4b4-1b45a9aebc72',
              name: 'Darkroom Doors',
              tradeId: '08 34 36',
            },
            {
              id: 'a5b3cbe4-9725-4a8c-ab0a-5ae6df21679d',
              name: 'Radio-Frequency-Interference Shielding Doors',
              tradeId: '08 34 46',
            },
            {
              id: '3ba0130d-0d92-4e4e-b16c-c460606adc74',
              name: 'Radiation Shielding Doors and Frames',
              tradeId: '08 34 49',
            },
            {
              id: '90e21dc7-7ed8-4523-aeb0-efcb65845ffc',
              name: 'Neutron Shielding Doors and Frames',
              tradeId: '08 34 49.13',
            },
            {
              id: '2c1392ff-adcb-4b0e-a2ba-a294864eba48',
              name: 'Security Doors and Frames',
              tradeId: '08 34 53',
            },
            {
              id: 'cf44c03c-0a2c-4f47-ab9d-73131534cb84',
              name: 'Security Gates',
              tradeId: '08 34 56',
            },
            {
              id: 'ab0046eb-2053-4fea-8c5c-d060b8d25b5b',
              name: 'File Room Doors and Frames',
              tradeId: '08 34 58',
            },
            {
              id: '8616287e-df76-4ca8-abaf-c98556dd7a51',
              name: 'Vault Doors and Day Gates',
              tradeId: '08 34 59',
            },
            {
              id: 'b7601b2c-854f-4b61-bd84-9f6d15cfcec6',
              name: 'Detention Doors and Frames',
              tradeId: '08 34 63',
            },
            {
              id: 'c835397f-be57-4213-adb3-5f8808aacc5b',
              name: 'Steel Detention Doors and Frames',
              tradeId: '08 34 63.13',
            },
            {
              id: '909fff41-0170-4571-b32a-73f463c5e85f',
              name: 'Steel Plate Detention Doors and Frames',
              tradeId: '08 34 63.16',
            },
            {
              id: 'a5ae9317-b919-4a7e-9cce-ae1e8d3b61c3',
              name: 'Detention Door Frame Protection',
              tradeId: '08 34 63.33',
            },
            {
              id: 'a10717b0-1d44-4d82-a4cd-19fa21fd68c3',
              name: 'Sound Control Door Assemblies',
              tradeId: '08 34 73',
            },
          ],
          tradeId: '08 34 00',
        },
        {
          id: '4efb7f87-f15d-4326-8fa7-2d49f0e0b1bb',
          name: 'Folding Doors and Grilles',
          children: [
            {
              id: '517661e5-f216-497d-b883-6b8fb357fdbb',
              name: 'Folding Doors and Grilles',
              tradeId: '08 35 00',
            },
            {
              id: 'ceb90a87-9e6f-4b1e-8de8-362f37727ff7',
              name: 'Folding Doors',
              tradeId: '08 35 13',
            },
            {
              id: '43796971-0884-4e68-8867-d1bc6657336e',
              name: 'Accordion Folding Doors',
              tradeId: '08 35 13.13',
            },
            {
              id: '1b7a530a-1d5a-486f-9e9a-de98047762d1',
              name: 'Accordion Folding Fire Doors',
              tradeId: '08 35 13.23',
            },
            {
              id: '239f0878-1301-4b6a-ad59-88db3051e9d0',
              name: 'Panel Folding Doors',
              tradeId: '08 35 13.33',
            },
            {
              id: 'f9065b9e-3d60-4f10-bb92-a5678ecacdbe',
              name: 'Folding Grilles',
              tradeId: '08 35 16',
            },
            {
              id: 'ffe5d8bf-3e4f-4c2d-85db-4ca964283636',
              name: 'Accordion Folding Grilles',
              tradeId: '08 35 16.13',
            },
          ],
          tradeId: '08 35 00',
        },
        {
          id: '44b4a01e-5351-4f9e-8af0-f2d611fb26bb',
          name: 'Panel Doors',
          children: [
            {
              id: '2aa418a8-0416-4366-a8b6-d22483c58c46',
              name: 'Panel Doors',
              tradeId: '08 36 00',
            },
            {
              id: '712ad3dd-78ef-45c2-b853-c66dfab1a1b2',
              name: 'Sectional Doors',
              tradeId: '08 36 13',
            },
            {
              id: 'be9f0d96-8ce5-46c1-a7a3-5b238a948348',
              name: 'Single-Panel Doors',
              tradeId: '08 36 16',
            },
            {
              id: '98e14009-8d0f-4bf0-a1b5-cdc9dea2bfc1',
              name: 'Multi-Leaf Vertical Lift Doors',
              tradeId: '08 36 19',
            },
            {
              id: '7949bc40-0e8b-45d7-a89c-f6513e2f801a',
              name: 'Telescoping Vertical Lift Doors',
              tradeId: '08 36 23',
            },
          ],
          tradeId: '08 36 00',
        },
        {
          id: '374d2c50-92c7-451e-882b-08e3e2f4bccb',
          name: 'Traffic Doors',
          children: [
            {
              id: 'e11b229c-2581-411c-86c5-6a9694f3ee6a',
              name: 'Traffic Doors',
              tradeId: '08 38 00',
            },
            {
              id: '80746580-d062-429e-8c6a-9cb81e4babef',
              name: 'Flexible Strip Doors',
              tradeId: '08 38 13',
            },
            {
              id: '9aee8bd9-839f-4ed1-bfc2-be01933ca7f4',
              name: 'Flexible Traffic Doors',
              tradeId: '08 38 16',
            },
            {
              id: '23702f52-dad4-42fb-ba4f-44fe685776e0',
              name: 'Rigid Traffic Doors',
              tradeId: '08 38 19',
            },
          ],
          tradeId: '08 38 00',
        },
        {
          id: 'ea3de454-2e8e-4608-a349-c4e9e3be4e2e',
          name: 'Pressure-Resistant Doors',
          children: [
            {
              id: 'f3a6622d-c80b-421e-aebc-355cf51b0935',
              name: 'Pressure-Resistant Doors',
              tradeId: '08 39 00',
            },
            {
              id: '1d922eca-1453-4e60-9dd8-6c29fbd54723',
              name: 'Airtight Doors',
              tradeId: '08 39 13',
            },
            {
              id: 'e38ea678-b04b-4212-a4ca-5830d0875f2b',
              name: 'Watertight Doors',
              tradeId: '08 39 19',
            },
            {
              id: 'd49a3cd3-e17b-4863-8711-74d59bbd3dbe',
              name: 'Blast-Resistant Doors',
              tradeId: '08 39 53',
            },
          ],
          tradeId: '08 39 00',
        },
        {
          id: '377d22c6-ae81-4658-b3c0-9141c5c52410',
          name: 'Entrances, Storefronts and Curtain Walls',
          children: [],
          tradeId: '08 40 00',
        },
        {
          id: '9e3b1645-cf08-4530-af64-e64e287c1fd4',
          name: 'Entrances and Storefronts',
          children: [
            {
              id: '710f1af0-6540-4959-a67e-2870a106f0ca',
              name: 'Entrances and Storefronts',
              tradeId: '08 41 00',
            },
            {
              id: '9afc94ed-0ff7-4e57-a3c1-37701ab988f6',
              name: 'Aluminum-Framed Entrances and Storefronts',
              tradeId: '08 41 13',
            },
            {
              id: '5bbf317b-212d-46ec-8a33-38beee5eee30',
              name: 'Fire-Rated Aluminum-Framed Entrances and Storefronts',
              tradeId: '08 41 13.13',
            },
            {
              id: '185076d4-c1c4-48bf-a0fd-896c8aee2dd9',
              name: 'Bronze-Framed Entrances and Storefronts',
              tradeId: '08 41 16',
            },
            {
              id: '336258c9-c330-494c-b0bc-47a004b5cc4f',
              name: 'Stainless-Steel-Framed Entrances and Storefronts',
              tradeId: '08 41 19',
            },
            {
              id: '395ed2cc-b5a8-4a3d-9a87-971df1e2db45',
              name: 'Steel-Framed Entrances and Storefronts',
              tradeId: '08 41 23',
            },
            {
              id: 'cb2327a6-1beb-47a8-a2b8-1efe8c1d59c4',
              name: 'All-Glass Entrances and Storefronts',
              tradeId: '08 41 26',
            },
          ],
          tradeId: '08 41 00',
        },
        {
          id: '01152772-4acd-4632-b234-578cd1cc9c4e',
          name: 'Entrances',
          children: [
            {
              id: 'c845b68b-b230-4132-afda-4f97ed5f55ef',
              name: 'Entrances',
              tradeId: '08 42 00',
            },
            {
              id: '5b0d6d59-2449-4c92-be42-e7580a6e37d8',
              name: 'All-Glass Entrances',
              tradeId: '08 42 26',
            },
            {
              id: 'b575c5ba-12e4-445e-8cd5-1a91dca68d48',
              name: 'Automatic Entrances',
              tradeId: '08 42 29',
            },
            {
              id: '2fa8bf3a-1279-49d9-8fa3-0dcee29f8427',
              name: 'Folding Automatic Entrances',
              tradeId: '08 42 29.13',
            },
            {
              id: '7915009c-1602-4394-993c-06680f7323ef',
              name: 'Sliding Automatic Entrances',
              tradeId: '08 42 29.23',
            },
            {
              id: '09d78396-cc9e-46e8-aa36-74c568bb56fe',
              name: 'Swinging Automatic Entrances',
              tradeId: '08 42 29.33',
            },
            {
              id: '99c1cb23-36de-4a9a-ae53-fe7d06506012',
              name: 'Revolving Door Entrances',
              tradeId: '08 42 33',
            },
            {
              id: '6f73dee2-8a12-437d-a8ff-f9d7ef80c6f2',
              name: 'Security Revolving Door Entrances',
              tradeId: '08 42 33.13',
            },
            {
              id: '5ed3cbc6-c290-4664-a37e-65ad06fc6d6f',
              name: 'Balanced Door Entrances',
              tradeId: '08 42 36',
            },
            {
              id: '85c0cbe4-6c69-4cea-9e1d-eeb8690da363',
              name: 'Pressure-Resistant Entrances',
              tradeId: '08 42 39',
            },
            {
              id: '5d2c6c21-419f-4e19-a76b-86017ffa1bf6',
              name: 'Intensive Care Unit/Critical Care Unit Entrances',
              tradeId: '08 42 43',
            },
          ],
          tradeId: '08 42 00',
        },
        {
          id: 'b9f1a7fe-301d-4473-8faa-c7f41e86ddaf',
          name: 'Storefronts',
          children: [
            {
              id: '3d6baca4-cf17-4789-a350-a9100e4350c4',
              name: 'Storefronts',
              tradeId: '08 43 00',
            },
            {
              id: 'f36c8ab5-53e1-4c9e-9a83-079dd87f49db',
              name: 'Timber-Framed Storefronts',
              tradeId: '08 43 11',
            },
            {
              id: 'a5086ffd-bb29-44fc-ab30-dff1910f4ba1',
              name: 'Aluminum-Framed Storefronts',
              tradeId: '08 43 13',
            },
            {
              id: '776eaaca-bbbb-4da7-994e-ba86a9e09db4',
              name: 'Fire-Rated Aluminum Storefronts',
              tradeId: '08 43 13.13',
            },
            {
              id: 'd6ee7529-948b-49bb-9edf-3b4bc8128400',
              name: 'Bronze-Framed Storefronts',
              tradeId: '08 43 16',
            },
            {
              id: '44cd8c5e-f259-4cc9-a9bd-d2c53cb33a6a',
              name: 'Stainless-Steel-Framed Storefronts',
              tradeId: '08 43 19',
            },
            {
              id: '7fb123e9-95f1-46c2-a1c2-ab88fdc0fbad',
              name: 'Steel-Framed Storefronts',
              tradeId: '08 43 23',
            },
            {
              id: '3a487afb-14b5-478c-9ce6-ad39e122da0d',
              name: 'All-Glass Storefronts',
              tradeId: '08 43 26',
            },
            {
              id: '8fb6304a-6d89-458e-b50b-784803426363',
              name: 'Channel Glass Storefronts',
              tradeId: '08 43 27',
            },
            {
              id: '3a85a380-e378-4fee-9652-9f5fd2412989',
              name: 'Sliding Storefronts',
              tradeId: '08 43 29',
            },
          ],
          tradeId: '08 43 00',
        },
        {
          id: 'f9da0f5b-9866-458d-9dbb-4faa158cf6e5',
          name: 'Curtain Wall and Glazed Assemblies',
          children: [
            {
              id: '3f8c2f22-1006-4704-be50-40b88d26c097',
              name: 'Curtain Wall and Glazed Assemblies',
              tradeId: '08 44 00',
            },
            {
              id: '23e6c0ff-d14d-4403-a336-caef5c0aaa23',
              name: 'Glazed Timber Curtain Walls',
              tradeId: '08 44 11',
            },
            {
              id: '9a7bf210-f8f0-4f18-b8e8-ce6c2a2fac6a',
              name: 'Glazed Composite Curtain Wall',
              tradeId: '08 44 12',
            },
            {
              id: '6f7ac5c0-c4d8-4fe8-9756-ca65cf559262',
              name: 'Glazed Fiberglass Curtain Wall',
              tradeId: '08 44 12.13',
            },
            {
              id: '7212b01d-8fc9-4a82-af0e-57c089010758',
              name: 'Glazed Aluminum Curtain Walls',
              tradeId: '08 44 13',
            },
            {
              id: '3955b30a-1a61-41fd-b621-d615e3496e05',
              name: 'Glazed Bronze Curtain Walls',
              tradeId: '08 44 16',
            },
            {
              id: 'a1640af3-f006-4b79-a885-52cf41afa442',
              name: 'Glazed Steel Curtain Walls',
              tradeId: '08 44 18',
            },
            {
              id: 'a8859650-8d5c-4173-8c3a-39294e70e64c',
              name: 'Glazed Stainless-Steel Curtain Walls',
              tradeId: '08 44 19',
            },
            {
              id: '24036a76-3a66-4ec2-916a-530d3bc319a0',
              name: 'Structural Sealant Glazed Curtain Walls',
              tradeId: '08 44 23',
            },
            {
              id: '37525c4b-0c40-4f72-b94c-5b5e73b8be38',
              name: 'Structural Glass Curtain Walls',
              tradeId: '08 44 26',
            },
            {
              id: '79b2136e-f0b3-4326-a700-57c003344a80',
              name: 'Point Supported Structural Glass',
              tradeId: '08 44 26.19',
            },
            {
              id: '013291b2-e135-485e-913b-fe62bc2e2790',
              name: 'Sloped Glazing Assemblies',
              tradeId: '08 44 33',
            },
          ],
          tradeId: '08 44 00',
        },
        {
          id: 'c263ea8e-58b5-4422-a5e9-26762f7a74f5',
          name: 'Translucent Wall and Roof Assemblies',
          children: [
            {
              id: '09bb2924-2358-4716-a041-11d687340108',
              name: 'Translucent Wall and Roof Assemblies',
              tradeId: '08 45 00',
            },
            {
              id: '9fa1ba2d-a828-4f69-ac54-e79544e6ed93',
              name: 'Structured-Polycarbonate-Panel Assemblies',
              tradeId: '08 45 13',
            },
            {
              id: '72692409-59a0-4ce6-968b-bf45d5f4d351',
              name: 'Fiberglass-Sandwich-Panel Assemblies',
              tradeId: '08 45 23',
            },
          ],
          tradeId: '08 45 00',
        },
        {
          id: '45534190-bc08-4980-b21e-ba67725ece9e',
          name: 'Windows',
          children: [],
          tradeId: '08 50 00',
        },
        {
          id: '018740a3-71c1-4139-84db-008da5b72411',
          name: 'Metal Windows',
          children: [
            {
              id: '7df4b829-42f6-4398-b13c-6d9e9aa57d02',
              name: 'Metal Windows',
              tradeId: '08 51 00',
            },
            {
              id: '66e58847-fe70-4896-baf6-b972d72c22b5',
              name: 'Aluminum Windows',
              tradeId: '08 51 13',
            },
            {
              id: '802d90d5-7961-4ebc-868b-a21ba3e3e773',
              name: 'Bronze Windows',
              tradeId: '08 51 16',
            },
            {
              id: 'f45c0572-8cb2-43f2-9905-4e37e0a93d10',
              name: 'Stainless-Steel Windows',
              tradeId: '08 51 19',
            },
            {
              id: '41d92714-84a7-45ac-9703-14729ca817a5',
              name: 'Steel Windows',
              tradeId: '08 51 23',
            },
            {
              id: 'c3283759-2adc-4ad0-8cb1-f61ac3236b05',
              name: 'Metal Window Screens',
              tradeId: '08 51 66',
            },
            {
              id: 'f500ff97-27a3-478e-b185-fdf817f0666e',
              name: 'Metal Storm Windows',
              tradeId: '08 51 69',
            },
          ],
          tradeId: '08 51 00',
        },
        {
          id: 'cf44d6d1-2d4c-4faa-b5b0-5c84f634b587',
          name: 'Wood Windows',
          children: [
            {
              id: '1d7c3b26-973e-4572-9c59-14ea26a4bcf9',
              name: 'Wood Windows',
              tradeId: '08 52 00',
            },
            {
              id: 'ebeeb156-63df-4641-93aa-452131072afb',
              name: 'Metal-Clad Wood Windows',
              tradeId: '08 52 13',
            },
            {
              id: '3b22b538-d723-475d-92b8-5441b36e5ce1',
              name: 'Plastic-Clad Wood Windows',
              tradeId: '08 52 16',
            },
            {
              id: '6ab85bed-2b3d-4c9f-a03a-8e2915459806',
              name: 'Wood Window Screens',
              tradeId: '08 52 66',
            },
            {
              id: 'c8bb353d-8de1-4707-b462-8d5de2336d10',
              name: 'Wood Storm Windows',
              tradeId: '08 52 69',
            },
          ],
          tradeId: '08 52 00',
        },
        {
          id: '0d778d6a-be86-49dd-bcb5-52146f7762f7',
          name: 'Plastic Windows',
          children: [
            {
              id: 'b8467a91-5ec9-4115-95ab-6cb9162bfd5c',
              name: 'Plastic Windows',
              tradeId: '08 53 00',
            },
            {
              id: 'd75f6b00-fbe4-48ae-af56-46604fab058e',
              name: 'Vinyl Windows',
              tradeId: '08 53 13',
            },
            {
              id: '39adf393-fbfb-4ea6-a024-1b9604d51914',
              name: 'Vinyl Window Screens',
              tradeId: '08 53 66',
            },
            {
              id: 'f62a0348-b16b-40e9-be57-c86df6415a1b',
              name: 'Vinyl Storm Windows',
              tradeId: '08 53 69',
            },
          ],
          tradeId: '08 53 00',
        },
        {
          id: 'f34a3f9d-2d83-4806-bb8e-5a02ec63d075',
          name: 'Composite Windows',
          children: [
            {
              id: 'd4043a65-59df-4b8d-85c4-c515f977fc96',
              name: 'Composite Windows',
              tradeId: '08 54 00',
            },
            {
              id: 'ae468bbd-61ed-4708-9d6c-7e9b027ef8fd',
              name: 'Fiberglass Windows',
              tradeId: '08 54 13',
            },
            {
              id: '3f033015-7db2-4556-876c-a79ba5087949',
              name: 'Fiberglass Window Screens',
              tradeId: '08 54 66',
            },
            {
              id: 'cfcf27b7-99f3-4fa7-88ae-63be78b0cf95',
              name: 'Fiberglass Storm Windows',
              tradeId: '08 54 69',
            },
          ],
          tradeId: '08 54 00',
        },
        {
          id: 'd1aea6e0-a108-4a5a-bfe9-a5d112479659',
          name: 'Pressure-Resistant Windows',
          children: [],
          tradeId: '08 55 00',
        },
        {
          id: '49990290-1fed-4bd0-a7f7-d529a0e852bd',
          name: 'Special Function Windows',
          children: [
            {
              id: 'f7d24116-cbc6-4401-bd8c-13ab5debf8ae',
              name: 'Special Function Windows',
              tradeId: '08 56 00',
            },
            {
              id: '52a71343-be6e-43b3-978a-ec2d2562b1ce',
              name: 'Pass Windows',
              tradeId: '08 56 19',
            },
            {
              id: '479a308b-6c1a-4f8f-9d60-05cda84a1755',
              name: 'Radio-Frequency-Interference Shielding Windows',
              tradeId: '08 56 46',
            },
            {
              id: '08d09a7f-e56b-42c3-be90-d06a2d688564',
              name: 'Radiation Shielding Windows',
              tradeId: '08 56 49',
            },
            {
              id: 'd0413dc6-a70d-4a53-bca8-4172b08110ba',
              name: 'Security Windows',
              tradeId: '08 56 53',
            },
            {
              id: '94bd2578-1114-4979-889b-0ade60d02383',
              name: 'Security Window Screens',
              tradeId: '08 56 56',
            },
            {
              id: '86fd6644-830c-4e89-908d-060d594fc3e6',
              name: 'Service and Teller Window Units',
              tradeId: '08 56 59',
            },
            {
              id: '9fbf0724-d32a-455f-873d-e21502f62cfc',
              name: 'Detention Windows',
              tradeId: '08 56 63',
            },
            {
              id: 'e7d2e6e2-c868-497f-9bcf-1e32252a0e6f',
              name: 'Detention Window Screens',
              tradeId: '08 56 66',
            },
            {
              id: '4a317c88-51b6-4adf-a7a6-6bd948f7f53c',
              name: 'Sound Control Windows',
              tradeId: '08 56 73',
            },
            {
              id: 'a156ca38-433b-4ab0-86b8-c817b6fc6c72',
              name: 'Interior Insulating Windows',
              tradeId: '08 56 88',
            },
          ],
          tradeId: '08 56 00',
        },
        {
          id: 'd753f535-c08b-4119-bcdc-ab4bb8c53674',
          name: 'Roof Windows and Skylights',
          children: [],
          tradeId: '08 60 00',
        },
        {
          id: '3dbe40a1-a1f9-4070-a0a5-76c136486cd8',
          name: 'Roof Windows',
          children: [
            {
              id: '69a6a605-f3ba-4ffd-942f-f23be9c2e6a1',
              name: 'Roof Windows',
              tradeId: '08 61 00',
            },
            {
              id: 'dac6e07c-842f-441f-91af-aa96b1f6749f',
              name: 'Metal Roof Windows',
              tradeId: '08 61 13',
            },
            {
              id: 'f0ca61d8-45fb-4836-8d17-aabd2f81f243',
              name: 'Wood Roof Windows',
              tradeId: '08 61 16',
            },
          ],
          tradeId: '08 61 00',
        },
        {
          id: '1f9635e0-8d26-4f56-95ee-aa89a3ac53f2',
          name: 'Unit Skylights',
          children: [
            {
              id: '00ee0fc8-9b81-4359-901d-250931002d1a',
              name: 'Unit Skylights',
              tradeId: '08 62 00',
            },
            {
              id: '26fe3c5f-fb43-434b-bd81-9650331d5753',
              name: 'Domed Unit Skylights',
              tradeId: '08 62 13',
            },
            {
              id: 'ed6c8b74-32fe-4604-a6cb-8d0ada5e821a',
              name: 'Pyramidal Unit Skylights',
              tradeId: '08 62 16',
            },
            {
              id: '4b561d22-c5f9-4395-b0bb-8f9e7c267f69',
              name: 'Vaulted Unit Skylights',
              tradeId: '08 62 19',
            },
            {
              id: '420579dd-082e-4f0b-bd26-f11d00cb8dac',
              name: 'Tubular Skylights',
              tradeId: '08 62 23',
            },
          ],
          tradeId: '08 62 00',
        },
        {
          id: 'bbe2f70e-7ab6-4b5a-94fc-a629bdaef813',
          name: 'Metal-Framed Skylights',
          children: [
            {
              id: 'b891f6c1-7a00-4d02-98e5-6fce0c4936ea',
              name: 'Metal-Framed Skylights',
              tradeId: '08 63 00',
            },
            {
              id: '073ad6cb-7b20-4487-982f-cec9d8bb8d46',
              name: 'Domed Metal-Framed Skylights',
              tradeId: '08 63 13',
            },
            {
              id: '27accb36-3b77-492f-a5d6-cd1914d8eaf0',
              name: 'Pyramidal Metal-Framed Skylights',
              tradeId: '08 63 16',
            },
            {
              id: '968a076e-1853-4589-a398-dc2a0fd3ee5b',
              name: 'Vaulted Metal-Framed Skylights',
              tradeId: '08 63 19',
            },
            {
              id: '92b438e0-8df6-400e-ac74-753fa6dcf65a',
              name: 'Ridge Metal-Framed Skylights',
              tradeId: '08 63 23',
            },
            {
              id: '53a42575-bfa5-4b53-8efa-db276c211ba7',
              name: 'Motorized Metal-Framed Skylights',
              tradeId: '08 63 53',
            },
          ],
          tradeId: '08 63 00',
        },
        {
          id: '5b32f6e8-0e90-41a5-ab20-4862e0e76bf9',
          name: 'Plastic-Framed Skylights',
          children: [],
          tradeId: '08 64 00',
        },
        {
          id: '98eb3701-d986-4fc8-a84e-80425ee5b31d',
          name: 'Skylight Protection and Screens',
          children: [],
          tradeId: '08 67 00',
        },
        {
          id: 'c1119aa9-a3db-474f-993d-827f5b32014c',
          name: 'Hardware',
          children: [],
          tradeId: '08 70 00',
        },
        {
          id: '57704136-6391-49b0-9993-c1ca5436bbdf',
          name: 'Door Hardware',
          children: [
            {
              id: 'c6ff73a9-98a6-43ca-98c5-ed7f3965c6b3',
              name: 'Door Hardware',
              tradeId: '08 71 00',
            },
            {
              id: '6430609f-3cf0-4018-b3d1-fad2085cc142',
              name: 'Automatic Door Operators',
              tradeId: '08 71 13',
            },
            {
              id: '3f5c79e0-4542-454b-8c1e-a6dd83041cbc',
              name: 'Security Door Hardware',
              tradeId: '08 71 53',
            },
            {
              id: 'aaa76bdd-aeb8-46ef-a1ed-0fea58c108a0',
              name: 'Detention Door Hardware',
              tradeId: '08 71 63',
            },
          ],
          tradeId: '08 71 00',
        },
        {
          id: 'b3395ee6-5d4a-49a8-9f2a-30501bcc121f',
          name: 'Access Control Hardware',
          children: [
            {
              id: '39c77528-076a-4f25-aabe-67d9453930f3',
              name: 'Access Control Hardware',
              tradeId: '08 74 00',
            },
            {
              id: '86555ebd-5859-4122-ba2f-77f5222531bc',
              name: 'Card Key Access Control Hardware',
              tradeId: '08 74 13',
            },
            {
              id: '9f47166d-f562-4a2f-a000-7382fae3632e',
              name: 'Keypad Access Control Hardware',
              tradeId: '08 74 16',
            },
            {
              id: 'acba0bad-f800-4844-8602-c437d38a52f1',
              name: 'Biometric Identity Access Control Hardware',
              tradeId: '08 74 19',
            },
          ],
          tradeId: '08 74 00',
        },
        {
          id: 'c8a243ab-1ebb-4a3a-ac87-65465a1dd861',
          name: 'Window Hardware',
          children: [
            {
              id: 'b4755852-e9d8-4513-8335-11818ece5af3',
              name: 'Window Hardware',
              tradeId: '08 75 00',
            },
            {
              id: 'f5069aed-7cc9-403b-9bc3-db362e131bf5',
              name: 'Automatic Window Equipment',
              tradeId: '08 75 13',
            },
            {
              id: '577a0cda-3edd-4cd2-803b-2a5eed14f082',
              name: 'Window Operators',
              tradeId: '08 75 16',
            },
          ],
          tradeId: '08 75 00',
        },
        {
          id: '23d51f0a-6512-47ba-a5c4-e15e6fa39428',
          name: 'Special Function Hardware',
          children: [],
          tradeId: '08 78 00',
        },
        {
          id: '0841a34c-7e1a-4115-b09f-921931988630',
          name: 'Hardware Accessories',
          children: [
            {
              id: '1194633a-b0d7-43b2-8f3a-d7eef5012805',
              name: 'Hardware Accessories',
              tradeId: '08 79 00',
            },
            {
              id: '56070886-9a7b-4753-ade2-e6586ca87ce1',
              name: 'Key Storage Equipment',
              tradeId: '08 79 13',
            },
          ],
          tradeId: '08 79 00',
        },
        {
          id: '8d6be53f-cadc-4753-8fa7-ee9258c18508',
          name: 'Glazing',
          children: [],
          tradeId: '08 80 00',
        },
        {
          id: '3f9c9a46-22a0-4415-b1ad-e4630b87a175',
          name: 'Glass Glazing',
          children: [
            {
              id: '9eb3315e-d9cb-45d9-8269-3056376b8691',
              name: 'Glass Glazing',
              tradeId: '08 81 00',
            },
            {
              id: 'af5fb65f-fa8c-4aab-b1ce-89670c1224dc',
              name: 'Decorative Glass Glazing',
              tradeId: '08 81 13',
            },
          ],
          tradeId: '08 81 00',
        },
        {
          id: 'ee35d7fe-38c1-4dfd-9c11-77d698fb30ef',
          name: 'Mirrors',
          children: [
            {
              id: '52b70785-4f9f-420c-84cf-2190396d6796',
              name: 'Mirrors',
              tradeId: '08 83 00',
            },
            {
              id: 'cd21471a-2718-46d0-b12c-b68635dae071',
              name: 'Mirrored Glass Glazing',
              tradeId: '08 83 13',
            },
            {
              id: '7541bed3-ac71-497d-a8e6-973bf5f65fa4',
              name: 'Mirrored Plastic Glazing',
              tradeId: '08 83 16',
            },
          ],
          tradeId: '08 83 00',
        },
        {
          id: '355f1e97-6b74-4016-9fb8-1a4b44d15c2b',
          name: 'Plastic Glazing',
          children: [
            {
              id: '1151170e-9a4c-4cc4-b094-94ed369f4913',
              name: 'Plastic Glazing',
              tradeId: '08 84 00',
            },
            {
              id: '0f413f33-ea93-406b-a184-c76d45c4f537',
              name: 'Decorative Plastic Glazing',
              tradeId: '08 84 13',
            },
          ],
          tradeId: '08 84 00',
        },
        {
          id: 'e4330aaf-4ad9-4bee-9826-1ff1d0db7015',
          name: 'Glazing Accessories',
          children: [],
          tradeId: '08 85 00',
        },
        {
          id: '7bfb09f2-b4a5-42c4-94e7-4637a336fa7e',
          name: 'Glazing Surface Films',
          children: [
            {
              id: 'd1890f5c-510a-467c-8d88-918284303c36',
              name: 'Glazing Surface Films',
              tradeId: '08 87 00',
            },
            {
              id: 'e195f268-42e8-4415-a1bb-c4d1e2518ab3',
              name: 'Solar Control Films',
              tradeId: '08 87 13',
            },
            {
              id: '5791e431-1497-4357-b01d-227490f8b69a',
              name: 'Safety and Security Films',
              tradeId: '08 87 23',
            },
            {
              id: 'aa54db96-edf4-4611-9ad7-127b3d14e792',
              name: 'Safety Films',
              tradeId: '08 87 23.13',
            },
            {
              id: 'c4219cc0-9a4d-412b-ba5d-84e8b2687891',
              name: 'Security Films',
              tradeId: '08 87 23.16',
            },
            {
              id: '421a5f2f-5426-42c8-b58d-d545d3c07588',
              name: 'Decorative Films',
              tradeId: '08 87 33',
            },
          ],
          tradeId: '08 87 00',
        },
        {
          id: '8e337cce-1d52-43d5-aabf-2e4d1b7725d9',
          name: 'Special Function Glazing',
          children: [
            {
              id: '49873c6e-efd2-4d1a-b931-1abe74a6439a',
              name: 'Special Function Glazing',
              tradeId: '08 88 00',
            },
            {
              id: '10561628-e599-4c44-a23f-d9c0cef912a5',
              name: 'Fire-Resistant Glazing',
              tradeId: '08 88 13',
            },
            {
              id: '15d4f4d2-17b2-4f15-a535-df54f345f3ad',
              name: 'Hurricane-Resistant Glazing',
              tradeId: '08 88 19',
            },
            {
              id: 'a766d7ab-d1f1-43f1-9f5d-ca23b925b846',
              name: 'Cable Suspended Glazing',
              tradeId: '08 88 23',
            },
            {
              id: 'a1bcbc4a-c54f-4bb8-ba65-44e8df65e360',
              name: 'Transparent Mirrored Glazing',
              tradeId: '08 88 33',
            },
            {
              id: '8a89b2f5-1d14-45d7-9e45-ee2e9adc139f',
              name: 'Switchable Glass',
              tradeId: '08 88 36',
            },
            {
              id: '0620cfed-9ef8-4055-94bb-94d32ee93e55',
              name: 'Electronically Controlled Switchable Glass',
              tradeId: '08 88 36.16',
            },
            {
              id: '8e5d7cfd-c089-4459-a210-0014df4d9b66',
              name: 'Pressure-Resistant Glazing',
              tradeId: '08 88 39',
            },
            {
              id: '4fe59d6e-6dee-4e18-ae9c-c7cea64c0d5e',
              name: 'Radiation-Resistant Glazing',
              tradeId: '08 88 49',
            },
            {
              id: '09253952-9cc9-44b0-bfb8-21919e0efdc9',
              name: 'Security Glazing',
              tradeId: '08 88 53',
            },
            {
              id: 'ffe19e13-5a15-4e6c-8379-436aed99744d',
              name: 'Ballistics-Resistant Glazing',
              tradeId: '08 88 56',
            },
          ],
          tradeId: '08 88 00',
        },
        {
          id: '1e110d75-0de5-4285-b43d-876cebd69724',
          name: 'Louvers and Vents',
          children: [],
          tradeId: '08 90 00',
        },
        {
          id: '97abce06-d194-45ba-9085-886fd9baea9f',
          name: 'Louvers',
          children: [
            {
              id: '77d4cf6a-ca8d-4e5d-8ea2-67e53dd9f020',
              name: 'Louvers',
              tradeId: '08 91 00',
            },
            {
              id: 'ddecfcf7-89cc-4996-82a9-5499db938d1b',
              name: 'Motorized Wall Louvers',
              tradeId: '08 91 13',
            },
            {
              id: 'ae78c3b8-a816-488a-8e16-7a5dff655fbe',
              name: 'Operable Wall Louvers',
              tradeId: '08 91 16',
            },
            {
              id: 'd173205c-0697-4eef-bc9c-f19b6c0aa95e',
              name: 'Fixed Louvers',
              tradeId: '08 91 19',
            },
            {
              id: '8d523b6f-6715-4894-8e3a-aeb9ab60254d',
              name: 'Door Louvers',
              tradeId: '08 91 26',
            },
          ],
          tradeId: '08 91 00',
        },
        {
          id: 'bc532ed2-9db9-4082-921a-ba0d2aba7d8a',
          name: 'Louvered Equipment Enclosures',
          children: [],
          tradeId: '08 92 00',
        },
        {
          id: '7f16636d-8ae4-48e8-82e3-d341000348ab',
          name: 'Vents',
          children: [
            {
              id: 'cf56ae5a-181e-40b0-83d2-89b544c9a601',
              name: 'Vents',
              tradeId: '08 95 00',
            },
            {
              id: '765a53d6-da1d-4de9-8571-453a870ff9c1',
              name: 'Soffit Vents',
              tradeId: '08 95 13',
            },
            {
              id: '2884d5d3-a352-4010-849e-1d28d34e05ec',
              name: 'Wall Vents',
              tradeId: '08 95 16',
            },
            {
              id: '4d1f5839-df43-406f-859b-c937880007c3',
              name: 'Explosion Vents',
              tradeId: '08 95 33',
            },
            {
              id: 'a877e9bc-5e5a-45ff-80db-7175d829e1a8',
              name: 'Flood Vents',
              tradeId: '08 95 43',
            },
          ],
          tradeId: '08 95 00',
        },
      ],
      tradeId: '08 00 00',
    },
    {
      id: '2cf45f9b-3aee-442e-b8b5-ca0ed86eae4a',
      name: 'Finishes',
      children: [
        {
          id: '7100b13f-1172-457b-b1e7-bbe20132b6d1',
          name: 'Finishes',
          children: [],
          tradeId: '09 00 00',
        },
        {
          id: 'b4ade589-c525-45ee-9510-06d8e127caa8',
          name: 'Maintenance of Finishes',
          children: [
            {
              id: 'df58cc8e-35f8-42fd-b9de-fc012a785c01',
              name: 'Maintenance of Finishes',
              tradeId: '09 01 00',
            },
            {
              id: '2824dda5-5bf1-42f1-af6c-1e1cbf202e56',
              name: 'Maintenance of Plaster and Gypsum Board',
              tradeId: '09 01 20',
            },
            {
              id: 'd87d5c50-a7fa-4619-91ab-a517c635d50e',
              name: 'Plaster Restoration',
              tradeId: '09 01 20.91',
            },
            {
              id: '4b4427cd-db2a-4f43-89cb-5fa21cb066a9',
              name: 'Maintenance of Tiling',
              tradeId: '09 01 30',
            },
            {
              id: '35c65aa7-059a-471a-9a7c-b67bc1b8833e',
              name: 'Tile Restoration',
              tradeId: '09 01 30.91',
            },
            {
              id: 'a9660915-3cbb-4a39-afb4-dc133ac7c8b7',
              name: 'Maintenance of Ceilings',
              tradeId: '09 01 50',
            },
            {
              id: '81c0cdeb-0f49-4e22-9740-39603aa36a11',
              name: 'Ceiling Restoration',
              tradeId: '09 01 50.91',
            },
            {
              id: 'adfa076e-a876-4d0e-b68c-0858a50d83b6',
              name: 'Maintenance of Flooring',
              tradeId: '09 01 60',
            },
            {
              id: 'ed7b680e-882d-4adb-b90a-15f1a63e443f',
              name: 'Flooring Restoration',
              tradeId: '09 01 60.91',
            },
            {
              id: '7c0e35ba-9b7b-44f5-9031-6f6056c4b7fc',
              name: 'Maintenance of Wall Finishes',
              tradeId: '09 01 70',
            },
            {
              id: 'a55ae84c-80fa-4e38-aa33-e2c607c5be1e',
              name: 'Wall Finish Restoration',
              tradeId: '09 01 70.91',
            },
            {
              id: '77a93b01-5a43-4e7e-918d-cc10a08d35aa',
              name: 'Maintenance of Acoustic Treatment',
              tradeId: '09 01 80',
            },
            {
              id: 'a5cae4c6-ba7a-4844-ac0f-573713014009',
              name: 'Maintenance of Painting and Coating',
              tradeId: '09 01 90',
            },
            {
              id: '7228ffc3-f1aa-4563-8678-38e69972a5e9',
              name: 'Paint Cleaning',
              tradeId: '09 01 90.51',
            },
            {
              id: 'c7a586aa-6c95-44bb-9d6f-4ff032f2dc66',
              name: 'Maintenance Repainting',
              tradeId: '09 01 90.52',
            },
            {
              id: '6d1087a0-78a0-4964-8f93-932fe8cb35f1',
              name: 'Maintenance Coatings',
              tradeId: '09 01 90.53',
            },
            {
              id: 'a821c9af-afd2-4c11-bd54-891741aca7db',
              name: 'Repainting',
              tradeId: '09 01 90.61',
            },
            {
              id: '9362374a-1b2a-4c7b-82ea-0198dd56cc14',
              name: 'Paint Restoration',
              tradeId: '09 01 90.91',
            },
            {
              id: '389aec3c-dcd1-422a-a735-e49690610fd9',
              name: 'Coating Restoration',
              tradeId: '09 01 90.92',
            },
            {
              id: '4c0cd0c2-658f-431c-8d2b-30622d66f9f6',
              name: 'Paint Preservation',
              tradeId: '09 01 90.93',
            },
          ],
          tradeId: '09 01 00',
        },
        {
          id: 'bda584dd-8839-43ed-9a2a-ecf0c1e9e288',
          name: 'Common Work Results for Finishes',
          children: [
            {
              id: 'c5fe8377-a3da-4419-a755-7db06273ad17',
              name: 'Common Work Results for Finishes',
              tradeId: '09 05 00',
            },
            {
              id: '73411c0b-1c80-4a1c-b5ed-6574da5cfc36',
              name: 'Common Finishes',
              tradeId: '09 05 13',
            },
            {
              id: '3b614510-69ac-4fbc-9d28-0a4911fc6863',
              name: 'Common Work Results for Flooring Preparation',
              tradeId: '09 05 61',
            },
          ],
          tradeId: '09 05 00',
        },
        {
          id: '4c0dbfb9-5a1a-4505-a70a-6dad1c1b20bb',
          name: 'Schedules for Finishes',
          children: [
            {
              id: 'db26a278-e5c0-43dd-87f2-45e9eb109ec2',
              name: 'Schedules for Finishes',
              tradeId: '09 06 00',
            },
            {
              id: 'fa84f7bc-8bae-4c87-b5c7-e0d45fa47c20',
              name: 'Room Finish Schedule',
              tradeId: '09 06 00.13',
            },
            {
              id: '686a91c1-36be-43db-b4c8-19c0658f397e',
              name: 'Schedules for Plaster and Gypsum Board',
              tradeId: '09 06 20',
            },
            {
              id: '540dc505-f7e2-4f0f-bce6-6b178ca4cd89',
              name: 'Schedules for Tiling',
              tradeId: '09 06 30',
            },
            {
              id: '76bf9c20-cc0f-44b2-b37d-cf1784fc68a1',
              name: 'Schedules for Ceilings',
              tradeId: '09 06 50',
            },
            {
              id: 'eb916dc8-ee9f-4c36-87a8-fd97edccc522',
              name: 'Schedules for Flooring',
              tradeId: '09 06 60',
            },
            {
              id: '1c4689ad-3cac-4639-8cfa-739d5400e01c',
              name: 'Schedules for Wall Finishes',
              tradeId: '09 06 70',
            },
            {
              id: '2d1e580e-b087-48b5-84ac-193e684549c5',
              name: 'Schedules for Acoustical Treatment',
              tradeId: '09 06 80',
            },
            {
              id: '1ee2d439-44cd-45a7-aef2-6e6c345fb22e',
              name: 'Schedules for Painting and Coating',
              tradeId: '09 06 90',
            },
            {
              id: '1cba61cf-aeca-4d08-8f88-61fdf2440d36',
              name: 'Paint Schedule',
              tradeId: '09 06 90.13',
            },
          ],
          tradeId: '09 06 00',
        },
        {
          id: '56697b63-d576-48fd-a525-9c61cee5141c',
          name: 'Schedules for Finishes',
          children: [
            {
              id: 'f064065a-b33f-4427-ae98-9553d040ebaa',
              name: 'Schedules for Finishes',
              tradeId: '09 06 00',
            },
            {
              id: '69002203-d5c5-48cb-8152-294885ab2101',
              name: 'Room Finish Schedule',
              tradeId: '09 06 00.13',
            },
            {
              id: '01350a02-1d20-4622-9ece-19235183be36',
              name: 'Schedules for Plaster and Gypsum Board',
              tradeId: '09 06 20',
            },
            {
              id: '5f8b29f8-bd40-4d82-a968-97caecaabdea',
              name: 'Schedules for Tiling',
              tradeId: '09 06 30',
            },
            {
              id: 'ffd1d636-05a2-4dc7-a698-79b06bb9f04d',
              name: 'Schedules for Ceilings',
              tradeId: '09 06 50',
            },
            {
              id: 'c2d421b6-7ed0-4d28-b08d-1d795ab429ec',
              name: 'Schedules for Flooring',
              tradeId: '09 06 60',
            },
            {
              id: '596d9b7b-bbb9-46df-9ba6-4a7bde52c237',
              name: 'Schedules for Wall Finishes',
              tradeId: '09 06 70',
            },
            {
              id: '8c0dd672-2fd0-4f33-824d-5d692efb4de9',
              name: 'Schedules for Acoustical Treatment',
              tradeId: '09 06 80',
            },
            {
              id: 'a9af1ce3-f255-4793-825e-af831f56226f',
              name: 'Schedules for Painting and Coating',
              tradeId: '09 06 90',
            },
            {
              id: '59346af3-9dbf-46e1-941f-104ba4a530a7',
              name: 'Paint Schedule',
              tradeId: '09 06 90.13',
            },
          ],
          tradeId: '09 06 00',
        },
        {
          id: 'ac07f819-2931-4089-b540-61f4e5416ab6',
          name: 'Commissioning of Finishes',
          children: [],
          tradeId: '09 08 00',
        },
        {
          id: 'ea446917-f3a1-4569-9f1e-6ed6258d496e',
          name: 'Plaster and Gypsum Board',
          children: [],
          tradeId: '09 20 00',
        },
        {
          id: '5300330a-48f8-4848-81ab-51d6a79e43ce',
          name: 'Plaster and Gypsum Board Assemblies',
          children: [
            {
              id: '68037559-19c6-4545-b7d6-69f5f50e880a',
              name: 'Plaster and Gypsum Board Assemblies',
              tradeId: '09 21 00',
            },
            {
              id: 'edb929fb-55ac-44ec-97d9-f161640c0784',
              name: 'Plaster Assemblies',
              tradeId: '09 21 13',
            },
            {
              id: '388f8162-c1ec-4879-95cd-4b5a5296405a',
              name: 'Gypsum Board Assemblies',
              tradeId: '09 21 16',
            },
            {
              id: '0b7def02-fc74-49d9-9319-32a3d16041fd',
              name: 'Gypsum Board Shaft Wall Assemblies',
              tradeId: '09 21 16.23',
            },
            {
              id: '13c8015d-e5ae-4d44-a7b1-311f18c8e7d0',
              name: 'Gypsum Board Area Separation Wall Assemblies',
              tradeId: '09 21 16.33',
            },
          ],
          tradeId: '09 21 00',
        },
        {
          id: '9c31ce7e-660a-4dd3-86a7-93adcd9f7e4b',
          name: 'Supports for Plaster and Gypsum Board',
          children: [
            {
              id: 'b6f7c2ab-cbae-477a-827c-7cf6033e4c4a',
              name: 'Supports for Plaster and Gypsum Board',
              tradeId: '09 22 00',
            },
            {
              id: '868aa2d3-38d0-4c17-90cd-5db6a8d5e3a0',
              name: 'Metal Furring',
              tradeId: '09 22 13',
            },
            {
              id: '6681ad8a-995c-456d-aff3-7cc74f5f1e9a',
              name: 'Metal Channel Furring',
              tradeId: '09 22 13.13',
            },
            {
              id: '80bf6420-ba45-41e1-a6fc-d4ac556119f6',
              name: 'Resilient Channel Furring',
              tradeId: '09 22 13.23',
            },
            {
              id: '2eca5308-a7a7-4618-8b01-6d16178def13',
              name: 'Non-Structural Metal Framing',
              tradeId: '09 22 16',
            },
            {
              id: 'b0ab9690-1902-45e7-9260-ccbf63dbb0d5',
              name: 'Non-Structural Metal Stud Framing',
              tradeId: '09 22 16.13',
            },
            {
              id: '88851fb5-1978-47f1-9fbc-c2953da990ba',
              name: 'Suspension Systems',
              tradeId: '09 22 26',
            },
            {
              id: '06885b16-09d1-4766-8e97-d2a60da1c9e2',
              name: 'Metal Suspension Systems',
              tradeId: '09 22 26.23',
            },
            {
              id: '01ccc0e9-1363-4e01-8a60-74a81971b27d',
              name: 'Plastic Suspension Systems',
              tradeId: '09 22 26.33',
            },
            {
              id: '2cacdc21-3618-491f-9e8e-4b67a373b87e',
              name: 'Lath',
              tradeId: '09 22 36',
            },
            {
              id: '7e6b8656-eaaa-464a-b121-c074fb57f7fc',
              name: 'Gypsum Lath',
              tradeId: '09 22 36.13',
            },
            {
              id: '6cdac380-0396-4740-a1f9-a01fa0638bba',
              name: 'Metal Lath',
              tradeId: '09 22 36.23',
            },
            {
              id: 'e55a4cc0-d635-4dad-9d77-f1a46b52d1e9',
              name: 'Veneer Plaster Base',
              tradeId: '09 22 39',
            },
          ],
          tradeId: '09 22 00',
        },
        {
          id: 'b2cd57b2-7d64-44fb-a2ae-ade3e53ce501',
          name: 'Gypsum Plastering',
          children: [
            {
              id: '459c2e0b-0bd3-47f6-9f12-02d16a22a5ab',
              name: 'Gypsum Plastering',
              tradeId: '09 23 00',
            },
            {
              id: 'd5dced6a-101f-461f-954a-3d9e28666050',
              name: 'Acoustical Gypsum Plastering',
              tradeId: '09 23 13',
            },
            {
              id: '191f02d3-a197-4736-934c-5618741b467f',
              name: 'Fireproof Gypsum Plastering',
              tradeId: '09 23 82',
            },
          ],
          tradeId: '09 23 00',
        },
        {
          id: '4696376a-eaef-4e63-ae78-9ed11fc87601',
          name: 'Cement Plastering',
          children: [
            {
              id: 'acae094e-e23f-4f34-a92b-6e3cc9b1f5c8',
              name: 'Cement Plastering',
              tradeId: '09 24 00',
            },
            {
              id: '1143be42-2dd7-4460-ac49-ed60bb639f62',
              name: 'Adobe Finish',
              tradeId: '09 24 13',
            },
            {
              id: 'bbe9465c-79d0-421d-808b-b9ec44d2779b',
              name: 'Cement Stucco',
              tradeId: '09 24 23',
            },
            {
              id: '3004b4a2-48d9-4c74-8721-8319f23bf30d',
              name: 'Cement Parging',
              tradeId: '09 24 33',
            },
          ],
          tradeId: '09 24 00',
        },
        {
          id: 'f0b60cf1-b67c-4e89-805f-c135b1628020',
          name: 'Other Plastering',
          children: [
            {
              id: '31caa077-7cda-444c-9533-5dfae3512c70',
              name: 'Other Plastering',
              tradeId: '09 25 00',
            },
            {
              id: 'f08c6a73-ee28-455f-9a14-248ba5df5d72',
              name: 'Acrylic Plastering',
              tradeId: '09 25 13',
            },
            {
              id: '10638e92-f51c-4e6b-b994-6c639e6c21e6',
              name: 'Acrylic Plaster Finish',
              tradeId: '09 25 13.13',
            },
            {
              id: '5c6e0860-3ed1-4769-a8a8-62d9e5c6f4e1',
              name: 'Lime Based Plastering',
              tradeId: '09 25 23',
            },
            {
              id: 'c99d411e-ea32-4bea-9045-2a0fbbcab905',
              name: 'Natural Clay Plastering',
              tradeId: '09 25 26',
            },
          ],
          tradeId: '09 25 00',
        },
        {
          id: 'a54378b3-00e2-4369-8e38-113dbd3c002b',
          name: 'Veneer Plastering',
          children: [
            {
              id: '4ec2a45d-d9f0-4bf3-895d-985394a9be73',
              name: 'Veneer Plastering',
              tradeId: '09 26 00',
            },
            {
              id: 'eae94c25-a4d1-4349-aeb1-f2a13278891b',
              name: 'Gypsum Veneer Plastering',
              tradeId: '09 26 13',
            },
          ],
          tradeId: '09 26 00',
        },
        {
          id: '7b016967-0b49-49f0-bcd7-3fbcd4fdb60f',
          name: 'Plaster Fabrications',
          children: [
            {
              id: '66c12782-a690-40f4-8b81-842acc165cbe',
              name: 'Plaster Fabrications',
              tradeId: '09 27 00',
            },
            {
              id: 'c4c8e94c-ca61-4206-814c-d124bee52143',
              name: 'Glass-Fiber-Reinforced Plaster Fabrications',
              tradeId: '09 27 13',
            },
            {
              id: 'd87dd33a-c049-4a1e-b02f-f65ca24eebcf',
              name: 'Simulated Plaster Fabrications',
              tradeId: '09 27 23',
            },
          ],
          tradeId: '09 27 00',
        },
        {
          id: 'c02df7fb-2577-4c73-85d1-d880694d3598',
          name: 'Backing Boards and Underlayments',
          children: [
            {
              id: 'b69c5244-28de-4aba-a3df-45bbd4123b0e',
              name: 'Backing Boards and Underlayments',
              tradeId: '09 28 00',
            },
            {
              id: 'a0ac8a3e-2d74-42e2-95d4-84092cfcabf9',
              name: 'Cementitious Backing Boards',
              tradeId: '09 28 13',
            },
            {
              id: '76b73dc2-93f4-42b1-b4d6-05305320a24d',
              name: 'Glass-Mat Faced Gypsum Backing Boards',
              tradeId: '09 28 16',
            },
            {
              id: 'c9c390da-717b-4a18-b020-e14217672803',
              name: 'Fibered Gypsum Backing Boards',
              tradeId: '09 28 19',
            },
          ],
          tradeId: '09 28 00',
        },
        {
          id: '724e0a8e-530a-455a-b5ff-87dcb89271bc',
          name: 'Gypsum Board',
          children: [
            {
              id: '420058dd-07f6-4c97-963b-88bfae3561fa',
              name: 'Gypsum Board',
              tradeId: '09 29 00',
            },
            {
              id: '06a6f82a-005c-4154-85c6-81fd6d718bdf',
              name: 'Gypsum Board Fireproofing',
              tradeId: '09 29 82',
            },
          ],
          tradeId: '09 29 00',
        },
        {
          id: '6244c1f3-8514-47f7-82b6-3be944960fa7',
          name: 'Tiling',
          children: [
            {
              id: '3b06031d-8af8-41ca-a098-70b0cac2bccb',
              name: 'Tiling',
              tradeId: '09 30 00',
            },
            {
              id: '888a8ea8-0d9b-4079-a387-c978aea9d1ea',
              name: 'Ceramic Tiling',
              tradeId: '09 30 13',
            },
            {
              id: 'bc1a34a0-0fba-4656-a685-26ca5d424d5e',
              name: 'Quarry Tiling',
              tradeId: '09 30 16',
            },
            {
              id: 'dbb538f3-7fb8-4979-9ac1-6daddd7eac63',
              name: 'Paver Tiling',
              tradeId: '09 30 19',
            },
            {
              id: '065fbe9a-79ed-45e8-b095-45dc0da4c0d4',
              name: 'Glass Mosaic Tiling',
              tradeId: '09 30 23',
            },
            {
              id: '930c3cc4-a57f-4116-a336-7a499a7a815a',
              name: 'Plastic Tiling',
              tradeId: '09 30 26',
            },
            {
              id: 'f3453317-d96d-49c5-bee6-1a5ca2e72256',
              name: 'Metal Tiling',
              tradeId: '09 30 29',
            },
            {
              id: '5e5c739e-b435-4887-9288-8340815a25e6',
              name: 'Stone Tiling',
              tradeId: '09 30 33',
            },
            {
              id: '80bab6d1-5d22-4c46-986e-7052e378a9b6',
              name: 'Concrete Tiling',
              tradeId: '09 30 36',
            },
            {
              id: '1cdd81e0-86f3-4008-ad7a-d723d77d240b',
              name: 'Brick Tiling',
              tradeId: '09 30 39',
            },
          ],
          tradeId: '09 30 00',
        },
        {
          id: '8a4c3927-61ff-4b31-9609-6bc5f2889d01',
          name: 'Thin-Set Tiling',
          children: [
            {
              id: '98485907-5599-49c4-80c6-fcbdea00fd97',
              name: 'Thin-Set Tiling',
              tradeId: '09 31 00',
            },
            {
              id: 'c495aaf5-8a6b-4377-8bc3-54664de45d3c',
              name: 'Thin-Set Ceramic Tiling',
              tradeId: '09 31 13',
            },
            {
              id: '2feb940a-5f39-4557-a498-78fd80ee8af8',
              name: 'Thin-Set Quarry Tiling',
              tradeId: '09 31 16',
            },
            {
              id: '178c166f-1fcb-4f96-b23a-3a0f3e602ee3',
              name: 'Thin-Set Paver Tiling',
              tradeId: '09 31 19',
            },
            {
              id: '8e77c5e1-f1be-4d52-a149-d4dd80ad38d8',
              name: 'Thin-Set Glass Mosaic Tiling',
              tradeId: '09 31 23',
            },
            {
              id: '68a40960-7e46-418c-9b83-d4418f1db60f',
              name: 'Thin-Set Plastic Tiling',
              tradeId: '09 31 26',
            },
            {
              id: '62b818ec-77c8-4f03-9aa1-71b7ec729419',
              name: 'Thin-Set Metal Tiling',
              tradeId: '09 31 29',
            },
            {
              id: 'dcf33f26-451b-41c1-b1d8-9656211fdbc8',
              name: 'Thin-Set Stone Tiling',
              tradeId: '09 31 33',
            },
            {
              id: '6431d7e3-de80-41f3-afd9-9cbfe04a1310',
              name: 'Thin-Set Concrete Tiling',
              tradeId: '09 31 36',
            },
          ],
          tradeId: '09 31 00',
        },
        {
          id: '88bf7b9e-11d2-4bcc-b951-0103f9cc6291',
          name: 'Mortar-Bed Tiling',
          children: [
            {
              id: 'bb01d8ab-d79a-4d7a-9ca8-d03728a6dfd0',
              name: 'Mortar-Bed Tiling',
              tradeId: '09 32 00',
            },
            {
              id: 'b5eb709e-e1a7-4b90-805d-437ff8277c8c',
              name: 'Mortar-Bed Ceramic Tiling',
              tradeId: '09 32 13',
            },
            {
              id: '41580280-3809-481d-86c2-c1d5412f7e67',
              name: 'Mortar-Bed Quarry Tiling',
              tradeId: '09 32 16',
            },
            {
              id: 'f24f4fe5-725a-4c28-a57a-7b55f63cce3e',
              name: 'Mortar-Bed Paver Tiling',
              tradeId: '09 32 19',
            },
            {
              id: 'cc20b2f5-fad7-42d4-bc54-6a882275df0c',
              name: 'Mortar-Bed Glass Mosaic Tiling',
              tradeId: '09 32 23',
            },
            {
              id: 'ed6194d8-ea30-4004-9ba9-153d5ea8fac1',
              name: 'Mortar-Bed Plastic Tiling',
              tradeId: '09 32 26',
            },
            {
              id: '8f973c77-66ba-4f79-8414-ce0ca6bfae50',
              name: 'Mortar-Bed Metal Tiling',
              tradeId: '09 32 29',
            },
            {
              id: '4ebd4bfb-1421-421a-b4e0-bdee5f801c6a',
              name: 'Mortar-Bed Stone Tiling',
              tradeId: '09 32 33',
            },
            {
              id: '71946804-553d-4ccd-8ef8-6e2ab8438b70',
              name: 'Mortar-Bed Concrete Tiling',
              tradeId: '09 32 36',
            },
          ],
          tradeId: '09 32 00',
        },
        {
          id: '3f075c60-b565-4513-8f7e-85102209260d',
          name: 'Conductive Tiling',
          children: [
            {
              id: '356457e3-5db3-4020-96d9-84e8f3f00de5',
              name: 'Conductive Tiling',
              tradeId: '09 33 00',
            },
            {
              id: '3f425f00-bd44-4f52-b919-a87fe5253452',
              name: 'Conductive Ceramic Tiling',
              tradeId: '09 33 13',
            },
            {
              id: 'c17ded44-81d0-48fe-934f-a84aa8977aa6',
              name: 'Conductive Quarry Tiling',
              tradeId: '09 33 16',
            },
            {
              id: 'e64a7773-750b-4ef1-b472-4fd582a96fb1',
              name: 'Conductive Paver Tiling',
              tradeId: '09 33 19',
            },
            {
              id: '2e2dbbf5-a4db-483a-b350-5eb8e17e5be6',
              name: 'Conductive Glass Mosaic Tiling',
              tradeId: '09 33 23',
            },
            {
              id: '60eddc1e-a697-4bf8-94e0-c16f33e45b70',
              name: 'Conductive Plastic Tiling',
              tradeId: '09 33 26',
            },
            {
              id: 'dffed6f6-4a9d-4c2c-9615-2e09c40febc5',
              name: 'Conductive Metal Tiling',
              tradeId: '09 33 29',
            },
            {
              id: 'a43af8de-63d8-4f91-ac17-c7c57a87800d',
              name: 'Conductive Stone Tiling',
              tradeId: '09 33 33',
            },
            {
              id: '9fec6adf-b9fb-41a4-844f-400dcd9e6217',
              name: 'Conductive Concrete Tiling',
              tradeId: '09 33 36',
            },
          ],
          tradeId: '09 33 00',
        },
        {
          id: '70364581-682e-4290-86eb-a824b62b7caa',
          name: 'Waterproofing-Membrane Tiling',
          children: [
            {
              id: 'dc145fcd-965c-49fd-be92-d357ca15e19f',
              name: 'Waterproofing-Membrane Tiling',
              tradeId: '09 34 00',
            },
            {
              id: '3b0964bf-0aa9-4c65-b85f-162fbd86d555',
              name: 'Waterproofing-Membrane Ceramic Tiling',
              tradeId: '09 34 13',
            },
            {
              id: 'ea15feb7-2e15-4c79-93ee-f72e1ebbc77a',
              name: 'Waterproofing-Membrane Quarry Tiling',
              tradeId: '09 34 16',
            },
            {
              id: 'e307b9fe-32d2-4f0b-aaf2-3159199dce08',
              name: 'Waterproofing-Membrane Paver Tiling',
              tradeId: '09 34 19',
            },
            {
              id: '75836e1a-05fa-4fb1-a730-945313224e31',
              name: 'Waterproofing-Membrane Glass Mosaic Tiling',
              tradeId: '09 34 23',
            },
            {
              id: '36817a32-6fd2-461f-beea-c2994eef75dc',
              name: 'Waterproofing-Membrane Plastic Tiling',
              tradeId: '09 34 26',
            },
            {
              id: '78955696-82e7-4f87-baef-a2ab2d3b6994',
              name: 'Waterproofing-Membrane Metal Tiling',
              tradeId: '09 34 29',
            },
            {
              id: 'd0461b7a-fcbe-4ca3-bf39-8050ef528cb7',
              name: 'Waterproofing-Membrane Stone Tiling',
              tradeId: '09 34 33',
            },
            {
              id: '25623f75-b887-4830-9991-44ddb23651db',
              name: 'Waterproofing-Membrane Concrete Tiling',
              tradeId: '09 34 36',
            },
          ],
          tradeId: '09 34 00',
        },
        {
          id: 'e0085817-f908-4251-b3b3-fe691514c95b',
          name: 'Chemical-Resistant Tiling',
          children: [
            {
              id: '2a6044c5-4bf0-4603-bdf5-bb27fa8d9f6e',
              name: 'Chemical-Resistant Tiling',
              tradeId: '09 35 00',
            },
            {
              id: '7a2706cc-ceb8-44ee-9ab4-ed0d800af998',
              name: 'Chemical-Resistant Ceramic Tiling',
              tradeId: '09 35 13',
            },
            {
              id: 'b42c6d7f-b8b0-4184-8b1d-83cbb7b03e82',
              name: 'Chemical-Resistant Quarry Tiling',
              tradeId: '09 35 16',
            },
            {
              id: '8c2959a6-a9d9-42a2-8627-9f889d5083a5',
              name: 'Chemical-Resistant Paver Tiling',
              tradeId: '09 35 19',
            },
            {
              id: 'd049eb63-14b1-4db2-9917-de5092ab1737',
              name: 'Chemical-Resistant Glass Mosaic Tiling',
              tradeId: '09 35 23',
            },
            {
              id: '57b467fb-1d62-4637-915b-191dfa1bd538',
              name: 'Chemical-Resistant Plastic Tiling',
              tradeId: '09 35 26',
            },
            {
              id: 'e2420b88-2eab-4210-961a-acd069aef66e',
              name: 'Chemical-Resistant Metal Tiling',
              tradeId: '09 35 29',
            },
            {
              id: 'ae730bd4-3452-4f8c-9830-9f8101b88050',
              name: 'Chemical-Resistant Stone Tiling',
              tradeId: '09 35 33',
            },
            {
              id: 'c7d8928b-6230-4f0e-bf11-13c87a3df836',
              name: 'Chemical-Resistant Concrete Tiling',
              tradeId: '09 35 36',
            },
          ],
          tradeId: '09 35 00',
        },
        {
          id: 'eef87bd9-4c3d-49c2-9f27-736cf4b638b5',
          name: 'Ceilings',
          children: [],
          tradeId: '09 50 00',
        },
        {
          id: '98eef51d-2ac5-49dc-8392-2749b7dff99e',
          name: 'Acoustical Ceilings',
          children: [
            {
              id: '44cb61b1-46c6-4d89-8794-a78415e6cce4',
              name: 'Acoustical Ceilings',
              tradeId: '09 51 00',
            },
            {
              id: '9259bd97-c389-4438-bb78-b7b68adde66e',
              name: 'Acoustical Panel Ceilings',
              tradeId: '09 51 13',
            },
            {
              id: '7e576c11-daf9-4732-97d3-3714fe5c9393',
              name: 'Acoustical Fabric-Faced Panel Ceilings',
              tradeId: '09 51 14',
            },
            {
              id: '69b8f5b2-962e-4ec2-9db2-60e4bf356bb2',
              name: 'Acoustical Tile Ceilings',
              tradeId: '09 51 23',
            },
            {
              id: '16ae1aad-79f0-49c4-856b-acd45c4ecfe3',
              name: 'Acoustical Wood Ceilings',
              tradeId: '09 51 26',
            },
            {
              id: '55ef8c9e-376a-4349-9447-11847f3c01fc',
              name: 'Acoustical Metal Pan Ceilings',
              tradeId: '09 51 33',
            },
            {
              id: '09ea3f42-d3f8-4b45-981d-37b6df3515bf',
              name: 'Acoustical Snap-in Metal Pan Ceilings',
              tradeId: '09 51 33.13',
            },
            {
              id: '50cebede-1a75-402c-8e0e-e675cc855e60',
              name: 'Direct-Applied Acoustical Ceilings',
              tradeId: '09 51 53',
            },
          ],
          tradeId: '09 51 00',
        },
        {
          id: '8ff4162b-d682-4278-b7f9-7936d0f8825a',
          name: 'Acoustical Ceiling Suspension Assemblies',
          children: [
            {
              id: 'cf54ac3e-0895-4798-a047-f7cf6a44766a',
              name: 'Acoustical Ceiling Suspension Assemblies',
              tradeId: '09 53 00',
            },
            {
              id: '2f89b154-f4bc-438b-a644-7545d7926ec7',
              name: 'Curved Profile Ceiling Suspension Assemblies',
              tradeId: '09 53 13',
            },
            {
              id: 'dbc209d8-8055-4cff-8d75-7ccef29f7739',
              name: 'Metal Acoustical Ceiling Suspension Assemblies',
              tradeId: '09 53 23',
            },
            {
              id: '189bc514-101e-4fa9-9814-f808ea71ad95',
              name: 'Plastic Acoustical Ceiling Suspension Assemblies',
              tradeId: '09 53 33',
            },
          ],
          tradeId: '09 53 00',
        },
        {
          id: '12112888-f8f7-467e-adb8-54697ef0c849',
          name: 'Specialty Ceilings',
          children: [
            {
              id: '06c00a2f-c395-4a28-96e1-970697865d66',
              name: 'Specialty Ceilings',
              tradeId: '09 54 00',
            },
            {
              id: '40145caf-be22-4af7-8342-7c03fd70d183',
              name: 'Open Metal Mesh Ceilings',
              tradeId: '09 54 13',
            },
            {
              id: 'e608c382-1142-42bc-b734-37ba18cbde45',
              name: 'Luminous Ceilings',
              tradeId: '09 54 16',
            },
            {
              id: 'f98786d0-211e-454b-988d-784c0778b5a4',
              name: 'Mirror Panel Ceilings',
              tradeId: '09 54 19',
            },
            {
              id: 'd00f8903-795a-4391-9725-75fcf232602c',
              name: 'Linear Metal Ceilings',
              tradeId: '09 54 23',
            },
            {
              id: '69d31744-22d7-4599-890c-05831e64c1af',
              name: 'Suspended Wood Ceilings',
              tradeId: '09 54 26',
            },
            {
              id: '0271efad-89ee-44ed-800f-e1d58c81ab2a',
              name: 'Decorative Panel Ceilings',
              tradeId: '09 54 33',
            },
            {
              id: '8ea5659d-1f7c-4571-bc65-596c46c64d43',
              name: 'Suspended Decorative Grids',
              tradeId: '09 54 36',
            },
            {
              id: '47f95c5c-fa5d-4394-87ef-c6d41817aab4',
              name: 'Stretched-Fabric Ceiling Systems',
              tradeId: '09 54 43',
            },
            {
              id: '8130971f-e075-4e80-ae8d-3458787b1571',
              name: 'Fabric-Wrapped Ceiling Panels',
              tradeId: '09 54 46',
            },
            {
              id: 'f35b931d-2c1a-49cd-9bc3-43d561b32c11',
              name: 'Fiberglass Reinforced Panel Ceilings',
              tradeId: '09 54 53',
            },
          ],
          tradeId: '09 54 00',
        },
        {
          id: 'a064a847-d34b-406b-bb24-50b4e975ec74',
          name: 'Textured Ceilings',
          children: [
            {
              id: '4f2c1900-5b1e-4548-9c9a-be39e6855762',
              name: 'Textured Ceilings',
              tradeId: '09 56 00',
            },
            {
              id: 'fcb6c0f3-ef3b-444c-b1f1-1f603adbb1b9',
              name: 'Gypsum-Panel Textured Ceilings',
              tradeId: '09 56 13',
            },
            {
              id: '96e1acff-f0b4-4ab4-942c-e54346e7eb52',
              name: 'Metal-Panel Textured Ceilings',
              tradeId: '09 56 16',
            },
          ],
          tradeId: '09 56 00',
        },
        {
          id: 'd33b9d3c-e5ab-4333-bae1-dd9bc44673d0',
          name: 'Special Function Ceilings',
          children: [
            {
              id: 'a6c273f2-a634-4dba-8cd9-abe1ccf79d5d',
              name: 'Special Function Ceilings',
              tradeId: '09 57 00',
            },
            {
              id: '60400857-24fa-48e9-80d3-cfb87ffe5a69',
              name: 'Security Ceiling Assemblies',
              tradeId: '09 57 53',
            },
          ],
          tradeId: '09 57 00',
        },
        {
          id: 'ad52a3f7-36ac-48b1-924f-3708b09d1d24',
          name: 'Integrated Ceiling Assemblies',
          children: [],
          tradeId: '09 58 00',
        },
        {
          id: '45bb0bbc-3066-4644-8be3-b33255a9fb9b',
          name: 'Flooring',
          children: [
            {
              id: 'c340afc1-bd6f-439d-a316-1323b6f0f927',
              name: 'Flooring',
              tradeId: '09 60 00',
            },
            {
              id: '9ea7f284-2d49-4260-8db7-898f2aba0612',
              name: 'Acoustic Underlayment',
              tradeId: '09 60 13',
            },
          ],
          tradeId: '09 60 00',
        },
        {
          id: 'b63c4b85-84dd-478c-9faa-44ed4af44166',
          name: 'Flooring Treatment',
          children: [
            {
              id: 'f1d530c0-e28e-452e-8ac8-7fa475e94e85',
              name: 'Flooring Treatment',
              tradeId: '09 61 00',
            },
            {
              id: 'f8eab42a-c6df-4bae-9510-cf3f0c01e13f',
              name: 'Slip-Resistant Flooring Treatment',
              tradeId: '09 61 13',
            },
            {
              id: '31ab6f3b-35e5-46b8-be4e-67ff5a4f2153',
              name: 'Concrete Staining',
              tradeId: '09 61 19',
            },
            {
              id: 'faeb562a-9f0d-4f69-9d0e-e46661bd17f7',
              name: 'Static-Resistant Flooring Treatment',
              tradeId: '09 61 36',
            },
          ],
          tradeId: '09 61 00',
        },
        {
          id: '6c7bb729-7a8f-423b-b08d-4c3c0d1e85a4',
          name: 'Specialty Flooring',
          children: [
            {
              id: '25963259-bdea-46ef-971e-484a9349ea8a',
              name: 'Specialty Flooring',
              tradeId: '09 62 00',
            },
            {
              id: '5ee1b783-4283-43ab-95f7-fb8fabadce33',
              name: 'Asphaltic Plank Flooring',
              tradeId: '09 62 13',
            },
            {
              id: 'df4c1a47-d2fb-4ae0-af70-c3106954f2bc',
              name: 'Laminate Flooring',
              tradeId: '09 62 19',
            },
            {
              id: '62b98529-2e1b-47d6-a1cc-e9581458c364',
              name: 'Bamboo Flooring',
              tradeId: '09 62 23',
            },
            {
              id: '09c134ca-6dc9-4176-b0db-e12392e78820',
              name: 'Leather Flooring',
              tradeId: '09 62 26',
            },
            {
              id: '82fb741d-e13c-4e40-ae64-640c6d2d1636',
              name: 'Cork Flooring',
              tradeId: '09 62 29',
            },
            {
              id: '81bafa08-7b90-4b50-988c-bf29b36471e4',
              name: 'Acid-Resistant Flooring',
              tradeId: '09 62 35',
            },
            {
              id: 'e669bb8f-1c34-40e8-8f3b-4e1ec87e84d5',
              name: 'Acoustic Flooring',
              tradeId: '09 62 48',
            },
            {
              id: '118e7294-0696-4589-b1b3-287685c66007',
              name: 'Synthetic Turf Flooring',
              tradeId: '09 62 53',
            },
            {
              id: 'b2cdc94d-f170-4916-8bf3-5b61d64e78e5',
              name: 'Metal Flooring',
              tradeId: '09 62 63',
            },
            {
              id: '92cbafc7-ed12-4578-bb98-9248819d7269',
              name: 'Aluminum Flooring',
              tradeId: '09 62 63.13',
            },
            {
              id: '304261f1-93f2-47b1-af5e-3b601f1838c2',
              name: 'Stainless Steel Flooring',
              tradeId: '09 62 63.16',
            },
            {
              id: '61db6ec5-2cab-4f6a-9df0-e6d16f7b8313',
              name: 'Structural Glass Flooring',
              tradeId: '09 62 83',
            },
          ],
          tradeId: '09 62 00',
        },
        {
          id: 'beafd31c-bb37-4058-8133-69157d32b5a7',
          name: 'Masonry Flooring',
          children: [
            {
              id: 'e9574020-7fa6-4d0e-b92b-fc043facee61',
              name: 'Masonry Flooring',
              tradeId: '09 63 00',
            },
            {
              id: '11541227-6fd5-42ad-8b8e-7cda10667a72',
              name: 'Brick Flooring',
              tradeId: '09 63 13',
            },
            {
              id: 'dd89ac96-53e8-4aff-afa3-24cf1489a16f',
              name: 'Chemical-Resistant Brick Flooring',
              tradeId: '09 63 13.35',
            },
            {
              id: 'fd93508d-6455-4e65-b2ed-ffa0e352dfd0',
              name: 'Stone Flooring',
              tradeId: '09 63 40',
            },
            {
              id: '82a07a1b-0118-46cc-883d-902233ed0c87',
              name: 'Composition Stone Flooring',
              tradeId: '09 63 43',
            },
          ],
          tradeId: '09 63 00',
        },
        {
          id: 'dae2a6a3-6174-4b62-bca3-20925fe3d181',
          name: 'Wood Flooring',
          children: [
            {
              id: '4f24bcd8-2894-406d-8e06-6ed12f1117e6',
              name: 'Wood Flooring',
              tradeId: '09 64 00',
            },
            {
              id: '553a7b54-4bbc-428f-891b-9b24e245d171',
              name: 'Wood Block Flooring',
              tradeId: '09 64 16',
            },
            {
              id: 'b94f59c7-febc-40b6-b670-5c05a122ecda',
              name: 'Wood Composition Flooring',
              tradeId: '09 64 19',
            },
            {
              id: '09ede59a-c091-45dd-a821-a5668374e070',
              name: 'Wood Parquet Flooring',
              tradeId: '09 64 23',
            },
            {
              id: '65ce126f-8850-4e7f-b630-d1f2c570c7d8',
              name: 'Acrylic-Impregnated Wood Parquet Flooring',
              tradeId: '09 64 23.13',
            },
            {
              id: '6092b6c7-07c5-419a-8507-1d8e348f9661',
              name: 'Wood Strip and Plank Flooring',
              tradeId: '09 64 29',
            },
            {
              id: 'f87a9be8-f3d1-48e5-b3bc-a6a53ca760c9',
              name: 'Laminated Wood Flooring',
              tradeId: '09 64 33',
            },
            {
              id: 'a39db5f3-f716-4eed-8c40-bc83ac9fafaa',
              name: 'Resilient Wood Flooring Assemblies',
              tradeId: '09 64 53',
            },
            {
              id: '1efe796c-1f56-47cc-9abe-379d9216d3c9',
              name: 'Wood Athletic Flooring',
              tradeId: '09 64 66',
            },
          ],
          tradeId: '09 64 00',
        },
        {
          id: 'e9824d3f-2332-4d2c-9f05-749693869f80',
          name: 'Resilient Flooring',
          children: [
            {
              id: 'c63b13a2-7833-40d7-9156-d45780b4ac53',
              name: 'Resilient Flooring',
              tradeId: '09 65 00',
            },
            {
              id: 'd080fba0-9848-4986-92c5-ab4d6d7dda93',
              name: 'Resilient Base and Accessories',
              tradeId: '09 65 13',
            },
            {
              id: 'd2d570f1-133c-4ae0-a77e-d3ce0f847004',
              name: 'Resilient Base',
              tradeId: '09 65 13.13',
            },
            {
              id: '2dc4288d-cce6-4525-a9ac-3dbcbb2ed278',
              name: 'Resilient Stair Treads and Risers',
              tradeId: '09 65 13.23',
            },
            {
              id: 'a9d5c406-be17-4d55-b2cd-756dcdbed2de',
              name: 'Resilient Stair Nosings',
              tradeId: '09 65 13.26',
            },
            {
              id: '75036fee-0551-4cd8-a855-d461601d1e83',
              name: 'Resilient Accessories',
              tradeId: '09 65 13.33',
            },
            {
              id: 'f342699b-e9ba-4fd2-8800-ebb61b8b1fc4',
              name: 'Resilient Carpet Transitions',
              tradeId: '09 65 13.36',
            },
            {
              id: '3b339aba-56bc-4cd5-8d0e-58385990b2ab',
              name: 'Resilient Sheet Flooring',
              tradeId: '09 65 16',
            },
            {
              id: '5c7518e0-c421-402a-be8c-1748904e9e97',
              name: 'Vinyl Sheet Flooring',
              tradeId: '09 65 16.23',
            },
            {
              id: 'fafc9efc-5903-4451-98cc-5c3e1b7ebc8b',
              name: 'Rubber Sheet Flooring',
              tradeId: '09 65 16.33',
            },
            {
              id: 'a86aad28-ccc1-40ce-a467-35255b8201f8',
              name: 'PVC-Free Sheet Flooring',
              tradeId: '09 65 16.43',
            },
            {
              id: '3cd3d301-0e7f-4bea-a590-fb84166932e9',
              name: 'Resilient Tile Flooring',
              tradeId: '09 65 19',
            },
            {
              id: '5f5bc301-a732-4c41-ba1b-dd557c0f0e0d',
              name: 'Conductive Resilient Flooring',
              tradeId: '09 65 33',
            },
            {
              id: '4f0ce8e1-2369-4ab5-8ba3-fcd0612b2ef7',
              name: 'Static-Control Resilient Flooring',
              tradeId: '09 65 36',
            },
            {
              id: 'b71ca3dc-3d38-4638-8aca-cbe51c8874a5',
              name: 'Static-Dissipative Resilient Flooring',
              tradeId: '09 65 36.13',
            },
            {
              id: '99ab125a-b731-4aa6-bfce-ee4db83fc88d',
              name: 'Static-Resistant Resilient Flooring',
              tradeId: '09 65 36.16',
            },
            {
              id: 'fa30584c-e039-4388-901d-26f15d54af7a',
              name: 'Linoleum Flooring',
              tradeId: '09 65 43',
            },
            {
              id: '97e7a5e6-363c-48dc-a9af-1e4e78f44763',
              name: 'Resilient Athletic Flooring',
              tradeId: '09 65 66',
            },
          ],
          tradeId: '09 65 00',
        },
        {
          id: 'f5b6e993-2c65-4a3d-ac95-4f5abf8c4dcb',
          name: 'Terrazzo Flooring',
          children: [
            {
              id: '3e3e2d0b-aaf2-4b8a-85d4-e2666ba34cff',
              name: 'Terrazzo Flooring',
              tradeId: '09 66 00',
            },
            {
              id: 'b9e2e050-93b5-471a-926f-5825b3a2870d',
              name: 'Portland Cement Terrazzo Flooring',
              tradeId: '09 66 13',
            },
            {
              id: '76bdf638-9e94-450f-9b5e-84790908fb12',
              name: 'Sand Cushion Terrazzo Flooring',
              tradeId: '09 66 13.13',
            },
            {
              id: 'ae04411c-be8c-4f18-8840-7e17f4596f41',
              name: 'Monolithic Terrazzo Flooring',
              tradeId: '09 66 13.16',
            },
            {
              id: '6794f477-53d9-4275-a1f5-5c431e197676',
              name: 'Bonded Terrazzo Flooring',
              tradeId: '09 66 13.19',
            },
            {
              id: 'dd1d5a56-3a94-4585-9a92-9e68e52dd551',
              name: 'Palladina Terrazzo Flooring',
              tradeId: '09 66 13.23',
            },
            {
              id: '126d8950-5dba-4aab-85fb-35e9f720adc2',
              name: 'Rustic Terrazzo Flooring',
              tradeId: '09 66 13.26',
            },
            {
              id: 'ab8acc63-e7f6-4cda-8bc7-8e7fdde765e1',
              name: 'Structural Terrazzo Flooring',
              tradeId: '09 66 13.33',
            },
            {
              id: '27d2d877-d95c-4029-9d03-ad1e4d9ddaf6',
              name: 'Terrazzo Floor Tile',
              tradeId: '09 66 16',
            },
            {
              id: 'c264a1cb-631d-4ffb-bdf2-fc33566e3c57',
              name: 'Portland Cement Terrazzo Floor Tile',
              tradeId: '09 66 16.13',
            },
            {
              id: '0fe4e36c-3e19-48d0-843f-ada1acf70b63',
              name: 'Plastic-Matrix Terrazzo Floor Tile',
              tradeId: '09 66 16.16',
            },
            {
              id: '823249dc-ade9-4023-94f9-82a258cd50a6',
              name: 'Resinous Matrix Terrazzo Flooring',
              tradeId: '09 66 23',
            },
            {
              id: '297705bd-ce48-4102-a94f-2f6acbccb100',
              name: 'Polyacrylate Modified Cementitious Terrazzo Flooring',
              tradeId: '09 66 23.13',
            },
            {
              id: 'a7702af5-c428-4a99-b5f6-cc80c341c4c1',
              name: 'Epoxy-Resin Terrazzo Flooring',
              tradeId: '09 66 23.16',
            },
            {
              id: 'df0c99fd-18d4-4bf8-975c-248b7b51f670',
              name: 'Polyester-Resin Terrazzo Flooring',
              tradeId: '09 66 23.19',
            },
            {
              id: 'ac53f1e2-f3d2-4838-a131-179069cd9927',
              name: 'Conductive Terrazzo Flooring',
              tradeId: '09 66 33',
            },
            {
              id: '90ec230d-b3f3-4de7-898f-c0d11b1b5a97',
              name: 'Conductive Epoxy-Resin Terrazzo',
              tradeId: '09 66 33.13',
            },
            {
              id: '1c74de08-9e48-41d4-aaf0-8cada1b6424f',
              name: 'Conductive Polyester-Resin Terrazzo Flooring',
              tradeId: '09 66 33.16',
            },
            {
              id: '45a474a6-fc55-470c-a599-589d297b7898',
              name: 'Conductive Plastic-Matrix Terrazzo Flooring',
              tradeId: '09 66 33.19',
            },
          ],
          tradeId: '09 66 00',
        },
        {
          id: '6fd59c8b-b792-417b-9306-474c7b951d41',
          name: 'Fluid-Applied Flooring',
          children: [
            {
              id: '2078694a-159f-4ff6-b55b-b3e3d4782001',
              name: 'Fluid-Applied Flooring',
              tradeId: '09 67 00',
            },
            {
              id: '7cf44372-a891-4546-97f7-0c108658cb28',
              name: 'Elastomeric Liquid Flooring',
              tradeId: '09 67 13',
            },
            {
              id: '22ac5fc4-73a4-42b7-9b58-6627c43ab9ce',
              name: 'Conductive Elastomeric Liquid Flooring',
              tradeId: '09 67 13.33',
            },
            {
              id: 'cd855afc-42cf-4c91-a093-c263372670df',
              name: 'Epoxy-Marble Chip Flooring',
              tradeId: '09 67 16',
            },
            {
              id: 'cf8e3286-ba42-4b4d-8c23-4edfaa3e2ac9',
              name: 'Magnesium-Oxychloride Flooring',
              tradeId: '09 67 19',
            },
            {
              id: 'bd0e0d5f-8e11-432f-9db7-e329246062a5',
              name: 'Resinous Flooring',
              tradeId: '09 67 23',
            },
            {
              id: '32665a83-5627-4066-a4a1-fdb82bd1a028',
              name: 'Quartz Flooring',
              tradeId: '09 67 26',
            },
            {
              id: 'f0760fcf-6a89-43d1-b6cf-71600c38acda',
              name: 'Fluid-Applied Athletic Flooring',
              tradeId: '09 67 66',
            },
          ],
          tradeId: '09 67 00',
        },
        {
          id: 'e0065c32-8bc5-4ba9-be78-698578f474c0',
          name: 'Carpeting',
          children: [
            {
              id: '2efda13c-7f8a-4395-8f5d-9bc2aacd753e',
              name: 'Carpeting',
              tradeId: '09 68 00',
            },
            {
              id: 'f57d8cb3-12fc-4096-850b-bfbd2e51c37f',
              name: 'Tile Carpeting',
              tradeId: '09 68 13',
            },
            {
              id: '0db71f59-f5b7-4384-a10f-50011f007009',
              name: 'Sheet Carpeting',
              tradeId: '09 68 16',
            },
          ],
          tradeId: '09 68 00',
        },
        {
          id: '14d6f507-6e77-4fdb-98c8-40fd138aff7d',
          name: 'Access Flooring',
          children: [
            {
              id: '7e89070a-e2f7-4c49-b4e3-308a918cb9f3',
              name: 'Access Flooring',
              tradeId: '09 69 00',
            },
            {
              id: '7b6f5492-8e7f-420c-a60c-0118c744d5e4',
              name: 'Rigid-Grid Access Flooring',
              tradeId: '09 69 13',
            },
            {
              id: '63d45fd0-75be-4b75-a118-bfdcb861691b',
              name: 'Snap-on Stringer Access Flooring',
              tradeId: '09 69 16',
            },
            {
              id: 'c48a1cc5-81d1-4a7a-b42d-f65f9450074d',
              name: 'Stringerless Access Flooring',
              tradeId: '09 69 19',
            },
            {
              id: 'e87db1b6-0fa4-4dba-ad9e-3959fb31c7e4',
              name: 'Low-Profile Fixed Height Access Flooring',
              tradeId: '09 69 33',
            },
            {
              id: '31e347e3-aef4-4fda-9243-9f2204ffcf5b',
              name: 'Access Flooring Accessories',
              tradeId: '09 69 53',
            },
            {
              id: '7062c509-dcf0-4697-a02e-6adeed5a7cfb',
              name: 'Access Flooring Stairs and Stringers',
              tradeId: '09 69 56',
            },
          ],
          tradeId: '09 69 00',
        },
        {
          id: '7a97cbf9-13db-40b5-86e9-ce09e2b6cb01',
          name: 'Wall Finishes',
          children: [],
          tradeId: '09 70 00',
        },
        {
          id: 'd1912aae-4bdf-4f78-b9b6-0ccd21ec82de',
          name: 'Wall Coverings',
          children: [
            {
              id: '2e6ea4a8-9612-4066-88f3-43bb24fbc34a',
              name: 'Wall Coverings',
              tradeId: '09 72 00',
            },
            {
              id: 'c85226f2-7e0b-4632-8d1c-a373e8756a07',
              name: 'Cork Wall Coverings',
              tradeId: '09 72 13',
            },
            {
              id: '1df64b06-df8c-45c9-a90b-0f50c53d2e36',
              name: 'Vinyl-Coated Fabric Wall Coverings',
              tradeId: '09 72 16',
            },
            {
              id: '478ba4a2-d6bb-4bb4-bb75-59150d6b6ee5',
              name: 'Flexible Vinyl Wall Coverings',
              tradeId: '09 72 16.13',
            },
            {
              id: '4d481736-3c0f-473f-ace3-543a596b8098',
              name: 'Rigid-Sheet Vinyl Wall Coverings',
              tradeId: '09 72 16.16',
            },
            {
              id: 'fdd38a00-cab7-4f3c-9a1f-8479b9e2e84b',
              name: 'Textile Wall Coverings',
              tradeId: '09 72 19',
            },
            {
              id: '25d1f96a-1ef0-48fb-bbee-87297bc8aa10',
              name: 'Wallpapering',
              tradeId: '09 72 23',
            },
          ],
          tradeId: '09 72 00',
        },
        {
          id: '1157e4d4-6bdc-49f7-8003-0de8ea740314',
          name: 'Wall Carpeting',
          children: [],
          tradeId: '09 73 00',
        },
        {
          id: 'e83d9a73-7095-4629-abec-179d60ac6f6b',
          name: 'Flexible Wood Sheets',
          children: [
            {
              id: '66cbb3d5-413d-4c18-95e6-3562520aa36a',
              name: 'Flexible Wood Sheets',
              tradeId: '09 74 00',
            },
            {
              id: '4fbbb201-c43d-4e31-b7d4-15c9f20328d7',
              name: 'Wood Wall Coverings',
              tradeId: '09 74 13',
            },
            {
              id: '4b411779-362a-4bb0-8f0e-5f0149ba4f5d',
              name: 'Flexible Wood Veneers',
              tradeId: '09 74 16',
            },
          ],
          tradeId: '09 74 00',
        },
        {
          id: '301887d4-c351-4b8e-9d74-0f7834c6c6a4',
          name: 'Stone Facing',
          children: [
            {
              id: '3fe55b4a-52b0-4a5f-a11f-c267b46bc409',
              name: 'Stone Facing',
              tradeId: '09 75 00',
            },
            {
              id: '5b0b57c7-9d7c-4a33-8bb5-d77d04db2200',
              name: 'Stone Wall Facing',
              tradeId: '09 75 13',
            },
            {
              id: '30a2be0e-7b1c-4ade-a4b8-debd02e4a220',
              name: 'Stone Trim',
              tradeId: '09 75 19',
            },
          ],
          tradeId: '09 75 00',
        },
        {
          id: '8178db5d-3ff5-42c3-b318-bf004727675f',
          name: 'Plastic Blocks',
          children: [],
          tradeId: '09 76 00',
        },
        {
          id: 'd3e272f3-aaa3-444e-8e58-01861d04430d',
          name: 'Special Wall Surfacing',
          children: [
            {
              id: '9d3e7c2a-b08c-4d6f-919a-16f1e2a91a9f',
              name: 'Special Wall Surfacing',
              tradeId: '09 77 00',
            },
            {
              id: '85648024-3062-4961-9be5-49ee3f36cdfe',
              name: 'Stretched-Fabric Wall Systems',
              tradeId: '09 77 13',
            },
            {
              id: '767e23e8-9378-4da7-8530-40cac0514705',
              name: 'Fabric-Wrapped Panels',
              tradeId: '09 77 23',
            },
            {
              id: 'e7d91cb8-9d9d-4fa6-860e-167b0b907396',
              name: 'Vegetated Wall Systems',
              tradeId: '09 77 53',
            },
          ],
          tradeId: '09 77 00',
        },
        {
          id: '1d67f7d3-8b15-47f5-ae4b-096803a5bfc6',
          name: 'Interior Wall Paneling',
          children: [
            {
              id: '9ad3d4c7-81ff-4696-93e4-3c60f90aa518',
              name: 'Interior Wall Paneling',
              tradeId: '09 78 00',
            },
            {
              id: '3a072841-8ab4-42ff-9050-092e3dca66b9',
              name: 'Metal Interior Wall Paneling',
              tradeId: '09 78 13',
            },
          ],
          tradeId: '09 78 00',
        },
        {
          id: 'e5a86250-3428-43b0-988f-b66d5b589df8',
          name: 'Acoustic Treatment',
          children: [],
          tradeId: '09 80 00',
        },
        {
          id: '32f52655-361a-4805-8eca-d46d003ead8d',
          name: 'Acoustic Insulation',
          children: [
            {
              id: '10c0cf96-f7bf-4972-93ce-3a1ad1c526e4',
              name: 'Acoustic Insulation',
              tradeId: '09 81 00',
            },
            {
              id: 'ffdd557e-caff-4bc3-94c9-a3d34de60e26',
              name: 'Acoustic Board Insulation',
              tradeId: '09 81 13',
            },
            {
              id: '0c196848-7982-4056-aaad-7c108d0c784e',
              name: 'Acoustic Blanket Insulation',
              tradeId: '09 81 16',
            },
            {
              id: '4ddd646e-4884-420f-b5fe-f7062ba86558',
              name: 'Sprayed Acoustic Insulation',
              tradeId: '09 81 29',
            },
          ],
          tradeId: '09 81 00',
        },
        {
          id: '2b93e6eb-036d-4641-b35d-3479231b011f',
          name: 'Acoustic Finishes',
          children: [
            {
              id: '0c651078-7b52-4791-9801-b06deb60993d',
              name: 'Acoustic Finishes',
              tradeId: '09 83 00',
            },
            {
              id: '9e17e69b-313c-4820-be73-0ed81994bf32',
              name: 'Acoustic Wall Coating',
              tradeId: '09 83 13',
            },
            {
              id: '711ba010-a312-4e39-bd4c-b4a0b7be6a2a',
              name: 'Acoustic Ceiling Coating',
              tradeId: '09 83 16',
            },
            {
              id: 'd157b6b5-4a37-4faa-90ef-6f7de0d32d9f',
              name: 'Acoustic Drapery',
              tradeId: '09 83 22',
            },
          ],
          tradeId: '09 83 00',
        },
        {
          id: 'c620337b-86a2-47b1-84c7-5362afbfb055',
          name: 'Acoustic Room Components',
          children: [
            {
              id: '010488c0-a211-486f-8b45-289b52a177ea',
              name: 'Acoustic Room Components',
              tradeId: '09 84 00',
            },
            {
              id: 'e85d4af2-6614-47bc-972b-caecee579852',
              name: 'Fixed Sound-Absorptive Panels',
              tradeId: '09 84 13',
            },
            {
              id: '120bcb68-12ce-44b7-864b-bf91095465de',
              name: 'Acoustic Stretched-Fabric Wall Systems',
              tradeId: '09 84 14',
            },
            {
              id: 'a4db0a9a-7fd7-4d1c-aba9-0027c32d8089',
              name: 'Fixed Sound-Reflective Panels',
              tradeId: '09 84 16',
            },
            {
              id: '097bc3e2-25b6-40ea-85b5-2f56041db8b8',
              name: 'Moveable Sound-Absorptive Panels',
              tradeId: '09 84 23',
            },
            {
              id: '3aaf5738-624b-4099-9920-a6a45f5a3b44',
              name: 'Moveable Sound-Reflective Panels',
              tradeId: '09 84 26',
            },
            {
              id: '36e1b6cd-241c-4556-8af4-13ce05ca808f',
              name: 'Sound-Absorbing Wall Units',
              tradeId: '09 84 33',
            },
            {
              id: '43955895-c20e-472c-889d-bff07c2b2cff',
              name: 'Sound-Absorbing Ceiling Units',
              tradeId: '09 84 36',
            },
          ],
          tradeId: '09 84 00',
        },
        {
          id: 'd01d2ccb-40a3-49bf-bffe-e9279fafcf79',
          name: 'Painting and Coating',
          children: [],
          tradeId: '09 90 00',
        },
        {
          id: 'b4d4dbe0-95f7-4302-aae9-6476bf24b151',
          name: 'Painting',
          children: [
            {
              id: 'c0afd2c7-e0f8-4e99-b631-208f7e358b84',
              name: 'Painting',
              tradeId: '09 91 00',
            },
            {
              id: '6c780f9c-9d1c-4f54-b44e-27a353259221',
              name: 'Exterior Painting',
              tradeId: '09 91 13',
            },
            {
              id: 'c853a35e-a6b0-4683-9c78-1bb079719a6c',
              name: 'Interior Painting',
              tradeId: '09 91 23',
            },
          ],
          tradeId: '09 91 00',
        },
        {
          id: '2facfe01-2529-4643-9b2f-063b3088928f',
          name: 'Staining and Transparent Finishing',
          children: [
            {
              id: 'dcfcf907-9f77-4eef-914f-9925e69e3ccf',
              name: 'Staining and Transparent Finishing',
              tradeId: '09 93 00',
            },
            {
              id: 'c1e8b2d3-ba7f-46d7-b20f-38f5dce56a5d',
              name: 'Exterior Staining and Finishing',
              tradeId: '09 93 13',
            },
            {
              id: '0ddee99c-6cf5-4e6e-8d6e-2f8ce6ee2e7d',
              name: 'Exterior Staining',
              tradeId: '09 93 13.13',
            },
            {
              id: '16e687d9-3b2d-419b-a3f4-c030de9a63e7',
              name: 'Exterior Finishing',
              tradeId: '09 93 13.53',
            },
            {
              id: 'c767ffd5-a406-45c5-a1a0-dcc463820611',
              name: 'Interior Staining and Finishing',
              tradeId: '09 93 23',
            },
            {
              id: 'e4a6d739-c97d-4337-af98-795851e1034e',
              name: 'Interior Staining',
              tradeId: '09 93 23.13',
            },
            {
              id: 'd6a2ddf0-bad9-4bf2-932e-e8a31e9b07b9',
              name: 'Interior Finishing',
              tradeId: '09 93 23.53',
            },
          ],
          tradeId: '09 93 00',
        },
        {
          id: '586bf35c-20bb-4138-8c0b-b061432c3b5b',
          name: 'Decorative Finishing',
          children: [
            {
              id: '83238fa1-612f-4d9e-a35e-6cfbd91a0f4b',
              name: 'Decorative Finishing',
              tradeId: '09 94 00',
            },
            {
              id: '0173007e-350f-4bd6-8d4c-5fca7ee3a6a8',
              name: 'Textured Finishing',
              tradeId: '09 94 13',
            },
            {
              id: 'ec7d3e9a-51ea-4fdd-b53c-5b95f0d9834e',
              name: 'Faux Finishing',
              tradeId: '09 94 16',
            },
            {
              id: 'efd10dc6-fba1-462a-b461-30a9cfce931e',
              name: 'Multicolor Interior Finishing',
              tradeId: '09 94 19',
            },
          ],
          tradeId: '09 94 00',
        },
        {
          id: '0d8afbb1-302d-4350-a602-fbc8eeb22b64',
          name: 'High-Performance Coatings',
          children: [
            {
              id: '66169559-d99b-4c08-adc6-ffd82ef1e861',
              name: 'High-Performance Coatings',
              tradeId: '09 96 00',
            },
            {
              id: '8860d350-1ddb-4ba9-9274-cd8688b1bd89',
              name: 'Abrasion-Resistant Coatings',
              tradeId: '09 96 13',
            },
            {
              id: 'fdaaaa01-6033-4f4c-ab9b-b3f47b2ee5eb',
              name: 'Graffiti-Resistant Coatings',
              tradeId: '09 96 23',
            },
            {
              id: '628c4015-7ae2-4426-b6ae-9b51cc64b15c',
              name: 'Marine Coatings',
              tradeId: '09 96 26',
            },
            {
              id: '1467e861-deb3-496e-8997-dd4d08d6c9ce',
              name: 'High-Temperature-Resistant Coatings',
              tradeId: '09 96 33',
            },
            {
              id: 'febe5424-0d68-4108-a6a0-ce2fca451264',
              name: 'Chemical-Resistant Coatings',
              tradeId: '09 96 35',
            },
            {
              id: '605c5942-20f8-4d15-8eae-512bede98338',
              name: 'Fire-Retardant Coatings',
              tradeId: '09 96 43',
            },
            {
              id: '1f4980bb-b125-4940-880d-3b77d672247b',
              name: 'Intumescent Painting',
              tradeId: '09 96 46',
            },
            {
              id: '599bf97f-40a9-464b-b350-ca925a9ecb61',
              name: 'Elastomeric Coatings',
              tradeId: '09 96 53',
            },
            {
              id: '1cfa7b6d-16db-43b3-ab31-9217a3ace53b',
              name: 'Epoxy Coatings',
              tradeId: '09 96 56',
            },
            {
              id: 'cf78ea1e-8cc4-403d-a460-ede2827066ff',
              name: 'High-Build Glazed Coatings',
              tradeId: '09 96 59',
            },
            {
              id: '60bfd877-c82f-4614-afdf-177ea8728d58',
              name: 'Textured Plastic Coatings',
              tradeId: '09 96 63',
            },
            {
              id: '043ab9da-2b59-4f18-a2b0-4cfdbf3de19d',
              name: 'Aggregate Wall Coatings',
              tradeId: '09 96 66',
            },
          ],
          tradeId: '09 96 00',
        },
        {
          id: '3d37f993-44be-4ac8-ae5a-29cfeb831198',
          name: 'Special Coatings',
          children: [
            {
              id: 'd267f9be-2194-4a96-ba2e-38ee3b7b3ab7',
              name: 'Special Coatings',
              tradeId: '09 97 00',
            },
            {
              id: 'cb9dd869-7aaa-4f42-a55e-ff1dbefc955f',
              name: 'Steel Coatings',
              tradeId: '09 97 13',
            },
            {
              id: '5a6a0857-bd18-40a8-8ad9-4b65008303e8',
              name: 'Interior Steel Coatings',
              tradeId: '09 97 13.13',
            },
            {
              id: 'dc0aa9e0-c208-42e9-81c4-a38d26f10cef',
              name: 'Exterior Steel Coatings',
              tradeId: '09 97 13.23',
            },
            {
              id: '8dcd9b04-6e79-4889-a2eb-70a12a2ad531',
              name: 'Concrete and Masonry Coatings',
              tradeId: '09 97 23',
            },
            {
              id: 'bded23b5-3c3c-4951-a0bd-7b66eec47584',
              name: 'Cementitious Coatings',
              tradeId: '09 97 26',
            },
            {
              id: '3c37de96-141c-4d48-833b-fb9cd6f26c4a',
              name: 'Interior Cementitious Coatings',
              tradeId: '09 97 26.13',
            },
            {
              id: 'edcd815d-a3f8-4674-8ad0-85c3db83471a',
              name: 'Exterior Cementitious Coatings',
              tradeId: '09 97 26.23',
            },
          ],
          tradeId: '09 97 00',
        },
      ],
      tradeId: '09 00 00',
    },
    {
      id: 'b0a6b560-9c13-431b-bfb9-290355a04c11',
      name: 'Specialties',
      children: [
        {
          id: 'ccc2ba0b-defa-4a1c-89af-da6d29b19c5b',
          name: 'Specialties',
          children: [],
          tradeId: '10 00 00',
        },
        {
          id: 'f14318ab-2eb8-47ea-b72c-0db4d25c60d5',
          name: 'Operation and Maintenance of Specialties',
          children: [
            {
              id: 'a4ade748-7bd2-485d-964f-5f2a211960ef',
              name: 'Operation and Maintenance of Specialties',
              tradeId: '10 01 00',
            },
            {
              id: 'f53b4a98-8cb3-4dbe-b90c-b68e02ec8d62',
              name: 'Operation and Maintenance of Information Specialties',
              tradeId: '10 01 10',
            },
            {
              id: '9a2f9602-bd51-4c00-a5d6-33c1906a5770',
              name: 'Operation and Maintenance of Interior Specialties',
              tradeId: '10 01 20',
            },
            {
              id: 'aa183c7a-4b31-4df5-b051-cac627565224',
              name: 'Operation and Maintenance of Fireplaces and Stoves',
              tradeId: '10 01 30',
            },
            {
              id: 'a06016f4-34d6-4756-a268-58ac041a9444',
              name: 'Operation and Maintenance of Safety Specialties',
              tradeId: '10 01 40',
            },
            {
              id: '7ad302ed-5b41-46a3-9af7-72f63a61ca2b',
              name: 'Operation and Maintenance of Storage Specialties',
              tradeId: '10 01 50',
            },
            {
              id: 'a69affa6-265b-4868-a281-880053146b40',
              name: 'Operation and Maintenance of Exterior Specialties',
              tradeId: '10 01 70',
            },
            {
              id: '10ae9205-ae80-4c32-a71d-5dcec2fb41b8',
              name: 'Operation and Maintenance of Other Specialties',
              tradeId: '10 01 80',
            },
          ],
          tradeId: '10 01 00',
        },
        {
          id: 'dd499399-4e65-412c-b8af-ef8e9f5e2dff',
          name: 'Common Work Results for Specialties',
          children: [],
          tradeId: '10 05 00',
        },
        {
          id: '511ddfcc-e50f-4aa4-bbc6-bbb49c5f96d8',
          name: 'Schedules for Specialties',
          children: [
            {
              id: '85fd5975-e1ff-4a28-ac5d-214e70727874',
              name: 'Schedules for Specialties',
              tradeId: '10 06 00',
            },
            {
              id: '5c9682b5-862e-4710-becc-4e8805227047',
              name: 'Schedules for Information Specialties',
              tradeId: '10 06 10',
            },
            {
              id: '028f0529-5706-43fd-b506-182ec8911796',
              name: 'Exterior Signage Schedule',
              tradeId: '10 06 10.13',
            },
            {
              id: 'b367b359-df5f-447c-86a2-1408675db763',
              name: 'Interior Signage Schedule',
              tradeId: '10 06 10.16',
            },
            {
              id: 'e5050cba-3187-4511-b34f-f76407b750b1',
              name: 'Schedules for Interior Specialties',
              tradeId: '10 06 20',
            },
            {
              id: '0031f5d1-4b4b-49a3-b10e-3a4cf5672bff',
              name: 'Toilet, Bath and Laundry Accessory Schedule',
              tradeId: '10 06 20.13',
            },
            {
              id: '0560579b-1503-444e-94af-532f0594edb9',
              name: 'Schedules for Fireplaces and Stoves',
              tradeId: '10 06 30',
            },
            {
              id: '3d6a52cb-7844-4a43-a5a9-e4c5dac9d2fa',
              name: 'Schedules for Safety Specialties',
              tradeId: '10 06 40',
            },
            {
              id: 'ccc2f8ba-ac15-4d91-af6a-f15790ff3dca',
              name: 'Schedules for Storage Specialties',
              tradeId: '10 06 50',
            },
            {
              id: '77ad0ad4-9ae5-411f-b095-f872b3a109db',
              name: 'Schedules for Exterior Specialties',
              tradeId: '10 06 70',
            },
            {
              id: '1c63ad2f-d5fb-4ff5-aea6-62fe99e9d2b4',
              name: 'Schedules for Other Specialties',
              tradeId: '10 06 80',
            },
          ],
          tradeId: '10 06 00',
        },
        {
          id: '885e3816-2444-4de4-83dc-61d53cf5dfe7',
          name: 'Commissioning of Specialties',
          children: [],
          tradeId: '10 08 00',
        },
        {
          id: 'a2f9e6c4-8d04-4b21-a9b0-1997d25e1cbe',
          name: 'Information Specialties',
          children: [],
          tradeId: '10 10 00',
        },
        {
          id: '6463210f-d041-44ec-aa28-418a4cb009f8',
          name: 'Visual Display Units',
          children: [
            {
              id: '4ea0eeab-9d3d-40ff-833d-f5c63c59a039',
              name: 'Visual Display Units',
              tradeId: '10 11 00',
            },
            {
              id: 'cdd97bb6-e17e-4ff7-b9fd-b8a39aab24db',
              name: 'Chalkboards',
              tradeId: '10 11 13',
            },
            {
              id: '920c8af7-d591-4569-ad0a-a7a37f157e7e',
              name: 'Fixed Chalkboards',
              tradeId: '10 11 13.13',
            },
            {
              id: '3c4ec587-7c6a-4d87-8483-53e4b553295d',
              name: 'Modular-Support-Mounted Chalkboards',
              tradeId: '10 11 13.23',
            },
            {
              id: '5608ae7d-0466-4767-9ba1-987700464cb3',
              name: 'Rail-Mounted Chalkboards',
              tradeId: '10 11 13.33',
            },
            {
              id: 'a3ae6ae4-8236-487c-8d97-0f4cb83217c9',
              name: 'Portable Chalkboards',
              tradeId: '10 11 13.43',
            },
            {
              id: '9bca28fe-791a-403c-bb87-289fa86e0b01',
              name: 'Markerboards',
              tradeId: '10 11 16',
            },
            {
              id: 'ff382190-1335-4b4b-8702-076567a0d894',
              name: 'Fixed Markerboards',
              tradeId: '10 11 16.13',
            },
            {
              id: '2b7e3fca-b5e5-4be2-91c0-54267c198156',
              name: 'Modular-Support-Mounted Markerboards',
              tradeId: '10 11 16.23',
            },
            {
              id: 'c9280d36-372a-499f-a99d-a4a970da8406',
              name: 'Rail-Mounted Markerboards',
              tradeId: '10 11 16.33',
            },
            {
              id: 'c749e6fc-aca5-4e80-92bd-93839862777e',
              name: 'Portable Markerboards',
              tradeId: '10 11 16.43',
            },
            {
              id: '0845b3f6-856b-431e-bf0c-1b655d9b8ddc',
              name: 'Electronic Markerboards',
              tradeId: '10 11 16.53',
            },
            {
              id: 'ff6aa47e-124c-4137-9750-ecd506beb4e5',
              name: 'Tackboards',
              tradeId: '10 11 23',
            },
            {
              id: '2f752a0f-c68a-4d87-b516-d93d84e1cc96',
              name: 'Fixed Tackboards',
              tradeId: '10 11 23.13',
            },
            {
              id: '330f710a-dd75-43c9-b184-3128eba42326',
              name: 'Modular-Support-Mounted Tackboards',
              tradeId: '10 11 23.23',
            },
            {
              id: '3923385c-f164-4131-bcd3-08cee9e6c67b',
              name: 'Rail-Mounted Tackboards',
              tradeId: '10 11 23.33',
            },
            {
              id: 'f97a19a4-faed-4162-a852-327fc3f2fae7',
              name: 'Portable Tackboards',
              tradeId: '10 11 23.43',
            },
            {
              id: 'd60d2de0-a11e-4474-bb1d-a79eb949f144',
              name: 'Sliding Visual Display Units',
              tradeId: '10 11 33',
            },
            {
              id: '18705a19-058c-4894-8d31-2ebbc79c24f3',
              name: 'Horizontal-Sliding Visual Display Units',
              tradeId: '10 11 33.13',
            },
            {
              id: 'fcafb28b-e63f-469b-b5e4-62644d558840',
              name: 'Vertical-Sliding Visual Display Units',
              tradeId: '10 11 33.23',
            },
            {
              id: '70392b4d-8f0a-47fc-aab3-075b2d068dbd',
              name: 'Visual Display Conference Units',
              tradeId: '10 11 36',
            },
            {
              id: '358d8f94-2fac-4c83-85f6-3451ed9619a6',
              name: 'Visual Display Rails',
              tradeId: '10 11 39',
            },
            {
              id: '9c0de1ef-2279-462e-8a9d-95ad74a5bf28',
              name: 'Visual Display Wall Panels',
              tradeId: '10 11 43',
            },
            {
              id: '63171bf7-d299-406c-bd22-93de0cc5805c',
              name: 'Visual Display Fabrics',
              tradeId: '10 11 46',
            },
          ],
          tradeId: '10 11 00',
        },
        {
          id: 'f632642c-50d6-4be3-9d81-0e6139f59dec',
          name: 'Display Cases',
          children: [],
          tradeId: '10 12 00',
        },
        {
          id: '3333b689-9681-4f75-b167-292ba668d27c',
          name: 'Directories',
          children: [
            {
              id: 'eefa9105-1dce-4d67-8869-617f015da3cd',
              name: 'Directories',
              tradeId: '10 13 00',
            },
            {
              id: '6bb9470c-b136-4968-9feb-2e48e4f7fb43',
              name: 'Electronic Directories',
              tradeId: '10 13 13',
            },
            {
              id: '7b0364cf-ca73-491b-8656-58a563a3709d',
              name: 'Illuminated Directories',
              tradeId: '10 13 23',
            },
          ],
          tradeId: '10 13 00',
        },
        {
          id: '40501ac0-dc7d-47f5-b93b-02e08d10af57',
          name: 'Signage',
          children: [
            {
              id: '9fa7bd0a-f7b9-40b9-a7a0-cb83d768962f',
              name: 'Signage',
              tradeId: '10 14 00',
            },
            {
              id: '5f08361f-f483-4c09-a30b-0f8555f9fdef',
              name: 'Plaques',
              tradeId: '10 14 16',
            },
            {
              id: '42897a78-54f8-4534-9a76-351884c8fd00',
              name: 'Dimensional Letter Signage',
              tradeId: '10 14 19',
            },
            {
              id: '822c8122-ad67-4733-88ef-798ef4e03c51',
              name: 'Panel Signage',
              tradeId: '10 14 23',
            },
            {
              id: '76308f09-9d8a-4c62-b04f-d85f64973c13',
              name: 'Engraved Panel Signage',
              tradeId: '10 14 23.13',
            },
            {
              id: 'b482cf78-5de9-44ed-9eb9-142088e0c474',
              name: 'Post and Panel/Pylon Signage',
              tradeId: '10 14 26',
            },
            {
              id: '219d3411-b220-44db-8770-e4a8ba67c4fa',
              name: 'Illuminated Panel Signage',
              tradeId: '10 14 33',
            },
            {
              id: '913c6d5c-defd-4e19-bbda-f54c78cd816d',
              name: 'Photoluminescent Signage',
              tradeId: '10 14 43',
            },
            {
              id: '0a0e80c8-535c-4e9e-97b1-10b6b6eaa435',
              name: 'Traffic Signage',
              tradeId: '10 14 53',
            },
            {
              id: 'b3f32360-f9ac-4fbd-aeeb-96075cd55e2d',
              name: 'Transportation Reference Markers',
              tradeId: '10 14 53.13',
            },
            {
              id: '04e5c1f4-dae1-4a52-b06f-aac5b61385a5',
              name: 'Electronic Message Signage',
              tradeId: '10 14 63',
            },
            {
              id: 'b26e25f3-d00a-4b14-be97-0d22adb14524',
              name: 'Audible Signage',
              tradeId: '10 14 64',
            },
            {
              id: '050f93df-452a-4b33-8ce5-971dd913dee2',
              name: 'Floating Signage',
              tradeId: '10 14 66',
            },
            {
              id: '252b8aa9-1f78-49d3-bdf5-4ec1f8e1e99f',
              name: 'Tactile Signage',
              tradeId: '10 14 67',
            },
          ],
          tradeId: '10 14 00',
        },
        {
          id: '43b1f2c7-fe37-4580-9e9d-d159d7f92e07',
          name: 'Telephone Specialties',
          children: [
            {
              id: '282e04c1-36bb-415e-9d76-a6f1824ce02d',
              name: 'Telephone Specialties',
              tradeId: '10 17 00',
            },
            {
              id: '57536af2-f23f-48b1-951b-719ceed73173',
              name: 'Telephone Directory Units',
              tradeId: '10 17 13',
            },
            {
              id: '1205f943-1ae4-4e12-b6bc-0b70c0aaa16b',
              name: 'Telephone Enclosures',
              tradeId: '10 17 16',
            },
            {
              id: '72e8e6c9-6b06-42d5-9fdb-4256530b24e4',
              name: 'Telephone Stalls',
              tradeId: '10 17 16.13',
            },
            {
              id: '557e2a56-3186-418d-a1b0-8dd0283121c3',
              name: 'Telephone Alcoves',
              tradeId: '10 17 16.16',
            },
            {
              id: 'e7338cde-4be2-4304-934c-7c0a3956f0ea',
              name: 'Telephone Shelving',
              tradeId: '10 17 19',
            },
          ],
          tradeId: '10 17 00',
        },
        {
          id: 'cf6e383e-99ab-4855-a04d-57a492ded186',
          name: 'Informational Kiosks',
          children: [],
          tradeId: '10 18 00',
        },
        {
          id: '4667c010-bc43-4927-bbf6-f0023b3a7f33',
          name: 'Interior Specialties',
          children: [],
          tradeId: '10 20 00',
        },
        {
          id: '302c3bfb-c44b-4c2d-973a-4106718d1323',
          name: 'Compartments and Cubicles',
          children: [
            {
              id: '9e6ff2cf-ac56-4240-9dde-9c4673ebe663',
              name: 'Compartments and Cubicles',
              tradeId: '10 21 00',
            },
            {
              id: 'dad6ef59-69a8-4ce0-8c9e-6fab62f415dd',
              name: 'Toilet Compartments',
              tradeId: '10 21 13',
            },
            {
              id: 'd547d53a-8453-4b7c-ba33-c1aa7623cdb7',
              name: 'Metal Toilet Compartments',
              tradeId: '10 21 13.13',
            },
            {
              id: '0935089a-aba8-495e-bb89-b661abb93df9',
              name: 'Plastic-Laminate-Clad Toilet Compartments',
              tradeId: '10 21 13.16',
            },
            {
              id: '1b71b166-566c-4cb9-b45d-9dfa4ea6a34c',
              name: 'Plastic Toilet Compartments',
              tradeId: '10 21 13.19',
            },
            {
              id: '33081a43-2d15-4106-8c7d-dfed99c4d837',
              name: 'Particleboard Toilet Compartments',
              tradeId: '10 21 13.23',
            },
            {
              id: '5c197842-746b-45df-94ef-0784531d55b3',
              name: 'Stone Toilet Compartments',
              tradeId: '10 21 13.40',
            },
            {
              id: '2bb9eb45-6079-46fb-a1f3-343b9454409d',
              name: 'Shower and Dressing Compartments',
              tradeId: '10 21 16',
            },
            {
              id: 'c303670a-4024-4985-b521-1dd74b534803',
              name: 'Metal Shower and Dressing Compartments',
              tradeId: '10 21 16.13',
            },
            {
              id: '2ee931f5-0299-4546-939c-d7488c5335b8',
              name: 'Plastic-Laminate-Clad Shower and Dressing Compartments',
              tradeId: '10 21 16.16',
            },
            {
              id: '6a702d34-6a68-4f42-95fa-70eb5cc0671c',
              name: 'Plastic Shower and Dressing Compartments',
              tradeId: '10 21 16.19',
            },
            {
              id: '18e754a8-b6b0-4aac-bc46-1486b555436e',
              name: 'Particleboard Shower and Dressing Compartments',
              tradeId: '10 21 16.23',
            },
            {
              id: 'cd8d957f-e2af-40df-bdc8-ee43844bfbbc',
              name: 'Stone Shower and Dressing Compartments',
              tradeId: '10 21 16.40',
            },
            {
              id: '7118d31c-8f3b-4658-8658-8fa640a96778',
              name: 'Cubicle Curtains and Track',
              tradeId: '10 21 23',
            },
            {
              id: 'f84bb793-f658-4d5b-82a6-901695180e02',
              name: 'Cubicle Curtains',
              tradeId: '10 21 23.13',
            },
            {
              id: 'da96659f-d632-47de-a1c3-bd0a0aebf2b5',
              name: 'Cubicle Track and Hardware',
              tradeId: '10 21 23.16',
            },
          ],
          tradeId: '10 21 00',
        },
        {
          id: '0d427caf-bdcb-4bc9-9c22-6652e63c75f3',
          name: 'Partitions',
          children: [
            {
              id: '70a2fac4-a4f9-4845-ab63-406636085d78',
              name: 'Partitions',
              tradeId: '10 22 00',
            },
            {
              id: 'd32e0527-02bc-43e3-ae16-949f8643c8d2',
              name: 'Wire Mesh Partitions',
              tradeId: '10 22 13',
            },
            {
              id: '272bf4cf-49f6-413c-85f4-2153111706ea',
              name: 'Expanded Metal Partitions',
              tradeId: '10 22 14',
            },
            {
              id: '9d380719-ef0b-4501-8a6f-c36a227ce919',
              name: 'Folding Gates',
              tradeId: '10 22 16',
            },
            {
              id: '67df9573-f751-46c4-bfb0-8d3d2f6cea01',
              name: 'Demountable Partitions',
              tradeId: '10 22 19',
            },
            {
              id: '339ba81e-86c8-40d2-b977-513584e220c8',
              name: 'Demountable Metal Partitions',
              tradeId: '10 22 19.13',
            },
            {
              id: '3dc55e55-3675-4fa1-9d67-6b704070ad29',
              name: 'Demountable Wood Partitions',
              tradeId: '10 22 19.23',
            },
            {
              id: '3b3329e7-4df5-49ee-b042-44fc6ac132eb',
              name: 'Demountable Plastic Partitions',
              tradeId: '10 22 19.33',
            },
            {
              id: 'bd4ff6a0-fce7-4f21-bc4e-ae21003ebd9a',
              name: 'Demountable Composite Partitions',
              tradeId: '10 22 19.43',
            },
            {
              id: 'c1a7e178-2a06-457f-8b57-0ffe1e7f3999',
              name: 'Demountable Gypsum Partitions',
              tradeId: '10 22 19.53',
            },
            {
              id: '6c6da448-677f-49ba-9270-e68c2b51d3c7',
              name: 'Portable Partitions, Screens and Panels',
              tradeId: '10 22 23',
            },
            {
              id: '871d3dc8-f0fc-4edc-9c36-4b79ffea873e',
              name: 'Wall Screens',
              tradeId: '10 22 23.13',
            },
            {
              id: '6a0fdae8-763e-4532-8fd8-6cef5ec76302',
              name: 'Movable Panel Systems',
              tradeId: '10 22 23.23',
            },
            {
              id: '96845fe9-e053-4847-9cef-766370d4c252',
              name: 'Accordion Folding Partitions',
              tradeId: '10 22 33',
            },
            {
              id: 'c1f9644d-7f12-45d9-8aeb-5b8a983c8d0e',
              name: 'Coiling Partitions',
              tradeId: '10 22 36',
            },
            {
              id: '363f1465-4d11-4e93-bb81-95ab7a1ac388',
              name: 'Folding Panel Partitions',
              tradeId: '10 22 39',
            },
            {
              id: 'f8f27b03-ade7-4575-bb2c-79cf4bce066e',
              name: 'Sliding Partitions',
              tradeId: '10 22 43',
            },
          ],
          tradeId: '10 22 00',
        },
        {
          id: '495d533d-18ec-4f85-a9ec-2b8c51323fe4',
          name: 'Service Walls',
          children: [
            {
              id: 'f7edfe05-0eb9-45b7-a521-04808abb0308',
              name: 'Service Walls',
              tradeId: '10 25 00',
            },
            {
              id: '6e0924ce-03cf-4895-8207-c7b601feaca6',
              name: 'Patient Bed Service Walls',
              tradeId: '10 25 13',
            },
            {
              id: 'acbfcaf2-d1de-4d38-81b4-078e8bdf2173',
              name: 'Modular Service Walls',
              tradeId: '10 25 16',
            },
          ],
          tradeId: '10 25 00',
        },
        {
          id: 'f4fbbc79-9a64-4637-8785-f7ecb7391aa5',
          name: 'Wall and Door Protection',
          children: [
            {
              id: 'de5e2b9c-aff9-4516-882b-9e5a891dc501',
              name: 'Wall and Door Protection',
              tradeId: '10 26 00',
            },
            {
              id: 'a4802e84-2006-444e-98ef-06affc807eea',
              name: 'Corner Guards',
              tradeId: '10 26 13',
            },
            {
              id: '9283ccf2-5c37-4ead-bec1-aff0c84d45c9',
              name: 'Bumper Guards',
              tradeId: '10 26 16',
            },
            {
              id: 'a8f24ddc-5605-4c66-8e33-a70f383f0af5',
              name: 'Bumper Rails',
              tradeId: '10 26 16.13',
            },
            {
              id: 'a254bafa-d3ec-4886-8019-5d6177df7ce4',
              name: 'Protective Corridor Handrails',
              tradeId: '10 26 16.16',
            },
            {
              id: '691208ba-cf2d-4da9-8b3f-a2942fcd8deb',
              name: 'Protective Wall Covering',
              tradeId: '10 26 23',
            },
            {
              id: 'f0088150-320d-4c81-ad96-35e511c85e85',
              name: 'Impact Resistant Wall Protection',
              tradeId: '10 26 23.13',
            },
            {
              id: '105ecc3b-f631-4f4f-988c-3efe5c66d3ed',
              name: 'Fiberglass Reinforced Protective Wall Covering',
              tradeId: '10 26 23.16',
            },
            {
              id: '1bace144-2ff1-4b94-bf56-05116a00903d',
              name: 'Door and Frame Protection',
              tradeId: '10 26 33',
            },
            {
              id: '8d96ad04-ba21-4677-b1d2-7a825161e4de',
              name: 'Bullet Resistant Panels',
              tradeId: '10 26 41',
            },
          ],
          tradeId: '10 26 00',
        },
        {
          id: '088d41f6-357a-4402-95aa-124863f8de89',
          name: 'Toilet, Bath and Laundry Accessories',
          children: [
            {
              id: '954ad9a5-0230-45db-a675-8b9c64eccdba',
              name: 'Toilet, Bath and Laundry Accessories',
              tradeId: '10 28 00',
            },
            {
              id: 'e9235445-9caf-4062-9bf8-cbbf815e5d08',
              name: 'Toilet Accessories',
              tradeId: '10 28 13',
            },
            {
              id: 'a878ecee-c509-4915-8111-cb6134eadc06',
              name: 'Commercial Toilet Accessories',
              tradeId: '10 28 13.13',
            },
            {
              id: '6012f463-fa69-44cd-9e4c-2238ba6f2246',
              name: 'Healthcare Toilet Accessories',
              tradeId: '10 28 13.19',
            },
            {
              id: '4fce61b6-d891-4e15-a4e3-38d89a09d56e',
              name: 'Security Toilet Accessories',
              tradeId: '10 28 13.53',
            },
            {
              id: 'baae1291-30d0-4a6a-a296-1f4d7a8f0826',
              name: 'Detention Toilet Accessories',
              tradeId: '10 28 13.63',
            },
            {
              id: 'abce65e3-6909-4435-87e8-b763746627cf',
              name: 'Bath Accessories',
              tradeId: '10 28 16',
            },
            {
              id: '8a31f569-3475-4abb-aab3-89cf48699ecb',
              name: 'Residential Bath Accessories',
              tradeId: '10 28 16.13',
            },
            {
              id: 'a020dfe1-9792-43e9-a970-8a6eeb894638',
              name: 'Tub and Shower Doors',
              tradeId: '10 28 19',
            },
            {
              id: '4ba21314-4c0b-4724-96a6-691148c23137',
              name: 'Shower Doors',
              tradeId: '10 28 19.16',
            },
            {
              id: 'fd087135-4916-4d2a-aa3e-d97cdde517c0',
              name: 'Tub Doors',
              tradeId: '10 28 19.19',
            },
            {
              id: '2c8a3ca5-4a20-4364-b0ee-dcf278428212',
              name: 'Laundry Accessories',
              tradeId: '10 28 23',
            },
            {
              id: '97957cb5-c177-47be-ad57-d982b22868e9',
              name: 'Built-In Ironing Boards',
              tradeId: '10 28 23.13',
            },
            {
              id: '593411c8-a216-4e69-983c-0fbd80f59e5d',
              name: 'Clothes Drying Racks',
              tradeId: '10 28 23.16',
            },
          ],
          tradeId: '10 28 00',
        },
        {
          id: '615d9cc0-a294-48a4-92a3-f1441e3f854b',
          name: 'Fireplaces and Stoves',
          children: [],
          tradeId: '10 30 00',
        },
        {
          id: '33394226-7858-42a8-89cd-0777061cc795',
          name: 'Manufactured Fireplaces',
          children: [
            {
              id: '5fce8e39-4c27-49b1-8a69-7736c1c9129b',
              name: 'Manufactured Fireplaces',
              tradeId: '10 31 00',
            },
            {
              id: '551ab281-8f75-48b8-b6cf-9c327cd007e9',
              name: 'Manufactured Fireplace Chimneys',
              tradeId: '10 31 13',
            },
            {
              id: 'e090c263-30c0-4fbb-acc9-9c951322988d',
              name: 'Manufactured Fireplace Forms',
              tradeId: '10 31 16',
            },
          ],
          tradeId: '10 31 00',
        },
        {
          id: 'b14a089d-a798-46f0-a820-fe9d66d920b0',
          name: 'Fireplace Specialties',
          children: [
            {
              id: 'c738975c-d694-4f07-bc12-18ad5bceb2fa',
              name: 'Fireplace Specialties',
              tradeId: '10 32 00',
            },
            {
              id: '50f2e739-c1be-47bc-a1a8-35d999c8fab1',
              name: 'Fireplace Dampers',
              tradeId: '10 32 13',
            },
            {
              id: '3ce433f4-83c1-40a3-a12b-67325e3f43f1',
              name: 'Fireplace Inserts',
              tradeId: '10 32 16',
            },
            {
              id: '4ce2677a-cff8-4f3d-8aa1-9692b0ad2a19',
              name: 'Fireplace Screens',
              tradeId: '10 32 19',
            },
            {
              id: 'e4b6d562-a318-4721-b818-7577ee50c7d5',
              name: 'Fireplace Doors',
              tradeId: '10 32 23',
            },
            {
              id: 'e942e1a8-73d5-4374-aa5f-97a09ea28d5e',
              name: 'Fireplace Water Heaters',
              tradeId: '10 32 26',
            },
          ],
          tradeId: '10 32 00',
        },
        {
          id: '8013de82-c1bc-4106-ace5-597bb631708a',
          name: 'Stoves',
          children: [
            {
              id: 'b05af391-db00-43dc-a428-de2e1de30aa0',
              name: 'Stoves',
              tradeId: '10 35 00',
            },
            {
              id: 'aa7228a7-44e4-4ff6-91bd-5d5d0cb73844',
              name: 'Heating Stoves',
              tradeId: '10 35 13',
            },
            {
              id: 'c1d49a54-8807-42bf-b080-35cca47d648f',
              name: 'Cooking Stoves',
              tradeId: '10 35 23',
            },
          ],
          tradeId: '10 35 00',
        },
        {
          id: 'f6d8b6f3-1aee-4457-921c-6ffc5b5d70e0',
          name: 'Safety Specialties',
          children: [],
          tradeId: '10 40 00',
        },
        {
          id: '15fe1ade-26de-48ce-a751-fa7d2fcfab3b',
          name: 'Emergency Access and Information Cabinets',
          children: [
            {
              id: 'fc8d71c6-a01c-4eae-93b1-9f8fd056fcb2',
              name: 'Emergency Access and Information Cabinets',
              tradeId: '10 41 00',
            },
            {
              id: '60f935ae-a3f3-4aff-973b-c6e8d93765ea',
              name: 'Fire Department Plan Cabinets',
              tradeId: '10 41 13',
            },
            {
              id: '822fd44d-4c51-45b3-b63f-054ce867ce34',
              name: 'Emergency Key Cabinets',
              tradeId: '10 41 16',
            },
          ],
          tradeId: '10 41 00',
        },
        {
          id: 'd9110888-0e4a-4c58-9943-87fad266c7bb',
          name: 'Emergency Aid Specialties',
          children: [
            {
              id: '31983c11-fa98-44eb-9d9a-f7ebc8684da7',
              name: 'Emergency Aid Specialties',
              tradeId: '10 43 00',
            },
            {
              id: '995f1cfb-edf1-48df-a7ff-8bee5690ddd2',
              name: 'Defibrillator Cabinets',
              tradeId: '10 43 13',
            },
            {
              id: '0ff8b683-784c-468b-b066-aa87cc6045dd',
              name: 'First Aid Cabinets',
              tradeId: '10 43 16',
            },
            {
              id: '3cd4bd41-5e3b-4a63-bbe0-b0f7b8300bd9',
              name: 'Accessibility Evacuation Chairs',
              tradeId: '10 43 21',
            },
            {
              id: '9e544f6d-8f83-478d-baa2-2acd1cc73b6a',
              name: 'Respiration Equipment',
              tradeId: '10 43 31',
            },
            {
              id: 'd01e49ef-d832-428d-9c2d-12b8392d4cb8',
              name: 'Breathing Air Replenishment Systems',
              tradeId: '10 43 33',
            },
          ],
          tradeId: '10 43 00',
        },
        {
          id: '60d4f4b0-cb0a-4323-8b67-90021345a167',
          name: 'Fire Protection Specialties',
          children: [
            {
              id: 'ca7d8dd9-c3e3-41e3-b373-3c275ba6e65a',
              name: 'Fire Protection Specialties',
              tradeId: '10 44 00',
            },
            {
              id: '7dabe2bd-c356-44e9-aeed-c7223f52403b',
              name: 'Fire Protection Cabinets',
              tradeId: '10 44 13',
            },
            {
              id: '43290676-1883-46e8-a737-d79c445f2987',
              name: 'Security Fire Extinguisher Cabinets',
              tradeId: '10 44 13.53',
            },
            {
              id: '4a1bdff7-b02a-45a1-b4e8-b94e1bc29af5',
              name: 'Fire Extinguishers',
              tradeId: '10 44 16',
            },
            {
              id: '37f7ae96-459a-4d52-9db3-df1887622571',
              name: 'Portable Fire Extinguishers',
              tradeId: '10 44 16.13',
            },
            {
              id: '9ddd997b-e67f-4956-9332-c504125924a6',
              name: 'Wheeled Fire Extinguisher Units',
              tradeId: '10 44 16.16',
            },
            {
              id: '54cd5d3f-a9ac-46f9-a56e-c91faf3537e8',
              name: 'Fire Blankets',
              tradeId: '10 44 19',
            },
            {
              id: '6ce36c84-b7b3-4cd1-b38a-277f02451bd2',
              name: 'Fire Extinguisher Accessories',
              tradeId: '10 44 43',
            },
          ],
          tradeId: '10 44 00',
        },
        {
          id: 'c3111afa-9409-4562-8956-04b35fa0c988',
          name: 'Storage Specialties',
          children: [],
          tradeId: '10 50 00',
        },
        {
          id: 'f70a18a3-0d60-430d-8c04-5982ac4c6755',
          name: 'Lockers',
          children: [
            {
              id: 'e857763c-315a-4426-986e-0bb6ad332b59',
              name: 'Lockers',
              tradeId: '10 51 00',
            },
            {
              id: '45d0cb26-cb07-4722-8fd1-c5af3b78705f',
              name: 'Metal Lockers',
              tradeId: '10 51 13',
            },
            {
              id: '751f492a-e99a-4057-99ba-e558106bba77',
              name: 'Wood Lockers',
              tradeId: '10 51 16',
            },
            {
              id: '3be6a89a-057c-4240-ba5a-96535d498df3',
              name: 'Plastic-Laminate-Clad Lockers',
              tradeId: '10 51 23',
            },
            {
              id: 'c954edd6-5678-456c-82ea-7891559e9c74',
              name: 'Plastic Lockers',
              tradeId: '10 51 26',
            },
            {
              id: '83554431-2fe7-4327-94d6-30641c638388',
              name: 'Recycled Plastic Lockers',
              tradeId: '10 51 26.13',
            },
            {
              id: '1b20e4fc-0106-44c3-9f3e-b13d1e777f40',
              name: 'Phenolic Lockers',
              tradeId: '10 51 29',
            },
            {
              id: '83bc4164-8b9b-4520-8a97-d8e9dbb5b599',
              name: 'Glass Lockers',
              tradeId: '10 51 33',
            },
            {
              id: 'b59e3321-4923-46b4-ad78-3835af25eeb0',
              name: 'Wire Mesh Storage Lockers',
              tradeId: '10 51 43',
            },
            {
              id: '314d953d-dce6-4634-8184-45f014000bf1',
              name: 'Locker Room Benches',
              tradeId: '10 51 53',
            },
          ],
          tradeId: '10 51 00',
        },
        {
          id: 'dfe7927b-c1e7-4d60-8782-33bf4823b478',
          name: 'Postal Specialties',
          children: [
            {
              id: 'ee7c8a31-dd0b-478d-a0b6-380d340c72fb',
              name: 'Postal Specialties',
              tradeId: '10 55 00',
            },
            {
              id: 'b268d380-42a9-4e0b-b736-63d97b2683df',
              name: 'Central Mail Delivery Boxes',
              tradeId: '10 55 13',
            },
            {
              id: '13c5356b-f08d-46ce-91a5-742f24cecf24',
              name: 'Cluster Box Units',
              tradeId: '10 55 13.13',
            },
            {
              id: 'a0f0e475-6020-448c-94e8-a5a5a9a09728',
              name: 'Mail Collection Boxes',
              tradeId: '10 55 16',
            },
            {
              id: '37fc32bb-dcca-466a-b640-8de9851fa09d',
              name: 'Receiving Boxes',
              tradeId: '10 55 19',
            },
            {
              id: '160e15bd-99db-45fa-9813-a06e805c7cf3',
              name: 'Mail Boxes',
              tradeId: '10 55 23',
            },
            {
              id: 'b58d6f55-1b3d-4a98-aa44-a147ac9d8d34',
              name: 'Apartment Mail Boxes',
              tradeId: '10 55 23.13',
            },
            {
              id: '69a2f49d-1337-4ebc-899f-1a216c640981',
              name: 'Mail Box Directories',
              tradeId: '10 55 23.16',
            },
            {
              id: 'd8df6548-6943-413b-af89-b2f3c86f8f06',
              name: 'Mail Box Key Keepers',
              tradeId: '10 55 23.19',
            },
            {
              id: '666dca05-900b-40ed-aa12-1e2ccf69ed52',
              name: 'Parcel Lockers',
              tradeId: '10 55 26',
            },
            {
              id: '23066101-3a88-43a2-9852-d854ea1b766a',
              name: 'Data Distribution Boxes',
              tradeId: '10 55 33',
            },
            {
              id: 'abd762e1-b5cc-4063-9a39-37b78cd3a796',
              name: 'Package Depositories',
              tradeId: '10 55 36',
            },
            {
              id: '094691f1-322d-48d1-a0da-bcfc1614659a',
              name: 'Mail Chutes',
              tradeId: '10 55 91',
            },
          ],
          tradeId: '10 55 00',
        },
        {
          id: '564dbbd6-1280-47d2-8186-6674225b0ef2',
          name: 'Storage Assemblies',
          children: [
            {
              id: '788828c1-46d8-463d-81e5-ddbd75cc54b5',
              name: 'Storage Assemblies',
              tradeId: '10 56 00',
            },
            {
              id: '79afbea1-0c1f-4256-8147-25f0344914ec',
              name: 'Metal Storage Shelving',
              tradeId: '10 56 13',
            },
            {
              id: '1c718550-d3b5-4dd0-b662-1563dc932b8d',
              name: 'End-Panel-Support Metal Storage Shelving',
              tradeId: '10 56 13.13',
            },
            {
              id: '4d8ffd07-1e14-42b6-96de-5504f41717b3',
              name: 'Post-and-Shelf Metal Storage Shelving',
              tradeId: '10 56 13.16',
            },
            {
              id: '87e001ee-9241-4806-a358-c9aa65596161',
              name: 'Post-and-Beam Metal Storage Shelving',
              tradeId: '10 56 13.19',
            },
            {
              id: '385fe0db-f559-47e1-bbec-2d1a0cd2d9d9',
              name: 'Cantilever Metal Storage Shelving',
              tradeId: '10 56 13.23',
            },
            {
              id: 'd7958631-7b0e-471e-84fb-557e11ab08ea',
              name: 'Fabricated Wood Storage Shelving',
              tradeId: '10 56 16',
            },
            {
              id: '61b83e42-b1fe-419d-b64f-d957b69c3005',
              name: 'Wall-Mounted Standards and Shelving',
              tradeId: '10 56 17',
            },
            {
              id: '409a4790-2ec2-4505-a233-d1002549e700',
              name: 'Plastic Storage Shelving',
              tradeId: '10 56 19',
            },
            {
              id: '934632b0-befe-4fe5-ba00-fc6963c6a2ee',
              name: 'Recycled Plastic Storage Shelving',
              tradeId: '10 56 19.13',
            },
            {
              id: '3884001b-a373-4b10-ae6f-706bf3d34097',
              name: 'Wire Storage Shelving',
              tradeId: '10 56 23',
            },
            {
              id: 'ae877c58-04db-4d11-9108-c2ebea95cf77',
              name: 'Mobile Storage Shelving',
              tradeId: '10 56 26',
            },
            {
              id: 'f67d43d1-400b-4591-8ad5-5ab90d0578dc',
              name: 'Manual Mobile Storage Shelving',
              tradeId: '10 56 26.13',
            },
            {
              id: '4694b40f-e7d2-4e65-814a-011477b98768',
              name: 'Motorized Mobile Storage Shelving',
              tradeId: '10 56 26.23',
            },
            {
              id: 'bb297e13-f89e-42e2-944d-64655256c7df',
              name: 'Storage Racks',
              tradeId: '10 56 29',
            },
            {
              id: '8df599df-6e84-4fc2-b688-7e2442e9ac04',
              name: 'Flow Storage Racks',
              tradeId: '10 56 29.13',
            },
            {
              id: 'cb806dd1-2a3e-493d-ad6d-ae3517a88400',
              name: 'Pallet Storage Racks',
              tradeId: '10 56 29.16',
            },
            {
              id: 'fab0b140-b970-427d-93ec-2bbeebf16722',
              name: 'Movable-Shelf Storage Racks',
              tradeId: '10 56 29.19',
            },
            {
              id: '3d7840ff-e40a-41e9-b396-c80e11ce6b88',
              name: 'Stacker Storage Racks',
              tradeId: '10 56 29.23',
            },
            {
              id: '18f69579-8761-4226-89d5-acd8b3ea51c5',
              name: 'Cantilever Storage Racks',
              tradeId: '10 56 29.26',
            },
            {
              id: '5b457199-d8e2-40e7-b294-ec902c573250',
              name: 'Drive-In Storage Racks',
              tradeId: '10 56 29.29',
            },
            {
              id: '62c68272-f9f9-49be-973d-f0614b9480af',
              name: 'Drive-Through Storage Racks',
              tradeId: '10 56 29.33',
            },
            {
              id: '5eca8c1c-58e9-4e03-a8b2-f9998481dcc1',
              name: 'Wine Storage Racks',
              tradeId: '10 56 29.43',
            },
            {
              id: '838c294b-b82a-4684-8b29-148f5df94f65',
              name: 'Mercantile Storage Assemblies',
              tradeId: '10 56 33',
            },
          ],
          tradeId: '10 56 00',
        },
        {
          id: '162e0b5d-adc5-4824-8ca9-416ca52e3ce8',
          name: 'Wardrobe and Closet Specialties',
          children: [
            {
              id: 'f8f5d77c-b249-45cd-9068-4c8ea04e9d7b',
              name: 'Wardrobe and Closet Specialties',
              tradeId: '10 57 00',
            },
            {
              id: '28203a7d-9050-4264-99e2-975e084785f8',
              name: 'Hat and Coat Racks',
              tradeId: '10 57 13',
            },
            {
              id: '1ebb8ec0-37f5-407d-bc7c-af29aca4e0a2',
              name: 'Boot Racks',
              tradeId: '10 57 16',
            },
            {
              id: '1e5070ef-d023-4fbf-848e-728604433370',
              name: 'Closet and Utility Shelving',
              tradeId: '10 57 23',
            },
            {
              id: '17ca5463-11c3-470b-9b0d-b50f3a0b830d',
              name: 'Wire Closet and Utility Shelving',
              tradeId: '10 57 23.13',
            },
            {
              id: 'ab3cd79e-b660-42a3-9c59-ae14456bf5e1',
              name: 'Plastic-Laminate-Clad Closet and Utility Shelving',
              tradeId: '10 57 23.16',
            },
            {
              id: '4ddec618-a4d4-48ec-99f7-dd48815cfe40',
              name: 'Wood Closet and Utility Shelving',
              tradeId: '10 57 23.19',
            },
            {
              id: 'cbefc9b6-be36-49e8-a3ea-0ee600878c5a',
              name: 'Closet and Utility Shelving Hardware',
              tradeId: '10 57 33',
            },
          ],
          tradeId: '10 57 00',
        },
        {
          id: '60cdbf86-11cd-468f-86fb-e93fbc8df88c',
          name: 'Exterior Specialties',
          children: [],
          tradeId: '10 70 00',
        },
        {
          id: '1ac9c991-a265-4f84-ae40-4ae322b863a4',
          name: 'Exterior Protection',
          children: [
            {
              id: '2de070c0-a60b-4602-a8d4-e986f547c29f',
              name: 'Exterior Protection',
              tradeId: '10 71 00',
            },
            {
              id: '8d5cd969-cb1d-45ef-87a3-d22dfbbe3b6a',
              name: 'Exterior Sun Control Devices',
              tradeId: '10 71 13',
            },
            {
              id: '6bade6a1-4814-410f-a0cb-1ea929b2b18a',
              name: 'Exterior Shutters',
              tradeId: '10 71 13.13',
            },
            {
              id: 'a5ab90d6-3cbd-4047-ad64-3c4f1e27c9d8',
              name: 'Rolling Exterior Shutters',
              tradeId: '10 71 13.19',
            },
            {
              id: '7934ebc4-d137-45a2-a909-a6f0763ff3fd',
              name: 'Coiling Exterior Shutters',
              tradeId: '10 71 13.23',
            },
            {
              id: '31edf488-7b36-4d60-b769-39b618f1d2e2',
              name: 'Decorative Exterior Shutters',
              tradeId: '10 71 13.26',
            },
            {
              id: '2cdfafa2-1d80-4bb6-89e3-cad8b5821fec',
              name: 'Side-Hinged Exterior Shutters',
              tradeId: '10 71 13.29',
            },
            {
              id: '43ea9bb0-7c4f-4deb-acba-1b3c043cc844',
              name: 'Fixed Sun Screens',
              tradeId: '10 71 13.43',
            },
            {
              id: '67b2f210-7560-4ed0-918a-a3318039a185',
              name: 'Exterior Daylighting Devices',
              tradeId: '10 71 13.53',
            },
            {
              id: '4720d136-db5d-4e10-b7a7-9177e16c964b',
              name: 'Storm Panels',
              tradeId: '10 71 16',
            },
            {
              id: 'f0fdc771-dced-4524-871f-41d18acf531e',
              name: 'Demountable Storm Panels',
              tradeId: '10 71 16.13',
            },
            {
              id: '376646f8-c962-4f85-9644-bc51a8b57456',
              name: 'Movable Storm Panels',
              tradeId: '10 71 16.16',
            },
            {
              id: '25e230f0-79eb-4d9e-8470-c5ee5275eb02',
              name: 'Flood Barriers',
              tradeId: '10 71 19',
            },
            {
              id: '9e2a1bc4-9be7-453d-9df5-6689f41f89e5',
              name: 'Removable Flood Barriers',
              tradeId: '10 71 19.16',
            },
          ],
          tradeId: '10 71 00',
        },
        {
          id: 'ba4f3339-3ff8-4850-b3bd-e788826b6499',
          name: 'Protective Covers',
          children: [
            {
              id: '0020efb8-ccc0-4ab8-ba31-a90b5dee1935',
              name: 'Protective Covers',
              tradeId: '10 73 00',
            },
            {
              id: 'dec7e208-d1fc-4bd6-8558-0a68671c1613',
              name: 'Awnings',
              tradeId: '10 73 13',
            },
            {
              id: '70487d0f-882a-4aaf-bd71-b51ffdbb9d9e',
              name: 'Canopies',
              tradeId: '10 73 16',
            },
            {
              id: '1df0738c-ef87-4cf6-949a-19b4a265ac11',
              name: 'Car Shelters',
              tradeId: '10 73 23',
            },
            {
              id: '3a6d71a5-eabf-4630-b16c-70b67d64fb55',
              name: 'Walkway Coverings',
              tradeId: '10 73 26',
            },
            {
              id: 'c50bb944-34f4-42ad-96b9-e75bddded9fa',
              name: 'Marquees',
              tradeId: '10 73 33',
            },
            {
              id: '843a4cb2-9254-4faa-94c6-4e9e6462e4fb',
              name: 'Transportation Stop Shelters',
              tradeId: '10 73 43',
            },
          ],
          tradeId: '10 73 00',
        },
        {
          id: '2a51eb3f-0584-48a5-9335-0d66911062ef',
          name: 'Manufactured Exterior Specialties',
          children: [
            {
              id: '4840ca5c-dfd8-4811-8da2-ad355f452920',
              name: 'Manufactured Exterior Specialties',
              tradeId: '10 74 00',
            },
            {
              id: '8432eaa7-a0f7-4c86-87d0-63bc4673cd1a',
              name: 'Exterior Clocks',
              tradeId: '10 74 13',
            },
            {
              id: '477bc775-54df-4b52-b3b7-4b15027dcb5b',
              name: 'Cupolas',
              tradeId: '10 74 23',
            },
            {
              id: '216ffea5-49e0-48f1-9ece-acf349eb0911',
              name: 'Spires',
              tradeId: '10 74 26',
            },
            {
              id: 'f9fc16c7-9245-4d17-8003-db2c5da0dad0',
              name: 'Steeples',
              tradeId: '10 74 29',
            },
            {
              id: '02034084-9913-4228-a091-143b151a7d9f',
              name: 'Weathervanes',
              tradeId: '10 74 33',
            },
            {
              id: '7684f413-d331-462d-bb5d-7b54da762937',
              name: 'Below-Grade Egress Assemblies',
              tradeId: '10 74 43',
            },
            {
              id: '562ff537-78f7-46e3-9520-259db28054c4',
              name: 'Window Wells',
              tradeId: '10 74 46',
            },
          ],
          tradeId: '10 74 00',
        },
        {
          id: '83e03768-ff56-49db-80dd-3cc5a188ce3a',
          name: 'Flagpoles',
          children: [
            {
              id: '93f81139-b55e-434c-b617-f45e73a28009',
              name: 'Flagpoles',
              tradeId: '10 75 00',
            },
            {
              id: '831c939e-a0ad-442c-8382-732a9c21f84e',
              name: 'Automatic Flagpoles',
              tradeId: '10 75 13',
            },
            {
              id: '14534163-cd20-4af8-ac43-22fb18c8a25b',
              name: 'Ground-Set Flagpoles',
              tradeId: '10 75 16',
            },
            {
              id: 'c46cdbd1-9ac4-45a8-bacc-edf589e9eea6',
              name: 'Nautical Flagpoles',
              tradeId: '10 75 19',
            },
            {
              id: '4b4ea4c2-d943-4c94-8284-01d060b56745',
              name: 'Wall-Mounted Flagpoles',
              tradeId: '10 75 23',
            },
          ],
          tradeId: '10 75 00',
        },
        {
          id: '583184a3-7d94-4549-baff-678d4a90303d',
          name: 'Other Specialties',
          children: [],
          tradeId: '10 80 00',
        },
        {
          id: 'dce95c4c-40f1-4d7e-ae6b-549557f850a3',
          name: 'Pest Control Devices',
          children: [
            {
              id: '4820bd82-d422-4474-9a20-d2373bff5141',
              name: 'Pest Control Devices',
              tradeId: '10 81 00',
            },
            {
              id: '395ff8b4-30bc-4afa-80d3-cfd4679e4c3b',
              name: 'Bird Control Devices',
              tradeId: '10 81 13',
            },
            {
              id: '735d8982-7231-4ed9-8851-4e88ffbad13b',
              name: 'Insect Control Devices',
              tradeId: '10 81 16',
            },
            {
              id: '1a75ceea-2dcf-4790-939f-36e4305e65fb',
              name: 'Rodent Control Devices',
              tradeId: '10 81 19',
            },
          ],
          tradeId: '10 81 00',
        },
        {
          id: '6a21945f-9c9a-4658-a913-656c2792f683',
          name: 'Grilles and Screens',
          children: [
            {
              id: '12e42667-2faf-455b-9fd7-bfcc7d966f5a',
              name: 'Grilles and Screens',
              tradeId: '10 82 00',
            },
            {
              id: '5b89807f-13a7-4369-b641-1f30fe93f946',
              name: 'Exterior Grilles and Screens',
              tradeId: '10 82 13',
            },
            {
              id: '1166337a-2491-430f-99dc-5761060e0cdf',
              name: 'Exterior Sound Screens',
              tradeId: '10 82 19',
            },
            {
              id: 'ce2754ee-476d-43e2-9fe3-091128550a27',
              name: 'Interior Grilles and Screens',
              tradeId: '10 82 23',
            },
          ],
          tradeId: '10 82 00',
        },
        {
          id: '941c3e41-4032-43c1-91a6-63bebbe98130',
          name: 'Flags and Banners',
          children: [
            {
              id: '3e2f9c19-8b1c-4287-90e4-4fe05f0c582f',
              name: 'Flags and Banners',
              tradeId: '10 83 00',
            },
            {
              id: 'c76d0e56-2ad4-4b0c-a7b9-bac65e4ad276',
              name: 'Flags',
              tradeId: '10 83 13',
            },
            {
              id: '2f9fa727-a201-486d-962f-f2a22071bb67',
              name: 'Banners',
              tradeId: '10 83 16',
            },
          ],
          tradeId: '10 83 00',
        },
        {
          id: 'a9e75b6a-19ab-48f0-9310-497127725dc7',
          name: 'Gas Lighting',
          children: [
            {
              id: 'da9da4b4-f65e-475e-b14c-558d68abddb5',
              name: 'Gas Lighting',
              tradeId: '10 84 00',
            },
            {
              id: '2a2fe9c5-37f4-4ac3-bce5-f6d6dd5246c7',
              name: 'Exterior Gas Lighting',
              tradeId: '10 84 13',
            },
            {
              id: 'fcaa88b1-cb08-463b-8914-a16aa387013c',
              name: 'Interior Gas Lighting',
              tradeId: '10 84 16',
            },
          ],
          tradeId: '10 84 00',
        },
        {
          id: '732f56e6-da02-4d0a-996e-e53fd04db434',
          name: 'Security Mirrors and Domes',
          children: [],
          tradeId: '10 86 00',
        },
        {
          id: '0bbc1f79-a13f-4d94-b4c7-a2d9fd80d941',
          name: 'Scales',
          children: [],
          tradeId: '10 88 00',
        },
      ],
      tradeId: '10 00 00',
    },
    {
      id: '601209d2-f96f-47c5-8d9d-fa51eb993a1c',
      name: 'Equipment',
      children: [
        {
          id: '3549023b-776a-44a7-ab8f-850e9938c5d4',
          name: 'Equipment',
          children: [],
          tradeId: '11 00 00',
        },
        {
          id: '174b71b7-db0e-423e-a23d-b42e8d1af708',
          name: 'Operation and Maintenance of Equipment',
          children: [
            {
              id: 'f6cebd06-c239-43b3-9810-7064c7f04679',
              name: 'Operation and Maintenance of Equipment',
              tradeId: '11 01 00',
            },
            {
              id: '012c173d-c544-474a-9210-ccaf23a98e07',
              name: 'Operation and Maintenance of Vehicle and Pedestrian Equipment',
              tradeId: '11 01 10',
            },
            {
              id: '76ca9a5e-5751-4cfa-af29-763be7161582',
              name: 'Operation and Maintenance of Security, Bank and Detention Equipment',
              tradeId: '11 01 15',
            },
            {
              id: '46f3a6b7-d47b-4d45-a0ae-6f131ac1ea8a',
              name: 'Operation and Maintenance of Commercial Equipment',
              tradeId: '11 01 20',
            },
            {
              id: 'e71d5457-2486-4f51-ad3b-39f1cc472a55',
              name: 'Operation and Maintenance of Residential Equipment',
              tradeId: '11 01 30',
            },
            {
              id: 'b2ceeeac-235e-4ec7-aaad-f6e9f9440a04',
              name: 'Operation and Maintenance of Foodservice Equipment',
              tradeId: '11 01 40',
            },
            {
              id: '859d7aea-92d0-4ae4-b3ea-3f05bb7a1de7',
              name: 'Operation and Maintenance of Educational and Scientific Equipment',
              tradeId: '11 01 50',
            },
            {
              id: '5bade575-c21a-4560-946d-842c3324e005',
              name: 'Operation and Maintenance of Observatory Equipment',
              tradeId: '11 01 56',
            },
            {
              id: 'f806ecc9-6c29-403f-a4c0-00d7d23e9685',
              name: 'Operation and Maintenance of Entertainment Equipment',
              tradeId: '11 01 60',
            },
            {
              id: '307075fd-b5c2-47d6-9502-62f07eeddbc9',
              name: 'Operation and Maintenance of Athletic and Recreational Equipment',
              tradeId: '11 01 65',
            },
            {
              id: 'c3986547-ae4d-4e85-a7b3-d53800ae7b5a',
              name: 'Operation and Maintenance of Healthcare Equipment',
              tradeId: '11 01 70',
            },
            {
              id: 'b055f753-eb59-49b3-bb1d-e6d439852685',
              name: 'Operation and Maintenance of Collection and Disposal Equipment',
              tradeId: '11 01 80',
            },
            {
              id: '8ae1d8a5-096e-4b27-9b1b-fa2be016baa3',
              name: 'Operation and Maintenance of Other Equipment',
              tradeId: '11 01 90',
            },
          ],
          tradeId: '11 01 00',
        },
        {
          id: 'dccb5230-a98b-4489-89a5-4b68f220c598',
          name: 'Common Work Results for Equipment',
          children: [
            {
              id: '3f1e257b-cdde-4325-bcb4-a52c6418aed8',
              name: 'Common Work Results for Equipment',
              tradeId: '11 05 00',
            },
            {
              id: '356c1e10-0f27-4401-ba89-4c22b3bf061e',
              name: 'Common Motor Requirements for Equipment',
              tradeId: '11 05 13',
            },
          ],
          tradeId: '11 05 00',
        },
        {
          id: '28f37139-b87e-4746-86ba-e7d2cb1bbdbb',
          name: 'Schedules for Equipment',
          children: [
            {
              id: '53d5e5d9-7558-41a2-9dd9-332bba31aae9',
              name: 'Schedules for Equipment',
              tradeId: '11 06 00',
            },
            {
              id: '5450e444-08c3-48e2-8f7f-4203e95ead7d',
              name: 'Schedules for Vehicle and Pedestrian Equipment',
              tradeId: '11 06 10',
            },
            {
              id: '61510e0e-42ae-4ec8-9a4b-0219b0371a25',
              name: 'Schedules for Security, Bank and Detention Equipment',
              tradeId: '11 06 15',
            },
            {
              id: '2939aa62-15f3-428c-8fb8-82425cf2ded4',
              name: 'Teller and Service Equipment Schedule',
              tradeId: '11 06 15.13',
            },
            {
              id: 'e2b852de-c3d2-4d0c-9cd4-f1b8a21b517d',
              name: 'Schedules for Commercial Equipment',
              tradeId: '11 06 20',
            },
            {
              id: '95758e46-a7d4-41bc-be10-9c6fb568c151',
              name: 'Schedules for Residential Equipment',
              tradeId: '11 06 30',
            },
            {
              id: '637148ae-fe82-4731-a790-37dfa817e9c7',
              name: 'Schedules for Foodservice Equipment',
              tradeId: '11 06 40',
            },
            {
              id: '039c70b7-97cb-4395-8ca3-2e82bc21b59e',
              name: 'Foodservice Equipment Schedule',
              tradeId: '11 06 40.13',
            },
            {
              id: '7c1b6ff9-c905-4581-bb20-c505333a02e0',
              name: 'Schedules for Educational and Scientific Equipment',
              tradeId: '11 06 50',
            },
            {
              id: '2313ced0-f0fa-4652-8b84-1f4d12147e1c',
              name: 'Schedules for Entertainment Equipment',
              tradeId: '11 06 60',
            },
            {
              id: '1d4e3423-ec00-406f-ba0f-e6517cd25faa',
              name: 'Schedules for Athletic and Recreational Equipment',
              tradeId: '11 06 65',
            },
            {
              id: '1cffb889-b0e4-4079-bbf9-1a083a907070',
              name: 'Schedules for Healthcare Equipment',
              tradeId: '11 06 70',
            },
            {
              id: '77d3b4f4-797b-4d84-94ff-e677f4a44231',
              name: 'Healthcare Equipment Schedule',
              tradeId: '11 06 70.13',
            },
            {
              id: '44c71e90-28a3-47db-b47b-58bdfd587af4',
              name: 'Schedules for Collection and Disposal Equipment',
              tradeId: '11 06 80',
            },
            {
              id: '09d55955-838c-47d6-8d2a-45605e5dfb55',
              name: 'Schedules for Other Equipment',
              tradeId: '11 06 90',
            },
          ],
          tradeId: '11 06 00',
        },
        {
          id: '52ad8926-b8f3-4619-a267-57ce580aca1f',
          name: 'Commissioning of Equipment',
          children: [],
          tradeId: '11 08 00',
        },
        {
          id: '98d45593-3231-40d6-b2b8-8bdfa6ab2179',
          name: 'Vehicle and Pedestrian Equipment',
          children: [],
          tradeId: '11 10 00',
        },
        {
          id: '0ae8751f-dc70-4c12-90e9-ed092dc52f36',
          name: 'Vehicle Service Equipment',
          children: [
            {
              id: '0406f581-e54d-48bb-899c-82a8f4d85051',
              name: 'Vehicle Service Equipment',
              tradeId: '11 11 00',
            },
            {
              id: 'cc399ca2-a04b-41f0-a427-c8cfbc50ff96',
              name: 'Compressed-Air Vehicle Service Equipment',
              tradeId: '11 11 13',
            },
            {
              id: '0255d824-ec58-4e36-b26b-172899add267',
              name: 'Vehicle Lubrication Equipment',
              tradeId: '11 11 19',
            },
            {
              id: 'c490be16-c5a5-439c-8c44-d3ef4cb2f436',
              name: 'Tire-Changing Equipment',
              tradeId: '11 11 23',
            },
            {
              id: '1b01915b-cd33-4b9f-b70b-08e866bd4687',
              name: 'Vehicle-Washing Equipment',
              tradeId: '11 11 26',
            },
          ],
          tradeId: '11 11 00',
        },
        {
          id: '7abe5bf3-900e-4c33-b49f-b4cb126e740a',
          name: 'Parking Control Equipment',
          children: [
            {
              id: '88236386-2c02-4b37-bbe7-fee974e63157',
              name: 'Parking Control Equipment',
              tradeId: '11 12 00',
            },
            {
              id: 'ce8fbea2-fd96-4a26-9443-136a6291858d',
              name: 'Parking Key and Card Control Units',
              tradeId: '11 12 13',
            },
            {
              id: '394a22d9-8c53-45ba-8c1d-6ec13953e112',
              name: 'Parking Ticket Dispensers',
              tradeId: '11 12 16',
            },
            {
              id: '89021d6e-927a-4fb3-8cda-76c6e7d9383b',
              name: 'Parking Meters',
              tradeId: '11 12 23',
            },
            {
              id: '7264fe54-b63c-4b57-ac11-bb52914d9ad3',
              name: 'Parking Fee Collection Equipment',
              tradeId: '11 12 26',
            },
            {
              id: '68752f54-e610-4c49-b9d6-933371d24efe',
              name: 'Parking Fee Coin Collection Equipment',
              tradeId: '11 12 26.13',
            },
            {
              id: '1e7b39c6-0990-45ff-97d2-63f68b59e614',
              name: 'Parking Gates',
              tradeId: '11 12 33',
            },
            {
              id: 'fbe46483-e79b-4ea8-8f25-5358d54f104e',
              name: 'Lift Arm Parking Gates',
              tradeId: '11 12 33.13',
            },
            {
              id: '9658c118-75a0-49d5-8822-4ffaa7124dc4',
              name: 'Sliding Parking Gates',
              tradeId: '11 12 33.16',
            },
            {
              id: '7ceebdea-2a7d-4aa5-80a2-43d71c7ded8e',
              name: 'Swinging Parking Gates',
              tradeId: '11 12 33.19',
            },
          ],
          tradeId: '11 12 00',
        },
        {
          id: '0bb4ea3a-1900-46c9-9a5c-db88145318d9',
          name: 'Loading Dock Equipment',
          children: [
            {
              id: '9fa98dd1-c670-4399-9923-1f00b61275d7',
              name: 'Loading Dock Equipment',
              tradeId: '11 13 00',
            },
            {
              id: '4c1a8c66-c41b-4411-be36-2c210c46ea49',
              name: 'Loading Dock Bumpers',
              tradeId: '11 13 13',
            },
            {
              id: '65c92f77-d83e-4126-bdde-df4b9546011a',
              name: 'Loading Dock Seals and Shelters',
              tradeId: '11 13 16',
            },
            {
              id: '6870908c-95d1-4c0f-b439-8210177e9bf4',
              name: 'Loading Dock Seals',
              tradeId: '11 13 16.13',
            },
            {
              id: '2097efa4-d087-468c-8bf9-95666147e20d',
              name: 'Loading Dock Shelters',
              tradeId: '11 13 16.23',
            },
            {
              id: '3af17d5a-934f-47a8-aedc-a5eb85f3dd28',
              name: 'Loading Dock Rail Shelters',
              tradeId: '11 13 16.33',
            },
            {
              id: 'f548fba7-a820-43db-858d-f60a1cee9f17',
              name: 'Stationary Loading Dock Equipment',
              tradeId: '11 13 19',
            },
            {
              id: '92f37575-bc3d-41e4-8704-138cdd83b457',
              name: 'Loading Dock Levelers',
              tradeId: '11 13 19.13',
            },
            {
              id: '66c5f6c6-3fa7-4cc4-846b-6281eb263d88',
              name: 'Stationary Loading Dock Lifts',
              tradeId: '11 13 19.23',
            },
            {
              id: '88e92d73-a8b3-494f-b25c-fc4c869296dc',
              name: 'Loading Dock Truck Lifts',
              tradeId: '11 13 19.26',
            },
            {
              id: 'd869b8dd-d553-4bbb-bcfe-4649c38bcedd',
              name: 'Loading Dock Truck Restraints',
              tradeId: '11 13 19.33',
            },
            {
              id: 'c58f9f86-1c1c-4c22-b209-d4ac20909dad',
              name: 'Portable Dock Equipment',
              tradeId: '11 13 23',
            },
            {
              id: '17402eba-cfa1-4ec6-afed-e677c3587cff',
              name: 'Portable Dock Lifts',
              tradeId: '11 13 23.13',
            },
            {
              id: '1cc5b65e-1f3b-481f-98cb-c1ec0cf3bff5',
              name: 'Portable Dock Ramps',
              tradeId: '11 13 23.16',
            },
            {
              id: 'c26efe53-cbae-4a0a-9969-f6ea45a8fbca',
              name: 'Portable Dock Bridges',
              tradeId: '11 13 23.19',
            },
            {
              id: '1007370f-50cf-49cf-89f7-675a858be12a',
              name: 'Portable Dock Platforms',
              tradeId: '11 13 23.23',
            },
            {
              id: '9bc42d66-c49b-44eb-8d50-84db445c5cc0',
              name: 'Loading Dock Lights',
              tradeId: '11 13 26',
            },
          ],
          tradeId: '11 13 00',
        },
        {
          id: '4ac099a4-ee4d-401a-b3d5-e1c572aa1b66',
          name: 'Pedestrian Control Equipment',
          children: [
            {
              id: '2f97c0b0-cc69-42cd-abbf-591a3b30d628',
              name: 'Pedestrian Control Equipment',
              tradeId: '11 14 00',
            },
            {
              id: 'ed684ab5-73a6-4589-8e86-a9c65bbef4df',
              name: 'Pedestrian Gates',
              tradeId: '11 14 13',
            },
            {
              id: '88aaf0dd-e2c2-4e06-9395-7372a7514b90',
              name: 'Portable Posts and Railings',
              tradeId: '11 14 13.13',
            },
            {
              id: '52089dd1-6bfd-4424-ab74-dd2abc72cbf0',
              name: 'Rotary Gates',
              tradeId: '11 14 13.16',
            },
            {
              id: '9a9947ab-6632-4f4e-92d4-577de599a5f4',
              name: 'Turnstiles',
              tradeId: '11 14 13.19',
            },
            {
              id: 'a13ddd99-eba5-4543-bbcb-1502457d5a40',
              name: 'Money-Changing Equipment',
              tradeId: '11 14 16',
            },
            {
              id: '482e389f-6e24-426b-9487-6c1d26253e5a',
              name: 'Money-Changing Machines',
              tradeId: '11 14 16.19',
            },
            {
              id: '20fdd1f9-44e1-432f-a742-2418731b0b41',
              name: 'Pedestrian Fare Collection Equipment',
              tradeId: '11 14 26',
            },
            {
              id: '4569c9a0-b271-412e-bef2-edb1a527d0f7',
              name: 'Pedestrian Coin Fare Collection Equipment',
              tradeId: '11 14 26.13',
            },
            {
              id: 'ef528427-bb20-46de-95fd-0d0608fdfff1',
              name: 'Pedestrian Detection Equipment',
              tradeId: '11 14 43',
            },
            {
              id: 'f27a5115-a3f9-4612-8da2-589207740789',
              name: 'Electronic Detection and Counting Systems',
              tradeId: '11 14 43.13',
            },
            {
              id: '521b183c-0d9d-46ba-a4e8-f17b6ea7b5c2',
              name: 'Pedestrian Security Equipment',
              tradeId: '11 14 53',
            },
          ],
          tradeId: '11 14 00',
        },
        {
          id: 'eb5c0c2d-1ac2-4286-8665-2d4b15addc5f',
          name: 'Security, Detention and Banking Equipment',
          children: [],
          tradeId: '11 15 00',
        },
        {
          id: '1585aa06-230f-411b-bbad-bcdb4f061138',
          name: 'Vault Equipment',
          children: [
            {
              id: '1a0db0bb-6110-4acd-b1cf-25f085226901',
              name: 'Vault Equipment',
              tradeId: '11 16 00',
            },
            {
              id: '4aab32ae-39bc-46e7-9609-5bc44ef0a938',
              name: 'Safe Deposit Boxes',
              tradeId: '11 16 13',
            },
            {
              id: '4d855162-5d9e-464d-8f96-fbd2fa5834fa',
              name: 'Safes',
              tradeId: '11 16 16',
            },
            {
              id: '52091bee-0c23-4d3c-810c-fb2261f59d2d',
              name: 'Vault Ventilators',
              tradeId: '11 16 23',
            },
            {
              id: 'a12c708e-d766-4dc3-b2b5-bc5da3cc3f3e',
              name: 'Time Capsules',
              tradeId: '11 16 33',
            },
          ],
          tradeId: '11 16 00',
        },
        {
          id: 'a8dd6dd5-c7b8-464c-9082-2a34283f330a',
          name: 'Teller and Service Equipment',
          children: [
            {
              id: '7544468e-f12f-4034-8b8d-5f9a58e0b731',
              name: 'Teller and Service Equipment',
              tradeId: '11 17 00',
            },
            {
              id: 'f5c9a476-668e-45d7-8732-e972b187304f',
              name: 'Teller Equipment Systems',
              tradeId: '11 17 13',
            },
            {
              id: 'af86e2de-98e6-4ea2-a674-f140645d02e8',
              name: 'Automatic Banking Systems',
              tradeId: '11 17 16',
            },
            {
              id: 'd17413b0-7efe-48e4-b04b-7bda75fb68c6',
              name: 'Money Handling Equipment',
              tradeId: '11 17 23',
            },
            {
              id: 'f1adc8f4-1119-4a6b-a8d7-16aff2b24fb1',
              name: 'Money Depositories',
              tradeId: '11 17 26',
            },
            {
              id: 'd36af7aa-5790-4969-82b4-adff482c5b56',
              name: 'Money Cart Pass-Through',
              tradeId: '11 17 33',
            },
            {
              id: '4a351733-b88d-4ff9-8736-f2f0fba01366',
              name: 'Package Transfer Units',
              tradeId: '11 17 36',
            },
          ],
          tradeId: '11 17 00',
        },
        {
          id: 'e6e480ad-22d2-42cf-ac9b-da900048abbd',
          name: 'Security Equipment',
          children: [
            {
              id: '02a056f1-c449-4fd4-91a8-8dfb15f8bbdc',
              name: 'Security Equipment',
              tradeId: '11 18 00',
            },
            {
              id: 'b1562791-88eb-44fa-a3eb-f0c654537326',
              name: 'Deal Drawers',
              tradeId: '11 18 13',
            },
            {
              id: '087462f8-edcd-49fa-a526-719bc23e7dbd',
              name: 'Gun Ports',
              tradeId: '11 18 16',
            },
            {
              id: 'a8c0fbcc-251c-4240-b860-57a11ba279d2',
              name: 'Valuable Material Storage',
              tradeId: '11 18 23',
            },
          ],
          tradeId: '11 18 00',
        },
        {
          id: 'dc907cff-bb39-437f-8346-a26a9b7ea905',
          name: 'Detention Equipment',
          children: [
            {
              id: '55470abf-d186-4e72-acad-a178c9d1917b',
              name: 'Detention Equipment',
              tradeId: '11 19 00',
            },
            {
              id: '3fd20c31-23d3-4eaa-a51b-71d45653c82d',
              name: 'Detention Pass-Through Doors',
              tradeId: '11 19 13',
            },
            {
              id: '05a08a56-091d-4207-8e80-5ea5a06693b7',
              name: 'Detention Gun Lockers',
              tradeId: '11 19 16',
            },
          ],
          tradeId: '11 19 00',
        },
        {
          id: 'f5e7415f-72f8-4e33-8983-7420e8bf0a8f',
          name: 'Commercial Equipment',
          children: [],
          tradeId: '11 20 00',
        },
        {
          id: '5e8b5682-2bd9-4832-9e63-056514712f1c',
          name: 'Mercantile and Service Equipment',
          children: [
            {
              id: '4abf705f-0a98-45ea-86e6-1d3e63ab7acd',
              name: 'Mercantile and Service Equipment',
              tradeId: '11 21 00',
            },
            {
              id: 'b207258c-de4f-46b0-a90e-3fd2b4c421fa',
              name: 'Cash Registers and Checking Equipment',
              tradeId: '11 21 13',
            },
            {
              id: 'a0c13278-dcd6-4a90-bd5e-706eed37076c',
              name: 'Vending Equipment',
              tradeId: '11 21 23',
            },
            {
              id: 'c3a6cda0-8e24-4f8f-99c1-29b63b0c616b',
              name: 'Vending Machines',
              tradeId: '11 21 23.13',
            },
            {
              id: '5814d182-b6d2-45dc-ab25-f94448176daa',
              name: 'Checkroom Equipment',
              tradeId: '11 21 33',
            },
            {
              id: '99f44671-fb1a-4b49-bbae-497b9e883656',
              name: 'Weighing and Wrapping Equipment',
              tradeId: '11 21 43',
            },
            {
              id: 'af5ec80f-a9cf-4371-af09-091cf634e4b6',
              name: 'Barber and Beauty Shop Equipment',
              tradeId: '11 21 53',
            },
          ],
          tradeId: '11 21 00',
        },
        {
          id: '34f2119e-982a-4655-9d13-4d42a4c92bc5',
          name: 'Refrigerated Display Equipment',
          children: [],
          tradeId: '11 22 00',
        },
        {
          id: '20746314-3e82-47b8-8e0b-dcbd94531006',
          name: 'Commercial Laundry and Dry Cleaning Equipment',
          children: [
            {
              id: 'ea2c1694-96c3-48fc-8d8d-b2c4d9e44a06',
              name: 'Commercial Laundry and Dry Cleaning Equipment',
              tradeId: '11 23 00',
            },
            {
              id: 'c45ce40c-c5c5-4120-811c-9e401be3cea5',
              name: 'Dry Cleaning Equipment',
              tradeId: '11 23 13',
            },
            {
              id: '6e7ca29d-bd83-4b91-b19a-fad3524046d9',
              name: 'Drying and Conditioning Equipment',
              tradeId: '11 23 16',
            },
            {
              id: '00835b1a-98d6-4b35-8fdd-5f36e22aeb89',
              name: 'Finishing Equipment',
              tradeId: '11 23 19',
            },
            {
              id: '21efb84d-8bb7-4df0-bade-546403702e5f',
              name: 'Commercial Ironing Equipment',
              tradeId: '11 23 23',
            },
            {
              id: '00324fd5-cb4d-484a-9599-f8a35f005fab',
              name: 'Commercial Washers and Extractors',
              tradeId: '11 23 26',
            },
            {
              id: '9b4d5486-4669-4c97-b202-e3f5c61730f4',
              name: 'Coin-Operated Laundry Equipment',
              tradeId: '11 23 33',
            },
            {
              id: '7b7c5c8d-9482-4e75-96bc-03b840d132da',
              name: 'Hanging Garment Conveyors',
              tradeId: '11 23 43',
            },
          ],
          tradeId: '11 23 00',
        },
        {
          id: 'f35d6600-6fbf-486a-8324-07eba623dd80',
          name: 'Maintenance Equipment',
          children: [
            {
              id: '3ab57188-bc4a-46ec-8f41-500d45ecdbd1',
              name: 'Maintenance Equipment',
              tradeId: '11 24 00',
            },
            {
              id: 'efb3c39a-40d1-4c43-97d0-c2e6bb1e4fb4',
              name: 'Floor and Wall Cleaning Equipment',
              tradeId: '11 24 13',
            },
            {
              id: '9ca3a7d2-f045-472e-ac55-494941ab942e',
              name: 'Housekeeping Carts',
              tradeId: '11 24 16',
            },
            {
              id: '39d4b14f-19ad-49dd-bc29-1c691dcf2ae6',
              name: 'Vacuum Cleaning Systems',
              tradeId: '11 24 19',
            },
            {
              id: '16ffcf17-cd83-4116-b675-e4315e667769',
              name: 'Façade Access Equipment',
              tradeId: '11 24 23',
            },
            {
              id: 'ea74681d-05be-4442-9fa9-3b958e270fd4',
              name: 'Window Washing Systems',
              tradeId: '11 24 23.13',
            },
            {
              id: '1e636917-0f3e-41bc-a8db-0d0c22a6e938',
              name: 'Facility Fall Protection',
              tradeId: '11 24 29',
            },
          ],
          tradeId: '11 24 00',
        },
        {
          id: 'ec4742a7-135c-4e92-9787-b02d7d0186c7',
          name: 'Hospitality Equipment',
          children: [
            {
              id: '634bce2c-514a-43ec-8b6d-b418ec7b5881',
              name: 'Hospitality Equipment',
              tradeId: '11 25 00',
            },
            {
              id: '3c85acc7-27b7-48c9-be09-689236bb4f94',
              name: 'Registration Equipment',
              tradeId: '11 25 13',
            },
          ],
          tradeId: '11 25 00',
        },
        {
          id: 'bf1a2886-76a4-444c-8b90-aad56dead64a',
          name: 'Unit Kitchens',
          children: [
            {
              id: 'd0c807cf-0cab-4fb9-93a0-ffb417ed0ee5',
              name: 'Unit Kitchens',
              tradeId: '11 26 00',
            },
            {
              id: 'd11d37a4-8cdd-4efc-85d1-422ddc3ae8dc',
              name: 'Metal Unit Kitchens',
              tradeId: '11 26 13',
            },
            {
              id: 'e6b8ebe7-0c1a-42fd-aeca-21362498e932',
              name: 'Wood Unit Kitchens',
              tradeId: '11 26 16',
            },
            {
              id: '07188dd8-a1f8-4118-b2be-7700e5f64192',
              name: 'Plastic-Laminate-Clad Unit Kitchens',
              tradeId: '11 26 19',
            },
          ],
          tradeId: '11 26 00',
        },
        {
          id: '32cf4886-03f1-48d6-a89d-a25de712e35a',
          name: 'Photographic Processing Equipment',
          children: [
            {
              id: '4b1b6989-520d-4d57-86c8-f2622832d5b8',
              name: 'Photographic Processing Equipment',
              tradeId: '11 27 00',
            },
            {
              id: '2fc04c9b-f3d2-43dd-8a9a-6216fff1b389',
              name: 'Darkroom Processing Equipment',
              tradeId: '11 27 13',
            },
            {
              id: '3abac5ac-1afa-4925-b803-f8e01cc9d3fe',
              name: 'Film Transfer Cabinets',
              tradeId: '11 27 16',
            },
          ],
          tradeId: '11 27 00',
        },
        {
          id: 'e8dece04-a4b3-4a29-b0cc-a2d1a87352e7',
          name: 'Office Equipment',
          children: [
            {
              id: '99e5788a-54e1-4005-adfa-aed84a30dedc',
              name: 'Office Equipment',
              tradeId: '11 28 00',
            },
            {
              id: '1cc008ea-9057-483f-89e8-e03a07679867',
              name: 'Computers',
              tradeId: '11 28 13',
            },
            {
              id: '60ce4dfa-ced3-4863-a42a-304ceaf026dd',
              name: 'Printers',
              tradeId: '11 28 16',
            },
            {
              id: '343a8ccb-9362-468a-9041-af06645255e2',
              name: 'Self-Contained Facsimile Machines',
              tradeId: '11 28 19',
            },
            {
              id: '8e242a6f-4708-4049-baf4-cc1f39493b39',
              name: 'Copiers',
              tradeId: '11 28 23',
            },
          ],
          tradeId: '11 28 00',
        },
        {
          id: '9b3456eb-e7b5-4870-a277-551958d488c2',
          name: 'Postal, Packaging and Shipping Equipment',
          children: [
            {
              id: '92d29f87-49e0-4397-9a03-62d16b4e51ea',
              name: 'Postal, Packaging and Shipping Equipment',
              tradeId: '11 29 00',
            },
            {
              id: '63c5e65a-e0cc-4ca5-aeac-356132458431',
              name: 'Packaging Equipment',
              tradeId: '11 29 23',
            },
            {
              id: '4127dd23-a5e0-408e-bd2d-07491e15a462',
              name: 'Shipping Equipment',
              tradeId: '11 29 33',
            },
            {
              id: '8216426e-3e08-41e5-9f13-53bacb38989a',
              name: 'Postal Equipment',
              tradeId: '11 29 55',
            },
          ],
          tradeId: '11 29 00',
        },
        {
          id: '7398e80e-e984-40b8-b7f6-2e00a6f1e0db',
          name: 'Residential Equipment',
          children: [],
          tradeId: '11 30 00',
        },
        {
          id: '616da4d8-cc34-4e04-8393-c1fc9a7556e3',
          name: 'Residential Appliances',
          children: [
            {
              id: 'fba70a4e-928b-48fe-8304-da2a771a3e0e',
              name: 'Residential Appliances',
              tradeId: '11 31 00',
            },
            {
              id: 'b1a858d2-6435-4b4d-b97f-914f9eafef1e',
              name: 'Residential Kitchen Appliances',
              tradeId: '11 31 13',
            },
            {
              id: 'f43b68ed-76ae-475f-98e2-360b0de20ac8',
              name: 'Residential Laundry Appliances',
              tradeId: '11 31 23',
            },
          ],
          tradeId: '11 31 00',
        },
        {
          id: '65e1eb4d-2429-4602-bfbc-602c48b489ed',
          name: 'Retractable Stairs',
          children: [],
          tradeId: '11 33 00',
        },
        {
          id: 'ebf27790-d676-41c0-931c-85b181dc564b',
          name: 'Residential Ceiling Fans',
          children: [],
          tradeId: '11 34 00',
        },
        {
          id: '24f2209e-c667-437d-a115-ec0e4306c671',
          name: 'Foodservice Equipment',
          children: [],
          tradeId: '11 40 00',
        },
        {
          id: '5886cd25-0bc5-4d0c-b34e-12f5bab35dfb',
          name: 'Foodservice Storage Equipment',
          children: [
            {
              id: '6a93dea2-0e9b-4272-9f62-fbe70e80d016',
              name: 'Foodservice Storage Equipment',
              tradeId: '11 41 00',
            },
            {
              id: '89af9d36-ee61-4623-82a6-c19b954fdf88',
              name: 'Refrigerated Food Storage Cases',
              tradeId: '11 41 13',
            },
            {
              id: 'ae91bdf1-58e4-4918-962a-c23632c35a6a',
              name: 'Walk-In Coolers',
              tradeId: '11 41 23',
            },
            {
              id: '450cb727-2c9b-40d7-b15c-4aa1439d7cfa',
              name: 'Walk-In Freezers',
              tradeId: '11 41 26',
            },
            {
              id: '4d643a5a-b9c2-4817-b4cb-e0f72c796227',
              name: 'Foodservice Shelving',
              tradeId: '11 41 33',
            },
          ],
          tradeId: '11 41 00',
        },
        {
          id: '2c200b4d-689a-4c63-889b-95503287c28e',
          name: 'Food Preparation Equipment',
          children: [
            {
              id: '84b94354-a6c3-4132-ac8a-491b4b81fff1',
              name: 'Food Preparation Equipment',
              tradeId: '11 42 00',
            },
            {
              id: '84a56c93-8f71-4132-a9bb-fdc5cd5edd05',
              name: 'Food Preparation Appliances',
              tradeId: '11 42 13',
            },
            {
              id: 'cdadbaed-fa36-411c-a782-728a04c06308',
              name: 'Food Preparation Surfaces',
              tradeId: '11 42 16',
            },
          ],
          tradeId: '11 42 00',
        },
        {
          id: '63071675-c105-45f2-9866-b2ed601e37a0',
          name: 'Food Delivery Carts and Conveyors',
          children: [
            {
              id: '16b63289-03e0-4963-b5bc-e17ee33bbfbf',
              name: 'Food Delivery Carts and Conveyors',
              tradeId: '11 43 00',
            },
            {
              id: '10f2b3d4-84bf-450c-a052-553c28c2a4de',
              name: 'Food Delivery Carts',
              tradeId: '11 43 13',
            },
            {
              id: 'f907440f-750b-4b3f-a3b0-891bacb9ab38',
              name: 'Food Delivery Conveyors',
              tradeId: '11 43 16',
            },
          ],
          tradeId: '11 43 00',
        },
        {
          id: '4879eefa-1c58-4335-aa3a-cfbf1c35d394',
          name: 'Food Cooking Equipment',
          children: [
            {
              id: '5aaae6bd-9f25-4b03-ada9-823b60e8880e',
              name: 'Food Cooking Equipment',
              tradeId: '11 44 00',
            },
            {
              id: 'd76251d6-d6c2-42f9-bf44-085cd67aa99c',
              name: 'Commercial Ranges',
              tradeId: '11 44 13',
            },
            {
              id: '97e70c3d-39bf-45ba-866e-449562175f63',
              name: 'Commercial Ovens',
              tradeId: '11 44 16',
            },
          ],
          tradeId: '11 44 00',
        },
        {
          id: '85e93c53-c1de-4aab-9b80-3d5a0eeaff3c',
          name: 'Food Dispensing Equipment',
          children: [
            {
              id: 'eaf99e57-c349-4c40-8fee-5374c50e32fe',
              name: 'Food Dispensing Equipment',
              tradeId: '11 46 00',
            },
            {
              id: '5b20ef2d-7864-4eae-b9c5-9f5189889385',
              name: 'Bar Equipment',
              tradeId: '11 46 13',
            },
            {
              id: '07a61e62-a042-4dfb-9853-c2b8c636982f',
              name: 'Service Line Equipment',
              tradeId: '11 46 16',
            },
            {
              id: '0e3d92d4-8381-422e-ad78-195e8a4b4c4b',
              name: 'Soda Fountain Equipment',
              tradeId: '11 46 19',
            },
            {
              id: 'a6a6679c-16f5-4a95-8b20-6ae93248d7ad',
              name: 'Coffee and Espresso Equipment',
              tradeId: '11 46 23',
            },
          ],
          tradeId: '11 46 00',
        },
        {
          id: '859f5f8d-e158-4251-a651-25e5dec9072e',
          name: 'Ice Machines',
          children: [],
          tradeId: '11 47 00',
        },
        {
          id: 'a871454e-28bf-4e47-b113-68b78770d83c',
          name: 'Cleaning and Disposal Equipment',
          children: [
            {
              id: '936c21dd-40b5-4319-b535-2c00739d03f1',
              name: 'Cleaning and Disposal Equipment',
              tradeId: '11 48 00',
            },
            {
              id: '045682e8-06e7-4c24-8cee-1a87a1751f63',
              name: 'Commercial Dishwashers',
              tradeId: '11 48 13',
            },
          ],
          tradeId: '11 48 00',
        },
        {
          id: '80087887-4774-40b0-8556-fca203e4123c',
          name: 'Educational and Scientific Equipment',
          children: [],
          tradeId: '11 50 00',
        },
        {
          id: 'f8441519-2d09-4027-8f41-037154bfd012',
          name: 'Library Equipment',
          children: [
            {
              id: '71b233de-f48b-4718-a108-5e5a05222c7a',
              name: 'Library Equipment',
              tradeId: '11 51 00',
            },
            {
              id: 'b3cf06c6-7401-410c-b0c1-4cac76386e40',
              name: 'Automated Book Storage and Retrieval Systems',
              tradeId: '11 51 13',
            },
            {
              id: 'cb4b8fe3-d945-4637-a57a-1bb9d8fd7bcf',
              name: 'Book Depositories',
              tradeId: '11 51 16',
            },
            {
              id: '63e70f97-883b-4256-b199-55f93285b09f',
              name: 'Book Theft Protection Equipment',
              tradeId: '11 51 19',
            },
            {
              id: '452896dc-cf5c-4e63-b110-a856ae029271',
              name: 'Library Stack Systems',
              tradeId: '11 51 23',
            },
            {
              id: '548b3e58-6c38-4fa3-9e17-e9a081e9d7f6',
              name: 'Metal Library Shelving',
              tradeId: '11 51 23.13',
            },
          ],
          tradeId: '11 51 00',
        },
        {
          id: 'cb38984b-9cbf-41aa-909e-a77c23b73a51',
          name: 'Audio-Visual Equipment',
          children: [
            {
              id: 'ec6ceadc-56c4-4f52-a07f-3cc09a3ef937',
              name: 'Audio-Visual Equipment',
              tradeId: '11 52 00',
            },
            {
              id: '713db8fb-fd39-472f-850d-7f6e4ed416a9',
              name: 'Projection Screens',
              tradeId: '11 52 13',
            },
            {
              id: '2ef2ab86-1b28-4187-805d-59b069dc7439',
              name: 'Fixed Projection Screens',
              tradeId: '11 52 13.13',
            },
            {
              id: 'c332186e-fe9b-4f49-9617-d2460770e760',
              name: 'Portable Projection Screens',
              tradeId: '11 52 13.16',
            },
            {
              id: '9f4a48ad-14b1-42ab-8fce-ed73ad7fe78d',
              name: 'Rear Projection Screens',
              tradeId: '11 52 13.19',
            },
            {
              id: '1fbbb7cb-20e8-4ebe-947b-09c206f32fb8',
              name: 'Projectors',
              tradeId: '11 52 16',
            },
            {
              id: 'b007053b-8c46-443d-a825-0c0b58c7a1fc',
              name: 'Movie Projectors',
              tradeId: '11 52 16.13',
            },
            {
              id: '69eaa4e0-ce99-4dd7-8f09-f81a20114e69',
              name: 'Slide Projectors',
              tradeId: '11 52 16.16',
            },
            {
              id: 'd92b93ca-a9aa-4b94-87bc-154164348d79',
              name: 'Overhead Projectors',
              tradeId: '11 52 16.19',
            },
            {
              id: '15166870-7c54-47c1-b6cf-284e78f15e52',
              name: 'Opaque Projectors',
              tradeId: '11 52 16.23',
            },
            {
              id: '477d2b69-3194-4fcd-8a64-2b77a77ad090',
              name: 'Video Projectors',
              tradeId: '11 52 16.26',
            },
            {
              id: '82c57e7e-877c-4293-8e56-5d9b34ca9967',
              name: 'Players and Recorders',
              tradeId: '11 52 19',
            },
            {
              id: 'b472b64b-f75d-43ec-8f20-0fe65fa3f8b7',
              name: 'Audio-Visual Equipment Supports',
              tradeId: '11 52 23',
            },
          ],
          tradeId: '11 52 00',
        },
        {
          id: 'c6cef027-4dac-41e1-a666-03213baf99a8',
          name: 'Laboratory Equipment',
          children: [
            {
              id: 'e12d4ba3-fe5e-40d2-acdd-d1e4e1742960',
              name: 'Laboratory Equipment',
              tradeId: '11 53 00',
            },
            {
              id: '6ed998bb-965c-40a9-ab5a-f570005ee7c8',
              name: 'Laboratory Fume Hoods',
              tradeId: '11 53 13',
            },
            {
              id: '53d1f8bd-6dcf-4d7f-9d73-6fe2867fc14f',
              name: 'Recirculating Laboratory Fume Hoods',
              tradeId: '11 53 13.13',
            },
            {
              id: '8feb5059-81d1-47ef-9960-f57189ba1910',
              name: 'Laboratory Incubators',
              tradeId: '11 53 16',
            },
            {
              id: '73cc5d0a-a56c-4a01-875b-f8cee49480d6',
              name: 'Laboratory Equipment Washers',
              tradeId: '11 53 17',
            },
            {
              id: '0dbc8c85-d051-4d40-8254-4b51d5a9e32e',
              name: 'Laboratory Sterilizers',
              tradeId: '11 53 19',
            },
            {
              id: '377541aa-78cf-4edd-a861-9850601102e4',
              name: 'Laboratory Refrigerators',
              tradeId: '11 53 23',
            },
            {
              id: '9cd032cd-da7f-4115-9a33-8233abf99f77',
              name: 'Laboratory Freezers',
              tradeId: '11 53 26',
            },
            {
              id: '3be3712c-0714-473f-96c4-2e885aa1f1fb',
              name: 'Laboratory Controlled-Environment Cabinets',
              tradeId: '11 53 29',
            },
            {
              id: 'cce26560-ec76-4f3c-8057-77c086c046e0',
              name: 'Emergency Safety Appliances',
              tradeId: '11 53 33',
            },
            {
              id: '93d1fb79-0718-434a-a648-390f54c2473b',
              name: 'Service Fittings and Accessories',
              tradeId: '11 53 43',
            },
            {
              id: 'e7f7456f-51e2-472c-b2db-d027e8876eec',
              name: 'Biological Safety Cabinets',
              tradeId: '11 53 53',
            },
          ],
          tradeId: '11 53 00',
        },
        {
          id: '5839bd6b-f80d-49c4-be99-ef2f8fe1ce0a',
          name: 'Planetarium Equipment',
          children: [
            {
              id: '1c8cf91e-b9b7-46fb-9629-85e2bf73ad91',
              name: 'Planetarium Equipment',
              tradeId: '11 55 00',
            },
            {
              id: '87dac797-24de-4164-86d7-7eefc55f1a82',
              name: 'Planetarium Projectors',
              tradeId: '11 55 13',
            },
            {
              id: 'c0cec608-4cfa-4baf-b03f-56893c80c1b5',
              name: 'Planetarium Pendulums',
              tradeId: '11 55 16',
            },
          ],
          tradeId: '11 55 00',
        },
        {
          id: '979389c5-cc4e-4cdf-a2a4-33206ce5911a',
          name: 'Observatory Equipment',
          children: [
            {
              id: '546c5ff3-d01e-4219-8e80-7b3a12bce45f',
              name: 'Observatory Equipment',
              tradeId: '11 56 00',
            },
            {
              id: '141c56ab-9d09-46b1-8b12-893c829ef205',
              name: 'Telescopes',
              tradeId: '11 56 13',
            },
            {
              id: 'acbeca40-efdb-4be0-af2c-03e46e4c9093',
              name: 'Telescope Mounts',
              tradeId: '11 56 16',
            },
            {
              id: '8391f523-2c9b-4aa9-9de6-a00fe590a03c',
              name: 'Telescope Drive Mechanisms',
              tradeId: '11 56 19',
            },
            {
              id: '2a49be28-c9aa-45a5-b836-774d9ef82195',
              name: 'Telescope Domes',
              tradeId: '11 56 23',
            },
          ],
          tradeId: '11 56 00',
        },
        {
          id: '00439f2d-6daa-439b-9149-b35d51ed7a9b',
          name: 'Vocational Shop Equipment',
          children: [],
          tradeId: '11 57 00',
        },
        {
          id: 'b6e7d20b-2678-4d94-966c-4e4fd198ac24',
          name: 'Exhibit Equipment',
          children: [],
          tradeId: '11 59 00',
        },
        {
          id: 'e337eede-0d0f-406e-927c-b8230de57654',
          name: 'Entertainment Equipment',
          children: [],
          tradeId: '11 60 00',
        },
        {
          id: '7d4b6946-7cac-4017-827e-2b220ff572c9',
          name: 'Broadcast, Theater and Stage Equipment',
          children: [
            {
              id: 'adbec075-c5c7-4bbe-986d-d76649d3c9f8',
              name: 'Broadcast, Theater and Stage Equipment',
              tradeId: '11 61 00',
            },
            {
              id: '11badf12-3fdb-4215-977a-1c56d2288938',
              name: 'Acoustical Shells',
              tradeId: '11 61 13',
            },
            {
              id: '344ba095-c8df-4fcd-a32e-6a0b85cf615a',
              name: 'Folding and Portable Stages',
              tradeId: '11 61 23',
            },
            {
              id: '7dc568bd-1117-4919-a8c1-0bd79344c228',
              name: 'Rigging Systems and Controls',
              tradeId: '11 61 33',
            },
            {
              id: 'ac68a61d-f1cd-4c4f-a23f-5cfd1b4c93e6',
              name: 'Lighting Rigging Systems',
              tradeId: '11 61 33.11',
            },
            {
              id: '187659a9-3bea-4ac8-a59d-00b352e5c66f',
              name: 'Scenery Rigging Systems',
              tradeId: '11 61 33.16',
            },
            {
              id: 'd978acb7-690e-4da1-8867-f228ad31f6df',
              name: 'Curtain Systems',
              tradeId: '11 61 33.19',
            },
            {
              id: '0e0955e8-f206-4dbd-8019-b3dc273b0001',
              name: 'Stage Curtains',
              tradeId: '11 61 43',
            },
          ],
          tradeId: '11 61 00',
        },
        {
          id: '7a7fea13-b59c-4983-be47-4d236b714012',
          name: 'Musical Equipment',
          children: [
            {
              id: '05826bcf-0241-43e3-9ec5-decbc3d3a290',
              name: 'Musical Equipment',
              tradeId: '11 62 00',
            },
            {
              id: '3e973f2d-d852-42c6-9b53-0438ed6423a7',
              name: 'Bells',
              tradeId: '11 62 13',
            },
            {
              id: '71db08d7-dc5d-4062-ad6b-fb29bf92b508',
              name: 'Carillons',
              tradeId: '11 62 16',
            },
            {
              id: 'ed1fd7f3-00d4-4299-9be0-98952436cad8',
              name: 'Organs',
              tradeId: '11 62 19',
            },
          ],
          tradeId: '11 62 00',
        },
        {
          id: 'a7c970f4-224c-4725-86b3-367baece5f8b',
          name: 'Athletic and Recreational Equipment',
          children: [],
          tradeId: '11 65 00',
        },
        {
          id: 'fac391d1-5d78-4b9d-a37d-c91fbe4d2dbd',
          name: 'Athletic Equipment',
          children: [
            {
              id: '019ddf91-c52d-4608-ae06-306ca6ebb7e9',
              name: 'Athletic Equipment',
              tradeId: '11 66 00',
            },
            {
              id: '3060e611-03b8-401b-a848-d0229c0a6576',
              name: 'Exercise Equipment',
              tradeId: '11 66 13',
            },
            {
              id: 'cce3b69a-c0c1-48f1-a834-65e0bd20a25b',
              name: 'Gymnasium Equipment',
              tradeId: '11 66 23',
            },
            {
              id: 'f343a4aa-13fe-4604-afaa-ec4be03b3aa3',
              name: 'Basketball Equipment',
              tradeId: '11 66 23.13',
            },
            {
              id: '9bdda92a-b53a-4ffe-85d4-36b8b0ed84fd',
              name: 'Volleyball Equipment',
              tradeId: '11 66 23.23',
            },
            {
              id: '8c358bdf-04d9-4eec-98ec-13164987b38f',
              name: 'Interior Tennis Equipment',
              tradeId: '11 66 23.33',
            },
            {
              id: '020a7bec-876e-48ad-9157-3c8ea43ff932',
              name: 'Interior Track and Field Equipment',
              tradeId: '11 66 23.43',
            },
            {
              id: '4c1e5ddd-2080-4602-92e6-8cb466596f55',
              name: 'Wall Padding',
              tradeId: '11 66 23.53',
            },
            {
              id: '06b59667-7bbf-46c7-82ed-b0e6b930db79',
              name: 'Mat Storage',
              tradeId: '11 66 23.56',
            },
            {
              id: 'adccd128-f68c-43a1-9efa-ecb8a8d6f1ea',
              name: 'Interior Scoreboards',
              tradeId: '11 66 43',
            },
            {
              id: '6da86322-d787-47b8-936f-ebcb89c55d82',
              name: 'Gymnasium Dividers',
              tradeId: '11 66 53',
            },
            {
              id: '4348fc2c-3461-4d29-95fc-fc1ac38bfa22',
              name: 'Batting/Golf Cages',
              tradeId: '11 66 53.13',
            },
          ],
          tradeId: '11 66 00',
        },
        {
          id: 'ea462b60-9d42-49aa-bdaf-8c781a83833f',
          name: 'Recreational Equipment',
          children: [
            {
              id: 'b87ec481-bbb5-4fa3-a250-09046bbf6f01',
              name: 'Recreational Equipment',
              tradeId: '11 67 00',
            },
            {
              id: 'aad12d2c-4e10-488f-9aaa-e643549c6de2',
              name: 'Bowling Alley Equipment',
              tradeId: '11 67 13',
            },
            {
              id: '933498fd-ef04-4213-bb17-d84caa68f6c0',
              name: 'Shooting Range Equipment',
              tradeId: '11 67 23',
            },
            {
              id: '2629238b-b7b5-4541-b935-a31348435b05',
              name: 'Climbing Walls',
              tradeId: '11 67 33',
            },
            {
              id: '7dc0ae13-6790-4c40-9027-57b37e9ed46a',
              name: 'Table Games Equipment',
              tradeId: '11 67 43',
            },
            {
              id: '82146ba6-f890-42b2-996c-5c49fb0b7306',
              name: 'Pool Tables',
              tradeId: '11 67 43.13',
            },
            {
              id: 'c0355d79-4ede-4928-a10e-cde34b2a60cf',
              name: 'Ping-Pong Tables',
              tradeId: '11 67 43.23',
            },
            {
              id: '61c3a2d3-eab6-45bf-8116-1a075b5efe82',
              name: 'Game Room Equipment',
              tradeId: '11 67 53',
            },
            {
              id: '83681022-7b66-424e-873c-ce00ed361307',
              name: 'Video Games',
              tradeId: '11 67 53.13',
            },
            {
              id: '15a2c944-2e23-422e-ac67-a88ca902d287',
              name: 'Pinball Machines',
              tradeId: '11 67 53.23',
            },
          ],
          tradeId: '11 67 00',
        },
        {
          id: 'd18d8695-affe-4ab7-afe6-a5f1b2aaac27',
          name: 'Play Field Equipment and Structures',
          children: [
            {
              id: '95f510f2-2787-4642-acb3-039db931b664',
              name: 'Play Field Equipment and Structures',
              tradeId: '11 68 00',
            },
            {
              id: 'c19c41f7-f7eb-4012-80c0-913f247775c0',
              name: 'Playground Equipment',
              tradeId: '11 68 13',
            },
            {
              id: 'e79403b8-24c2-4768-aa29-2b35f3be2819',
              name: 'Play Structures',
              tradeId: '11 68 16',
            },
            {
              id: '0fa5aa87-0974-445f-af21-9bb09cc57b60',
              name: 'Exterior Court Athletic Equipment',
              tradeId: '11 68 23',
            },
            {
              id: '80193ff5-04f8-47f8-a311-d15996e03299',
              name: 'Exterior Basketball Equipment',
              tradeId: '11 68 23.13',
            },
            {
              id: 'f4b1d70a-ffc2-4475-986e-e96f9503d68a',
              name: 'Exterior Volleyball Equipment',
              tradeId: '11 68 23.23',
            },
            {
              id: '3193a02a-5e8e-46ac-9ccf-275c6aaa3e4a',
              name: 'Tennis Equipment',
              tradeId: '11 68 23.33',
            },
            {
              id: '204ba19f-cc07-4fe3-b516-6939fb089f6e',
              name: 'Athletic Field Equipment',
              tradeId: '11 68 33',
            },
            {
              id: '635e6b60-daea-4a76-b358-79ef714a63fd',
              name: 'Football Field Equipment',
              tradeId: '11 68 33.13',
            },
            {
              id: '43c9e8ff-0262-443f-a06c-54b6607087ef',
              name: 'Soccer and Field Hockey Equipment',
              tradeId: '11 68 33.23',
            },
            {
              id: '4d262659-25ee-4cd6-a0b4-8cc57bf8febb',
              name: 'Baseball Field Equipment',
              tradeId: '11 68 33.33',
            },
            {
              id: '630d58c9-bdf2-4a4b-8f33-5ed0157d7124',
              name: 'Track and Field Equipment',
              tradeId: '11 68 33.43',
            },
            {
              id: '284da975-2b40-41fe-baf1-b25c77bd9c97',
              name: 'Exterior Scoreboards',
              tradeId: '11 68 43',
            },
          ],
          tradeId: '11 68 00',
        },
        {
          id: 'c8aeeb25-6107-453e-a8ba-cbf2639953b4',
          name: 'Healthcare Equipment',
          children: [],
          tradeId: '11 70 00',
        },
        {
          id: '20f16dc1-c170-4b5b-b366-cf312e647468',
          name: 'Medical Sterilizing Equipment',
          children: [],
          tradeId: '11 71 00',
        },
        {
          id: '9a8f266f-323d-4cba-a3ae-9dced727f8c3',
          name: 'Examination and Treatment Equipment',
          children: [
            {
              id: '5ac6c75b-e4fc-4735-acde-fde0e685c59d',
              name: 'Examination and Treatment Equipment',
              tradeId: '11 72 00',
            },
            {
              id: '14f6c563-3bc1-4527-be14-de42acfd2843',
              name: 'Examination Equipment',
              tradeId: '11 72 13',
            },
            {
              id: 'b293d869-4dda-40d2-b3db-bc22b786e7b7',
              name: 'Treatment Equipment',
              tradeId: '11 72 53',
            },
          ],
          tradeId: '11 72 00',
        },
        {
          id: '2a2477f3-a49b-41df-b24b-8f86be54f839',
          name: 'Patient Care Equipment',
          children: [],
          tradeId: '11 73 00',
        },
        {
          id: 'af88148e-0a60-40aa-9ab7-97404582279d',
          name: 'Dental Equipment',
          children: [],
          tradeId: '11 74 00',
        },
        {
          id: '2ab59e7d-db32-4de2-b032-1168e75c8783',
          name: 'Optical Equipment',
          children: [],
          tradeId: '11 75 00',
        },
        {
          id: 'd2b9b95e-a733-45af-bf27-d403c65ef15a',
          name: 'Operating Room Equipment',
          children: [],
          tradeId: '11 76 00',
        },
        {
          id: '4248cf16-4bdd-43b9-9cb0-60c67570f0cb',
          name: 'Radiology Equipment',
          children: [],
          tradeId: '11 77 00',
        },
        {
          id: '6583fc1c-851c-462d-b87f-74e95937bf1e',
          name: 'Mortuary Equipment',
          children: [
            {
              id: '6c0d75cd-5780-4952-b81f-0d6a68b2c03f',
              name: 'Mortuary Equipment',
              tradeId: '11 78 00',
            },
            {
              id: 'ecb85aec-deb5-4e8e-8d9f-88106424711c',
              name: 'Mortuary Refrigerators',
              tradeId: '11 78 13',
            },
            {
              id: 'a657cd72-115b-47af-a0b0-9a39fa67f57d',
              name: 'Crematorium Equipment',
              tradeId: '11 78 16',
            },
            {
              id: 'bf4797c2-956c-47e2-9f2e-a78311572d38',
              name: 'Mortuary Lifts',
              tradeId: '11 78 19',
            },
          ],
          tradeId: '11 78 00',
        },
        {
          id: '21b06b49-6066-4f1b-abcd-4c86b3fc31ac',
          name: 'Therapy Equipment',
          children: [],
          tradeId: '11 79 00',
        },
        {
          id: 'ccf78752-2ab2-4396-a979-2ee4f949d58f',
          name: 'Collection and Disposal Equipment',
          children: [],
          tradeId: '11 80 00',
        },
        {
          id: 'ba1ce888-0304-453c-9d2c-463136414cec',
          name: 'Solid Waste Handling Equipment',
          children: [
            {
              id: 'e3e20b75-8275-48e7-a663-d2ea9f1fff23',
              name: 'Solid Waste Handling Equipment',
              tradeId: '11 82 00',
            },
            {
              id: 'df04b33b-ea78-4f54-b066-0f75ff8d8cef',
              name: 'Solid Waste Bins',
              tradeId: '11 82 13',
            },
            {
              id: 'a6a47687-3616-4ff9-bd4f-85844c4cd605',
              name: 'Packaged Incinerators',
              tradeId: '11 82 19',
            },
            {
              id: '32b63df8-4458-49e9-94d0-89104d5ec326',
              name: 'Recycling Equipment',
              tradeId: '11 82 23',
            },
            {
              id: 'a9c93a19-5df5-4230-b2d4-bbecabb3f27b',
              name: 'Facility Waste Compactors',
              tradeId: '11 82 26',
            },
            {
              id: '4c4441c7-e269-41a8-ae7b-c9c83c6e9889',
              name: 'Composting Equipment',
              tradeId: '11 82 29',
            },
            {
              id: 'f0a8f405-7599-4621-a692-7034bdf63490',
              name: 'Facility Waste Shredders',
              tradeId: '11 82 33',
            },
            {
              id: 'e0d44bc8-2e58-4eab-8f9f-ad8b72469805',
              name: 'Facility Waste Balers',
              tradeId: '11 82 36',
            },
          ],
          tradeId: '11 82 00',
        },
        {
          id: 'c797c28c-27be-4d4d-a167-866a64743427',
          name: 'Other Equipment',
          children: [],
          tradeId: '11 90 00',
        },
        {
          id: 'f6b1de3a-9d05-43ad-86fb-846af1bfd57e',
          name: 'Religious Equipment',
          children: [
            {
              id: 'eeeff395-6fcd-40e4-bad8-4125d87dcea6',
              name: 'Religious Equipment',
              tradeId: '11 91 00',
            },
            {
              id: '21a8327c-dbc5-4dfe-80ad-648302cf8325',
              name: 'Baptisteries',
              tradeId: '11 91 13',
            },
          ],
          tradeId: '11 91 00',
        },
        {
          id: 'c61b3fa4-73a2-4760-941c-4fa67fa4e4ab',
          name: 'Agricultural Equipment',
          children: [
            {
              id: '09d11ad8-3a6c-4d4e-a809-04b814c82cd3',
              name: 'Agricultural Equipment',
              tradeId: '11 92 00',
            },
            {
              id: 'f2cf73e1-366b-49ab-88e7-78d34029a1da',
              name: 'Milkers',
              tradeId: '11 92 13',
            },
            {
              id: 'b16500cf-4d57-48ca-9193-178cbfa1c198',
              name: 'Stock Feeders',
              tradeId: '11 92 16',
            },
            {
              id: '05305757-7016-47bb-88c3-474211d9add1',
              name: 'Stock Waterers',
              tradeId: '11 92 19',
            },
            {
              id: '4096c99a-83eb-41bf-a645-ab2ae15026c7',
              name: 'Agricultural Waste Clean-Up Equipment',
              tradeId: '11 92 23',
            },
          ],
          tradeId: '11 92 00',
        },
        {
          id: 'bd11f3be-0f7e-4719-831b-cbefadde441c',
          name: 'Horticultural Equipment',
          children: [
            {
              id: '5b66702a-1b54-4247-8594-121fbaa8c6be',
              name: 'Horticultural Equipment',
              tradeId: '11 93 00',
            },
            {
              id: '69216e5f-694d-4bcc-bf3b-f0e133fbb5a4',
              name: 'Hydroponic Growing Systems',
              tradeId: '11 93 13',
            },
            {
              id: '122c5c04-a9ef-49b3-8be2-5a4e773ddf37',
              name: 'Seeders',
              tradeId: '11 93 16',
            },
            {
              id: 'aa1b4271-2744-4944-a23c-481b60e97699',
              name: 'Transplanters',
              tradeId: '11 93 19',
            },
            {
              id: '5c14b358-f2dd-4cc1-8320-b470a441c3f0',
              name: 'Potting Machines',
              tradeId: '11 93 23',
            },
            {
              id: 'e76a498e-2755-4370-96b7-35527f3fbaaf',
              name: 'Flat Fillers',
              tradeId: '11 93 26',
            },
            {
              id: '3c78af7f-8281-4fba-a966-e391ada7a533',
              name: 'Baggers',
              tradeId: '11 93 29',
            },
            {
              id: '2be5f083-4422-4543-a9db-36ec8c16803d',
              name: 'Soil Mixers',
              tradeId: '11 93 33',
            },
          ],
          tradeId: '11 93 00',
        },
        {
          id: '60908edf-1323-40af-adef-9938f186cdf6',
          name: 'Arts and Crafts Equipment',
          children: [
            {
              id: '0a5c8e7a-c3e2-45b9-af7f-a39b43e69f41',
              name: 'Arts and Crafts Equipment',
              tradeId: '11 95 00',
            },
            {
              id: 'a0939668-1671-4b8e-a01e-2ed4f3f3daef',
              name: 'Kilns',
              tradeId: '11 95 13',
            },
          ],
          tradeId: '11 95 00',
        },
      ],
      tradeId: '11 00 00',
    },
    {
      id: '356091a1-9657-4882-b280-098a601b0a77',
      name: 'Furnishings',
      children: [
        {
          id: 'ae2e4b20-cedd-4c92-b355-e2d1eaaa5d99',
          name: 'Furnishings',
          children: [],
          tradeId: '12 00 00',
        },
        {
          id: '99bfa16f-7dd7-4821-845f-7634f67467eb',
          name: 'Operation and Maintenance of Furnishings',
          children: [
            {
              id: 'de227371-a5af-41c7-981d-3795c44e35a2',
              name: 'Operation and Maintenance of Furnishings',
              tradeId: '12 01 00',
            },
            {
              id: '73340cdf-ef04-476d-83c5-6d75943cd0e0',
              name: 'Operation and Maintenance of Art',
              tradeId: '12 01 10',
            },
            {
              id: 'a6d039c3-2718-49fe-ba1a-0993eb2c0c84',
              name: 'Operation and Maintenance of Window Treatments',
              tradeId: '12 01 20',
            },
            {
              id: '10932e22-f43b-446d-b9cc-e669b9ae2581',
              name: 'Operation and Maintenance of Casework',
              tradeId: '12 01 30',
            },
            {
              id: '6f660e99-9954-4eb3-9332-33ac7a2dcc6c',
              name: 'Operation and Maintenance of Furnishings and Accessories',
              tradeId: '12 01 40',
            },
            {
              id: '168dce6f-b47f-4a1e-9f45-4499ba915337',
              name: 'Operation and Maintenance of Furniture',
              tradeId: '12 01 50',
            },
            {
              id: '4bbec2d2-45d9-467c-b5ae-7e75300c3a11',
              name: 'Operation and Maintenance of Multiple Seating',
              tradeId: '12 01 60',
            },
            {
              id: '13beb420-b2ca-452c-ad53-5f2711083cac',
              name: 'Operation and Maintenance of Other Furnishings',
              tradeId: '12 01 90',
            },
          ],
          tradeId: '12 01 00',
        },
        {
          id: 'bc9de941-d191-4719-9ca5-bddba723fc2c',
          name: 'Common Work Results for Furnishings',
          children: [
            {
              id: 'a40e65e2-c983-4ab1-b095-aa9d28ebf18d',
              name: 'Common Work Results for Furnishings',
              tradeId: '12 05 00',
            },
            {
              id: '6c2e18a9-0603-438b-a479-8cee6f3fddec',
              name: 'Fabrics',
              tradeId: '12 05 13',
            },
          ],
          tradeId: '12 05 00',
        },
        {
          id: '1b55862d-bd5e-4045-a65a-3c88f7fe88ad',
          name: 'Schedules for Furnishings',
          children: [
            {
              id: '89679488-0e4a-45ae-bdee-e6d4bbcccc2d',
              name: 'Schedules for Furnishings',
              tradeId: '12 06 00',
            },
            {
              id: 'd5934f08-7927-4375-8cfc-a21292218f4b',
              name: 'Schedules for Art',
              tradeId: '12 06 10',
            },
            {
              id: 'de096a4b-9412-4882-b7d8-dfa4a263adf3',
              name: 'Schedules for Window Treatments',
              tradeId: '12 06 20',
            },
            {
              id: '581a103e-3807-4c40-be78-3cf046f89e25',
              name: 'Window Treatment Schedule',
              tradeId: '12 06 20.13',
            },
            {
              id: 'd9e33e72-c712-4493-a379-908b695d09b1',
              name: 'Schedules for Casework',
              tradeId: '12 06 30',
            },
            {
              id: '647f2773-92d8-4e19-8d36-9cd2a3128632',
              name: 'Manufactured Casework Schedule',
              tradeId: '12 06 30.13',
            },
            {
              id: 'ac3a46b7-14da-48bc-8701-efa7cf006709',
              name: 'Schedules for Furnishings and Accessories',
              tradeId: '12 06 40',
            },
            {
              id: '344a2020-14c1-4cd7-b7a0-9a5a4f7b1b29',
              name: 'Furnishings Schedule',
              tradeId: '12 06 40.13',
            },
            {
              id: 'e0aa45d4-dda9-45b2-a6b6-117c0d7eb470',
              name: 'Schedules for Furniture',
              tradeId: '12 06 50',
            },
            {
              id: '3a21486e-7261-44c3-b408-9b494aef84c1',
              name: 'Schedules for Multiple Seating',
              tradeId: '12 06 60',
            },
            {
              id: '61be5faa-a13e-403f-a962-cea4dca01db8',
              name: 'Schedules for Other Furnishings',
              tradeId: '12 06 90',
            },
          ],
          tradeId: '12 06 00',
        },
        {
          id: '87a4483c-52ce-4359-a6c3-047b774bee87',
          name: 'Commissioning of Furnishings',
          children: [],
          tradeId: '12 08 00',
        },
        {
          id: '04d6b9c0-9150-44bd-b774-8145fa37bc4a',
          name: 'Art',
          children: [],
          tradeId: '12 10 00',
        },
        {
          id: '198dd8e0-26fe-4ef0-b7e5-eddfd4502e68',
          name: 'Murals',
          children: [
            {
              id: '14719f91-5b50-43ce-b59a-402de32df05b',
              name: 'Murals',
              tradeId: '12 11 00',
            },
            {
              id: '0baa6cf1-f951-4aca-b11d-0886cac563a9',
              name: 'Photo Murals',
              tradeId: '12 11 13',
            },
            {
              id: 'dc0d7aeb-43c1-482b-9587-6a2f88a6bd5c',
              name: 'Sculptured Brick Panels',
              tradeId: '12 11 16',
            },
            {
              id: '5a985d35-9770-4a76-8e9a-2751916964ac',
              name: 'Brick Murals',
              tradeId: '12 11 23',
            },
            {
              id: 'b4f6fc86-0e11-4266-8d3f-de543f0a1306',
              name: 'Ceramic Tile Murals',
              tradeId: '12 11 26',
            },
            {
              id: 'e62c523a-d888-4fe1-a2e4-d12245dabdad',
              name: "Trompe l'oeil",
              tradeId: '12 11 33',
            },
          ],
          tradeId: '12 11 00',
        },
        {
          id: '1f97d60f-d9f4-4f1b-911f-ecca3ef60737',
          name: 'Wall Decorations',
          children: [
            {
              id: '928be23a-373f-4f10-bf5d-f32436f5e74d',
              name: 'Wall Decorations',
              tradeId: '12 12 00',
            },
            {
              id: '2e97b10a-a2dd-46df-91ce-7b6d33348457',
              name: 'Commissioned Paintings',
              tradeId: '12 12 13',
            },
            {
              id: '6aac53ad-74c2-4ad0-b340-986d73e05eea',
              name: 'Framed Paintings',
              tradeId: '12 12 16',
            },
            {
              id: '9040dbb5-2df8-417c-a649-a021605df84f',
              name: 'Framed Prints',
              tradeId: '12 12 19',
            },
            {
              id: '2e1ee683-10ff-475b-8926-07b61ba692cc',
              name: 'Tapestries',
              tradeId: '12 12 23',
            },
            {
              id: '3683030e-9f17-44ae-b329-8ba37499dbf4',
              name: 'Wall Hangings',
              tradeId: '12 12 26',
            },
          ],
          tradeId: '12 12 00',
        },
        {
          id: '28ba8c1d-b4fe-4d5a-a7f0-d275d9f509f8',
          name: 'Sculptures',
          children: [
            {
              id: '891de11f-c3a4-4fdb-b803-e1861b80e8b2',
              name: 'Sculptures',
              tradeId: '12 14 00',
            },
            {
              id: '95907cfc-95f6-49eb-9f92-a9a97468e022',
              name: 'Carved Sculpture',
              tradeId: '12 14 13',
            },
            {
              id: '3f140245-384d-49ad-9258-76bc4ffb27f1',
              name: 'Cast Sculpture',
              tradeId: '12 14 16',
            },
            {
              id: '8b580e21-4635-4c31-99c8-c8513aefc818',
              name: 'Constructed Sculpture',
              tradeId: '12 14 19',
            },
            {
              id: 'a421cf1f-b06c-416a-b037-5b2dfe6ab3bf',
              name: 'Relief Art',
              tradeId: '12 14 23',
            },
          ],
          tradeId: '12 14 00',
        },
        {
          id: 'c59a9f1a-fcf1-42b2-9a8e-57a8d82dd8cb',
          name: 'Art Glass',
          children: [
            {
              id: '6953e93f-53df-4f3e-8379-383a07728de3',
              name: 'Art Glass',
              tradeId: '12 17 00',
            },
            {
              id: 'b677deed-8f9e-443e-a7fc-04d6770264d2',
              name: 'Etched Glass',
              tradeId: '12 17 13',
            },
            {
              id: '11984b74-2d9b-4ebd-b926-88bef2959637',
              name: 'Stained Glass',
              tradeId: '12 17 16',
            },
          ],
          tradeId: '12 17 00',
        },
        {
          id: '7a91eb0a-3551-43a2-9d84-966c3f8af796',
          name: 'Religious Art',
          children: [],
          tradeId: '12 19 00',
        },
        {
          id: 'c6610f14-d339-492a-8493-10d2011d8ef9',
          name: 'Window Treatments',
          children: [],
          tradeId: '12 20 00',
        },
        {
          id: '1c7f9395-c313-4046-9a4e-c80bc32c82fb',
          name: 'Window Blinds',
          children: [
            {
              id: '558c0558-0582-4cf0-bd10-2e24987d0a21',
              name: 'Window Blinds',
              tradeId: '12 21 00',
            },
            {
              id: '8d423045-98b8-4d6d-ae9c-2041db5b4ef2',
              name: 'Horizontal Louver Blinds',
              tradeId: '12 21 13',
            },
            {
              id: '3b732ee4-9c8d-4505-b6a5-4b47a2ed410d',
              name: 'Metal Horizontal Louver Blinds',
              tradeId: '12 21 13.13',
            },
            {
              id: '2d7030ad-0878-4399-9891-7609fcf6e3be',
              name: 'Wood Horizontal Louver Blinds',
              tradeId: '12 21 13.23',
            },
            {
              id: 'a9a05b3d-2a4d-4f0d-88a2-b0f5c6c773c7',
              name: 'Plastic Horizontal Louver Blinds',
              tradeId: '12 21 13.33',
            },
            {
              id: '95e4c3d2-8df0-428e-a31c-e7da6d403ef1',
              name: 'Vertical Louver Blinds',
              tradeId: '12 21 16',
            },
            {
              id: '97999d88-5fb9-4565-88c9-5159a981490f',
              name: 'Metal Vertical Louver Blinds',
              tradeId: '12 21 16.13',
            },
            {
              id: '16ae05c8-15a0-40bf-915b-674d8ececd2a',
              name: 'Wood Vertical Louver Blinds',
              tradeId: '12 21 16.23',
            },
            {
              id: 'af727bea-22ca-4b0f-b97e-4f2797117a0b',
              name: 'Plastic Vertical Louver Blinds',
              tradeId: '12 21 16.33',
            },
            {
              id: '842fba7f-d6fe-4ae7-90bf-f9dc6cc32bc5',
              name: 'Roll-Down Blinds',
              tradeId: '12 21 23',
            },
            {
              id: '5f969820-de77-47b3-b9ad-1f7f7ed5fdb9',
              name: 'Black-Out Blinds',
              tradeId: '12 21 26',
            },
          ],
          tradeId: '12 21 00',
        },
        {
          id: 'd32cc41e-421d-4ae7-8b4b-22d314605c03',
          name: 'Curtains and Drapes',
          children: [
            {
              id: 'e1c2e77c-a681-4650-864c-c6caa688f594',
              name: 'Curtains and Drapes',
              tradeId: '12 22 00',
            },
            {
              id: '981ea94c-3430-48d2-a60f-4625746553e7',
              name: 'Draperies',
              tradeId: '12 22 13',
            },
            {
              id: '3ea5a3f0-a2c1-4c74-ae37-5f09af9ba5fe',
              name: 'Drapery Track and Accessories',
              tradeId: '12 22 16',
            },
          ],
          tradeId: '12 22 00',
        },
        {
          id: '60a6f5e8-47a2-4c3f-8d67-12327ccef485',
          name: 'Interior Shutters',
          children: [
            {
              id: 'c5326ad0-9fdd-44e1-a735-8375bc8f24b0',
              name: 'Interior Shutters',
              tradeId: '12 23 00',
            },
            {
              id: 'ca39b1bb-9420-42ce-8b4b-afbc15698e64',
              name: 'Wood Interior Shutters',
              tradeId: '12 23 13',
            },
          ],
          tradeId: '12 23 00',
        },
        {
          id: '0f9c2211-1bde-400c-a9f4-b38b043af1de',
          name: 'Window Shades',
          children: [
            {
              id: '97d70c6a-6fbd-423a-808f-9cbb8e16a5df',
              name: 'Window Shades',
              tradeId: '12 24 00',
            },
            {
              id: '9643eec7-c14a-4fcc-b3e4-f997c202ece7',
              name: 'Roller Window Shades',
              tradeId: '12 24 13',
            },
            {
              id: '2ef5266f-e5cf-493b-bf29-6b1e7cf2247e',
              name: 'Pleated Window Shades',
              tradeId: '12 24 16',
            },
            {
              id: 'd858fcc6-746c-460a-ae84-3a127d7f09a8',
              name: 'Z-Pleated Window Shades',
              tradeId: '12 24 16.13',
            },
            {
              id: 'b9ba0d57-a4f6-45d9-be21-463257fb0ba1',
              name: 'Cellular Shades',
              tradeId: '12 24 16.16',
            },
            {
              id: '3943e31e-25fa-48de-ae99-8be3c87a3ffd',
              name: 'Roman Shades',
              tradeId: '12 24 16.19',
            },
          ],
          tradeId: '12 24 00',
        },
        {
          id: '6580ea04-06f7-4a57-ae96-fd90e017cc01',
          name: 'Window Treatment Operating Hardware',
          children: [
            {
              id: 'fdecfd6c-1c7b-4d28-a0e2-8a430308dc57',
              name: 'Window Treatment Operating Hardware',
              tradeId: '12 25 00',
            },
            {
              id: 'd100eb64-c853-4400-bf63-aae2b4956a4e',
              name: 'Window Treatment Control System',
              tradeId: '12 25 09',
            },
            {
              id: 'b754ed46-ae5f-4c58-9191-918c86f72202',
              name: 'Motorized Drapery Rods',
              tradeId: '12 25 13',
            },
          ],
          tradeId: '12 25 00',
        },
        {
          id: '1c3af136-24ac-4753-891f-f6d96e422989',
          name: 'Interior Daylighting Devices',
          children: [],
          tradeId: '12 26 00',
        },
        {
          id: '67830266-d35a-422e-b668-1f73ba943f74',
          name: 'Casework',
          children: [],
          tradeId: '12 30 00',
        },
        {
          id: 'b34507c2-b25e-4243-9ce1-5ebb79212b33',
          name: 'Manufactured Metal Casework',
          children: [
            {
              id: '311e0bd3-cc44-4863-8562-76107af970ed',
              name: 'Manufactured Metal Casework',
              tradeId: '12 31 00',
            },
            {
              id: '36e5c67a-e4fb-4a39-a658-168cdf0d502f',
              name: 'Manufactured Metal Sandwich Panel Casework',
              tradeId: '12 31 16',
            },
          ],
          tradeId: '12 31 00',
        },
        {
          id: 'bdd905a5-3a77-41ff-b2d7-80b5edaf7410',
          name: 'Manufactured Wood Casework',
          children: [
            {
              id: '7bb49848-ee31-4f81-9772-40d257011404',
              name: 'Manufactured Wood Casework',
              tradeId: '12 32 00',
            },
            {
              id: '51f1e4c9-cc22-46b5-85ec-0fca4945017d',
              name: 'Manufactured Wood-Veneer-Faced Casework',
              tradeId: '12 32 13',
            },
            {
              id: '2ce01c9d-c7a9-4070-9175-f36b1ddc9a7a',
              name: 'Manufactured Plastic-Laminate-Clad Casework',
              tradeId: '12 32 16',
            },
          ],
          tradeId: '12 32 00',
        },
        {
          id: '962cbf3b-b7c5-4c1b-ad09-ce7571ead50b',
          name: 'Manufactured Plastic Casework',
          children: [
            {
              id: 'c5f1cee0-4e01-4d72-ac21-95411c9f5faa',
              name: 'Manufactured Plastic Casework',
              tradeId: '12 34 00',
            },
            {
              id: '34a0ce0c-b392-4a2e-a019-2ebcd38bfe8d',
              name: 'Manufactured Solid-Plastic Casework',
              tradeId: '12 34 16',
            },
          ],
          tradeId: '12 34 00',
        },
        {
          id: '6227bb6f-5774-4f12-a059-e9ff550d0d50',
          name: 'Specialty Casework',
          children: [
            {
              id: 'bad88c74-7833-4f92-ae51-c746bd2e7bb7',
              name: 'Specialty Casework',
              tradeId: '12 35 00',
            },
            {
              id: '4b110a46-bda1-48aa-80f4-53a113cf20b7',
              name: 'Bank Casework',
              tradeId: '12 35 17',
            },
            {
              id: 'f0179dfa-1b44-40a3-95bb-6cf697fdb677',
              name: 'Hospitality Casework',
              tradeId: '12 35 25',
            },
            {
              id: '1861047d-4e25-412f-a5e4-2dbd8f60a455',
              name: 'Residential Casework',
              tradeId: '12 35 30',
            },
            {
              id: 'b37ba3c0-85aa-4e19-8976-5219e4de7b63',
              name: 'Kitchen Casework',
              tradeId: '12 35 30.13',
            },
            {
              id: '3ad613ee-8fab-46d5-89d8-143f4fe91586',
              name: 'Bathroom Casework',
              tradeId: '12 35 30.23',
            },
            {
              id: '5ca4b987-b99f-40e0-8a8c-3443f525d027',
              name: 'Dormitory Casework',
              tradeId: '12 35 30.43',
            },
            {
              id: '9968873e-38bb-43d8-ba22-d0c1165aca28',
              name: 'Utility Room Casework',
              tradeId: '12 35 33',
            },
            {
              id: '25b859ea-fe38-422d-9e03-cf872f1752e9',
              name: 'Mailroom Casework',
              tradeId: '12 35 36',
            },
            {
              id: 'e44cd435-a41b-40bd-9bec-926219b7a987',
              name: 'Commercial Kitchen Casework',
              tradeId: '12 35 39',
            },
            {
              id: '808f029a-cd0c-431a-8688-e6ffe4cdca60',
              name: 'Educational/Library Casework',
              tradeId: '12 35 50',
            },
            {
              id: '52b1d772-d51f-40fb-ba3b-a4ce70dc599b',
              name: 'Educational Casework',
              tradeId: '12 35 50.13',
            },
            {
              id: 'a4b36b66-6f90-4fb7-b02b-509119def59d',
              name: 'Library Casework',
              tradeId: '12 35 50.53',
            },
            {
              id: 'cdcf259d-e694-4ed1-a44a-52e0995a4b9c',
              name: 'Built-In Study Carrels',
              tradeId: '12 35 50.56',
            },
            {
              id: 'e5a8f449-a418-4498-9664-b9e140a2738c',
              name: 'Laboratory Casework',
              tradeId: '12 35 53',
            },
            {
              id: '5e2d61bd-c99c-4dec-8f25-7bf4d40c0132',
              name: 'Metal Laboratory Casework',
              tradeId: '12 35 53.13',
            },
            {
              id: '00f74c89-e65e-46a2-b0c5-8b2e768079cf',
              name: 'Plastic-Laminate-Clad Laboratory Casework',
              tradeId: '12 35 53.16',
            },
            {
              id: '50eaca04-5a09-415e-bc51-8df998ff4a10',
              name: 'Wood Laboratory Casework',
              tradeId: '12 35 53.19',
            },
            {
              id: 'ab83fd2b-e26d-4da2-abaf-d262c088d163',
              name: 'Solid-Plastic Laboratory Casework',
              tradeId: '12 35 53.23',
            },
            {
              id: 'a8559169-8a07-4f82-a2a0-903e77d75a2d',
              name: 'Display Casework',
              tradeId: '12 35 59',
            },
            {
              id: 'd67566ef-4b28-4f67-8457-fd09f0e4c499',
              name: 'Healthcare Casework',
              tradeId: '12 35 70',
            },
            {
              id: 'c5bf68f9-ba24-4086-87f9-f27ca81d5aac',
              name: 'Hospital Casework',
              tradeId: '12 35 70.13',
            },
            {
              id: 'cc2d41b3-c4f8-49ad-be22-ab38a2c62d71',
              name: 'Nurse Station Casework',
              tradeId: '12 35 70.16',
            },
            {
              id: '79d92181-d85a-4c70-a935-8182c359a473',
              name: 'Exam Room Casework',
              tradeId: '12 35 70.19',
            },
            {
              id: 'b1de4e44-7508-4d0b-ab85-497974946b25',
              name: 'Dental Casework',
              tradeId: '12 35 70.74',
            },
            {
              id: 'fa6cafc5-c001-4c8b-beee-7442a8558f55',
              name: 'Performing Arts Casework',
              tradeId: '12 35 83',
            },
            {
              id: 'cf98ce3b-9c51-447e-a61c-b0f91b295994',
              name: 'Religious Casework',
              tradeId: '12 35 91',
            },
          ],
          tradeId: '12 35 00',
        },
        {
          id: '772864b7-368e-4fa9-b8b4-a952bac471f0',
          name: 'Countertops',
          children: [
            {
              id: '800abd4d-7373-4261-83fb-bd9b4077756f',
              name: 'Countertops',
              tradeId: '12 36 00',
            },
            {
              id: 'ce0cb034-be20-4fd3-b110-9089acc5d434',
              name: 'Concrete Countertops',
              tradeId: '12 36 13',
            },
            {
              id: 'b83904c7-ecc8-4ccf-a52e-f21d9bf9fb2e',
              name: 'Metal Countertops',
              tradeId: '12 36 16',
            },
            {
              id: '5ccd624a-4275-4961-8a4a-dff17b23e800',
              name: 'Wood Countertops',
              tradeId: '12 36 19',
            },
            {
              id: '14c52997-3871-4644-99f0-03f990ddee73',
              name: 'Plastic Countertops',
              tradeId: '12 36 23',
            },
            {
              id: '6f729e68-4f24-43a6-bae3-f0122713a9a1',
              name: 'Plastic-Laminate-Clad Countertops',
              tradeId: '12 36 23.13',
            },
            {
              id: 'f0ac3149-1b2c-43b1-a54b-fad17f53affc',
              name: 'Stone Countertops',
              tradeId: '12 36 40',
            },
            {
              id: '8cb6eaf4-8b1e-4601-9d8b-70ce8bdc1047',
              name: 'Laboratory Countertops',
              tradeId: '12 36 53',
            },
            {
              id: '43be5724-a48f-40d7-83e6-7acdefd6351d',
              name: 'Simulated Stone Countertops',
              tradeId: '12 36 61',
            },
            {
              id: '9ccb6873-4151-47db-ac26-ef928f6f3a80',
              name: 'Cultured Marble Countertops',
              tradeId: '12 36 61.13',
            },
            {
              id: '7d553268-278b-44af-ba8a-6aefece5bd43',
              name: 'Solid Surfacing Countertops',
              tradeId: '12 36 61.16',
            },
            {
              id: '7a27adc5-9276-4b92-9b92-28a76e2a1e45',
              name: 'Quartz Agglomerate Countertops',
              tradeId: '12 36 61.19',
            },
          ],
          tradeId: '12 36 00',
        },
        {
          id: 'ed841c8b-7728-4205-b8c5-c15f6a2e68ce',
          name: 'Furnishings and Accessories',
          children: [],
          tradeId: '12 40 00',
        },
        {
          id: '4a4c4f48-9316-40cc-b3bb-37b2a65969ec',
          name: 'Office Accessories',
          children: [
            {
              id: 'c161a913-af6a-4a40-8191-51d4144b3097',
              name: 'Office Accessories',
              tradeId: '12 41 00',
            },
            {
              id: 'f9c56123-97d4-4d82-905a-7917aca7ed79',
              name: 'Desk Accessories',
              tradeId: '12 41 13',
            },
          ],
          tradeId: '12 41 00',
        },
        {
          id: '3bf3f977-d096-4c5d-b4e8-dfbd396d0a00',
          name: 'Table Accessories',
          children: [
            {
              id: 'ed88ed5f-738f-48f0-8795-703d93421f3c',
              name: 'Table Accessories',
              tradeId: '12 42 00',
            },
            {
              id: 'b35a7c71-7e63-4f68-983a-c89af4473fd2',
              name: 'Ceramics',
              tradeId: '12 42 13',
            },
            {
              id: '8afa48be-f788-4e0f-b30a-a199bf85a2ef',
              name: 'Flatware',
              tradeId: '12 42 16',
            },
            {
              id: '5c25785b-fc63-4531-a0dc-c80903491471',
              name: 'Silverware',
              tradeId: '12 42 16.13',
            },
            {
              id: '342e5b92-80ef-4b3b-ac16-a598971217c7',
              name: 'Hollowware',
              tradeId: '12 42 19',
            },
            {
              id: 'b3f6bd62-39b5-47fd-9190-56b90509f093',
              name: 'Glassware',
              tradeId: '12 42 23',
            },
            {
              id: '35fdc5ab-af47-409d-bfb9-ff591129d694',
              name: 'Table Linens',
              tradeId: '12 42 26',
            },
            {
              id: 'e53aaf3d-f5fc-4858-bb00-c95d4116e028',
              name: 'Napery',
              tradeId: '12 42 26.13',
            },
          ],
          tradeId: '12 42 00',
        },
        {
          id: 'f6038c2b-5ac3-4c7e-b959-e1e8a94b365b',
          name: 'Portable Lamps',
          children: [
            {
              id: '794feb5c-0dd9-4e17-b649-30618cc52465',
              name: 'Portable Lamps',
              tradeId: '12 43 00',
            },
            {
              id: '7ad51690-8d3d-48b4-b2b1-c88f7d19a01b',
              name: 'Lamps',
              tradeId: '12 43 13',
            },
            {
              id: 'fabe8f97-f0cc-4af7-8008-4b1248ea46b4',
              name: 'Desk Lamps',
              tradeId: '12 43 13.13',
            },
            {
              id: '80541418-5a20-4afd-982d-fa3a35063a91',
              name: 'Table Lamps',
              tradeId: '12 43 13.16',
            },
            {
              id: 'd34e8f5d-2b99-4b72-9462-f646df504ce1',
              name: 'Floor Lamps',
              tradeId: '12 43 13.19',
            },
          ],
          tradeId: '12 43 00',
        },
        {
          id: 'b103c0a0-8516-405c-a37f-e110af6191e1',
          name: 'Bath Furnishings',
          children: [
            {
              id: '316ef450-91f3-4c5e-a781-7607f1c8a537',
              name: 'Bath Furnishings',
              tradeId: '12 44 00',
            },
            {
              id: 'bd42b2b0-7d9f-4b1f-afe1-6f46ffa2343a',
              name: 'Bath Linens',
              tradeId: '12 44 13',
            },
            {
              id: '0168ec94-1097-4b0f-b964-3b22fa24dba9',
              name: 'Bath Mats',
              tradeId: '12 44 13.13',
            },
            {
              id: '5c23dd67-5f57-4833-98e7-8085b6bff2a2',
              name: 'Bath Towels',
              tradeId: '12 44 13.16',
            },
            {
              id: '4b3600ec-622f-4efc-8c81-124d4a1ea7b3',
              name: 'Shower Curtains',
              tradeId: '12 44 16',
            },
          ],
          tradeId: '12 44 00',
        },
        {
          id: '23f7ab47-25b8-4c25-98ff-68183a31185d',
          name: 'Bedroom Furnishings',
          children: [
            {
              id: 'c57ed237-3690-46ee-8266-57fc9be3c9a2',
              name: 'Bedroom Furnishings',
              tradeId: '12 45 00',
            },
            {
              id: 'f8915599-2231-4c51-8446-dd6827180843',
              name: 'Bed Linens',
              tradeId: '12 45 13',
            },
            {
              id: '8bcce97c-3e82-4be6-9df8-3884fd54d7a9',
              name: 'Blankets',
              tradeId: '12 45 13.13',
            },
            {
              id: '4f822531-48ae-46c3-8708-c519d3ff6e9b',
              name: 'Comforters',
              tradeId: '12 45 13.16',
            },
            {
              id: '63f85a35-06ba-4457-8d30-15e0da89d779',
              name: 'Pillows',
              tradeId: '12 45 16',
            },
          ],
          tradeId: '12 45 00',
        },
        {
          id: '638fedce-3c37-4357-82c3-769dbc635af0',
          name: 'Furnishing Accessories',
          children: [
            {
              id: '44b256f1-fe75-4ddb-9153-d2fbd213bbde',
              name: 'Furnishing Accessories',
              tradeId: '12 46 00',
            },
            {
              id: 'c85aa51a-4ebb-4815-a10f-0bb23426a8fc',
              name: 'Ash Receptacles',
              tradeId: '12 46 13',
            },
            {
              id: 'af0d9766-be5c-4148-befd-5bc88cb0034d',
              name: 'Bowls',
              tradeId: '12 46 16',
            },
            {
              id: 'faa10df8-68e3-4de8-8f0c-675435353769',
              name: 'Clocks',
              tradeId: '12 46 19',
            },
            {
              id: 'ffc3916b-e6fb-4547-8a91-54915da377f9',
              name: 'Decorative Crafts',
              tradeId: '12 46 23',
            },
            {
              id: '1334cb4b-3ba1-494a-a436-add7587db677',
              name: 'Decorative Screens',
              tradeId: '12 46 26',
            },
            {
              id: '63a73d13-f683-4c01-9a2f-a14604104ad3',
              name: 'Vases',
              tradeId: '12 46 29',
            },
            {
              id: '796dd767-146d-4d6a-a1cf-f3e01c3d1579',
              name: 'Waste Receptacles',
              tradeId: '12 46 33',
            },
          ],
          tradeId: '12 46 00',
        },
        {
          id: 'd4571c67-e255-47d4-8ec7-22467a5e2a25',
          name: 'Rugs and Mats',
          children: [
            {
              id: '02c14565-f77b-41dc-802c-58a9541e0aab',
              name: 'Rugs and Mats',
              tradeId: '12 48 00',
            },
            {
              id: 'b4c78881-9681-4562-a2d6-cb38eb328515',
              name: 'Entrance Floor Mats and Frames',
              tradeId: '12 48 13',
            },
            {
              id: 'c322c23a-92fd-4057-b6d2-09af71c1713e',
              name: 'Entrance Floor Mats',
              tradeId: '12 48 13.13',
            },
            {
              id: 'a176cb7d-4013-4a64-8f4a-2253b74483d6',
              name: 'Entrance Floor Mat Frames',
              tradeId: '12 48 13.16',
            },
            {
              id: '43f6d5be-3b76-42f8-8fdd-1fa022765ca7',
              name: 'Entrance Floor Grilles',
              tradeId: '12 48 16',
            },
            {
              id: '74ba5d25-ff00-4978-83f9-dde9c2343452',
              name: 'Entrance Floor Gratings',
              tradeId: '12 48 19',
            },
            {
              id: 'aac1912b-b058-4940-b895-89d7b4a4f52e',
              name: 'Entrance Floor Grids',
              tradeId: '12 48 23',
            },
            {
              id: 'f6b469e3-01b8-4e15-b3b3-a4648e5e962d',
              name: 'Entrance Tile',
              tradeId: '12 48 26',
            },
            {
              id: 'f5fcd700-5c70-43af-868c-6a889b5306d5',
              name: 'Floor Mats',
              tradeId: '12 48 43',
            },
            {
              id: 'fcca9de3-3d90-41da-a8a4-5ee4469d12db',
              name: 'Chair Mats',
              tradeId: '12 48 43.13',
            },
            {
              id: '60840f81-6b32-486b-8e5d-e89216392df8',
              name: 'Anti-Fatigue Floor Mats',
              tradeId: '12 48 43.23',
            },
            {
              id: '0d1ed90e-80b4-47ca-b848-6845692b962c',
              name: 'Fall-Cushioning Floor Mats',
              tradeId: '12 48 43.26',
            },
            {
              id: '7a6fbe25-1857-4eb5-9eb3-ebdf07b0b324',
              name: 'Rugs',
              tradeId: '12 48 53',
            },
            {
              id: 'cefc2023-3d77-437d-9290-361a9006e2c6',
              name: 'Runners',
              tradeId: '12 48 53.13',
            },
            {
              id: 'c7be09da-0905-42ff-99e8-309f2367c23c',
              name: 'Oriental Rugs',
              tradeId: '12 48 53.16',
            },
          ],
          tradeId: '12 48 00',
        },
        {
          id: 'f89c8335-3a37-4f49-b12c-0f5df3b37955',
          name: 'Furniture',
          children: [],
          tradeId: '12 50 00',
        },
        {
          id: '5c37c781-5c65-4ef3-a9a0-035657f7239b',
          name: 'Office Furniture',
          children: [
            {
              id: '243e3bce-a977-4bc8-a8fd-92d607af1401',
              name: 'Office Furniture',
              tradeId: '12 51 00',
            },
            {
              id: '00e15c64-eb30-412e-9d3f-0d684498ea9b',
              name: 'Case Goods',
              tradeId: '12 51 16',
            },
            {
              id: '3b61ca17-b9ba-4c8c-915e-3362abdd7af0',
              name: 'Metal Case Goods',
              tradeId: '12 51 16.13',
            },
            {
              id: 'c3f1a9f9-3212-4e17-bcb3-be4bdcad1849',
              name: 'Wood Case Goods',
              tradeId: '12 51 16.16',
            },
            {
              id: 'f0042a05-5c1d-4c1c-8142-c5a9290cab3f',
              name: 'Plastic-Laminate-Clad Case Goods',
              tradeId: '12 51 16.19',
            },
            {
              id: '797b1d8f-03f4-4b34-a0da-5cd6e2043914',
              name: 'Filing Cabinets',
              tradeId: '12 51 19',
            },
            {
              id: 'a497b3a1-c861-4c76-bd6b-0e948b57d3fb',
              name: 'Lateral Filing Cabinets',
              tradeId: '12 51 19.13',
            },
            {
              id: 'e8cfc3f7-f6db-44e7-89ee-b9caf81c4afb',
              name: 'Vertical Filing Cabinets',
              tradeId: '12 51 19.16',
            },
            {
              id: '137d225c-2c73-4cfb-b126-de1511344fd9',
              name: 'Office Tables',
              tradeId: '12 51 23',
            },
            {
              id: '9bbedb93-8ddb-4cf0-b94c-d5dab8f5761f',
              name: 'Custom Office Furniture',
              tradeId: '12 51 83',
            },
          ],
          tradeId: '12 51 00',
        },
        {
          id: '7ca8c1d1-d1f0-4ad8-9929-0b5ab01ee797',
          name: 'Seating',
          children: [
            {
              id: 'eadcff27-3d64-40c8-8bf5-511fda5fd5c7',
              name: 'Seating',
              tradeId: '12 52 00',
            },
            {
              id: '87e6abf7-fc21-41c7-9d99-15c60c6def59',
              name: 'Chairs',
              tradeId: '12 52 13',
            },
            {
              id: 'c7c235ce-2996-437b-b71d-60a67e30d961',
              name: 'Upholstered Seating',
              tradeId: '12 52 19',
            },
            {
              id: '6f918458-f636-4677-aecf-820b51cef68e',
              name: 'Office Seating',
              tradeId: '12 52 23',
            },
            {
              id: 'f983c486-8b52-4b74-a6c2-f6b73abd21cb',
              name: 'Healthcare Seating',
              tradeId: '12 52 70',
            },
            {
              id: '02dd3e6e-51e9-440d-9810-6b63dfa1a4eb',
              name: 'Custom Seating',
              tradeId: '12 52 83',
            },
          ],
          tradeId: '12 52 00',
        },
        {
          id: 'db1f8760-14e2-45fd-8fd8-70ea8c095807',
          name: 'Retail Furniture',
          children: [
            {
              id: 'fcb47b08-213f-4d2c-8867-32f8596e661c',
              name: 'Retail Furniture',
              tradeId: '12 53 00',
            },
            {
              id: '1d9723ce-b2f4-4b5c-bbb5-f00d4d9ed00c',
              name: 'Custom Retail Furniture',
              tradeId: '12 53 83',
            },
          ],
          tradeId: '12 53 00',
        },
        {
          id: '3abd0311-2c91-455f-bd8c-5067a3ca4ed6',
          name: 'Hospitality Furniture',
          children: [
            {
              id: '91fbd81b-2992-44d4-a24e-521a39706b1f',
              name: 'Hospitality Furniture',
              tradeId: '12 54 00',
            },
            {
              id: 'ae770b5b-64b6-41d5-88d3-b351821c6c8e',
              name: 'Hotel and Motel Furniture',
              tradeId: '12 54 13',
            },
            {
              id: 'aa40f2bf-a858-4d82-8467-0aea789337a3',
              name: 'Restaurant Furniture',
              tradeId: '12 54 16',
            },
            {
              id: 'ce4c3fc2-5a12-448c-a714-175a7605936d',
              name: 'Custom Hospitality Furniture',
              tradeId: '12 54 83',
            },
          ],
          tradeId: '12 54 00',
        },
        {
          id: '981103bb-e9f8-4599-9f9c-87006a740151',
          name: 'Detention Furniture',
          children: [
            {
              id: '9364df31-6147-410e-ac94-f04a24236627',
              name: 'Detention Furniture',
              tradeId: '12 55 00',
            },
            {
              id: '86baa89f-5416-4580-a548-70b8a68c6e8b',
              name: 'Detention Bunks',
              tradeId: '12 55 13',
            },
            {
              id: 'f5d93d92-a9e2-49dc-a495-47a16b9d8386',
              name: 'Detention Desks',
              tradeId: '12 55 16',
            },
            {
              id: '617f4c56-24d2-4f01-b3e9-4ee39d228876',
              name: 'Detention Stools',
              tradeId: '12 55 19',
            },
            {
              id: 'deb2375c-0d78-477a-a3fb-57dbd7e817f4',
              name: 'Detention Tables',
              tradeId: '12 55 23',
            },
            {
              id: 'a9ac7a53-d6e0-4e64-8a58-04ca3694400e',
              name: 'Detention Safety Clothes Hooks',
              tradeId: '12 55 26',
            },
            {
              id: 'f80f8dd1-b310-479d-8cd8-d86d4e14dfa8',
              name: 'Custom Detention Furniture',
              tradeId: '12 55 83',
            },
            {
              id: 'f9032131-d467-4eac-a1ce-d0fec7401597',
              name: 'Detention Control Room Furniture',
              tradeId: '12 55 86',
            },
          ],
          tradeId: '12 55 00',
        },
        {
          id: 'eb8abebb-70d3-4980-9059-f8382d094ec5',
          name: 'Institutional Furniture',
          children: [
            {
              id: '0f3d6535-30b6-420b-90b7-55e9b5959f15',
              name: 'Institutional Furniture',
              tradeId: '12 56 00',
            },
            {
              id: '727914d3-9d98-4665-bd5b-64a143f60a5e',
              name: 'Religious Furniture',
              tradeId: '12 56 23',
            },
            {
              id: 'b656c054-7ec2-412b-aa4d-4b9f9ffd35f2',
              name: 'Classroom Furniture',
              tradeId: '12 56 33',
            },
            {
              id: 'a4c79c49-7b58-4bd8-927f-e24671970cfc',
              name: 'Fixed Classroom Tables',
              tradeId: '12 56 33.13',
            },
            {
              id: '3374d59b-0b7e-4e89-ad2d-7776f2af9349',
              name: 'Classroom Computer Furniture',
              tradeId: '12 56 33.23',
            },
            {
              id: '7d5169a8-0e67-45a2-b365-6fe737411b68',
              name: 'Lecterns',
              tradeId: '12 56 39',
            },
            {
              id: 'deea4840-9bcd-455c-8780-49628b071ef7',
              name: 'Dormitory Furniture',
              tradeId: '12 56 43',
            },
            {
              id: 'b5b11076-12b2-4b56-9846-79ff65a39054',
              name: 'Library Furniture',
              tradeId: '12 56 51',
            },
            {
              id: '4850230d-700b-47dd-8400-325655083b73',
              name: 'Book Shelves',
              tradeId: '12 56 51.13',
            },
            {
              id: '3f23b60c-106b-42af-9227-c6b33ad2692c',
              name: 'Study Carrels',
              tradeId: '12 56 51.16',
            },
            {
              id: '67ca3ae5-0ca4-451d-8ab2-80f519e09802',
              name: 'Index Card File Cabinets',
              tradeId: '12 56 51.19',
            },
            {
              id: '418762a4-debb-4cb5-b144-bb8e094095ff',
              name: 'Audio-Visual Furniture',
              tradeId: '12 56 52',
            },
            {
              id: '0566f2db-b492-4c67-9879-cbf4f60834c4',
              name: 'Laboratory Furniture',
              tradeId: '12 56 53',
            },
            {
              id: 'cac00825-eba1-4bdf-a3f4-7c5ec113604a',
              name: 'Healthcare Furniture',
              tradeId: '12 56 70',
            },
            {
              id: '567f112b-f224-4fd0-8da4-e75b76f0f776',
              name: 'Custom Institutional Furniture',
              tradeId: '12 56 83',
            },
            {
              id: 'a667a40c-49fd-4e60-8951-4ed964a7e5aa',
              name: 'Institutional Control Room Furniture',
              tradeId: '12 56 86',
            },
          ],
          tradeId: '12 56 00',
        },
        {
          id: 'e928f0c8-ca11-4b76-b980-f9a4ec8d7d31',
          name: 'Industrial Furniture',
          children: [
            {
              id: '5c23d181-b41b-4f1f-9c68-838949748dd7',
              name: 'Industrial Furniture',
              tradeId: '12 57 00',
            },
            {
              id: '66e7e72c-ca73-4644-9c69-6931d76ecc91',
              name: 'Welding Benches',
              tradeId: '12 57 13',
            },
            {
              id: '8a910f9f-abf0-4ec6-accd-d3b76f91b2e0',
              name: 'Welding Screens',
              tradeId: '12 57 16',
            },
            {
              id: '17f0014a-d16a-40a1-b780-ef0e6b2a824d',
              name: 'Laser Containment Screens',
              tradeId: '12 57 19',
            },
            {
              id: '7877014d-21b3-4b57-88ec-54931eec0292',
              name: 'Custom Industrial Furniture',
              tradeId: '12 57 83',
            },
            {
              id: '80af3038-98cb-455b-abe2-2cda9a2039e7',
              name: 'Industrial Control Room Furniture',
              tradeId: '12 57 86',
            },
          ],
          tradeId: '12 57 00',
        },
        {
          id: '9882b798-c68d-492f-a2d4-809d59c6b1e2',
          name: 'Residential Furniture',
          children: [
            {
              id: '31a4ea5a-7b3d-45b4-9e66-af18b61c02a6',
              name: 'Residential Furniture',
              tradeId: '12 58 00',
            },
            {
              id: 'f1519763-d07d-4720-b41f-dc896d0cb5b8',
              name: 'Couches and Loveseats',
              tradeId: '12 58 13',
            },
            {
              id: '6e529ac0-4967-4dcd-89b1-f57ddc7b1c3b',
              name: 'Futons',
              tradeId: '12 58 13.13',
            },
            {
              id: '23f7e970-7c3d-4e5a-a1f3-8b357183b88a',
              name: 'Residential Chairs',
              tradeId: '12 58 16',
            },
            {
              id: '53ba8e19-fe7a-4ef5-ae1a-d352f80a831c',
              name: 'Reclining Chairs',
              tradeId: '12 58 16.13',
            },
            {
              id: 'ff8116c6-1072-496b-bcea-18455331e959',
              name: 'Dining Tables and Chairs',
              tradeId: '12 58 19',
            },
            {
              id: '32c37ee1-9780-4ac3-b85b-8b359b40501f',
              name: 'Coffee Tables',
              tradeId: '12 58 23',
            },
            {
              id: '13ed036c-ead5-4ffc-8c8b-80e5f9c2f463',
              name: 'Entertainment Centers',
              tradeId: '12 58 26',
            },
            {
              id: 'd71a42cc-5338-437d-ba6b-2110b1687375',
              name: 'Beds',
              tradeId: '12 58 29',
            },
            {
              id: '93e69139-b84b-4bc0-9116-67dc20c4c500',
              name: 'Daybeds',
              tradeId: '12 58 29.13',
            },
            {
              id: '03779a26-cc24-4074-98fb-c740dcf17493',
              name: 'Dressers',
              tradeId: '12 58 33',
            },
            {
              id: '39423a42-e626-4508-8c8d-38bb38d1e8e6',
              name: 'Armoires',
              tradeId: '12 58 33.13',
            },
            {
              id: '7d71186b-4d61-48bf-9141-f6cdf5da66d4',
              name: 'Nightstands',
              tradeId: '12 58 36',
            },
            {
              id: '7e82d5a9-2847-4450-81ae-080519a7185f',
              name: 'Custom Residential Furniture',
              tradeId: '12 58 83',
            },
          ],
          tradeId: '12 58 00',
        },
        {
          id: 'e5abf2a7-3fb4-4cb1-ad8c-d97555cfea8b',
          name: 'Systems Furniture',
          children: [
            {
              id: '915783e0-1c31-45f2-8d49-f66a49acff22',
              name: 'Systems Furniture',
              tradeId: '12 59 00',
            },
            {
              id: '16051cbe-834b-4cd0-b3cd-2f9ff8f12c75',
              name: 'Panel-Hung Component System Furniture',
              tradeId: '12 59 13',
            },
            {
              id: '49f57d71-d730-490b-aeab-f6642f83884c',
              name: 'Free-Standing Component System Furniture',
              tradeId: '12 59 16',
            },
            {
              id: '6316bcbd-17f0-4c7d-8fc3-6e9ddd1f079f',
              name: 'Beam System Furniture',
              tradeId: '12 59 19',
            },
            {
              id: 'b08f4667-91db-43dd-ba6d-4fbe9b4ea985',
              name: 'Desk System Furniture',
              tradeId: '12 59 23',
            },
            {
              id: '591818ad-1c4b-462d-aea6-c2115008bb69',
              name: 'Custom Systems Furniture',
              tradeId: '12 59 83',
            },
          ],
          tradeId: '12 59 00',
        },
        {
          id: '9ab7a384-b2c3-4398-a5c0-51f5e4e3c8d3',
          name: 'Multiple Seating',
          children: [],
          tradeId: '12 60 00',
        },
        {
          id: 'ae1626ef-bb03-480f-946b-1af2d936b919',
          name: 'Fixed Audience Seating',
          children: [
            {
              id: '8a32b66c-e3d3-43dd-95ef-45185e242cbc',
              name: 'Fixed Audience Seating',
              tradeId: '12 61 00',
            },
            {
              id: 'e75aeb3a-b395-4f57-bad5-e89540d9e9b2',
              name: 'Upholstered Audience Seating',
              tradeId: '12 61 13',
            },
            {
              id: '64f214a0-bce8-4ef3-9100-eb51eb016ac3',
              name: 'Molded-Plastic Audience Seating',
              tradeId: '12 61 16',
            },
          ],
          tradeId: '12 61 00',
        },
        {
          id: 'a5a4e584-5819-462a-a645-a1e7ac0ba5d7',
          name: 'Portable Audience Seating',
          children: [
            {
              id: 'fc0af6a5-2bab-447e-b594-ce5ef665b962',
              name: 'Portable Audience Seating',
              tradeId: '12 62 00',
            },
            {
              id: 'bf83483f-aa0b-4929-8cdb-f7737f82e921',
              name: 'Folding Chairs',
              tradeId: '12 62 13',
            },
            {
              id: '7707bab5-8ca0-4243-bc7c-5d8aada6816b',
              name: 'Interlocking Chairs',
              tradeId: '12 62 16',
            },
            {
              id: '4bcf8040-f006-479f-9980-d1472c8e0059',
              name: 'Stacking Chairs',
              tradeId: '12 62 19',
            },
          ],
          tradeId: '12 62 00',
        },
        {
          id: '40485ab3-deb1-42dc-b2c4-26f34d57cb63',
          name: 'Stadium and Arena Seating',
          children: [
            {
              id: '367081b4-7113-4305-928d-71914ce732c2',
              name: 'Stadium and Arena Seating',
              tradeId: '12 63 00',
            },
            {
              id: '3733c2e6-af8f-43ba-906d-7de54a8cacb2',
              name: 'Stadium and Arena Bench Seating',
              tradeId: '12 63 13',
            },
            {
              id: '2b425248-5571-4c25-99ba-9e9d38e42247',
              name: 'Stadium and Arena Seats',
              tradeId: '12 63 23',
            },
          ],
          tradeId: '12 63 00',
        },
        {
          id: '5cb45ce0-1b44-409c-8642-c295ef1c487a',
          name: 'Booths and Tables',
          children: [],
          tradeId: '12 64 00',
        },
        {
          id: 'bac52f15-7182-40e5-b65b-597c1cc0435b',
          name: 'Multiple-Use Fixed Seating',
          children: [],
          tradeId: '12 65 00',
        },
        {
          id: '0fbc992d-eaae-4a6b-ba56-bab14f9e048f',
          name: 'Telescoping Stands',
          children: [
            {
              id: '52a00853-b573-4b2d-a539-6c311e1488e2',
              name: 'Telescoping Stands',
              tradeId: '12 66 00',
            },
            {
              id: 'e4b88043-2d26-4858-9d36-fda7504d98f3',
              name: 'Telescoping Bleachers',
              tradeId: '12 66 13',
            },
            {
              id: 'a81c413a-6d37-4309-b386-95da8197bb31',
              name: 'Telescoping Chair Platforms',
              tradeId: '12 66 23',
            },
          ],
          tradeId: '12 66 00',
        },
        {
          id: 'fc419fd6-cfdf-4728-a8f0-15b0a307eecc',
          name: 'Pews and Benches',
          children: [
            {
              id: 'a3e256cd-f388-4980-9efe-24a8e457f2ff',
              name: 'Pews and Benches',
              tradeId: '12 67 00',
            },
            {
              id: 'd1ada799-0036-4dd4-a7b6-3e41bdadc6e5',
              name: 'Pews',
              tradeId: '12 67 13',
            },
            {
              id: 'cab67a19-8000-4d4a-b7e4-9100ba7560da',
              name: 'Benches',
              tradeId: '12 67 23',
            },
          ],
          tradeId: '12 67 00',
        },
        {
          id: '3e41b534-da26-4233-9496-8941b00e9425',
          name: 'Seat and Table Assemblies',
          children: [
            {
              id: '19dac97b-5b0d-43f6-ade4-0e137e9cdaf5',
              name: 'Seat and Table Assemblies',
              tradeId: '12 68 00',
            },
            {
              id: '5ab23d8f-695d-4261-aa90-f3b4f985c5f8',
              name: 'Pedestal Tablet Arm Chairs',
              tradeId: '12 68 13',
            },
          ],
          tradeId: '12 68 00',
        },
        {
          id: '115f4d9e-ea29-4eea-9021-4f13f8ef22aa',
          name: 'Other Furnishings',
          children: [],
          tradeId: '12 90 00',
        },
        {
          id: 'b3942481-9c5c-466f-a253-a8417bdc5418',
          name: 'Interior Planters and Artificial Plants',
          children: [
            {
              id: '66dc2d94-f7ba-43b5-82ec-7e0fd162bb03',
              name: 'Interior Planters and Artificial Plants',
              tradeId: '12 92 00',
            },
            {
              id: '9bbfcd93-e6ac-414e-9007-19c6dbd85ba9',
              name: 'Interior Artificial Plants',
              tradeId: '12 92 13',
            },
            {
              id: 'a42df090-2bd7-4518-85e8-e09fc6981a19',
              name: 'Interior Planters',
              tradeId: '12 92 33',
            },
            {
              id: 'd6ab5117-e737-42cf-a87d-12e15210db30',
              name: 'Interior Landscaping Accessories',
              tradeId: '12 92 43',
            },
          ],
          tradeId: '12 92 00',
        },
        {
          id: 'c15e9e8b-e779-4bf4-8a7e-8c67df00fbdf',
          name: 'Site Furnishings',
          children: [
            {
              id: '230ceb0b-1cb5-40ff-bc7b-bd13995ffe9b',
              name: 'Site Furnishings',
              tradeId: '12 93 00',
            },
            {
              id: 'ffcde320-65dd-4e6d-8b65-f745ea4e208f',
              name: 'Bicycle Racks',
              tradeId: '12 93 13',
            },
            {
              id: '25ca92c3-56ae-4a44-a4bf-a23cc03aaf7f',
              name: 'Bicycle Lockers',
              tradeId: '12 93 14',
            },
            {
              id: 'a2946c37-409b-4465-8532-7007529510ee',
              name: 'Trash and Litter Receptacles',
              tradeId: '12 93 23',
            },
            {
              id: '6f47296e-08d3-4e91-b0e2-3282378084ce',
              name: 'Manufactured Planters',
              tradeId: '12 93 33',
            },
            {
              id: 'a09272de-343f-40b3-9c45-874892615c8a',
              name: 'Site Seating and Tables',
              tradeId: '12 93 43',
            },
            {
              id: '02d6d555-454c-49e9-b61a-b7a79431ebc2',
              name: 'Site Seating',
              tradeId: '12 93 43.13',
            },
            {
              id: '3010095b-c697-4fa8-960b-51abc952c8ed',
              name: 'Site Tables',
              tradeId: '12 93 43.53',
            },
          ],
          tradeId: '12 93 00',
        },
      ],
      tradeId: '12 00 00',
    },
    {
      id: '78d3c9e2-6507-4b4d-b0ab-65c94c44dd39',
      name: 'Special Construction',
      children: [
        {
          id: 'db13e159-f5af-4799-ad29-3f2ea6659441',
          name: 'Special Construction',
          children: [],
          tradeId: '13 00 00',
        },
        {
          id: '19658685-da67-4fcb-a932-d2cc8c47ceb2',
          name: 'Operation and Maintenance of Special Construction',
          children: [
            {
              id: '9182fcfe-a871-4481-96fd-bc1af30a7dd1',
              name: 'Operation and Maintenance of Special Construction',
              tradeId: '13 01 00',
            },
            {
              id: 'e90800a2-50df-4d58-93bf-1ba5f68b4030',
              name: 'Operation and Maintenance of Special Facility Components',
              tradeId: '13 01 10',
            },
            {
              id: '5f36adfe-4131-499c-9f75-65d8ef859317',
              name: 'Operation and Maintenance of Swimming Pools',
              tradeId: '13 01 11',
            },
            {
              id: 'ac66643e-b3f3-4e29-b633-0925bc768c10',
              name: 'Operation and Maintenance of Fountains',
              tradeId: '13 01 12',
            },
            {
              id: 'cbaa0b63-84f7-4ce1-9868-5ede1b1e8596',
              name: 'Operation and Maintenance of Aquariums',
              tradeId: '13 01 13',
            },
            {
              id: 'a46242a8-4eba-4856-85d5-9bc04e3729a7',
              name: 'Operation and Maintenance of Amusement Park Structures and Equipment',
              tradeId: '13 01 14',
            },
            {
              id: 'fafb52cb-ff4b-42f3-8878-b3668cdde1f8',
              name: 'Operation and Maintenance of Ice Rinks',
              tradeId: '13 01 18',
            },
            {
              id: 'f50ed580-4714-43de-8077-e2978bb2ce4b',
              name: 'Operation and Maintenance of Special Purpose Rooms',
              tradeId: '13 01 20',
            },
            {
              id: '50ae49a1-8b53-47be-85db-af15415fd523',
              name: 'Operation and Maintenance of Controlled Environment Rooms',
              tradeId: '13 01 21',
            },
            {
              id: 'f56d411f-f322-4852-ba65-6f539d18a2eb',
              name: 'Operation and Maintenance of Planetariums',
              tradeId: '13 01 23',
            },
            {
              id: '1d361651-4b9c-4617-b6d9-8c49f86af1b1',
              name: 'Operation and Maintenance of Special Structures',
              tradeId: '13 01 30',
            },
            {
              id: 'f8278d26-8889-491f-9330-dd0c7795ee42',
              name: 'Operation and Maintenance of Integrated Construction',
              tradeId: '13 01 40',
            },
            {
              id: '9defea6d-01f3-41b3-a53b-4f201ec10806',
              name: 'Operation and Maintenance of Radiation Protection',
              tradeId: '13 01 49',
            },
            {
              id: '2747bce8-7568-44a0-905b-601f7f4c3ce1',
              name: 'Operation and Maintenance of Special Instrumentation',
              tradeId: '13 01 50',
            },
            {
              id: '80fe012b-2f33-43b4-b436-25a76c1ddb89',
              name: 'Operation and Maintenance of Stress Instrumentation',
              tradeId: '13 01 51',
            },
            {
              id: 'b8469b0b-9190-49d7-adab-a5767cde1168',
              name: 'Operation and Maintenance of Seismic Instrumentation',
              tradeId: '13 01 52',
            },
            {
              id: '8486308f-bf93-4b09-9ee8-7c4b0d7bb974',
              name: 'Operation and Maintenance of Meteorological Instrumentation',
              tradeId: '13 01 53',
            },
          ],
          tradeId: '13 01 00',
        },
        {
          id: 'ad929fdd-7a15-4488-a402-b8904a07eb25',
          name: 'Common Work Results for Special Construction',
          children: [],
          tradeId: '13 05 00',
        },
        {
          id: '020dab6a-91c7-4eae-9689-5fdae2e9f5cd',
          name: 'Schedules for Special Construction',
          children: [
            {
              id: 'f9b860a5-f96e-43d5-9882-2b2d4da58c58',
              name: 'Schedules for Special Construction',
              tradeId: '13 06 00',
            },
            {
              id: '6934b42f-ec76-43aa-b06f-93289f7f59b2',
              name: 'Schedules for Special Facility Components',
              tradeId: '13 06 10',
            },
            {
              id: 'b8fadd36-2574-423a-9d7f-a267858cd595',
              name: 'Schedules for Special Purpose Rooms',
              tradeId: '13 06 20',
            },
            {
              id: '9bdb7978-3f0b-4161-8509-d0ef61acf846',
              name: 'Schedules for Special Structures',
              tradeId: '13 06 30',
            },
            {
              id: '5fa67e30-3288-4f24-ac0e-d217f804f663',
              name: 'Schedules for Integrated Construction',
              tradeId: '13 06 40',
            },
            {
              id: '1b66bf84-012d-48ce-b36e-78900d65937c',
              name: 'Schedules for Special Instrumentation',
              tradeId: '13 06 50',
            },
          ],
          tradeId: '13 06 00',
        },
        {
          id: '5cd4dc74-19c2-4a64-9be0-b09d6a775bf0',
          name: 'Commissioning of Special Construction',
          children: [
            {
              id: 'bc97681f-7fe4-45f8-bffa-73d534c9dcd0',
              name: 'Commissioning of Special Construction',
              tradeId: '13 08 00',
            },
            {
              id: '931d4312-454d-424f-ae3a-7d8472ce7ad0',
              name: 'Commissioning of Special Facility Components',
              tradeId: '13 08 10',
            },
            {
              id: 'eccb7955-5960-4a49-91f8-1ccee8cff692',
              name: 'Commissioning of Swimming Pools',
              tradeId: '13 08 11',
            },
            {
              id: '36a1c84b-26a0-4a8d-ad93-24f975fc883a',
              name: 'Commissioning of Fountains',
              tradeId: '13 08 12',
            },
            {
              id: 'db3bce89-149b-4b1c-8303-84cf3c550132',
              name: 'Commissioning of Aquariums',
              tradeId: '13 08 13',
            },
            {
              id: 'd37fcd21-c7af-4d7b-a315-50d18498b4d2',
              name: 'Commissioning of Amusement Park Structures and Equipment',
              tradeId: '13 08 14',
            },
            {
              id: 'ab18c23c-dc88-4623-941e-e7db6d0d9656',
              name: 'Commissioning of Ice Rinks',
              tradeId: '13 08 18',
            },
            {
              id: '0362ccb3-d199-4fe1-9e1b-02ac672db811',
              name: 'Commissioning of Special Purpose Rooms',
              tradeId: '13 08 20',
            },
            {
              id: 'ec8c5c97-8294-4745-ba04-35d5dcebf640',
              name: 'Commissioning of Controlled Environment Rooms',
              tradeId: '13 08 21',
            },
            {
              id: '25fd4e09-13c8-4b63-ae4f-c94be420c978',
              name: 'Commissioning of Planetariums',
              tradeId: '13 08 23',
            },
            {
              id: '2d97cf37-cf62-4ac6-b6e1-1ea6ffbece11',
              name: 'Commissioning of Special Structures',
              tradeId: '13 08 30',
            },
            {
              id: 'c1532fb6-bf41-4d24-b739-d3e036564faf',
              name: 'Commissioning of Integrated Construction',
              tradeId: '13 08 40',
            },
            {
              id: 'a5dad1aa-26ba-4421-beb9-e404fcbc4156',
              name: 'Commissioning of Special Instrumentation',
              tradeId: '13 08 50',
            },
          ],
          tradeId: '13 08 00',
        },
        {
          id: '611c466c-6f20-41af-968c-e096e44ba613',
          name: 'Special Facility Components',
          children: [],
          tradeId: '13 10 00',
        },
        {
          id: '766efc6d-2eac-4751-8191-a487a370ba13',
          name: 'Swimming Pools',
          children: [
            {
              id: 'fdbe50f9-4395-4db5-a447-88ee30264979',
              name: 'Swimming Pools',
              tradeId: '13 11 00',
            },
            {
              id: '68e8f6c4-98c6-48f3-acdd-53dfbcf9287e',
              name: 'Below-Grade Swimming Pools',
              tradeId: '13 11 13',
            },
            {
              id: 'ea5774a7-d1d5-4dd9-9c1b-e711a565f639',
              name: 'On-Grade Swimming Pools',
              tradeId: '13 11 23',
            },
            {
              id: '70576ab4-aae5-4a23-ba8e-380402240378',
              name: 'Elevated Swimming Pools',
              tradeId: '13 11 33',
            },
            {
              id: '6606d8f2-e6fe-4796-94ff-d21584e17e1a',
              name: 'Recirculating Gutter Systems',
              tradeId: '13 11 43',
            },
            {
              id: 'c1fc5bf1-2ee5-4395-be7e-aa30cb9abdb6',
              name: 'Swimming Pool Accessories',
              tradeId: '13 11 46',
            },
            {
              id: '6613db3b-445d-40ce-a485-162b9f4c0858',
              name: 'Swimming Pool Cleaning Equipment',
              tradeId: '13 11 49',
            },
            {
              id: '6eac9ce2-dc5a-4fa7-adeb-f5a02621509b',
              name: 'Movable Pool Bulkheads',
              tradeId: '13 11 53',
            },
            {
              id: '5240554d-5ee9-41a9-bc94-337c36d9d825',
              name: 'Movable Pool Floors',
              tradeId: '13 11 56',
            },
          ],
          tradeId: '13 11 00',
        },
        {
          id: '210f7370-7915-4573-ac8d-ec98d3e56637',
          name: 'Fountains',
          children: [
            {
              id: '9b422a72-5ad7-412e-a510-6635010500b3',
              name: 'Fountains',
              tradeId: '13 12 00',
            },
            {
              id: '92ed726e-a6a4-4d11-88e1-bbd71f26bcc3',
              name: 'Exterior Fountains',
              tradeId: '13 12 13',
            },
            {
              id: '4e02e6fe-79b2-4be3-be1f-89e9678f0f4f',
              name: 'Interior Fountains',
              tradeId: '13 12 23',
            },
          ],
          tradeId: '13 12 00',
        },
        {
          id: '879620d2-0a0a-4fbc-b3fb-4aaed86864cf',
          name: 'Aquariums',
          children: [],
          tradeId: '13 13 00',
        },
        {
          id: '52501d8d-a354-4d1f-8468-b5e754b71364',
          name: 'Amusement Park Structures and Equipment',
          children: [
            {
              id: 'd997d295-ec66-4b36-8042-84f9cc8b7a92',
              name: 'Amusement Park Structures and Equipment',
              tradeId: '13 14 00',
            },
            {
              id: '828859ca-c274-4434-8deb-2a9ba71e6715',
              name: 'Water Slides',
              tradeId: '13 14 13',
            },
            {
              id: '8101a21a-9436-491a-9653-b84563d88fe7',
              name: 'Wave Generating Equipment',
              tradeId: '13 14 16',
            },
            {
              id: '76cea2fb-42ec-4eac-9236-9992df87a734',
              name: 'Amusement Park Rides',
              tradeId: '13 14 23',
            },
          ],
          tradeId: '13 14 00',
        },
        {
          id: 'ffcbea1f-f7f1-4cff-88a8-15b70251237d',
          name: 'Tubs and Pools',
          children: [
            {
              id: '20bb4d1e-848a-45f0-a9c5-d1d9af6303b8',
              name: 'Tubs and Pools',
              tradeId: '13 17 00',
            },
            {
              id: 'b0efa820-4ff6-4b95-946f-27d206e50996',
              name: 'Hot Tubs',
              tradeId: '13 17 13',
            },
            {
              id: '5bd8e7c9-f4f3-4168-bc83-23d4dae40271',
              name: 'Therapeutic Pools',
              tradeId: '13 17 23',
            },
            {
              id: '968784d1-5b7d-4b60-8c80-a632380c4317',
              name: 'Whirlpool Tubs',
              tradeId: '13 17 33',
            },
          ],
          tradeId: '13 17 00',
        },
        {
          id: 'b272df48-20fc-4213-82c9-baa12d1b0c80',
          name: 'Ice Rinks',
          children: [
            {
              id: 'c2c0c04f-7085-429f-9a34-342a222b14ca',
              name: 'Ice Rinks',
              tradeId: '13 18 00',
            },
            {
              id: 'd1a65a3a-bffd-469c-b7c3-d0e3df87a492',
              name: 'Ice Rink Floor Systems',
              tradeId: '13 18 13',
            },
            {
              id: '32e239f0-5671-4cee-89fb-595cadb195ea',
              name: 'Ice Rink Dasher Boards',
              tradeId: '13 18 16',
            },
          ],
          tradeId: '13 18 00',
        },
        {
          id: '39482d37-22c4-403c-b38e-1953e00ff7e2',
          name: 'Kennels and Animal Shelters',
          children: [
            {
              id: '2739375b-18cd-4304-a2c9-f0111d5c9131',
              name: 'Kennels and Animal Shelters',
              tradeId: '13 19 00',
            },
            {
              id: 'e0fce9a4-65f4-4614-a8c0-53c5a1dacce3',
              name: 'Kennel Enclosures and Gates',
              tradeId: '13 19 13',
            },
            {
              id: 'de19801c-abc8-4e33-95c5-bad4ac0b5780',
              name: 'Kennel Feeding Devices',
              tradeId: '13 19 16',
            },
          ],
          tradeId: '13 19 00',
        },
        {
          id: '7efdcabd-2f8f-49f2-9434-e9ae77472fad',
          name: 'Special Purpose Rooms',
          children: [],
          tradeId: '13 20 00',
        },
        {
          id: '1bb6722d-b7d6-43a2-9742-23c834e05537',
          name: 'Controlled Environment Rooms',
          children: [
            {
              id: '198d117b-c2cd-43ee-ae0a-6a5e01c01b0c',
              name: 'Controlled Environment Rooms',
              tradeId: '13 21 00',
            },
            {
              id: '009ea711-b2af-40aa-acf6-6ea843d2a075',
              name: 'Clean Rooms',
              tradeId: '13 21 13',
            },
            {
              id: '58507bcc-deb4-4197-a0d7-2acaafb5e74a',
              name: 'Hyperbaric Rooms',
              tradeId: '13 21 16',
            },
            {
              id: 'aef6340b-3bff-4c8d-91d7-8fd9c4f5f80d',
              name: 'Insulated Rooms',
              tradeId: '13 21 23',
            },
            {
              id: 'aa6070ff-2d7a-4eb4-b744-cc4d7ec12930',
              name: 'Cold Storage Rooms',
              tradeId: '13 21 26',
            },
            {
              id: '68fcbca4-5b5f-4daa-8ddc-bd5d6998362c',
              name: 'Walk-in Coolers',
              tradeId: '13 21 26.13',
            },
            {
              id: '8dae2b66-ba2d-417a-89c5-ae52c1e85acb',
              name: 'Walk-in Freezers',
              tradeId: '13 21 26.16',
            },
            {
              id: 'cf845860-fb6a-45b7-87fa-721d9cd7ec09',
              name: 'Constant Temperature Rooms',
              tradeId: '13 21 29',
            },
            {
              id: '7185744f-db0f-4d46-a41b-07a2bd7fb970',
              name: 'Sound-Conditioned Rooms',
              tradeId: '13 21 48',
            },
          ],
          tradeId: '13 21 00',
        },
        {
          id: 'e00c1528-f36b-418c-baf9-82c2311a9eef',
          name: 'Office Shelters and Booths',
          children: [],
          tradeId: '13 22 00',
        },
        {
          id: '7c2fc814-3942-48a6-a8b7-9da0a436b95c',
          name: 'Planetariums',
          children: [],
          tradeId: '13 23 00',
        },
        {
          id: '300eea3c-b426-42d9-a0b9-772787f7289e',
          name: 'Special Activity Rooms',
          children: [
            {
              id: '740bcfbc-383b-481c-a34d-da1c334111ac',
              name: 'Special Activity Rooms',
              tradeId: '13 24 00',
            },
            {
              id: 'd1e209a3-79bf-4a3b-a00c-a2a38b1ea639',
              name: 'Saunas',
              tradeId: '13 24 16',
            },
            {
              id: '70c8f15a-2ae5-4a08-ac3a-0104d9c9fdff',
              name: 'Steam Baths',
              tradeId: '13 24 26',
            },
            {
              id: '7fce391d-547f-48e7-a146-4a9d2d4f98ab',
              name: 'Athletic Rooms',
              tradeId: '13 24 66',
            },
          ],
          tradeId: '13 24 00',
        },
        {
          id: '22668ce1-d3ae-4e9a-9b69-e13fcb83f7c5',
          name: 'Fabricated Rooms',
          children: [
            {
              id: 'fbb582ad-4662-4c63-a08c-658f1232f04e',
              name: 'Fabricated Rooms',
              tradeId: '13 26 00',
            },
            {
              id: '501f46cc-c631-4f16-84cd-96d77440a212',
              name: 'Storm Shelter Rooms',
              tradeId: '13 26 13',
            },
          ],
          tradeId: '13 26 00',
        },
        {
          id: '60911a78-8b1d-46b9-b13b-f587a590aa24',
          name: 'Vaults',
          children: [
            {
              id: '70ae5a49-b1b0-4a70-80af-9c79819b76c3',
              name: 'Vaults',
              tradeId: '13 27 00',
            },
            {
              id: '6505efb4-9ee5-4e29-89b5-ec0c6503080e',
              name: 'Modular Fire Vaults',
              tradeId: '13 27 16',
            },
            {
              id: '8f8f23db-c42e-465f-be64-d42b6c0cb04d',
              name: 'Security Vaults',
              tradeId: '13 27 53',
            },
            {
              id: 'f3c878d5-c15b-4edd-b0ca-24885ed04dca',
              name: 'Modular Concrete Security Vaults',
              tradeId: '13 27 53.13',
            },
            {
              id: 'f1bd5086-ab67-4bc0-9f3c-a039f76853fb',
              name: 'Modular Metal-Clad Laminated Security Vaults',
              tradeId: '13 27 53.16',
            },
          ],
          tradeId: '13 27 00',
        },
        {
          id: '5873813d-9384-4b03-bb48-b0bf9432599e',
          name: 'Athletic and Recreational Special Construction',
          children: [
            {
              id: '0b1708d4-45c3-4ea4-8fb0-1651592e5857',
              name: 'Athletic and Recreational Special Construction',
              tradeId: '13 28 00',
            },
            {
              id: '0d16700b-b46a-435e-b63b-7658f1525164',
              name: 'Indoor Soccer Boards',
              tradeId: '13 28 13',
            },
            {
              id: '8683b624-643d-4f34-91cc-ebda4080b978',
              name: 'Safety Netting',
              tradeId: '13 28 16',
            },
            {
              id: '962fdeef-15fb-4fcc-b76b-54dea0377351',
              name: 'Arena Football Boards',
              tradeId: '13 28 19',
            },
            {
              id: '89df3f5c-36ba-4428-b894-cc920757aaf2',
              name: 'Floor Sockets',
              tradeId: '13 28 26',
            },
            {
              id: 'dc27291e-eb02-41af-ac84-dba5ecbbdbfa',
              name: 'Athletic and Recreational Court Walls',
              tradeId: '13 28 33',
            },
            {
              id: 'd4329860-e95f-4e91-a596-1d8c2caf6976',
              name: 'Demountable Athletic Surfaces',
              tradeId: '13 28 66',
            },
          ],
          tradeId: '13 28 00',
        },
        {
          id: 'eecc2d63-e55b-4f8c-81f4-4e0cf47b3823',
          name: 'Special Structures',
          children: [],
          tradeId: '13 30 00',
        },
        {
          id: '13784b8d-dff9-41a0-97ba-2913e2230c73',
          name: 'Fabric Structures',
          children: [
            {
              id: 'b28f5297-99ac-43e3-9d95-c197a5606869',
              name: 'Fabric Structures',
              tradeId: '13 31 00',
            },
            {
              id: '87f963e5-a821-41f5-860c-fe5b7c57b0ad',
              name: 'Air-Supported Fabric Structures',
              tradeId: '13 31 13',
            },
            {
              id: '4bad799b-bc4c-4a00-baca-93aea2d07219',
              name: 'Single-Walled Air-Supported Structures',
              tradeId: '13 31 13.13',
            },
            {
              id: '297462d6-8df4-43d6-89ca-6c6ca12d4d8f',
              name: 'Multiple-Walled Air-Supported Structures',
              tradeId: '13 31 13.16',
            },
            {
              id: '680d1175-8485-45a6-a5e2-77d3a9604f5b',
              name: 'Tensioned Fabric Structures',
              tradeId: '13 31 23',
            },
            {
              id: '30a7d8cf-aa86-466c-a143-5caaee791b8a',
              name: 'Framed Fabric Structures',
              tradeId: '13 31 33',
            },
          ],
          tradeId: '13 31 00',
        },
        {
          id: '7417457f-fcd5-4b16-80d1-311da868feec',
          name: 'Space Frames',
          children: [
            {
              id: '840ff355-b02c-452a-87a3-f73c2469e58c',
              name: 'Space Frames',
              tradeId: '13 32 00',
            },
            {
              id: '1fe05f64-b1f4-44ae-96a0-bab4c1e4cbe6',
              name: 'Metal Space Frames',
              tradeId: '13 32 13',
            },
            {
              id: '293146d3-ce5d-40e5-a1f8-e35401451fc2',
              name: 'Wood Space Frames',
              tradeId: '13 32 23',
            },
          ],
          tradeId: '13 32 00',
        },
        {
          id: '81bf8921-5651-40e1-871a-4cde128b3e9b',
          name: 'Geodesic Structures',
          children: [
            {
              id: '3b6e7e53-da51-4e7b-b1ce-af4781fe53f3',
              name: 'Geodesic Structures',
              tradeId: '13 33 00',
            },
            {
              id: '3d7ac019-c798-495a-9c58-cd6c85501473',
              name: 'Geodesic Domes',
              tradeId: '13 33 13',
            },
          ],
          tradeId: '13 33 00',
        },
        {
          id: '184cbce1-d958-4738-a43e-32d7d6713876',
          name: 'Fabricated Engineered Structures',
          children: [
            {
              id: 'ee589799-3bce-41af-a414-b328741ccf85',
              name: 'Fabricated Engineered Structures',
              tradeId: '13 34 00',
            },
            {
              id: '1adc61ab-df6a-4113-b33d-490dc2688fc1',
              name: 'Glazed Structures',
              tradeId: '13 34 13',
            },
            {
              id: 'edf1a421-fa1f-46ec-b64d-c4ca44fa9d69',
              name: 'Greenhouses',
              tradeId: '13 34 13.13',
            },
            {
              id: '27e8a0de-bda6-4d5e-a642-b3dc9ce8b703',
              name: 'Solariums',
              tradeId: '13 34 13.16',
            },
            {
              id: '200a3b43-0a9d-4a2c-bf67-4ad56a81a3c3',
              name: 'Swimming Pool Enclosures',
              tradeId: '13 34 13.19',
            },
            {
              id: '6194f6ee-24a6-4aa1-9351-a36c1da03109',
              name: 'Sunrooms',
              tradeId: '13 34 13.23',
            },
            {
              id: '885f0c15-541d-4f0a-857f-b9696a8ce781',
              name: 'Conservatories',
              tradeId: '13 34 13.26',
            },
            {
              id: 'a7fa05e1-05d8-4b88-8eb1-b11b34b50af4',
              name: 'Grandstands and Bleachers',
              tradeId: '13 34 16',
            },
            {
              id: 'f1b97db1-28c2-4947-9c4c-0db25fcc5ca7',
              name: 'Grandstands',
              tradeId: '13 34 16.13',
            },
            {
              id: 'd2596321-0f61-4ef5-80fc-8172072742e4',
              name: 'Bleachers',
              tradeId: '13 34 16.53',
            },
            {
              id: 'aeb3121d-777e-4fb4-baae-96c8bb1ebbe1',
              name: 'Post Frame Building Systems',
              tradeId: '13 34 18',
            },
            {
              id: '0cbb05e4-f48c-4ad6-940e-3fe208157c33',
              name: 'Metal Building Systems',
              tradeId: '13 34 19',
            },
            {
              id: 'ce3a9448-f1df-4a84-b0cf-c93ef5285158',
              name: 'Fabricated Structures',
              tradeId: '13 34 23',
            },
            {
              id: '0da79fb6-28d6-4027-b705-f98d98763377',
              name: 'Portable and Mobile Buildings',
              tradeId: '13 34 23.13',
            },
            {
              id: '40fe4a70-8870-4ffd-8e32-ad3e5462eee6',
              name: 'Fabricated Classroom Buildings',
              tradeId: '13 34 23.14',
            },
            {
              id: 'cd7a0c39-058a-4abd-aec4-110dc08448d5',
              name: 'Fabricated Control Booths',
              tradeId: '13 34 23.16',
            },
            {
              id: '88a8a341-6718-4666-ae23-8198222e04d1',
              name: 'Fabricated Dome Structures',
              tradeId: '13 34 23.19',
            },
            {
              id: 'b9e41d86-daee-4394-9ba4-7785d4c197a5',
              name: 'Fabricated Substation Control Rooms',
              tradeId: '13 34 23.23',
            },
            {
              id: 'f6654595-8f31-4f3c-b2d1-fcd6f89b2ba2',
              name: 'Observatories',
              tradeId: '13 34 56',
            },
          ],
          tradeId: '13 34 00',
        },
        {
          id: '179d14c8-52d4-42d6-978a-ec4fed16628b',
          name: 'Rammed Earth Construction',
          children: [
            {
              id: '485dec8c-7868-4481-8ccd-5963a43c5208',
              name: 'Rammed Earth Construction',
              tradeId: '13 35 00',
            },
            {
              id: '282080a5-975a-4d1e-8b1f-8bd0405848d1',
              name: 'Rammed Earth Walls',
              tradeId: '13 35 13',
            },
            {
              id: 'e46597b0-5a17-4813-80ba-5ec0daf09519',
              name: 'Traditional Rammed Earth Walls',
              tradeId: '13 35 13.13',
            },
            {
              id: '8039c2f5-4c85-4bc5-8683-d53afc9197c1',
              name: 'Stabilized Insulated Rammed Earth Walls',
              tradeId: '13 35 13.23',
            },
          ],
          tradeId: '13 35 00',
        },
        {
          id: '2a57b89b-8f50-4c42-aba6-dcff209edf8f',
          name: 'Towers',
          children: [
            {
              id: 'ad76f582-52c1-446e-bd4a-3c87231d7cac',
              name: 'Towers',
              tradeId: '13 36 00',
            },
            {
              id: 'eab4ce82-d4e3-4109-88eb-8fc2326fb60b',
              name: 'Metal Towers',
              tradeId: '13 36 13',
            },
            {
              id: '990011e5-78dc-4812-8054-46b1ef4f6189',
              name: 'Steel Towers',
              tradeId: '13 36 13.13',
            },
            {
              id: '997a1178-ad98-4461-85a4-e5e56eb3c1c7',
              name: 'Wood Towers',
              tradeId: '13 36 23',
            },
          ],
          tradeId: '13 36 00',
        },
        {
          id: 'a8ca3686-3630-4929-bf2b-92492a4efcf8',
          name: 'Integrated Construction',
          children: [],
          tradeId: '13 40 00',
        },
        {
          id: 'ef8355cc-30db-43f7-a4c1-c91c8a6f1754',
          name: 'Building Modules',
          children: [
            {
              id: '55ff4d8d-0899-4974-a65d-82866372e046',
              name: 'Building Modules',
              tradeId: '13 42 00',
            },
            {
              id: 'b32ebd96-3d43-4158-a2f9-faa27065922b',
              name: 'Bathroom Unit Modules',
              tradeId: '13 42 13',
            },
            {
              id: 'e7becf60-c5a3-473c-ad66-6ae5c9cfdc6f',
              name: 'Hospitality Unit Modules',
              tradeId: '13 42 25',
            },
            {
              id: '8d7e759d-97be-4c56-be56-da58e604bda4',
              name: 'Apartment Unit Modules',
              tradeId: '13 42 33',
            },
            {
              id: '2feddd17-7521-4b75-901a-7290d066b361',
              name: 'Dormitory Unit Modules',
              tradeId: '13 42 43',
            },
            {
              id: '5bb5dad0-797d-4e08-a8c8-2a5d51eaa6e1',
              name: 'Education Unit Modules',
              tradeId: '13 42 46',
            },
            {
              id: 'e5388ca6-85bb-4afc-b778-8054b4230468',
              name: 'Detention Cell Modules',
              tradeId: '13 42 63',
            },
            {
              id: '696f4c54-9c2a-45eb-94eb-77745ce0b2f6',
              name: 'Precast-Concrete Detention Cell Modules',
              tradeId: '13 42 63.13',
            },
            {
              id: '4367335b-d505-4908-8a04-b98cf279d17d',
              name: 'Steel Detention Cell Modules',
              tradeId: '13 42 63.16',
            },
          ],
          tradeId: '13 42 00',
        },
        {
          id: '2f7dabc3-44d2-4032-a2cc-ae62fa7cbb92',
          name: 'Modular Mezzanines',
          children: [],
          tradeId: '13 44 00',
        },
        {
          id: '7101179c-f4b1-4098-bc49-2fa6e16d4956',
          name: 'Sound, Vibration and Seismic Control',
          children: [
            {
              id: '2842249e-0274-4e3f-a2b1-7a48d649bceb',
              name: 'Sound, Vibration and Seismic Control',
              tradeId: '13 48 00',
            },
            {
              id: 'd2d41d47-e944-4959-b902-5a29dbe8d408',
              name: 'Manufactured Sound and Vibration Control Components',
              tradeId: '13 48 13',
            },
            {
              id: '5ccde5bc-331a-43b0-8436-34052972a2a1',
              name: 'Fabricated Sound and Vibration Control Assemblies',
              tradeId: '13 48 23',
            },
            {
              id: 'a8ed2908-af46-4873-9ecd-9d2dc2923c40',
              name: 'Manufactured Seismic Control Components',
              tradeId: '13 48 53',
            },
            {
              id: '6d007de4-ee2f-42e3-9344-50cfd3c02710',
              name: 'Fabricated Seismic Control Assemblies',
              tradeId: '13 48 63',
            },
          ],
          tradeId: '13 48 00',
        },
        {
          id: 'a95cf13f-b8c7-4ae1-9468-4db5aa44fb17',
          name: 'Radiation Protection',
          children: [
            {
              id: 'fae02f50-d2c3-4990-b6ec-efdc366c7f9f',
              name: 'Radiation Protection',
              tradeId: '13 49 00',
            },
            {
              id: '366aa40c-54ba-470e-bd99-397dceac2777',
              name: 'Integrated X-Ray Shielding Assemblies',
              tradeId: '13 49 13',
            },
            {
              id: '4beaeaa1-ed69-4d5f-afc2-f1fd4243553c',
              name: 'Modular X-Ray Shielding Rooms',
              tradeId: '13 49 16',
            },
            {
              id: '952945a4-e782-48b2-8c3f-d51262105ad0',
              name: 'Integrated RFI/EMI Shielding Assemblies',
              tradeId: '13 49 23',
            },
            {
              id: 'c4058259-5a7b-481e-9305-4d1940450794',
              name: 'Modular RFI/EMI Shielding Rooms',
              tradeId: '13 49 26',
            },
          ],
          tradeId: '13 49 00',
        },
        {
          id: '9729185c-c743-47be-9c33-7cc521205867',
          name: 'Special Instrumentation',
          children: [],
          tradeId: '13 50 00',
        },
        {
          id: '52eaa6d4-d549-404b-9f9f-9e3dff98fe88',
          name: 'Stress Instrumentation',
          children: [],
          tradeId: '13 51 00',
        },
        {
          id: '9227c439-6d3c-44c1-8866-a34246c4b9d9',
          name: 'Seismic Instrumentation',
          children: [],
          tradeId: '13 52 00',
        },
        {
          id: 'ceec9890-7f46-4a56-8f92-b6dd13e46bca',
          name: 'Meteorological Instrumentation',
          children: [
            {
              id: '71dd2701-edf9-44b9-b598-bcdf92b8fbcd',
              name: 'Meteorological Instrumentation',
              tradeId: '13 53 00',
            },
            {
              id: 'd84c1b93-b94c-4dbc-8e6f-b0ff0bb3fe33',
              name: 'Solar Instrumentation',
              tradeId: '13 53 13',
            },
            {
              id: '9b39d577-e000-4e7f-8a7d-beeced695259',
              name: 'Wind Instrumentation',
              tradeId: '13 53 23',
            },
          ],
          tradeId: '13 53 00',
        },
      ],
      tradeId: '13 00 00',
    },
    {
      id: 'f8683096-1566-4ae3-9526-969f34c2ae4e',
      name: 'Conveying Equipment',
      children: [
        {
          id: 'a4c5f145-5f55-46ea-be9b-b5773e8420e8',
          name: 'Conveying Equipment',
          children: [],
          tradeId: '14 00 00',
        },
        {
          id: 'f774c922-39c0-45c2-afd1-7e8babc14347',
          name: 'Operation and Maintenance of Conveying Equipment',
          children: [
            {
              id: '72b47308-8366-49f2-a728-71ddabc08846',
              name: 'Operation and Maintenance of Conveying Equipment',
              tradeId: '14 01 00',
            },
            {
              id: '0d7d08a9-8f39-45be-bc25-1e33d8a508f6',
              name: 'Operation and Maintenance of Dumbwaiters',
              tradeId: '14 01 10',
            },
            {
              id: '240a04c6-d29b-48da-818c-f94067d15f78',
              name: 'Dumbwaiter Rehabilitation',
              tradeId: '14 01 10.71',
            },
            {
              id: '9b51f809-0063-45ee-b2fd-e6808f2020ac',
              name: 'Operation and Maintenance of Elevators',
              tradeId: '14 01 20',
            },
            {
              id: 'cdd88849-8434-4793-af2b-297a3cb9fc21',
              name: 'Elevator Rehabilitation',
              tradeId: '14 01 20.71',
            },
            {
              id: '3d237234-44eb-4b6d-bc61-7953c3754fe4',
              name: 'Operation and Maintenance of Escalators and Moving Walks',
              tradeId: '14 01 30',
            },
            {
              id: '093db0dd-dd67-4245-829b-d68bdf7c3ba5',
              name: 'Escalators and Moving Walks Rehabilitation',
              tradeId: '14 01 30.71',
            },
            {
              id: '0cc05a6f-fea0-4096-ae98-d4f2ffc9da4f',
              name: 'Operation and Maintenance of Lifts',
              tradeId: '14 01 40',
            },
            {
              id: 'f5a1f03d-5f0b-4586-970f-8334bdfcc0f0',
              name: 'Lift Rehabilitation',
              tradeId: '14 01 40.71',
            },
            {
              id: 'ad826fbc-bf9c-49ed-bb09-8e943400d465',
              name: 'Operation and Maintenance of Turntables',
              tradeId: '14 01 70',
            },
            {
              id: 'bc70bc2b-d6b0-49f7-adc7-5b2a7c356e04',
              name: 'Operation and Maintenance of Scaffolding',
              tradeId: '14 01 80',
            },
            {
              id: 'd3922773-c92a-47d8-9b20-8d72e564e59e',
              name: 'Operation and Maintenance of Other Conveying Equipment',
              tradeId: '14 01 90',
            },
          ],
          tradeId: '14 01 00',
        },
        {
          id: 'ac02feb0-d45f-47ff-83bd-6857303e1d60',
          name: 'Common Work Results for Conveying Equipment',
          children: [],
          tradeId: '14 05 00',
        },
        {
          id: 'fcbbe613-fdae-41e1-b3c8-0660a323103b',
          name: 'Schedules for Conveying Equipment',
          children: [
            {
              id: '50fcec50-a361-4aa3-8e12-f7a85682f3d0',
              name: 'Schedules for Conveying Equipment',
              tradeId: '14 06 00',
            },
            {
              id: '4ece2d05-b26e-4ebc-821b-49592fd55cd6',
              name: 'Schedules for Dumbwaiters',
              tradeId: '14 06 10',
            },
            {
              id: '833afe62-8042-4c52-a023-133051dbc95f',
              name: 'Schedules for Elevators',
              tradeId: '14 06 20',
            },
            {
              id: '9b0c4c2b-293c-4bf5-83e2-e12749bde752',
              name: 'Elevator Equipment Schedule',
              tradeId: '14 06 20.13',
            },
            {
              id: '299c83e3-08d0-40cc-b93e-caccf703cd5b',
              name: 'Schedules for Escalators and Moving Walks',
              tradeId: '14 06 30',
            },
            {
              id: 'cfe15be1-a02c-420d-be59-08b8603a259e',
              name: 'Schedules for Lifts',
              tradeId: '14 06 40',
            },
            {
              id: '062f9cfa-9794-4796-9726-eb57e60538df',
              name: 'Lift Schedule',
              tradeId: '14 06 40.13',
            },
            {
              id: '5c8fbbe1-76f3-485d-839c-2c3828f93386',
              name: 'Schedules for Turntables',
              tradeId: '14 06 70',
            },
            {
              id: '2d6176eb-9b8c-4c66-bdf9-6c5d3b2fd5d4',
              name: 'Schedules for Scaffolding',
              tradeId: '14 06 80',
            },
            {
              id: 'a5069dad-00e4-40d3-8d68-c64ad8a20a60',
              name: 'Schedules for Other Conveying Equipment',
              tradeId: '14 06 90',
            },
          ],
          tradeId: '14 06 00',
        },
        {
          id: 'bf0e9666-b152-478a-b092-3dbf26bc262e',
          name: 'Commissioning of Conveying Equipment',
          children: [
            {
              id: 'ede63032-18cc-4d8f-8731-1e05c3cff2ee',
              name: 'Commissioning of Conveying Equipment',
              tradeId: '14 08 00',
            },
            {
              id: '4e10ab31-de89-46c3-9894-cb3a0d080245',
              name: 'Commissioning of Dumbwaiters',
              tradeId: '14 08 10',
            },
            {
              id: 'b0e7c304-b110-4504-bbcc-95a6997f564c',
              name: 'Commissioning of Elevators',
              tradeId: '14 08 20',
            },
            {
              id: '61ffd445-9fb0-4ff4-b91b-054ef599f39f',
              name: 'Commissioning of Escalators and Moving Walks',
              tradeId: '14 08 30',
            },
            {
              id: '33956134-7d3b-42f6-9c01-171956831702',
              name: 'Commissioning of Lifts',
              tradeId: '14 08 40',
            },
            {
              id: '02c6bc59-790c-4002-9e25-23aae333ee56',
              name: 'Commissioning of Turntables',
              tradeId: '14 08 70',
            },
            {
              id: '9590c034-f9da-40ca-9519-b6f8fa33dde9',
              name: 'Commissioning of Scaffolding',
              tradeId: '14 08 80',
            },
          ],
          tradeId: '14 08 00',
        },
        {
          id: '5e6cc2d8-0e16-45cc-bfe9-616a5b035eb5',
          name: 'Dumbwaiters',
          children: [],
          tradeId: '14 10 00',
        },
        {
          id: '76cdefc3-c6fe-4e6b-87d4-020b89f4d6a3',
          name: 'Manual Dumbwaiters',
          children: [],
          tradeId: '14 11 00',
        },
        {
          id: 'cddcf2dc-a745-4a99-87e1-fba3a48c474e',
          name: 'Electric Dumbwaiters',
          children: [],
          tradeId: '14 12 00',
        },
        {
          id: '72e6b588-4f79-40ca-8c72-dde331997d2f',
          name: 'Hydraulic Dumbwaiters',
          children: [],
          tradeId: '14 14 00',
        },
        {
          id: '564809ce-1cb1-476c-a63d-0d7a12b16e2a',
          name: 'Elevators',
          children: [],
          tradeId: '14 20 00',
        },
        {
          id: 'cea105c0-a2e8-478c-b249-edf3acbb552a',
          name: 'Electric Traction Elevators',
          children: [
            {
              id: '1d1a20a8-a248-42b4-9124-4106d4d1f1e4',
              name: 'Electric Traction Elevators',
              tradeId: '14 21 00',
            },
            {
              id: '12db5cdc-85ce-4190-b8fc-db067a49012c',
              name: 'Electric Traction Freight Elevators',
              tradeId: '14 21 13',
            },
            {
              id: 'e00aaa31-e7fb-48dd-b33d-46e3d6a2dc2f',
              name: 'Electric Traction Passenger Elevators',
              tradeId: '14 21 23',
            },
            {
              id: 'df653083-02cb-4f0d-98af-ab527f4bca7c',
              name: 'Machine Room Electric Traction Passenger Elevators',
              tradeId: '14 21 23.13',
            },
            {
              id: 'c3428011-15e4-415b-80c6-9215a9e5dce6',
              name: 'Machine Room-Less Electric Traction Passenger Elevators',
              tradeId: '14 21 23.16',
            },
            {
              id: 'ff42737e-41be-4768-ab26-01ba92757239',
              name: 'Electric Traction Residential Elevators',
              tradeId: '14 21 33',
            },
            {
              id: '399d5da2-6fd7-4eab-8883-f71469e3e08f',
              name: 'Electric Traction Service Elevators',
              tradeId: '14 21 43',
            },
          ],
          tradeId: '14 21 00',
        },
        {
          id: '5929a290-dc15-4256-a9c6-208884b3069f',
          name: 'Hydraulic Elevators',
          children: [
            {
              id: '9f674468-74ab-4607-be7b-9d34b920ecb2',
              name: 'Hydraulic Elevators',
              tradeId: '14 24 00',
            },
            {
              id: 'ae4b7705-79fc-402d-a117-188392137cdd',
              name: 'Hydraulic Freight Elevators',
              tradeId: '14 24 13',
            },
            {
              id: '45d29bc2-fd32-49e5-a6cb-64894fbdda5b',
              name: 'Hydraulic Passenger Elevators',
              tradeId: '14 24 23',
            },
            {
              id: 'a5919a4d-248f-489e-bb38-c5c2f14d4ba0',
              name: 'Hydraulic Residential Elevators',
              tradeId: '14 24 33',
            },
            {
              id: '2746f197-3676-4167-b4dc-f460383e40b4',
              name: 'Hydraulic Service Elevators',
              tradeId: '14 24 43',
            },
          ],
          tradeId: '14 24 00',
        },
        {
          id: 'afaa653a-940a-4224-bc24-8b9f41bb46c4',
          name: 'Limited-Use/Limited-Application Elevators',
          children: [],
          tradeId: '14 26 00',
        },
        {
          id: '5278e849-5848-4325-8b44-0e66302967a0',
          name: 'Custom Elevator Cabs and Doors',
          children: [
            {
              id: 'ac10622d-6a14-44c6-b7d3-57ef7a1d0dd7',
              name: 'Custom Elevator Cabs and Doors',
              tradeId: '14 27 00',
            },
            {
              id: 'aa4c2262-02cf-43bd-98a2-e5218704afbf',
              name: 'Custom Elevator Cab Finishes',
              tradeId: '14 27 13',
            },
            {
              id: 'ac46aa34-320b-4faa-891a-2fcfffb43b69',
              name: 'Custom Elevator Doors',
              tradeId: '14 27 16',
            },
          ],
          tradeId: '14 27 00',
        },
        {
          id: '7155edda-bd8e-4f2b-aad4-05381f3ea119',
          name: 'Elevator Equipment and Controls',
          children: [
            {
              id: 'd5626d12-7e33-4c65-ac66-0074e01a7b32',
              name: 'Elevator Equipment and Controls',
              tradeId: '14 28 00',
            },
            {
              id: '201981d9-8947-40fa-ba76-2422da9f3d2f',
              name: 'Elevator Controls',
              tradeId: '14 28 16',
            },
            {
              id: 'ffee0546-ff8a-4e24-906e-0fe3b184060c',
              name: 'Elevator Equipment',
              tradeId: '14 28 19',
            },
            {
              id: '1cddea42-5759-4667-91fb-62c0eb9e9efc',
              name: 'Elevator Safety Equipment',
              tradeId: '14 28 19.13',
            },
            {
              id: '213f1fba-dd87-483c-ab9e-5334b61fcb12',
              name: 'Elevator Hoistway Equipment',
              tradeId: '14 28 19.16',
            },
          ],
          tradeId: '14 28 00',
        },
        {
          id: '5ef5e63c-250f-4dcb-b683-a6941841d3a4',
          name: 'Escalators and Moving Walks',
          children: [],
          tradeId: '14 30 00',
        },
        {
          id: 'f379b281-e828-4f29-ab8a-d25889e173e4',
          name: 'Escalators',
          children: [],
          tradeId: '14 31 00',
        },
        {
          id: 'ec594156-3869-45ee-99b6-4d6e0986f7e3',
          name: 'Moving Walks',
          children: [],
          tradeId: '14 32 00',
        },
        {
          id: 'e39ded65-a393-43d8-8f12-757fbd574a96',
          name: 'Moving Ramps',
          children: [
            {
              id: '24b70e38-d1d3-4614-a845-47a29f91d80b',
              name: 'Moving Ramps',
              tradeId: '14 33 00',
            },
            {
              id: '697626e4-5a51-43ed-ae52-df338d3a7ab9',
              name: 'Motorized Ramps',
              tradeId: '14 33 13',
            },
            {
              id: 'af588c13-c179-4d65-a008-7ded0362771d',
              name: 'Powered Ramps',
              tradeId: '14 33 16',
            },
          ],
          tradeId: '14 33 00',
        },
        {
          id: '47fd73bb-7ef7-4dff-ba8b-c028e2620388',
          name: 'Lifts',
          children: [],
          tradeId: '14 40 00',
        },
        {
          id: 'b7e5c4e0-a882-4113-842a-b9a7499e03e5',
          name: 'People Lifts',
          children: [
            {
              id: '9339e5f7-a246-45f2-a67d-10378ff72331',
              name: 'People Lifts',
              tradeId: '14 41 00',
            },
            {
              id: '5af265bc-2c40-4a20-aaaf-7df0d338addd',
              name: 'Counterbalanced People Lifts',
              tradeId: '14 41 13',
            },
            {
              id: 'cbc539e0-7548-4c85-a2b6-b3427a9b094c',
              name: 'Endless-Belt People Lifts',
              tradeId: '14 41 16',
            },
          ],
          tradeId: '14 41 00',
        },
        {
          id: 'c16e9724-a1ab-407c-aad3-c472ec6949ce',
          name: 'Wheelchair Lifts',
          children: [
            {
              id: 'fb4c3249-530e-4801-aa07-e74dc2b8b8f3',
              name: 'Wheelchair Lifts',
              tradeId: '14 42 00',
            },
            {
              id: '618e4986-b28c-4306-bb9c-915049466c76',
              name: 'Inclined Wheelchair Lifts',
              tradeId: '14 42 13',
            },
            {
              id: 'b2dabfc4-db88-47b2-a295-11fd6de5cfdf',
              name: 'Vertical Wheelchair Lifts',
              tradeId: '14 42 16',
            },
          ],
          tradeId: '14 42 00',
        },
        {
          id: 'ed46e170-7edb-49a7-8b68-779f144b28d8',
          name: 'Platform Lifts',
          children: [
            {
              id: '4fe75df7-a845-4ac7-ba4d-624e5b68c1d9',
              name: 'Platform Lifts',
              tradeId: '14 43 00',
            },
            {
              id: '2fcbc9d9-5ec1-4096-9e6f-092c8cf91be2',
              name: 'Orchestra Lifts',
              tradeId: '14 43 13',
            },
            {
              id: '11a7fee0-9a8a-4237-b140-f44098847eb1',
              name: 'Stage Lifts',
              tradeId: '14 43 16',
            },
          ],
          tradeId: '14 43 00',
        },
        {
          id: '641b42fb-a0e0-46a0-a7d1-095f3f69f011',
          name: 'Sidewalk Lifts',
          children: [],
          tradeId: '14 44 00',
        },
        {
          id: '6fcb8b5b-67b0-497d-a3fc-b834906b07a4',
          name: 'Vehicle Lifts',
          children: [],
          tradeId: '14 45 00',
        },
        {
          id: '2fa75695-6619-4990-8e91-252c2cfa6e4c',
          name: 'Turntables',
          children: [],
          tradeId: '14 70 00',
        },
        {
          id: '1d74d5cf-af8d-4357-aa00-5ae7464829fb',
          name: 'Industrial Turntables',
          children: [
            {
              id: '3a99a3c0-ca58-4119-be21-c0ea29958762',
              name: 'Industrial Turntables',
              tradeId: '14 71 00',
            },
            {
              id: '7c476691-c384-47ac-b0a2-f7c73ae4f8c6',
              name: 'Vehicle Turntables',
              tradeId: '14 71 11',
            },
          ],
          tradeId: '14 71 00',
        },
        {
          id: '597f4af4-2999-43ec-80af-39c92c13dc26',
          name: 'Hospitality Turntables',
          children: [
            {
              id: '79ab8699-6ec7-4af1-86ef-81e3f590f2e4',
              name: 'Hospitality Turntables',
              tradeId: '14 72 00',
            },
            {
              id: '48329bb7-3677-4bea-8715-915991938bf0',
              name: 'Restaurant Turntables',
              tradeId: '14 72 25',
            },
          ],
          tradeId: '14 72 00',
        },
        {
          id: '77c0365b-e66c-455f-8a2d-58ed64dc582b',
          name: 'Exhibit Turntables',
          children: [
            {
              id: 'b84e42c0-c8d5-40b2-9380-abf0623efb77',
              name: 'Exhibit Turntables',
              tradeId: '14 73 00',
            },
            {
              id: 'e952ef9c-9fdf-42e4-9622-bc2557b34f79',
              name: 'Display Turntables',
              tradeId: '14 73 59',
            },
          ],
          tradeId: '14 73 00',
        },
        {
          id: 'c2d08cb6-0805-4199-937c-2483266ce7a8',
          name: 'Entertainment Turntables',
          children: [
            {
              id: 'a30b4573-c39d-49ab-ac38-265e5a1a7458',
              name: 'Entertainment Turntables',
              tradeId: '14 74 00',
            },
            {
              id: 'e98e7a9b-3a27-40e0-9b51-fc349bfeac9d',
              name: 'Stage Turntables',
              tradeId: '14 74 61',
            },
          ],
          tradeId: '14 74 00',
        },
        {
          id: '7b7eb310-c130-4a57-b671-acb2ee04fc0e',
          name: 'Scaffolding',
          children: [],
          tradeId: '14 80 00',
        },
        {
          id: '70f4ed11-0b6b-4ef8-884d-938328ba629c',
          name: 'Suspended Scaffolding',
          children: [
            {
              id: 'bf48193a-c892-4b6a-8ea0-2f4c492b82f4',
              name: 'Suspended Scaffolding',
              tradeId: '14 81 00',
            },
            {
              id: 'faadb1f2-c49c-44c1-b15e-8f8784755533',
              name: 'Beam Scaffolding',
              tradeId: '14 81 13',
            },
            {
              id: '30437567-f186-4de9-ab60-a077b4eabbc2',
              name: 'Carriage Scaffolding',
              tradeId: '14 81 16',
            },
            {
              id: '87de6fda-de78-4d02-99d6-eb242e2fab20',
              name: 'Hook Scaffolding',
              tradeId: '14 81 19',
            },
          ],
          tradeId: '14 81 00',
        },
        {
          id: '360151cc-14e5-41f7-ba0c-31b6be5c83e5',
          name: 'Rope Climbers',
          children: [
            {
              id: '2ce773b7-2cdc-452c-9358-17c1279cc28b',
              name: 'Rope Climbers',
              tradeId: '14 82 00',
            },
            {
              id: '48dfe4f1-88f3-4f9f-a9c7-545e3690e32c',
              name: 'Manual Rope Climbers',
              tradeId: '14 82 13',
            },
            {
              id: 'a71405df-6950-45d1-a1c8-e493790f6ba9',
              name: 'Powered Rope Climbers',
              tradeId: '14 82 16',
            },
          ],
          tradeId: '14 82 00',
        },
        {
          id: '2f9bf5f3-3273-4e32-8b3c-d354b5860c22',
          name: 'Elevating Platforms',
          children: [
            {
              id: 'c212569c-1ead-448f-9571-620a3a94318a',
              name: 'Elevating Platforms',
              tradeId: '14 83 00',
            },
            {
              id: '1be56317-9375-41b6-af5e-2c3ea2c4d03d',
              name: 'Telescoping Platform Lifts',
              tradeId: '14 83 13',
            },
            {
              id: '07c32d42-cc9f-46ea-8cdb-17e45c968224',
              name: 'Electric and Battery Telescoping Platform Lifts',
              tradeId: '14 83 13.13',
            },
            {
              id: '2527ff23-1ac6-4139-a398-3a2818aa86a8',
              name: 'Pneumatic Telescoping Platform Lifts',
              tradeId: '14 83 13.16',
            },
            {
              id: 'c20f67c1-8c66-4f64-9569-c9d11e9c2eed',
              name: 'Scissor Lift Platforms',
              tradeId: '14 83 16',
            },
            {
              id: '336c45bc-f6c9-492c-bf27-e3b4c096847b',
              name: 'Multi-Axis Platform Lifts',
              tradeId: '14 83 19',
            },
          ],
          tradeId: '14 83 00',
        },
        {
          id: 'ceb7e99a-4cff-4ef0-8a32-59a34ba7a2fa',
          name: 'Powered Scaffolding',
          children: [
            {
              id: 'c2ff9deb-4cfe-4898-8458-949cad93755d',
              name: 'Powered Scaffolding',
              tradeId: '14 84 00',
            },
            {
              id: '124728da-7a12-4d3d-8712-025baebc3a10',
              name: 'Window Washing Scaffolding',
              tradeId: '14 84 13',
            },
            {
              id: '9adee0ce-7dd9-4386-b14e-30147eb842b9',
              name: 'Window Washing Hoists',
              tradeId: '14 84 23',
            },
          ],
          tradeId: '14 84 00',
        },
        {
          id: '59bc9158-8af5-4344-a6b5-ed40f4dbdf62',
          name: 'Other Conveying Equipment',
          children: [],
          tradeId: '14 90 00',
        },
        {
          id: '16b2f411-4090-41ce-b79c-17de932ae12a',
          name: 'Facility Chutes',
          children: [
            {
              id: '2a336e7f-26aa-4fa3-8ce8-c3f97cb43d8c',
              name: 'Facility Chutes',
              tradeId: '14 91 00',
            },
            {
              id: '67592b6d-0bc1-4c75-b967-03a643f42071',
              name: 'Coal Chutes',
              tradeId: '14 91 13',
            },
            {
              id: '41774b4d-ffda-46f5-ad83-1b297c9d1464',
              name: 'Escape Chutes',
              tradeId: '14 91 23',
            },
            {
              id: 'fed18c63-7573-4510-9011-57e7d5af2d07',
              name: 'Laundry and Linen Chutes',
              tradeId: '14 91 33',
            },
            {
              id: '04a9c750-ff6c-430f-bf6d-c9d812440b4c',
              name: 'Trash Chutes',
              tradeId: '14 91 82',
            },
          ],
          tradeId: '14 91 00',
        },
        {
          id: 'eb86344d-f37f-4ecf-9a45-a886a11527b8',
          name: 'Pneumatic Tube Systems',
          children: [],
          tradeId: '14 92 00',
        },
        {
          id: 'a581ac5e-f9f5-4e44-82aa-6df244e6ddd2',
          name: 'Slide Pole Systems',
          children: [
            {
              id: 'f096be0f-b61a-4aeb-ad4e-f97333ca1789',
              name: 'Slide Pole Systems',
              tradeId: '14 93 00',
            },
            {
              id: 'c91c7cd2-f9ea-4cb0-bc6c-2ab37c0dd57b',
              name: 'Firehouse Slide Poles',
              tradeId: '14 93 13',
            },
          ],
          tradeId: '14 93 00',
        },
      ],
      tradeId: '14 00 00',
    },
    {
      id: '4983c4cd-afaa-4ee8-8834-3b14e297ec4d',
      name: 'Fire Suppression',
      children: [
        {
          id: '9cd73e3e-6eec-4625-a4fc-fd32671df0ae',
          name: 'Fire Suppression',
          children: [],
          tradeId: '21 00 00',
        },
        {
          id: '74399917-cea4-4e25-8ce1-9335670bd001',
          name: 'Operation and Maintenance of Fire Suppression',
          children: [
            {
              id: '3676ff88-e13a-4b49-b905-2e02be77f839',
              name: 'Operation and Maintenance of Fire Suppression',
              tradeId: '21 01 00',
            },
            {
              id: '7da2f25e-0676-4bab-860f-10cd817f325b',
              name: 'Operation and Maintenance of Water-Based Fire-Suppression Systems',
              tradeId: '21 01 10',
            },
            {
              id: 'ad74ab36-9252-4df2-88f8-bf25e8817961',
              name: 'Operation and Maintenance of Fire-Extinguishing Systems',
              tradeId: '21 01 20',
            },
            {
              id: 'e316012c-3f97-4080-aaef-3eebb5fc55d7',
              name: 'Operation and Maintenance of Fire-Suppression Equipment',
              tradeId: '21 01 30',
            },
          ],
          tradeId: '21 01 00',
        },
        {
          id: 'ca661256-aeb8-4e0f-92eb-217de3027748',
          name: 'Common Work Results for Fire Suppression',
          children: [
            {
              id: 'd248c788-54b6-44d3-922a-824089a924bc',
              name: 'Common Work Results for Fire Suppression',
              tradeId: '21 05 00',
            },
            {
              id: '91965844-988f-4183-b65b-2522652e26e2',
              name: 'Common Motor Requirements for Fire-Suppression Equipment',
              tradeId: '21 05 13',
            },
            {
              id: 'a7164312-7a7c-4cdf-b079-dd310e289f41',
              name: 'Expansion Fittings and Loops for Fire-Suppression Piping',
              tradeId: '21 05 16',
            },
            {
              id: 'bf241759-b64f-4950-b871-67d15b3627eb',
              name: 'Meters and Gages for Fire-Suppression Systems',
              tradeId: '21 05 19',
            },
            {
              id: '10f39252-e478-47be-9cf1-88081de01305',
              name: 'General-Duty Valves for Water-Based Fire-Suppression Piping',
              tradeId: '21 05 23',
            },
            {
              id: 'f2e126d9-207e-4f97-a903-10717a18c5ec',
              name: 'Hangers and Supports for Fire-Suppression Piping and Equipment',
              tradeId: '21 05 29',
            },
            {
              id: 'eaa5ee39-6004-4919-8f28-d1e4bf4fc0cc',
              name: 'Heat Tracing for Fire-Suppression Piping',
              tradeId: '21 05 33',
            },
            {
              id: 'f0365f1b-9538-4f9b-8856-f8fe9f8b4c5c',
              name: 'Vibration and Seismic Controls for Fire-Suppression Piping and Equipment',
              tradeId: '21 05 48',
            },
            {
              id: '11009943-ac42-4c36-a3d8-aefc225e7712',
              name: 'Identification for Fire-Suppression Piping and Equipment',
              tradeId: '21 05 53',
            },
          ],
          tradeId: '21 05 00',
        },
        {
          id: '8d133724-c2ad-45e2-8763-ec4863a4cf2f',
          name: 'Schedules for Fire Suppression',
          children: [
            {
              id: '069d99db-d643-4d08-8e6c-140352a262d5',
              name: 'Schedules for Fire Suppression',
              tradeId: '21 06 00',
            },
            {
              id: 'd7bfbcab-c6bd-4431-963a-94edc984e290',
              name: 'Schedules for Water-Based Fire-Suppression Systems',
              tradeId: '21 06 10',
            },
            {
              id: 'e3539df9-e747-4474-9597-c7ade5b7dd1e',
              name: 'Schedules for Fire-Extinguishing Systems',
              tradeId: '21 06 20',
            },
            {
              id: '3a26dc58-84d9-42ca-9279-68010d15df85',
              name: 'Schedules for Fire-Suppression Equipment',
              tradeId: '21 06 30',
            },
          ],
          tradeId: '21 06 00',
        },
        {
          id: '0e5c6c18-83f5-45c2-b3e9-13644b2a5fb5',
          name: 'Fire Suppression Systems Insulation',
          children: [
            {
              id: 'b057ccd6-3f9a-4688-ac01-8d39a25d4c0f',
              name: 'Fire Suppression Systems Insulation',
              tradeId: '21 07 00',
            },
            {
              id: '8527af2d-4b45-4a39-a509-e55ed17a60d8',
              name: 'Fire-Suppression Equipment Insulation',
              tradeId: '21 07 16',
            },
            {
              id: '69eafbc3-7cc5-49b7-9fa6-8ce8d56b9cdd',
              name: 'Fire-Suppression Piping Insulation',
              tradeId: '21 07 19',
            },
          ],
          tradeId: '21 07 00',
        },
        {
          id: 'eecbc973-47b5-4770-9479-fe28a42eecc6',
          name: 'Commissioning of Fire Suppression',
          children: [],
          tradeId: '21 08 00',
        },
        {
          id: 'f9c571de-4854-4df7-a3de-0932d348fdd1',
          name: 'Instrumentation and Control for Fire-Suppression Systems',
          children: [],
          tradeId: '21 09 00',
        },
        {
          id: 'ba1c043e-9be5-416e-a83a-9cbbc67d5a10',
          name: 'Water-Based Fire-Suppression Systems',
          children: [],
          tradeId: '21 10 00',
        },
        {
          id: '003914f4-a6ff-4ffa-be78-3ffab4e9d6ec',
          name: 'Facility Fire-Suppression Water-Service Piping',
          children: [
            {
              id: '7d10f607-b1ab-4cfa-bee4-310c93f702b2',
              name: 'Facility Fire-Suppression Water-Service Piping',
              tradeId: '21 11 00',
            },
            {
              id: '38fa783c-d998-499a-84be-67f127c03551',
              name: 'Facility Fire Hydrants',
              tradeId: '21 11 16',
            },
            {
              id: '86568c65-41e1-4fff-abba-53d3969a5814',
              name: 'Fire-Department Connections',
              tradeId: '21 11 19',
            },
          ],
          tradeId: '21 11 00',
        },
        {
          id: 'fd7c73c5-2a68-4bcf-b4e3-3c859dc97301',
          name: 'Fire-Suppression Standpipes',
          children: [
            {
              id: 'dc3f4350-243d-46aa-9a12-a4a71840476a',
              name: 'Fire-Suppression Standpipes',
              tradeId: '21 12 00',
            },
            {
              id: 'b624a151-851c-48bc-a2e5-04a761611dfc',
              name: 'Fire-Suppression Hoses and Nozzles',
              tradeId: '21 12 13',
            },
            {
              id: '532cd687-2f08-4de7-8984-fc92fe88e65a',
              name: 'Fire-Suppression Hose Reels',
              tradeId: '21 12 16',
            },
            {
              id: 'ef9d132f-aace-44b4-be3d-7102ed8bb220',
              name: 'Fire-Suppression Hose Racks',
              tradeId: '21 12 19',
            },
            {
              id: '467ba7c4-93e4-480f-9a44-7f83aef64c27',
              name: 'Fire-Suppression Hose Valves',
              tradeId: '21 12 23',
            },
          ],
          tradeId: '21 12 00',
        },
        {
          id: 'd25aa4f0-f1f9-4cca-b460-5fb2ae3bb220',
          name: 'Fire-Suppression Sprinkler Systems',
          children: [
            {
              id: 'a5d7f9c0-f961-42e8-ba70-ec66f8e6c657',
              name: 'Fire-Suppression Sprinkler Systems',
              tradeId: '21 13 00',
            },
            {
              id: '63770ce0-91f0-4a49-b5cb-df6df4e46989',
              name: 'Wet-Pipe Sprinkler Systems',
              tradeId: '21 13 13',
            },
            {
              id: '325f65c3-ce3f-4418-8099-d31b18f0a54e',
              name: 'Dry-Pipe Sprinkler Systems',
              tradeId: '21 13 16',
            },
            {
              id: '0c292c2d-b052-4638-a042-a9b990a3ad6e',
              name: 'Preaction Sprinkler Systems',
              tradeId: '21 13 19',
            },
            {
              id: '9dd6ad29-419a-4fe1-b8b2-d466a4c38792',
              name: 'Combined Dry-Pipe and Preaction Sprinkler Systems',
              tradeId: '21 13 23',
            },
            {
              id: '5c707344-b2a9-459a-9b0e-252100e6273e',
              name: 'Deluge Fire-Suppression Sprinkler Systems',
              tradeId: '21 13 26',
            },
            {
              id: 'ba2ce25a-bf70-4a2a-a946-759acf44826f',
              name: 'Water Spray Fixed Systems',
              tradeId: '21 13 29',
            },
            {
              id: 'c283b529-7955-4e25-971f-a707835fe48d',
              name: 'Antifreeze Sprinkler Systems',
              tradeId: '21 13 36',
            },
            {
              id: '8906489e-25f7-4b2a-b3ec-a3fa6a959e09',
              name: 'Foam-Water Systems',
              tradeId: '21 13 39',
            },
          ],
          tradeId: '21 13 00',
        },
        {
          id: '498178e3-e027-461a-82b6-e2cbb08a1259',
          name: 'Fire-Suppression Pressure Maintenance Pumps',
          children: [],
          tradeId: '21 16 00',
        },
        {
          id: '4da86b1c-ee76-43ba-8cd9-c10744506338',
          name: 'Fire-Extinguishing Systems',
          children: [],
          tradeId: '21 20 00',
        },
        {
          id: '4eda6151-4ea4-46cd-b594-78292fa1c3cf',
          name: 'Carbon-Dioxide Fire-Extinguishing Systems',
          children: [
            {
              id: '50d2cc2a-084e-4c2c-8834-7f2176fd430b',
              name: 'Carbon-Dioxide Fire-Extinguishing Systems',
              tradeId: '21 21 00',
            },
            {
              id: '86f06017-9188-4b21-a1f9-82903ff38984',
              name: 'Carbon-Dioxide Fire-Extinguishing Piping',
              tradeId: '21 21 13',
            },
            {
              id: '298739c9-ca72-4a11-a494-3c1e5819a6e3',
              name: 'Carbon-Dioxide Fire-Extinguishing Equipment',
              tradeId: '21 21 16',
            },
          ],
          tradeId: '21 21 00',
        },
        {
          id: '9d5f046e-9a9b-4d58-b39a-c16226172c5c',
          name: 'Clean-Agent Fire-Extinguishing Systems',
          children: [
            {
              id: 'a564c432-7b9c-4d89-aaec-64c224b20d06',
              name: 'Clean-Agent Fire-Extinguishing Systems',
              tradeId: '21 22 00',
            },
            {
              id: '221a7c12-a2a6-4703-bda9-e33663ff85ef',
              name: 'Clean-Agent Fire-Extinguishing Piping',
              tradeId: '21 22 13',
            },
            {
              id: '760a67ff-c5ac-47b4-b6be-84bf10521545',
              name: 'Clean-Agent Fire-Extinguishing Equipment',
              tradeId: '21 22 16',
            },
          ],
          tradeId: '21 22 00',
        },
        {
          id: '35afca08-19f9-4ca5-aad9-e6cfbd7265aa',
          name: 'Wet-Chemical Fire-Extinguishing Systems',
          children: [
            {
              id: 'ba523225-f209-4a03-8a57-67c0b7748afe',
              name: 'Wet-Chemical Fire-Extinguishing Systems',
              tradeId: '21 23 00',
            },
            {
              id: '407f26e0-5d3b-4bdf-b1bc-d8cca226ed3e',
              name: 'Wet-Chemical Fire-Extinguishing Piping',
              tradeId: '21 23 13',
            },
            {
              id: '1c98ccd2-e77c-41c1-8b47-34bed4eb4730',
              name: 'Wet-Chemical Fire-Extinguishing Equipment',
              tradeId: '21 23 16',
            },
          ],
          tradeId: '21 23 00',
        },
        {
          id: 'dd2ebcd1-2d8e-4918-9b69-cfdd1d0121fc',
          name: 'Dry-Chemical Fire-Extinguishing Systems',
          children: [
            {
              id: '7780d003-58af-485a-9a6e-29947fcbcf11',
              name: 'Dry-Chemical Fire-Extinguishing Systems',
              tradeId: '21 24 00',
            },
            {
              id: '9e1ded10-907d-4d9a-9a7c-2cc6570933fe',
              name: 'Dry-Chemical Fire-Extinguishing Piping',
              tradeId: '21 24 13',
            },
            {
              id: '43dbd5fc-f321-447f-ad8e-cd1cb1a02321',
              name: 'Dry-Chemical Fire-Extinguishing Equipment',
              tradeId: '21 24 16',
            },
          ],
          tradeId: '21 24 00',
        },
        {
          id: 'fbd6dac0-c4f2-433a-ae7e-773308469fc0',
          name: 'Fire Pumps',
          children: [],
          tradeId: '21 30 00',
        },
        {
          id: 'bbbda051-9ada-4139-a77c-e47424b3a52c',
          name: 'Centrifugal Fire Pumps',
          children: [
            {
              id: '90a92677-71bf-466f-a4e8-941f70b68a2c',
              name: 'Centrifugal Fire Pumps',
              tradeId: '21 31 00',
            },
            {
              id: '4cc566d2-6dc4-49ae-b0be-825cf10d61f2',
              name: 'Electric-Drive, Centrifugal Fire Pumps',
              tradeId: '21 31 13',
            },
            {
              id: '0adaea83-7948-40dd-b6a7-4325a79cd723',
              name: 'Diesel-Drive, Centrifugal Fire Pumps',
              tradeId: '21 31 16',
            },
          ],
          tradeId: '21 31 00',
        },
        {
          id: 'acca3df9-20cd-4add-9aaf-d1f0a7b34d01',
          name: 'Vertical-Turbine Fire Pumps',
          children: [
            {
              id: '8b947d0e-df9a-4250-b569-451bead07342',
              name: 'Vertical-Turbine Fire Pumps',
              tradeId: '21 32 00',
            },
            {
              id: '90f52edf-8efc-44e8-9e8c-da13e5dd1502',
              name: 'Electric-Drive, Vertical-Turbine Fire Pumps',
              tradeId: '21 32 13',
            },
            {
              id: 'e682c01d-45f3-4b0c-b679-ba72394af0bd',
              name: 'Diesel-Drive, Vertical-Turbine Fire Pumps',
              tradeId: '21 32 16',
            },
          ],
          tradeId: '21 32 00',
        },
        {
          id: '158836d8-9840-4279-b394-0ec368ad0241',
          name: 'Positive-Displacement Fire Pumps',
          children: [
            {
              id: 'a6ed7ca7-f092-411b-87bd-1a1ed5ab3274',
              name: 'Positive-Displacement Fire Pumps',
              tradeId: '21 33 00',
            },
            {
              id: 'b3cf4fc9-b498-469f-99fd-847d2c514023',
              name: 'Electric-Drive, Positive-Displacement Fire Pumps',
              tradeId: '21 33 13',
            },
            {
              id: '918fa3fa-b006-437b-9366-a91590aa4408',
              name: 'Diesel-Drive, Positive-Displacement Fire Pumps',
              tradeId: '21 33 16',
            },
          ],
          tradeId: '21 33 00',
        },
        {
          id: '679cf798-50ef-4f3e-a814-6516a615b4e0',
          name: 'Fire-Suppression Water Storage',
          children: [],
          tradeId: '21 40 00',
        },
        {
          id: '3be26e2f-29a1-4385-85b5-83b22d787612',
          name: 'Storage Tanks for Fire-Suppression Water',
          children: [
            {
              id: 'eb4634ce-0e64-403b-a2cf-b65d3cc91ba7',
              name: 'Storage Tanks for Fire-Suppression Water',
              tradeId: '21 41 00',
            },
            {
              id: '4b958a14-dd51-45de-96ff-565268194142',
              name: 'Pressurized Storage Tanks for Fire-Suppression Water',
              tradeId: '21 41 13',
            },
            {
              id: 'cc3e5008-350c-434f-98ac-f0c89e559d89',
              name: 'Elevated Storage Tanks for Fire-Suppression Water',
              tradeId: '21 41 16',
            },
            {
              id: '5f3477c0-4451-47a6-af79-87fd6874ca73',
              name: 'Roof-Mounted Storage Tanks for Fire-Suppression Water',
              tradeId: '21 41 19',
            },
            {
              id: '526d5d04-f175-4f12-9cfe-289d8f77cc8d',
              name: 'Ground Suction Storage Tanks for Fire-Suppression Water',
              tradeId: '21 41 23',
            },
            {
              id: '014c0d10-61db-49d7-baab-8819a1144d89',
              name: 'Underground Storage Tanks for Fire-Suppression Water',
              tradeId: '21 41 26',
            },
            {
              id: '284d7662-21d2-4277-b1e1-f6dc4d86a723',
              name: 'Storage Tanks for Fire-Suppression Water Additives',
              tradeId: '21 41 29',
            },
          ],
          tradeId: '21 41 00',
        },
      ],
      tradeId: '21 00 00',
    },
    {
      id: '184a878d-4ff7-494e-85ba-1f48fb437111',
      name: 'Plumbing',
      children: [
        {
          id: '39257bf2-1586-4379-8642-3e815a1c7a35',
          name: 'Plumbing',
          children: [],
          tradeId: '22 00 00',
        },
        {
          id: 'ab2776f3-94d5-4053-8634-3e0fe5cb0b4b',
          name: 'Operation and Maintenance of Plumbing',
          children: [
            {
              id: 'a582bfde-b3f1-418b-b4db-8ef02bbd062b',
              name: 'Operation and Maintenance of Plumbing',
              tradeId: '22 01 00',
            },
            {
              id: '13814535-29a2-4b05-967d-ff1583911eb0',
              name: 'Operation and Maintenance of Plumbing Piping and Pumps',
              tradeId: '22 01 10',
            },
            {
              id: 'e1f42428-bb9c-4716-ae4c-be4dc979823c',
              name: 'Video Piping Inspections',
              tradeId: '22 01 10.16',
            },
            {
              id: '9fef71ce-adf6-4be0-99a2-26dff705f248',
              name: 'Plumbing Piping Cleaning',
              tradeId: '22 01 10.51',
            },
            {
              id: '3d691acd-5eeb-43bd-b7d0-58944fcd354d',
              name: 'Plumbing Piping Repairs',
              tradeId: '22 01 10.61',
            },
            {
              id: '61e3d35c-32a4-4ddb-8523-7c8b879aca39',
              name: 'Plumbing Piping Relining',
              tradeId: '22 01 10.62',
            },
            {
              id: '1da11191-6c4f-43ca-beb7-781bbab77bc1',
              name: 'Operation and Maintenance of Plumbing Equipment',
              tradeId: '22 01 30',
            },
            {
              id: '36f9eda0-690f-40fe-aa4e-f0135ff59dfb',
              name: 'Operation and Maintenance of Plumbing Fixtures',
              tradeId: '22 01 40',
            },
            {
              id: '4870ea57-e2db-4675-a3e1-a7db1bfe5c86',
              name: 'Operation and Maintenance of Pool and Fountain Plumbing Systems',
              tradeId: '22 01 50',
            },
            {
              id: '3d3b0abe-4917-4620-9893-f98632adfba6',
              name: 'Operation and Maintenance of Laboratory and Healthcare Systems',
              tradeId: '22 01 60',
            },
          ],
          tradeId: '22 01 00',
        },
        {
          id: 'e2149c4b-a50e-4b49-9e55-9554e375d881',
          name: 'Common Work Results for Plumbing',
          children: [
            {
              id: '680b21ef-b0e3-43bb-9b54-b93d9f210fa5',
              name: 'Common Work Results for Plumbing',
              tradeId: '22 05 00',
            },
            {
              id: '11767abf-d18a-45ed-950c-58f0deb889d9',
              name: 'Common Motor Requirements for Plumbing Equipment',
              tradeId: '22 05 13',
            },
            {
              id: '161f7b7b-5b7d-48a7-9062-956ab4b5e0f6',
              name: 'Expansion Fittings and Loops for Plumbing Piping',
              tradeId: '22 05 16',
            },
            {
              id: '75e052dd-dc04-4014-87db-4d69f019a5c9',
              name: 'Meters and Gages for Plumbing Piping',
              tradeId: '22 05 19',
            },
            {
              id: 'a2d48082-a0c0-4ad8-af46-ff44e291a36e',
              name: 'General-Duty Valves for Plumbing Piping',
              tradeId: '22 05 23',
            },
            {
              id: 'fc98c95d-c842-4f23-b448-ad00c3996380',
              name: 'Hangers and Supports for Plumbing Piping and Equipment',
              tradeId: '22 05 29',
            },
            {
              id: 'b9a23e84-4fe1-4455-9880-bf183fcf65eb',
              name: 'Heat Tracing for Plumbing Piping',
              tradeId: '22 05 33',
            },
            {
              id: 'ad923ed3-ae18-4fe9-95b3-ed6dad482ae7',
              name: 'Vibration and Seismic Controls for Plumbing Piping and Equipment',
              tradeId: '22 05 48',
            },
            {
              id: '5a1a921c-59fb-4da2-8f30-2910fd4ac32e',
              name: 'Identification for Plumbing Piping and Equipment',
              tradeId: '22 05 53',
            },
            {
              id: '09dbe451-ebc5-4a96-8a06-cd74674073d2',
              name: 'Facility Drainage Manholes',
              tradeId: '22 05 73',
            },
            {
              id: 'cdc3ba3c-e76a-436b-9fde-73162e0455d8',
              name: 'Facility Drainage Piping Cleanouts',
              tradeId: '22 05 76',
            },
          ],
          tradeId: '22 05 00',
        },
        {
          id: '91f7907f-2367-4fa8-873a-88088936852b',
          name: 'Schedules for Plumbing',
          children: [
            {
              id: '7bd8607e-e5bd-4127-956e-15b5e4bfa99a',
              name: 'Schedules for Plumbing',
              tradeId: '22 06 00',
            },
            {
              id: 'b6d3428c-3608-410b-b7a7-33c029cce5d9',
              name: 'Schedules for Plumbing Piping and Pumps',
              tradeId: '22 06 10',
            },
            {
              id: 'e2042262-8661-4114-91cf-264bd021db35',
              name: 'Plumbing Pump Schedule',
              tradeId: '22 06 10.13',
            },
            {
              id: 'd6b6fb43-ac7e-44cf-9080-bc6c0b625bee',
              name: 'Schedules for Facility Potable Water Storage',
              tradeId: '22 06 12',
            },
            {
              id: 'c38916ba-4391-4f41-a7ea-9275701fb855',
              name: 'Schedules for General Service Compressed-Air Equipment',
              tradeId: '22 06 15',
            },
            {
              id: 'fc7226e4-c70f-4dcb-9f57-ccf1e8d8686c',
              name: 'Schedules for Plumbing Equipment',
              tradeId: '22 06 30',
            },
            {
              id: '2caff66b-0b7c-4261-b071-fbc46884879b',
              name: 'Domestic Water Heater Schedule',
              tradeId: '22 06 30.13',
            },
            {
              id: '77ba4178-84fa-4933-803c-24f5470af531',
              name: 'Schedules for Plumbing Fixtures',
              tradeId: '22 06 40',
            },
            {
              id: '39113422-79da-4a09-b673-26c964a802ab',
              name: 'Plumbing Fixture Schedule',
              tradeId: '22 06 40.13',
            },
            {
              id: 'dbdf9c09-4ba2-4a15-b181-8f4bd6d40302',
              name: 'Schedules for Pool and Fountain Plumbing Systems',
              tradeId: '22 06 50',
            },
            {
              id: '7a408eb1-f01d-491c-b7e2-a30036ee8a6f',
              name: 'Schedules for Laboratory and Healthcare Systems',
              tradeId: '22 06 60',
            },
          ],
          tradeId: '22 06 00',
        },
        {
          id: '81c9f699-3bb5-4c59-b53b-5d95c5241fa9',
          name: 'Plumbing Insulation',
          children: [
            {
              id: '000577c6-6d39-44fa-9e70-8010ae2d0765',
              name: 'Plumbing Insulation',
              tradeId: '22 07 00',
            },
            {
              id: '1ae91a61-5674-4208-a6a9-589f170de1c8',
              name: 'Plumbing Equipment Insulation',
              tradeId: '22 07 16',
            },
            {
              id: '00b0a45e-4e0e-4a63-b121-de014d31050e',
              name: 'Plumbing Piping Insulation',
              tradeId: '22 07 19',
            },
          ],
          tradeId: '22 07 00',
        },
        {
          id: 'aa7722ee-fcea-46fb-964c-824b9e60a031',
          name: 'Commissioning of Plumbing',
          children: [],
          tradeId: '22 08 00',
        },
        {
          id: 'eeae1c03-86fb-40eb-850d-22e6e97cfe04',
          name: 'Instrumentation and Control for Plumbing',
          children: [],
          tradeId: '22 09 00',
        },
        {
          id: '7e94faac-cffa-4c30-9a01-5c1f62545447',
          name: 'Plumbing Piping',
          children: [],
          tradeId: '22 10 00',
        },
        {
          id: '00e09ea6-22c0-4dba-bc58-fc7c19947671',
          name: 'Facility Water Distribution',
          children: [
            {
              id: '735b6390-6467-4dd4-82a1-7989e799cae1',
              name: 'Facility Water Distribution',
              tradeId: '22 11 00',
            },
            {
              id: 'cb6e6c33-51e8-4c28-ae2f-02a000f485ac',
              name: 'Facility Water Distribution Piping',
              tradeId: '22 11 13',
            },
            {
              id: 'f00fb947-8c25-407b-b8ce-6485f76d421e',
              name: 'Domestic Water Piping',
              tradeId: '22 11 16',
            },
            {
              id: '93969730-dfa8-4546-8861-fa3fd5a7b8b6',
              name: 'Domestic Water Piping Specialties',
              tradeId: '22 11 19',
            },
            {
              id: '221c35d6-7f33-4264-a0f5-acfc17da773e',
              name: 'Domestic Water Pumps',
              tradeId: '22 11 23',
            },
            {
              id: '5b10f9c4-6219-4b70-967b-72cc4e6e0411',
              name: 'Domestic-Water Packaged Booster Pumps',
              tradeId: '22 11 23.13',
            },
            {
              id: '68ebac96-7aa9-4fc0-a8fe-75c1eaa8ff33',
              name: 'Close-Coupled, In-Line, Sealless Centrifugal Domestic-Water Pumps',
              tradeId: '22 11 23.23',
            },
            {
              id: '02b546fe-61a4-4c57-891a-bd18775908f4',
              name: 'Close-Coupled, Horizontally Mounted, In-Line Centrifugal Domestic-Water Pumps',
              tradeId: '22 11 23.26',
            },
            {
              id: '610e0f34-72f3-4c00-8cd3-d0c5da1096ba',
              name: 'Close-Coupled, Vertically Mounted, In-Line Centrifugal Domestic-Water Pumps',
              tradeId: '22 11 23.29',
            },
            {
              id: '903b68d9-87f2-4540-b9bd-a37f33129e8a',
              name: 'Separately Coupled, In-Line Centrifugal Domestic-Water Pumps',
              tradeId: '22 11 23.33',
            },
            {
              id: '5bf9f279-1b09-497a-a17d-11b6028e9f98',
              name: 'Separately Coupled, Horizontally Mounted, In-Line Centrifugal Domestic-Water Pumps',
              tradeId: '22 11 23.36',
            },
          ],
          tradeId: '22 11 00',
        },
        {
          id: '6d5de35c-5628-4825-9011-70ca67f98448',
          name: 'Facility Potable-Water Storage Tanks',
          children: [
            {
              id: 'a536f68f-6cc2-4150-b86b-101fdcd81701',
              name: 'Facility Potable-Water Storage Tanks',
              tradeId: '22 12 00',
            },
            {
              id: 'f2af9aaa-1b3e-44e8-8777-179c6f1b5abe',
              name: 'Facility Roof-Mounted, Potable-Water Storage Tanks',
              tradeId: '22 12 13',
            },
            {
              id: 'e493187b-a9aa-4804-979f-581754fa98ad',
              name: 'Facility Elevated, Potable-Water Storage Tanks',
              tradeId: '22 12 16',
            },
            {
              id: '50f3abad-b7f8-473f-bc6a-81e3d59e810f',
              name: 'Facility Ground-Mounted, Potable-Water Storage Tanks',
              tradeId: '22 12 19',
            },
            {
              id: '24f6226a-1ca8-4d7f-95ba-e21421aa1047',
              name: 'Facility Underground Potable-Water Storage Tanks',
              tradeId: '22 12 21',
            },
            {
              id: '1d23d7a9-dc4d-405d-a018-1fc0276e5192',
              name: 'Facility Indoor Potable-Water Storage Tanks',
              tradeId: '22 12 23',
            },
            {
              id: 'a19f614b-9745-44ba-bf90-936f8777bd7c',
              name: 'Facility Steel, Indoor Potable-Water Storage Pressure Tanks',
              tradeId: '22 12 23.13',
            },
            {
              id: 'aee5e058-a26b-4833-910e-3af352faf9d0',
              name: 'Facility Steel, Indoor Potable-Water Storage Non-Pressure Tanks',
              tradeId: '22 12 23.16',
            },
            {
              id: '8a545fa4-46d3-4609-a167-1fec246fec75',
              name: 'Facility Plastic, Indoor Potable-Water Storage Pressure Tanks',
              tradeId: '22 12 23.23',
            },
            {
              id: 'f438b400-1850-4b19-9a46-a86550e1bee8',
              name: 'Facility Plastic, Indoor Potable-Water Storage Non-Pressure Tanks',
              tradeId: '22 12 23.26',
            },
          ],
          tradeId: '22 12 00',
        },
        {
          id: '02595f0b-d6a4-4e0f-b175-caf1be0a9e42',
          name: 'Facility Sanitary Sewerage',
          children: [
            {
              id: '24b551a2-53bd-4ef3-9a3e-5d2c3d964384',
              name: 'Facility Sanitary Sewerage',
              tradeId: '22 13 00',
            },
            {
              id: 'd3250e1e-766f-437f-b4d8-60e86fcef49e',
              name: 'Facility Sanitary Sewers',
              tradeId: '22 13 13',
            },
            {
              id: 'aacddd6f-994e-4b20-ba65-cb237e346632',
              name: 'Sanitary Waste and Vent Piping',
              tradeId: '22 13 16',
            },
            {
              id: 'c45e8d27-dd49-4fa1-a407-ebfa41f775bb',
              name: 'Sanitary Waste Piping Specialties',
              tradeId: '22 13 19',
            },
            {
              id: '266e7dd6-92f9-43f8-a68b-39f0f3ef3087',
              name: 'Sanitary Drains',
              tradeId: '22 13 19.13',
            },
            {
              id: '16216879-2173-4dc9-8982-1eb42e5b9818',
              name: 'Fats, Oils and Grease Disposal Systems',
              tradeId: '22 13 19.23',
            },
            {
              id: 'b30877db-6a82-41dd-98a5-bcbc672f459b',
              name: 'Grease Removal Devices',
              tradeId: '22 13 19.26',
            },
            {
              id: '1d99d08c-54c0-499f-88d9-13023ba463fd',
              name: 'Backwater Valves',
              tradeId: '22 13 19.33',
            },
            {
              id: '6daea853-f6ad-462c-84ab-e98c612e6bd3',
              name: 'Air-Admittance Valves',
              tradeId: '22 13 19.36',
            },
            {
              id: '5b7af70a-a645-463e-a4f9-24db2ab5baf3',
              name: 'Sanitary Waste Interceptors',
              tradeId: '22 13 23',
            },
            {
              id: 'a87640bd-7569-4606-b095-6112ff1db1b0',
              name: 'Sanitary Waste Separators',
              tradeId: '22 13 26',
            },
            {
              id: '8760ff6f-7e62-481c-b9cf-547721ffed7f',
              name: 'Sanitary Sewerage Pumps',
              tradeId: '22 13 29',
            },
            {
              id: '7f333ac7-85b3-446c-973c-1aed8f85d899',
              name: 'Wet-Pit-Mounted, Vertical Sewerage Pumps',
              tradeId: '22 13 29.13',
            },
            {
              id: '3a614d90-d776-4f07-b8cd-18f81b01275d',
              name: 'Submersible Sewerage Pumps',
              tradeId: '22 13 29.16',
            },
            {
              id: '7bd7fff9-43ff-4df5-ad6a-dfdcd44bc4e9',
              name: 'Sewerage Pump Reverse-Flow Assemblies',
              tradeId: '22 13 29.23',
            },
            {
              id: '5bb977ac-c363-4b71-a5a0-ce739fa0c9cb',
              name: 'Sewerage Pump Basins and Pits',
              tradeId: '22 13 29.33',
            },
            {
              id: 'ade23bcd-9d33-4262-b90e-f70a7fabf72a',
              name: 'Packaged, Submersible Sewerage Pump Units',
              tradeId: '22 13 33',
            },
            {
              id: '4799db6a-8585-490d-8bdc-faa35ffd1845',
              name: 'Packaged, Wastewater Pump Units',
              tradeId: '22 13 36',
            },
            {
              id: '7298f337-9679-41ef-9e2f-cd98579d4b40',
              name: 'Facility Packaged Sewage Pumping Stations',
              tradeId: '22 13 43',
            },
            {
              id: '6e419a85-14c4-43d5-81ad-d5c00a237c8f',
              name: 'Facility Dry-Well Packaged Sewage Pumping Stations',
              tradeId: '22 13 43.13',
            },
            {
              id: '32433b45-c828-4840-b986-fe4b7ea3eebb',
              name: 'Facility Wet-Well Packaged Sewage Pumping Stations',
              tradeId: '22 13 43.16',
            },
            {
              id: '1ece9d6c-f3f3-4f1f-84b1-7c911fd663d7',
              name: 'Facility Septic Tanks',
              tradeId: '22 13 53',
            },
            {
              id: '20671d32-5848-4879-842f-79ffee247a30',
              name: 'Facility Gray Water Tanks',
              tradeId: '22 13 63',
            },
          ],
          tradeId: '22 13 00',
        },
        {
          id: '3fba7f6f-0eaa-4a6b-9807-ce4945f0dddc',
          name: 'Facility Storm Drainage',
          children: [
            {
              id: 'd03fbb7b-f217-44a3-98e0-044ba74239ef',
              name: 'Facility Storm Drainage',
              tradeId: '22 14 00',
            },
            {
              id: '8d22c825-ef7e-42ea-a336-c0f1016f1b50',
              name: 'Facility Storm Drainage Piping',
              tradeId: '22 14 13',
            },
            {
              id: '873230d2-57e5-4471-9bc0-8262499dac83',
              name: 'Rainwater Leaders',
              tradeId: '22 14 16',
            },
            {
              id: 'f0718660-43ba-46cc-af68-2a2ab7188e10',
              name: 'Sump Pump Discharge Piping',
              tradeId: '22 14 19',
            },
            {
              id: 'd34fedd0-6e24-4f5f-a587-6bcb1e62d994',
              name: 'Storm Drainage Piping Specialties',
              tradeId: '22 14 23',
            },
            {
              id: '2e03e0be-9d4c-4a0a-acd0-62aba9c2f659',
              name: 'Facility Storm Drains',
              tradeId: '22 14 26',
            },
            {
              id: '8a2aafbf-2aa7-4248-8a9c-9ddf6b6b342b',
              name: 'Roof Drains',
              tradeId: '22 14 26.13',
            },
            {
              id: 'bb7641ed-9a61-4c91-9eb4-b3e0ff18af14',
              name: 'Facility Area Drains',
              tradeId: '22 14 26.16',
            },
            {
              id: 'd1cdd5ec-f245-42e7-b73c-9c31ae40bbab',
              name: 'Facility Trench Drains',
              tradeId: '22 14 26.19',
            },
            {
              id: '03b8dced-8fb9-47aa-9b75-9c1a220a91a1',
              name: 'Sump Pumps',
              tradeId: '22 14 29',
            },
            {
              id: '689fe6e2-f6ae-4758-bd85-2a0b0b505217',
              name: 'Wet-Pit-Mounted, Vertical Sump Pumps',
              tradeId: '22 14 29.13',
            },
            {
              id: '2f68a4c7-39a7-4f10-b3b2-65079dbb242c',
              name: 'Submersible Sump Pumps',
              tradeId: '22 14 29.16',
            },
            {
              id: '3811822f-167f-43c8-945f-acdfdfd22d37',
              name: 'Sump-Pump Basins and Pits',
              tradeId: '22 14 29.19',
            },
            {
              id: 'f56dc5f1-37ed-48c5-a487-60393ee73843',
              name: 'Packaged, Pedestal Drainage Pump Units',
              tradeId: '22 14 33',
            },
            {
              id: 'a685d7ca-00a1-4e4a-a671-3f159c9193fe',
              name: 'Packaged, Submersible, Drainage Pump Units',
              tradeId: '22 14 36',
            },
            {
              id: '7b023e1d-e433-45ec-9744-3cb56661ff8f',
              name: 'Rainwater Storage Tanks',
              tradeId: '22 14 53',
            },
          ],
          tradeId: '22 14 00',
        },
        {
          id: '8a7e6aef-ff2d-43bd-8759-b46e4bcde8e0',
          name: 'General Service Compressed-Air Systems',
          children: [
            {
              id: '8ab5fbf2-c8e7-4acd-a781-2ce2bdf67fbc',
              name: 'General Service Compressed-Air Systems',
              tradeId: '22 15 00',
            },
            {
              id: '23ef9ca1-10cd-4d2c-a32f-8ea2365a8738',
              name: 'General Service Compressed-Air Piping',
              tradeId: '22 15 13',
            },
            {
              id: '6ed1c9ac-7151-4d65-8259-3d9bdc246189',
              name: 'General Service Compressed-Air Valves',
              tradeId: '22 15 16',
            },
            {
              id: '384e5afc-1499-4c0b-9d20-d0e308ab7ea2',
              name: 'General Service Packaged Air Compressors and Receivers',
              tradeId: '22 15 19',
            },
            {
              id: '2ea0990d-7038-4bd8-b670-ee9d336fb624',
              name: 'General Service Packaged Reciprocating Air Compressors',
              tradeId: '22 15 19.13',
            },
            {
              id: '8c82554c-9c6b-41d0-8683-3317797f6e65',
              name: 'General Service Packaged Liquid-Ring Air Compressors',
              tradeId: '22 15 19.16',
            },
            {
              id: 'a688d501-1914-4698-bb12-3e284fd9e115',
              name: 'General Service Packaged Rotary-Screw Air Compressors',
              tradeId: '22 15 19.19',
            },
            {
              id: '8874b6c2-7458-4ddc-a980-895656b51638',
              name: 'General Service Packaged Sliding-Vane Air Compressors',
              tradeId: '22 15 19.23',
            },
          ],
          tradeId: '22 15 00',
        },
        {
          id: 'f5e10c1d-0e7f-4b94-9a25-ab4a36a93980',
          name: 'Plumbing Equipment',
          children: [],
          tradeId: '22 30 00',
        },
        {
          id: 'e832cc26-61bc-409f-81d0-cb4f722b7c78',
          name: 'Domestic Water Softeners',
          children: [
            {
              id: '21d10d1e-3d7a-4aea-a98e-c2d38c61da24',
              name: 'Domestic Water Softeners',
              tradeId: '22 31 00',
            },
            {
              id: '597b7e04-66bf-4ac7-a984-c12cb680a67a',
              name: 'Residential Domestic Water Softeners',
              tradeId: '22 31 13',
            },
            {
              id: '1caccb7d-efc8-4517-972f-af5abc4361b5',
              name: 'Commercial Domestic Water Softeners',
              tradeId: '22 31 16',
            },
          ],
          tradeId: '22 31 00',
        },
        {
          id: '1d7b48b8-781a-4591-985b-26d121aa0290',
          name: 'Domestic Water Filtration Equipment',
          children: [
            {
              id: 'd0955cb4-c241-4a87-bef7-de8d6604b418',
              name: 'Domestic Water Filtration Equipment',
              tradeId: '22 32 00',
            },
            {
              id: '883d73bd-9502-40ee-beca-4c648f21c7a7',
              name: 'Domestic-Water Bag-Type Filters',
              tradeId: '22 32 13',
            },
            {
              id: '6ebdd28e-4b8d-4079-82f7-886936fc93b6',
              name: 'Domestic-Water Freestanding Cartridge Filters',
              tradeId: '22 32 16',
            },
            {
              id: '3ec8440d-a9d7-4b0d-b836-cd43dd80aaf5',
              name: 'Domestic-Water Off-Floor Cartridge Filters',
              tradeId: '22 32 19',
            },
            {
              id: 'e3830db2-6565-4a15-b540-e16b0376f972',
              name: 'Domestic-Water Carbon Filters',
              tradeId: '22 32 23',
            },
            {
              id: 'a995bbc4-bae2-4798-a889-3e590dcc3c32',
              name: 'Domestic-Water Sand Filters',
              tradeId: '22 32 26',
            },
            {
              id: 'f260987d-f9bb-4d13-9e96-b37c0e655d56',
              name: 'Domestic-Water Circulating Sand Filters',
              tradeId: '22 32 26.13',
            },
            {
              id: '3ac272dc-b866-498f-a0e3-821db5a306fe',
              name: 'Domestic-Water Multimedia Sand Filters',
              tradeId: '22 32 26.16',
            },
            {
              id: '1fdec704-1624-4cd4-a3f2-c847334b8c88',
              name: 'Domestic-Water Greensand Filters',
              tradeId: '22 32 26.19',
            },
          ],
          tradeId: '22 32 00',
        },
        {
          id: '324923a0-eb69-4391-8f84-4aef87ba676a',
          name: 'Electric Domestic Water Heaters',
          children: [
            {
              id: '6d26714b-a613-4082-a46f-54848e05bd64',
              name: 'Electric Domestic Water Heaters',
              tradeId: '22 33 00',
            },
            {
              id: '86c386b3-d238-4c90-adbe-e64714afdb98',
              name: 'Instantaneous Electric Domestic Water Heaters',
              tradeId: '22 33 13',
            },
            {
              id: '075f30c5-6f58-4f98-8191-6ab8c88cdf80',
              name: 'Flow-Control, Instantaneous Electric Domestic Water Heaters',
              tradeId: '22 33 13.13',
            },
            {
              id: 'b6f32e6a-0d48-47d6-bc78-5749e0448ba9',
              name: 'Thermostat-Control, Instantaneous Electric Domestic Water Heaters',
              tradeId: '22 33 13.16',
            },
            {
              id: 'ace0bac7-af52-434d-a7d4-5d964df7d9b6',
              name: 'Residential, Electric Domestic Water Heaters',
              tradeId: '22 33 30',
            },
            {
              id: 'ca2e03ac-c609-41f4-b221-3fc1204cc34d',
              name: 'Residential, Small-Capacity Electric Domestic Water Heaters',
              tradeId: '22 33 30.13',
            },
            {
              id: 'db813272-18cd-429f-b151-ca0e7c01c968',
              name: 'Residential, Storage Electric Domestic Water Heaters',
              tradeId: '22 33 30.16',
            },
            {
              id: 'de6459b1-3967-47b2-ac8c-c709e62a311e',
              name: 'Residential, Collector-to-Tank, Solar-Electric Domestic Water Heaters',
              tradeId: '22 33 30.23',
            },
            {
              id: 'ed1208fe-6856-4047-924c-df3428023a6d',
              name: 'Residential, Collector-to-Tank, Heat-Exchanger-Coil, Solar-Electric Domestic Water Heaters',
              tradeId: '22 33 30.26',
            },
            {
              id: '76b76d13-0013-4a73-9647-0329d12be47c',
              name: 'Light-Commercial Electric Domestic Water Heaters',
              tradeId: '22 33 33',
            },
            {
              id: 'ea77a387-fdae-4b6b-b2c5-0058fff6fe78',
              name: 'Commercial Domestic Water Electric Booster Heaters',
              tradeId: '22 33 36',
            },
            {
              id: '5d7fbab8-ccaa-47a5-aaf1-9fd07c681b41',
              name: 'Commercial Domestic Water Electric Booster Heaters',
              tradeId: '22 33 36.13',
            },
            {
              id: '21a8c653-55bb-4ad9-8c36-8358cefa7a3e',
              name: 'Commercial Storage Electric Domestic Water Heaters',
              tradeId: '22 33 36.16',
            },
          ],
          tradeId: '22 33 00',
        },
        {
          id: 'b3ac9212-e0a0-4985-ad3a-81c80e149acf',
          name: 'Fuel-Fired Domestic Water Heaters',
          children: [
            {
              id: 'b4a492fb-a6aa-4d7a-8f44-3e5032333519',
              name: 'Fuel-Fired Domestic Water Heaters',
              tradeId: '22 34 00',
            },
            {
              id: '24144775-f545-4c45-b144-2ca622523106',
              name: 'Instantaneous, Tankless, Gas Domestic Water Heaters',
              tradeId: '22 34 13',
            },
            {
              id: 'fa3194ed-8413-4c68-b965-89bac265973c',
              name: 'Residential Gas Domestic Water Heaters',
              tradeId: '22 34 30',
            },
            {
              id: '34b0d2b6-c9eb-4454-8473-5b81f9b08212',
              name: 'Residential, Atmospheric, Gas Domestic Water Heaters',
              tradeId: '22 34 30.13',
            },
            {
              id: '379f0dfa-7dc6-4a39-bcbf-f272b565d531',
              name: 'Residential, Direct-Vent, Gas Domestic Water Heaters',
              tradeId: '22 34 30.16',
            },
            {
              id: 'a967524b-3062-4285-82d5-6b1542cf79a2',
              name: 'Residential, Power-Vent, Gas Domestic Water Heaters',
              tradeId: '22 34 30.19',
            },
            {
              id: '2e32f747-af87-4435-839c-9ff79b9c5ba9',
              name: 'Commercial Gas Domestic Water Heaters',
              tradeId: '22 34 36',
            },
            {
              id: 'a424c33a-3a01-477a-9c51-5f9648b21baf',
              name: 'Commercial, Atmospheric, Gas Domestic Water Heaters',
              tradeId: '22 34 36.13',
            },
            {
              id: 'd24176ca-f41e-4ab6-a0a8-1e65efb70414',
              name: 'Commercial, Power-Burner, Gas Domestic Water Heaters',
              tradeId: '22 34 36.16',
            },
            {
              id: 'cf4e5b4a-211a-480e-821f-50a96e3aa512',
              name: 'Commercial, Power-Vent, Gas Domestic Water Heaters',
              tradeId: '22 34 36.19',
            },
            {
              id: 'fed57723-fe33-46ea-b84f-d40d7e544dce',
              name: 'Commercial, High-Efficiency, Gas Domestic Water Heaters',
              tradeId: '22 34 36.23',
            },
            {
              id: 'be4d562d-5053-4edd-b48a-2692763a2468',
              name: 'Commercial, Coil-Type, Finned-Tube, Gas Domestic Water Heaters',
              tradeId: '22 34 36.26',
            },
            {
              id: '3f8c3ca1-e1fb-49f2-8b99-483321908d60',
              name: 'Commercial, Grid-Type, Finned-Tube, Gas Domestic Water Heaters',
              tradeId: '22 34 36.29',
            },
            {
              id: '45552a46-b31f-45da-832c-01653e51d480',
              name: 'Oil-Fired Domestic Water Heaters',
              tradeId: '22 34 46',
            },
            {
              id: '99d599b2-bfe9-4bfd-bbbe-7d07244273fb',
              name: 'Large-Capacity, Oil-Fired Domestic Water Heaters',
              tradeId: '22 34 46.13',
            },
            {
              id: 'd4929d5c-2781-4dbc-83c0-8f0de4f21173',
              name: 'Dual-Fuel-Fired Domestic Water Heaters',
              tradeId: '22 34 56',
            },
          ],
          tradeId: '22 34 00',
        },
        {
          id: 'c5cebbdd-dc46-49f1-b660-61c8ab2bf2be',
          name: 'Domestic Water Heat Exchangers',
          children: [
            {
              id: 'ff509c4f-d81e-4048-bdc9-35203d0ffa61',
              name: 'Domestic Water Heat Exchangers',
              tradeId: '22 35 00',
            },
            {
              id: 'd990515e-21e7-4611-bca4-077694f4803f',
              name: 'Instantaneous Domestic Water Heat Exchangers',
              tradeId: '22 35 13',
            },
            {
              id: 'ca8b87db-c90e-47ad-a318-05840b3c5d3d',
              name: 'Heating-Fluid-in-Coil, Instantaneous Domestic Water Heat Exchangers',
              tradeId: '22 35 13.13',
            },
            {
              id: 'f27854c4-9b68-4efb-abf2-d271043a443c',
              name: 'Domestic-Water-in-Coil, Instantaneous Domestic Water Heat Exchangers',
              tradeId: '22 35 13.16',
            },
            {
              id: 'fabf98a6-feda-482a-bb23-f26d3df2f3c8',
              name: 'Heating-Fluid-in-U-Tube-Coil, Instantaneous Domestic Water Heat Exchangers',
              tradeId: '22 35 13.19',
            },
            {
              id: 'a58806ec-6f44-4088-a8c7-1c5fa7ca56ec',
              name: 'Circulating, Domestic Water Heat Exchangers',
              tradeId: '22 35 23',
            },
            {
              id: '9de2aa45-fd34-42c3-a530-07c685269be4',
              name: 'Circulating, Compact Domestic Water Heat Exchangers',
              tradeId: '22 35 23.13',
            },
            {
              id: 'd1102a03-1ca5-4dd4-82cd-1fd467c713e9',
              name: 'Circulating, Storage Domestic Water Heat Exchangers',
              tradeId: '22 35 23.16',
            },
            {
              id: '9a03beb6-5431-4797-8319-c07db741cdea',
              name: 'Noncirculating, Domestic Water Heat Exchangers',
              tradeId: '22 35 29',
            },
            {
              id: 'e63b089a-a46d-4dae-a01b-9b58f1e61316',
              name: 'Noncirculating, Compact Domestic Water Heat Exchangers',
              tradeId: '22 35 29.13',
            },
            {
              id: '907b34be-e5c1-42f8-82ef-0c8d6e441df3',
              name: 'Noncirculating, Storage Domestic Water Heat Exchangers',
              tradeId: '22 35 29.16',
            },
            {
              id: '32585a40-18b9-4902-bb8a-637f08c2f4c7',
              name: 'Domestic Water Brazed-Plate Heat Exchangers',
              tradeId: '22 35 36',
            },
            {
              id: 'b58e8798-f561-49c1-9453-4179deec12ac',
              name: 'Domestic Water Frame-and-Plate Heat Exchangers',
              tradeId: '22 35 39',
            },
            {
              id: 'ce44c3c5-5e7c-45b8-bc1f-7c3bb428602d',
              name: 'Domestic Water Heat Reclaimers',
              tradeId: '22 35 43',
            },
          ],
          tradeId: '22 35 00',
        },
        {
          id: '70bd2c34-32b6-4534-9bbf-44bd87a59fe3',
          name: 'Plumbing Fixtures',
          children: [],
          tradeId: '22 40 00',
        },
        {
          id: '79cf5f3e-9b1a-4a9c-84d6-a77b7a79e6c7',
          name: 'Residential Plumbing Fixtures',
          children: [
            {
              id: '0e9cd57f-8d00-4dde-93b0-6d6e450c3585',
              name: 'Residential Plumbing Fixtures',
              tradeId: '22 41 00',
            },
            {
              id: '45417e4d-487a-4c95-acb4-04de66a2ebfc',
              name: 'Residential Water Closets, Urinals and Bidets',
              tradeId: '22 41 13',
            },
            {
              id: '7a094b97-9032-452d-82e6-4bd25157cde2',
              name: 'Residential Water Closets',
              tradeId: '22 41 13.13',
            },
            {
              id: 'e5ab2f0c-2074-43cd-ad06-03678e5a4e73',
              name: 'Residential Urinals',
              tradeId: '22 41 13.16',
            },
            {
              id: '2c7e2c1a-4522-41a3-bd7d-e1366850c633',
              name: 'Residential Bidets',
              tradeId: '22 41 13.19',
            },
            {
              id: 'c04fe3ce-d2b7-4d4c-a04a-ac3573f99c82',
              name: 'Residential Lavatories and Sinks',
              tradeId: '22 41 16',
            },
            {
              id: 'e2259b02-dab7-46e5-aa99-8aaa357b2f45',
              name: 'Residential Lavatories',
              tradeId: '22 41 16.13',
            },
            {
              id: '72463501-cca9-4f06-9204-c913e24b19dd',
              name: 'Residential Sinks',
              tradeId: '22 41 16.16',
            },
            {
              id: '1054b226-1793-4a73-bc7f-c75cd5e16f73',
              name: 'Residential Bathtubs',
              tradeId: '22 41 19',
            },
            {
              id: 'bd56679f-ad45-4280-a1f4-64964fe75675',
              name: 'Residential Showers',
              tradeId: '22 41 23',
            },
            {
              id: '72fdd534-9471-4ab3-b9f3-4c8f3b32ca81',
              name: 'Residential Disposers',
              tradeId: '22 41 26',
            },
            {
              id: '89871b1c-c685-4a8b-9af7-068e8c3868cd',
              name: 'Residential Laundry Trays',
              tradeId: '22 41 36',
            },
            {
              id: '9cf8c1f8-a420-4bed-92cc-f37dd1c2a88e',
              name: 'Residential Faucets, Supplies and Trim',
              tradeId: '22 41 39',
            },
          ],
          tradeId: '22 41 00',
        },
        {
          id: 'abf817c1-3fff-4c4e-9509-1198a4066990',
          name: 'Commercial Plumbing Fixtures',
          children: [
            {
              id: 'cc341afc-3c28-4f54-9877-a3ccad3b8424',
              name: 'Commercial Plumbing Fixtures',
              tradeId: '22 42 00',
            },
            {
              id: '7ac6e8f1-2dbb-4b53-b3cb-438a7c0a8e31',
              name: 'Commercial Water Closets, Urinals and Bidets',
              tradeId: '22 42 13',
            },
            {
              id: '4bbf653e-93ea-4032-af0d-0877782941d1',
              name: 'Commercial Water Closets',
              tradeId: '22 42 13.13',
            },
            {
              id: '7868a803-dd80-4a84-b4f6-dc5661bd08f6',
              name: 'Commercial Urinals',
              tradeId: '22 42 13.16',
            },
            {
              id: '10f3fa2f-d008-49dd-aa28-a723ecee159b',
              name: 'Commercial Lavatories and Sinks',
              tradeId: '22 42 16',
            },
            {
              id: 'c92e213d-1835-42a4-be98-2c09f1ae853a',
              name: 'Commercial Lavatories',
              tradeId: '22 42 16.13',
            },
            {
              id: '46bf80e8-7945-475e-99f0-b7d97bc47845',
              name: 'Commercial Sinks',
              tradeId: '22 42 16.16',
            },
            {
              id: 'b31ae6c6-2b87-4095-b988-07ff5b29b7c3',
              name: 'Commercial Bathtubs',
              tradeId: '22 42 19',
            },
            {
              id: 'b379174e-aab7-4773-bc15-17248231c945',
              name: 'Commercial Showers',
              tradeId: '22 42 23',
            },
            {
              id: '33d1e2d5-7461-47cc-a11a-953448e23ccd',
              name: 'Commercial Disposers',
              tradeId: '22 42 26',
            },
            {
              id: '95073dcf-775a-4de0-8a53-5cfddb68aec2',
              name: 'Shampoo Bowls',
              tradeId: '22 42 29',
            },
            {
              id: '18b3b420-7142-4993-9fcd-dc81cf703511',
              name: 'Wash Fountains',
              tradeId: '22 42 33',
            },
            {
              id: '64c2de07-c7fe-4629-9ae1-4b0833d62a8a',
              name: 'Commercial Laundry Trays',
              tradeId: '22 42 36',
            },
            {
              id: 'f1dde476-c807-4dd1-9d93-7ac1f28cf9a2',
              name: 'Commercial Faucets, Supplies and Trim',
              tradeId: '22 42 39',
            },
            {
              id: '0d64536b-0537-4b0b-8d13-26f776c81619',
              name: 'Flushometers',
              tradeId: '22 42 43',
            },
          ],
          tradeId: '22 42 00',
        },
        {
          id: '8a52dd29-29f2-49df-b0c2-824e230ef23d',
          name: 'Healthcare Plumbing Fixtures',
          children: [
            {
              id: 'd4b1662d-f23d-490a-ba77-db28bde9570b',
              name: 'Healthcare Plumbing Fixtures',
              tradeId: '22 43 00',
            },
            {
              id: '5f1b722b-6375-415e-b5fc-ecf1255a3de5',
              name: 'Healthcare Water Closets',
              tradeId: '22 43 13',
            },
            {
              id: '94158d38-4a1a-467e-bbee-3e61de6bf37f',
              name: 'Healthcare Sinks',
              tradeId: '22 43 16',
            },
            {
              id: 'a55e4c2f-d588-414b-a5c4-f95cca9df339',
              name: 'Healthcare Bathtubs',
              tradeId: '22 43 19',
            },
            {
              id: 'e37d6f63-5022-43a4-848b-f0023d932ac5',
              name: 'Healthcare Showers',
              tradeId: '22 43 23',
            },
            {
              id: 'b0e8722e-57d2-461e-853e-670673505e03',
              name: 'Healthcare Faucets',
              tradeId: '22 43 39',
            },
            {
              id: '12c2e062-c7a4-4312-97a3-067a6cc799af',
              name: 'Healthcare Plumbing Fixture Flushometers',
              tradeId: '22 43 43',
            },
          ],
          tradeId: '22 43 00',
        },
        {
          id: 'a40a9e1a-e360-4d29-abe6-d848f7cbd942',
          name: 'Emergency Plumbing Fixtures',
          children: [
            {
              id: '870f786b-3b75-4a10-ad7a-2bc8f9fc87d9',
              name: 'Emergency Plumbing Fixtures',
              tradeId: '22 45 00',
            },
            {
              id: '3fb8e90a-784c-46d9-afc0-f1eb0ff6f083',
              name: 'Emergency Showers',
              tradeId: '22 45 13',
            },
            {
              id: '185990f5-0ee6-44f3-990e-8a84adc2fd57',
              name: 'Eyewash Equipment',
              tradeId: '22 45 16',
            },
            {
              id: '0cc5e227-73d4-46cf-9b9d-42846f525321',
              name: 'Self-Contained Eyewash Equipment',
              tradeId: '22 45 19',
            },
            {
              id: 'ad671149-5004-47ee-adb7-1f0ce19e1bd0',
              name: 'Personal Eyewash Equipment',
              tradeId: '22 45 23',
            },
            {
              id: '657eaef1-ab85-4dcf-abd5-fb9f975506ac',
              name: 'Eye/Face Wash Equipment',
              tradeId: '22 45 26',
            },
            {
              id: '11497158-8408-46e0-8064-85228a6156e7',
              name: 'Hand-Held Emergency Drench Hoses',
              tradeId: '22 45 29',
            },
            {
              id: 'e17619e5-86d5-48a8-aea7-bb4598bf1db4',
              name: 'Combination Emergency Fixture Units',
              tradeId: '22 45 33',
            },
            {
              id: '26aedd51-8e21-4431-b7e3-de4b0436805f',
              name: 'Emergency Fixture Water-Tempering Equipment',
              tradeId: '22 45 36',
            },
          ],
          tradeId: '22 45 00',
        },
        {
          id: '59e1d0db-f657-4192-a9af-b3d54ad14bfb',
          name: 'Security Plumbing Fixtures',
          children: [
            {
              id: 'f5b1b42d-a9aa-49ea-bae8-5386c2b5d471',
              name: 'Security Plumbing Fixtures',
              tradeId: '22 46 00',
            },
            {
              id: '18085697-14c1-4ced-b938-c47e491937fc',
              name: 'Security Water Closets and Urinals',
              tradeId: '22 46 13',
            },
            {
              id: 'c26eafba-5d13-438e-b0d0-60961d344309',
              name: 'Security Water Closets',
              tradeId: '22 46 13.13',
            },
            {
              id: '56dc4ab9-af33-48af-b414-32e23f1c2df1',
              name: 'Security Urinals',
              tradeId: '22 46 13.16',
            },
            {
              id: '8656be2a-303c-451e-81e4-2824fb5fbdb5',
              name: 'Security Lavatories and Sinks',
              tradeId: '22 46 16',
            },
            {
              id: '763aa854-1249-425d-b2cf-340284ce83de',
              name: 'Security Lavatories',
              tradeId: '22 46 16.13',
            },
            {
              id: '9cdceb5f-8dbc-4b8c-bdd7-9c59e7a980ed',
              name: 'Security Sinks',
              tradeId: '22 46 16.16',
            },
            {
              id: 'da8cdc57-d1c7-4913-9dce-59862bc86075',
              name: 'Security Showers',
              tradeId: '22 46 19',
            },
            {
              id: 'c7bf446b-aa8d-4cdd-aa06-e07b66f68871',
              name: 'Security Faucets, Supplies and Trim',
              tradeId: '22 46 39',
            },
            {
              id: '73368e62-9724-4777-95d4-97805c381472',
              name: 'Security Plumbing Fixture Flushometers',
              tradeId: '22 46 43',
            },
            {
              id: '883134d6-869f-4d1b-87ef-fdb09ce4e450',
              name: 'Security Plumbing Fixture Supports',
              tradeId: '22 46 53',
            },
          ],
          tradeId: '22 46 00',
        },
        {
          id: 'cca2ef41-f441-4417-bff0-70bba7df25a7',
          name: 'Drinking Fountains and Water Coolers',
          children: [
            {
              id: '01c0fef6-3f6f-43ac-954b-3ab28369d88a',
              name: 'Drinking Fountains and Water Coolers',
              tradeId: '22 47 00',
            },
            {
              id: 'c03918df-914d-4aec-9e4a-c0ca19657c36',
              name: 'Drinking Fountains',
              tradeId: '22 47 13',
            },
            {
              id: '23a5f697-ba9d-4004-881a-fe0c21d65b34',
              name: 'Pressure Water Coolers',
              tradeId: '22 47 16',
            },
            {
              id: '16bccf7c-2825-4b40-93ca-a588fc82a50c',
              name: 'Water-Station Water Coolers',
              tradeId: '22 47 19',
            },
            {
              id: '9a38ffd0-3136-4f9e-94c2-9e7f944ec0c0',
              name: 'Remote Water Coolers',
              tradeId: '22 47 23',
            },
          ],
          tradeId: '22 47 00',
        },
        {
          id: '72c5e359-8d2a-42c5-b9f0-928d49bf71d3',
          name: 'Pool and Fountain Plumbing Systems',
          children: [],
          tradeId: '22 50 00',
        },
        {
          id: '7fd639f8-a1cf-403c-8467-1af3e5618e65',
          name: 'Swimming Pool Plumbing Systems',
          children: [
            {
              id: '7f2d1213-9c23-449e-b067-9f65c314a2a1',
              name: 'Swimming Pool Plumbing Systems',
              tradeId: '22 51 00',
            },
            {
              id: '08b6b707-06f7-4a27-9149-4126ff4f4c3e',
              name: 'Swimming Pool Piping',
              tradeId: '22 51 13',
            },
            {
              id: '312fa225-310f-40b1-8e83-4c44f7d4bc1e',
              name: 'Swimming Pool Pumps',
              tradeId: '22 51 16',
            },
            {
              id: '798ab574-f491-4d0a-8646-7a681fa5c814',
              name: 'Swimming Pool Water Treatment Equipment',
              tradeId: '22 51 19',
            },
            {
              id: '57366337-0ad1-4be9-9d06-643235c6f9ed',
              name: 'Swimming Pool Equipment Controls',
              tradeId: '22 51 23',
            },
          ],
          tradeId: '22 51 00',
        },
        {
          id: 'aacc69bf-1c8c-4c36-b844-dc9b73b8c824',
          name: 'Fountain Plumbing Systems',
          children: [
            {
              id: 'a7b1f1bf-fd0d-4197-b3ce-3c73a3328d9a',
              name: 'Fountain Plumbing Systems',
              tradeId: '22 52 00',
            },
            {
              id: '02643aa8-7d01-4915-adab-4799cae13336',
              name: 'Fountain Piping',
              tradeId: '22 52 13',
            },
            {
              id: 'b5152a44-fa42-4c5e-91a0-4f243748e336',
              name: 'Fountain Pumps',
              tradeId: '22 52 16',
            },
            {
              id: '1151fbd0-1495-43d0-8ae7-ba654307d9c3',
              name: 'Fountain Water Treatment Equipment',
              tradeId: '22 52 19',
            },
            {
              id: 'ffad483f-09a8-43cd-b8a4-52dc2318c183',
              name: 'Fountain Equipment Controls',
              tradeId: '22 52 23',
            },
          ],
          tradeId: '22 52 00',
        },
        {
          id: 'de5f6d49-86d8-4d04-bf07-408b17146c60',
          name: 'Gas and Vacuum Systems for Laboratory and Healthcare Facilities',
          children: [],
          tradeId: '22 60 00',
        },
        {
          id: 'cd4d2317-9d9c-477f-94d6-69cb47a22fc5',
          name: 'Compressed-Air Systems for Laboratory and Healthcare Facilities',
          children: [
            {
              id: '577b9be9-d3c3-4e74-9a95-d55c0068b4b9',
              name: 'Compressed-Air Systems for Laboratory and Healthcare Facilities',
              tradeId: '22 61 00',
            },
            {
              id: 'be041d78-d4e1-4855-be94-1b7be4f0f467',
              name: 'Compressed-Air Piping for Laboratory and Healthcare Facilities',
              tradeId: '22 61 13',
            },
            {
              id: '6d4711c4-0559-4954-89a5-4cb01803fc72',
              name: 'Laboratory Compressed-Air Piping',
              tradeId: '22 61 13.53',
            },
            {
              id: 'a1a880d3-5a69-4c5d-8e4a-8e440ac9fff5',
              name: 'Healthcare Compressed-Air Piping',
              tradeId: '22 61 13.70',
            },
            {
              id: '8ce0b649-8bce-42e1-a33f-0114d049b6c9',
              name: 'Dental Compressed-Air Piping',
              tradeId: '22 61 13.74',
            },
            {
              id: '08f87f7c-bb0e-43ea-abd4-16f26a8c6ef3',
              name: 'Compressed-Air Equipment for Laboratory and Healthcare Facilities',
              tradeId: '22 61 19',
            },
            {
              id: '500df075-41d1-45eb-ace7-763eb9d01a6d',
              name: 'Laboratory Compressed-Air Equipment',
              tradeId: '22 61 19.53',
            },
            {
              id: 'c0b96345-d4c8-4ed8-bccc-95a4e4677d17',
              name: 'Healthcare Compressed-Air Equipment',
              tradeId: '22 61 19.70',
            },
            {
              id: '63ecf409-de8f-46d4-a96c-900eee321cbb',
              name: 'Dental Compressed-Air Equipment',
              tradeId: '22 61 19.74',
            },
          ],
          tradeId: '22 61 00',
        },
        {
          id: '676484b8-00c8-4786-9ce1-1cce9ac87d49',
          name: 'Vacuum Systems for Laboratory and Healthcare Facilities',
          children: [
            {
              id: '791b2ace-a6d3-4dd9-a072-94c827157970',
              name: 'Vacuum Systems for Laboratory and Healthcare Facilities',
              tradeId: '22 62 00',
            },
            {
              id: '30dd98b8-8a96-4357-b454-3dcf0cd41d47',
              name: 'Vacuum Piping for Laboratory and Healthcare Facilities',
              tradeId: '22 62 13',
            },
            {
              id: '4191dbe4-bcb4-4bf8-a5b9-94182bcad402',
              name: 'Laboratory Vacuum Piping',
              tradeId: '22 62 13.53',
            },
            {
              id: 'f206e400-e7bf-4adb-aec2-a074f76b8466',
              name: 'Healthcare, Surgical Vacuum Piping',
              tradeId: '22 62 13.70',
            },
            {
              id: '08fc3bd6-b210-4091-8475-d4bbfed987de',
              name: 'Dental Vacuum Piping',
              tradeId: '22 62 13.74',
            },
            {
              id: '62573110-6812-48ea-b0c7-5fb92b25f451',
              name: 'Vacuum Equipment for Laboratory and Healthcare Facilities',
              tradeId: '22 62 19',
            },
            {
              id: '7548fa40-26cb-49dd-9242-fcf6bcb611f8',
              name: 'Laboratory Vacuum Equipment',
              tradeId: '22 62 19.53',
            },
            {
              id: '6e31cbb8-3b01-4420-b638-83db245de032',
              name: 'Healthcare Vacuum Equipment',
              tradeId: '22 62 19.70',
            },
            {
              id: '450bb91e-7f49-40ce-9255-1097db9117db',
              name: 'Dental Vacuum and Evacuation Equipment',
              tradeId: '22 62 19.74',
            },
            {
              id: 'ee608bdb-9871-4cc4-9f8a-4bcafb52f46a',
              name: 'Waste Anesthesia-Gas Piping',
              tradeId: '22 62 23',
            },
          ],
          tradeId: '22 62 00',
        },
        {
          id: '32e1fd1e-d1fe-46da-9cd8-2b919153eb5a',
          name: 'Gas Systems for Laboratory and Healthcare Facilities',
          children: [
            {
              id: 'e00203ed-3af1-460c-a133-db30f2a37107',
              name: 'Gas Systems for Laboratory and Healthcare Facilities',
              tradeId: '22 63 00',
            },
            {
              id: '2d094f84-92f5-4bec-97bc-d7acf3773271',
              name: 'Gas Piping for Laboratory and Healthcare Facilities',
              tradeId: '22 63 13',
            },
            {
              id: '5a98b430-63e8-43a5-8619-f81af3ac8a87',
              name: 'Laboratory Gas Piping',
              tradeId: '22 63 13.53',
            },
            {
              id: 'f479eb66-f007-4030-a562-46f5e63d48e1',
              name: 'Healthcare Gas Piping',
              tradeId: '22 63 13.70',
            },
            {
              id: '4bee16fb-220e-4acc-8239-a0b52e304da4',
              name: 'Gas Storage Tanks for Laboratory and Healthcare Facilities',
              tradeId: '22 63 19',
            },
            {
              id: 'ad67119c-78f1-4b9d-9bc0-fb8f24634894',
              name: 'Laboratory Gas Storage Tanks',
              tradeId: '22 63 19.53',
            },
            {
              id: 'c6a7acac-7492-441b-8b00-310597da5b7d',
              name: 'Healthcare Gas Storage Tanks',
              tradeId: '22 63 19.70',
            },
          ],
          tradeId: '22 63 00',
        },
        {
          id: 'e77ec9ea-80f7-4ce7-955a-91c3f0b3567f',
          name: 'Chemical-Waste Systems for Laboratory and Healthcare Facilities',
          children: [
            {
              id: '1fc92f69-2339-4f93-b760-a7c51808733f',
              name: 'Chemical-Waste Systems for Laboratory and Healthcare Facilities',
              tradeId: '22 66 00',
            },
            {
              id: 'a4f9d30d-ed0b-4cac-9220-ba6e6dd70bf4',
              name: 'Laboratory Chemical-Waste and Vent Piping',
              tradeId: '22 66 53',
            },
            {
              id: '635c3857-0ea6-40e1-b5c5-d27f2a13eea4',
              name: 'Healthcare Chemical-Waste and Vent Piping',
              tradeId: '22 66 70',
            },
            {
              id: '24124fd7-feae-4972-86d1-551924823db4',
              name: 'Chemical-Waste Tanks',
              tradeId: '22 66 83',
            },
            {
              id: '9a05cbb9-4e7a-40b2-9300-4b6a511677bd',
              name: 'Chemical-Waste Dilution Tanks',
              tradeId: '22 66 83.13',
            },
            {
              id: 'fed83bea-5193-48aa-8721-753fcba73f76',
              name: 'Chemical-Waste Neutralization Tanks',
              tradeId: '22 66 83.16',
            },
          ],
          tradeId: '22 66 00',
        },
        {
          id: '97e7428c-610f-4eb6-94ec-220df23ba427',
          name: 'Processed Water Systems for Laboratory and Healthcare Facilities',
          children: [
            {
              id: 'd5eff755-99b8-4f20-8b86-7b11ff1d00ed',
              name: 'Processed Water Systems for Laboratory and Healthcare Facilities',
              tradeId: '22 67 00',
            },
            {
              id: 'f2308d05-ebc1-4655-bc28-7fa85b6b907c',
              name: 'Processed Water Piping for Laboratory and Healthcare Facilities',
              tradeId: '22 67 13',
            },
            {
              id: '71fb5b98-933c-4736-84ee-eec6ce0746c0',
              name: 'Distilled-Water Piping',
              tradeId: '22 67 13.13',
            },
            {
              id: 'b2cc1436-505c-4fc0-9e48-4fc57776ac49',
              name: 'Reverse-Osmosis Water Piping',
              tradeId: '22 67 13.16',
            },
            {
              id: '649f0bdb-b02a-44a7-92fe-56bc8d0ca928',
              name: 'Deionized-Water Piping',
              tradeId: '22 67 13.19',
            },
            {
              id: '6883659a-7514-4282-ada6-b17aa8af55d5',
              name: 'Processed Water Equipment for Laboratory and Healthcare Facilities',
              tradeId: '22 67 19',
            },
            {
              id: '35c823a0-5158-4e96-a7b6-6bae6fd13af2',
              name: 'Distilled-Water Equipment',
              tradeId: '22 67 19.13',
            },
            {
              id: 'a513f52e-e593-4ec5-8ec6-eb2ee1b0ffd1',
              name: 'Reverse-Osmosis Water Equipment',
              tradeId: '22 67 19.16',
            },
            {
              id: '43d2098e-1b7c-48cf-8794-c70cf29d70eb',
              name: 'Deionized-Water Equipment',
              tradeId: '22 67 19.19',
            },
          ],
          tradeId: '22 67 00',
        },
      ],
      tradeId: '22 00 00',
    },
    {
      id: 'cd1f82b4-f02f-4b90-b049-d3f3f28e6ffe',
      name: 'Heating, Ventilating and Air Conditioning (HVAC)',
      children: [
        {
          id: 'cc449f01-a2a6-461f-80dd-fba9fd1207ca',
          name: 'Heating, Ventilating and Air Conditioning (HVAC)',
          children: [],
          tradeId: '23 00 00',
        },
        {
          id: '056b4e50-277e-440b-a106-9cc582a0add5',
          name: 'Operation and Maintenance of HVAC Systems',
          children: [
            {
              id: '00ced83e-789d-4bb3-938e-597b1bfa5128',
              name: 'Operation and Maintenance of HVAC Systems',
              tradeId: '23 01 00',
            },
            {
              id: '378eb647-8b90-40bf-9182-de0302d0dca7',
              name: 'Operation and Maintenance of Facility Fuel Systems',
              tradeId: '23 01 10',
            },
            {
              id: '239c0b31-1e8c-4aea-956b-a9d3d588504c',
              name: 'Operation and Maintenance of HVAC Piping and Pumps',
              tradeId: '23 01 20',
            },
            {
              id: '6149e9e2-7e63-448e-a706-7a30f383a58f',
              name: 'Operation and Maintenance of HVAC Air Distribution',
              tradeId: '23 01 30',
            },
            {
              id: '38069ab3-314b-47be-8e9a-d42efdfa3f20',
              name: 'HVAC Air-Distribution System Cleaning',
              tradeId: '23 01 30.51',
            },
            {
              id: '6e4e9816-f548-4245-b690-635eb2c4fccc',
              name: 'Operation and Maintenance of Central Heating Equipment',
              tradeId: '23 01 50',
            },
            {
              id: '7ce4576f-1709-41ea-a41b-a92f484d39e7',
              name: 'Operation and Maintenance of Central Cooling Equipment',
              tradeId: '23 01 60',
            },
            {
              id: '6f2ea24a-bc2c-48e1-a8ec-3ae1c4997e33',
              name: 'Refrigerant Recovery/Recycling',
              tradeId: '23 01 60.71',
            },
            {
              id: '20a7aa67-643a-47fe-a755-67b68efeb9a0',
              name: 'Operation and Maintenance of Central HVAC Equipment',
              tradeId: '23 01 70',
            },
            {
              id: '1d347abb-2ae5-4225-ad92-6664f37b97b9',
              name: 'Operation and Maintenance of Decentralized HVAC Equipment',
              tradeId: '23 01 80',
            },
            {
              id: '049cd4cd-3758-4536-b7c7-72563fd20140',
              name: 'Diagnostic Systems for HVAC',
              tradeId: '23 01 90',
            },
          ],
          tradeId: '23 01 00',
        },
        {
          id: '34e808ad-b2e1-4f9e-9ffb-57a0723ebac3',
          name: 'Common Work Results for HVAC',
          children: [
            {
              id: 'd826fafc-a82c-4b66-9b08-b1df45f0f209',
              name: 'Common Work Results for HVAC',
              tradeId: '23 05 00',
            },
            {
              id: '0281a15c-59a7-4704-98e2-03249f0e299b',
              name: 'Common Motor Requirements for HVAC Equipment',
              tradeId: '23 05 13',
            },
            {
              id: 'd6899616-03b3-4c9b-988d-eadb6b0ba419',
              name: 'Expansion Fittings and Loops for HVAC Piping',
              tradeId: '23 05 16',
            },
            {
              id: 'beb608f9-f032-4e5f-94a4-1e522aaba77f',
              name: 'Meters and Gages for HVAC Piping',
              tradeId: '23 05 19',
            },
            {
              id: '9fe10641-25d2-4932-a017-fe658f75186b',
              name: 'General-Duty Valves for HVAC Piping',
              tradeId: '23 05 23',
            },
            {
              id: 'b3137dd5-f058-4570-b68b-e98743913aff',
              name: 'Hangers and Supports for HVAC Piping and Equipment',
              tradeId: '23 05 29',
            },
            {
              id: 'd412c8ae-186b-4c7c-9cf7-30633e2a8e29',
              name: 'Heat Tracing for HVAC Piping',
              tradeId: '23 05 33',
            },
            {
              id: '60c6f958-3d60-416b-bcc3-c9afd123b055',
              name: 'Vibration and Seismic Controls for HVAC',
              tradeId: '23 05 48',
            },
            {
              id: '0d9e067f-7fc4-44d6-813a-a91836045e75',
              name: 'Identification for HVAC Piping and Equipment',
              tradeId: '23 05 53',
            },
            {
              id: '9f73e709-4f89-4788-888a-7d18dddadd9d',
              name: 'Anti-Microbial Coatings for HVAC Ducts and Equipment',
              tradeId: '23 05 63',
            },
            {
              id: '2ecc871c-a253-4a7f-83d5-de0f4a3ae7ba',
              name: 'Anti-Microbial Ultraviolet Emitters for HVAC Ducts and Equipment',
              tradeId: '23 05 66',
            },
            {
              id: 'dd928815-b5ab-4bec-aef0-cd81e7a641a0',
              name: 'Testing, Adjusting and Balancing for HVAC',
              tradeId: '23 05 93',
            },
          ],
          tradeId: '23 05 00',
        },
        {
          id: 'be724929-7261-4e34-897e-d64a70aa72d7',
          name: 'Schedules for HVAC',
          children: [
            {
              id: 'a305dbf0-2908-4c6a-b419-ff9458109c41',
              name: 'Schedules for HVAC',
              tradeId: '23 06 00',
            },
            {
              id: 'eeca0840-0bfe-4ebc-a591-ef7ddf63cd2a',
              name: 'Schedules for Facility Fuel Service Systems',
              tradeId: '23 06 10',
            },
            {
              id: 'ef1eea76-1ca2-4c6c-b24a-bc0e10a90e18',
              name: 'Schedules for HVAC Piping and Pumps',
              tradeId: '23 06 20',
            },
            {
              id: '9c1f6e13-3a2f-443e-984b-e315cb8ead76',
              name: 'Hydronic Pump Schedule',
              tradeId: '23 06 20.13',
            },
            {
              id: '2cc56d22-b3af-4eb1-9e24-39d199890573',
              name: 'Schedules for HVAC Air Distribution',
              tradeId: '23 06 30',
            },
            {
              id: 'f37de416-9e48-42ec-b3cc-99e374c04602',
              name: 'HVAC Fan Schedule',
              tradeId: '23 06 30.13',
            },
            {
              id: 'dd9d9f28-8e8a-4801-ba43-904ccaccaa2f',
              name: 'Air Terminal Unit Schedule',
              tradeId: '23 06 30.16',
            },
            {
              id: '3cb6ae67-ea74-400b-9f88-a9c4a0159f76',
              name: 'Air Outlet and Inlet Schedule',
              tradeId: '23 06 30.19',
            },
            {
              id: 'ac71ff07-8502-4c25-a667-9696e4b3dfdd',
              name: 'HVAC Air Cleaning Device Schedule',
              tradeId: '23 06 30.23',
            },
            {
              id: '1a8c3b54-695a-4ac4-a0b1-24761a74cca3',
              name: 'Schedules for Central Heating Equipment',
              tradeId: '23 06 50',
            },
            {
              id: 'c5659d44-514f-420d-9d8e-ef810b52ce8a',
              name: 'Heating Boiler Schedule',
              tradeId: '23 06 50.13',
            },
            {
              id: 'a45b278f-39bf-40d9-8f35-dcf95acb636e',
              name: 'Schedules for Central Cooling Equipment',
              tradeId: '23 06 60',
            },
            {
              id: '721fc211-0607-476b-ac21-2d74ca83f160',
              name: 'Refrigerant Condenser Schedule',
              tradeId: '23 06 60.13',
            },
            {
              id: 'b9ec35a9-dc33-4d5a-890b-76275fc0fec4',
              name: 'Packaged Water Chiller Schedule',
              tradeId: '23 06 60.16',
            },
            {
              id: '1954c7b1-f261-434d-80b6-bc1f4896bd6f',
              name: 'Schedules for Central HVAC Equipment',
              tradeId: '23 06 70',
            },
            {
              id: 'e1b35b13-f509-4e26-b8e1-6451e6188174',
              name: 'Indoor, Central-Station Air-Handling Unit Schedule',
              tradeId: '23 06 70.13',
            },
            {
              id: '4c797f99-1749-45fc-887d-9edac238fb26',
              name: 'Packaged Outdoor HVAC Equipment Schedule',
              tradeId: '23 06 70.16',
            },
            {
              id: 'e720b8e3-723f-4ee6-834b-cc1aa7b40261',
              name: 'Schedules for Decentralized HVAC Equipment',
              tradeId: '23 06 80',
            },
            {
              id: 'f8024825-7c60-4a58-9355-db7b1506b182',
              name: 'Decentralized Unitary HVAC Equipment Schedule',
              tradeId: '23 06 80.13',
            },
            {
              id: '8108e269-1cb2-4cc8-9062-20aec106564b',
              name: 'Convection Heating and Cooling Unit Schedule',
              tradeId: '23 06 80.16',
            },
            {
              id: '1c0752ca-6952-4063-aed3-5d296603653a',
              name: 'Radiant Heating Unit Schedule',
              tradeId: '23 06 80.19',
            },
          ],
          tradeId: '23 06 00',
        },
        {
          id: '98046131-edad-41cb-8fda-3a3e706eeedc',
          name: 'HVAC Insulation',
          children: [
            {
              id: '085547c9-e3e6-40e7-b10d-841628fc1689',
              name: 'HVAC Insulation',
              tradeId: '23 07 00',
            },
            {
              id: 'a5946caa-b878-4709-b35b-86bddcacbbb3',
              name: 'Duct Insulation',
              tradeId: '23 07 13',
            },
            {
              id: '255318f8-9c4a-403f-be9f-b04181794e24',
              name: 'HVAC Equipment Insulation',
              tradeId: '23 07 16',
            },
            {
              id: '21868bcf-4109-462f-be8c-eea93380e455',
              name: 'HVAC Piping Insulation',
              tradeId: '23 07 19',
            },
          ],
          tradeId: '23 07 00',
        },
        {
          id: 'e7f4fe27-87f6-4425-8429-9583dff8346c',
          name: 'Commissioning of HVAC',
          children: [],
          tradeId: '23 08 00',
        },
        {
          id: '1261bb47-7679-4144-9849-9a43c477bd1f',
          name: 'Instrumentation and Control for HVAC',
          children: [
            {
              id: '75e81712-8207-4868-b921-1f40b879239c',
              name: 'Instrumentation and Control for HVAC',
              tradeId: '23 09 00',
            },
            {
              id: '10484a37-24c7-43c1-af31-1601a787ef30',
              name: 'Instrumentation and Control Devices for HVAC',
              tradeId: '23 09 13',
            },
            {
              id: '9fdf6fa8-38cb-48b8-b1f6-e4f7c2e96494',
              name: 'Actuators and Operators',
              tradeId: '23 09 13.13',
            },
            {
              id: 'df294103-e59f-4cc0-a8d4-677cd2b99758',
              name: 'Sensors and Transmitters',
              tradeId: '23 09 13.23',
            },
            {
              id: 'ba855904-99a0-49b9-b3c9-7ba4dafd2177',
              name: 'Control Valves',
              tradeId: '23 09 13.33',
            },
            {
              id: '703fe54a-de59-413d-8fce-55c42a6f15b8',
              name: 'Control Dampers',
              tradeId: '23 09 13.43',
            },
            {
              id: '66eeaaf6-2258-43a2-8bb3-3935ca12ae64',
              name: 'Direct-Digital Control System for HVAC',
              tradeId: '23 09 23',
            },
            {
              id: '2db21668-97c0-47dd-b5cf-659b4d4fecc2',
              name: 'Electric and Electronic Control System for HVAC',
              tradeId: '23 09 33',
            },
            {
              id: '5af7392c-fd4b-45c0-b3d5-bef62ce8e96e',
              name: 'Pneumatic Control System for HVAC',
              tradeId: '23 09 43',
            },
            {
              id: '433d2b1e-49fd-4957-b973-2ea90156caea',
              name: 'Pneumatic and Electric Control System for HVAC',
              tradeId: '23 09 53',
            },
            {
              id: '75a1115f-6192-46b2-aa6b-8878d9d1625d',
              name: 'Sequence of Operations for HVAC Controls',
              tradeId: '23 09 93',
            },
          ],
          tradeId: '23 09 00',
        },
        {
          id: '1316461e-0a16-4a3e-9f08-6cc06509d599',
          name: 'Facility Fuel Systems',
          children: [],
          tradeId: '23 10 00',
        },
        {
          id: 'd64d3bf1-8226-46b5-a136-c3cd7351deec',
          name: 'Facility Fuel Piping',
          children: [
            {
              id: '594a29b1-e85c-434e-a38a-9fec80f169a4',
              name: 'Facility Fuel Piping',
              tradeId: '23 11 00',
            },
            {
              id: '349081d9-0d89-4584-aacd-1e40905a55f0',
              name: 'Facility Fuel-Oil Piping',
              tradeId: '23 11 13',
            },
            {
              id: '61844ad1-4c8f-403a-8c60-89b25af1bcd9',
              name: 'Facility Gasoline Piping',
              tradeId: '23 11 16',
            },
            {
              id: '63d378d2-a41b-4d67-b6e6-0ac4d37708b0',
              name: 'Facility Natural-Gas Piping',
              tradeId: '23 11 23',
            },
            {
              id: '5c1dea5a-f98a-4938-b154-b0438ad0d6ee',
              name: 'Facility Liquefied-Petroleum Gas Piping',
              tradeId: '23 11 26',
            },
          ],
          tradeId: '23 11 00',
        },
        {
          id: '2d12e128-5064-4562-af1d-a63643f05916',
          name: 'Facility Fuel Pumps',
          children: [
            {
              id: '6975f2c3-4a84-4365-ad56-30af3ebb8bb9',
              name: 'Facility Fuel Pumps',
              tradeId: '23 12 00',
            },
            {
              id: 'e9dc2cdb-98b2-42cf-afe6-b5e9b7dbb5be',
              name: 'Facility Fuel-Oil Pumps',
              tradeId: '23 12 13',
            },
            {
              id: 'bd766a3b-78d3-4683-9d9f-c7be9c03e63a',
              name: 'Facility Gasoline Dispensing Pumps',
              tradeId: '23 12 16',
            },
          ],
          tradeId: '23 12 00',
        },
        {
          id: 'd6df5401-9470-472a-8533-8d6b57f5fe05',
          name: 'Facility Fuel-Storage Tanks',
          children: [
            {
              id: '2f62ec37-0f20-4b77-9e6d-492f6e739165',
              name: 'Facility Fuel-Storage Tanks',
              tradeId: '23 13 00',
            },
            {
              id: '6a51d54e-967b-46fb-9e8d-1e308ff8ae77',
              name: 'Facility Underground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 13',
            },
            {
              id: '3ce0d22c-432b-42ac-bef9-9b8e55e83ce3',
              name: 'Double-Wall Steel, Underground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 13.13',
            },
            {
              id: '37c11e65-07fc-4e61-b10e-8046c10a482b',
              name: 'Composite, Steel, Underground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 13.16',
            },
            {
              id: '2f8bd79a-76c2-41e0-afb2-3ae8b8f3ba97',
              name: 'Jacketed, Steel, Underground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 13.19',
            },
            {
              id: 'e2ad3a05-cd20-4c0a-901c-b1acc0bbf7c0',
              name: 'Glass-Fiber-Reinforced-Plastic, Underground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 13.23',
            },
            {
              id: 'bca2459b-5a60-42ac-a9d1-2eab297ab5ed',
              name: 'Fuel-Oil Storage Tank Pumps',
              tradeId: '23 13 13.33',
            },
            {
              id: 'df688455-c46f-4290-8148-5e7e0ba290c5',
              name: 'Facility Aboveground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 23',
            },
            {
              id: 'f4238c37-e557-4de4-ae71-a29b4b06d950',
              name: 'Vertical, Steel, Aboveground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 23.13',
            },
            {
              id: '05279e83-9122-4948-ae5d-5d6b096896f1',
              name: 'Horizontal, Steel, Aboveground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 23.16',
            },
            {
              id: 'a37f64fd-0e35-44f4-b7fc-47a8ba74fd4e',
              name: 'Containment-Dike, Steel, Aboveground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 23.19',
            },
            {
              id: 'b06f3f43-17ae-44c6-b58e-5fa8bc009bfb',
              name: 'Insulated, Steel, Aboveground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 23.23',
            },
            {
              id: '09ed12e0-f896-4a50-8287-538e52dc2e27',
              name: 'Concrete-Vaulted, Steel, Aboveground Fuel-Oil, Storage Tanks',
              tradeId: '23 13 23.26',
            },
          ],
          tradeId: '23 13 00',
        },
        {
          id: '91632a2a-5395-4fb7-bced-55653fb1dbec',
          name: 'HVAC Piping and Pumps',
          children: [],
          tradeId: '23 20 00',
        },
        {
          id: '483de8cf-29ca-4e3e-bec1-49c1c3a5e4a9',
          name: 'Hydronic Piping and Pumps',
          children: [
            {
              id: '2d15c09c-39c6-4a09-a13e-876ab89b5ca3',
              name: 'Hydronic Piping and Pumps',
              tradeId: '23 21 00',
            },
            {
              id: 'f691140c-8b3b-4ec2-a6ae-e198c1f88518',
              name: 'Hydronic Piping',
              tradeId: '23 21 13',
            },
            {
              id: '8aa69ed4-dff4-4c63-88a1-4a6f7bcbd5c5',
              name: 'Underground Hydronic Piping',
              tradeId: '23 21 13.13',
            },
            {
              id: '5c85078b-da7d-4fc0-be4f-35b70611969c',
              name: 'Aboveground Hydronic Piping',
              tradeId: '23 21 13.23',
            },
            {
              id: 'b8b7dc2e-cc40-486e-b243-712c7d416bd3',
              name: 'Ground-Loop Heat-Pump Piping',
              tradeId: '23 21 13.33',
            },
            {
              id: 'eef14ddd-f1a8-4250-af45-82efe6a0eb63',
              name: 'Hydronic Piping Specialties',
              tradeId: '23 21 16',
            },
            {
              id: 'a9e1bab7-e5bc-4768-8ac2-5ec9643fb9dc',
              name: 'Hydronic Pumps',
              tradeId: '23 21 23',
            },
            {
              id: '58031260-6205-43b6-949d-09beec2b92a8',
              name: 'In-Line Centrifugal Hydronic Pumps',
              tradeId: '23 21 23.13',
            },
            {
              id: '3522bf8f-a2f4-4ffe-a911-7a9931dd5c7a',
              name: 'Base-Mounted, Centrifugal Hydronic Pumps',
              tradeId: '23 21 23.16',
            },
            {
              id: 'd8e8e2da-011a-4c3a-b5aa-43b79e1b3d8c',
              name: 'Vertical-Mounted, Double-Suction Centrifugal Hydronic Pumps',
              tradeId: '23 21 23.19',
            },
            {
              id: 'ec3bae5a-5227-4623-8f65-c24aa30c2128',
              name: 'Vertical-Turbine Hydronic Pumps',
              tradeId: '23 21 23.23',
            },
            {
              id: 'b9e0b7b9-8427-4f59-90e0-7e6ccfc09c0a',
              name: 'Automatic Condensate Pump Units',
              tradeId: '23 21 29',
            },
          ],
          tradeId: '23 21 00',
        },
        {
          id: '8e5d70fb-274f-4935-a262-d7456a703da1',
          name: 'Steam and Condensate Piping and Pumps',
          children: [
            {
              id: '52a8cdf1-d386-4f8e-bf79-f9d3d76e4a8c',
              name: 'Steam and Condensate Piping and Pumps',
              tradeId: '23 22 00',
            },
            {
              id: 'fab0a204-9961-4e7c-8078-69bc2fdd8bfb',
              name: 'Steam and Condensate Heating Piping',
              tradeId: '23 22 13',
            },
            {
              id: '2fbc592c-d262-4f68-ab2f-15ebf4533bd8',
              name: 'Underground Steam and Condensate Heating Piping',
              tradeId: '23 22 13.13',
            },
            {
              id: 'ba9aeb24-ecf6-4ac9-a37a-6bd2e1bc2c5f',
              name: 'Aboveground Steam and Condensate Heating Piping',
              tradeId: '23 22 13.23',
            },
            {
              id: '708c4d2d-4db2-4a29-b610-b53de6f9b02a',
              name: 'Steam and Condensate Heating Piping Specialties',
              tradeId: '23 22 16',
            },
            {
              id: 'a72055dd-60eb-43a7-a7ae-c2b27097496d',
              name: 'Steam Condensate Pumps',
              tradeId: '23 22 23',
            },
            {
              id: '6b3deb41-7ad1-4207-a2bd-234d5035a576',
              name: 'Electric-Driven Steam Condensate Pumps',
              tradeId: '23 22 23.13',
            },
            {
              id: '4c0acd81-22db-47ff-822a-a76ea839589f',
              name: 'Pressure-Powered Steam Condensate Pumps',
              tradeId: '23 22 23.23',
            },
          ],
          tradeId: '23 22 00',
        },
        {
          id: 'c95934d6-1ed7-4629-8a25-ba6abfb6d5a8',
          name: 'Refrigerant Piping',
          children: [
            {
              id: '3bc65a3c-aea9-4f26-b4ee-a76a4b51df34',
              name: 'Refrigerant Piping',
              tradeId: '23 23 00',
            },
            {
              id: '52fda4cf-ae8f-4c7c-99de-977243946523',
              name: 'Refrigerant Piping Valves',
              tradeId: '23 23 13',
            },
            {
              id: 'bb1e5d11-49d4-468b-a486-69b4381725cb',
              name: 'Refrigerant Piping Specialties',
              tradeId: '23 23 16',
            },
            {
              id: '7eb4e17b-cf49-4b9b-ae65-ee4ca79ac0f9',
              name: 'Refrigerant Safety Relief Valve Discharge Piping',
              tradeId: '23 23 19',
            },
            {
              id: '1cc6611f-f2cd-413b-8422-749e1c14693b',
              name: 'Refrigerants',
              tradeId: '23 23 23',
            },
          ],
          tradeId: '23 23 00',
        },
        {
          id: '3ceca62c-c1e0-4b97-9d93-f32fea7ef32b',
          name: 'Internal-Combustion Engine Piping',
          children: [
            {
              id: 'd445be81-610a-4a29-952f-8679719e3972',
              name: 'Internal-Combustion Engine Piping',
              tradeId: '23 24 00',
            },
            {
              id: 'fabc0454-d57d-4650-b897-d2ee134757b0',
              name: 'Internal-Combustion Engine Remote-Radiator Coolant Piping',
              tradeId: '23 24 13',
            },
            {
              id: 'e0cee2f8-387c-4a67-9e22-f1dedd3a1f44',
              name: 'Internal-Combustion Engine Exhaust Piping',
              tradeId: '23 24 16',
            },
          ],
          tradeId: '23 24 00',
        },
        {
          id: '5a3e9e66-78b3-458c-8a11-0c68a977316a',
          name: 'HVAC Water Treatment',
          children: [
            {
              id: '6e38ee3f-510c-47f7-8e2f-75571a721b39',
              name: 'HVAC Water Treatment',
              tradeId: '23 25 00',
            },
            {
              id: '9d1f414a-fff1-487e-80f4-6d7900f93718',
              name: 'Water Treatment for Closed-Loop Hydronic Systems',
              tradeId: '23 25 13',
            },
            {
              id: '17839020-990a-4504-9fbe-21118f079a95',
              name: 'Water Treatment for Open Hydronic Systems',
              tradeId: '23 25 16',
            },
            {
              id: '2a02c0b0-6e88-4d28-aa0b-06c1acfe6e06',
              name: 'Water Treatment for Steam System Feedwater',
              tradeId: '23 25 19',
            },
            {
              id: '395cfc17-600f-4dff-b29e-059782ca7df9',
              name: 'Water Treatment for Humidification Steam System Feedwater',
              tradeId: '23 25 23',
            },
          ],
          tradeId: '23 25 00',
        },
        {
          id: '2d6ab6f8-6750-4067-8b74-2b45d2bfca5c',
          name: 'HVAC Air Distribution',
          children: [],
          tradeId: '23 30 00',
        },
        {
          id: '3a68b4f8-c892-4eed-9256-bca275dfc23c',
          name: 'HVAC Ducts and Casings',
          children: [
            {
              id: '87d47f92-7d53-4b07-a47e-c75d9e8ad3c6',
              name: 'HVAC Ducts and Casings',
              tradeId: '23 31 00',
            },
            {
              id: '9e64cd5a-ec94-4ab5-b258-11a1c88f7419',
              name: 'Metal Ducts',
              tradeId: '23 31 13',
            },
            {
              id: 'eba7d675-1041-4e79-aec4-09a926e77d6e',
              name: 'Rectangular Metal Ducts',
              tradeId: '23 31 13.13',
            },
            {
              id: '36503507-92d8-4833-93c8-0b10c1faa2cd',
              name: 'Round and Flat-Oval Spiral Ducts',
              tradeId: '23 31 13.16',
            },
            {
              id: '66b39ad1-ee8c-47d7-9a29-0348cb78b68c',
              name: 'Metal Duct Fittings',
              tradeId: '23 31 13.19',
            },
            {
              id: 'd09789b1-4f76-4241-826f-73b00c28ebca',
              name: 'Nonmetal Ducts',
              tradeId: '23 31 16',
            },
            {
              id: 'e1af6451-d240-4c69-9819-10c2c03dffa2',
              name: 'Fibrous-Glass Ducts',
              tradeId: '23 31 16.13',
            },
            {
              id: '925b5e00-23ff-4536-8c77-69a138880a5f',
              name: 'Thermoset Fiberglass-Reinforced Plastic Ducts',
              tradeId: '23 31 16.16',
            },
            {
              id: 'fa7a197c-1804-4a5d-afcf-f2c7fe9d11d7',
              name: 'PVC Ducts',
              tradeId: '23 31 16.19',
            },
            {
              id: 'ccd6f057-aae1-472c-ac27-e43b0264846b',
              name: 'Concrete Ducts',
              tradeId: '23 31 16.26',
            },
            {
              id: '683f237a-2670-4fb7-8fef-b6d1d9e4bc2a',
              name: 'HVAC Casings',
              tradeId: '23 31 19',
            },
          ],
          tradeId: '23 31 00',
        },
        {
          id: '050f5d57-7886-4b9c-b44d-9c6853334049',
          name: 'Air Plenums and Chases',
          children: [
            {
              id: '7e94cd68-0117-4647-a02c-61055414e6d8',
              name: 'Air Plenums and Chases',
              tradeId: '23 32 00',
            },
            {
              id: '8eff2b0f-38a7-47eb-bf76-42abb7a90281',
              name: 'Fabricated, Metal Air Plenums',
              tradeId: '23 32 13',
            },
            {
              id: 'e15dfe2c-b1d2-498c-acaa-6dc4b9b2fca9',
              name: 'Air-Distribution Ceiling Plenums',
              tradeId: '23 32 33',
            },
            {
              id: 'da8cc281-1bde-48ea-bae0-2c502104e5a7',
              name: 'Air-Distribution Floor Plenums',
              tradeId: '23 32 36',
            },
            {
              id: 'a3152355-0331-4a8b-9ae3-2ca86f8da4e3',
              name: 'Air-Distribution Wall Plenums',
              tradeId: '23 32 39',
            },
            {
              id: 'ac7deb9f-ee13-4cb8-bec5-1328fc3df39a',
              name: 'Air-Distribution Chases Formed by General Construction',
              tradeId: '23 32 43',
            },
            {
              id: 'e2d6648f-eda4-4392-97fa-9b636af15efa',
              name: 'Acoustical Air Plenums',
              tradeId: '23 32 48',
            },
          ],
          tradeId: '23 32 00',
        },
        {
          id: '13aee650-01aa-4ce1-b382-f49ff8ad03b7',
          name: 'Air Duct Accessories',
          children: [
            {
              id: '57a55814-68ed-4a27-aabd-814f2b6e2e44',
              name: 'Air Duct Accessories',
              tradeId: '23 33 00',
            },
            {
              id: '1b32f8cb-5788-4079-b66b-473a333f9973',
              name: 'Dampers',
              tradeId: '23 33 13',
            },
            {
              id: '28bc7cb5-ce68-4283-82dd-b6926d6afc26',
              name: 'Volume-Control Dampers',
              tradeId: '23 33 13.13',
            },
            {
              id: 'b1eae660-46e3-4110-96cc-3060a41b3d14',
              name: 'Fire Dampers',
              tradeId: '23 33 13.16',
            },
            {
              id: '370999b3-58d4-41d1-a07c-5fbbbccdf9da',
              name: 'Smoke-Control Dampers',
              tradeId: '23 33 13.19',
            },
            {
              id: 'f554eb38-f4bc-4ee1-9039-5891f959f81f',
              name: 'Backdraft Dampers',
              tradeId: '23 33 13.23',
            },
            {
              id: '6d096077-4d5a-4092-a26a-dfd9ecef1718',
              name: 'Duct Silencers',
              tradeId: '23 33 19',
            },
            {
              id: '5be46d93-2432-4267-8315-cb9085bcf615',
              name: 'Turning Vanes',
              tradeId: '23 33 23',
            },
            {
              id: '1d4abf0f-28b8-459b-a8bd-b7af4f3c9342',
              name: 'Duct-Mounting Access Doors',
              tradeId: '23 33 33',
            },
            {
              id: '53667085-90ea-4f44-8406-2d860128ab91',
              name: 'Flexible Connectors',
              tradeId: '23 33 43',
            },
            {
              id: '86e17078-4108-46ce-820b-347244b2633f',
              name: 'Flexible Ducts',
              tradeId: '23 33 46',
            },
            {
              id: 'bd5863f4-84c6-43a4-adb8-2ee17e10d364',
              name: 'Duct Liners',
              tradeId: '23 33 53',
            },
          ],
          tradeId: '23 33 00',
        },
        {
          id: '3527ab8d-fc4c-43db-8074-31a2e50fe0b0',
          name: 'HVAC Fans',
          children: [
            {
              id: 'c7d582dd-04fb-4af5-89f6-2c9aa5dc527f',
              name: 'HVAC Fans',
              tradeId: '23 34 00',
            },
            {
              id: '6c0d80bd-690a-4a75-9b55-2fbbcb345414',
              name: 'Axial HVAC Fans',
              tradeId: '23 34 13',
            },
            {
              id: '3de22f7c-831c-4def-a6a1-775ba46c07cd',
              name: 'Centrifugal HVAC Fans',
              tradeId: '23 34 16',
            },
            {
              id: '4842d60c-38ba-49b2-919e-e05f7365db2a',
              name: 'HVAC Power Ventilators',
              tradeId: '23 34 23',
            },
            {
              id: '138d83ee-b92a-43c1-b211-05cfcbcd9db8',
              name: 'Air Curtains',
              tradeId: '23 34 33',
            },
          ],
          tradeId: '23 34 00',
        },
        {
          id: '6562765f-2fed-434f-a13f-063a74c03e96',
          name: 'Special Exhaust Systems',
          children: [
            {
              id: '487ff4e4-0fe9-45fa-9011-b785cb0f6772',
              name: 'Special Exhaust Systems',
              tradeId: '23 35 00',
            },
            {
              id: '2322935b-b060-4e43-bc12-af58f848db04',
              name: 'Dust Collection Systems',
              tradeId: '23 35 13',
            },
            {
              id: '8c4506ab-c2dd-468c-b550-fbec8f747012',
              name: 'Sawdust Collection Systems',
              tradeId: '23 35 13.13',
            },
            {
              id: 'c7b60898-48c5-4221-8755-062f35b635d6',
              name: 'Engine Exhaust Systems',
              tradeId: '23 35 16',
            },
            {
              id: 'da38b18d-a20d-45e1-9b77-3ccb8c2d89d6',
              name: 'Positive-Pressure Engine Exhaust Systems',
              tradeId: '23 35 16.13',
            },
            {
              id: 'baf400d6-d828-43aa-9c88-ddb1cc886e82',
              name: 'Mechanical Engine Exhaust Systems',
              tradeId: '23 35 16.16',
            },
          ],
          tradeId: '23 35 00',
        },
        {
          id: '6101c84e-7ac9-4e9a-9cff-fe513da012d1',
          name: 'Air Terminal Units',
          children: [
            {
              id: '8cf3b8a7-ac12-4c5c-9a34-2fb2210326ff',
              name: 'Air Terminal Units',
              tradeId: '23 36 00',
            },
            {
              id: '473f2823-a339-4c91-88ef-583009973101',
              name: 'Constant-Air-Volume Units',
              tradeId: '23 36 13',
            },
            {
              id: '568aa80f-41c6-4d87-88cd-9252874886c2',
              name: 'Variable-Air-Volume Units',
              tradeId: '23 36 16',
            },
          ],
          tradeId: '23 36 00',
        },
        {
          id: 'f23be2b4-8033-4210-940a-9835616bdf85',
          name: 'Air Outlets and Inlets',
          children: [
            {
              id: 'f38badb1-eef7-4227-a4ef-50cfbb523f3e',
              name: 'Air Outlets and Inlets',
              tradeId: '23 37 00',
            },
            {
              id: 'cf9e0248-e539-4fad-b4fb-b3647f9dc889',
              name: 'Diffusers, Registers and Grilles',
              tradeId: '23 37 13',
            },
            {
              id: 'c4fcd4fa-360f-416f-9068-f77efa037fab',
              name: 'Fabric Air Distribution Devices',
              tradeId: '23 37 16',
            },
            {
              id: 'f0a52a04-e8f7-484f-b518-831f3b8925bb',
              name: 'HVAC Gravity Ventilators',
              tradeId: '23 37 23',
            },
            {
              id: '17dbbcde-bc94-41b1-ad3c-2f9b45429920',
              name: 'HVAC Gravity Dome Ventilators',
              tradeId: '23 37 23.13',
            },
            {
              id: '2432433c-1ed9-4b2c-88f5-2459640da07a',
              name: 'HVAC Gravity Louvered-Penthouse Ventilators',
              tradeId: '23 37 23.16',
            },
            {
              id: '124984e3-615b-41a5-9ed0-3a8dc35f350f',
              name: 'HVAC Gravity Upblast Ventilators',
              tradeId: '23 37 23.19',
            },
          ],
          tradeId: '23 37 00',
        },
        {
          id: 'ed34d0a3-5913-40c6-9fad-bc1d2e459b56',
          name: 'Ventilation Hoods',
          children: [
            {
              id: 'eacd84d7-ee1f-4303-8f08-fa278d6128ec',
              name: 'Ventilation Hoods',
              tradeId: '23 38 00',
            },
            {
              id: '661afa7e-02a2-486d-9a45-f35244ea4d39',
              name: 'Commercial-Kitchen Hoods',
              tradeId: '23 38 13',
            },
            {
              id: '8d68ec25-f4a2-474a-a967-a3d8da04cf64',
              name: 'Listed Commercial-Kitchen Hoods',
              tradeId: '23 38 13.13',
            },
            {
              id: '148a45d7-e31b-434b-80e0-0b267fba13e4',
              name: 'Standard Commercial-Kitchen Hoods',
              tradeId: '23 38 13.16',
            },
            {
              id: 'bd3c8d1b-c3c0-41d4-82ab-3f4119e8386b',
              name: 'Fume Hoods',
              tradeId: '23 38 16',
            },
          ],
          tradeId: '23 38 00',
        },
        {
          id: '57764397-c79c-4a05-939e-706c6855b777',
          name: 'HVAC Air Cleaning Devices',
          children: [],
          tradeId: '23 40 00',
        },
        {
          id: '2289e397-90a8-407b-b301-9dbdb6d85581',
          name: 'Particulate Air Filtration',
          children: [
            {
              id: 'e60639b4-510f-4db6-8ed1-954fe4d1cfd3',
              name: 'Particulate Air Filtration',
              tradeId: '23 41 00',
            },
            {
              id: 'c137773c-92d8-4da7-a6fd-16517b88b508',
              name: 'Panel Air Filters',
              tradeId: '23 41 13',
            },
            {
              id: '5f8611a9-dde6-4590-ae7d-8696ea372b84',
              name: 'Renewable-Media Air Filters',
              tradeId: '23 41 16',
            },
            {
              id: '8e5c9542-e4b2-4889-a69d-4c17511a381c',
              name: 'Washable Air Filters',
              tradeId: '23 41 19',
            },
            {
              id: '47c047e3-21c0-4e8f-803c-c70f5234b3af',
              name: 'Extended Surface Filters',
              tradeId: '23 41 23',
            },
            {
              id: 'c04729bb-8988-455c-a5fc-c55707f54455',
              name: 'High-Efficiency Particulate Filtration',
              tradeId: '23 41 33',
            },
            {
              id: '06093fe4-b154-4030-8084-0237d14fd080',
              name: 'Ultra-Low Penetration Filtration',
              tradeId: '23 41 43',
            },
            {
              id: '3adf29ed-eb38-46e1-baed-d5143acdb1ce',
              name: 'Super Ultra-Low Penetration Filtration',
              tradeId: '23 41 46',
            },
          ],
          tradeId: '23 41 00',
        },
        {
          id: '76b2830f-08d2-4fb7-8820-0bdf77591462',
          name: 'Gas-Phase Air Filtration',
          children: [
            {
              id: '8a50a374-baba-4b3a-b78f-d9d31c641523',
              name: 'Gas-Phase Air Filtration',
              tradeId: '23 42 00',
            },
            {
              id: '68773200-75de-4c3c-9d9e-5cde87223e44',
              name: 'Activated-Carbon Air Filtration',
              tradeId: '23 42 13',
            },
            {
              id: '10707537-17b6-45ac-88f2-bb43dc46a89f',
              name: 'Chemically-Impregnated Adsorption Air Filtration',
              tradeId: '23 42 16',
            },
            {
              id: 'd68fee30-c104-4e27-b1f4-53fbc1dd5c18',
              name: 'Catalytic-Adsorption Air Filtration',
              tradeId: '23 42 19',
            },
          ],
          tradeId: '23 42 00',
        },
        {
          id: 'ccc106d5-7718-418b-91ce-a8c930aac403',
          name: 'Electronic Air Cleaners',
          children: [
            {
              id: '9d8c64cc-f450-4c32-a169-9467104cefcb',
              name: 'Electronic Air Cleaners',
              tradeId: '23 43 00',
            },
            {
              id: '3d610860-245f-4cc4-a0ea-9ed7eb8e50b5',
              name: 'Washable Electronic Air Cleaners',
              tradeId: '23 43 13',
            },
            {
              id: 'ebb8a3ac-374d-4ec7-8cc4-467edf8cc66c',
              name: 'Agglomerator Electronic Air Cleaners',
              tradeId: '23 43 16',
            },
            {
              id: '532d5fc3-6fd7-4f80-84a6-28fcbbcc9dfd',
              name: 'Self-Contained Electronic Air Cleaners',
              tradeId: '23 43 23',
            },
          ],
          tradeId: '23 43 00',
        },
        {
          id: '2ce0dffe-5389-4476-9f93-3adb80f5a0ed',
          name: 'Central Heating Equipment',
          children: [],
          tradeId: '23 50 00',
        },
        {
          id: '02bb1ded-1e7b-4566-a0b2-7a82a6f7db6a',
          name: 'Breechings, Chimneys and Stacks',
          children: [
            {
              id: 'a0a064e4-d9f6-4640-9130-b3c0baf660ea',
              name: 'Breechings, Chimneys and Stacks',
              tradeId: '23 51 00',
            },
            {
              id: '51a31a58-b92e-47b0-a91d-50c790edaa3c',
              name: 'Draft Control Devices',
              tradeId: '23 51 13',
            },
            {
              id: '4f7a3f39-3c7c-465f-9330-4ef384cc0dee',
              name: 'Draft-Induction Fans',
              tradeId: '23 51 13.13',
            },
            {
              id: '94f86e98-622d-4654-ac8f-b07a1cde4aee',
              name: 'Vent Dampers',
              tradeId: '23 51 13.16',
            },
            {
              id: 'd8c145e2-9bb3-468f-8e18-ee6c87a1b849',
              name: 'Barometric Dampers',
              tradeId: '23 51 13.19',
            },
            {
              id: 'd2ecf3f5-320f-4ea2-afb8-074715f7dd95',
              name: 'Fabricated Breechings and Accessories',
              tradeId: '23 51 16',
            },
            {
              id: '1cbb0b31-a1d5-414d-bee2-604c4fbddeed',
              name: 'Fabricated Stacks',
              tradeId: '23 51 19',
            },
            {
              id: '66dee9ae-91ed-433e-b112-ea6009868326',
              name: 'Gas Vents',
              tradeId: '23 51 23',
            },
            {
              id: '9baba19d-95cd-47ac-ad31-49b1e3b4764d',
              name: 'Insulated Sectional Chimneys',
              tradeId: '23 51 33',
            },
            {
              id: '0b21f977-6a66-4f1a-a574-110da77caf69',
              name: 'Flue-Gas Filtration Equipment',
              tradeId: '23 51 43',
            },
            {
              id: '99cecec4-d75a-43e3-9531-da8bb2daab86',
              name: 'Gaseous Filtration',
              tradeId: '23 51 43.13',
            },
            {
              id: '643cf4d1-c99e-4378-b171-bf3bfa29f4a3',
              name: 'Particulate Filtration',
              tradeId: '23 51 43.16',
            },
          ],
          tradeId: '23 51 00',
        },
        {
          id: '1ee9dab0-2686-48ce-834b-21a4d19034b8',
          name: 'Heating Boilers',
          children: [
            {
              id: '6de271fe-9281-4bb5-b0d3-85c59a42fa93',
              name: 'Heating Boilers',
              tradeId: '23 52 00',
            },
            {
              id: '6e4751be-a729-4e7d-bfb5-ff53bad521ed',
              name: 'Electric Boilers',
              tradeId: '23 52 13',
            },
            {
              id: 'c562c5e9-8e30-4ede-939d-2411da212238',
              name: 'Condensing Boilers',
              tradeId: '23 52 16',
            },
            {
              id: '68a4c281-f000-4d24-b39c-d3fc02a59c91',
              name: 'Stainless-Steel Condensing Boilers',
              tradeId: '23 52 16.13',
            },
            {
              id: 'fd2f585c-9001-4aa0-be06-7f4877bb917c',
              name: 'Aluminum Condensing Boilers',
              tradeId: '23 52 16.16',
            },
            {
              id: '754880d6-6f1e-4212-9245-a83d8adc0dc1',
              name: 'Low Mass Boilers',
              tradeId: '23 52 17',
            },
            {
              id: '0c378540-1152-45bf-af47-447026f8fcdb',
              name: 'Pulse Combustion Boilers',
              tradeId: '23 52 19',
            },
            {
              id: '0e25b9d2-d605-4c6d-99fd-4192bc1f282b',
              name: 'Cast-Iron Boilers',
              tradeId: '23 52 23',
            },
            {
              id: '1a9f98e3-5553-40aa-810f-16e91c02df10',
              name: 'Water-Tube Boilers',
              tradeId: '23 52 33',
            },
            {
              id: '5712ea72-58e4-42e4-a1eb-aba526f4e764',
              name: 'Finned Water-Tube Boilers',
              tradeId: '23 52 33.13',
            },
            {
              id: '29112af6-1ba5-4237-a1fa-211ca9e35e12',
              name: 'Steel Water-Tube Boilers',
              tradeId: '23 52 33.16',
            },
            {
              id: 'd738d7e1-9a09-4f16-b41e-86b820319d85',
              name: 'Copper Water-Tube Boilers',
              tradeId: '23 52 33.19',
            },
            {
              id: 'f0734d74-ae3f-46ec-824b-5edcb1385d68',
              name: 'Fire-Tube Boilers',
              tradeId: '23 52 39',
            },
            {
              id: '87d8a09e-af1f-4d65-968a-2504dbda1d92',
              name: 'Scotch Marine Boilers',
              tradeId: '23 52 39.13',
            },
            {
              id: '964fc338-bcc8-445e-91bb-143383c86123',
              name: 'Steel Fire-Tube Boilers',
              tradeId: '23 52 39.16',
            },
            {
              id: '58d5f974-fd35-42c9-b5c2-aed2ea4db303',
              name: 'Boiler Blowdown Systems',
              tradeId: '23 52 83',
            },
          ],
          tradeId: '23 52 00',
        },
        {
          id: '7f4b7a10-33a5-413f-b8d2-f45c5a8d4891',
          name: 'Heating Boiler Feedwater Equipment',
          children: [
            {
              id: '29ad8aaf-2b1b-4f17-86c4-c8abcb72fb12',
              name: 'Heating Boiler Feedwater Equipment',
              tradeId: '23 53 00',
            },
            {
              id: '830df38d-b768-45c8-a2e3-916ade382384',
              name: 'Boiler Feedwater Pumps',
              tradeId: '23 53 13',
            },
            {
              id: '20fe64d9-fcdf-4f84-8e13-5f6fd7a2d8f9',
              name: 'Deaerators',
              tradeId: '23 53 16',
            },
          ],
          tradeId: '23 53 00',
        },
        {
          id: '4c8b2720-876f-41bd-96d7-829bab242382',
          name: 'Furnaces',
          children: [
            {
              id: 'b348cb99-082e-42da-a050-7bedc64947f6',
              name: 'Furnaces',
              tradeId: '23 54 00',
            },
            {
              id: '252a1ae5-c9dd-4746-9ec9-7070dc5e403b',
              name: 'Electric-Resistance Furnaces',
              tradeId: '23 54 13',
            },
            {
              id: '58c9e2c0-a9ff-4a0a-b0d3-c8856bf4133d',
              name: 'Fuel-Fired Furnaces',
              tradeId: '23 54 16',
            },
            {
              id: 'f9bee8b7-3221-41d4-8305-0d7eb7f9df2d',
              name: 'Gas-Fired Furnaces',
              tradeId: '23 54 16.13',
            },
            {
              id: '771bd874-3ad1-4295-b5bf-f5c008108450',
              name: 'Oil-Fired Furnaces',
              tradeId: '23 54 16.16',
            },
          ],
          tradeId: '23 54 00',
        },
        {
          id: '0f71c759-b1fa-45b6-af47-0e856f58855c',
          name: 'Fuel-Fired Heaters',
          children: [
            {
              id: 'a2aa522a-40a5-4848-87da-c04b24d40843',
              name: 'Fuel-Fired Heaters',
              tradeId: '23 55 00',
            },
            {
              id: 'c8049d77-f589-4dd2-ade5-cc6dc1a4a535',
              name: 'Fuel-Fired Duct Heaters',
              tradeId: '23 55 13',
            },
            {
              id: 'bf172457-5750-4da1-a219-7b2a8226ced2',
              name: 'Oil-Fired Duct Heaters',
              tradeId: '23 55 13.13',
            },
            {
              id: 'fed718e2-49a0-41c6-a4b1-cf286c0ff92b',
              name: 'Gas-Fired Duct Heaters',
              tradeId: '23 55 13.16',
            },
            {
              id: 'ac035d6c-075e-47a9-8cb1-1e8a0aff8ffc',
              name: 'Gas-Fired Radiant Heaters',
              tradeId: '23 55 23',
            },
            {
              id: 'de5043a4-ec2b-454d-b86e-9db9a080e660',
              name: 'Low-Intensity Gas-Fired Radiant Heaters',
              tradeId: '23 55 23.13',
            },
            {
              id: '9732d49b-3aa6-4564-982b-dc3eb817a6c1',
              name: 'High-Intensity Gas-Fired Radiant Heaters',
              tradeId: '23 55 23.16',
            },
            {
              id: 'e8de243b-e174-407d-aa1a-82d99c20f2eb',
              name: 'Fuel-Fired Unit Heaters',
              tradeId: '23 55 33',
            },
            {
              id: '85af9a28-cc20-448d-a183-2b5989551cdd',
              name: 'Oil-Fired Unit Heaters',
              tradeId: '23 55 33.13',
            },
            {
              id: '775ebbef-24c1-43ed-aff3-99d90fd91596',
              name: 'Gas-Fired Unit Heaters',
              tradeId: '23 55 33.16',
            },
          ],
          tradeId: '23 55 00',
        },
        {
          id: '2960a724-446b-4412-a908-5dcd8f653428',
          name: 'Solar Energy Heating Equipment',
          children: [
            {
              id: 'd9925f08-c533-42ea-b479-62f78437969f',
              name: 'Solar Energy Heating Equipment',
              tradeId: '23 56 00',
            },
            {
              id: '2fcec939-3528-45c5-b80c-58dc60c32214',
              name: 'Heating Solar Collectors',
              tradeId: '23 56 13',
            },
            {
              id: '0a59d1eb-e5a9-498e-9b9c-c7623435ebdf',
              name: 'Heating Solar Flat-Plate Collectors',
              tradeId: '23 56 13.13',
            },
            {
              id: '4a15f092-a35f-4e78-8641-c1db27f7c4cc',
              name: 'Heating Solar Concentrating Collectors',
              tradeId: '23 56 13.16',
            },
            {
              id: '7c25bbd7-7cf4-46f8-9415-9e03e217a4e8',
              name: 'Heating Solar Vacuum-Tube Collectors',
              tradeId: '23 56 13.19',
            },
            {
              id: 'd1c8a616-fe7f-4c77-814a-41290448bb73',
              name: 'Packaged Solar Heating Equipment',
              tradeId: '23 56 16',
            },
            {
              id: '556f278c-f87f-485c-b5dd-9aa13b51cb4e',
              name: 'Solar Air-Heating Panels',
              tradeId: '23 56 23',
            },
          ],
          tradeId: '23 56 00',
        },
        {
          id: 'ff6c2d4c-0d97-4b0d-8e98-d842b062f9d1',
          name: 'Heat Exchangers for HVAC',
          children: [
            {
              id: 'bdf3c333-d7ac-4528-8215-23d258208167',
              name: 'Heat Exchangers for HVAC',
              tradeId: '23 57 00',
            },
            {
              id: 'fa3fa844-8503-4c76-8863-f36bc8a90e55',
              name: 'Steam-to-Steam Heat Exchangers',
              tradeId: '23 57 13',
            },
            {
              id: 'f7eee9b1-f7cc-4998-b115-462a74853994',
              name: 'Steam-to-Water Heat Exchangers',
              tradeId: '23 57 16',
            },
            {
              id: 'a13ce8e4-14c9-48dd-b8cc-acacf37e73e4',
              name: 'Liquid-to-Liquid Heat Exchangers',
              tradeId: '23 57 19',
            },
            {
              id: 'c3f2e277-4b04-45d5-ada0-59b68476c1f6',
              name: 'Plate-Type, Liquid-to-Liquid Heat Exchangers',
              tradeId: '23 57 19.13',
            },
            {
              id: '512df1d9-7ece-4ab2-8ac0-5132c4169e70',
              name: 'Shell-Type, Liquid-to-Liquid Heat Exchangers',
              tradeId: '23 57 19.16',
            },
            {
              id: 'd468b29e-1120-4617-a58e-d5bd10645a96',
              name: 'Direct Geoexchange Heat Exchangers',
              tradeId: '23 57 33',
            },
          ],
          tradeId: '23 57 00',
        },
        {
          id: '8fd0b43e-736e-4883-977c-cfd8644b85a9',
          name: 'Central Cooling Equipment',
          children: [],
          tradeId: '23 60 00',
        },
        {
          id: 'eb2a8fc3-85de-4c92-869e-1a7f383c5873',
          name: 'Refrigerant Compressors',
          children: [
            {
              id: '48e8f85d-ff76-4c1a-83f9-36c265af6cb7',
              name: 'Refrigerant Compressors',
              tradeId: '23 61 00',
            },
            {
              id: 'a4a0a9db-1425-42e7-8e36-c5506b41a5e1',
              name: 'Centrifugal Refrigerant Compressors',
              tradeId: '23 61 13',
            },
            {
              id: 'cdf2af57-c152-48cb-bd4b-a5dd926e9b02',
              name: 'Non-Condensable Gas Purge Equipment',
              tradeId: '23 61 13.13',
            },
            {
              id: 'e96747ba-ea49-45d9-aefc-aa5b450f8c77',
              name: 'Reciprocating Refrigerant Compressors',
              tradeId: '23 61 16',
            },
            {
              id: 'f13d81bb-cf3f-49c3-b421-b032efc59092',
              name: 'Scroll Refrigerant Compressors',
              tradeId: '23 61 19',
            },
            {
              id: '1c86a6d1-1aa9-4e55-9288-281a66093fb4',
              name: 'Rotary-Screw Refrigerant Compressors',
              tradeId: '23 61 23',
            },
          ],
          tradeId: '23 61 00',
        },
        {
          id: '128b676c-8a05-4e69-8066-f6d51e6a48fa',
          name: 'Packaged Compressor and Condenser Units',
          children: [
            {
              id: '6494343b-73c8-4b57-a117-c0e277e5f775',
              name: 'Packaged Compressor and Condenser Units',
              tradeId: '23 62 00',
            },
            {
              id: 'afc84631-1176-4100-bde3-e19198775270',
              name: 'Packaged Air-Cooled Refrigerant Compressor and Condenser Units',
              tradeId: '23 62 13',
            },
            {
              id: '2ed0a012-ba37-40ca-97ff-12483628a214',
              name: 'Packaged Water-Cooled Refrigerant Compressor and Condenser Units',
              tradeId: '23 62 23',
            },
          ],
          tradeId: '23 62 00',
        },
        {
          id: 'b17dc343-7a21-480b-967f-1e105124f289',
          name: 'Refrigerant Condensers',
          children: [
            {
              id: '71536819-cc74-4f7b-b52f-e36cd29aca30',
              name: 'Refrigerant Condensers',
              tradeId: '23 63 00',
            },
            {
              id: '2ba6c270-2caf-414d-b863-85088cfa05f7',
              name: 'Air-Cooled Refrigerant Condensers',
              tradeId: '23 63 13',
            },
            {
              id: '2f9907e4-9b31-4ba8-9918-60a29bb6340f',
              name: 'Water-Cooled Refrigerant Condensers',
              tradeId: '23 63 23',
            },
            {
              id: '864bd34a-9c6d-464d-abf7-688e5bd875b3',
              name: 'Evaporative Refrigerant Condensers',
              tradeId: '23 63 33',
            },
          ],
          tradeId: '23 63 00',
        },
        {
          id: '8895558f-c7a6-4a75-b338-8fe5ad579fe8',
          name: 'Packaged Water Chillers',
          children: [
            {
              id: '73179aa1-a503-4fa5-a252-c0b729846177',
              name: 'Packaged Water Chillers',
              tradeId: '23 64 00',
            },
            {
              id: '68d3b6ae-000d-40f7-a63f-941847909aa4',
              name: 'Absorption Water Chillers',
              tradeId: '23 64 13',
            },
            {
              id: 'd3600b48-53d2-4d81-bef6-25dbe0ca01b4',
              name: 'Direct-Fired Absorption Water Chillers',
              tradeId: '23 64 13.13',
            },
            {
              id: '97e5f4f2-4019-4025-9c94-d266b82b327c',
              name: 'Indirect-Fired Absorption Water Chillers',
              tradeId: '23 64 13.16',
            },
            {
              id: '5a95abb6-b5cf-4544-82e1-ba76f8156eda',
              name: 'Centrifugal Water Chillers',
              tradeId: '23 64 16',
            },
            {
              id: 'e2bef2f4-a3c6-494e-993f-d9ac6a4bdba2',
              name: 'Air-Cooled Centrifugal Water Chillers',
              tradeId: '23 64 16.13',
            },
            {
              id: '5bb537ad-ae1c-4028-8c43-e61c58bc5afb',
              name: 'Water-Cooled Centrifugal Water Chillers',
              tradeId: '23 64 16.16',
            },
            {
              id: '4fe799ee-3ad8-4ea3-80f1-2555cf793efd',
              name: 'Reciprocating Water Chillers',
              tradeId: '23 64 19',
            },
            {
              id: '9ea01bd6-5026-404e-9072-61f6cee8dd6d',
              name: 'Scroll Water Chillers',
              tradeId: '23 64 23',
            },
            {
              id: '034e64f3-cf91-47b5-80b8-bc3be47ef5f1',
              name: 'Rotary-Screw Water Chillers',
              tradeId: '23 64 26',
            },
          ],
          tradeId: '23 64 00',
        },
        {
          id: 'cd26f2e9-a270-419b-bccb-8d5500fa6920',
          name: 'Cooling Towers',
          children: [
            {
              id: '8d43a051-5a85-4f60-8714-9aa68d93d71f',
              name: 'Cooling Towers',
              tradeId: '23 65 00',
            },
            {
              id: 'e9af672e-97d3-44f9-a6a3-fb336e8baead',
              name: 'Forced-Draft Cooling Towers',
              tradeId: '23 65 13',
            },
            {
              id: 'f9ba8d0f-986e-4d56-ac31-4aab1b370507',
              name: 'Open-Circuit, Forced-Draft Cooling Towers',
              tradeId: '23 65 13.13',
            },
            {
              id: 'd3c3df9f-7b0c-4853-8abb-fcacbfae2292',
              name: 'Closed-Circuit, Forced-Draft Cooling Towers',
              tradeId: '23 65 13.16',
            },
            {
              id: 'f456baa0-0c70-4bfb-8446-43b646268d21',
              name: 'Natural-Draft Cooling Towers',
              tradeId: '23 65 16',
            },
            {
              id: '1ffe041f-9d68-4f58-9de8-152ab3b91f3a',
              name: 'Field-Erected Cooling Towers',
              tradeId: '23 65 23',
            },
            {
              id: 'a3c39eca-0721-4250-9bc7-f80b869c9f4f',
              name: 'Liquid Coolers',
              tradeId: '23 65 33',
            },
          ],
          tradeId: '23 65 00',
        },
        {
          id: 'ba0b16f7-f4bf-4708-8f93-ebcc977f7251',
          name: 'Central HVAC Equipment',
          children: [],
          tradeId: '23 70 00',
        },
        {
          id: '4b9ac737-7a0e-4f88-b778-fc8693651adf',
          name: 'Thermal Storage',
          children: [
            {
              id: '4641269b-44f7-4ca8-a94b-bec9f15d58ec',
              name: 'Thermal Storage',
              tradeId: '23 71 00',
            },
            {
              id: '20d5b8c2-387c-49b8-a08c-00ed69e85b55',
              name: 'Thermal Heat Storage',
              tradeId: '23 71 13',
            },
            {
              id: '96e3a812-9912-4bb1-a9ac-20a76bc93f6a',
              name: 'Room Storage Heaters for Thermal Storage',
              tradeId: '23 71 13.13',
            },
            {
              id: '631b0dcc-b9f7-4880-8c9c-5a0fa26cec8a',
              name: 'Heat-Pump Boosters for Thermal Storage',
              tradeId: '23 71 13.16',
            },
            {
              id: '60cffb5f-1ed6-400a-be34-fcb7f1452156',
              name: 'Central Furnace Heat-Storage Units',
              tradeId: '23 71 13.19',
            },
            {
              id: '50fdf63a-9aa7-4e66-8a7e-58ec88ab147d',
              name: 'Pressurized-Water Thermal Storage Tanks',
              tradeId: '23 71 13.23',
            },
            {
              id: '8c1b40b8-170c-4fab-bd7c-26ed8202887b',
              name: 'Chilled-Water Thermal Storage',
              tradeId: '23 71 16',
            },
            {
              id: 'd90d08e1-9243-49c2-9325-44ea4c00bbff',
              name: 'Ice Storage',
              tradeId: '23 71 19',
            },
            {
              id: '68f6d206-0f30-4c85-9927-71717e16e6c2',
              name: 'Internal Ice-on-Coil Thermal Storage',
              tradeId: '23 71 19.13',
            },
            {
              id: '4e4076d5-bda8-489f-b860-9d623162bbbf',
              name: 'External Ice-on-Coil Thermal Storage',
              tradeId: '23 71 19.16',
            },
            {
              id: 'c6274f29-72eb-4bf9-8a8d-0b57d50af5e0',
              name: 'Encapsulated-Ice Thermal Storage',
              tradeId: '23 71 19.19',
            },
            {
              id: '7f8f07a8-121e-4f10-972e-f59f149604cf',
              name: 'Ice-Harvesting Thermal Storage',
              tradeId: '23 71 19.23',
            },
            {
              id: 'eabedcbb-969e-43ad-bed5-9ce65fa94608',
              name: 'Ice-Slurry Thermal Storage',
              tradeId: '23 71 19.26',
            },
          ],
          tradeId: '23 71 00',
        },
        {
          id: '7759b63e-108d-407f-bf7d-4807c0f417b8',
          name: 'Air-to-Air Energy Recovery Equipment',
          children: [
            {
              id: 'd01d0e3e-d54c-45ce-a9f4-a35ad00c8c1d',
              name: 'Air-to-Air Energy Recovery Equipment',
              tradeId: '23 72 00',
            },
            {
              id: '44f2ed4a-d306-4594-af29-5fb1aba2a637',
              name: 'Heat-Wheel Air-to-Air Energy-Recovery Equipment',
              tradeId: '23 72 13',
            },
            {
              id: 'be286fd6-b0d0-4e28-8c80-b6b2b4580e1c',
              name: 'Heat-Pipe Air-to-Air Energy-Recovery Equipment',
              tradeId: '23 72 16',
            },
            {
              id: '8edeccf9-969e-4ec7-be5c-171aef06eb56',
              name: 'Fixed-Plate Air-to-Air Energy-Recovery Equipment',
              tradeId: '23 72 19',
            },
            {
              id: 'd94d114a-27ec-4739-8fe2-ad078a7eae1f',
              name: 'Packaged Air-to-Air Energy-Recovery Units',
              tradeId: '23 72 23',
            },
          ],
          tradeId: '23 72 00',
        },
        {
          id: 'a63e57cb-fa9e-49c2-93aa-ff2c4acdeee5',
          name: 'Indoor Central-Station Air-Handling Units',
          children: [
            {
              id: '84ba884d-c56d-4513-89a7-941fc56957e0',
              name: 'Indoor Central-Station Air-Handling Units',
              tradeId: '23 73 00',
            },
            {
              id: 'c18f83cc-12d6-4a60-bd10-eec3b29e1dcb',
              name: 'Modular Indoor Central-Station Air-Handling Units',
              tradeId: '23 73 13',
            },
            {
              id: '31a47674-ef7b-4997-abfd-b0e9038829c3',
              name: 'Custom Indoor Central-Station Air-Handling Units',
              tradeId: '23 73 23',
            },
            {
              id: '2a34eeb2-944b-4340-8bfb-35f07f9a9557',
              name: 'Indoor Indirect Fuel-Fired Heating and Ventilating Units',
              tradeId: '23 73 33',
            },
            {
              id: '35a6873b-1865-49ff-986d-e3f359985f63',
              name: 'Indoor Indirect Oil-Fired Heating and Ventilating Units',
              tradeId: '23 73 33.13',
            },
            {
              id: '8d898c17-8680-41f6-9282-22c39c638fbe',
              name: 'Indoor Indirect Gas-Fired Heating and Ventilating Units',
              tradeId: '23 73 33.16',
            },
            {
              id: '744491c1-df1d-4c61-9d36-a30729d22029',
              name: 'Indoor, Direct Gas-Fired Heating and Ventilating Units',
              tradeId: '23 73 39',
            },
          ],
          tradeId: '23 73 00',
        },
        {
          id: '6e311ae1-3fb9-4126-922c-98e832106fcb',
          name: 'Packaged Outdoor HVAC Equipment',
          children: [
            {
              id: '6db84652-2cf9-4ace-ac5f-fa07ce701ca6',
              name: 'Packaged Outdoor HVAC Equipment',
              tradeId: '23 74 00',
            },
            {
              id: 'a50da1e6-5f96-40cc-b20a-accf95f21a62',
              name: 'Packaged, Outdoor, Central-Station Air-Handling Units',
              tradeId: '23 74 13',
            },
            {
              id: 'e1d9d274-9ebb-4fa6-bae6-beb3338d9fd8',
              name: 'Packaged, Outdoor, Heating-Only Makeup-Air Units',
              tradeId: '23 74 23',
            },
            {
              id: '9d8d73f6-7774-40af-8f42-1dfd1e532c33',
              name: 'Packaged, Direct-Fired, Outdoor, Heating-Only Makeup-Air Units',
              tradeId: '23 74 23.13',
            },
            {
              id: '2c0570d1-eb29-4e46-8dbc-6cf17971a896',
              name: 'Packaged, Indirect-Fired, Outdoor, Heating-Only Makeup-Air Units',
              tradeId: '23 74 23.16',
            },
            {
              id: '57b81a38-dac9-490f-a910-22897d64f468',
              name: 'Dedicated Outdoor-Air Units',
              tradeId: '23 74 33',
            },
          ],
          tradeId: '23 74 00',
        },
        {
          id: '36d8b74b-bd93-4054-845e-306a66eb7582',
          name: 'Custom-Packaged Outdoor HVAC Equipment',
          children: [
            {
              id: '9475eb23-98e2-4d3a-b673-e1aec58cbfe2',
              name: 'Custom-Packaged Outdoor HVAC Equipment',
              tradeId: '23 75 00',
            },
            {
              id: '53a88b4e-8f33-4a6e-a91c-21f94302a196',
              name: 'Custom-Packaged, Outdoor, Central-Station Air-Handling Units',
              tradeId: '23 75 13',
            },
            {
              id: 'e15b3779-e0c6-4128-bb6e-3292ca19f1bb',
              name: 'Custom-Packaged, Outdoor, Heating and Ventilating Makeup-Air Units',
              tradeId: '23 75 23',
            },
            {
              id: 'b52edbcd-42c4-4213-9c2c-dcaa532c212a',
              name: 'Custom-Packaged, Outdoor, Heating and Cooling Makeup Air-Conditioners',
              tradeId: '23 75 33',
            },
          ],
          tradeId: '23 75 00',
        },
        {
          id: '2967b69f-0b7b-401a-b9c0-128bf051d5c1',
          name: 'Evaporative Air-Cooling Equipment',
          children: [
            {
              id: '1bbfba2d-24fc-406b-a8f0-b536cbec12fd',
              name: 'Evaporative Air-Cooling Equipment',
              tradeId: '23 76 00',
            },
            {
              id: 'c0b9a6b1-25f3-4a8c-8732-54e7d2ff4264',
              name: 'Direct Evaporative Air Coolers',
              tradeId: '23 76 13',
            },
            {
              id: '72c79904-8d34-48b6-a451-2429065994f0',
              name: 'Indirect Evaporative Air Coolers',
              tradeId: '23 76 16',
            },
            {
              id: '76b06d84-0f2d-49e4-bb4a-aa97c1d5be9c',
              name: 'Combined Direct and Indirect Evaporative Air Coolers',
              tradeId: '23 76 19',
            },
          ],
          tradeId: '23 76 00',
        },
        {
          id: 'e4536c0a-7bd7-4c94-8b43-a236f3f7e532',
          name: 'Decentralized HVAC Equipment',
          children: [],
          tradeId: '23 80 00',
        },
        {
          id: '35c9edf2-dc24-42cc-969d-7f9fd0112d70',
          name: 'Decentralized Unitary HVAC Equipment',
          children: [
            {
              id: '9ebd3277-bda9-428d-be8a-5dcc25bc8d72',
              name: 'Decentralized Unitary HVAC Equipment',
              tradeId: '23 81 00',
            },
            {
              id: '8913293e-5168-4657-8e68-f352ef01bf4a',
              name: 'Packaged Terminal Air-Conditioners',
              tradeId: '23 81 13',
            },
            {
              id: 'adecdd0e-c34d-46b2-808c-32ca6d8f565f',
              name: 'Room Air-Conditioners',
              tradeId: '23 81 16',
            },
            {
              id: 'be71de7b-a44d-459d-9897-30ea9ed19733',
              name: 'Self-Contained Air-Conditioners',
              tradeId: '23 81 19',
            },
            {
              id: '2fdfa494-4aef-469c-a09c-57742b0aa9e7',
              name: 'Small-Capacity Self-Contained Air-Conditioners',
              tradeId: '23 81 19.13',
            },
            {
              id: 'a6c25fd6-4034-474a-ac9a-e366aecc0c07',
              name: 'Large-Capacity Self-Contained Air-Conditioners',
              tradeId: '23 81 19.16',
            },
            {
              id: '6f3ab769-830d-4c6a-aadc-f42468752ac3',
              name: 'Computer-Room Air-Conditioners',
              tradeId: '23 81 23',
            },
            {
              id: '3801cd5d-2955-4fde-9201-c5d795acf0a0',
              name: 'Split-System Air-Conditioners',
              tradeId: '23 81 26',
            },
            {
              id: '5bf487a3-e19a-4fff-bc84-2f9f2c7742fe',
              name: 'Small-Capacity Split-System Air-Conditioners',
              tradeId: '23 81 26.13',
            },
            {
              id: '2a8bf95d-11a5-4334-bdfa-78998aeb48f9',
              name: 'Large-Capacity Split-System Air-Conditioners',
              tradeId: '23 81 26.16',
            },
            {
              id: '1876f2d6-6dc1-4d91-a2b7-8389baf0bc2a',
              name: 'Variable Refrigerant Flow HVAC Systems',
              tradeId: '23 81 29',
            },
            {
              id: 'd637991b-adfa-472f-b419-3f10b0e7f371',
              name: 'Air-Source Unitary Heat Pumps',
              tradeId: '23 81 43',
            },
            {
              id: 'dbdd202a-802c-482b-a998-2eb0e078bd0f',
              name: 'Water-Source Unitary Heat Pumps',
              tradeId: '23 81 46',
            },
          ],
          tradeId: '23 81 00',
        },
        {
          id: '29613f51-9c80-479a-a72b-02316de5c04f',
          name: 'Convection Heating and Cooling Units',
          children: [
            {
              id: 'e84d1610-1a65-4084-9d80-dbdbc493a123',
              name: 'Convection Heating and Cooling Units',
              tradeId: '23 82 00',
            },
            {
              id: '0294870c-446f-4c7d-a071-ca46814c543e',
              name: 'Valance Heating and Cooling Units',
              tradeId: '23 82 13',
            },
            {
              id: '2e5313c9-f3f6-4534-aaf9-92808c43658a',
              name: 'Chilled Beams',
              tradeId: '23 82 14',
            },
            {
              id: '4a884be5-26ec-4f2f-94ef-261bd8530a49',
              name: 'Air Coils',
              tradeId: '23 82 16',
            },
            {
              id: 'b970c769-0f28-401f-9ab2-4e643e5e146c',
              name: 'Fan Coil Units',
              tradeId: '23 82 19',
            },
            {
              id: '067cb6ef-878a-454f-92a7-184306140072',
              name: 'Unit Ventilators',
              tradeId: '23 82 23',
            },
            {
              id: '3a788f38-0ce0-4971-bd9e-b63f1b0494fb',
              name: 'Induction Units',
              tradeId: '23 82 26',
            },
            {
              id: 'dc50f252-6f38-41d5-8630-163e7b106107',
              name: 'Radiators',
              tradeId: '23 82 29',
            },
            {
              id: '09153d04-ae75-4759-a44a-158e934b0d81',
              name: 'Convectors',
              tradeId: '23 82 33',
            },
            {
              id: '24919ae8-8a43-4041-b35d-32f0079cfa05',
              name: 'Finned-Tube Radiation Heaters',
              tradeId: '23 82 36',
            },
            {
              id: '1ea5b1db-d3a3-4b56-9a50-6eeef6ca376a',
              name: 'Unit Heaters',
              tradeId: '23 82 39',
            },
            {
              id: '86b4cf82-2cb8-496d-a54d-942d506f4e2d',
              name: 'Cabinet Unit Heaters',
              tradeId: '23 82 39.13',
            },
            {
              id: 'c1d55948-4f39-428b-931e-383cd70f7d0f',
              name: 'Propeller Unit Heaters',
              tradeId: '23 82 39.16',
            },
            {
              id: '2ff3d58c-ab8b-497e-9a71-71285ec99a4f',
              name: 'Wall and Ceiling Unit Heaters',
              tradeId: '23 82 39.19',
            },
            {
              id: '32bf0c15-3935-494a-9c24-f0534d5a6f0f',
              name: 'Water-to-Water Heat Pumps',
              tradeId: '23 82 41',
            },
          ],
          tradeId: '23 82 00',
        },
        {
          id: '747252b2-6b0f-41fa-9ee5-8f5f9c2b0a8c',
          name: 'Radiant Heating Units',
          children: [
            {
              id: '868d2764-2da7-4b37-b574-6aec2efaa472',
              name: 'Radiant Heating Units',
              tradeId: '23 83 00',
            },
            {
              id: '8b0cc938-d383-4564-b01e-013ee296bdb8',
              name: 'Radiant-Heating Electric Cables',
              tradeId: '23 83 13',
            },
            {
              id: '611b6242-0690-4c99-8db7-eaba9aaf3110',
              name: 'Radiant-Heating Electric Mats',
              tradeId: '23 83 13.16',
            },
            {
              id: '5ddacb10-bbe1-4cc5-aa8d-72764ea4ed86',
              name: 'Radiant-Heating Hydronic Piping',
              tradeId: '23 83 16',
            },
            {
              id: '0ff2439a-82cf-475e-a5ff-c3b9c07233ff',
              name: 'Radiant-Heating Electric Panels',
              tradeId: '23 83 23',
            },
            {
              id: 'd5a952f4-263b-4c51-a039-241638b66980',
              name: 'Electric Radiant Heaters',
              tradeId: '23 83 33',
            },
          ],
          tradeId: '23 83 00',
        },
        {
          id: '6ad8f036-615c-4d51-9c86-60ec7808cd0d',
          name: 'Humidity Control Equipment',
          children: [
            {
              id: '100c0b29-85cf-4d6b-afcc-9bf63adf2c62',
              name: 'Humidity Control Equipment',
              tradeId: '23 84 00',
            },
            {
              id: '51c5ccd4-f0bd-4414-97af-fbe7c3a0f840',
              name: 'Humidifiers',
              tradeId: '23 84 13',
            },
            {
              id: '14aac214-6bfb-417b-bd65-757fda28ec68',
              name: 'Heated-Pan Humidifiers',
              tradeId: '23 84 13.13',
            },
            {
              id: '1eeefd35-af05-4fc9-b969-8b7383d0d985',
              name: 'Wetted-Element Humidifiers',
              tradeId: '23 84 13.16',
            },
            {
              id: 'c763c807-8b59-4a87-a379-57cdef3034c2',
              name: 'Atomizing Humidifiers',
              tradeId: '23 84 13.19',
            },
            {
              id: '42d2f1ef-499c-485e-a8b1-dd25474f2f96',
              name: 'Direct-Steam-Injection Humidifiers',
              tradeId: '23 84 13.23',
            },
            {
              id: '7760ad6d-804f-4a47-9e7d-802d36fa47a8',
              name: 'Jacketed, Steam Humidifiers',
              tradeId: '23 84 13.26',
            },
            {
              id: '6eb3625e-281c-4343-a8cc-71314d229685',
              name: 'Self-Contained Steam Humidifiers',
              tradeId: '23 84 13.29',
            },
            {
              id: 'ee6bd4ae-fed8-4de6-97dd-3f74ab8f533c',
              name: 'Portable Humidifiers',
              tradeId: '23 84 13.33',
            },
            {
              id: 'd4efb55b-8c39-40da-aa79-4d080197246a',
              name: 'Mechanical Dehumidification Units',
              tradeId: '23 84 16',
            },
            {
              id: 'ccb2fe58-bda5-4a67-bd3d-024b4d7db575',
              name: 'Outdoor, Mechanical Dehumidification Units',
              tradeId: '23 84 16.13',
            },
            {
              id: 'bb5cd8d9-c318-4ef4-81b2-ff41c4bf49fb',
              name: 'Indoor, Mechanical Dehumidification Units',
              tradeId: '23 84 16.16',
            },
            {
              id: '80aa5e76-824e-4f0c-9524-e731a0e0c6a5',
              name: 'Portable Dehumidifiers',
              tradeId: '23 84 16.33',
            },
            {
              id: 'f178bdb2-8325-43c7-a022-2c116a5e48e1',
              name: 'Desiccant Dehumidification Units',
              tradeId: '23 84 19',
            },
          ],
          tradeId: '23 84 00',
        },
      ],
      tradeId: '23 00 00',
    },
    {
      id: '158f704c-1621-48f9-b851-14afff7bbcc3',
      name: 'Integrated Automation',
      children: [
        {
          id: '0831b50e-bdeb-4223-9a12-832340305769',
          name: 'Integrated Automation',
          children: [],
          tradeId: '25 00 00',
        },
        {
          id: '29a066cd-bdab-4951-a4bb-298b697b56ac',
          name: 'Operation and Maintenance of Integrated Automation',
          children: [
            {
              id: '00004504-b4f5-49a2-9586-b101564b52ef',
              name: 'Operation and Maintenance of Integrated Automation',
              tradeId: '25 01 00',
            },
            {
              id: 'c57d2f8f-3bd3-4679-b991-c561a034199a',
              name: 'Operation and Maintenance of Integrated Automation Network Equipment',
              tradeId: '25 01 10',
            },
            {
              id: '37b953a0-5305-4c24-bc89-d639e1f23156',
              name: 'Operation and Maintenance of Integrated Equipment',
              tradeId: '25 01 20',
            },
            {
              id: '727040b8-8e87-4726-b754-bc4887061536',
              name: 'Operation and Maintenance of Integrated Automation Instrumentation and Terminal Devices',
              tradeId: '25 01 30',
            },
            {
              id: '98de984c-c971-408a-861f-05310345c992',
              name: 'Diagnostic Systems for Integrated Automation',
              tradeId: '25 01 90',
            },
          ],
          tradeId: '25 01 00',
        },
        {
          id: '248395d3-f79e-4e28-bcee-ba35403ae624',
          name: 'Common Work Results for Integrated Automation',
          children: [
            {
              id: '218306da-ebb9-4630-92f4-3f67caa4e341',
              name: 'Common Work Results for Integrated Automation',
              tradeId: '25 05 00',
            },
            {
              id: 'f38deaa3-0a64-4b43-a4a0-d6ea74b77ed6',
              name: 'Conductors and Cables for Integrated Automation',
              tradeId: '25 05 13',
            },
            {
              id: '1f815b00-759c-48da-b76b-5dc88838dcf6',
              name: 'Grounding and Bonding for Integrated Automation',
              tradeId: '25 05 26',
            },
            {
              id: '28a770fb-4464-44dc-abe4-d99a1755337c',
              name: 'Pathways for Integrated Automation',
              tradeId: '25 05 28',
            },
            {
              id: '2891774a-f2ad-4aea-b99e-e958256fc7aa',
              name: 'Hangers and Supports for Integrated Automation',
              tradeId: '25 05 28.29',
            },
            {
              id: '0bbac338-6cb5-4366-b862-442290b02b9f',
              name: 'Conduits and Backboxes for Integrated Automation',
              tradeId: '25 05 28.33',
            },
            {
              id: '0a5f83e6-6afe-405d-b286-8922f54410a4',
              name: 'Cable Trays for Integrated Automation',
              tradeId: '25 05 28.36',
            },
            {
              id: '07f5182c-0c4b-480e-b103-3a211497f2b8',
              name: 'Surface Raceways for Integrated Automation',
              tradeId: '25 05 28.39',
            },
            {
              id: 'd00c329e-c631-4185-9817-ddaee3918872',
              name: 'Vibration and Seismic Controls for Integrated Automation',
              tradeId: '25 05 48',
            },
            {
              id: '415dce75-e809-4688-9ddd-a7548ff40058',
              name: 'Identification for Integrated Automation',
              tradeId: '25 05 53',
            },
          ],
          tradeId: '25 05 00',
        },
        {
          id: '8fd7caf7-0787-4579-93dc-2777752c58f8',
          name: 'Schedules for Integrated Automation',
          children: [
            {
              id: '836f6e22-9e52-43b5-b6f6-cd7b90efb41b',
              name: 'Schedules for Integrated Automation',
              tradeId: '25 06 00',
            },
            {
              id: 'b1bf8fab-3638-4b10-a74a-003335b925ca',
              name: 'Schedules for Integrated Automation Network',
              tradeId: '25 06 11',
            },
            {
              id: '6bf10026-a345-4437-abd9-ebef5e1f650c',
              name: 'Schedules for Integrated Automation Network Gateways',
              tradeId: '25 06 12',
            },
            {
              id: '4e286ca4-8876-4994-aee6-8f512efb8e8c',
              name: 'Schedules for Integrated Automation Control and Monitoring Network',
              tradeId: '25 06 13',
            },
            {
              id: '6ceaa38f-6e0a-40df-abd6-feea0c651eb1',
              name: 'Schedules for Integrated Automation Local Control Units',
              tradeId: '25 06 14',
            },
            {
              id: 'b56eb26c-5fe2-436b-8130-6916dddbc120',
              name: 'Schedules for Integrated Automation Instrumentation and Terminal Devices',
              tradeId: '25 06 30',
            },
          ],
          tradeId: '25 06 00',
        },
        {
          id: '6a13e780-2b88-4505-b6c6-b2ed44fae64f',
          name: 'Commissioning of Integrated Automation',
          children: [],
          tradeId: '25 08 00',
        },
        {
          id: 'a149465a-6cdb-4aa8-a216-042da0acb028',
          name: 'Integrated Automation Network Equipment',
          children: [],
          tradeId: '25 10 00',
        },
        {
          id: '2e3d26a0-9580-46ad-95dd-92c9fa5180e6',
          name: 'Integrated Automation Network Devices',
          children: [
            {
              id: '1ec6e9c9-fa7d-43cb-8624-adce086a4249',
              name: 'Integrated Automation Network Devices',
              tradeId: '25 11 00',
            },
            {
              id: '6f718293-0f67-4c8d-8134-9f327525d5b6',
              name: 'Integrated Automation Network Servers',
              tradeId: '25 11 13',
            },
            {
              id: '45b7b7e4-860e-4b10-b87c-19235fa9109e',
              name: 'Integrated Automation Network Routers, Bridges, Switches, Hubs and Modems',
              tradeId: '25 11 16',
            },
            {
              id: 'e60197f1-c9e8-46a1-a128-c4c77472d217',
              name: 'Integrated Automation Network Operator Workstations',
              tradeId: '25 11 19',
            },
          ],
          tradeId: '25 11 00',
        },
        {
          id: '079ce348-9860-4f1e-bcb4-449709db411c',
          name: 'Integrated Automation Network Gateways',
          children: [
            {
              id: 'b67729f4-d4e2-4e50-b821-968469e3a66f',
              name: 'Integrated Automation Network Gateways',
              tradeId: '25 12 00',
            },
            {
              id: 'a94a9571-0522-4f9e-888c-1b6095e1a655',
              name: 'Hardwired Integration Network Gateways',
              tradeId: '25 12 13',
            },
            {
              id: '415068b9-33b8-4e5d-bb34-f324745767dc',
              name: 'Direct-Protocol Integration Network Gateways',
              tradeId: '25 12 16',
            },
            {
              id: '632ae834-45dd-4638-858f-f80097cee8fb',
              name: 'Neutral-Protocol Integration Network Gateways',
              tradeId: '25 12 19',
            },
            {
              id: '795baa51-1496-4f44-b71f-f4ebc4f1fce9',
              name: 'Client-Server Information/Database Integration Network Gateways',
              tradeId: '25 12 23',
            },
          ],
          tradeId: '25 12 00',
        },
        {
          id: '1b339deb-27be-49c0-a036-268f52f4e5f3',
          name: 'Integrated Automation Control and Monitoring Network',
          children: [
            {
              id: '8215546f-aa5f-4c3e-b9d9-e7de791eb28a',
              name: 'Integrated Automation Control and Monitoring Network',
              tradeId: '25 13 00',
            },
            {
              id: 'a5dc0b78-dca9-443a-bf95-4e1c36c83d42',
              name: 'Integrated Automation Control and Monitoring Network Supervisory Control',
              tradeId: '25 13 13',
            },
            {
              id: 'd120bd01-9e65-4630-a6c7-50c3de9346bd',
              name: 'Integrated Automation Control and Monitoring Network Integration Panels',
              tradeId: '25 13 16',
            },
            {
              id: '3039c310-67f5-4b50-9e80-f96f01223220',
              name: 'Integrated Automation Control and Monitoring Network Interoperability',
              tradeId: '25 13 19',
            },
          ],
          tradeId: '25 13 00',
        },
        {
          id: '3d90ecde-9edd-4459-add9-715ae83d9acb',
          name: 'Integrated Automation Local Control Units',
          children: [
            {
              id: 'baeb2427-79f6-406b-ad66-b1d2a3a905d5',
              name: 'Integrated Automation Local Control Units',
              tradeId: '25 14 00',
            },
            {
              id: 'b18969d5-0e37-4402-83ff-78ea85b0e906',
              name: 'Integrated Automation Remote Control Panels',
              tradeId: '25 14 13',
            },
            {
              id: '5e3f1283-e2b6-4a70-816e-386e16ae4f26',
              name: 'Integrated Automation Application-Specific Control Panels',
              tradeId: '25 14 16',
            },
            {
              id: 'dd564767-a5bd-4f31-b99f-3df225ab4fb9',
              name: 'Integrated Automation Terminal Control Units',
              tradeId: '25 14 19',
            },
            {
              id: '77b8c5ff-99f2-4e0b-9131-bc7030e63ff9',
              name: 'Integrated Automation Field Equipment Panels',
              tradeId: '25 14 23',
            },
          ],
          tradeId: '25 14 00',
        },
        {
          id: 'f901acb0-87c2-4e21-bf67-120f57cd48bf',
          name: 'Integrated Automation Software',
          children: [
            {
              id: 'e091c5c6-6a08-4522-8f36-2d6091994ff4',
              name: 'Integrated Automation Software',
              tradeId: '25 15 00',
            },
            {
              id: '32fce81b-3295-4ed1-a00c-8efa1b1856ba',
              name: 'Integrated Automation Software for Network Gateways',
              tradeId: '25 15 13',
            },
            {
              id: '458f4bab-a84a-44c4-a3ed-e1ee9bbd6a7d',
              name: 'Integrated Automation Software for Control and Monitoring Networks',
              tradeId: '25 15 16',
            },
            {
              id: '577d0a28-080d-4287-a25a-b398140727e5',
              name: 'Integrated Automation Software for Local Control Units',
              tradeId: '25 15 19',
            },
          ],
          tradeId: '25 15 00',
        },
        {
          id: 'a8271a89-5fa5-46a1-bffe-64010f630cfc',
          name: 'Integrated Automation Instrumentation and Terminal Devices',
          children: [],
          tradeId: '25 30 00',
        },
        {
          id: '4bd0e5f8-1bc8-41cb-85ae-857e49499938',
          name: 'Integrated Automation Instrumentation and Terminal Devices for Facility Equipment',
          children: [],
          tradeId: '25 31 00',
        },
        {
          id: 'c473b986-5f0d-4f1a-8885-50ae2d181116',
          name: 'Integrated Automation Instrumentation and Terminal Devices for Conveying Equipment',
          children: [],
          tradeId: '25 32 00',
        },
        {
          id: '1241ecc6-99ca-4283-932e-801c1322781b',
          name: 'Integrated Automation Instrumentation and Terminal Devices for Fire-Suppression Systems',
          children: [],
          tradeId: '25 33 00',
        },
        {
          id: '82b6f52d-e20d-4b81-aff3-25bf8db246db',
          name: 'Integrated Automation Instrumentation and Terminal Devices for Plumbing',
          children: [],
          tradeId: '25 34 00',
        },
        {
          id: '844948e7-76cb-41b7-a348-067add7e368d',
          name: 'Integrated Automation Instrumentation and Terminal Devices for HVAC',
          children: [
            {
              id: 'aa533d87-c15b-4a24-a956-fd0da1acf6d1',
              name: 'Integrated Automation Instrumentation and Terminal Devices for HVAC',
              tradeId: '25 35 00',
            },
            {
              id: '0378edd6-5ed6-4e61-9683-dfe3e042ef64',
              name: 'Integrated Automation Actuators and Operators',
              tradeId: '25 35 13',
            },
            {
              id: 'b9fb607f-6ef4-4eaa-ae39-390bea584016',
              name: 'Integrated Automation Sensors and Transmitters',
              tradeId: '25 35 16',
            },
            {
              id: 'c64db114-4415-426e-84a5-5b51d217556d',
              name: 'Integrated Automation Control Valves',
              tradeId: '25 35 19',
            },
            {
              id: '7a729061-5e14-4c5a-9035-b7d7b612f37a',
              name: 'Integrated Automation Control Dampers',
              tradeId: '25 35 23',
            },
            {
              id: '643842be-14d0-4e12-8f15-25d61a2e9587',
              name: 'Integrated Automation Compressed Air Supply',
              tradeId: '25 35 26',
            },
          ],
          tradeId: '25 35 00',
        },
        {
          id: 'cd7f4dbb-b268-408c-a5f4-fa9ae5e9157f',
          name: 'Integrated Automation Instrumentation and Terminal Devices for Electrical Systems',
          children: [
            {
              id: '9e9bc600-0c90-4169-a81a-9547ae5e0065',
              name: 'Integrated Automation Instrumentation and Terminal Devices for Electrical Systems',
              tradeId: '25 36 00',
            },
            {
              id: '94939ad6-2050-4c30-9142-49dd90b5fd2b',
              name: 'Integrated Automation Power Meters',
              tradeId: '25 36 13',
            },
            {
              id: '4b31dd37-c971-4147-93da-9e2310563d4d',
              name: 'Integrated Automation KW Transducers',
              tradeId: '25 36 16',
            },
            {
              id: 'd0138c26-ad7c-4e91-9688-9cb878a9b2c2',
              name: 'Integrated Automation Current Sensors',
              tradeId: '25 36 19',
            },
            {
              id: '35107956-4df4-49e9-99bb-3f33e7d6aff4',
              name: 'Integrated Automation Battery Monitors',
              tradeId: '25 36 23',
            },
            {
              id: '44cfff1c-cece-4cd2-903e-6b418fe6c5a3',
              name: 'Integrated Automation Lighting Relays',
              tradeId: '25 36 26',
            },
            {
              id: '93aa8b48-c635-4c82-8473-52c14190ec7b',
              name: 'Integrated Automation UPS Monitors',
              tradeId: '25 36 29',
            },
          ],
          tradeId: '25 36 00',
        },
        {
          id: 'b530079f-1c42-4387-b8a0-f532f694cc02',
          name: 'Integrated Automation Instrumentation and Terminal Devices for Communications Systems',
          children: [],
          tradeId: '25 37 00',
        },
        {
          id: '5fbcd695-bc63-4100-bd96-8b949e1937d3',
          name: 'Integrated Automation Instrumentation and Terminal Devices for Electronic Safety and Security Systems',
          children: [],
          tradeId: '25 38 00',
        },
        {
          id: 'c930e3d1-9ba9-43da-8ac1-8e3875304843',
          name: 'Integrated Automation Facility Controls',
          children: [],
          tradeId: '25 50 00',
        },
        {
          id: '11ec074f-5625-44c5-9a97-93c34dcd2c0b',
          name: 'Integrated Automation Control of Facility Equipment',
          children: [],
          tradeId: '25 51 00',
        },
        {
          id: '157d5528-2d2f-480e-bbb6-4b54bc4bf86d',
          name: 'Integrated Automation Control of Conveying Equipment',
          children: [],
          tradeId: '25 52 00',
        },
        {
          id: '8705a332-d5d2-4abe-9114-cde31c994f6d',
          name: 'Integrated Automation Control of Fire-Suppression Systems',
          children: [],
          tradeId: '25 53 00',
        },
        {
          id: '90d84b19-763e-4358-9757-ede93a0b796e',
          name: 'Integrated Automation Control of Plumbing',
          children: [],
          tradeId: '25 54 00',
        },
        {
          id: '9ed73ca2-8b36-4df7-974d-c2767f96f13a',
          name: 'Integrated Automation Control of HVAC',
          children: [],
          tradeId: '25 55 00',
        },
        {
          id: '2656faba-7568-4cc4-a6e3-26659e05d4f3',
          name: 'Integrated Automation Control of Electrical Systems',
          children: [],
          tradeId: '25 56 00',
        },
        {
          id: '62308c0e-e472-4f67-baca-441b39a33042',
          name: 'Integrated Automation Control of Communications Systems',
          children: [],
          tradeId: '25 57 00',
        },
        {
          id: 'a44606ed-603b-44de-b38a-0ab45eae675a',
          name: 'Integrated Automation Control of Electronic Safety and Security Systems',
          children: [],
          tradeId: '25 58 00',
        },
        {
          id: 'bdf937e6-1400-4dd8-8850-9fd628a62062',
          name: 'Integrated Automation Control Sequences',
          children: [],
          tradeId: '25 90 00',
        },
        {
          id: '08c185e9-6ff0-45a2-8791-1f2f2f91b9aa',
          name: 'Integrated Automation Control Sequences for Facility Equipment',
          children: [],
          tradeId: '25 91 00',
        },
        {
          id: '9b226bbf-4925-4283-b037-0f784eee324f',
          name: 'Integrated Automation Control Sequences for Conveying Equipment',
          children: [],
          tradeId: '25 92 00',
        },
        {
          id: '42407732-402e-4bf9-b947-75488e219253',
          name: 'Integrated Automation Control Sequences for Fire-Suppression Systems',
          children: [],
          tradeId: '25 93 00',
        },
        {
          id: 'd0d95985-ffdc-4f29-b8f4-7fb9c0a39479',
          name: 'Integrated Automation Control Sequences for Plumbing',
          children: [],
          tradeId: '25 94 00',
        },
        {
          id: 'f9e56609-f397-4c5d-b3be-d7e49465e1db',
          name: 'Integrated Automation Control Sequences for HVAC',
          children: [],
          tradeId: '25 95 00',
        },
        {
          id: 'c1bf2956-704c-4e5c-b21c-546dead6e93d',
          name: 'Integrated Automation Control Sequences for Electrical Systems',
          children: [],
          tradeId: '25 96 00',
        },
        {
          id: 'd5ee6ebf-b804-469e-b66d-e966d148173f',
          name: 'Integrated Automation Control Sequences for Communications Systems',
          children: [],
          tradeId: '25 97 00',
        },
        {
          id: '287e162a-afde-4b4b-a8c9-59b4367f7126',
          name: 'Integrated Automation Control Sequences for Electronic Safety and Security Systems',
          children: [],
          tradeId: '25 98 00',
        },
      ],
      tradeId: '25 00 00',
    },
    {
      id: '604f5825-7b4e-4499-b88b-daac1f2f7564',
      name: 'Electrical',
      children: [
        {
          id: 'ca2e48be-dad4-4430-ba2b-4f84c0a09ac8',
          name: 'Electrical',
          children: [],
          tradeId: '26 00 00',
        },
        {
          id: '83eae07f-215c-41ad-90ae-98dbae06cea6',
          name: 'Operation and Maintenance of Electrical Systems',
          children: [
            {
              id: '0dda9b4a-4ac0-4064-9adf-40b94547440e',
              name: 'Operation and Maintenance of Electrical Systems',
              tradeId: '26 01 00',
            },
            {
              id: 'ab2d8801-d05a-4b04-99f9-007277c08324',
              name: 'Operation and Maintenance of Medium-Voltage Electrical Distribution',
              tradeId: '26 01 10',
            },
            {
              id: '2905df38-b03b-404b-98ef-80b8a15270fe',
              name: 'Operation and Maintenance of Low-Voltage Electrical Distribution',
              tradeId: '26 01 20',
            },
            {
              id: '1dbcddcc-f434-432c-b7fd-e70acb62029f',
              name: 'Maintenance Testing of Electrical Systems',
              tradeId: '26 01 26',
            },
            {
              id: '7295333e-ffe7-4072-9c3f-6060134a223d',
              name: 'Operation and Maintenance of Facility Electrical Power Generating and Storing Equipment',
              tradeId: '26 01 30',
            },
            {
              id: 'fd764adb-e3ff-4782-b240-29f74a8d1c1c',
              name: 'Operation and Maintenance of Electrical and Cathodic Protection Systems',
              tradeId: '26 01 40',
            },
            {
              id: '620d8b07-a137-41d2-b013-1193cbcd4d20',
              name: 'Operation and Maintenance of Lighting',
              tradeId: '26 01 50',
            },
            {
              id: 'fef4bfe1-c48c-4082-8ea2-5ee2e02dfd02',
              name: 'Luminaire Relamping',
              tradeId: '26 01 50.51',
            },
            {
              id: '643f8800-f2cc-4c70-addc-e651a990da86',
              name: 'Luminaire Replacement',
              tradeId: '26 01 50.81',
            },
          ],
          tradeId: '26 01 00',
        },
        {
          id: 'ee0ea4eb-d0fe-4302-ba31-d8d6e61b530f',
          name: 'Common Work Results for Electrical',
          children: [
            {
              id: 'b68dd149-ca5d-46af-bd1c-e3566bd46dd6',
              name: 'Common Work Results for Electrical',
              tradeId: '26 05 00',
            },
            {
              id: '74625fdc-15c1-4fd9-98c6-102d36340574',
              name: 'Medium-Voltage Cables',
              tradeId: '26 05 13',
            },
            {
              id: 'e3015aee-1d3b-43d9-b62a-095ee6b13d1a',
              name: 'Medium-Voltage Open Conductors',
              tradeId: '26 05 13.13',
            },
            {
              id: 'e8f8511d-2196-43bd-8558-6518b1a421e2',
              name: 'Medium-Voltage, Single- and Multi-Conductor Cables',
              tradeId: '26 05 13.16',
            },
            {
              id: '394c088e-b0eb-479e-a528-f1f5245617e3',
              name: 'Low-Voltage Electrical Power Conductors and Cables',
              tradeId: '26 05 19',
            },
            {
              id: '9f826a1e-3f74-4e0f-bf6a-91a0622afd4d',
              name: 'Undercarpet Electrical Power Cables',
              tradeId: '26 05 19.13',
            },
            {
              id: '7735275a-96f0-4c7f-aa88-86675f3b1044',
              name: 'Manufactured Wiring Assemblies',
              tradeId: '26 05 19.23',
            },
            {
              id: '0095450e-f856-4577-9358-aa93267622fb',
              name: 'Control-Voltage Electrical Power Cables',
              tradeId: '26 05 23',
            },
            {
              id: '01457dd3-bd35-4b85-8986-f65a620bc19f',
              name: 'Grounding and Bonding for Electrical Systems',
              tradeId: '26 05 26',
            },
            {
              id: '0c0112e0-6049-43ef-a72c-f32763651df4',
              name: 'Hangers and Supports for Electrical Systems',
              tradeId: '26 05 29',
            },
            {
              id: '26c0886d-e0cf-48b9-a698-d3ff6ad040d3',
              name: 'Raceway and Boxes for Electrical Systems',
              tradeId: '26 05 33',
            },
            {
              id: '7d3974fa-f7ac-49e6-bc4b-094aa1504de8',
              name: 'Conduit for Electrical Systems',
              tradeId: '26 05 33.13',
            },
            {
              id: 'e475ff23-c031-4a9e-8d5a-b769b7ec50be',
              name: 'Boxes for Electrical Systems',
              tradeId: '26 05 33.16',
            },
            {
              id: '9ead34d3-9ef0-4df0-a573-6869786d2482',
              name: 'Surface raceways for Electrical Systems',
              tradeId: '26 05 33.23',
            },
            {
              id: 'b6ec8cbb-5533-4f32-aabe-e881444ac277',
              name: 'Cable Trays for Electrical Systems',
              tradeId: '26 05 36',
            },
            {
              id: 'c6dbbfc0-c53b-4a2c-bf27-43532cfd1323',
              name: 'Underfloor Raceways for Electrical Systems',
              tradeId: '26 05 39',
            },
            {
              id: 'ff7fef60-2d59-4321-a068-c7d35c885c8e',
              name: 'Underground Ducts and Raceways for Electrical Systems',
              tradeId: '26 05 43',
            },
            {
              id: 'dc2de6bd-2f27-47c5-aa92-a564cf234f6f',
              name: 'Utility Poles for Electrical Systems',
              tradeId: '26 05 46',
            },
            {
              id: 'ab31168e-3e08-4212-95fd-731df7752d6a',
              name: 'Vibration and Seismic Controls for Electrical Systems',
              tradeId: '26 05 48',
            },
            {
              id: 'e103a938-e781-4f45-89b7-3092e979650a',
              name: 'Identification for Electrical Systems',
              tradeId: '26 05 53',
            },
            {
              id: '58cebd10-2eee-4478-94e6-a93be306ec9d',
              name: 'Overcurrent Protective Device Coordination Study',
              tradeId: '26 05 73',
            },
            {
              id: '324d17a0-87a0-435b-8008-b1ef72b92014',
              name: 'Wiring Connections',
              tradeId: '26 05 83',
            },
          ],
          tradeId: '26 05 00',
        },
        {
          id: '7cf1bc99-64b0-4e80-afe2-ed367bc67de9',
          name: 'Schedules for Electrical',
          children: [
            {
              id: '25d71884-96c9-4773-80d5-0d6c4cdbf3cb',
              name: 'Schedules for Electrical',
              tradeId: '26 06 00',
            },
            {
              id: '3ef99d90-962f-4486-8b86-f5be5725da01',
              name: 'Schedules for Medium-Voltage Electrical Distribution',
              tradeId: '26 06 10',
            },
            {
              id: '2f34e012-1924-4bbf-aa3e-c4fd41486832',
              name: 'Schedules for Low-Voltage Electrical Distribution',
              tradeId: '26 06 20',
            },
            {
              id: 'efb8ebe3-1a6a-4c6f-8155-97bdad6dfda9',
              name: 'Electrical Switchboard Schedule',
              tradeId: '26 06 20.13',
            },
            {
              id: 'ce1357fc-6026-4150-84d5-07b50c69e413',
              name: 'Electrical Panelboard Schedule',
              tradeId: '26 06 20.16',
            },
            {
              id: 'a7a64885-0ef5-41b6-b4c4-c96ed314c959',
              name: 'Electrical Motor-Control Center Schedule',
              tradeId: '26 06 20.19',
            },
            {
              id: '18f45647-3fe2-4b41-92f5-3f66a8304e57',
              name: 'Electrical Circuit Schedule',
              tradeId: '26 06 20.23',
            },
            {
              id: '971e5e99-dc75-437e-b503-6434c436add2',
              name: 'Wiring Device Schedule',
              tradeId: '26 06 20.26',
            },
            {
              id: '4f641db6-af66-4173-a92e-ee135c310068',
              name: 'Schedules for Facility Electrical Power Generating and Storing Equipment',
              tradeId: '26 06 30',
            },
            {
              id: 'c3de7a39-a351-4c29-b252-65174b55819d',
              name: 'Schedules for Electrical and Cathodic Protection Systems',
              tradeId: '26 06 40',
            },
            {
              id: '3f3d9803-afab-43e4-8a17-1410e5430e0f',
              name: 'Schedules for Lighting',
              tradeId: '26 06 50',
            },
            {
              id: 'ee5ca750-a4f6-4760-9a6e-799da17c20a8',
              name: 'Lighting Panelboard Schedule',
              tradeId: '26 06 50.13',
            },
            {
              id: '4e3ee0c7-713f-4427-965b-adae30257fd6',
              name: 'Lighting Fixture Schedule',
              tradeId: '26 06 50.16',
            },
          ],
          tradeId: '26 06 00',
        },
        {
          id: '4ac38caa-ebb1-4e71-b2c6-138a50018374',
          name: 'Commissioning of Electrical Systems',
          children: [],
          tradeId: '26 08 00',
        },
        {
          id: '939c215c-d4c3-4f36-8dcc-6a17da72994b',
          name: 'Instrumentation and Control for Electrical Systems',
          children: [
            {
              id: '0292b182-e420-42b8-8955-382a300c5156',
              name: 'Instrumentation and Control for Electrical Systems',
              tradeId: '26 09 00',
            },
            {
              id: '8fd8cd88-a2f7-4226-9bed-5d947d4a6a47',
              name: 'Electrical Power Monitoring',
              tradeId: '26 09 13',
            },
            {
              id: 'ec8b2d98-1a25-4bf1-84a3-26323f977339',
              name: 'Peak Load Controllers',
              tradeId: '26 09 15',
            },
            {
              id: '344be732-ae6c-4513-8f5f-75f4d52a81d6',
              name: 'Electrical Controls and Relays',
              tradeId: '26 09 16',
            },
            {
              id: 'cb005c44-6409-4aaf-bf75-f4fbcec626f3',
              name: 'Programmable Controllers',
              tradeId: '26 09 17',
            },
            {
              id: 'f51cbd7d-204b-4d36-8aca-0d70bfd7632e',
              name: 'Enclosed Contactors',
              tradeId: '26 09 19',
            },
            {
              id: 'b83f157d-ef22-445d-931a-d804b740b36e',
              name: 'Lighting Control Devices',
              tradeId: '26 09 23',
            },
            {
              id: 'd0d6591b-34ba-4b7a-b95d-bff9d557354f',
              name: 'Lighting Control Panelboards',
              tradeId: '26 09 26',
            },
            {
              id: 'adb3806d-d0b0-4950-9b91-53ce2caddce1',
              name: 'Central Dimming Controls',
              tradeId: '26 09 33',
            },
            {
              id: '7954a087-4a1c-4f5d-b539-6b6e1e876c58',
              name: 'Multichannel Remote-Controlled Dimmers',
              tradeId: '26 09 33.13',
            },
            {
              id: 'f2664f3b-36fc-4b90-b87e-43664543a4c7',
              name: 'Remote-Controlled Dimming Stations',
              tradeId: '26 09 33.16',
            },
            {
              id: '66a19ed5-b4a2-4364-bb86-6d758d475dd0',
              name: 'Modular Dimming Controls',
              tradeId: '26 09 36',
            },
            {
              id: 'e1c807b4-1db5-42ef-8a1f-aa0af129e19e',
              name: 'Manual Modular Dimming Controls',
              tradeId: '26 09 36.13',
            },
            {
              id: 'a538cd81-1e77-4356-80f7-fcb2e63585c4',
              name: 'Integrated Multipreset Modular Dimming Controls',
              tradeId: '26 09 36.16',
            },
            {
              id: '5940efd0-8d81-401b-8f78-5bbe9dc389cc',
              name: 'Network Lighting Controls',
              tradeId: '26 09 43',
            },
            {
              id: '14a4072e-f274-4060-8466-7e9b40420d91',
              name: 'Digital-Network Lighting Controls',
              tradeId: '26 09 43.13',
            },
            {
              id: '0d0c88f0-dd0a-4938-bb72-efdd2cb15b20',
              name: 'Addressable Fixture Lighting Control',
              tradeId: '26 09 43.16',
            },
            {
              id: 'd1d2d61f-f76e-4c0a-bcfb-7db0cedb116a',
              name: 'Wireless Network Lighting Controls',
              tradeId: '26 09 43.19',
            },
            {
              id: '132ca399-1e8d-49ff-873c-49adf3c9e0f8',
              name: 'Theatrical Lighting Controls',
              tradeId: '26 09 61',
            },
          ],
          tradeId: '26 09 00',
        },
        {
          id: 'bbcd20fd-53d3-4ccb-af0f-6990b043839d',
          name: 'Medium-Voltage Electrical Distribution',
          children: [],
          tradeId: '26 10 00',
        },
        {
          id: 'f9f58f09-fda6-437e-b4f9-214560686856',
          name: 'Substations',
          children: [
            {
              id: '51d8338c-9b73-43f2-a6c8-4d864bf65266',
              name: 'Substations',
              tradeId: '26 11 00',
            },
            {
              id: '0e1222e7-eaf2-4856-867e-61ed8e111e74',
              name: 'Primary Unit Substations',
              tradeId: '26 11 13',
            },
            {
              id: 'f3b5f222-9d90-49a9-92b7-8e8e59ea38b5',
              name: 'Secondary Unit Substations',
              tradeId: '26 11 16',
            },
          ],
          tradeId: '26 11 00',
        },
        {
          id: 'ac4c75c4-3e97-49a4-a6d8-6183689ecff6',
          name: 'Medium-Voltage Transformers',
          children: [
            {
              id: '3a68df19-e535-48cd-9239-fd64b9a0f589',
              name: 'Medium-Voltage Transformers',
              tradeId: '26 12 00',
            },
            {
              id: 'fbfc5c70-cceb-4a6d-bde9-a4edfaf8337f',
              name: 'Liquid-Filled, Medium-Voltage Transformers',
              tradeId: '26 12 13',
            },
            {
              id: 'eda38f77-146c-4038-bc31-c1ec90c6dacd',
              name: 'Dry-Type, Medium-Voltage Transformers',
              tradeId: '26 12 16',
            },
            {
              id: '9e316e1a-f1a1-4482-a08e-7fcb92d8bdbe',
              name: 'Pad-Mounted, Liquid-Filled, Medium-Voltage Transformers',
              tradeId: '26 12 19',
            },
          ],
          tradeId: '26 12 00',
        },
        {
          id: '3631c731-3d02-4567-9795-ada1c3a0a65e',
          name: 'Medium-Voltage Switchgear',
          children: [
            {
              id: '6528358e-c69e-4af2-807e-ae0d659576b9',
              name: 'Medium-Voltage Switchgear',
              tradeId: '26 13 00',
            },
            {
              id: '734b318c-0d67-4357-8968-444268e94211',
              name: 'Medium-Voltage Circuit Breaker Switchgear',
              tradeId: '26 13 13',
            },
            {
              id: '3b09ac16-9f93-4f18-bef7-2f4936c6d3e7',
              name: 'Medium-Voltage Fusible Interrupter Switchgear',
              tradeId: '26 13 16',
            },
            {
              id: 'e0508d34-02a0-4709-ace3-73a83a8814b2',
              name: 'Medium-Voltage Vacuum Interrupter Switchgear',
              tradeId: '26 13 19',
            },
            {
              id: '8920e216-f18a-4796-a54c-a0969b5de763',
              name: 'Medium-Voltage  Metal-Enclosed Switchgear',
              tradeId: '26 13 23',
            },
            {
              id: '6027b3d1-84e7-4bd1-b37c-cb0efcf87a52',
              name: 'Medium-Voltage Metal-Clad Switchgear',
              tradeId: '26 13 26',
            },
            {
              id: '833031b5-6783-41fa-a801-3224dd30a794',
              name: 'Medium-Voltage Compartmentalized Switchgear',
              tradeId: '26 13 29',
            },
          ],
          tradeId: '26 13 00',
        },
        {
          id: '80b7cf8d-fa23-4584-8dc1-68413eee4c48',
          name: 'Medium-Voltage Metering',
          children: [],
          tradeId: '26 16 00',
        },
        {
          id: 'd95d38f7-0e1d-487c-948b-869d2b1b48bc',
          name: 'Medium-Voltage Circuit Protection Devices',
          children: [
            {
              id: 'ae02f23c-dbcc-4a0f-8159-77bdf814d792',
              name: 'Medium-Voltage Circuit Protection Devices',
              tradeId: '26 18 00',
            },
            {
              id: '0c20373a-1246-4de9-b0be-7d64094c8172',
              name: 'Medium-Voltage Cutouts',
              tradeId: '26 18 13',
            },
            {
              id: '8483493b-0b19-4b4e-aeea-b261944cb5f5',
              name: 'Medium-Voltage Fuses',
              tradeId: '26 18 16',
            },
            {
              id: '385344bd-89ec-4600-b818-444a8998236c',
              name: 'Medium-Voltage Lightning Arresters',
              tradeId: '26 18 19',
            },
            {
              id: '6dbee570-5662-44de-8623-19a749777b43',
              name: 'Medium-Voltage Surge Arresters',
              tradeId: '26 18 23',
            },
            {
              id: '5233481f-f123-4ddb-8725-9e50aad2cace',
              name: 'Medium-Voltage Reclosers',
              tradeId: '26 18 26',
            },
            {
              id: 'cc908896-e198-4425-a68d-2320bf350ca1',
              name: 'Medium-Voltage Enclosed Bus',
              tradeId: '26 18 29',
            },
            {
              id: 'e9dd03fd-ef1c-42fc-9da9-1445e2d6a2c3',
              name: 'Medium-Voltage Enclosed Fuse Cutouts',
              tradeId: '26 18 33',
            },
            {
              id: '10363d89-b1d4-458d-8ce0-a18715f9aaef',
              name: 'Medium-Voltage Enclosed Fuses',
              tradeId: '26 18 36',
            },
            {
              id: '5d95b0d6-10d4-4608-94e6-70aacb2c8566',
              name: 'Medium-Voltage Motor Controllers',
              tradeId: '26 18 39',
            },
          ],
          tradeId: '26 18 00',
        },
        {
          id: 'b4065f86-d040-447d-ad45-8a72e70ce937',
          name: 'Low-Voltage Electrical Transmission',
          children: [],
          tradeId: '26 20 00',
        },
        {
          id: '17b91544-84b6-4998-a46f-66a503ddc550',
          name: 'Low-Voltage Electrical Service Entrance',
          children: [
            {
              id: '78cd8a8c-51fb-48c3-a5b8-e445a30fdf53',
              name: 'Low-Voltage Electrical Service Entrance',
              tradeId: '26 21 00',
            },
            {
              id: '169fa630-57c6-4e04-b6f4-05201cce5f3e',
              name: 'Low-Voltage Overhead Electrical Service Entrance',
              tradeId: '26 21 13',
            },
            {
              id: '5ffe4359-c128-4ded-a830-fbbc31797ad2',
              name: 'Low-Voltage Underground Electrical Service Entrance',
              tradeId: '26 21 16',
            },
          ],
          tradeId: '26 21 00',
        },
        {
          id: '687ec180-42d0-43e2-8363-fa2296c07461',
          name: 'Low-Voltage Transformers',
          children: [
            {
              id: '0c8bb37f-b1ee-443f-bd54-dff680162d4c',
              name: 'Low-Voltage Transformers',
              tradeId: '26 22 00',
            },
            {
              id: '0dade726-c385-4fa2-9ef9-bb1157c004a1',
              name: 'Low-Voltage Distribution Transformers',
              tradeId: '26 22 13',
            },
            {
              id: '198c8fb1-e096-46e1-b8e3-0d4b3ad7a6fb',
              name: 'Low-Voltage Buck-Boost Transformers',
              tradeId: '26 22 16',
            },
            {
              id: 'cfcfdc2e-5843-4bf0-9643-1cc2e10104b3',
              name: 'Control and Signal Transformers',
              tradeId: '26 22 19',
            },
          ],
          tradeId: '26 22 00',
        },
        {
          id: '7187365d-de12-4572-9af8-2adefd6065fe',
          name: 'Low-Voltage Switchgear',
          children: [
            {
              id: 'e1528c70-55c9-4923-b948-a1bbfa538604',
              name: 'Low-Voltage Switchgear',
              tradeId: '26 23 00',
            },
            {
              id: '7e51b7da-5423-42d5-8f1a-2635bdcffb95',
              name: 'Paralleling Low-Voltage Switchgear',
              tradeId: '26 23 13',
            },
          ],
          tradeId: '26 23 00',
        },
        {
          id: '9bfcd7bf-a814-48b0-afa8-978669a3efb1',
          name: 'Switchboards and Panelboards',
          children: [
            {
              id: '00c6eced-5930-4e96-a363-23f2d390fb34',
              name: 'Switchboards and Panelboards',
              tradeId: '26 24 00',
            },
            {
              id: 'ecc0114e-9189-4d98-b894-51b66ba7093f',
              name: 'Switchboards',
              tradeId: '26 24 13',
            },
            {
              id: '5b3339b2-40d3-427a-a901-ac7ab7e12843',
              name: 'Panelboards',
              tradeId: '26 24 16',
            },
            {
              id: '87ae1f4e-3ba0-4582-a3e1-a7927a85b452',
              name: 'Motor-Control Centers',
              tradeId: '26 24 19',
            },
          ],
          tradeId: '26 24 00',
        },
        {
          id: 'bcc8e9bd-a042-4d62-8f43-52fedc108bc1',
          name: 'Enclosed Bus Assemblies',
          children: [],
          tradeId: '26 25 00',
        },
        {
          id: 'accffb38-5fe1-4651-aa32-37e7cdb5e4e2',
          name: 'Power Distribution Units',
          children: [],
          tradeId: '26 26 00',
        },
        {
          id: '89721f2e-4a17-4e6f-9318-6325b4877937',
          name: 'Low-Voltage Distribution Equipment',
          children: [
            {
              id: '05bb1c28-c8f4-49a1-9434-c1472b43b114',
              name: 'Low-Voltage Distribution Equipment',
              tradeId: '26 27 00',
            },
            {
              id: '08cb7249-b215-4bfd-b4f8-43080b41de09',
              name: 'Electricity Metering',
              tradeId: '26 27 13',
            },
            {
              id: '5ca6de8a-6441-4708-8983-54866bd5632b',
              name: 'Electrical Cabinets and Enclosures',
              tradeId: '26 27 16',
            },
            {
              id: '4e48ef5a-dd48-4dc5-bf2a-a143d93509fd',
              name: 'Multi-Outlet Assemblies',
              tradeId: '26 27 19',
            },
            {
              id: 'a0236300-f1cf-4cab-83c6-5bbbe8d47cf8',
              name: 'Indoor Service Poles',
              tradeId: '26 27 23',
            },
            {
              id: '05f4a11c-f0a8-48b9-a5e4-421383cca5c2',
              name: 'Wiring Devices',
              tradeId: '26 27 26',
            },
            {
              id: 'a2582fbb-80d0-44b0-b489-69911d3162a1',
              name: 'Door Chimes',
              tradeId: '26 27 73',
            },
          ],
          tradeId: '26 27 00',
        },
        {
          id: 'ef43bbc2-f563-4453-9b06-8b911e63e3dd',
          name: 'Low-Voltage Circuit Protective Devices',
          children: [
            {
              id: '2824ad76-baf1-4646-bf82-1aff63480b8a',
              name: 'Low-Voltage Circuit Protective Devices',
              tradeId: '26 28 00',
            },
            {
              id: '5e60f6cb-7eae-46cb-a4b4-5a1cd233bdc1',
              name: 'Fuses',
              tradeId: '26 28 13',
            },
            {
              id: '9b20a98b-7a35-4bcc-b772-54bb0cf8f302',
              name: 'Enclosed Switches and Circuit Breakers',
              tradeId: '26 28 16',
            },
            {
              id: '08c70440-6d29-4448-837e-6243f71c0c7b',
              name: 'Enclosed Circuit Breakers',
              tradeId: '26 28 16.13',
            },
            {
              id: '75cb2b2e-98d7-4f85-ae7a-4e89dafee6ba',
              name: 'Enclosed Switches',
              tradeId: '26 28 16.16',
            },
          ],
          tradeId: '26 28 00',
        },
        {
          id: 'a8179f6d-2947-4fa3-afde-90b0a3d61bb2',
          name: 'Low-Voltage Controllers',
          children: [
            {
              id: '8241498a-db1c-44c3-b933-e1741137c6c6',
              name: 'Low-Voltage Controllers',
              tradeId: '26 29 00',
            },
            {
              id: '62894aae-62cf-4b08-b42e-78a8c81b0017',
              name: 'Enclosed Controllers',
              tradeId: '26 29 13',
            },
            {
              id: 'bbabe5ed-4810-4428-9de7-444209c8f37e',
              name: 'Across-the-Line Motor Controllers',
              tradeId: '26 29 13.13',
            },
            {
              id: '5cae931e-4834-4d0a-95d0-036e0821e297',
              name: 'Reduced-Voltage Motor Controllers',
              tradeId: '26 29 13.16',
            },
            {
              id: 'b001e354-a6da-4934-ac31-57ede50a6d47',
              name: 'Variable-Frequency Motor Controllers',
              tradeId: '26 29 23',
            },
            {
              id: 'ad9dbe17-74fd-45a7-b857-58affd91c264',
              name: 'Controllers for Fire Pump Drivers',
              tradeId: '26 29 33',
            },
            {
              id: 'bde7fbd2-c58e-40a0-b024-e084c7cf682e',
              name: 'Full-Service Controllers for Fire Pump Electric-Motor Drivers',
              tradeId: '26 29 33.13',
            },
            {
              id: '308448d2-211d-4101-b5e0-00fd7e2785f7',
              name: 'Limited-Service Controllers for Fire Pump Electric-Motor Drivers',
              tradeId: '26 29 33.16',
            },
            {
              id: 'c3d79973-8bfb-4dcb-957f-5cf200acad9e',
              name: 'Controllers for Fire Pump Diesel Engine Drivers',
              tradeId: '26 29 33.19',
            },
          ],
          tradeId: '26 29 00',
        },
        {
          id: '0f0fd852-9034-4f4f-b652-f9a8f19f8284',
          name: 'Facility Electrical Power Generating and Storing Equipment',
          children: [],
          tradeId: '26 30 00',
        },
        {
          id: '401381e6-0d43-4636-9d50-433ee786fc07',
          name: 'Photovoltaic Collectors',
          children: [],
          tradeId: '26 31 00',
        },
        {
          id: 'e24d8c58-ab97-4624-b76c-ef144d7e6928',
          name: 'Packaged Generator Assemblies',
          children: [
            {
              id: 'ef9e6dec-01b9-4191-a21d-2bcfe2333365',
              name: 'Packaged Generator Assemblies',
              tradeId: '26 32 00',
            },
            {
              id: 'aae57b23-df44-42f0-90b9-24b15367ef81',
              name: 'Engine Generators',
              tradeId: '26 32 13',
            },
            {
              id: 'b3e9c2cd-3e5e-4400-80c3-a1d69851c0d4',
              name: 'Diesel-Engine-Driven Generator Sets',
              tradeId: '26 32 13.13',
            },
            {
              id: '2771d3e6-c7d2-4013-83a4-716c6889b2f5',
              name: 'Gas-Engine-Driven Generator Sets',
              tradeId: '26 32 13.16',
            },
            {
              id: '5368b86c-815e-4ac3-85a0-face3d023d66',
              name: 'Gas-Turbine Engine-Driven Generators',
              tradeId: '26 32 13.26',
            },
            {
              id: 'cdf06f07-c5d2-4e66-a4d8-af07104e2882',
              name: 'Steam-Turbine Generators',
              tradeId: '26 32 16',
            },
            {
              id: '1569bf99-e1e6-4ff5-9909-b839d4bccce5',
              name: 'Hydro-Turbine Generators',
              tradeId: '26 32 19',
            },
            {
              id: 'c1335657-810c-4811-9c65-4029b385f36d',
              name: 'Wind Energy Equipment',
              tradeId: '26 32 23',
            },
            {
              id: '62c5894e-2cf8-4982-b3bf-3254dfd577e2',
              name: 'Frequency Changers',
              tradeId: '26 32 26',
            },
            {
              id: '623359e4-f8f6-407a-9282-0e28d06c0e0f',
              name: 'Rotary Converters',
              tradeId: '26 32 29',
            },
            {
              id: '393f1459-aca1-4c31-bb78-384947a90ef7',
              name: 'Rotary Uninterruptible Power Units',
              tradeId: '26 32 33',
            },
            {
              id: '3d0e5164-d777-4878-940d-697be1ce28a4',
              name: 'Resistive Load Banks',
              tradeId: '26 32 36',
            },
          ],
          tradeId: '26 32 00',
        },
        {
          id: '4c30f512-7dee-4280-a5c7-17460b38b251',
          name: 'Battery Equipment',
          children: [
            {
              id: '679c60fb-4df1-44ce-a1d1-bc0442267dbd',
              name: 'Battery Equipment',
              tradeId: '26 33 00',
            },
            {
              id: '61766546-c28e-4c03-8d8d-702ef1d0a7bc',
              name: 'Batteries',
              tradeId: '26 33 13',
            },
            {
              id: '3c079476-5842-44ec-ad6f-6d3a7e757969',
              name: 'Battery Racks',
              tradeId: '26 33 16',
            },
            {
              id: '81a09356-16a4-46ca-a741-a3c828558a96',
              name: 'Battery Units',
              tradeId: '26 33 19',
            },
            {
              id: '5944b7ac-2ac9-4338-8e10-59d9da0ecee9',
              name: 'Central Battery Equipment',
              tradeId: '26 33 23',
            },
            {
              id: '48fc4d26-de48-4f34-8bab-8d322742f914',
              name: 'Static Power Converters',
              tradeId: '26 33 33',
            },
            {
              id: '45821d26-d935-4e03-85c0-9b3db29278b8',
              name: 'Battery Chargers',
              tradeId: '26 33 43',
            },
            {
              id: '4b8c9ee6-304c-46f8-97d6-f47061d430a3',
              name: 'Battery Monitoring',
              tradeId: '26 33 46',
            },
            {
              id: '1e41b1a8-b4ce-45ff-85df-61edc1698087',
              name: 'Static Uninterruptible Power Supply',
              tradeId: '26 33 53',
            },
          ],
          tradeId: '26 33 00',
        },
        {
          id: '750c5e9e-5d13-4401-a8f0-04fc0291adf3',
          name: 'Power Filters and Conditioners',
          children: [
            {
              id: '2c63a2f4-0a03-4a90-b739-853e73041a81',
              name: 'Power Filters and Conditioners',
              tradeId: '26 35 00',
            },
            {
              id: 'ecd98f4e-8c37-4dc7-ba45-5b4dfb44e413',
              name: 'Capacitors',
              tradeId: '26 35 13',
            },
            {
              id: '95a33bdb-8046-4646-b54d-76531c10ba0e',
              name: 'Chokes and Inductors',
              tradeId: '26 35 16',
            },
            {
              id: '2524dc0d-be19-4b5c-8c90-04522aa990ef',
              name: 'Electromagnetic-Interference Filters',
              tradeId: '26 35 23',
            },
            {
              id: '3bf571fe-65f8-4d64-88c1-b3805e35d075',
              name: 'Harmonic Filters',
              tradeId: '26 35 26',
            },
            {
              id: '084be14a-7875-41b3-a039-ef491c89fcae',
              name: 'Power Factor Correction Equipment',
              tradeId: '26 35 33',
            },
            {
              id: 'a2309666-a583-4886-8112-3682c3b4cc98',
              name: 'Slip Controllers',
              tradeId: '26 35 36',
            },
            {
              id: '5c1202ac-d87f-4514-b112-831227506d31',
              name: 'Static-Frequency Converters',
              tradeId: '26 35 43',
            },
            {
              id: 'a81726d5-3fb5-4b7c-9164-7f45288adafb',
              name: 'Radio-Frequency-Interference Filters',
              tradeId: '26 35 46',
            },
            {
              id: '679fdbf3-b94b-4391-9a75-4a5a28589d71',
              name: 'Voltage Regulators',
              tradeId: '26 35 53',
            },
          ],
          tradeId: '26 35 00',
        },
        {
          id: 'bd0d0af3-4b92-4e53-b02b-cc07843d6f7a',
          name: 'Transfer Switches',
          children: [
            {
              id: '37d1bd33-b353-442c-b644-9b555f9d74d1',
              name: 'Transfer Switches',
              tradeId: '26 36 00',
            },
            {
              id: 'bbf731dc-85ac-4a7a-be98-8a72cd97ed70',
              name: 'Manual Transfer Switches',
              tradeId: '26 36 13',
            },
            {
              id: '64555991-7ca5-4449-98c5-a29cf1c4c824',
              name: 'Automatic Transfer Switches',
              tradeId: '26 36 23',
            },
          ],
          tradeId: '26 36 00',
        },
        {
          id: '018e98de-495e-4de9-9af6-2ea8bb8e146c',
          name: 'Electrical and Cathodic Protection',
          children: [],
          tradeId: '26 40 00',
        },
        {
          id: '8c442069-3fd0-4167-b528-482cc9bb1190',
          name: 'Facility Lightning Protection',
          children: [
            {
              id: '2cc68f66-7d5f-4de3-894d-20384590dd5e',
              name: 'Facility Lightning Protection',
              tradeId: '26 41 00',
            },
            {
              id: '92adf852-ebc5-4220-9087-6eea9d8d61eb',
              name: 'Lightning Protection for Structures',
              tradeId: '26 41 13',
            },
            {
              id: 'a041f5ac-7e3b-4f9f-b9f5-0738f7ad4e4b',
              name: 'Lightning Protection for Buildings',
              tradeId: '26 41 13.13',
            },
            {
              id: 'c2081684-4dcb-4d82-b4d0-7ae6af7f1997',
              name: 'Lightning Prevention and Dissipation',
              tradeId: '26 41 16',
            },
            {
              id: '72e8755b-7cf5-47f1-922b-b69fa3feed08',
              name: 'Early Streamer Emission Lightning Protection',
              tradeId: '26 41 19',
            },
            {
              id: 'ec3ef5b6-7d8b-4a56-8912-8ea5890721ab',
              name: 'Lightning Protection Surge Arresters and Suppressors',
              tradeId: '26 41 23',
            },
          ],
          tradeId: '26 41 00',
        },
        {
          id: 'e8492300-e54b-4c19-960f-e52bd9eeb498',
          name: 'Cathodic Protection',
          children: [
            {
              id: '6190c086-96c4-4df0-a493-44293e73f132',
              name: 'Cathodic Protection',
              tradeId: '26 42 00',
            },
            {
              id: '3f7787e7-268f-4c9c-b270-68b27eeb869a',
              name: 'Passive Cathodic Protection for Underground and Submerged Piping',
              tradeId: '26 42 13',
            },
            {
              id: 'd4a9a0f8-396d-4fbf-bd16-9d9bf69b1208',
              name: 'Passive Cathodic Protection for Underground Storage Tank',
              tradeId: '26 42 16',
            },
          ],
          tradeId: '26 42 00',
        },
        {
          id: 'ce2cc644-8728-4b8e-b009-b2688b7b1f9b',
          name: 'Surge Protective Devices',
          children: [
            {
              id: 'f10c1245-ebcd-41dc-9bf9-22043f23ff76',
              name: 'Surge Protective Devices',
              tradeId: '26 43 00',
            },
            {
              id: '4c2f2e4e-a23c-4378-9065-2591b65bcbd8',
              name: 'Surge Protective Devices for Low-Voltage Electrical Power Circuitsrical Power Circuits',
              tradeId: '26 43 13',
            },
          ],
          tradeId: '26 43 00',
        },
        {
          id: '077e50aa-515b-4c4f-937c-8bf4c28a1ae1',
          name: 'Lighting',
          children: [],
          tradeId: '26 50 00',
        },
        {
          id: '476bac00-0cec-4562-8732-676569a1f0db',
          name: 'Interior Lighting',
          children: [
            {
              id: '801ed6e7-28a5-40bb-8df4-96a67f4ab6f3',
              name: 'Interior Lighting',
              tradeId: '26 51 00',
            },
            {
              id: 'd5802ec6-c2b3-4e28-9a98-fe28aeefcf4d',
              name: 'Interior Lighting Fixtures, Lamps and Ballasts',
              tradeId: '26 51 13',
            },
          ],
          tradeId: '26 51 00',
        },
        {
          id: '02fcf0d0-7849-48df-9516-d399dc3041fd',
          name: 'Emergency Lighting',
          children: [],
          tradeId: '26 52 00',
        },
        {
          id: 'f00003a4-dee4-4eca-8681-99d3baa87c07',
          name: 'Exit Signs',
          children: [],
          tradeId: '26 53 00',
        },
        {
          id: 'ac81149d-0603-4b3d-ba67-9146d9e57c90',
          name: 'Classified Location Lighting',
          children: [],
          tradeId: '26 54 00',
        },
        {
          id: '30f34a93-8633-4dbf-a177-f9a849555f67',
          name: 'Special Purpose Lighting',
          children: [
            {
              id: '2495ff92-7e15-4795-aa28-d7a373ac18e9',
              name: 'Special Purpose Lighting',
              tradeId: '26 55 00',
            },
            {
              id: '63592b39-f12b-4d82-959e-5ac251f5a723',
              name: 'Outline Lighting',
              tradeId: '26 55 23',
            },
            {
              id: '302ec5d8-03bd-4b7a-81d6-34ae1cbec24b',
              name: 'Underwater Lighting',
              tradeId: '26 55 29',
            },
            {
              id: '48e8968e-097c-41a1-a2fd-191e427c2e3c',
              name: 'Hazard Warning Lighting',
              tradeId: '26 55 33',
            },
            {
              id: 'c5acd686-56f7-48c9-bbb0-d7a043746d65',
              name: 'Obstruction Lighting',
              tradeId: '26 55 36',
            },
            {
              id: '3e32ed72-c39d-4906-a14c-050eeb90dc93',
              name: 'Helipad Lighting',
              tradeId: '26 55 39',
            },
            {
              id: 'a7bf654a-d52b-4925-b577-e66abe630619',
              name: 'Security Lighting',
              tradeId: '26 55 53',
            },
            {
              id: '46c189c0-38b4-4622-a3a8-75b1dde6e44c',
              name: 'Display Lighting',
              tradeId: '26 55 59',
            },
            {
              id: 'b1f61546-0a99-47f2-aaf8-ea02dbaa6181',
              name: 'Theatrical Lighting',
              tradeId: '26 55 61',
            },
            {
              id: '301c6bb9-528c-48c0-a51c-2ef64410a291',
              name: 'Detention Lighting',
              tradeId: '26 55 63',
            },
            {
              id: '9ed7bc57-79e1-492c-a4a2-b713099eecab',
              name: 'Healthcare Lighting',
              tradeId: '26 55 70',
            },
            {
              id: 'a21aa64f-9424-4340-87ed-18467a1c6f5d',
              name: 'Broadcast Lighting',
              tradeId: '26 55 83',
            },
          ],
          tradeId: '26 55 00',
        },
        {
          id: '45b2c2ef-7c7b-4d2d-9814-3c753bb83524',
          name: 'Exterior Lighting',
          children: [
            {
              id: '0c85c217-fe60-4fa0-b518-27ef9ce2ffa4',
              name: 'Exterior Lighting',
              tradeId: '26 56 00',
            },
            {
              id: '302057b1-3532-427e-ba51-4a091f426065',
              name: 'Lighting Poles and Standards',
              tradeId: '26 56 13',
            },
            {
              id: '938e16aa-989f-4b88-8d7d-cb38ff6b4603',
              name: 'Parking Lighting',
              tradeId: '26 56 16',
            },
            {
              id: 'b2146a4d-b99d-4199-ba46-c5fc29d28793',
              name: 'Roadway Lighting',
              tradeId: '26 56 19',
            },
            {
              id: '10737a12-2572-44ba-9ddf-6276ad776615',
              name: 'Area Lighting',
              tradeId: '26 56 23',
            },
            {
              id: 'a5694339-bd01-4e02-9165-631e6de5f69f',
              name: 'Landscape Lighting',
              tradeId: '26 56 26',
            },
            {
              id: '46b5b789-af63-4a21-b168-ba26e798fff5',
              name: 'Site Lighting',
              tradeId: '26 56 29',
            },
            {
              id: '78ffb7c7-b9ae-47be-93bd-d14abed47de6',
              name: 'Walkway Lighting',
              tradeId: '26 56 33',
            },
            {
              id: '5aa48f44-3f25-4ea2-811e-797ef42584a5',
              name: 'Flood Lighting',
              tradeId: '26 56 36',
            },
            {
              id: '1765a6dd-21ca-41f3-940e-2393b35739c6',
              name: 'Exterior Athletic Lighting',
              tradeId: '26 56 68',
            },
          ],
          tradeId: '26 56 00',
        },
      ],
      tradeId: '26 00 00',
    },
    {
      id: '731bcbd1-dc38-427a-9d37-f6fce352f5a5',
      name: 'Communications',
      children: [
        {
          id: '9059db63-02bc-484c-b007-75263fbcb038',
          name: 'Communications',
          children: [],
          tradeId: '27 00 00',
        },
        {
          id: '29faa5bc-952a-4115-a0b5-c26d157c2ce0',
          name: 'Operation and Maintenance of Communications Systems',
          children: [
            {
              id: 'f8845b4c-93ca-4ccb-8932-0f420793219a',
              name: 'Operation and Maintenance of Communications Systems',
              tradeId: '27 01 00',
            },
            {
              id: '18a4e96c-bf8c-4d83-88fa-e47fd97605fe',
              name: 'Operation and Maintenance of Structured Cabling and Enclosures',
              tradeId: '27 01 10',
            },
            {
              id: 'efc46516-4419-4051-ada4-768fed1a3034',
              name: 'Operation and Maintenance of Data Communications',
              tradeId: '27 01 20',
            },
            {
              id: '0056f54e-52cb-4ae5-961b-c1ecddf55920',
              name: 'Operation and Maintenance of Voice Communications',
              tradeId: '27 01 30',
            },
            {
              id: '81232c50-6f26-4833-86ad-1818fc3363cd',
              name: 'Operation and Maintenance of Audio-Video Communications',
              tradeId: '27 01 40',
            },
            {
              id: 'c551572f-1c55-4a66-b936-3b0d0f29e8ac',
              name: 'Operation and Maintenance of Distributed Communications and Monitoring',
              tradeId: '27 01 50',
            },
          ],
          tradeId: '27 01 00',
        },
        {
          id: 'a568872a-e690-458a-91f1-dbd60548b404',
          name: 'Common Work Results for Communications',
          children: [
            {
              id: 'fe1b41b6-81a6-45bb-8ae1-1c4c14ae5859',
              name: 'Common Work Results for Communications',
              tradeId: '27 05 00',
            },
            {
              id: 'b14486c7-c2e2-49a8-b3b1-c1ce9502fa54',
              name: 'Communications Services',
              tradeId: '27 05 13',
            },
            {
              id: '4701a483-6af8-4cd6-93e0-70a4ed8df8fd',
              name: 'Dialtone Services',
              tradeId: '27 05 13.13',
            },
            {
              id: 'abd466de-3e05-4ab7-9b80-076bd96b4d3a',
              name: 'T1 Services',
              tradeId: '27 05 13.23',
            },
            {
              id: '5b53dfb7-f4ba-44e5-afb4-8388a45f538d',
              name: 'DSL Services',
              tradeId: '27 05 13.33',
            },
            {
              id: '98e682ba-e424-4336-9b17-232f7ad58e95',
              name: 'Cable Services',
              tradeId: '27 05 13.43',
            },
            {
              id: '6298f73b-45a2-4ab3-91c8-557d7f82f213',
              name: 'Satellite Services',
              tradeId: '27 05 13.53',
            },
            {
              id: '6b771fee-730a-4ff4-93f1-50559b418b87',
              name: 'Grounding and Bonding for Communications Systems',
              tradeId: '27 05 26',
            },
            {
              id: '5de20536-091c-462a-89a7-9e7b9fc53904',
              name: 'Pathways for Communications Systems',
              tradeId: '27 05 28',
            },
            {
              id: 'f6cd476b-00eb-40ef-b39c-c34d1b3bc678',
              name: 'Hangers and Supports for Communications Systems',
              tradeId: '27 05 29',
            },
            {
              id: '5d092913-e4af-42fd-b77a-39a629d8233e',
              name: 'Conduits and Backboxes for Communications Systems',
              tradeId: '27 05 33',
            },
            {
              id: 'bf72efc8-da66-44d1-b5db-40a617b0dc7b',
              name: 'Cable Trays for Communications Systems',
              tradeId: '27 05 36',
            },
            {
              id: '9ad121e3-620b-4534-9161-ffc046845eaf',
              name: 'Surface Raceways for Communications Systems',
              tradeId: '27 05 39',
            },
            {
              id: 'a9805c72-a048-4a01-826c-8ed0665616bb',
              name: 'Underground Ducts and Raceways for Communications Systems',
              tradeId: '27 05 43',
            },
            {
              id: 'dd04bcfa-800c-40ba-a219-efc23d985e31',
              name: 'Utility Poles for Communications Systems',
              tradeId: '27 05 46',
            },
            {
              id: '78510625-2d0b-4d46-a0ea-2885484d768f',
              name: 'Vibration and Seismic Controls for Communications Systems',
              tradeId: '27 05 48',
            },
            {
              id: '5ab7084b-0571-4851-98fa-17ec6ed43f59',
              name: 'Identification for Communications Systems',
              tradeId: '27 05 53',
            },
          ],
          tradeId: '27 05 00',
        },
        {
          id: '173f89f4-f0f6-4074-9194-c593eec81328',
          name: 'Schedules for Communications',
          children: [
            {
              id: '93855dea-e256-4c1f-8782-a8b8c29099bc',
              name: 'Schedules for Communications',
              tradeId: '27 06 00',
            },
            {
              id: '1cff3d62-b147-42e9-bbc4-b568984b3636',
              name: 'Schedules for Structured Cabling and Enclosures',
              tradeId: '27 06 10',
            },
            {
              id: '4c2eb25e-1be3-4cfb-9042-a8495922ee65',
              name: 'Schedules for Data Communications',
              tradeId: '27 06 20',
            },
            {
              id: '0dd0267f-ab4f-4273-a817-805d1072a605',
              name: 'Schedules for Voice Communications',
              tradeId: '27 06 30',
            },
            {
              id: 'd7e40261-9eec-4ab1-bd3c-ece5ffc3e494',
              name: 'Schedules for Audio-Video Communications',
              tradeId: '27 06 40',
            },
            {
              id: '38f00e78-c20b-42ba-b2e6-b5161856b1b8',
              name: 'Schedules for Distributed Communications and Monitoring',
              tradeId: '27 06 50',
            },
          ],
          tradeId: '27 06 00',
        },
        {
          id: '73243067-56d8-4dc0-88b7-2ab0396e7d1a',
          name: 'Commissioning of Communications',
          children: [],
          tradeId: '27 08 00',
        },
        {
          id: 'aac2092b-fae2-4c98-a860-ee19ff3273d6',
          name: 'Structured Cabling',
          children: [],
          tradeId: '27 10 00',
        },
        {
          id: 'e05b45d1-3e60-4b32-adcc-1e61ee4b0945',
          name: 'Communications Equipment Room Fittings',
          children: [
            {
              id: '9d277d54-1ed1-4a64-a6cc-f0c225275641',
              name: 'Communications Equipment Room Fittings',
              tradeId: '27 11 00',
            },
            {
              id: '92ca7592-1a92-41fb-aadc-aaacd79fa1b5',
              name: 'Communications Entrance Protection',
              tradeId: '27 11 13',
            },
            {
              id: 'e5a3f7a4-02e9-48d3-a003-60b645c301e4',
              name: 'Communications Cabinets, Racks, Frames and Enclosures',
              tradeId: '27 11 16',
            },
            {
              id: 'fae9241b-2d61-41c2-8871-2fd2be354460',
              name: 'Communications Termination Blocks and Patch Panels',
              tradeId: '27 11 19',
            },
            {
              id: '0a958dbd-40ef-4575-bf41-08c03a272563',
              name: 'Communications Cable Management and Ladder Rack',
              tradeId: '27 11 23',
            },
            {
              id: '9e0c66a4-a1e8-43c4-a4b6-dc228beb484f',
              name: 'Communications Rack Mounted Power Protection and Power Strips',
              tradeId: '27 11 26',
            },
          ],
          tradeId: '27 11 00',
        },
        {
          id: '2ec3da2d-d466-4aab-be03-a683223ff24c',
          name: 'Communications Backbone Cabling',
          children: [
            {
              id: 'b4e3a5a7-713a-481a-af67-184eefb85d55',
              name: 'Communications Backbone Cabling',
              tradeId: '27 13 00',
            },
            {
              id: 'e968f8b5-fa60-4ff5-967b-23c601a2fe6c',
              name: 'Communications Copper Backbone Cabling',
              tradeId: '27 13 13',
            },
            {
              id: '83f358cd-aa71-4234-9b10-b12859f4f0bd',
              name: 'Communications Copper Cable Splicing and Terminations',
              tradeId: '27 13 13.13',
            },
            {
              id: '78f481d6-ac80-4ff4-8b87-285f3522e37a',
              name: 'Communications Optical Fiber Backbone Cabling',
              tradeId: '27 13 23',
            },
            {
              id: 'c8af3078-59d3-4875-b1fb-5ff8e50a6383',
              name: 'Communications Optical Fiber Splicing and Terminations',
              tradeId: '27 13 23.13',
            },
            {
              id: '7dcf737f-ab75-4a45-a89c-f7ee6f9b868c',
              name: 'Communications Coaxial Backbone Cabling',
              tradeId: '27 13 33',
            },
            {
              id: 'dc3ead09-65d7-4473-a416-c32bd84bcd28',
              name: 'Communications Coaxial Splicing and Terminations',
              tradeId: '27 13 33.13',
            },
            {
              id: 'be6c108b-f141-4aa3-aa4b-6793ae11d8d3',
              name: 'Communications Services Cabling',
              tradeId: '27 13 43',
            },
            {
              id: 'efb63ffe-92fc-4896-a27a-822f35bc01f8',
              name: 'Dialtone Services Cabling',
              tradeId: '27 13 43.13',
            },
            {
              id: '1b3af218-8480-4ef2-8864-4585ffcc1c84',
              name: 'T1 Services Cabling',
              tradeId: '27 13 43.23',
            },
            {
              id: '19c951a5-8a08-4cb7-a494-08bd2a19253d',
              name: 'DSL Services Cabling',
              tradeId: '27 13 43.33',
            },
            {
              id: '408d094f-977a-475b-b5f8-c5daa64ac26a',
              name: 'Cable Services Cabling',
              tradeId: '27 13 43.43',
            },
            {
              id: 'a7993a0b-84a1-4eef-a865-bfceac161c91',
              name: 'Satellite Services Cabling',
              tradeId: '27 13 43.53',
            },
          ],
          tradeId: '27 13 00',
        },
        {
          id: 'ebb80892-f53f-4e8d-8b96-dddd8b0fbf2e',
          name: 'Communications Horizontal Cabling',
          children: [
            {
              id: 'ca67272c-8953-48e0-8614-9faeda765aa6',
              name: 'Communications Horizontal Cabling',
              tradeId: '27 15 00',
            },
            {
              id: '22e790da-17e7-40f2-b33f-05806e4c8cad',
              name: 'Voice Communications Horizontal Cabling',
              tradeId: '27 15 00.16',
            },
            {
              id: '8b145f33-534a-4675-add1-e5f8a63ca259',
              name: 'Data Communications Horizontal Cabling',
              tradeId: '27 15 00.19',
            },
            {
              id: '6a198dc3-b29e-42ea-b77c-7cb0ace23169',
              name: 'Audio-Video Communications Horizontal Cabling',
              tradeId: '27 15 00.23',
            },
            {
              id: '378244ca-a916-44b9-aed1-ea71b5fbe9a2',
              name: 'Patient Monitoring and Telemetry Communications Horizontal Cabling',
              tradeId: '27 15 00.39',
            },
            {
              id: '8e92ec6b-3635-4a55-b611-fe446216e6ff',
              name: 'Nurse Call and Intercom Communications Horizontal Cabling',
              tradeId: '27 15 00.43',
            },
            {
              id: '8c3d0adb-748e-435c-a811-65fb0a64d847',
              name: 'Paging Communications Horizontal Cabling',
              tradeId: '27 15 00.46',
            },
            {
              id: 'be818fe6-fea5-467a-a21b-91d2edff2157',
              name: 'Intermediate Frequency/Radio Frequency Communications Horizontal Cabling',
              tradeId: '27 15 00.49',
            },
            {
              id: 'b8d174fa-4b12-4a51-9b67-1e87137cbeec',
              name: 'Antennas Communications Horizontal Cabling',
              tradeId: '27 15 00.53',
            },
            {
              id: '395473f5-1b62-4c38-9bb3-9d1217bf5b11',
              name: 'Communications Copper Horizontal Cabling',
              tradeId: '27 15 13',
            },
            {
              id: '3156eaaa-8759-4f31-83cb-299b96278963',
              name: 'Communications Optical Fiber Horizontal Cabling',
              tradeId: '27 15 23',
            },
            {
              id: '0535e106-bd06-4f60-a231-0b831637a890',
              name: 'Communications Coaxial Horizontal Cabling',
              tradeId: '27 15 33',
            },
            {
              id: 'c9702763-4763-46fd-ad58-f3ec08abfb51',
              name: 'Communications Faceplates and Connectors',
              tradeId: '27 15 43',
            },
          ],
          tradeId: '27 15 00',
        },
        {
          id: '36f552d8-8489-4b17-9bff-9ba63872eafd',
          name: 'Communications Horizontal Cabling',
          children: [
            {
              id: 'b3972b3b-32fb-43ae-b262-6a252b4c4b22',
              name: 'Communications Horizontal Cabling',
              tradeId: '27 15 00',
            },
            {
              id: 'a039b3c4-7894-481c-ac72-ab05c2273803',
              name: 'Voice Communications Horizontal Cabling',
              tradeId: '27 15 00.16',
            },
            {
              id: '6c48ec10-0b12-409b-a919-c671c45fbfa8',
              name: 'Data Communications Horizontal Cabling',
              tradeId: '27 15 00.19',
            },
            {
              id: 'c6efee79-baf1-470f-ad42-c383477a1707',
              name: 'Audio-Video Communications Horizontal Cabling',
              tradeId: '27 15 00.23',
            },
            {
              id: '955ce694-bef6-487e-8070-8188d4b1ac85',
              name: 'Patient Monitoring and Telemetry Communications Horizontal Cabling',
              tradeId: '27 15 00.39',
            },
            {
              id: '576090fd-1791-4fe5-bb34-bd07c8c202f0',
              name: 'Nurse Call and Intercom Communications Horizontal Cabling',
              tradeId: '27 15 00.43',
            },
            {
              id: '20ddf52a-d7c7-4b83-b831-eaed9a74d758',
              name: 'Paging Communications Horizontal Cabling',
              tradeId: '27 15 00.46',
            },
            {
              id: '30f522cf-4acc-45bb-a80f-067b826ac6a8',
              name: 'Intermediate Frequency/Radio Frequency Communications Horizontal Cabling',
              tradeId: '27 15 00.49',
            },
            {
              id: 'e7662c75-4560-478c-87e2-b781326665a2',
              name: 'Antennas Communications Horizontal Cabling',
              tradeId: '27 15 00.53',
            },
            {
              id: 'c5d75c7a-783d-405b-bcd3-f607f36be331',
              name: 'Communications Copper Horizontal Cabling',
              tradeId: '27 15 13',
            },
            {
              id: '0f714120-04fe-44c6-b767-ca64bf4a4c83',
              name: 'Communications Optical Fiber Horizontal Cabling',
              tradeId: '27 15 23',
            },
            {
              id: '8f0e4ede-0670-40c9-920e-fcceec9544a1',
              name: 'Communications Coaxial Horizontal Cabling',
              tradeId: '27 15 33',
            },
            {
              id: 'f4bb78fa-577d-47b7-a608-b6683b04d7b2',
              name: 'Communications Faceplates and Connectors',
              tradeId: '27 15 43',
            },
          ],
          tradeId: '27 15 00',
        },
        {
          id: 'f7f0c1d4-0b15-426a-ba91-defecf3d4577',
          name: 'Communications Horizontal Cabling',
          children: [
            {
              id: 'bcf4b259-458b-46fe-8891-355fba6f517f',
              name: 'Communications Horizontal Cabling',
              tradeId: '27 15 00',
            },
            {
              id: 'c7be1b39-260b-4a77-b584-edf583335938',
              name: 'Voice Communications Horizontal Cabling',
              tradeId: '27 15 00.16',
            },
            {
              id: '36a9c966-53bb-482d-9948-f965712d1c81',
              name: 'Data Communications Horizontal Cabling',
              tradeId: '27 15 00.19',
            },
            {
              id: 'bc233d19-39c9-46a7-829e-c005d514432d',
              name: 'Audio-Video Communications Horizontal Cabling',
              tradeId: '27 15 00.23',
            },
            {
              id: 'bf19bdce-f919-4316-9441-809b0d3d9a64',
              name: 'Patient Monitoring and Telemetry Communications Horizontal Cabling',
              tradeId: '27 15 00.39',
            },
            {
              id: '35ce12dc-22e1-4397-948d-b2c66385c140',
              name: 'Nurse Call and Intercom Communications Horizontal Cabling',
              tradeId: '27 15 00.43',
            },
            {
              id: 'e487fd89-d7cb-44ab-8aab-02d4d7f7a1ee',
              name: 'Paging Communications Horizontal Cabling',
              tradeId: '27 15 00.46',
            },
            {
              id: '01de21fa-c596-4690-aba7-fea3b95e9f17',
              name: 'Intermediate Frequency/Radio Frequency Communications Horizontal Cabling',
              tradeId: '27 15 00.49',
            },
            {
              id: 'bb059719-b597-4ea7-9649-ae97c52a057f',
              name: 'Antennas Communications Horizontal Cabling',
              tradeId: '27 15 00.53',
            },
            {
              id: '278c4753-4592-4b33-bc98-c8a596c96c0b',
              name: 'Communications Copper Horizontal Cabling',
              tradeId: '27 15 13',
            },
            {
              id: '36c18879-2b9d-4b6f-8463-1de274033d67',
              name: 'Communications Optical Fiber Horizontal Cabling',
              tradeId: '27 15 23',
            },
            {
              id: '65b0483e-6ba1-49d1-a1d0-a6554f2a8f53',
              name: 'Communications Coaxial Horizontal Cabling',
              tradeId: '27 15 33',
            },
            {
              id: '630ea92c-9a0a-4a42-8267-ff28e6657c2c',
              name: 'Communications Faceplates and Connectors',
              tradeId: '27 15 43',
            },
          ],
          tradeId: '27 15 00',
        },
        {
          id: 'bb9fc2b2-0135-4515-8920-4ed968956f4f',
          name: 'Communications Horizontal Cabling',
          children: [
            {
              id: '83a3d10e-1289-4cf4-a53f-328c1d6b4339',
              name: 'Communications Horizontal Cabling',
              tradeId: '27 15 00',
            },
            {
              id: '534aef5b-a84c-41c7-ab3e-8c9862573560',
              name: 'Voice Communications Horizontal Cabling',
              tradeId: '27 15 00.16',
            },
            {
              id: '19e4a082-c41c-4867-a862-9290ace79dba',
              name: 'Data Communications Horizontal Cabling',
              tradeId: '27 15 00.19',
            },
            {
              id: '6b635490-1c79-4991-803a-f132cb2d334a',
              name: 'Audio-Video Communications Horizontal Cabling',
              tradeId: '27 15 00.23',
            },
            {
              id: '1f96d9dd-ea22-40d8-a941-329def67206a',
              name: 'Patient Monitoring and Telemetry Communications Horizontal Cabling',
              tradeId: '27 15 00.39',
            },
            {
              id: '36e5f357-865c-4a87-9c55-6be6cb468161',
              name: 'Nurse Call and Intercom Communications Horizontal Cabling',
              tradeId: '27 15 00.43',
            },
            {
              id: '67fbaac2-4b0b-49e1-a2d6-efd53306317e',
              name: 'Paging Communications Horizontal Cabling',
              tradeId: '27 15 00.46',
            },
            {
              id: '83bf0258-977f-4c6c-96e2-e680e57625e6',
              name: 'Intermediate Frequency/Radio Frequency Communications Horizontal Cabling',
              tradeId: '27 15 00.49',
            },
            {
              id: '5b753998-c5fc-4ef2-820b-1a18419a503d',
              name: 'Antennas Communications Horizontal Cabling',
              tradeId: '27 15 00.53',
            },
            {
              id: '058f3f2e-1b4b-4591-8ee9-92c8e209f687',
              name: 'Communications Copper Horizontal Cabling',
              tradeId: '27 15 13',
            },
            {
              id: '52e9c23f-3ceb-4159-8c16-2d72f8e8b56a',
              name: 'Communications Optical Fiber Horizontal Cabling',
              tradeId: '27 15 23',
            },
            {
              id: '72069efc-2162-4d5a-aaa9-8a5c1dee82f9',
              name: 'Communications Coaxial Horizontal Cabling',
              tradeId: '27 15 33',
            },
            {
              id: 'd91e6e2f-7a1c-4732-89e4-a3bf09052740',
              name: 'Communications Faceplates and Connectors',
              tradeId: '27 15 43',
            },
          ],
          tradeId: '27 15 00',
        },
        {
          id: 'c410ce26-b4a6-4b28-bf62-cb0da1b97e02',
          name: 'Communications Horizontal Cabling',
          children: [
            {
              id: 'bf789a05-8d39-4224-99ed-39fddb849c0b',
              name: 'Communications Horizontal Cabling',
              tradeId: '27 15 00',
            },
            {
              id: '3da389d3-ceac-4fdd-aec6-9280402a4b80',
              name: 'Voice Communications Horizontal Cabling',
              tradeId: '27 15 00.16',
            },
            {
              id: '5bacd844-4c90-486e-bddf-324421f47923',
              name: 'Data Communications Horizontal Cabling',
              tradeId: '27 15 00.19',
            },
            {
              id: 'e680a0af-458d-45fe-8634-8b583f94ee09',
              name: 'Audio-Video Communications Horizontal Cabling',
              tradeId: '27 15 00.23',
            },
            {
              id: '5d50cbd9-e8b1-4fc6-b97d-bc0f4e4e22a0',
              name: 'Patient Monitoring and Telemetry Communications Horizontal Cabling',
              tradeId: '27 15 00.39',
            },
            {
              id: '835d79b7-12da-42a9-bef1-ce658576fef8',
              name: 'Nurse Call and Intercom Communications Horizontal Cabling',
              tradeId: '27 15 00.43',
            },
            {
              id: '79d50731-6331-4a12-b95f-06d68f3ec98b',
              name: 'Paging Communications Horizontal Cabling',
              tradeId: '27 15 00.46',
            },
            {
              id: '3fef4edc-043a-4f93-8106-3fc387c8f635',
              name: 'Intermediate Frequency/Radio Frequency Communications Horizontal Cabling',
              tradeId: '27 15 00.49',
            },
            {
              id: 'fb4c9332-b0e4-4642-b356-308fd36943d8',
              name: 'Antennas Communications Horizontal Cabling',
              tradeId: '27 15 00.53',
            },
            {
              id: '967e81f8-42f4-40d3-b1dc-7266e9e00119',
              name: 'Communications Copper Horizontal Cabling',
              tradeId: '27 15 13',
            },
            {
              id: '12495345-4e4f-4aca-a5db-769ebb60b41a',
              name: 'Communications Optical Fiber Horizontal Cabling',
              tradeId: '27 15 23',
            },
            {
              id: '6f836f2c-2b67-4b26-a5ac-29fa9ca9aebd',
              name: 'Communications Coaxial Horizontal Cabling',
              tradeId: '27 15 33',
            },
            {
              id: 'da5f9904-c934-49d4-9e26-2d90614bcd63',
              name: 'Communications Faceplates and Connectors',
              tradeId: '27 15 43',
            },
          ],
          tradeId: '27 15 00',
        },
        {
          id: 'a3484084-5de5-4efe-9ebb-b45b7b8280c5',
          name: 'Communications Horizontal Cabling',
          children: [
            {
              id: '9b5e43cb-e639-4b2e-a23c-c6257e0bbdf1',
              name: 'Communications Horizontal Cabling',
              tradeId: '27 15 00',
            },
            {
              id: 'b502223f-df4d-4754-92e3-0ae779abdda6',
              name: 'Voice Communications Horizontal Cabling',
              tradeId: '27 15 00.16',
            },
            {
              id: 'c8a0b338-4e0a-479e-a45f-eaf7d3e6fc5e',
              name: 'Data Communications Horizontal Cabling',
              tradeId: '27 15 00.19',
            },
            {
              id: '611d8e2b-8326-48c8-bd36-bf6ed2c5820a',
              name: 'Audio-Video Communications Horizontal Cabling',
              tradeId: '27 15 00.23',
            },
            {
              id: 'e658a0b5-a853-4763-aa2c-7ee23e68cc7f',
              name: 'Patient Monitoring and Telemetry Communications Horizontal Cabling',
              tradeId: '27 15 00.39',
            },
            {
              id: '9ea2f398-1cdd-4461-9990-fbddd2d6037c',
              name: 'Nurse Call and Intercom Communications Horizontal Cabling',
              tradeId: '27 15 00.43',
            },
            {
              id: '68c95996-5e38-4f9c-8549-21c7cb7c2b1b',
              name: 'Paging Communications Horizontal Cabling',
              tradeId: '27 15 00.46',
            },
            {
              id: '97831f98-c94b-45da-806b-86658e8459b7',
              name: 'Intermediate Frequency/Radio Frequency Communications Horizontal Cabling',
              tradeId: '27 15 00.49',
            },
            {
              id: '5c5c63b1-0a87-4a75-9c12-e2297bcc19b8',
              name: 'Antennas Communications Horizontal Cabling',
              tradeId: '27 15 00.53',
            },
            {
              id: '9ccd1c78-f823-470f-b4f2-8092891561ac',
              name: 'Communications Copper Horizontal Cabling',
              tradeId: '27 15 13',
            },
            {
              id: 'dd152f90-b629-4eb3-b983-c5f93e72afd3',
              name: 'Communications Optical Fiber Horizontal Cabling',
              tradeId: '27 15 23',
            },
            {
              id: '19f8132f-1c7e-44f5-a960-2677985d69e6',
              name: 'Communications Coaxial Horizontal Cabling',
              tradeId: '27 15 33',
            },
            {
              id: 'e2ef7947-f183-43b3-a7a0-5259375ecad4',
              name: 'Communications Faceplates and Connectors',
              tradeId: '27 15 43',
            },
          ],
          tradeId: '27 15 00',
        },
        {
          id: 'a509f536-14ac-4ea7-855a-6eb3065e2e8b',
          name: 'Communications Horizontal Cabling',
          children: [
            {
              id: '31ec9e05-37e0-44d1-a063-8bc85fa6f824',
              name: 'Communications Horizontal Cabling',
              tradeId: '27 15 00',
            },
            {
              id: 'f81d0d3c-a1bd-4620-a022-8325ec6fbe87',
              name: 'Voice Communications Horizontal Cabling',
              tradeId: '27 15 00.16',
            },
            {
              id: '72cceb12-2db9-4add-9473-fb2c7097ff83',
              name: 'Data Communications Horizontal Cabling',
              tradeId: '27 15 00.19',
            },
            {
              id: '2b08cc0d-5c87-4861-a415-a4f6518a88af',
              name: 'Audio-Video Communications Horizontal Cabling',
              tradeId: '27 15 00.23',
            },
            {
              id: '0f41e507-1f5a-4b5d-ada6-e41e8f1b3354',
              name: 'Patient Monitoring and Telemetry Communications Horizontal Cabling',
              tradeId: '27 15 00.39',
            },
            {
              id: '75ad4b84-10ee-4df2-9057-a58999c56751',
              name: 'Nurse Call and Intercom Communications Horizontal Cabling',
              tradeId: '27 15 00.43',
            },
            {
              id: '550cac13-e3a0-48e1-b201-4eeffd4316a4',
              name: 'Paging Communications Horizontal Cabling',
              tradeId: '27 15 00.46',
            },
            {
              id: '4ef53f8b-8fe8-4dde-9b90-bdd3dcc1e60a',
              name: 'Intermediate Frequency/Radio Frequency Communications Horizontal Cabling',
              tradeId: '27 15 00.49',
            },
            {
              id: 'c390415a-91c5-4c27-904f-105453157bb7',
              name: 'Antennas Communications Horizontal Cabling',
              tradeId: '27 15 00.53',
            },
            {
              id: '436c2579-905e-4694-be58-4c873942aa1d',
              name: 'Communications Copper Horizontal Cabling',
              tradeId: '27 15 13',
            },
            {
              id: '2e9da0c9-d25e-42aa-9765-526ae5c1822c',
              name: 'Communications Optical Fiber Horizontal Cabling',
              tradeId: '27 15 23',
            },
            {
              id: 'e6653c2d-05cc-4a53-87b8-ae6fbcda61d5',
              name: 'Communications Coaxial Horizontal Cabling',
              tradeId: '27 15 33',
            },
            {
              id: '81a531a0-8335-42b4-8337-6bfd8b3e4cc5',
              name: 'Communications Faceplates and Connectors',
              tradeId: '27 15 43',
            },
          ],
          tradeId: '27 15 00',
        },
        {
          id: '1558a197-e20f-42f5-8a67-e654931f34ca',
          name: 'Communications Horizontal Cabling',
          children: [
            {
              id: '5b4393b5-a97c-4bde-bf27-cc7856226a3f',
              name: 'Communications Horizontal Cabling',
              tradeId: '27 15 00',
            },
            {
              id: '16be06d9-9330-478d-996e-15552af48e1e',
              name: 'Voice Communications Horizontal Cabling',
              tradeId: '27 15 00.16',
            },
            {
              id: '37c40294-685d-4952-bf2c-fa5ec9c99baa',
              name: 'Data Communications Horizontal Cabling',
              tradeId: '27 15 00.19',
            },
            {
              id: '902e8871-8bbc-4fde-8901-16be9e7b3c22',
              name: 'Audio-Video Communications Horizontal Cabling',
              tradeId: '27 15 00.23',
            },
            {
              id: '707f2336-e430-4755-855a-e9f50739a5b3',
              name: 'Patient Monitoring and Telemetry Communications Horizontal Cabling',
              tradeId: '27 15 00.39',
            },
            {
              id: '51fb1e91-a40b-4b11-8273-ca401bab70ae',
              name: 'Nurse Call and Intercom Communications Horizontal Cabling',
              tradeId: '27 15 00.43',
            },
            {
              id: 'c85c9049-de51-4559-95b3-f4fde2e1c30f',
              name: 'Paging Communications Horizontal Cabling',
              tradeId: '27 15 00.46',
            },
            {
              id: 'f9ca10e5-517b-4810-af75-3f66f980dd48',
              name: 'Intermediate Frequency/Radio Frequency Communications Horizontal Cabling',
              tradeId: '27 15 00.49',
            },
            {
              id: '6eba0399-7c2c-4cc9-94aa-940ffe762995',
              name: 'Antennas Communications Horizontal Cabling',
              tradeId: '27 15 00.53',
            },
            {
              id: '6c90019d-676c-4330-bb31-4de3396a556c',
              name: 'Communications Copper Horizontal Cabling',
              tradeId: '27 15 13',
            },
            {
              id: 'e10606e6-bb02-4fc5-8007-5e5fb4d1a1c4',
              name: 'Communications Optical Fiber Horizontal Cabling',
              tradeId: '27 15 23',
            },
            {
              id: '9460f25e-9449-46f1-8727-36052d72b534',
              name: 'Communications Coaxial Horizontal Cabling',
              tradeId: '27 15 33',
            },
            {
              id: 'ae1c6b64-cb30-4ab7-951f-932303f6abc3',
              name: 'Communications Faceplates and Connectors',
              tradeId: '27 15 43',
            },
          ],
          tradeId: '27 15 00',
        },
        {
          id: '36020759-342a-4618-adb0-39515df6b053',
          name: 'Communications Horizontal Cabling',
          children: [
            {
              id: 'd4d9ce43-4b13-4a8c-8ce3-66393bd6a039',
              name: 'Communications Horizontal Cabling',
              tradeId: '27 15 00',
            },
            {
              id: 'dc2de1f0-4b1c-4d95-9d7d-b912ee4f3fb2',
              name: 'Voice Communications Horizontal Cabling',
              tradeId: '27 15 00.16',
            },
            {
              id: '2a226341-ae04-4a4d-b33b-be4456142b6f',
              name: 'Data Communications Horizontal Cabling',
              tradeId: '27 15 00.19',
            },
            {
              id: '644654e8-4d63-40f4-aea6-3843595b6021',
              name: 'Audio-Video Communications Horizontal Cabling',
              tradeId: '27 15 00.23',
            },
            {
              id: '184c9ad9-7cf7-4213-bdb1-52d2e52fdd2e',
              name: 'Patient Monitoring and Telemetry Communications Horizontal Cabling',
              tradeId: '27 15 00.39',
            },
            {
              id: '16adf6b8-e376-4c4b-815e-f6ff3a92ea00',
              name: 'Nurse Call and Intercom Communications Horizontal Cabling',
              tradeId: '27 15 00.43',
            },
            {
              id: '382da815-f4f5-4875-b8c8-243cdfc7230b',
              name: 'Paging Communications Horizontal Cabling',
              tradeId: '27 15 00.46',
            },
            {
              id: '744fcc6a-469f-45fb-bae2-c15d7e8d5b71',
              name: 'Intermediate Frequency/Radio Frequency Communications Horizontal Cabling',
              tradeId: '27 15 00.49',
            },
            {
              id: '405e20b7-495f-43b6-89dd-ee7c4a0d8251',
              name: 'Antennas Communications Horizontal Cabling',
              tradeId: '27 15 00.53',
            },
            {
              id: 'b84cf556-e358-4ecf-b8ba-0912e9c27907',
              name: 'Communications Copper Horizontal Cabling',
              tradeId: '27 15 13',
            },
            {
              id: '8becd55b-88f7-4f55-be26-d00c1226e84f',
              name: 'Communications Optical Fiber Horizontal Cabling',
              tradeId: '27 15 23',
            },
            {
              id: '4244cadf-1988-4b9b-8821-65a2d2fe635a',
              name: 'Communications Coaxial Horizontal Cabling',
              tradeId: '27 15 33',
            },
            {
              id: 'ea76648a-72f6-4014-8d91-109fd73adbf7',
              name: 'Communications Faceplates and Connectors',
              tradeId: '27 15 43',
            },
          ],
          tradeId: '27 15 00',
        },
        {
          id: '5b710b06-b37c-43c4-977a-bba4a515ad29',
          name: 'Communications Connecting Cords, Devices and Adapters',
          children: [
            {
              id: 'e12a5035-a309-4c90-9c14-af91b4427e0e',
              name: 'Communications Connecting Cords, Devices and Adapters',
              tradeId: '27 16 00',
            },
            {
              id: 'ebb137aa-cff6-489a-9187-e6c1f8a05249',
              name: 'Communications Custom Cable Assemblies',
              tradeId: '27 16 13',
            },
            {
              id: '24fcae12-e264-4f9a-856a-2a88a33881bb',
              name: 'Communications Media Converters, Adapters and Transceivers',
              tradeId: '27 16 16',
            },
            {
              id: 'd0434327-2c2b-4357-8dbb-5231270c9012',
              name: 'Communications Patch Cords, Station Cords and Cross Connect Wire',
              tradeId: '27 16 19',
            },
          ],
          tradeId: '27 16 00',
        },
        {
          id: '4de078f9-a619-496e-a93b-f33bed4e2941',
          name: 'Data Communications',
          children: [],
          tradeId: '27 20 00',
        },
        {
          id: '8d17ce03-9483-4419-80cc-d9cd71e732d1',
          name: 'Data Communications Network Equipment',
          children: [
            {
              id: 'aa2f82a6-8beb-4fb9-b749-db9f40e150ed',
              name: 'Data Communications Network Equipment',
              tradeId: '27 21 00',
            },
            {
              id: 'a7985053-6f4e-4e6f-9ae9-6bb9db79b8ef',
              name: 'Data Communications Firewalls',
              tradeId: '27 21 13',
            },
            {
              id: '01e794d9-9d6b-4d8f-ab3d-6027447b7014',
              name: 'Data Communications Routers, CSU/DSU, Multiplexers, Codecs and Modems',
              tradeId: '27 21 16',
            },
            {
              id: '67c18129-d4aa-4647-b1b4-4884c355f816',
              name: 'Data Communications Network Management',
              tradeId: '27 21 26',
            },
            {
              id: 'd68df844-27ee-4543-8d68-6207ae40171f',
              name: 'Data Communications Switches and Hubs',
              tradeId: '27 21 29',
            },
            {
              id: '2fab826f-a878-4da2-9f17-4a2ecd72b2c3',
              name: 'Data Communications Wireless Access Points',
              tradeId: '27 21 33',
            },
          ],
          tradeId: '27 21 00',
        },
        {
          id: '085ebf93-9075-4521-8af5-47941425a28b',
          name: 'Data Communications Hardware',
          children: [
            {
              id: '4bf1cb90-9548-4da2-98f1-c36e07f2c9dc',
              name: 'Data Communications Hardware',
              tradeId: '27 22 00',
            },
            {
              id: '783c5b41-67c6-4e0d-9a83-9981a0cc7da4',
              name: 'Data Communications Mainframes',
              tradeId: '27 22 13',
            },
            {
              id: '44441fc1-5bdd-4629-83ae-7177abe7d786',
              name: 'Data Communications Storage and Backup',
              tradeId: '27 22 16',
            },
            {
              id: 'e94d143f-c9ce-4213-b273-5d4110667b30',
              name: 'Data Communications Servers',
              tradeId: '27 22 19',
            },
            {
              id: '5484a185-95e9-4c47-8cde-36a52854d415',
              name: 'Data Communications Desktops',
              tradeId: '27 22 23',
            },
            {
              id: '452345ce-dd38-49b6-8478-1273dc504c0f',
              name: 'Data Communications Laptops',
              tradeId: '27 22 26',
            },
            {
              id: '0930dabe-4dbc-457b-b1ff-632b8cf2fd0e',
              name: 'Data Communications Handhelds',
              tradeId: '27 22 29',
            },
          ],
          tradeId: '27 22 00',
        },
        {
          id: 'f93bd3a5-2451-4ee7-a2c6-7232beecebfd',
          name: 'Data Communications Peripheral Data Equipment',
          children: [
            {
              id: '311b9992-1679-48a8-a1cf-27690346ac18',
              name: 'Data Communications Peripheral Data Equipment',
              tradeId: '27 24 00',
            },
            {
              id: '7da9ec1e-5c73-44b9-a6d6-825a81358a40',
              name: 'Printers',
              tradeId: '27 24 13',
            },
            {
              id: 'd8ada402-9691-4ea0-b9a4-80e50b07371f',
              name: 'Scanners',
              tradeId: '27 24 16',
            },
            {
              id: '3039cff3-7684-4830-a8cb-3ed221f824a3',
              name: 'External Drives',
              tradeId: '27 24 19',
            },
            {
              id: 'f1459798-cffc-476f-ae9e-bf8f788743d3',
              name: 'Audio-Video Devices',
              tradeId: '27 24 23',
            },
            {
              id: '6adb076c-3efd-4715-aa15-8fddd5ca9f69',
              name: 'Virtual Reality Equipment',
              tradeId: '27 24 26',
            },
            {
              id: 'd44c17c5-8bc7-41a4-8abb-54e5cfdc9a08',
              name: 'Disaster Recovery Equipment',
              tradeId: '27 24 29',
            },
          ],
          tradeId: '27 24 00',
        },
        {
          id: 'b4468ca3-9f21-41fc-8112-05ad45b415aa',
          name: 'Data Communications Software',
          children: [
            {
              id: 'c43ff9b7-413e-417e-a065-e65396d428c2',
              name: 'Data Communications Software',
              tradeId: '27 25 00',
            },
            {
              id: '91104b32-c647-4148-a1c7-ea09d6c1b686',
              name: 'Virus Protection Software',
              tradeId: '27 25 13',
            },
            {
              id: 'e559a84d-b0c3-4951-81f3-0826929d9f86',
              name: 'Application Suites',
              tradeId: '27 25 16',
            },
            {
              id: '10ca2364-cf38-4c63-b1de-465a4289dd40',
              name: 'Email Software',
              tradeId: '27 25 19',
            },
            {
              id: 'cb3adbeb-d32f-4a0e-86b2-9763e09b0efb',
              name: 'Graphics/Multimedia Software',
              tradeId: '27 25 23',
            },
            {
              id: 'ad546720-f17a-4640-bc97-259a551d1167',
              name: 'Customer Relationship Management Software',
              tradeId: '27 25 26',
            },
            {
              id: 'd2edac68-c839-44c2-a6f6-db428d46ee14',
              name: 'Operating System Software',
              tradeId: '27 25 29',
            },
            {
              id: '4ff36be8-a476-4bc9-8292-4097be4c7aef',
              name: 'Database Software',
              tradeId: '27 25 33',
            },
            {
              id: '368c6c5c-1252-406d-badb-4671faf6dfc0',
              name: 'Virtual Private Network Software',
              tradeId: '27 25 37',
            },
            {
              id: '0370021a-e3c4-4ec7-80f3-21b643ea1139',
              name: 'Internet Conferencing Software',
              tradeId: '27 25 39',
            },
          ],
          tradeId: '27 25 00',
        },
        {
          id: 'bae4df4b-89ef-46c5-b83c-12299534d417',
          name: 'Data Communications Programming and Integration Services',
          children: [
            {
              id: '187b03e1-a48e-4fca-a3c8-37cd6c273a0a',
              name: 'Data Communications Programming and Integration Services',
              tradeId: '27 26 00',
            },
            {
              id: 'fecb4366-02ac-4f76-b664-ca467d602dd7',
              name: 'Web Development',
              tradeId: '27 26 13',
            },
            {
              id: 'a95cff68-a3db-4aa5-8616-90651879e680',
              name: 'Database Development',
              tradeId: '27 26 16',
            },
            {
              id: '8519a2c3-bd7a-44fc-9092-1854d0ff3c36',
              name: 'Application Development',
              tradeId: '27 26 19',
            },
            {
              id: '8b04fbfe-a359-471e-995f-7c79a77c0ad8',
              name: 'Network Integration Requirements',
              tradeId: '27 26 23',
            },
            {
              id: '05e312d2-dfbd-4dd5-851f-3295a319ce0b',
              name: 'Data Communications Integration Requirements',
              tradeId: '27 26 26',
            },
          ],
          tradeId: '27 26 00',
        },
        {
          id: '4df178d9-5ee1-498e-925d-bd43467cea6b',
          name: 'Voice Communications',
          children: [],
          tradeId: '27 30 00',
        },
        {
          id: '040fb1af-cc9d-42b1-8a6e-4dc367a44c26',
          name: 'Voice Communications Switching and Routing Equipment',
          children: [
            {
              id: 'ce2c0459-40c1-477f-9fa6-bf56b9089f71',
              name: 'Voice Communications Switching and Routing Equipment',
              tradeId: '27 31 00',
            },
            {
              id: 'a1423afe-7864-4029-aa30-3dc749174add',
              name: 'PBX/ Key Systems',
              tradeId: '27 31 13',
            },
            {
              id: '93f6335d-571e-4679-9439-db5a05c436bd',
              name: 'Internet Protocol Voice Switches',
              tradeId: '27 31 23',
            },
          ],
          tradeId: '27 31 00',
        },
        {
          id: '47f76177-0ce2-4289-b721-cd3ded74ea9d',
          name: 'Voice Communications Terminal Equipment',
          children: [
            {
              id: 'ef8184b6-5a1d-468c-b20c-7e01ca5c21ce',
              name: 'Voice Communications Terminal Equipment',
              tradeId: '27 32 00',
            },
            {
              id: '62ce5173-95ec-4b1c-bed8-5fb0e77fcf81',
              name: 'Telephone Sets',
              tradeId: '27 32 13',
            },
            {
              id: 'b8002b13-8063-4fd7-af9c-bf838fc43034',
              name: 'Wireless Transceivers',
              tradeId: '27 32 16',
            },
            {
              id: 'd4010e44-3a7b-4531-85cf-d90d0457a42f',
              name: 'Elevator Telephones',
              tradeId: '27 32 23',
            },
            {
              id: 'da3003ee-1d26-4ace-92d6-363474a1362a',
              name: 'Ring-Down Emergency Telephones',
              tradeId: '27 32 26',
            },
            {
              id: '26e8602b-b04e-4f78-8bb8-1fc60fce413a',
              name: 'Facsimiles and Modems',
              tradeId: '27 32 29',
            },
            {
              id: 'e3ff37d8-86be-4821-a512-06186e92b6c5',
              name: 'TTY Equipment',
              tradeId: '27 32 36',
            },
            {
              id: '1adfe501-9a5c-42da-8940-685ebbe94130',
              name: 'Radio Communications Equipment',
              tradeId: '27 32 43',
            },
          ],
          tradeId: '27 32 00',
        },
        {
          id: '8322fb92-d077-4558-ac9f-b3acf0b7aaaf',
          name: 'Voice Communications Messaging',
          children: [
            {
              id: '9f83bc46-ce87-400b-906c-86accf644016',
              name: 'Voice Communications Messaging',
              tradeId: '27 33 00',
            },
            {
              id: '5777b82e-9810-4874-bcf0-c9522bdbd2b2',
              name: 'Voice Mail and Auto Attendant',
              tradeId: '27 33 16',
            },
            {
              id: 'c3472ce2-1f38-4970-8f7f-0cd2d71cf238',
              name: 'Interactive Voice Response',
              tradeId: '27 33 23',
            },
            {
              id: '3121609a-3001-4b6c-bf16-8c7d57c0f8a2',
              name: 'Facsimile Servers',
              tradeId: '27 33 26',
            },
          ],
          tradeId: '27 33 00',
        },
        {
          id: 'a0c60f0e-1c93-4ed3-9016-5ef66084bc3e',
          name: 'Call Accounting',
          children: [
            {
              id: 'cf16b55d-c445-4de0-aa10-fb4b47de5753',
              name: 'Call Accounting',
              tradeId: '27 34 00',
            },
            {
              id: '518d84c5-45f8-4667-b025-a9d84af6b281',
              name: 'Toll Fraud Equipment and Software',
              tradeId: '27 34 13',
            },
            {
              id: '119b8a8b-fa16-4aa9-b39e-bea1920af9cf',
              name: 'Telemanagement Software',
              tradeId: '27 34 16',
            },
          ],
          tradeId: '27 34 00',
        },
        {
          id: '205d7648-80b2-4c09-a11f-35c9fe022205',
          name: 'Call Management',
          children: [
            {
              id: '3b00e920-e1b1-4bc8-9471-b206b376e244',
              name: 'Call Management',
              tradeId: '27 35 00',
            },
            {
              id: '7b6496e7-64ab-4ffa-b1ed-4a746d354589',
              name: 'Digital Voice Announcers',
              tradeId: '27 35 13',
            },
            {
              id: '2aaaef5a-fa5c-4950-bee2-2ad0a90d18c6',
              name: 'Automatic Call Distributors',
              tradeId: '27 35 16',
            },
            {
              id: '80b8ce69-75b4-4d36-b757-fe6b6b0f4ff2',
              name: 'Call Status and Management Displays',
              tradeId: '27 35 19',
            },
            {
              id: '36b1fa65-2e63-4000-90e8-9f932ba08deb',
              name: 'Dedicated 911 Systems',
              tradeId: '27 35 23',
            },
          ],
          tradeId: '27 35 00',
        },
        {
          id: '1ee6e9c3-1605-4982-bef1-2365e862a67e',
          name: 'Audio-Video Communications',
          children: [],
          tradeId: '27 40 00',
        },
        {
          id: '767b35dd-4fd0-4073-8b80-c76bdbbe5358',
          name: 'Audio-Video Systems',
          children: [
            {
              id: '9bd34be8-26f8-4886-96d3-579551d26cd6',
              name: 'Audio-Video Systems',
              tradeId: '27 41 00',
            },
            {
              id: '865d7124-dba1-4efe-9c69-120f2d4ef15d',
              name: 'Architecturally Integrated Audio-Video Equipment',
              tradeId: '27 41 13',
            },
            {
              id: '38e9c669-9a6d-4036-8e69-6b0c404a8353',
              name: 'Integrated Audio-Video Systems and Equipment',
              tradeId: '27 41 16',
            },
            {
              id: 'd04798c6-1a56-4aea-bb6a-8950b02e7954',
              name: 'Integrated Audio-Video Systems and Equipment for Restaurants and Bars',
              tradeId: '27 41 16.25',
            },
            {
              id: '0339fe10-626e-49cc-a335-df8422e3dcaa',
              name: 'Integrated Audio-Video Systems and Equipment for Conference Rooms',
              tradeId: '27 41 16.28',
            },
            {
              id: 'bde2619d-76c7-443f-a0de-007dd737613f',
              name: 'Integrated Audio-Video Systems and Equipment for Board Rooms',
              tradeId: '27 41 16.29',
            },
            {
              id: '5160769d-731c-4883-9e2d-a1944b84e184',
              name: 'Integrated Audio-Video Systems and Equipment for Classrooms',
              tradeId: '27 41 16.51',
            },
            {
              id: 'ce3473c0-0eaf-4560-b7ad-03456876fa24',
              name: 'Integrated Audio-Video Systems and Equipment for Religious Facilities',
              tradeId: '27 41 16.52',
            },
            {
              id: '2597e5c7-8c5d-4029-ba5b-443151d55319',
              name: 'Integrated Audio-Video Systems and Equipment for Theaters',
              tradeId: '27 41 16.61',
            },
            {
              id: '32189cdf-8a5c-4b02-b2b5-fb13df1f652a',
              name: 'Integrated Audio-Video Systems and Equipment for Auditoriums',
              tradeId: '27 41 16.62',
            },
            {
              id: '32577a37-9e0d-4790-a7f6-22f5d286d4a2',
              name: 'Integrated Audio-Video Systems and Equipment for Stadiums and Arenas',
              tradeId: '27 41 16.63',
            },
            {
              id: '6bdacaaf-b442-48bf-b30f-ecbabfa0b212',
              name: 'Portable Audio-Video Equipment',
              tradeId: '27 41 19',
            },
            {
              id: '053e7353-7757-41da-8053-10d6bd7e3cd4',
              name: 'Audio-Video Accessories',
              tradeId: '27 41 23',
            },
            {
              id: 'ee3c7ef3-b7dd-41b6-aa75-4ceec76f7871',
              name: 'Master Antenna Television Systems',
              tradeId: '27 41 33',
            },
            {
              id: 'f8c65e02-530b-452f-857d-c6839e499289',
              name: 'Audio-Video Conferencing',
              tradeId: '27 41 43',
            },
          ],
          tradeId: '27 41 00',
        },
        {
          id: '0454fc4e-2789-4234-bc40-88a821b1cf44',
          name: 'Electronic Digital Systems',
          children: [
            {
              id: 'b8320361-9901-4a80-adc6-4ef8ba8d1b74',
              name: 'Electronic Digital Systems',
              tradeId: '27 42 00',
            },
            {
              id: '00c8f716-a967-4b00-b3ef-c165b616d784',
              name: 'Point of Sale Systems',
              tradeId: '27 42 13',
            },
            {
              id: '597ea7ff-6981-471c-a1f7-77c3fe1b9e0b',
              name: 'Transportation Information Display Systems',
              tradeId: '27 42 16',
            },
            {
              id: '2e2bc106-8ae9-498b-82dd-e3d2d9c5aa7e',
              name: 'Public Information Systems',
              tradeId: '27 42 19',
            },
          ],
          tradeId: '27 42 00',
        },
        {
          id: '1bf72088-f587-4fd2-acf7-52b8d0ec7858',
          name: 'Distributed Communications and Monitoring Systems',
          children: [],
          tradeId: '27 50 00',
        },
        {
          id: 'da73f961-b671-46a1-966e-97e4b36dcb33',
          name: 'Distributed Audio-Video Communications Systems',
          children: [
            {
              id: '6ee3a6d3-9c1d-47f5-af34-f762e8c5a655',
              name: 'Distributed Audio-Video Communications Systems',
              tradeId: '27 51 00',
            },
            {
              id: '63a52c41-2882-4f0b-b1cf-ed8a922b69f9',
              name: 'Paging Systems',
              tradeId: '27 51 13',
            },
            {
              id: '0b2d354a-f1a7-4dc7-8254-a05b0be0d33a',
              name: 'Overhead Paging Systems',
              tradeId: '27 51 13.13',
            },
            {
              id: 'ec2d368f-581f-4d49-9513-9bdf5eeb5010',
              name: 'Public Address Systems',
              tradeId: '27 51 16',
            },
            {
              id: '1cf7102d-eb99-43bf-855a-d29eb2f45d25',
              name: 'Sound Masking Systems',
              tradeId: '27 51 19',
            },
            {
              id: '5a914c8d-29b1-4346-94bb-c586ea50847d',
              name: 'Intercommunications and Program Systems',
              tradeId: '27 51 23',
            },
            {
              id: '8e687d93-9191-4867-82ee-ef20e1d4873b',
              name: 'Commercial Intercommunications and Program Systems',
              tradeId: '27 51 23.20',
            },
            {
              id: '2038368e-9735-4efc-a7f1-67368c73624b',
              name: 'Residential Intercommunications and Program Systems',
              tradeId: '27 51 23.30',
            },
            {
              id: 'aece9d03-dd92-4f18-bdc9-40b407256767',
              name: 'Educational Intercommunications and Program Systems',
              tradeId: '27 51 23.50',
            },
            {
              id: '8e44407a-33e7-4459-b466-75d4421f19e2',
              name: 'Detention Intercommunications and Program Systems',
              tradeId: '27 51 23.63',
            },
            {
              id: 'f856aea3-7735-466d-8f4c-132287cadb5a',
              name: 'Healthcare Intercommunications and Program Systems',
              tradeId: '27 51 23.70',
            },
            {
              id: 'f47c208e-ca05-47dc-9422-85128e6a1912',
              name: 'Assistive Listening Systems',
              tradeId: '27 51 26',
            },
          ],
          tradeId: '27 51 00',
        },
        {
          id: 'a090ce84-ab1b-45d6-99a1-72aa87d8c9ef',
          name: 'Healthcare Communications and Monitoring Systems',
          children: [
            {
              id: '68df387d-5c97-4317-9921-a2a9e0ef3f5d',
              name: 'Healthcare Communications and Monitoring Systems',
              tradeId: '27 52 00',
            },
            {
              id: '756d9667-58c1-441e-8638-465752cc5fb9',
              name: 'Patient Monitoring and Telemetry Systems',
              tradeId: '27 52 13',
            },
            {
              id: '50680c0e-119a-48e6-9022-7a9e61418305',
              name: 'Telemedicine Systems',
              tradeId: '27 52 16',
            },
            {
              id: '717c1a8f-5710-459c-9ce2-760900d7f8fb',
              name: 'Healthcare Imaging Systems',
              tradeId: '27 52 19',
            },
            {
              id: '60e6a6d9-1f5e-4b3b-9271-b91b66b60304',
              name: 'Nurse Call/Code Blue Systems',
              tradeId: '27 52 23',
            },
          ],
          tradeId: '27 52 00',
        },
        {
          id: 'c068b683-9e1b-40a6-8898-2a5bcc3dc936',
          name: 'Distributed Systems',
          children: [
            {
              id: 'af4bd09c-b3d4-4fd4-9dc3-37dc32a0b0e0',
              name: 'Distributed Systems',
              tradeId: '27 53 00',
            },
            {
              id: '5ef25a22-4a96-4022-a03c-cfe4d670b97a',
              name: 'Clock Systems',
              tradeId: '27 53 13',
            },
            {
              id: '0e102aad-6f36-4882-9dca-b2176d67dbbb',
              name: 'Wireless Clock Systems',
              tradeId: '27 53 13.13',
            },
            {
              id: '2fcf0946-0cda-4c6a-a81e-83c16d4738bc',
              name: 'Infrared and Radio Frequency Tracking Systems',
              tradeId: '27 53 16',
            },
            {
              id: 'a9f73b50-de6b-4a4b-8b4e-ebdf3a6bd848',
              name: 'Internal Cellular, Paging and Antenna Systems',
              tradeId: '27 53 19',
            },
          ],
          tradeId: '27 53 00',
        },
      ],
      tradeId: '27 00 00',
    },
    {
      id: '6266c9df-800b-454e-bac8-0ced1d8a8e75',
      name: 'Electronic Safety and Security',
      children: [
        {
          id: '886c0eda-38db-4645-a1b7-3b905220cdbf',
          name: 'Electronic Safety and Security',
          children: [],
          tradeId: '28 00 00',
        },
        {
          id: '1693f3b1-8d05-4d8f-8b02-7503f6a74b85',
          name: 'Operation and Maintenance of Electronic Safety and Security',
          children: [
            {
              id: 'b388069a-956b-4726-82fd-19f6f5da903a',
              name: 'Operation and Maintenance of Electronic Safety and Security',
              tradeId: '28 01 00',
            },
            {
              id: '5e3b5388-56db-4ad1-9b2d-db8e4c0631cf',
              name: 'Operation and Maintenance of Electronic Access Control and Intrusion Detection',
              tradeId: '28 01 10',
            },
            {
              id: '5b2d52f0-32e8-4ca4-991e-761c21ec55ac',
              name: 'Maintenance and Administration of Electronic Access Control and Intrusion Detection',
              tradeId: '28 01 10.51',
            },
            {
              id: 'ac6ab044-a644-4657-9dd6-50c48e489894',
              name: 'Revisions and Upgrades of Electronic Access Control and Intrusion Detection',
              tradeId: '28 01 10.71',
            },
            {
              id: '1bef536b-fa22-4350-91fd-6672d2f77b83',
              name: 'Operation and Maintenance of Electronic Surveillance',
              tradeId: '28 01 20',
            },
            {
              id: '0d858e5c-6a5b-4542-940a-057bbae912e4',
              name: 'Operation and Maintenance of Electronic Detection and Alarm',
              tradeId: '28 01 30',
            },
            {
              id: 'd15dc619-0ab4-489f-9c9e-044ddac12b3e',
              name: 'Maintenance and Administration of Electronic Detection and Alarm',
              tradeId: '28 01 30.51',
            },
            {
              id: 'e1c8bcd2-f07a-4efa-9119-3524045ed222',
              name: 'Revisions and Upgrades of Electronic Detection and Alarm',
              tradeId: '28 01 30.71',
            },
            {
              id: 'cc05648f-613d-4234-a6b4-b78bda294ea9',
              name: 'Operation and Maintenance of Electronic Monitoring and Control',
              tradeId: '28 01 40',
            },
            {
              id: '66d51ab8-591c-4bb7-a23a-e98bc25744c5',
              name: 'Maintenance and Administration of Electronic Monitoring and Control',
              tradeId: '28 01 40.51',
            },
            {
              id: '04fe1701-6158-44f2-91b3-5856c4cac2ba',
              name: 'Revisions and Upgrades of Electronic Monitoring and Control',
              tradeId: '28 01 40.71',
            },
          ],
          tradeId: '28 01 00',
        },
        {
          id: '8ddf41a7-a59e-4f33-974f-f85a8134e8a3',
          name: 'Common Work Results for Electronic Safety and Security',
          children: [
            {
              id: '0721c3dd-2460-4f9b-852a-ad0ba5869817',
              name: 'Common Work Results for Electronic Safety and Security',
              tradeId: '28 05 00',
            },
            {
              id: 'a8d4cc95-722c-4019-801a-a39e94d18065',
              name: 'Conductors and Cables for Electronic Safety and Security',
              tradeId: '28 05 13',
            },
            {
              id: 'ab93be90-c0d0-4ce2-9608-3bd3ea98b46c',
              name: 'CCTV Communications Conductors and Cables',
              tradeId: '28 05 13.13',
            },
            {
              id: 'b09a551a-72cf-4093-9c09-9e64af31e75b',
              name: 'Access Control Communications Conductors and Cables',
              tradeId: '28 05 13.16',
            },
            {
              id: '96c786e1-c6d3-47b9-84c8-84afe5699acf',
              name: 'Intrusion Detection Communications Conductors and Cables',
              tradeId: '28 05 13.19',
            },
            {
              id: 'ef5f7b3f-c354-442b-9822-d410371355df',
              name: 'Fire Alarm Communications Conductors and Cables',
              tradeId: '28 05 13.23',
            },
            {
              id: '2e056004-3b00-4df2-9f9d-a3db7dee06eb',
              name: 'Grounding and Bonding for Electronic Safety and Security',
              tradeId: '28 05 26',
            },
            {
              id: '3dfe493b-33ed-461f-a80b-1eec4c546bad',
              name: 'Pathways for Electronic Safety and Security',
              tradeId: '28 05 28',
            },
            {
              id: '26d5da54-da3f-40a2-9efa-996a357717b9',
              name: 'Hangers and Supports for Electronic Safety and Security',
              tradeId: '28 05 28.29',
            },
            {
              id: '90935df0-22ca-4c59-805f-0abb15b6e981',
              name: 'Conduits and Backboxes for Electronic Safety and Security',
              tradeId: '28 05 28.33',
            },
            {
              id: '7b88075e-77f2-41fa-94fe-a89b85fbc996',
              name: 'Cable Trays for Electronic Safety and Security',
              tradeId: '28 05 28.36',
            },
            {
              id: '1ebbc5eb-2e9b-4fba-9b13-2daeefbdedfe',
              name: 'Surface Raceways for Electronic Safety and Security',
              tradeId: '28 05 28.39',
            },
            {
              id: 'ad02ab49-3d5d-45fb-849d-826d3bc320e9',
              name: 'Vibration and Seismic Controls for Electronic Safety and Security',
              tradeId: '28 05 48',
            },
            {
              id: 'd049d47c-7749-4ad3-a067-ba2fd507ada5',
              name: 'Identification for Electronic Safety and Security',
              tradeId: '28 05 53',
            },
          ],
          tradeId: '28 05 00',
        },
        {
          id: '54dcc1aa-8a21-4e36-b78f-b2ea1d4b6baa',
          name: 'Schedules for Electronic Safety and Security',
          children: [
            {
              id: '90989c1b-af25-492d-9a03-8b3a2fc1f107',
              name: 'Schedules for Electronic Safety and Security',
              tradeId: '28 06 00',
            },
            {
              id: '9a755af8-3202-4798-8d85-875794046e1e',
              name: 'Schedules for Electronic Access Control and Intrusion Detection',
              tradeId: '28 06 10',
            },
            {
              id: 'eb3a5881-5d87-43a8-a537-c6bb844a507a',
              name: 'Schedules for Electronic Surveillance',
              tradeId: '28 06 20',
            },
            {
              id: '36c9b247-2268-45d3-9da8-ef7e45cfe1d1',
              name: 'Schedules for Electronic Detection and Alarm',
              tradeId: '28 06 30',
            },
            {
              id: '6f910d19-40fa-4d6c-8f7e-1dcf9fb17881',
              name: 'Schedules for Electronic Monitoring and Control',
              tradeId: '28 06 40',
            },
          ],
          tradeId: '28 06 00',
        },
        {
          id: 'ae5c914f-ef46-45fa-83c7-13e49e5622e0',
          name: 'Commissioning of Electronic Safety and Security',
          children: [],
          tradeId: '28 08 00',
        },
        {
          id: '9f083686-8645-4dd8-8e94-4c8f95e21299',
          name: 'Electronic Access Control and Intrusion Detection',
          children: [],
          tradeId: '28 10 00',
        },
        {
          id: 'b7739f21-c220-4f16-ac3b-46c15144f1df',
          name: 'Access Control',
          children: [
            {
              id: '3f118ce1-3de4-4863-9c20-d1fb9cbd4891',
              name: 'Access Control',
              tradeId: '28 13 00',
            },
            {
              id: '6f397065-fe89-40ed-ad5e-c67451970388',
              name: 'Access Control Global Applications',
              tradeId: '28 13 13',
            },
            {
              id: '153d79c6-e373-45e0-9ab2-5be2f03fa630',
              name: 'Access Control Systems and Database Management',
              tradeId: '28 13 16',
            },
            {
              id: '5b676b81-dee6-4822-a737-9a8e6fe0b039',
              name: 'Access Control Systems Infrastructure',
              tradeId: '28 13 19',
            },
            {
              id: 'a8065696-4ecf-4d62-b34a-e2ac51a83bb7',
              name: 'Access Control Remote Devices',
              tradeId: '28 13 26',
            },
            {
              id: '73945e9e-0683-43ba-b48b-994a40d5b304',
              name: 'Wireless Access Control Devices',
              tradeId: '28 13 26.11',
            },
            {
              id: '51f8631a-976c-45be-835d-d4546ee42f0b',
              name: 'Access Control Interfaces',
              tradeId: '28 13 33',
            },
            {
              id: 'f0cb37d0-3ea4-4d46-af37-0fa3534c65d0',
              name: 'Access Control Interfaces to Access Control Hardware',
              tradeId: '28 13 33.16',
            },
            {
              id: '1f22c228-d1a7-4e30-aacb-ce04e97e49d0',
              name: 'Access Control Interfaces to Intrusion Detection',
              tradeId: '28 13 33.26',
            },
            {
              id: '2d1f2336-b1ac-481d-83d0-f148997aecb7',
              name: 'Access Control Interfaces to Video Surveillance',
              tradeId: '28 13 33.33',
            },
            {
              id: 'd7970f99-3ee5-4696-a041-4ee8188d18b3',
              name: 'Access Control Interfaces to Fire Alarm',
              tradeId: '28 13 33.36',
            },
            {
              id: '65ce70ee-3e3e-40ba-8f9d-cf348c60e7ce',
              name: 'Access Control Identification Management Systems',
              tradeId: '28 13 43',
            },
            {
              id: '5126c9b8-a48f-4f10-805c-ecfe9d07847c',
              name: 'Security Access Detection',
              tradeId: '28 13 53',
            },
            {
              id: '1105d4e4-4d63-4c27-8b09-094f34d5633a',
              name: 'Security Access Metal Detectors',
              tradeId: '28 13 53.13',
            },
            {
              id: '37d9f32e-d085-4e60-b7fc-817fb0e63fcc',
              name: 'Security Access X-Ray Equipment',
              tradeId: '28 13 53.16',
            },
            {
              id: '5ac3202b-e4d9-4a69-9d84-a7a1b59f838d',
              name: 'Security Access Explosive Detection Equipment',
              tradeId: '28 13 53.23',
            },
            {
              id: 'e2d7eb46-b37f-48a0-9a48-ac6c8462376a',
              name: 'Security Access Sniffing Equipment',
              tradeId: '28 13 53.29',
            },
            {
              id: '8488be3e-79a4-45c1-a975-a58e0c15423d',
              name: 'Access Control Vehicle Identification System',
              tradeId: '28 13 63',
            },
          ],
          tradeId: '28 13 00',
        },
        {
          id: '16930135-0a38-44ea-affa-6bff0906d0b8',
          name: 'Intrusion Detection',
          children: [
            {
              id: 'ba1c415d-af2c-4a1d-8389-52f4e5544998',
              name: 'Intrusion Detection',
              tradeId: '28 16 00',
            },
            {
              id: '6c0a34c8-e436-48fb-87fc-9df70000e025',
              name: 'Intrusion Detection Control, GUI and Logic Systems',
              tradeId: '28 16 13',
            },
            {
              id: 'bb5402c5-9a2a-4f83-a4b0-2e6670be4692',
              name: 'Intrusion Detection Systems Infrastructure',
              tradeId: '28 16 16',
            },
            {
              id: '80cb77aa-2f71-4219-9165-822b2f3f9375',
              name: 'Intrusion Detection Remote Devices and Sensors',
              tradeId: '28 16 19',
            },
            {
              id: 'a8ce1718-b5ab-4834-936f-68126c9229bb',
              name: 'Intrusion Detection Interfaces',
              tradeId: '28 16 33',
            },
            {
              id: 'c3cbb680-d530-48d2-a214-0e15ea57768a',
              name: 'Intrusion Detection Interfaces to Remote Monitoring',
              tradeId: '28 16 33.13',
            },
            {
              id: '4561fcb9-8aff-4f93-a1bf-716fe55b777b',
              name: 'Intrusion Detection Interfaces to Access Control Hardware',
              tradeId: '28 16 33.16',
            },
            {
              id: '4c26fdad-fad1-479a-baaf-bf9364080696',
              name: 'Intrusion Detection Interfaces to Access Control System',
              tradeId: '28 16 33.23',
            },
            {
              id: '25743017-0928-4605-aa0a-5d829bd92afd',
              name: 'Intrusion Detection Interfaces to Video Surveillance',
              tradeId: '28 16 33.33',
            },
            {
              id: '2326d625-ae42-4bf0-9c9a-5dce9376271c',
              name: 'Intrusion Detection Interfaces to Fire Alarm',
              tradeId: '28 16 33.36',
            },
            {
              id: '57c518cc-de18-4d2f-a869-e934ead681e5',
              name: 'Perimeter Security Systems',
              tradeId: '28 16 43',
            },
          ],
          tradeId: '28 16 00',
        },
        {
          id: 'a303716c-8d2f-4d56-9878-94b39586cc5c',
          name: 'Electronic Surveillance',
          children: [],
          tradeId: '28 20 00',
        },
        {
          id: '4b4746da-9604-4d12-9dc8-98e8c2e6a0e1',
          name: 'Video Surveillance',
          children: [
            {
              id: '6fe203f7-6aa9-45bc-ad1a-4511981df58d',
              name: 'Video Surveillance',
              tradeId: '28 23 00',
            },
            {
              id: 'f4a3bc01-94d4-4953-b03f-e09b338b144e',
              name: 'Video Surveillance Control and Management Systems',
              tradeId: '28 23 13',
            },
            {
              id: '9a312024-e2dd-4d6e-9224-62b33132bbbc',
              name: 'Video Surveillance Monitoring and Supervisory Interfaces',
              tradeId: '28 23 16',
            },
            {
              id: 'b1cccfde-f42a-4bbc-82f4-28f615a9c584',
              name: 'Digital Video Recorders and Analog Recording Devices',
              tradeId: '28 23 19',
            },
            {
              id: 'e558d3e2-1a3c-493a-9b1a-a58e16cfcf1f',
              name: 'Video Surveillance Systems Infrastructure',
              tradeId: '28 23 23',
            },
            {
              id: '83055639-85b6-40d1-814d-fbb78959b15f',
              name: 'Video Surveillance Remote Positioning Equipment',
              tradeId: '28 23 26',
            },
            {
              id: 'd8109b1b-721c-4d85-b70f-d62afa41a838',
              name: 'Video Surveillance Remote Devices and Sensors',
              tradeId: '28 23 29',
            },
          ],
          tradeId: '28 23 00',
        },
        {
          id: '4128e798-05bd-43a4-b1d8-9a8e16e2f3d5',
          name: 'Electronic Personal Protection Systems',
          children: [
            {
              id: 'c800bd05-93b3-48f0-862c-1364ab7bbb60',
              name: 'Electronic Personal Protection Systems',
              tradeId: '28 26 00',
            },
            {
              id: '51ff3eaf-cd44-4d68-bed7-baf145f956b0',
              name: 'Electronic Personal Safety Detection Systems',
              tradeId: '28 26 13',
            },
            {
              id: '77009f3a-d2b0-46bd-af85-a870b5dc585c',
              name: 'Electronic Personal Safety Alarm Annunciation and Control Systems',
              tradeId: '28 26 16',
            },
            {
              id: '6422872f-6193-4553-9cf7-71b30f56d2d6',
              name: 'Electronic Personal Safety Interfaces to Remote Monitoring',
              tradeId: '28 26 19',
            },
            {
              id: '9eccc1ca-70c4-4850-91bd-42b499092921',
              name: 'Electronic Personal Safety Emergency Aid Devices',
              tradeId: '28 26 23',
            },
          ],
          tradeId: '28 26 00',
        },
        {
          id: 'fce85323-fb23-4de8-99c8-c1d925e616cb',
          name: 'Electronic Detection and Alarm',
          children: [],
          tradeId: '28 30 00',
        },
        {
          id: '89467520-7bae-437a-adba-2ee33d6fc38b',
          name: 'Fire Detection and Alarm',
          children: [
            {
              id: 'ce37dfc9-7195-462e-8066-c5e2e5da4f95',
              name: 'Fire Detection and Alarm',
              tradeId: '28 31 00',
            },
            {
              id: '60d967cf-3291-45f4-9a6f-c3c2a1f08d8f',
              name: 'Digital, Addressable Fire-Alarm Systems',
              tradeId: '28 31 11',
            },
            {
              id: '0d93fac5-8c1b-4fce-ab86-4f6e6af1e4ac',
              name: 'Zoned (DC Loop) Fire-Alarm Systems',
              tradeId: '28 31 12',
            },
            {
              id: 'ee9be51b-2f5a-4bfa-b9f9-0697fc7ba351',
              name: 'Fire Detection and Alarm Control, GUI and Logic Systems',
              tradeId: '28 31 13',
            },
            {
              id: 'd2ea8933-cf99-4a4c-9f8b-a8066670e05e',
              name: 'Fire Detection and Alarm Annunciation Panels and Fire Stations',
              tradeId: '28 31 23',
            },
            {
              id: '48ea9589-1631-4c4c-ad4c-fa6ac101f037',
              name: 'Fire Detection and Alarm Interfaces',
              tradeId: '28 31 33',
            },
            {
              id: '01f6ea2d-2ea0-49ad-af52-16df7bd1cbe5',
              name: 'Fire Detection and Alarm Interfaces to Remote Monitoring',
              tradeId: '28 31 33.13',
            },
            {
              id: 'f0742a84-80c3-4f10-a511-1ee6ef1eb6bf',
              name: 'Fire Detection and Alarm Interfaces to Access Control Hardware',
              tradeId: '28 31 33.16',
            },
            {
              id: '7ee4e42d-664a-4a10-96f6-c4761175b439',
              name: 'Fire Detection and Alarm Interfaces to Access Control System',
              tradeId: '28 31 33.23',
            },
            {
              id: 'e455ea3e-5c02-48a1-8fc8-988339c7e15b',
              name: 'Fire Detection and Alarm Interfaces to Intrusion Detection',
              tradeId: '28 31 33.26',
            },
            {
              id: 'be5d07da-1b38-4386-9388-ba044f85b2df',
              name: 'Fire Detection and Alarm Interfaces to Video Surveillance',
              tradeId: '28 31 33.33',
            },
            {
              id: 'f4e585da-6acc-42f9-b64a-878989404df8',
              name: 'Fire Detection and Alarm Interfaces to Elevator Control',
              tradeId: '28 31 33.43',
            },
            {
              id: 'c9924782-16c2-4947-9326-a92821606af4',
              name: 'Fire Detection Sensors',
              tradeId: '28 31 43',
            },
            {
              id: '149b98a2-c3ff-4c87-b885-99d1bc8115c6',
              name: 'Smoke Detection Sensors',
              tradeId: '28 31 46',
            },
            {
              id: '8b6a01fd-36a2-442b-9628-f996145439d5',
              name: 'Carbon-Monoxide Detection Sensors',
              tradeId: '28 31 49',
            },
            {
              id: '2619e7a4-202e-443d-8932-2bee9ddf4a5e',
              name: 'Fire Alarm Initiating Devices',
              tradeId: '28 31 53',
            },
            {
              id: '54b2b801-f14c-4ec1-a441-73fb18dc3ed1',
              name: 'Fire Alarm Pull Stations',
              tradeId: '28 31 53.13',
            },
            {
              id: '83b99e0c-d51f-4adc-886e-1ee9089996e9',
              name: 'Fire Alarm Level Detectors Switches',
              tradeId: '28 31 53.23',
            },
            {
              id: '4f38829e-b3fa-4d2d-9d29-0084b9d2b036',
              name: 'Fire Alarm Flow Switches',
              tradeId: '28 31 53.33',
            },
            {
              id: '2a9e3607-30d6-4ce3-a23a-784a1f812ace',
              name: 'Fire Alarm Pressure Sensors',
              tradeId: '28 31 53.43',
            },
            {
              id: '1281ac94-8d91-40a1-94bc-56a0d872984f',
              name: 'Fire Alarm Integrated Audio Visual Evacuation Systems',
              tradeId: '28 31 63',
            },
            {
              id: '3bb613c3-6efa-49c9-91f8-7662c619c408',
              name: 'Fire Alarm Horns and Strobes',
              tradeId: '28 31 63.13',
            },
          ],
          tradeId: '28 31 00',
        },
        {
          id: '70df2c97-1afb-4505-a068-64cecd5c5f6f',
          name: 'Radiation Detection and Alarm',
          children: [
            {
              id: '54a8b9ea-ef41-4a12-b058-95ed77948055',
              name: 'Radiation Detection and Alarm',
              tradeId: '28 32 00',
            },
            {
              id: '82e464b0-810a-4728-847a-2d905e707160',
              name: 'Radiation Detection and Alarm Control, GUI and Logic Systems',
              tradeId: '28 32 13',
            },
            {
              id: '893e0e4f-ee95-4c6c-8c49-8e0fc7b65987',
              name: 'Radiation Detection and Alarm Integrated Audio Evacuation Systems',
              tradeId: '28 32 23',
            },
            {
              id: '383a18f4-1520-4fde-a964-9911ce7ef8a5',
              name: 'Radiation Detection Sensors',
              tradeId: '28 32 33',
            },
            {
              id: '8106476c-bd64-4b6f-ac96-bf827def43e5',
              name: 'Radiation Dosimeters',
              tradeId: '28 32 43',
            },
          ],
          tradeId: '28 32 00',
        },
        {
          id: '8baafbc1-c9f9-4dab-8901-37e40b1707c7',
          name: 'Gas Detection and Alarm',
          children: [
            {
              id: '99228b2b-99d8-4411-a077-b760040e561a',
              name: 'Gas Detection and Alarm',
              tradeId: '28 33 00',
            },
            {
              id: '1465f5fd-24b2-4dd5-97ac-a42ec3650d17',
              name: 'Gas Detection and Alarm Control, GUI and Logic Systems',
              tradeId: '28 33 13',
            },
            {
              id: '5777a8e7-357c-4850-a4fa-dd16612b3ab8',
              name: 'Gas Detection and Alarm Integrated Audio Evacuation Systems',
              tradeId: '28 33 23',
            },
            {
              id: 'b06cad5d-9ec3-4148-9797-68ce9dc08e52',
              name: 'Gas Detection Sensors',
              tradeId: '28 33 33',
            },
          ],
          tradeId: '28 33 00',
        },
        {
          id: '20613331-e031-40dd-b12c-a9d89b7ef0b1',
          name: 'Fuel-Oil Detection and Alarm',
          children: [
            {
              id: 'c8dd618f-92d0-4abc-b5ed-18d7acd11f57',
              name: 'Fuel-Oil Detection and Alarm',
              tradeId: '28 34 00',
            },
            {
              id: '7c03fea9-8ab5-4d2e-b279-dafce3ea0dbf',
              name: 'Fuel-Oil Detection and Alarm Control, GUI and Logic Systems',
              tradeId: '28 34 13',
            },
            {
              id: 'eb5378a8-5478-4912-b25f-7e44c7aa1f51',
              name: 'Fuel-Oil Detection and Alarm Integrated Audio Evacuation Systems',
              tradeId: '28 34 23',
            },
            {
              id: '2512d0e1-aead-48b0-8a28-7ad77dc38f1a',
              name: 'Fuel-Oil Detection Sensors',
              tradeId: '28 34 33',
            },
          ],
          tradeId: '28 34 00',
        },
        {
          id: 'eeb5b767-67a2-4c91-91c0-df19e7376347',
          name: 'Refrigerant Detection and Alarm',
          children: [
            {
              id: 'f3ecbcd5-5251-4150-b5e6-fdd84d455301',
              name: 'Refrigerant Detection and Alarm',
              tradeId: '28 35 00',
            },
            {
              id: 'c6836217-ee97-4b92-98da-b124c094ff02',
              name: 'Refrigerant Detection and Alarm Control, GUI and Logic Systems',
              tradeId: '28 35 13',
            },
            {
              id: '61165efd-0caa-4889-91cf-b6d6de54f776',
              name: 'Refrigerant Detection and Alarm Integrated Audio Evacuation Systems',
              tradeId: '28 35 23',
            },
            {
              id: '9fe787d1-16d9-4dfa-9738-ad0c9a1e2fe3',
              name: 'Refrigerant Detection Sensors',
              tradeId: '28 35 33',
            },
          ],
          tradeId: '28 35 00',
        },
        {
          id: 'a8d8ed7a-16ff-4c46-90d4-1755c2e856b8',
          name: 'Water Detection and Alarm',
          children: [
            {
              id: 'f0a782c8-8918-4388-a43b-e17273fa3306',
              name: 'Water Detection and Alarm',
              tradeId: '28 36 00',
            },
            {
              id: '1ef710ea-73ed-478e-ba07-c9323c0acd0c',
              name: 'Water Detection and Alarm Control, GUI and Logic Systems',
              tradeId: '28 36 13',
            },
            {
              id: 'be579fc4-cafa-4267-9ee3-96d3f5587126',
              name: 'Water Detection Sensors',
              tradeId: '28 36 33',
            },
            {
              id: '434ea7b0-6aa7-492d-b66b-d75b73861006',
              name: 'Building Envelope Water Detection and Alarm',
              tradeId: '28 36 43',
            },
          ],
          tradeId: '28 36 00',
        },
        {
          id: 'fcb3c6ab-49ba-4f0b-97e5-89d464bc1fc1',
          name: 'Mass Notification Systems',
          children: [],
          tradeId: '28 39 00',
        },
        {
          id: '2d50f681-4867-48da-aaeb-f375c3eec980',
          name: 'Electronic Monitoring and Control',
          children: [],
          tradeId: '28 40 00',
        },
        {
          id: '8d3aded0-e51b-4aa3-925b-25e4458000c9',
          name: 'Electronic Structural Monitoring Systems',
          children: [],
          tradeId: '28 41 00',
        },
        {
          id: 'd7578c0d-b30c-41bc-83d4-157914bc7902',
          name: 'Electronic Detention Monitoring and Control Systems',
          children: [
            {
              id: '7ef1337c-1e66-4034-9b16-ccfeb4bb5912',
              name: 'Electronic Detention Monitoring and Control Systems',
              tradeId: '28 46 00',
            },
            {
              id: '58056463-aa7e-4ead-8ab7-639a7e3e2959',
              name: 'Hard-Wired Detention Monitoring and Control Systems',
              tradeId: '28 46 13',
            },
            {
              id: 'ba3d36ec-6139-4379-8101-da0535642587',
              name: 'Relay-Logic Detention Monitoring and Control Systems',
              tradeId: '28 46 16',
            },
            {
              id: '5bd430fb-1ffe-45f8-87cf-3a2bc3debc07',
              name: 'PLC Electronic Detention Monitoring and Control Systems',
              tradeId: '28 46 19',
            },
            {
              id: '119dfe99-a04d-4f08-8aa5-e0b13c31abd5',
              name: 'Computer-Based Detention Monitoring and Control Systems',
              tradeId: '28 46 23',
            },
            {
              id: '0dee8f5d-92c3-4468-b6ab-913f95404bac',
              name: 'Discreet-Logic Detention Monitoring and Control Systems',
              tradeId: '28 46 26',
            },
            {
              id: 'c4d732fb-4733-4f8d-b59c-14c12f3db84e',
              name: 'Discreet-Distributed Intelligence Detention Monitoring and Control Systems',
              tradeId: '28 46 29',
            },
          ],
          tradeId: '28 46 00',
        },
      ],
      tradeId: '28 00 00',
    },
    {
      id: '8e7097ea-b4c4-41de-b810-56c790cb2d0a',
      name: 'Earthwork',
      children: [
        {
          id: '6dbda044-2684-4a85-adb3-dc8f7f7ba36c',
          name: 'Earthwork',
          children: [],
          tradeId: '31 00 00',
        },
        {
          id: '0de93c8f-1bf1-4ae4-8b6b-2c0cee7a6124',
          name: 'Maintenance of Earthwork',
          children: [
            {
              id: '4ee28dc6-694f-4193-8188-268d4b407e33',
              name: 'Maintenance of Earthwork',
              tradeId: '31 01 00',
            },
            {
              id: '5a557945-66fa-4662-a8bc-be68177cfada',
              name: 'Maintenance of Clearing',
              tradeId: '31 01 10',
            },
            {
              id: 'db05adad-b78c-49c1-a929-422556fc401f',
              name: 'Maintenance of Earth Moving',
              tradeId: '31 01 20',
            },
            {
              id: '1e99ad95-229a-4194-b66c-a99679597369',
              name: 'Maintenance of Shoring and Underpinning',
              tradeId: '31 01 40',
            },
            {
              id: '9800db88-887a-4378-96a6-80ddae1bc4bd',
              name: 'Maintenance of Excavation Support and Protection',
              tradeId: '31 01 50',
            },
            {
              id: '3a415cf0-f6f5-43aa-89a6-ecae04d94b56',
              name: 'Maintenance of Special Foundations and Load Bearing Elements',
              tradeId: '31 01 60',
            },
            {
              id: 'ae6d4424-49fe-43db-9529-606f806a4e69',
              name: 'Maintenance of Driven Piles',
              tradeId: '31 01 62',
            },
            {
              id: 'b661f725-f03f-4538-aa7d-b4a8c9278c67',
              name: 'Driven Pile Repairs',
              tradeId: '31 01 62.61',
            },
            {
              id: '2656c234-8802-46d0-9a9d-80cfd9da412b',
              name: 'Maintenance of Bored and Augered Piles',
              tradeId: '31 01 63',
            },
            {
              id: 'df160ad2-18b0-4fe8-bd06-8d6fb57e5660',
              name: 'Bored and Augered Pile Repairs',
              tradeId: '31 01 63.61',
            },
            {
              id: '9aac35b8-4f13-4b4d-ae4a-9100119332ac',
              name: 'Maintenance of Tunneling and Mining',
              tradeId: '31 01 70',
            },
            {
              id: '333da597-3be8-4a19-9142-6c2a7cf33793',
              name: 'Tunnel Leak Repairs',
              tradeId: '31 01 70.61',
            },
          ],
          tradeId: '31 01 00',
        },
        {
          id: '61339280-92c0-4bbb-95fa-bf4d767a2169',
          name: 'Common Work Results for Earthwork',
          children: [
            {
              id: '5a3f5e33-e778-44c3-b0cb-14ad748eb1a9',
              name: 'Common Work Results for Earthwork',
              tradeId: '31 05 00',
            },
            {
              id: 'b459c77a-8a86-4079-a779-369e6f23dbd5',
              name: 'Soils for Earthwork',
              tradeId: '31 05 13',
            },
            {
              id: '1665b95f-10f9-4d97-b2cc-ce225b5dc83a',
              name: 'Aggregates for Earthwork',
              tradeId: '31 05 16',
            },
            {
              id: '51699dab-8f45-40b2-80d0-9584e9ed0a45',
              name: 'Geosynthetics for Earthwork',
              tradeId: '31 05 19',
            },
            {
              id: '8e85e691-1ee5-42c8-9d38-b95685b6dc4b',
              name: 'Geotextiles for Earthwork',
              tradeId: '31 05 19.13',
            },
            {
              id: '315c192e-94c8-470c-8dda-3432e208ceab',
              name: 'Geomembranes for Earthwork',
              tradeId: '31 05 19.16',
            },
            {
              id: '4e6b0389-15ad-4d3d-a2cd-988fc4ae0d10',
              name: 'Geogrids for Earthwork',
              tradeId: '31 05 19.19',
            },
            {
              id: '6e6477a4-c539-4fb7-addf-f84ee4976f44',
              name: 'Geosynthetic Clay Liners',
              tradeId: '31 05 19.23',
            },
            {
              id: 'b53bd5ea-343b-42cb-b50c-1e767d73e793',
              name: 'Geocomposites',
              tradeId: '31 05 19.26',
            },
            {
              id: 'cfc7befb-c1d0-42d6-a60b-916d9afea758',
              name: 'Geonets',
              tradeId: '31 05 19.29',
            },
            {
              id: 'cc32ba63-2fa5-4932-a661-66063d820e6c',
              name: 'Cement and Concrete for Earthwork',
              tradeId: '31 05 23',
            },
          ],
          tradeId: '31 05 00',
        },
        {
          id: 'fde806a7-9714-4b93-8b8f-0de41bb7877c',
          name: 'Schedules for Earthwork',
          children: [
            {
              id: '8acdd798-c678-450f-bef3-5aa3a9f22fbb',
              name: 'Schedules for Earthwork',
              tradeId: '31 06 00',
            },
            {
              id: 'bdf14554-4521-481d-b1f0-7064d47cbc56',
              name: 'Schedules for Clearing',
              tradeId: '31 06 10',
            },
            {
              id: '98ebf4a6-3736-4e11-bb65-ef9ac3c268dd',
              name: 'Schedules for Earth Moving',
              tradeId: '31 06 20',
            },
            {
              id: 'b9c6390b-766a-4441-9ad6-e8ec6ba6375f',
              name: 'Trench Dimension Schedule',
              tradeId: '31 06 20.13',
            },
            {
              id: '567be36e-2ef7-4c2d-b36e-03d8188d6925',
              name: 'Backfill Material Schedule',
              tradeId: '31 06 20.16',
            },
            {
              id: '7c393694-ddaf-44c0-a99a-5d8b974e1cc1',
              name: 'Schedules for Shoring and Underpinning',
              tradeId: '31 06 40',
            },
            {
              id: 'a288c789-4d82-4417-93a2-a796add8a981',
              name: 'Schedules for Excavation Support and Protection',
              tradeId: '31 06 50',
            },
            {
              id: '6604d573-7dfd-4488-89ad-5783b7422005',
              name: 'Schedules for Special Foundations and Load Bearing Elements',
              tradeId: '31 06 60',
            },
            {
              id: 'cbb73994-1ff1-4566-862e-82b41e196dc9',
              name: 'Driven Pile Schedule',
              tradeId: '31 06 60.13',
            },
            {
              id: '165e55c5-ddc3-46db-959f-238478d1addf',
              name: 'Caisson Schedule',
              tradeId: '31 06 60.16',
            },
            {
              id: '1ffcd55e-1533-4f50-beda-0c0df84ffff9',
              name: 'Schedules for Tunneling and Mining',
              tradeId: '31 06 70',
            },
          ],
          tradeId: '31 06 00',
        },
        {
          id: 'eebe26d8-4ab4-444e-8474-692c60249295',
          name: 'Commissioning of Earthwork',
          children: [
            {
              id: 'b810d9a8-0d0e-40c5-be1a-e8fdf91a01f4',
              name: 'Commissioning of Earthwork',
              tradeId: '31 08 00',
            },
            {
              id: 'c38e5a64-ecad-4e74-8fea-505ab9ec5db0',
              name: 'Pile Load Testing',
              tradeId: '31 08 13',
            },
            {
              id: '1b2b0b63-328b-41d9-89f9-def06f731a0c',
              name: 'Dynamic Pile Load Testing',
              tradeId: '31 08 13.13',
            },
            {
              id: 'e8207f06-cb5c-413f-a04a-07ef58d73f00',
              name: 'Static Pile Load Testing',
              tradeId: '31 08 13.16',
            },
          ],
          tradeId: '31 08 00',
        },
        {
          id: '75455c2e-c099-4bf6-bd66-328e0261ab3d',
          name: 'Geotechnical Instrumentation and Monitoring of Earthwork',
          children: [
            {
              id: '166e1185-6d81-4532-83ea-59aa86b1a65b',
              name: 'Geotechnical Instrumentation and Monitoring of Earthwork',
              tradeId: '31 09 00',
            },
            {
              id: '31a4eb2c-cae7-4fb9-a77c-376b67e4e34b',
              name: 'Geotechnical Instrumentation and Monitoring',
              tradeId: '31 09 13',
            },
            {
              id: '36871399-30a8-4e37-bbc9-fe40b7c2c729',
              name: 'Groundwater Monitoring During Construction',
              tradeId: '31 09 13.13',
            },
            {
              id: '1d44f70f-933a-4749-a48b-a18e174c62c9',
              name: 'Foundation Performance Instrumentation',
              tradeId: '31 09 16',
            },
            {
              id: '5a8ae6e7-9103-43e7-aea9-1b4c386ab3a0',
              name: 'Bored and Augered Pile Load Tests',
              tradeId: '31 09 16.26',
            },
          ],
          tradeId: '31 09 00',
        },
        {
          id: 'f1c1a0fb-fa3a-4c27-9d9f-d46acf3e8bfa',
          name: 'Site Clearing',
          children: [],
          tradeId: '31 10 00',
        },
        {
          id: '2e817b96-8b44-4720-b182-1bb42e5a0371',
          name: 'Clearing and Grubbing',
          children: [],
          tradeId: '31 11 00',
        },
        {
          id: '4f6ea390-e534-4c97-bd12-8ac4ae665fb9',
          name: 'Selective Clearing',
          children: [],
          tradeId: '31 12 00',
        },
        {
          id: 'c22676ae-ed1b-4d53-bdd5-5c091423d8f9',
          name: 'Selective Tree and Shrub Removal and Trimming',
          children: [
            {
              id: '31b09049-3ad1-4184-9182-f8a5a11d2ddb',
              name: 'Selective Tree and Shrub Removal and Trimming',
              tradeId: '31 13 00',
            },
            {
              id: '9a687573-7ae9-4e7c-bd26-868852b3af02',
              name: 'Selective Tree and Shrub Removal',
              tradeId: '31 13 13',
            },
            {
              id: 'cd3c4282-55d2-452b-adba-d8224c1af04e',
              name: 'Selective Tree and Shrub Trimming',
              tradeId: '31 13 16',
            },
          ],
          tradeId: '31 13 00',
        },
        {
          id: 'e51af662-b7c0-4988-8c9f-0ead2e0acb2c',
          name: 'Earth Stripping and Stockpiling',
          children: [
            {
              id: '1f6606a3-634b-44ef-8b03-da1a74d1b869',
              name: 'Earth Stripping and Stockpiling',
              tradeId: '31 14 00',
            },
            {
              id: 'e7078269-b255-4ca2-95bf-aff236a398e8',
              name: 'Soil Stripping and Stockpiling',
              tradeId: '31 14 13',
            },
            {
              id: '0f98819f-e875-4f82-8c01-f679ccd77bef',
              name: 'Soil Stripping',
              tradeId: '31 14 13.13',
            },
            {
              id: 'b8368bce-36ca-46c7-b1f7-3d607d25dc0c',
              name: 'Soil Stockpiling',
              tradeId: '31 14 13.16',
            },
            {
              id: 'b4410a69-3062-4ce7-b7a2-13110aabbdba',
              name: 'Topsoil Stripping and Stockpiling',
              tradeId: '31 14 13.23',
            },
            {
              id: '49b50452-177d-40c0-bab4-7a736e51c71e',
              name: 'Sod Stripping and Stockpiling',
              tradeId: '31 14 16',
            },
            {
              id: '8bec9c71-900f-4e25-b32c-f5362460ea1a',
              name: 'Sod Stripping',
              tradeId: '31 14 16.13',
            },
            {
              id: 'a3d2bf92-64cc-4553-8f72-1fd38229d15d',
              name: 'Sod Stockpiling',
              tradeId: '31 14 16.16',
            },
          ],
          tradeId: '31 14 00',
        },
        {
          id: '2af1eaee-042f-40fa-8459-a4d983e9c41a',
          name: 'Earth Moving',
          children: [],
          tradeId: '31 20 00',
        },
        {
          id: '10229723-56a9-4b05-bcd8-8fb5e59dc4c8',
          name: 'Off-Gassing Mitigation',
          children: [
            {
              id: '5bd895d7-29d2-4df4-ae42-046b348d4fa9',
              name: 'Off-Gassing Mitigation',
              tradeId: '31 21 00',
            },
            {
              id: 'bc988484-6945-4241-82a7-19d780373e0f',
              name: 'Radon Mitigation',
              tradeId: '31 21 13',
            },
            {
              id: 'ed97ec05-2fdf-4d7b-950b-b89838285800',
              name: 'Radon Venting',
              tradeId: '31 21 13.13',
            },
            {
              id: '897c0175-c901-4d88-9400-7b3e50e26af9',
              name: 'Methane Mitigation',
              tradeId: '31 21 16',
            },
            {
              id: '7342f2e8-df2e-46e1-aeac-7279566df542',
              name: 'Methane Venting',
              tradeId: '31 21 16.13',
            },
          ],
          tradeId: '31 21 00',
        },
        {
          id: '5b96eea6-b034-440c-891a-39d350338e9d',
          name: 'Grading',
          children: [
            {
              id: 'ddae23e5-83aa-486a-ad42-9a067b92be7b',
              name: 'Grading',
              tradeId: '31 22 00',
            },
            {
              id: 'e63b4047-0341-4d84-9370-72ec2aa2dadf',
              name: 'Rough Grading',
              tradeId: '31 22 13',
            },
            {
              id: '64a4637b-3d15-4df2-abf9-a62f229e98f1',
              name: 'Fine Grading',
              tradeId: '31 22 16',
            },
            {
              id: 'a7b9953a-4cde-42dc-9a5f-21fffc5eaa6a',
              name: 'Roadway Subgrade Reshaping',
              tradeId: '31 22 16.13',
            },
            {
              id: 'afc13ad2-e976-4615-b702-f3372bc4e155',
              name: 'Finish Grading',
              tradeId: '31 22 19',
            },
            {
              id: 'dc92e724-f6ff-4f0e-a5aa-c222ebdf436a',
              name: 'Spreading and Grading Topsoil',
              tradeId: '31 22 19.13',
            },
          ],
          tradeId: '31 22 00',
        },
        {
          id: '5750cf53-bf8c-4739-bd68-28c2b5f2e919',
          name: 'Excavation and Fill',
          children: [
            {
              id: 'af27d9d5-5631-4ff8-91e3-18db9e9208c8',
              name: 'Excavation and Fill',
              tradeId: '31 23 00',
            },
            {
              id: '8bb122ea-eb99-4294-9fef-4a21cb4fefcb',
              name: 'Subgrade Preparation',
              tradeId: '31 23 13',
            },
            {
              id: '57595a14-2886-4dd8-9e9c-9dfbad56ec45',
              name: 'Excavation',
              tradeId: '31 23 16',
            },
            {
              id: '859c5d49-04b0-41d5-8541-c40b7298a43f',
              name: 'Trenching',
              tradeId: '31 23 16.13',
            },
            {
              id: 'c818c5e7-74a7-45bd-a599-88103145d7b4',
              name: 'Structural Excavation for Minor Structures',
              tradeId: '31 23 16.16',
            },
            {
              id: 'ef2d904d-18b5-476f-a706-53b4ea263a51',
              name: 'Rock Removal',
              tradeId: '31 23 16.26',
            },
            {
              id: '137cc862-c8ec-4887-bec0-40c54cb811f6',
              name: 'Dewatering',
              tradeId: '31 23 19',
            },
            {
              id: '8120cd65-1779-45a0-bc5e-7d40cce2a2f7',
              name: 'Fill',
              tradeId: '31 23 23',
            },
            {
              id: 'b5ff41a0-94d3-401b-9ffc-e798277eee21',
              name: 'Backfill',
              tradeId: '31 23 23.13',
            },
            {
              id: '405d3aac-9cf1-49fb-a4ac-86433956031a',
              name: 'Compaction',
              tradeId: '31 23 23.23',
            },
            {
              id: 'd697d045-908d-4190-9299-98b61bb5e299',
              name: 'Flowable Fill',
              tradeId: '31 23 23.33',
            },
            {
              id: 'd3f42253-dc85-4c60-b030-6baa5801b36b',
              name: 'Geofoam',
              tradeId: '31 23 23.43',
            },
            {
              id: 'd3c340d3-b2e6-4f0f-be3d-10be6c0cafab',
              name: 'Trenching and Backfilling',
              tradeId: '31 23 33',
            },
          ],
          tradeId: '31 23 00',
        },
        {
          id: 'd23df9de-1335-4425-97b3-ccead1d94bc6',
          name: 'Embankments',
          children: [
            {
              id: '5f861b09-d5fa-44dd-bc68-dba43a877a20',
              name: 'Embankments',
              tradeId: '31 24 00',
            },
            {
              id: '5a1c25bb-2818-4c89-8f68-b9082e93178a',
              name: 'Roadway Embankments',
              tradeId: '31 24 13',
            },
            {
              id: '66926992-0959-4398-9bcc-98df4af5fc96',
              name: 'Railway Embankments',
              tradeId: '31 24 16',
            },
          ],
          tradeId: '31 24 00',
        },
        {
          id: 'e074c763-d528-4ee4-8653-a851d237be39',
          name: 'Erosion and Sedimentation Controls',
          children: [
            {
              id: '60b915ac-7d4c-4e9a-bfe5-6961ade755ef',
              name: 'Erosion and Sedimentation Controls',
              tradeId: '31 25 00',
            },
            {
              id: '527cec11-f030-4db8-9928-2a92614bce12',
              name: 'Stabilization Measures for Erosion and Sedimentation Control',
              tradeId: '31 25 14',
            },
            {
              id: 'a910d47e-2aad-4efe-80f7-f31b210d2f01',
              name: 'Hydraulically-Applied Erosion Control',
              tradeId: '31 25 14.13',
            },
            {
              id: '5e8abc37-e01f-45a1-91e6-0a8e8f6be5cd',
              name: 'Rolled Erosion Control Mats and Blankets',
              tradeId: '31 25 14.16',
            },
            {
              id: '04ff6f11-f6f4-48eb-8949-c63100a93914',
              name: 'Structural Measures for Erosion and Sedimentation Control',
              tradeId: '31 25 24',
            },
            {
              id: 'b37decae-265f-42be-b613-a3eb4f7632f5',
              name: 'Rock Barriers',
              tradeId: '31 25 24.13',
            },
            {
              id: 'aef68540-8943-42fb-a66d-39a68521d474',
              name: 'Retention Measures for Erosion and Sedimentation Controls',
              tradeId: '31 25 34',
            },
            {
              id: 'f51220c4-28c2-436d-9e27-c234a72a6053',
              name: 'Rock Basins',
              tradeId: '31 25 34.13',
            },
          ],
          tradeId: '31 25 00',
        },
        {
          id: 'c1db4dd1-d5a9-490d-8b8b-b24dcca4fe50',
          name: 'Earthwork Methods',
          children: [],
          tradeId: '31 30 00',
        },
        {
          id: '554886f9-c2d9-4292-aaf9-4dd994113e70',
          name: 'Soil Treatment',
          children: [
            {
              id: '540376f7-851e-4105-877f-c85ab12f14b6',
              name: 'Soil Treatment',
              tradeId: '31 31 00',
            },
            {
              id: '54e40b9e-fa11-4841-a97c-e5e165b56dd4',
              name: 'Rodent Control',
              tradeId: '31 31 13',
            },
            {
              id: '3577682f-c876-452c-956d-36202b7fbc26',
              name: 'Rodent Control Bait Systems',
              tradeId: '31 31 13.16',
            },
            {
              id: 'b0b588e6-522b-465b-b700-dd2cf508a455',
              name: 'Rodent Control Traps',
              tradeId: '31 31 13.19',
            },
            {
              id: '234e2d37-4e8e-411a-9589-5053969edac7',
              name: 'Rodent Control Electronic Systems',
              tradeId: '31 31 13.23',
            },
            {
              id: '9b3e131d-b35d-4b23-8b7a-7c9ce251cdda',
              name: 'Rodent Control Repellants',
              tradeId: '31 31 13.26',
            },
            {
              id: 'cad0633c-46a9-4dcb-913b-cb0d07aefaee',
              name: 'Termite Control',
              tradeId: '31 31 16',
            },
            {
              id: 'bf431313-a89b-44f8-a7e6-1b916e81edfa',
              name: 'Chemical Termite Control',
              tradeId: '31 31 16.13',
            },
            {
              id: '2336ddd3-0d36-4e55-a7b5-c3e9eb08ecef',
              name: 'Termite Control Bait Systems',
              tradeId: '31 31 16.16',
            },
            {
              id: 'dc35b3eb-9088-431d-97cd-daf0b3913e6f',
              name: 'Termite Control Barriers',
              tradeId: '31 31 16.19',
            },
            {
              id: 'a2989141-9189-4bb9-8af8-80082ad2e4e9',
              name: 'Vegetation Control',
              tradeId: '31 31 19',
            },
            {
              id: 'b0b2d2a8-83a0-4af6-93b0-876eeb413c54',
              name: 'Chemical Vegetation Control',
              tradeId: '31 31 19.13',
            },
          ],
          tradeId: '31 31 00',
        },
        {
          id: 'b12e1460-075f-41dc-96a8-786b8036d906',
          name: 'Soil Stabilization',
          children: [
            {
              id: '41ba0797-71e5-4fb5-8b75-6219548f5855',
              name: 'Soil Stabilization',
              tradeId: '31 32 00',
            },
            {
              id: '6a3b5ada-951b-454f-b292-7cb47191dc47',
              name: 'Soil Mixing Stabilization',
              tradeId: '31 32 13',
            },
            {
              id: '3f1516b2-4f19-4fb2-9028-bf46da1ebc31',
              name: 'Asphalt Soil Stabilization',
              tradeId: '31 32 13.13',
            },
            {
              id: 'b74e888b-2ba1-4db6-84f7-b73bf5e6fba6',
              name: 'Cement Soil Stabilization',
              tradeId: '31 32 13.16',
            },
            {
              id: '9287e46d-914f-4991-8f8c-c6f40b3e3a59',
              name: 'Lime Soil Stabilization',
              tradeId: '31 32 13.19',
            },
            {
              id: '7561bee8-2fe3-45db-a796-b99fa3a3c4bf',
              name: 'Fly-Ash Soil Stabilization',
              tradeId: '31 32 13.23',
            },
            {
              id: 'f36d1c56-0433-4122-abe9-d6ef81cf2123',
              name: 'Lime-Fly-Ash Soil Stabilization',
              tradeId: '31 32 13.26',
            },
            {
              id: '4b467762-09bf-4ab6-b38f-5c6eda8e24b7',
              name: 'Chemical Treatment Soil Stabilization',
              tradeId: '31 32 16',
            },
            {
              id: 'facf1510-ce33-422a-a983-4177a99a43a8',
              name: 'Polymer Emulsion Soil Stabilization',
              tradeId: '31 32 16.13',
            },
            {
              id: '37d196d2-fb40-4db9-8bd0-549c1c3f7308',
              name: 'Water Injection Soil Stabilization',
              tradeId: '31 32 17',
            },
            {
              id: '7fc308c2-c3af-44f1-985e-7bcf50a024cc',
              name: 'Geosynthetic Soil Stabilization and Layer Separation',
              tradeId: '31 32 19',
            },
            {
              id: 'beb6d787-3837-4ce1-8ed0-22a69b8a9c6d',
              name: 'Geogrid Soil Stabilization',
              tradeId: '31 32 19.13',
            },
            {
              id: '2c1ad5c7-8c56-41e2-84c8-8a16622f4c2f',
              name: 'Geotextile Soil Stabilization',
              tradeId: '31 32 19.16',
            },
            {
              id: '45cf5db5-9d5f-4260-ba99-c5494f5867b0',
              name: 'Geogrid Layer Separation',
              tradeId: '31 32 19.19',
            },
            {
              id: '25f84d4d-eb10-4b3a-ba99-dd1858579661',
              name: 'Geotextile Layer Separation',
              tradeId: '31 32 19.23',
            },
            {
              id: '8973097b-c47a-47fd-bb85-2905e4d8f8eb',
              name: 'Pressure Grouting Soil Stabilization',
              tradeId: '31 32 23',
            },
            {
              id: 'd613dc6c-6054-4367-98a3-0f6e1045b164',
              name: 'Cementitious Pressure Grouting Soil Stabilization',
              tradeId: '31 32 23.13',
            },
            {
              id: 'b75c1d29-3a60-401b-aa9f-03254e87cf54',
              name: 'Chemical Pressure Grouting Soil Stabilization',
              tradeId: '31 32 23.16',
            },
            {
              id: '46aed1cf-6311-4592-9754-3383e3c30f9e',
              name: 'Shotcrete Soil Slope Stabilization',
              tradeId: '31 32 33',
            },
            {
              id: 'cefe4e57-6d79-489a-9878-47cb6fad932d',
              name: 'Soil Nailing',
              tradeId: '31 32 36',
            },
            {
              id: 'f840394b-5ce4-4393-b64e-34eb694293e7',
              name: 'Driven Soil Nailing',
              tradeId: '31 32 36.13',
            },
            {
              id: '3af4d173-96e6-4c9d-a084-9944109b9b96',
              name: 'Grouted Soil Nailing',
              tradeId: '31 32 36.16',
            },
            {
              id: 'b6601998-8e4a-40b2-b334-985eb574febd',
              name: 'Corrosion-Protected Soil Nailing',
              tradeId: '31 32 36.19',
            },
            {
              id: '1bba17e8-cfa4-4dff-ace9-d0a1739fff12',
              name: 'Jet-Grouted Soil Nailing',
              tradeId: '31 32 36.23',
            },
            {
              id: 'f9ff04b2-450f-4938-978c-b0fc39de8fda',
              name: 'Launched Soil Nailing',
              tradeId: '31 32 36.26',
            },
          ],
          tradeId: '31 32 00',
        },
        {
          id: '77fc729e-5b37-4626-a053-9766770b6c18',
          name: 'Rock Stabilization',
          children: [
            {
              id: '447a4b8a-46af-4d30-8608-2c3702c43b69',
              name: 'Rock Stabilization',
              tradeId: '31 33 00',
            },
            {
              id: '885165d7-db43-470a-9b14-0964d1aba230',
              name: 'Rock Bolting and Grouting',
              tradeId: '31 33 13',
            },
            {
              id: '328945d1-a53c-45be-9d2a-4e34b5cafb34',
              name: 'Rock Slope Netting',
              tradeId: '31 33 23',
            },
            {
              id: '7029fd53-ab61-4697-af79-3ddf1c0420d4',
              name: 'Rock Slope Wire Mesh',
              tradeId: '31 33 26',
            },
            {
              id: '7d3af2ca-24ae-46dd-9627-eb82dedc9363',
              name: 'Shotcrete Rock Slope Stabilization',
              tradeId: '31 33 33',
            },
            {
              id: '58255104-6fb8-4cb0-bccc-336f63c6038d',
              name: 'Vegetated Rock Slope Stabilization',
              tradeId: '31 33 43',
            },
          ],
          tradeId: '31 33 00',
        },
        {
          id: '37b1c30c-bb03-47cd-89f4-7086ec31f180',
          name: 'Soil Reinforcement',
          children: [
            {
              id: 'a3894106-a93c-4227-83f7-c101cd44be6b',
              name: 'Soil Reinforcement',
              tradeId: '31 34 00',
            },
            {
              id: '4c445521-ac4d-41da-a7b7-672e6b56fd42',
              name: 'Geosynthetic Soil Reinforcement',
              tradeId: '31 34 19',
            },
            {
              id: '2f360292-1f6b-47ba-b3a3-f28b13be3c3e',
              name: 'Geogrid Soil Reinforcement',
              tradeId: '31 34 19.13',
            },
            {
              id: '1dcadef1-3330-4153-9009-bbdcd5147433',
              name: 'Geotextile Soil Reinforcement',
              tradeId: '31 34 19.16',
            },
            {
              id: 'af7465f9-affe-424c-b32e-fbc4ade2e1fa',
              name: 'Fiber Soil Reinforcement',
              tradeId: '31 34 23',
            },
            {
              id: 'c328b1f7-e8dd-4eff-b041-7913181b1d46',
              name: 'Geosynthetic Fiber Soil Reinforcement',
              tradeId: '31 34 23.13',
            },
          ],
          tradeId: '31 34 00',
        },
        {
          id: '2c2cc749-5010-4b6c-ace1-cebd6d1ae497',
          name: 'Slope Protection',
          children: [
            {
              id: '267430ea-ea15-4ff7-bb7e-b3bdaccbb55f',
              name: 'Slope Protection',
              tradeId: '31 35 00',
            },
            {
              id: '1c489038-f582-41cb-83d5-500840d4aee4',
              name: 'Geosynthetic Slope Protection',
              tradeId: '31 35 19',
            },
            {
              id: '48eab614-507d-4655-91d1-5593e4ac3b93',
              name: 'Geogrid Slope Protection',
              tradeId: '31 35 19.13',
            },
            {
              id: '6e561366-0163-4128-9a2b-97e9f89f35bf',
              name: 'Geotextile Slope Protection',
              tradeId: '31 35 19.16',
            },
            {
              id: '93d8ba29-5796-4aab-b20e-e9290d22cab1',
              name: 'Slope Protection with Mulch Control Netting',
              tradeId: '31 35 19.19',
            },
            {
              id: '697d15ab-c0f2-4d01-a810-b2c016c1ca03',
              name: 'Slope Protection with Slope Paving',
              tradeId: '31 35 23',
            },
            {
              id: '71a502b1-eb2c-4bfb-9534-f7535ebfbf0f',
              name: 'Cast-In-Place Concrete Slope Paving',
              tradeId: '31 35 23.13',
            },
            {
              id: 'a4982d18-de99-4012-85b2-926f3d159574',
              name: 'Precast Concrete Slope Paving',
              tradeId: '31 35 23.16',
            },
            {
              id: 'd52e2505-5fcf-47dd-8b98-d68e882ae3c6',
              name: 'Concrete Unit Masonry Slope Paving',
              tradeId: '31 35 23.19',
            },
            {
              id: 'd88ff924-12cd-4914-a2d5-95a93fa8cf99',
              name: 'Containment Barriers',
              tradeId: '31 35 26',
            },
            {
              id: 'b207bab4-a1a5-4364-be0d-dfa7e2104ff0',
              name: 'Clay Containment Barriers',
              tradeId: '31 35 26.13',
            },
            {
              id: 'd0292aec-482a-427f-9332-5374af43649b',
              name: 'Geomembrane Containment Barriers',
              tradeId: '31 35 26.16',
            },
            {
              id: 'c9d6b813-685d-4ca9-ba66-e764c93072db',
              name: 'Bentonite Slurry Trench',
              tradeId: '31 35 26.23',
            },
          ],
          tradeId: '31 35 00',
        },
        {
          id: 'f5d3b566-fb24-46fb-a8f6-d6c0bce1a9ce',
          name: 'Gabions',
          children: [
            {
              id: 'efc52ed2-1368-4ef0-8a6a-93faa4d6327a',
              name: 'Gabions',
              tradeId: '31 36 00',
            },
            {
              id: '261e9eec-1d1f-4a26-a5a8-965155044273',
              name: 'Gabion Boxes',
              tradeId: '31 36 13',
            },
            {
              id: '71d692ca-d405-404a-b8d8-d663292137d4',
              name: 'Gabion Mattresses',
              tradeId: '31 36 19',
            },
            {
              id: 'b70a2b13-de27-4e49-bad0-67b180557d0e',
              name: 'Vegetated Gabion Mattresses',
              tradeId: '31 36 19.13',
            },
          ],
          tradeId: '31 36 00',
        },
        {
          id: '9e3a9ed6-471c-496d-a8df-01aa87154d31',
          name: 'Riprap',
          children: [
            {
              id: 'c62b2550-f359-4c71-b8cc-b4e1b18e8a7b',
              name: 'Riprap',
              tradeId: '31 37 00',
            },
            {
              id: 'f5449645-869b-45bd-977b-3a67a53feb3c',
              name: 'Machined Riprap',
              tradeId: '31 37 13',
            },
            {
              id: '5f46d0bd-7fcf-44f6-807c-c44fe5530f1e',
              name: 'Non-Machined Riprap',
              tradeId: '31 37 16',
            },
            {
              id: 'c0cc5c51-124c-469a-b7f9-762ebbb532f4',
              name: 'Rubble-Stone Riprap',
              tradeId: '31 37 16.13',
            },
            {
              id: 'be1c5bd6-cdbf-425a-9dcb-d5f026c159b3',
              name: 'Concrete Unit Masonry Riprap',
              tradeId: '31 37 16.16',
            },
            {
              id: '3bd12585-afb9-4ad0-8621-638fa25e3fc9',
              name: 'Sacked Sand-Cement Riprap',
              tradeId: '31 37 16.19',
            },
          ],
          tradeId: '31 37 00',
        },
        {
          id: 'd90e35ee-203a-4cb7-9352-15ded3118740',
          name: 'Shoring and Underpinning',
          children: [],
          tradeId: '31 40 00',
        },
        {
          id: '08d0b351-0c22-4187-8b82-82c64294d0bb',
          name: 'Shoring',
          children: [
            {
              id: '709b3e8a-1bec-480b-a7a3-64608015230a',
              name: 'Shoring',
              tradeId: '31 41 00',
            },
            {
              id: '4d31e58a-f0bd-46e0-9cff-a84a605758b6',
              name: 'Timber Shoring',
              tradeId: '31 41 13',
            },
            {
              id: 'c441ca62-e165-4e5e-aafb-e8b11706f7c6',
              name: 'Sheet Piling',
              tradeId: '31 41 16',
            },
            {
              id: '874b0b44-048f-443b-933a-889f18f065ad',
              name: 'Steel Sheet Piling',
              tradeId: '31 41 16.13',
            },
            {
              id: '11b9cf2a-3125-4804-9c84-28a34c985fc1',
              name: 'Plastic Sheet Piling',
              tradeId: '31 41 16.16',
            },
            {
              id: '178891cd-8f7d-429f-818b-ff4408ce828d',
              name: 'Metal Hydraulic Shoring',
              tradeId: '31 41 19',
            },
            {
              id: '9c23568b-9620-4f17-94a1-753636facea9',
              name: 'Aluminum Hydraulic Shoring',
              tradeId: '31 41 19.13',
            },
            {
              id: '19b9a053-68d3-480a-b3c4-1803a587269b',
              name: 'Pneumatic Shoring',
              tradeId: '31 41 23',
            },
            {
              id: '4380999c-8d38-4216-84d9-3859aa71e471',
              name: 'Trench Shielding',
              tradeId: '31 41 33',
            },
          ],
          tradeId: '31 41 00',
        },
        {
          id: '3430e786-69b8-4bfe-9cc6-829c19683b75',
          name: 'Concrete Raising',
          children: [
            {
              id: '9d9e88c1-d0eb-44f1-b86f-e5da3cba6ef0',
              name: 'Concrete Raising',
              tradeId: '31 43 00',
            },
            {
              id: 'aeca6844-73f2-4375-83a7-5c38b17e4469',
              name: 'Pressure Grouting',
              tradeId: '31 43 13',
            },
            {
              id: 'f75e0ab0-41d5-45c5-a611-83868ed4fa91',
              name: 'Concrete Pressure Grouting',
              tradeId: '31 43 13.13',
            },
            {
              id: '84a3ed5e-89b3-4246-9ffc-3c1fdb02092d',
              name: 'Polyurethane Pressure Grouting',
              tradeId: '31 43 13.16',
            },
            {
              id: 'cfc135e5-309a-4a7b-9bb9-cb3640622302',
              name: 'Compaction Grouting',
              tradeId: '31 43 16',
            },
            {
              id: '117cafdc-3dc6-43ff-b4c2-732bb1d22ad0',
              name: 'Mechanical Jacking',
              tradeId: '31 43 19',
            },
          ],
          tradeId: '31 43 00',
        },
        {
          id: 'baa8e8d7-489e-4dd6-9476-006f7ba8afea',
          name: 'Vibroflotation and Densification',
          children: [
            {
              id: '1b8d8241-38de-47a3-8abf-2e578865cda1',
              name: 'Vibroflotation and Densification',
              tradeId: '31 45 00',
            },
            {
              id: 'a69a1080-e91c-4bdb-b95f-ad9777451316',
              name: 'Vibroflotation',
              tradeId: '31 45 13',
            },
            {
              id: '6c96c7d7-de55-47d0-a46c-2f8b01b50e68',
              name: 'Densification',
              tradeId: '31 45 16',
            },
          ],
          tradeId: '31 45 00',
        },
        {
          id: 'ee80768f-9cba-428c-b0c6-c017ed5a15a1',
          name: 'Needle Beams',
          children: [
            {
              id: 'f5d9e759-5752-4673-939c-73f3e48ebdf6',
              name: 'Needle Beams',
              tradeId: '31 46 00',
            },
            {
              id: '4b92614b-e2bf-4b95-ac28-0e792119ed0a',
              name: 'Cantilever Needle Beams',
              tradeId: '31 46 13',
            },
          ],
          tradeId: '31 46 00',
        },
        {
          id: '6382923e-c6d7-497a-9f7f-de9f235c9d7f',
          name: 'Underpinning',
          children: [
            {
              id: '1384e8fc-03ab-4bb8-8ac8-72b15ee37a49',
              name: 'Underpinning',
              tradeId: '31 48 00',
            },
            {
              id: '711c9baa-f0ed-4b2a-9141-777f8606b4f8',
              name: 'Underpinning Piers',
              tradeId: '31 48 13',
            },
            {
              id: 'd2941a1d-727a-434a-805d-dec04b411176',
              name: 'Bracket Piers',
              tradeId: '31 48 19',
            },
            {
              id: 'ee9b7f69-1696-4bab-9aa8-f1f681010ba4',
              name: 'Jacked Piers',
              tradeId: '31 48 23',
            },
            {
              id: 'a0a71fa9-66da-42b9-b39c-206164814ac9',
              name: 'Micropile Underpinning',
              tradeId: '31 48 33',
            },
          ],
          tradeId: '31 48 00',
        },
        {
          id: '343b90ad-5df6-4fe7-a8d7-a42667aa5eb6',
          name: 'Excavation Support and Protection',
          children: [],
          tradeId: '31 50 00',
        },
        {
          id: '9d38d540-36f0-4b3c-aa2d-4b03d59107fc',
          name: 'Anchor Tiebacks',
          children: [
            {
              id: '3cd0b550-1090-4ac2-b98c-ae216dda7bf6',
              name: 'Anchor Tiebacks',
              tradeId: '31 51 00',
            },
            {
              id: '7f56a95c-e8ed-43ce-8226-5dd400090659',
              name: 'Excavation Soil Anchors',
              tradeId: '31 51 13',
            },
            {
              id: '69f1ee46-1b89-405d-bb64-b0c32be7c201',
              name: 'Excavation Rock Anchors',
              tradeId: '31 51 16',
            },
          ],
          tradeId: '31 51 00',
        },
        {
          id: '3a403ff0-779d-4c2d-90a9-d7f79d1dcdce',
          name: 'Cofferdams',
          children: [
            {
              id: '9fa6928a-4ca4-4759-9998-a2759664fae5',
              name: 'Cofferdams',
              tradeId: '31 52 00',
            },
            {
              id: 'fb4ce14a-edf4-4e8e-ac4b-23d48d9a7b6b',
              name: 'Sheet Piling Cofferdams',
              tradeId: '31 52 13',
            },
            {
              id: 'bbd873db-cab6-4d48-a86d-e9e8c327760a',
              name: 'Timber Cofferdams',
              tradeId: '31 52 16',
            },
            {
              id: '5c0bec16-c417-4d1c-8566-dfc77cb68f69',
              name: 'Precast Concrete Cofferdams',
              tradeId: '31 52 19',
            },
          ],
          tradeId: '31 52 00',
        },
        {
          id: '1e6ff858-a65e-44fb-acf5-2bbe6878eee5',
          name: 'Cribbing and Walers',
          children: [
            {
              id: '0bc06eba-da1c-458d-a1a3-7e3dfd306206',
              name: 'Cribbing and Walers',
              tradeId: '31 53 00',
            },
            {
              id: '6955044d-d21b-4507-9565-e231033ab243',
              name: 'Timber Cribwork',
              tradeId: '31 53 13',
            },
          ],
          tradeId: '31 53 00',
        },
        {
          id: '0aabbe39-0853-41c3-8465-7657ee7297e4',
          name: 'Ground Freezing',
          children: [],
          tradeId: '31 54 00',
        },
        {
          id: '84780430-5a07-4734-9fa3-67f3e8562e69',
          name: 'Slurry Walls',
          children: [
            {
              id: 'bf7061f6-dbf9-4fbd-a3f4-2433290d7884',
              name: 'Slurry Walls',
              tradeId: '31 56 00',
            },
            {
              id: '7808c13c-4331-4a99-897b-ee34f6358d87',
              name: 'Bentonite Slurry Walls',
              tradeId: '31 56 13',
            },
            {
              id: '6cad93c9-4c8d-4a72-bff0-5988095047d4',
              name: 'Soil-Bentonite Slurry Walls',
              tradeId: '31 56 13.13',
            },
            {
              id: 'a2be0009-158d-4d3c-962b-a31824bb3f6f',
              name: 'Cement-Bentonite Slurry Walls',
              tradeId: '31 56 13.16',
            },
            {
              id: '88d122be-c621-4ee9-a680-b275688c21cc',
              name: 'Slag-Cement-Bentonite Slurry Walls',
              tradeId: '31 56 13.19',
            },
            {
              id: '2494d818-28dd-4475-b9de-743051e04cdc',
              name: 'Soil-Cement-Bentonite Slurry Walls',
              tradeId: '31 56 13.23',
            },
            {
              id: '2a472c7c-de16-41b9-88a4-ec47555bcee8',
              name: 'Pozzolan-Bentonite Slurry Walls',
              tradeId: '31 56 13.26',
            },
            {
              id: '54a8908a-eac2-468a-9d34-5ebc0cda90da',
              name: 'Organically-Modified Bentonite Slurry Walls',
              tradeId: '31 56 13.29',
            },
            {
              id: 'e736bccc-6c96-48dc-a6c0-f3dc3e6fa2ea',
              name: 'Attipulgite Slurry Walls',
              tradeId: '31 56 16',
            },
            {
              id: '314edc82-6c19-4891-a9b5-0036cab877a7',
              name: 'Soil-Attipulgite Slurry Walls',
              tradeId: '31 56 16.13',
            },
            {
              id: 'd530d4c3-6956-44a2-bc26-abffde9c46cb',
              name: 'Slurry-Geomembrane Composite Slurry Walls',
              tradeId: '31 56 19',
            },
            {
              id: '4659b436-2aba-4a99-b839-ee2a7f378ec7',
              name: 'Lean Concrete Slurry Walls',
              tradeId: '31 56 23',
            },
            {
              id: '70aeb632-4d85-41de-8968-c546f4455aa3',
              name: 'Bio-Polymer Trench Drain',
              tradeId: '31 56 26',
            },
          ],
          tradeId: '31 56 00',
        },
        {
          id: '46c20b7d-363d-42bf-974b-922433d4847a',
          name: 'Special Foundations and Load-Bearing Elements',
          children: [],
          tradeId: '31 60 00',
        },
        {
          id: '408298b5-6b5f-4841-8846-1c982e7aecc4',
          name: 'Driven Piles',
          children: [
            {
              id: 'fb689253-76a4-442e-ab9b-c0d7afbdf3f6',
              name: 'Driven Piles',
              tradeId: '31 62 00',
            },
            {
              id: 'f0c662c2-1dbd-40bd-9c49-9d858c6b34a6',
              name: 'Concrete Piles',
              tradeId: '31 62 13',
            },
            {
              id: '67f9b38e-9f1a-47e9-88ce-a2ec449da53e',
              name: 'Cast-in-Place Concrete Piles',
              tradeId: '31 62 13.13',
            },
            {
              id: '423adb81-4149-448d-b0f1-2617ee7ab7c1',
              name: 'Concrete Displacement Piles',
              tradeId: '31 62 13.16',
            },
            {
              id: '0f446086-7bcc-4e94-959c-f754b8765ef2',
              name: 'Precast Concrete Piles',
              tradeId: '31 62 13.19',
            },
            {
              id: '1e953539-2680-4a1b-9009-14d393e613e5',
              name: 'Prestressed Concrete Piles',
              tradeId: '31 62 13.23',
            },
            {
              id: '5a56e66e-beeb-424f-9e64-c89c313b7a56',
              name: 'Pressure-Injected Footings',
              tradeId: '31 62 13.26',
            },
            {
              id: 'ebcda0ea-2c3c-44b2-ac7a-67329dbd0d33',
              name: 'Steel Piles',
              tradeId: '31 62 16',
            },
            {
              id: '262bf55a-253b-4aa6-847a-ada0ecdf4353',
              name: 'Sheet Steel Piles',
              tradeId: '31 62 16.13',
            },
            {
              id: '2ee9ffbe-3444-4a29-88f3-5cebf9635a80',
              name: 'Steel H Piles',
              tradeId: '31 62 16.16',
            },
            {
              id: '61e9d67e-eeea-4ede-868f-6888132a983f',
              name: 'Unfilled Tubular Steel Piles',
              tradeId: '31 62 16.19',
            },
            {
              id: '345105d1-61c0-460c-ab34-9f1a6ae868ab',
              name: 'Timber Piles',
              tradeId: '31 62 19',
            },
            {
              id: '9c1ca6ff-a1c7-4e75-aa53-e060444bdcfa',
              name: 'Composite Piles',
              tradeId: '31 62 23',
            },
            {
              id: '024ae86e-5532-4574-8654-d54213fa53e2',
              name: 'Concrete-Filled Steel Piles',
              tradeId: '31 62 23.13',
            },
            {
              id: '2e7d01ae-ba23-43dd-a719-bbebcbe104c7',
              name: 'Wood and Cast-In-Place Concrete Piles',
              tradeId: '31 62 23.16',
            },
          ],
          tradeId: '31 62 00',
        },
        {
          id: '0b55b356-7e3c-45b0-9351-1a2e50081ace',
          name: 'Bored Piles',
          children: [
            {
              id: '77dbc226-b27c-4316-af3b-728088dc4481',
              name: 'Bored Piles',
              tradeId: '31 63 00',
            },
            {
              id: '7fb9b9ab-38b8-420a-8152-2fa3a6ce2ad6',
              name: 'Bored and Augered Test Piles',
              tradeId: '31 63 13',
            },
            {
              id: '02e4e360-b504-4ade-b8f6-a2d83dc9b58d',
              name: 'Auger Cast Grout Piles',
              tradeId: '31 63 16',
            },
            {
              id: '79fc9454-476d-48ae-8b03-c6aaecbcee41',
              name: 'Bored and Socketed Piles',
              tradeId: '31 63 19',
            },
            {
              id: '4447d781-8e94-48cd-b7a0-0b950f79dc3b',
              name: 'Rock Sockets for Piles',
              tradeId: '31 63 19.13',
            },
            {
              id: 'd6b5979a-eb84-4126-be25-071035abf0e7',
              name: 'Bored Concrete Piles',
              tradeId: '31 63 23',
            },
            {
              id: '0796b4a1-75d6-4c15-8879-a5520f368991',
              name: 'Bored and Belled Concrete Piles',
              tradeId: '31 63 23.13',
            },
            {
              id: '9cbff923-9067-44bc-8331-c84711e78d3b',
              name: 'Bored Friction Concrete Piles',
              tradeId: '31 63 23.16',
            },
            {
              id: '3f99b75e-17c7-4747-991f-80b1358c747b',
              name: 'Drilled Caissons',
              tradeId: '31 63 26',
            },
            {
              id: '7f9fc59f-d9ac-44c2-80be-6081b280f9ee',
              name: 'Fixed End Caisson Piles',
              tradeId: '31 63 26.13',
            },
            {
              id: 'f9668a78-e287-4542-803b-7d26749d583f',
              name: 'Concrete Caissons for Marine Construction',
              tradeId: '31 63 26.16',
            },
            {
              id: '779450eb-ffe9-4794-876d-5a06d4c9b41c',
              name: 'Drilled Concrete Piers and Shafts',
              tradeId: '31 63 29',
            },
            {
              id: 'cf50a278-e435-41a8-ab32-10f936460eea',
              name: 'Uncased Drilled Concrete Piers',
              tradeId: '31 63 29.13',
            },
            {
              id: 'c92b8ff7-601c-47d4-8558-10dd70554edc',
              name: 'Cased Drilled Concrete Piers',
              tradeId: '31 63 29.16',
            },
            {
              id: 'bf599809-2dbb-47d2-84bb-d3f487dad8fa',
              name: 'Drilled Micropiles',
              tradeId: '31 63 33',
            },
          ],
          tradeId: '31 63 00',
        },
        {
          id: '0aa3df56-3899-46bd-bea0-f73d1b11823f',
          name: 'Caissons',
          children: [
            {
              id: 'b2e8e4d2-c2b5-4e26-a294-b30a75e88caa',
              name: 'Caissons',
              tradeId: '31 64 00',
            },
            {
              id: '1844278a-6d87-4486-98a0-8ed103a726a2',
              name: 'Box Caissons',
              tradeId: '31 64 13',
            },
            {
              id: '06e5c2e2-32af-4744-bf08-561c82a45994',
              name: 'Excavated Caissons',
              tradeId: '31 64 16',
            },
            {
              id: '729c4d64-035f-4873-9b4c-be070ef5768c',
              name: 'Floating Caissons',
              tradeId: '31 64 19',
            },
            {
              id: '582aeca6-0056-45d9-ac83-ea9e81e44d2a',
              name: 'Open Caissons',
              tradeId: '31 64 23',
            },
            {
              id: 'fd18968e-c04a-4320-8df5-f49941e65434',
              name: 'Pneumatic Caissons',
              tradeId: '31 64 26',
            },
            {
              id: '2e54eb0f-280d-451e-b804-2042d35f4206',
              name: 'Sheeted Caissons',
              tradeId: '31 64 29',
            },
          ],
          tradeId: '31 64 00',
        },
        {
          id: '4ae8f7f3-c4f0-4464-91de-b771cd667e97',
          name: 'Special Foundations',
          children: [
            {
              id: '4e27bd0d-ac08-401f-a39d-bd97ea53a2fc',
              name: 'Special Foundations',
              tradeId: '31 66 00',
            },
            {
              id: 'f8d0b223-3e2e-49d0-b5b1-29bc30b5e63a',
              name: 'Special Piles',
              tradeId: '31 66 13',
            },
            {
              id: 'd39ee7ef-18a9-49a0-9d6c-6d0f12f1ec1d',
              name: 'Rammed Aggregate Piles',
              tradeId: '31 66 13.13',
            },
            {
              id: '5cda34e2-a70e-48af-8cc3-5621862525f2',
              name: 'Helical Foundation Piles',
              tradeId: '31 66 15',
            },
            {
              id: '8e9d95a5-5040-45a7-a91a-156be25fabef',
              name: 'Special Foundation Walls',
              tradeId: '31 66 16',
            },
            {
              id: 'e58dd30a-3a8b-45cc-86a3-43d3c63881cf',
              name: 'Anchored Foundation Walls',
              tradeId: '31 66 16.13',
            },
            {
              id: '7fb7ff10-e52a-4831-9ebb-e53915fce084',
              name: 'Concrete Cribbing Foundation Walls',
              tradeId: '31 66 16.23',
            },
            {
              id: 'b17af5ac-f462-4a84-9f83-1a4c0c5c5acb',
              name: 'Metal Cribbing Foundation Walls',
              tradeId: '31 66 16.26',
            },
            {
              id: 'b51a3e2c-f82f-43c3-9bba-119bea88d3f3',
              name: 'Manufactured Modular Foundation Walls',
              tradeId: '31 66 16.33',
            },
            {
              id: '964b4381-6ef4-4414-98e6-35a51facd1f7',
              name: 'Mechanically Stabilized Earth Foundation Walls',
              tradeId: '31 66 16.43',
            },
            {
              id: 'db4837e2-4d6f-4178-858d-8ee3a5d85ed5',
              name: 'Slurry Diaphragm Foundation Walls',
              tradeId: '31 66 16.46',
            },
            {
              id: '822edb8e-bf80-4f51-8cc4-28d1ac48067f',
              name: 'Soldier-Beam Foundation Walls',
              tradeId: '31 66 16.53',
            },
            {
              id: 'ee416968-c8fb-4bd2-935a-0edcb0617c6b',
              name: 'Permanently-Anchored Soldier-Beam Foundation Walls',
              tradeId: '31 66 16.56',
            },
            {
              id: '32e7c323-7cc5-4008-b77c-a1994af4fedf',
              name: 'Refrigerated Foundations',
              tradeId: '31 66 19',
            },
          ],
          tradeId: '31 66 00',
        },
        {
          id: '66528afa-48bb-474d-b347-4b7a865f8e31',
          name: 'Foundation Anchors',
          children: [
            {
              id: '447bf207-5e2b-424c-97b7-541b63772b4c',
              name: 'Foundation Anchors',
              tradeId: '31 68 00',
            },
            {
              id: '912e9812-7406-4a94-b169-98cf4ed11950',
              name: 'Rock Foundation Anchors',
              tradeId: '31 68 13',
            },
            {
              id: 'c74871c4-ca0e-454c-a481-5d0a852214a9',
              name: 'Helical Foundation Anchors',
              tradeId: '31 68 16',
            },
          ],
          tradeId: '31 68 00',
        },
        {
          id: '87c94f2e-78cd-4870-9dde-7f281137782a',
          name: 'Tunneling and Mining',
          children: [],
          tradeId: '31 70 00',
        },
        {
          id: '81b717bd-7ad4-419c-977b-8296539b9d20',
          name: 'Tunnel Excavation',
          children: [
            {
              id: 'c1394633-1fdb-47ee-8ef2-2fca4db2f83f',
              name: 'Tunnel Excavation',
              tradeId: '31 71 00',
            },
            {
              id: 'ebbe5c69-a45a-4a76-9b83-7abd9d80e06f',
              name: 'Shield Driving Tunnel Excavation',
              tradeId: '31 71 13',
            },
            {
              id: '79489195-a1a7-44e7-a234-a75e680ece9e',
              name: 'Tunnel Excavation by Drilling and Blasting',
              tradeId: '31 71 16',
            },
            {
              id: 'bbb31fd4-19fc-479c-91dd-cae4064951f0',
              name: 'Tunnel Excavation by Tunnel Boring Machine',
              tradeId: '31 71 19',
            },
            {
              id: '7d956d2c-5607-46b8-a4fd-dcd60a3188e3',
              name: 'Tunneling by Cut and Cover',
              tradeId: '31 71 23',
            },
          ],
          tradeId: '31 71 00',
        },
        {
          id: '6752ae78-9709-4fa5-9be4-94a9fbad8deb',
          name: 'Tunnel Support Systems',
          children: [
            {
              id: '500109ec-336f-4710-80ae-edb1bb7714b9',
              name: 'Tunnel Support Systems',
              tradeId: '31 72 00',
            },
            {
              id: '52d8bea8-c8e9-4209-a174-a02a587fc84a',
              name: 'Rock Reinforcement and Initial Support',
              tradeId: '31 72 13',
            },
            {
              id: '5872f256-eefd-445b-92cf-9c694bec6ff3',
              name: 'Steel Ribs and Lagging',
              tradeId: '31 72 16',
            },
          ],
          tradeId: '31 72 00',
        },
        {
          id: '09b8489d-4f15-43d0-81e1-d6142c0afd9a',
          name: 'Tunnel Grouting',
          children: [
            {
              id: '6e031d1b-3eac-4c0f-93bf-82f8efbd3c3a',
              name: 'Tunnel Grouting',
              tradeId: '31 73 00',
            },
            {
              id: 'b8b29033-321d-4f9a-b5f9-0915a92d1be8',
              name: 'Cement Tunnel Grouting',
              tradeId: '31 73 13',
            },
            {
              id: '453b47da-e81f-4175-8e5a-19b754a41414',
              name: 'Chemical Tunnel Grouting',
              tradeId: '31 73 16',
            },
          ],
          tradeId: '31 73 00',
        },
        {
          id: '8e12ac31-29b8-4873-b407-67510cf3a0ce',
          name: 'Tunnel Construction',
          children: [
            {
              id: 'cd7932fc-34e5-4a08-9881-9b7175862108',
              name: 'Tunnel Construction',
              tradeId: '31 74 00',
            },
            {
              id: '891cab27-f80b-4904-9448-2db1477625f9',
              name: 'Cast-in-Place Concrete Tunnel Lining',
              tradeId: '31 74 13',
            },
            {
              id: '412757a6-27cf-4ce3-aec7-0d549c69e93d',
              name: 'Precast Concrete Tunnel Lining',
              tradeId: '31 74 16',
            },
            {
              id: '8b1ce2ee-86fa-427f-81ed-a57632b973ff',
              name: 'Shotcrete Tunnel Lining',
              tradeId: '31 74 19',
            },
          ],
          tradeId: '31 74 00',
        },
        {
          id: '30488fd1-e8fd-4293-9250-bc4b3f01bc0c',
          name: 'Shaft Construction',
          children: [
            {
              id: '69fdc02e-9664-42d5-a024-714b33e2bbe2',
              name: 'Shaft Construction',
              tradeId: '31 75 00',
            },
            {
              id: '20e956e6-223f-447d-a543-0657c86c5225',
              name: 'Cast-in-Place Concrete Shaft Lining',
              tradeId: '31 75 13',
            },
            {
              id: 'aab2b20e-703b-46a4-b5b8-ebc9d2ae2aa7',
              name: 'Precast Concrete Shaft Lining',
              tradeId: '31 75 16',
            },
          ],
          tradeId: '31 75 00',
        },
        {
          id: '9988320a-186f-4d0b-823c-977a84244421',
          name: 'Submersible Tube Tunnels',
          children: [
            {
              id: '93af3c69-fe52-4324-b662-e6e60b15130b',
              name: 'Submersible Tube Tunnels',
              tradeId: '31 77 00',
            },
            {
              id: 'efbf4a48-140a-4ada-9265-1ff96b5ecb1c',
              name: 'Trench Excavation for Submerged Tunnels',
              tradeId: '31 77 13',
            },
            {
              id: '53b43286-108a-471d-bdff-2c0472835cdd',
              name: 'Tube Construction (Outfitting Tunnel Tubes)',
              tradeId: '31 77 16',
            },
            {
              id: '091e8c71-4562-47cb-baac-f6c001e6a84f',
              name: 'Floating and Laying Submerged Tunnels',
              tradeId: '31 77 19',
            },
          ],
          tradeId: '31 77 00',
        },
      ],
      tradeId: '31 00 00',
    },
    {
      id: '4351b55e-80e5-4434-ac13-e5c98271032e',
      name: 'Exterior Improvements',
      children: [
        {
          id: 'c85aaf71-dc28-4bc4-aeda-46f50923bbe5',
          name: 'Exterior Improvements',
          children: [],
          tradeId: '32 00 00',
        },
        {
          id: '74fa3068-3b20-431c-a163-e5a60b72e8e8',
          name: 'Operation and Maintenance of Exterior Improvements',
          children: [
            {
              id: '51c83737-0476-4141-a465-71ea71499f16',
              name: 'Operation and Maintenance of Exterior Improvements',
              tradeId: '32 01 00',
            },
            {
              id: 'd22f9e4d-e303-4391-9df2-95806d0e5ddd',
              name: 'Paving Cleaning',
              tradeId: '32 01 11',
            },
            {
              id: 'b97d6783-7679-4975-b70a-13e985857ce4',
              name: 'Rubber and Paint Removal from Paving',
              tradeId: '32 01 11.51',
            },
            {
              id: '6073299f-8282-4b2e-99c6-dc50df62b85b',
              name: 'Rubber Removal from Paving',
              tradeId: '32 01 11.52',
            },
            {
              id: 'a0957290-0fbe-4db7-9e6e-19d877b25a71',
              name: 'Paint Removal from Paving',
              tradeId: '32 01 11.53',
            },
            {
              id: 'f3a64863-13f6-44a6-ac66-c4480a5170bf',
              name: 'Flexible Paving Surface Treatment',
              tradeId: '32 01 13',
            },
            {
              id: '5d08144e-032a-43fd-bc41-85162380b135',
              name: 'Slurry Seal (Latex Modified)',
              tradeId: '32 01 13.61',
            },
            {
              id: '8dc1d02c-9b69-4000-9857-9f75d9ba54ce',
              name: 'Asphalt Surface Treatment',
              tradeId: '32 01 13.62',
            },
            {
              id: 'ad2fbf0f-bd28-425e-a056-627f78a1af2d',
              name: 'Flexible Paving Rehabilitation',
              tradeId: '32 01 16',
            },
            {
              id: '6e9b7f10-fb81-4cfa-a467-99662d2eb207',
              name: 'Cold Milling Asphalt Paving',
              tradeId: '32 01 16.71',
            },
            {
              id: 'd69291ec-31ae-45d4-b9c9-d2e65f67f886',
              name: 'Asphalt Paving Reuse',
              tradeId: '32 01 16.72',
            },
            {
              id: 'd79dc0e5-47f2-444e-9779-69be82e0f098',
              name: 'In Place Cold Reused Asphalt Paving',
              tradeId: '32 01 16.73',
            },
            {
              id: 'e1a424c2-2915-40e7-bbec-9ee45944048e',
              name: 'In Place Hot Reused Asphalt Paving',
              tradeId: '32 01 16.74',
            },
            {
              id: '71e364fc-536f-4a01-953a-dca7799c2ff5',
              name: 'Heater Scarifying of Asphalt Paving',
              tradeId: '32 01 16.75',
            },
            {
              id: '9be00dc4-50e2-4622-85ef-453ca22e509c',
              name: 'Flexible Paving Repair',
              tradeId: '32 01 17',
            },
            {
              id: '615bd20b-e9f8-4615-8e23-6bb6aa7a4e2c',
              name: 'Sealing Cracks in Asphalt Paving',
              tradeId: '32 01 17.61',
            },
            {
              id: '735e5097-2fc4-4d98-a01a-4ae390b3bc97',
              name: 'Stress-Absorbing Membrane Interlayer',
              tradeId: '32 01 17.62',
            },
            {
              id: '9ee9347e-41c4-4b88-9da0-0668db0e7a0e',
              name: 'Rigid Paving Surface Treatment',
              tradeId: '32 01 19',
            },
            {
              id: '14095736-ec5a-4603-9833-a6bcfcc465a4',
              name: 'Sealing of Joints in Rigid Paving',
              tradeId: '32 01 19.61',
            },
            {
              id: '0caa5816-5a7b-45f8-beea-7a90a1d5ed5b',
              name: 'Patching of Rigid Paving',
              tradeId: '32 01 19.62',
            },
            {
              id: '3f9e4c7a-0155-48b0-b21d-5fbc641b690f',
              name: 'Base Course Reconditioning',
              tradeId: '32 01 23',
            },
            {
              id: 'c0d71f92-7b61-45e8-afd9-601f9d2570e8',
              name: 'Rigid Paving Rehabilitation',
              tradeId: '32 01 26',
            },
            {
              id: 'bdc06aad-7936-4643-a883-d5c8780820bc',
              name: 'Grooving of Concrete Paving',
              tradeId: '32 01 26.71',
            },
            {
              id: '1679ffd8-9fb3-45be-827d-eb1f96a7ccb8',
              name: 'Grinding of Concrete Paving',
              tradeId: '32 01 26.72',
            },
            {
              id: 'b0b64859-4d56-4d05-8f74-f20294d5c6e1',
              name: 'Milling of Concrete Paving',
              tradeId: '32 01 26.73',
            },
            {
              id: '353c0a8f-b0b5-4e9e-8b97-18df5ca0b44d',
              name: 'Concrete Overlays',
              tradeId: '32 01 26.74',
            },
            {
              id: '4147dfe7-20ec-4fca-965d-dcf9ef88387f',
              name: 'Concrete Paving Reuse',
              tradeId: '32 01 26.75',
            },
            {
              id: 'fec95b2a-f334-43ef-b0be-31fb5b7afc5f',
              name: 'Rigid Paving Repair',
              tradeId: '32 01 29',
            },
            {
              id: '79f9495e-1dea-424a-af0a-8433ea871166',
              name: 'Partial Depth Patching of Rigid Paving',
              tradeId: '32 01 29.61',
            },
            {
              id: '71c427f6-1c91-4344-8712-c1c50ea009cb',
              name: 'Concrete Paving Raising',
              tradeId: '32 01 29.62',
            },
            {
              id: 'b7a365d9-3e71-4ca2-b7bf-2ef4c4367650',
              name: 'Subsealing and Stabilization',
              tradeId: '32 01 29.63',
            },
            {
              id: '3ecbee41-7702-4c4d-8d44-ec14f673e587',
              name: 'Operation and Maintenance of Site Improvements',
              tradeId: '32 01 30',
            },
            {
              id: 'da6c3ade-9470-4bec-bed3-f39ead2166f4',
              name: 'Snow Removal',
              tradeId: '32 01 30.13',
            },
            {
              id: 'fd6b6f50-9bf1-49d0-95d7-24c3655a3016',
              name: 'Operation and Maintenance of Irrigation',
              tradeId: '32 01 80',
            },
            {
              id: '3df6b91b-3605-4a93-99a1-4cda65c82cc2',
              name: 'Operation and Maintenance of Planting',
              tradeId: '32 01 90',
            },
            {
              id: '6b3bb48d-6bb8-410f-a059-7e95d83520bf',
              name: 'Fertilizing',
              tradeId: '32 01 90.13',
            },
            {
              id: '84a01fb8-c25c-4d6b-8fe1-ec9b058d6721',
              name: 'Amending Soils',
              tradeId: '32 01 90.16',
            },
            {
              id: '5880bd7d-444d-4f49-a9f0-3fadb0ca85c8',
              name: 'Mowing',
              tradeId: '32 01 90.19',
            },
            {
              id: '6e824e27-c5e0-4d2c-ba88-9090f032ef2e',
              name: 'Pruning',
              tradeId: '32 01 90.23',
            },
            {
              id: '5f58246e-8d8f-44e0-8410-0fa90fd2a625',
              name: 'Watering',
              tradeId: '32 01 90.26',
            },
            {
              id: '25557981-649e-44b3-9939-0d9f9c07a77c',
              name: 'Topsoil Preservation',
              tradeId: '32 01 90.29',
            },
            {
              id: '1b22e960-a436-4f9f-a30c-6a22b4d9daae',
              name: 'Tree and Shrub Preservation',
              tradeId: '32 01 90.33',
            },
          ],
          tradeId: '32 01 00',
        },
        {
          id: '2107c519-91f4-4b80-ae02-154acb359c8d',
          name: 'Common Work Results for Exterior Improvements',
          children: [
            {
              id: 'c8ed05ae-23de-47ae-8527-c029a891988c',
              name: 'Common Work Results for Exterior Improvements',
              tradeId: '32 05 00',
            },
            {
              id: '5ebecddd-42e1-4a56-bfbf-8de413330fbf',
              name: 'Soils for Exterior Improvements',
              tradeId: '32 05 13',
            },
            {
              id: '9350f47d-90f0-401a-978c-9b1cba010ee8',
              name: 'Aggregates for Exterior Improvements',
              tradeId: '32 05 16',
            },
            {
              id: 'aedc15c6-fb5e-415f-aca3-c2cd4886d7ab',
              name: 'Geosynthetics for Exterior Improvements',
              tradeId: '32 05 19',
            },
            {
              id: 'cfdc3da3-ddb3-4b56-a69a-03584a54bcbd',
              name: 'Geotextiles for Exterior Improvements',
              tradeId: '32 05 19.13',
            },
            {
              id: '63a5ca9d-d737-432b-83b2-f1e39431ea10',
              name: 'Geomembranes for Exterior Improvements',
              tradeId: '32 05 19.16',
            },
            {
              id: 'c0320ff3-a2b1-4578-9296-19fcdc6da231',
              name: 'Geogrids for Exterior Improvements',
              tradeId: '32 05 19.19',
            },
            {
              id: '85237e66-7fe6-4b25-999c-4f3a2e6b83eb',
              name: 'Cement and Concrete for Exterior Improvements',
              tradeId: '32 05 23',
            },
            {
              id: 'fa8f957d-258a-4b53-b164-a5c4c0ea8131',
              name: 'Common Work Results for Planting',
              tradeId: '32 05 33',
            },
          ],
          tradeId: '32 05 00',
        },
        {
          id: '796e1375-7187-4ef1-8b30-449111c514ee',
          name: 'Schedules for Exterior Improvements',
          children: [
            {
              id: '6471c770-d7c1-448f-a089-c55e09c4b1ef',
              name: 'Schedules for Exterior Improvements',
              tradeId: '32 06 00',
            },
            {
              id: 'b2fbe18f-541a-418f-9bae-1ffb00f91d5a',
              name: 'Schedules for Bases, Ballasts and Paving',
              tradeId: '32 06 10',
            },
            {
              id: '42931b39-cb2d-4ae4-8e9f-b9a89115f2ba',
              name: 'Pedestrian Walkway Schedule',
              tradeId: '32 06 10.13',
            },
            {
              id: 'd48f8979-d326-4fb2-8155-d7f075749a2d',
              name: 'Schedules for Site Improvements',
              tradeId: '32 06 30',
            },
            {
              id: 'ca2816f4-b1bf-47d8-86f5-78a1c49a491e',
              name: 'Retaining Wall Schedule',
              tradeId: '32 06 30.13',
            },
            {
              id: '547faca2-6431-46f4-a36e-b4e2503a18db',
              name: 'Schedules for Irrigation',
              tradeId: '32 06 80',
            },
            {
              id: '775632f8-a11a-44df-aa15-65a31b9b78c6',
              name: 'Irrigation Piping Schedule',
              tradeId: '32 06 80.13',
            },
            {
              id: 'c3e57073-fbbb-4a0e-83e4-f1bbe9493286',
              name: 'Schedules for Planting',
              tradeId: '32 06 90',
            },
            {
              id: '727c0cea-6c20-45bd-9c2d-071f1a67b879',
              name: 'Planting Schedule',
              tradeId: '32 06 90.13',
            },
          ],
          tradeId: '32 06 00',
        },
        {
          id: '4b4b91bf-6d59-427d-90c0-e6ec378b6d23',
          name: 'Commissioning of Exterior Improvements',
          children: [],
          tradeId: '32 08 00',
        },
        {
          id: 'd5bf1427-b20b-4619-93f6-1c1e4cb8a3f1',
          name: 'Bases, Ballasts and Paving',
          children: [],
          tradeId: '32 10 00',
        },
        {
          id: '05d59909-d225-485f-b543-c5c5b8afffed',
          name: 'Base Courses',
          children: [
            {
              id: '2cb89564-0f20-4309-af41-7cb9789090c4',
              name: 'Base Courses',
              tradeId: '32 11 00',
            },
            {
              id: '66fabf43-d465-42c4-a458-7ccf5612679d',
              name: 'Subgrade Modifications',
              tradeId: '32 11 13',
            },
            {
              id: '3f76d1a7-9e09-44a8-a54f-36264927cadd',
              name: 'Lime-Treated Subgrades',
              tradeId: '32 11 13.13',
            },
            {
              id: 'df5b9ad5-00eb-4b97-b91c-fe8bfaf05e92',
              name: 'Bituminous-Treated Subgrades',
              tradeId: '32 11 13.16',
            },
            {
              id: 'dd03289d-5ddc-4306-9976-4ff74e53cf99',
              name: 'Subbase Courses',
              tradeId: '32 11 16',
            },
            {
              id: '7ad57da9-41af-403b-95c5-a35e2a233409',
              name: 'Sand-Clay Subbase Courses',
              tradeId: '32 11 16.13',
            },
            {
              id: '57daf2ef-3fe6-447d-8e84-97560a713a47',
              name: 'Aggregate Subbase Courses',
              tradeId: '32 11 16.16',
            },
            {
              id: 'bb5da873-4e3d-482d-bd5f-22261a144a2a',
              name: 'Aggregate Base Courses',
              tradeId: '32 11 23',
            },
            {
              id: '6df1578b-f362-42e6-83b2-c54e82e23603',
              name: 'Sand-Clay Base Courses',
              tradeId: '32 11 23.13',
            },
            {
              id: 'ee4b8ffd-56a5-4482-be18-6e8356245863',
              name: 'Base Course Drainage Layers',
              tradeId: '32 11 23.23',
            },
            {
              id: 'a5476a25-1cbe-4994-bbfa-ac2d406bf3da',
              name: 'Asphaltic Base Courses',
              tradeId: '32 11 26',
            },
            {
              id: 'e932707a-5c5c-415f-acfa-768d1835f506',
              name: 'Plant Mix Asphaltic Base Courses',
              tradeId: '32 11 26.13',
            },
            {
              id: '792dc471-3a16-4495-bb89-32a001897b18',
              name: 'Road Mix Asphaltic Base Courses',
              tradeId: '32 11 26.16',
            },
            {
              id: 'c0e5e64f-0012-45be-818b-14e5c6d24960',
              name: 'Bituminous-Stabilized Base Courses',
              tradeId: '32 11 26.19',
            },
            {
              id: 'fd73c20d-e3e6-4210-b257-7e0fd5e9a7c1',
              name: 'Lime Treated Base Courses',
              tradeId: '32 11 29',
            },
            {
              id: '899e8a83-d497-46a2-b6c7-8191e590cb07',
              name: 'Lime-Fly Ash-Treated Base Courses',
              tradeId: '32 11 29.13',
            },
            {
              id: '56d16e66-4354-40d7-9854-4c70fc6d3d5c',
              name: 'Cement-Treated Base Courses',
              tradeId: '32 11 33',
            },
            {
              id: 'b249051d-bf5c-4488-a2aa-789f5a12d61e',
              name: 'Portland Cement-Stabilized Base Courses',
              tradeId: '32 11 33.13',
            },
            {
              id: '2be72fec-b6fe-44d5-ad85-374f891cea6b',
              name: 'Concrete Base Courses',
              tradeId: '32 11 36',
            },
            {
              id: '1fc77e2b-14fd-4b9e-a823-1fe0f762a320',
              name: 'Lean Concrete Base Courses',
              tradeId: '32 11 36.13',
            },
            {
              id: '9254f2ad-586c-439c-a509-dd9dcd76b043',
              name: 'Plain Cement Concrete Base Courses',
              tradeId: '32 11 36.16',
            },
            {
              id: '371f2393-85d2-4e9d-9f74-33669be39adb',
              name: 'Hydraulic Cement Concrete Base Courses',
              tradeId: '32 11 36.19',
            },
          ],
          tradeId: '32 11 00',
        },
        {
          id: '5036bdf9-6d98-4a22-b9d4-8ddf135e3cfc',
          name: 'Flexible Paving',
          children: [
            {
              id: 'd12b3704-bc03-4448-8a6c-1f2462b412d3',
              name: 'Flexible Paving',
              tradeId: '32 12 00',
            },
            {
              id: 'baf8694a-ccc6-43a1-bc56-af43948eddc4',
              name: 'Preparatory Coats',
              tradeId: '32 12 13',
            },
            {
              id: 'c51b7325-53ed-41c8-9a58-f1c4e0609887',
              name: 'Tack Coats',
              tradeId: '32 12 13.13',
            },
            {
              id: '28b69ab0-f596-40ed-ac86-b8efb8f25272',
              name: 'Asphaltic Tack Coats',
              tradeId: '32 12 13.16',
            },
            {
              id: 'b68766c9-ecc8-45b2-a55f-d8cb8e6fa681',
              name: 'Prime Coats',
              tradeId: '32 12 13.19',
            },
            {
              id: '7da07ddb-d751-45c3-957a-f2e33412bcc2',
              name: 'Asphaltic Prime Coats',
              tradeId: '32 12 13.23',
            },
            {
              id: '2b68fc3e-5d32-4f01-ae8e-1b236b4d3eea',
              name: 'Asphalt Paving',
              tradeId: '32 12 16',
            },
            {
              id: 'dc5af3f0-4080-43ed-8e10-3a196e0fcd02',
              name: 'Plant-Mix Asphalt Paving',
              tradeId: '32 12 16.13',
            },
            {
              id: '04c7bd38-f931-4d05-8b30-48a057008ef8',
              name: 'Road-Mix Asphalt Paving',
              tradeId: '32 12 16.16',
            },
            {
              id: '0a6667a7-a55b-4153-b120-0fcdf266f508',
              name: 'Cold-Mix Asphalt Paving',
              tradeId: '32 12 16.19',
            },
            {
              id: '46273bfc-fb66-4f6b-ab8f-ac68bf384b79',
              name: 'Reinforced Asphalt Paving',
              tradeId: '32 12 16.23',
            },
            {
              id: '2b34b875-3621-4ee0-9534-b8351712fd98',
              name: 'Fiber-Modified Asphalt Paving',
              tradeId: '32 12 16.26',
            },
            {
              id: 'c16748c3-0a93-40b5-a104-2de531d97873',
              name: 'Polymer-Modified Asphalt Paving',
              tradeId: '32 12 16.29',
            },
            {
              id: 'b70ea608-fe68-48d3-b980-a58e99d9da42',
              name: 'Granulated Rubber-Modified Asphalt Paving',
              tradeId: '32 12 16.33',
            },
            {
              id: '9f798d99-ecb7-447d-b4f0-aa90859b87d3',
              name: 'Athletic Asphalt Paving',
              tradeId: '32 12 16.36',
            },
            {
              id: 'd06c7e1d-d904-409e-b0fa-64e0feb134f2',
              name: 'Asphalt Paving Wearing Courses',
              tradeId: '32 12 19',
            },
            {
              id: '0910150d-fb3d-42b2-a1e8-8554dc9865b0',
              name: 'Road-Mix Asphalt Paving Wearing Courses',
              tradeId: '32 12 19.13',
            },
            {
              id: 'b4f200f6-73a3-4369-85f9-3ccf631785f5',
              name: 'Resin-Modified Asphalt Paving Wearing Courses',
              tradeId: '32 12 19.16',
            },
            {
              id: 'e58d2229-94f4-4535-843f-0c5db7ed2bc8',
              name: 'Porous Friction Asphalt Paving Wearing Courses',
              tradeId: '32 12 19.19',
            },
            {
              id: '06bd4731-0390-41dd-8a79-4ae43ae7c91c',
              name: 'Flexible Paving Surface Treatments',
              tradeId: '32 12 33',
            },
            {
              id: '1f6ba773-2929-4884-b9ce-c7f6c172cd0c',
              name: 'Seal Coats',
              tradeId: '32 12 36',
            },
            {
              id: '3bbfa6cd-cd04-47ea-bf82-2348ca9dc9e3',
              name: 'Asphaltic Seal and Fog Coats',
              tradeId: '32 12 36.13',
            },
            {
              id: '31f4c2d8-18a9-43b1-a8d4-a7a7f142ddee',
              name: 'Coal Tar Seal Coats',
              tradeId: '32 12 36.16',
            },
            {
              id: '30acf20d-85c5-496b-8a90-6273365c008c',
              name: 'Coal Tar Seal Coats with Unvulcanized Rubber',
              tradeId: '32 12 36.19',
            },
            {
              id: '36d4e193-81cf-4912-8372-2b9208451ce9',
              name: 'Fuel-Resistant Sealers',
              tradeId: '32 12 36.23',
            },
            {
              id: 'f225ca34-5414-44f9-bebc-2d7ff83b9012',
              name: 'Porous Flexible Paving',
              tradeId: '32 12 43',
            },
            {
              id: '56952bf2-ba6b-4da7-9d24-e0db58db12bc',
              name: 'Asphalt Paving Joint Sealants',
              tradeId: '32 12 73',
            },
          ],
          tradeId: '32 12 00',
        },
        {
          id: 'ec02b105-9f83-4e15-9064-3023a7c95219',
          name: 'Rigid Paving',
          children: [
            {
              id: 'c01d4642-498d-4d0a-8ad4-7e8e5f0ee412',
              name: 'Rigid Paving',
              tradeId: '32 13 00',
            },
            {
              id: 'c9e6354c-2fd0-44ee-a4ff-c983d2431cde',
              name: 'Concrete Paving',
              tradeId: '32 13 13',
            },
            {
              id: '87bca6fb-b62c-4776-ac53-e94823ef5e37',
              name: 'Exposed Aggregate Concrete Paving',
              tradeId: '32 13 13.13',
            },
            {
              id: '2a8b61b8-99bb-405d-a6d4-49608a1e3fe0',
              name: 'Power-Compacted Concrete Paving',
              tradeId: '32 13 13.16',
            },
            {
              id: '2dfe18ae-76b1-4a37-9f6d-57668ec29720',
              name: 'Prestressed Concrete Paving',
              tradeId: '32 13 13.19',
            },
            {
              id: 'b50e36b5-55d1-4447-a35b-fa54de0981ae',
              name: 'Concrete Paving Surface Treatment',
              tradeId: '32 13 13.23',
            },
            {
              id: '91749f3c-969a-4d63-9638-5dcff53da62d',
              name: 'Plain Concrete Paving',
              tradeId: '32 13 13.33',
            },
            {
              id: 'c9387bc4-0df2-4130-9029-34b0015395a3',
              name: 'Decorative Concrete Paving',
              tradeId: '32 13 16',
            },
            {
              id: '9fadbed8-c719-4fab-a68c-de2bec78ea5a',
              name: 'Patterned Concrete Paving',
              tradeId: '32 13 16.13',
            },
            {
              id: 'bbcea094-7024-4a6e-881b-bb236d38d0ba',
              name: 'Roller-Compacted Concrete Paving',
              tradeId: '32 13 16.16',
            },
            {
              id: 'b8e7c2d7-62fd-48dc-9f68-5cb5cac88914',
              name: 'Imprinted Concrete Paving',
              tradeId: '32 13 16.19',
            },
            {
              id: '5315bb1f-031c-461c-9230-48ab69e6dd96',
              name: 'Stamped Concrete Paving',
              tradeId: '32 13 16.23',
            },
            {
              id: 'dd9148e5-6194-4ea5-88da-2d9d0a06de95',
              name: 'Pervious Concrete Paving',
              tradeId: '32 13 43',
            },
            {
              id: '36dc33e3-8337-4a5e-9f19-791051b2152f',
              name: 'Concrete Paving Joint Sealants',
              tradeId: '32 13 73',
            },
            {
              id: '22e3dda6-2d41-4224-abe2-e8284f009533',
              name: 'Fuel-Resistant Concrete Paving Joint Sealants',
              tradeId: '32 13 73.13',
            },
            {
              id: '80cd675c-f229-420d-9c5f-0f8caf479deb',
              name: 'Field-Molded Concrete Paving Joint Sealants',
              tradeId: '32 13 73.16',
            },
            {
              id: '937009bc-489b-498d-9fc5-d4b0201260f0',
              name: 'Compression Concrete Paving Joint Sealants',
              tradeId: '32 13 73.19',
            },
          ],
          tradeId: '32 13 00',
        },
        {
          id: 'e942334a-cfa1-480c-bd6d-6445616a9299',
          name: 'Unit Paving',
          children: [
            {
              id: '4fc7d872-78cd-4660-a5ae-e49fc5c5d1bd',
              name: 'Unit Paving',
              tradeId: '32 14 00',
            },
            {
              id: '6a556ec2-cc50-4e1f-8e92-d93f4fb906d2',
              name: 'Precast Concrete Unit Paving',
              tradeId: '32 14 13',
            },
            {
              id: 'c6ccbfc6-bb57-4553-8dd8-fcda9b71a64d',
              name: 'Interlocking Precast Concrete Unit Paving',
              tradeId: '32 14 13.13',
            },
            {
              id: 'd5274510-2d08-478d-a37e-49e5420f9628',
              name: 'Precast Concrete Unit Paving Slabs',
              tradeId: '32 14 13.16',
            },
            {
              id: 'db07f6cf-a81e-414e-a3ed-5b73c47eab4c',
              name: 'Porous Precast Concrete Unit Paving',
              tradeId: '32 14 13.19',
            },
            {
              id: '71fa8717-6b95-4546-acb7-cd3c5bf09e0b',
              name: 'Brick Unit Paving',
              tradeId: '32 14 16',
            },
            {
              id: 'addc1a54-63de-4b71-a0ad-6820872f40ef',
              name: 'Asphalt Unit Paving',
              tradeId: '32 14 23',
            },
            {
              id: '028b3f6c-2a43-4382-a038-8c9e06f37403',
              name: 'Wood Paving',
              tradeId: '32 14 26',
            },
            {
              id: '35e9ee34-ddc9-4d11-8076-3b9681cf1939',
              name: 'Recycled-Rubber Paving',
              tradeId: '32 14 29',
            },
            {
              id: '65d469eb-c410-4d87-9424-e9a598299392',
              name: 'Stone Paving',
              tradeId: '32 14 40',
            },
            {
              id: '14c93276-cc2b-4f89-989f-dc17a8844179',
              name: 'Porous Unit Paving',
              tradeId: '32 14 43',
            },
          ],
          tradeId: '32 14 00',
        },
        {
          id: '5802a996-e3f7-4a8a-8c87-a947c3f783c1',
          name: 'Aggregate Surfacing',
          children: [
            {
              id: 'e93234e0-0036-4b95-b779-8c47016a976b',
              name: 'Aggregate Surfacing',
              tradeId: '32 15 00',
            },
            {
              id: '42a3fdec-75f5-4dfa-a5b6-f9e431502c25',
              name: 'Cinder Surfacing',
              tradeId: '32 15 13',
            },
            {
              id: 'cb10da1a-296f-485e-a502-8feccf1e2ce6',
              name: 'Crushed Stone Surfacing',
              tradeId: '32 15 40',
            },
          ],
          tradeId: '32 15 00',
        },
        {
          id: 'a2cc688a-347d-49a2-b47a-acd4c64bc5e2',
          name: 'Curbs, Gutters, Sidewalks and Driveways',
          children: [
            {
              id: 'acb43027-ce8e-4796-a95d-944192aa1708',
              name: 'Curbs, Gutters, Sidewalks and Driveways',
              tradeId: '32 16 00',
            },
            {
              id: '118be823-5153-442a-81f0-e62af95cdfc6',
              name: 'Curbs and Gutters',
              tradeId: '32 16 13',
            },
            {
              id: 'eb277ec8-eb15-4f29-b547-197ac894c829',
              name: 'Cast-In-Place Concrete Curbs and Gutters',
              tradeId: '32 16 13.13',
            },
            {
              id: '44c098d6-7a12-48ca-9832-58d0bf066e54',
              name: 'Steel Faced Curbs',
              tradeId: '32 16 13.16',
            },
            {
              id: '03a8c044-0155-4563-a1ab-18f041013475',
              name: 'Precast Concrete Curbs and Gutters',
              tradeId: '32 16 13.23',
            },
            {
              id: '3613b109-8f37-434a-a203-5fec25fa11fe',
              name: 'Asphalt Curbs',
              tradeId: '32 16 13.33',
            },
            {
              id: '77479ab5-41b3-4cd4-9952-b907bf6206bc',
              name: 'Stone Curbs',
              tradeId: '32 16 13.43',
            },
            {
              id: 'd634b8a7-1109-4f16-9c93-89a1c36d12cb',
              name: 'Sidewalks',
              tradeId: '32 16 23',
            },
            {
              id: 'a5b15a50-d0f2-47fd-bedf-29dbbc45e015',
              name: 'Driveways',
              tradeId: '32 16 33',
            },
          ],
          tradeId: '32 16 00',
        },
        {
          id: '8644570e-c85f-43a9-abc7-5d201c045577',
          name: 'Paving Specialties',
          children: [
            {
              id: '70c55f8c-daf4-497a-b9ba-1a1a25b212f1',
              name: 'Paving Specialties',
              tradeId: '32 17 00',
            },
            {
              id: '224ab1bc-4570-42e0-82e3-fad8801b03a0',
              name: 'Parking Bumpers',
              tradeId: '32 17 13',
            },
            {
              id: '9179097e-eaa6-48a9-a082-74a1fe7d3343',
              name: 'Metal Parking Bumpers',
              tradeId: '32 17 13.13',
            },
            {
              id: '24f85845-c7ce-45e5-8e6c-119f252917b9',
              name: 'Plastic Parking Bumpers',
              tradeId: '32 17 13.16',
            },
            {
              id: 'a44fa384-efd4-45e2-9971-cf4e61a0046a',
              name: 'Precast Concrete Parking Bumpers',
              tradeId: '32 17 13.19',
            },
            {
              id: 'd8a428de-77dc-41fd-9377-be4dc5256624',
              name: 'Rubber Parking Bumpers',
              tradeId: '32 17 13.23',
            },
            {
              id: '05618692-b0a5-40c3-99b8-569adb06e2b2',
              name: 'Wood Parking Bumpers',
              tradeId: '32 17 13.26',
            },
            {
              id: '8aa7ae53-9af6-4e22-aa92-47387ef751b5',
              name: 'Speed Bumps',
              tradeId: '32 17 16',
            },
            {
              id: 'cefa6821-44e7-45fe-896a-ef5e4354dc29',
              name: 'Pavement Markings',
              tradeId: '32 17 23',
            },
            {
              id: 'c2d74ae2-283c-44bf-9f9e-c664b1af6669',
              name: 'Painted Pavement Markings',
              tradeId: '32 17 23.13',
            },
            {
              id: '1ed7d2e5-8615-4293-8444-7d6b2e36bdaa',
              name: 'Raised Pavement Markings',
              tradeId: '32 17 23.23',
            },
            {
              id: 'c43ed69d-f435-4344-aa79-18adf884ba40',
              name: 'Plastic Pavement Markings',
              tradeId: '32 17 23.33',
            },
            {
              id: 'e8e121ab-777b-4f4e-b2e2-b579486f1fd3',
              name: 'Tactile Warning Surfacing',
              tradeId: '32 17 26',
            },
            {
              id: 'c9df30f4-4ce5-4696-b531-1ef6fdebc68c',
              name: 'Pavement Snow Melting Systems',
              tradeId: '32 17 43',
            },
          ],
          tradeId: '32 17 00',
        },
        {
          id: 'd60fc86f-4fa3-42c0-ba58-1580acbc23b6',
          name: 'Athletic and Recreational Surfacing',
          children: [
            {
              id: 'eb4e46f6-71bf-482e-8cda-7c6e81877090',
              name: 'Athletic and Recreational Surfacing',
              tradeId: '32 18 00',
            },
            {
              id: '71fae01b-d4de-4c68-8cbb-23a373c3a82f',
              name: 'Synthetic Grass Surfacing',
              tradeId: '32 18 13',
            },
            {
              id: '8155b94a-a993-4f9c-b5be-b92fd8fbd02a',
              name: 'Synthetic Resilient Surfacing',
              tradeId: '32 18 16',
            },
            {
              id: '443fe58d-1aae-4d11-9b20-3a861406b723',
              name: 'Playground Protective Surfacing',
              tradeId: '32 18 16.13',
            },
            {
              id: '3bb3b328-cc7e-47d8-ac0f-a74c56838064',
              name: 'Athletic Surfacing',
              tradeId: '32 18 23',
            },
            {
              id: 'd84b5ed0-c861-4920-b902-5b0c1334f5a0',
              name: 'Baseball Field Surfacing',
              tradeId: '32 18 23.13',
            },
            {
              id: '6b1b0484-e893-4d15-85d3-322df9563be1',
              name: 'Natural Baseball Field Surfacing',
              tradeId: '32 18 23.16',
            },
            {
              id: 'e3834872-47f4-44e3-a213-d9b2bfcf58e7',
              name: 'Synthetic Baseball Field Surfacing',
              tradeId: '32 18 23.19',
            },
            {
              id: '8981d49f-7652-4729-8308-bacd2180acbd',
              name: 'Field Sport Surfacing',
              tradeId: '32 18 23.23',
            },
            {
              id: '8bc42737-ee78-4cfb-954f-19bd5479f884',
              name: 'Natural Field Sport Surfacing',
              tradeId: '32 18 23.26',
            },
            {
              id: 'eedda57a-48e0-4ccd-9208-2e4fbc5a209a',
              name: 'Synthetic Field Sport Surfacing',
              tradeId: '32 18 23.29',
            },
            {
              id: 'a3a9d4ee-ea7e-47b1-ae98-3bd4c02338f3',
              name: 'Running Track Surfacing',
              tradeId: '32 18 23.33',
            },
            {
              id: '58fe554c-c908-45eb-a4ab-74317f4f1b76',
              name: 'Natural Running Track Surfacing',
              tradeId: '32 18 23.36',
            },
            {
              id: '0b062a8a-d51f-4dc1-a066-e9b0657a2543',
              name: 'Synthetic Running Track Surfacing',
              tradeId: '32 18 23.39',
            },
            {
              id: '27772a5b-49ac-4a47-9d43-f8dd9a40b698',
              name: 'Recreational Court Surfacing',
              tradeId: '32 18 23.43',
            },
            {
              id: 'e3a16807-64e8-468c-8c8e-648660c29130',
              name: 'Tennis Court Surfacing',
              tradeId: '32 18 23.53',
            },
            {
              id: '4e8514bf-11d8-4dbd-aed3-e70c1cb8049d',
              name: 'Natural Tennis Court Surfacing',
              tradeId: '32 18 23.56',
            },
            {
              id: '3abd9c8e-76a3-4b0a-8bea-ef2eee7f56ae',
              name: 'Synthetic Tennis Court Surfacing',
              tradeId: '32 18 23.59',
            },
          ],
          tradeId: '32 18 00',
        },
        {
          id: '24251b0f-b264-4499-bc96-5212e84f776d',
          name: 'Site Improvements',
          children: [],
          tradeId: '32 30 00',
        },
        {
          id: 'b30e0935-f0f8-4c88-a23e-a4144b125acc',
          name: 'Fences and Gates',
          children: [
            {
              id: 'a41f45e3-849b-448d-9215-d593188f543d',
              name: 'Fences and Gates',
              tradeId: '32 31 00',
            },
            {
              id: '34cb7ec5-ddee-4d62-b980-c0530e667119',
              name: 'Gate Operators',
              tradeId: '32 31 11',
            },
            {
              id: '237d84b7-7917-4396-bb32-ec7c1f44ef5b',
              name: 'Chain Link Fences and Gates',
              tradeId: '32 31 13',
            },
            {
              id: '9be416b6-fb16-4dde-abf2-8aed41d8f6eb',
              name: 'Recreational Court Fences and Gates',
              tradeId: '32 31 13.23',
            },
            {
              id: 'd033681f-8a3d-4429-98c3-62422e2012a1',
              name: 'Tennis Court Fences and Gates',
              tradeId: '32 31 13.26',
            },
            {
              id: '3a9cb5b0-ea61-4df5-b2da-89677efb83e0',
              name: 'Tennis Court Wind Breaker',
              tradeId: '32 31 13.29',
            },
            {
              id: 'a4b51ed1-879c-4b0b-b8cc-a339dbb465e3',
              name: 'Chain Link Backstops',
              tradeId: '32 31 13.33',
            },
            {
              id: 'ef66beda-41b0-4656-b98a-99d5bb282de6',
              name: 'High-Security Chain Link Fences and Gates',
              tradeId: '32 31 13.53',
            },
            {
              id: 'e7fc7d7e-bd5c-4cef-bf18-5256b69d7c7b',
              name: 'Welded Wire Fences and Gates',
              tradeId: '32 31 16',
            },
            {
              id: '4afecc22-c329-4623-b108-c5b7f30dc9e9',
              name: 'Expanded Metal Fences and Gates',
              tradeId: '32 31 17',
            },
            {
              id: '15e36358-22a2-4999-a1b0-2a255d2fa085',
              name: 'Decorative Metal Fences and Gates',
              tradeId: '32 31 19',
            },
            {
              id: '1e519961-9a4d-4a55-865e-0f1df4ff48f3',
              name: 'Plastic Fences and Gates',
              tradeId: '32 31 23',
            },
            {
              id: '7a116dc8-da48-47a0-a53f-8b8f13aa3f19',
              name: 'Wire Fences and Gates',
              tradeId: '32 31 26',
            },
            {
              id: '3a23a911-88ec-4306-ba06-bd64295231a3',
              name: 'Wood Fences and Gates',
              tradeId: '32 31 29',
            },
            {
              id: 'de2c4647-4b07-49d3-84bd-6374ceb22e3d',
              name: 'Composite Fences and Gates',
              tradeId: '32 31 32',
            },
            {
              id: 'c7e19a7c-022b-4f5e-8627-a6298d604e8f',
              name: 'Cattle Guards',
              tradeId: '32 31 53',
            },
          ],
          tradeId: '32 31 00',
        },
        {
          id: 'b57faac2-8276-46f8-a7b4-9e592e3895d5',
          name: 'Retaining Walls',
          children: [
            {
              id: '8fe60961-4469-4e28-98a7-ab56799a9fde',
              name: 'Retaining Walls',
              tradeId: '32 32 00',
            },
            {
              id: '64e79b29-ed64-4b82-b81b-b824418dc801',
              name: 'Cast-in-Place Concrete Retaining Walls',
              tradeId: '32 32 13',
            },
            {
              id: '70e9af63-272d-4ad5-af0d-ef0fd85cd441',
              name: 'Precast Concrete Retaining Walls',
              tradeId: '32 32 16',
            },
            {
              id: '990a7cd0-b1b7-4ced-b71c-57adf16c1fad',
              name: 'Unit Masonry Retaining Walls',
              tradeId: '32 32 19',
            },
            {
              id: 'bc3ea3d8-f853-4a84-a8c1-46951869bccb',
              name: 'Segmental Retaining Walls',
              tradeId: '32 32 23',
            },
            {
              id: 'ca59f0be-184f-463c-ac07-372bc5e08c08',
              name: 'Segmental Concrete Unit Masonry Retaining Walls',
              tradeId: '32 32 23.13',
            },
            {
              id: '64bee3ef-9f9d-4cf6-99c2-cf3387d256a1',
              name: 'Manufactured Modular Walls',
              tradeId: '32 32 23.16',
            },
            {
              id: '94a9edb8-9735-476e-96e4-ed112ee2a833',
              name: 'Metal Crib Retaining Walls',
              tradeId: '32 32 26',
            },
            {
              id: '6842c7cc-1272-4bcf-b20b-3ac08245001f',
              name: 'Timber Retaining Walls',
              tradeId: '32 32 29',
            },
            {
              id: '7cd456ff-bbd6-4ce3-ad57-694f8ba5ea2d',
              name: 'Reinforced Soil Retaining Walls',
              tradeId: '32 32 34',
            },
            {
              id: 'dcaa635f-0927-4797-aefd-f487b1fd4300',
              name: 'Gabion Retaining Walls',
              tradeId: '32 32 36',
            },
            {
              id: '6da47cd0-0a5b-4e60-8f5d-208a51f74aa1',
              name: 'Soldier-Beam Retaining Walls',
              tradeId: '32 32 43',
            },
            {
              id: '2c2e63ca-c3ba-4837-82bd-8ff659bfee9e',
              name: 'Stone Retaining Walls',
              tradeId: '32 32 53',
            },
          ],
          tradeId: '32 32 00',
        },
        {
          id: '506a1f30-7b5d-4d3d-ac32-98bb952247aa',
          name: 'Fabricated Bridges',
          children: [
            {
              id: '6fa167e0-f5d0-455e-a1b7-35c18fa3ebc9',
              name: 'Fabricated Bridges',
              tradeId: '32 34 00',
            },
            {
              id: '5abfa257-1996-4ea8-978d-bb6fe4ba101a',
              name: 'Fabricated Pedestrian Bridges',
              tradeId: '32 34 13',
            },
            {
              id: 'a8137db7-5650-449e-8417-53149f775fa3',
              name: 'Fabricated Roadway Bridges',
              tradeId: '32 34 23',
            },
            {
              id: 'f77938d8-bb96-4f35-a6f4-2c9461043807',
              name: 'Fabricated Railway Bridges',
              tradeId: '32 34 33',
            },
          ],
          tradeId: '32 34 00',
        },
        {
          id: 'd98ddd8f-b3f1-4d01-8140-5de65ed40e63',
          name: 'Screening Devices',
          children: [
            {
              id: '89ad577e-a99b-44af-b1fd-b80d22d35c01',
              name: 'Screening Devices',
              tradeId: '32 35 00',
            },
            {
              id: '1505648f-c026-4ac3-b5be-d32cc813fd82',
              name: 'Screens and Louvers',
              tradeId: '32 35 13',
            },
            {
              id: '78c1646f-b658-4398-9660-b1fc7cc96f5f',
              name: 'Sound Barriers',
              tradeId: '32 35 16',
            },
          ],
          tradeId: '32 35 00',
        },
        {
          id: 'dabef235-82ea-4476-86c0-a2cb3997ddd6',
          name: 'Manufactured Site Specialties',
          children: [
            {
              id: '4afd65ee-b163-4452-8a0c-140b7b60e6f3',
              name: 'Manufactured Site Specialties',
              tradeId: '32 39 00',
            },
            {
              id: 'a28a96b8-8ec2-41ae-a6d5-51c0d648178c',
              name: 'Manufactured Metal Bollards',
              tradeId: '32 39 13',
            },
            {
              id: 'ba1aa504-892e-4f75-bbcb-229064e35a42',
              name: 'Artificial Rock Fabrications',
              tradeId: '32 39 33',
            },
          ],
          tradeId: '32 39 00',
        },
        {
          id: '42128d2e-7ff8-408c-aec2-fa55352cc3a3',
          name: 'Wetlands',
          children: [],
          tradeId: '32 70 00',
        },
        {
          id: '61cebc3e-2813-4cce-8e87-d17fcc1ddbd7',
          name: 'Constructed Wetlands',
          children: [],
          tradeId: '32 71 00',
        },
        {
          id: 'c27ff1c5-9904-42bc-9428-5c58a157dda3',
          name: 'Wetlands Restoration',
          children: [],
          tradeId: '32 72 00',
        },
        {
          id: '330b8afd-1fce-4581-af5f-344b37d37b8a',
          name: 'Irrigation',
          children: [],
          tradeId: '32 80 00',
        },
        {
          id: 'dd289d83-095c-47f9-acb5-932be6df9d34',
          name: 'Irrigation Pumps',
          children: [],
          tradeId: '32 82 00',
        },
        {
          id: '313d511b-5624-4d52-905a-919dfad1feac',
          name: 'Planting Irrigation',
          children: [
            {
              id: '812a31e6-4505-4691-a55b-50295f109915',
              name: 'Planting Irrigation',
              tradeId: '32 84 00',
            },
            {
              id: '27110f2a-115b-4dc2-80ab-8e1fa5070b20',
              name: 'Drip Irrigation',
              tradeId: '32 84 13',
            },
            {
              id: 'd7902933-b99a-4d7e-8701-34c0740d34db',
              name: 'Underground Sprinklers',
              tradeId: '32 84 23',
            },
          ],
          tradeId: '32 84 00',
        },
        {
          id: 'e162471f-f847-487c-80f6-6c9971d68a5b',
          name: 'Agricultural Irrigation',
          children: [],
          tradeId: '32 86 00',
        },
        {
          id: '332c39b6-c3dc-4f99-acd9-ed208ff356d4',
          name: 'Planting',
          children: [],
          tradeId: '32 90 00',
        },
        {
          id: 'c77d4350-7a6b-4fca-bdb0-0aa4ed1c46a3',
          name: 'Planting Preparation',
          children: [
            {
              id: '3dd1e6e9-1c9e-47c7-a160-8ac243f8f6c6',
              name: 'Planting Preparation',
              tradeId: '32 91 00',
            },
            {
              id: 'd0b3ce24-7e6d-4c86-931d-f46588a0f636',
              name: 'Soil Preparation',
              tradeId: '32 91 13',
            },
            {
              id: 'f6d1e4eb-a104-4091-a728-9cba757152b2',
              name: 'Hydro-Punching',
              tradeId: '32 91 13.13',
            },
            {
              id: '698fb85b-3af0-40a9-8168-e52252bb312c',
              name: 'Mulching',
              tradeId: '32 91 13.16',
            },
            {
              id: 'a38002e0-c48c-420b-aedd-bab58f1b99ec',
              name: 'Planting Soil Mixing',
              tradeId: '32 91 13.19',
            },
            {
              id: '473b39ba-b613-4b0d-8e08-4d8470cfaa11',
              name: 'Structural Soil Mixing',
              tradeId: '32 91 13.23',
            },
            {
              id: '8fcc20fc-0746-4937-b6fd-e8e0b6a208bb',
              name: 'Planting Beds',
              tradeId: '32 91 13.26',
            },
            {
              id: '809a5750-81ce-448b-b6b2-090c11997174',
              name: 'Planting Soil Stabilization',
              tradeId: '32 91 16',
            },
            {
              id: 'b9d289a8-9cf6-4940-87fd-e8d0b699a48c',
              name: 'Blanket Planting Soil Stabilization',
              tradeId: '32 91 16.13',
            },
            {
              id: '055842d1-9d5e-4c45-b435-d02035870ba7',
              name: 'Mat Planting Soil Stabilization',
              tradeId: '32 91 16.16',
            },
            {
              id: '8eb6accf-6234-4a34-9f65-211b9731b04c',
              name: 'Netting Planting Soil Stabilization',
              tradeId: '32 91 16.19',
            },
            {
              id: '8690463c-f9ac-461e-b31e-9396ed584fad',
              name: 'Landscape Grading',
              tradeId: '32 91 19',
            },
            {
              id: '0a3709e1-76c5-4a12-a415-9f6421449874',
              name: 'Topsoil Placement and Grading',
              tradeId: '32 91 19.13',
            },
          ],
          tradeId: '32 91 00',
        },
        {
          id: '559e406c-83ea-43a8-90b7-b4112e81bb27',
          name: 'Turf and Grasses',
          children: [
            {
              id: 'bf19e331-9cde-42e2-8fcb-fc4f44355e6d',
              name: 'Turf and Grasses',
              tradeId: '32 92 00',
            },
            {
              id: '39bead8f-a6bf-44bc-94bf-48cdbfdfdbda',
              name: 'Hydro-Mulching',
              tradeId: '32 92 13',
            },
            {
              id: '2c767936-78db-499d-a91a-4bd7f763ce46',
              name: 'Plugging',
              tradeId: '32 92 16',
            },
            {
              id: '42feebe3-3c17-4fdc-a618-9b4b8ae5cc64',
              name: 'Seeding',
              tradeId: '32 92 19',
            },
            {
              id: '205a31a4-55b7-4f7c-a10d-cedde1c3286e',
              name: 'Mechanical Seeding',
              tradeId: '32 92 19.13',
            },
            {
              id: '9d342fd8-8f7f-45a8-9080-abec82e666f3',
              name: 'Hydraulic Seeding',
              tradeId: '32 92 19.16',
            },
            {
              id: 'ea18b8dd-2319-4563-92f6-3d0ce214ff2d',
              name: 'Sodding',
              tradeId: '32 92 23',
            },
            {
              id: '3bfec8fb-e5ac-4a0a-b1ab-d4f9ea9bd608',
              name: 'Sprigging',
              tradeId: '32 92 26',
            },
            {
              id: '4e0291d8-6318-4a72-9911-89633ef41c77',
              name: 'Stolonizing',
              tradeId: '32 92 26.13',
            },
          ],
          tradeId: '32 92 00',
        },
        {
          id: 'a6790047-d14f-439b-acde-f9f92e320d05',
          name: 'Plants',
          children: [
            {
              id: '74fd62a2-b4c4-4978-a5b8-88ab38278737',
              name: 'Plants',
              tradeId: '32 93 00',
            },
            {
              id: '20ce3aa0-c611-47fc-a7ea-931fdb2c2e01',
              name: 'Ground Covers',
              tradeId: '32 93 13',
            },
            {
              id: 'b96eed93-f2a4-4162-9574-d25966af52d1',
              name: 'Plants and Bulbs',
              tradeId: '32 93 23',
            },
            {
              id: '4535e4f4-f9f6-430f-ac34-d28b6cb6e2d8',
              name: 'Shrubs',
              tradeId: '32 93 33',
            },
            {
              id: '0d18fd1c-b760-4936-b80d-e5b6c75c579d',
              name: 'Trees',
              tradeId: '32 93 43',
            },
            {
              id: '6dfeafdd-40ad-42e7-a167-059fc18c2f19',
              name: 'Exterior Artificial Plants',
              tradeId: '32 93 93',
            },
          ],
          tradeId: '32 93 00',
        },
        {
          id: 'e391db45-159e-4da0-929a-2c7656da2c9b',
          name: 'Planting Accessories',
          children: [
            {
              id: '3b260d43-91c5-4dcb-bc3b-181f3c785c69',
              name: 'Planting Accessories',
              tradeId: '32 94 00',
            },
            {
              id: 'fe32cda0-4a88-47d6-8d66-f637bd67586a',
              name: 'Landscape Edging',
              tradeId: '32 94 13',
            },
            {
              id: '45632685-fa5f-4376-ba36-bb7db5c33462',
              name: 'Landscape Timbers',
              tradeId: '32 94 16',
            },
            {
              id: '9749c195-f2c4-4285-8b0a-95458e08607d',
              name: 'Landscape Surfacing',
              tradeId: '32 94 19',
            },
            {
              id: 'c872316d-47d5-407a-90b5-4b1fd9307f1d',
              name: 'Planters',
              tradeId: '32 94 33',
            },
            {
              id: 'ac7ebfe1-ec99-4bf3-bbef-9041c974f016',
              name: 'Tree Grates',
              tradeId: '32 94 43',
            },
            {
              id: 'a1425443-9966-4538-aec4-19f012d8555e',
              name: 'Tree Grids',
              tradeId: '32 94 46',
            },
          ],
          tradeId: '32 94 00',
        },
        {
          id: '05136fa7-3bfc-48d0-a654-b7ab9bd0c533',
          name: 'Exterior Planting Support Structures',
          children: [],
          tradeId: '32 95 00',
        },
        {
          id: '41c5a360-d3d7-47c1-85bf-31e6ec271bc7',
          name: 'Transplanting',
          children: [
            {
              id: '19e357a1-83c3-4be5-9aac-fe42669fc187',
              name: 'Transplanting',
              tradeId: '32 96 00',
            },
            {
              id: '97b2aa7c-cbfa-47e9-be2a-eec5cd7b3491',
              name: 'Ground Cover Transplanting',
              tradeId: '32 96 13',
            },
            {
              id: '879320d8-851b-4f82-8c92-6c4d5bb23372',
              name: 'Plant and Bulb Transplanting',
              tradeId: '32 96 23',
            },
            {
              id: 'd902f0ac-c681-41f8-83f3-52160be60c8d',
              name: 'Shrub Transplanting',
              tradeId: '32 96 33',
            },
            {
              id: '3f5ac4cb-4110-43ac-b2f3-5b8dd1cc3366',
              name: 'Tree Transplanting',
              tradeId: '32 96 43',
            },
          ],
          tradeId: '32 96 00',
        },
      ],
      tradeId: '32 00 00',
    },
    {
      id: 'f6a1e693-4c0f-427d-978a-e15e3cfa4d61',
      name: 'Utilities',
      children: [
        {
          id: '13fe88c5-f370-4d03-a6fe-d2cc9a39d2f6',
          name: 'Utilities',
          children: [],
          tradeId: '33 00 00',
        },
        {
          id: 'f5f232e9-b69a-46a3-8a8d-2f6e5b008325',
          name: 'Operation and Maintenance of Utilities',
          children: [
            {
              id: 'd969ac6a-1223-40e6-8b87-4f450e669022',
              name: 'Operation and Maintenance of Utilities',
              tradeId: '33 01 00',
            },
            {
              id: '3634aa8d-d893-4a56-8ecb-7f90bb9422d2',
              name: 'Operation and Maintenance of Water Utilities',
              tradeId: '33 01 10',
            },
            {
              id: '014a48f1-055e-427f-bfc9-075172ab340a',
              name: 'Water Utility Storage Tank Rehabilitation',
              tradeId: '33 01 10.71',
            },
            {
              id: '08c17d4f-6b04-4afb-8763-e2e3c5f06fa4',
              name: 'Operation and Maintenance of Wells',
              tradeId: '33 01 20',
            },
            {
              id: '21f6c833-f006-465a-8b9e-1f71285e4587',
              name: 'Operation and Maintenance of Sewer Utilities',
              tradeId: '33 01 30',
            },
            {
              id: 'a9e8fd6b-15bf-4d3d-8114-d0c558c1c2aa',
              name: 'Sewer and Manhole Testing',
              tradeId: '33 01 30.13',
            },
            {
              id: '192b7f48-b406-4c31-89dd-1b3f3eef3d28',
              name: 'TV Inspection of Sewer Pipelines',
              tradeId: '33 01 30.16',
            },
            {
              id: 'c79f3838-cd85-4372-a84a-d9eaabec350a',
              name: 'Maintenance of Sewer Utilities',
              tradeId: '33 01 30.51',
            },
            {
              id: 'd1a274b9-f20c-4544-ab45-3e42daefeeff',
              name: 'Pond and Reservoir Maintenance',
              tradeId: '33 01 30.52',
            },
            {
              id: 'ac27be23-790e-4eea-9911-7a0146c03976',
              name: 'Sewer and Pipe Joint Sealing',
              tradeId: '33 01 30.61',
            },
            {
              id: '5036936e-a200-4c39-b48d-77d5ef9f10ac',
              name: 'Manhole Grout Sealing',
              tradeId: '33 01 30.62',
            },
            {
              id: 'f0507216-9994-4b6f-a111-5fca3bf18d96',
              name: 'Rehabilitation of Sewer Utilities',
              tradeId: '33 01 30.71',
            },
            {
              id: '317f2f68-089b-4849-9ddb-4affeb855dc8',
              name: 'Relining Sewers',
              tradeId: '33 01 30.72',
            },
            {
              id: '50060211-ee84-4c8b-8dd1-8cd5fa574e26',
              name: 'Operation and Maintenance of Fuel Distribution Lines',
              tradeId: '33 01 50',
            },
            {
              id: 'ed391c7b-e061-4bfb-825c-6587abdf3b79',
              name: 'Cleaning Fuel-Storage Tanks',
              tradeId: '33 01 50.51',
            },
            {
              id: '881a5df4-185a-476a-95af-7c6ffede23f6',
              name: 'Lining of Steel Fuel-Storage Tanks',
              tradeId: '33 01 50.71',
            },
            {
              id: '5408e8ed-9b67-457f-8b53-fbce4fed701f',
              name: 'Operation and Maintenance of Hydronic and Steam Energy Utilities',
              tradeId: '33 01 60',
            },
            {
              id: 'ede59137-cfcc-4023-81b2-550ba9e7e2a1',
              name: 'Operation and Maintenance of Electrical Utilities',
              tradeId: '33 01 70',
            },
            {
              id: 'd215647e-17b8-430f-8a77-49d98f234d4d',
              name: 'Operation and Maintenance of Communications Utilities',
              tradeId: '33 01 80',
            },
          ],
          tradeId: '33 01 00',
        },
        {
          id: 'f04b4147-1da6-4066-8552-12c45b6d9154',
          name: 'Common Work Results for Utilities',
          children: [
            {
              id: 'e30a8479-d62d-4390-b429-c802c4211c65',
              name: 'Common Work Results for Utilities',
              tradeId: '33 05 00',
            },
            {
              id: '90ba8626-4408-45bf-857e-b90fd872ce73',
              name: 'Manholes and Structures',
              tradeId: '33 05 13',
            },
            {
              id: '2a8ba003-8c6a-42cf-8eb6-6e78b3a85353',
              name: 'Manhole Grade Adjustment',
              tradeId: '33 05 13.13',
            },
            {
              id: '28ec69db-d457-42b6-ac51-9a2cde016cfe',
              name: 'Utility Structures',
              tradeId: '33 05 16',
            },
            {
              id: '05b384f6-40d3-4833-88f8-daf20fdc8ad8',
              name: 'Precast Concrete Utility Structures',
              tradeId: '33 05 16.13',
            },
            {
              id: '4ba6e248-fa5c-4d57-957a-ca8787de7727',
              name: 'Rebuilding Utility Structures',
              tradeId: '33 05 16.53',
            },
            {
              id: 'd95cd167-cb1a-4ca7-aac5-791af7bc7955',
              name: 'Pressure Piping Tied Joint Restraint System',
              tradeId: '33 05 19',
            },
            {
              id: '72a78484-7b17-4c8f-aaa0-92551f7f50b8',
              name: 'Trenchless Utility Installation',
              tradeId: '33 05 23',
            },
            {
              id: '76e73891-6b93-407a-b19e-0457eb3b3513',
              name: 'Utility Horizontal Directional Drilling',
              tradeId: '33 05 23.13',
            },
            {
              id: '05883820-9b7b-44c0-a9e5-021af4abefa8',
              name: 'Utility Pipe Jacking',
              tradeId: '33 05 23.16',
            },
            {
              id: '1147d70a-4d2e-4285-a88d-e1023ab37c89',
              name: 'Microtunneling',
              tradeId: '33 05 23.19',
            },
            {
              id: 'e13fab78-9652-4d1d-acb1-53bb71c173cb',
              name: 'Utility Pipe Ramming',
              tradeId: '33 05 23.23',
            },
            {
              id: '08d8f9ec-f6a9-4e4f-acbe-9b4fd8d35bc8',
              name: 'Utility Impact Moling',
              tradeId: '33 05 23.26',
            },
            {
              id: '92b2a218-246d-419a-80e8-9e31f94e92d7',
              name: 'Cable Trenching and Plowing',
              tradeId: '33 05 23.29',
            },
            {
              id: '7ff845c6-8fd1-430d-9f86-15c1a1c71574',
              name: 'Utility Identification',
              tradeId: '33 05 26',
            },
            {
              id: 'e597d582-2cc8-425f-ba9b-fc1132a5a686',
              name: 'Utility Identification Signs',
              tradeId: '33 05 26.13',
            },
            {
              id: '0e1723d9-8073-4b94-8612-8951e0771633',
              name: 'Utility Identification Markers',
              tradeId: '33 05 26.16',
            },
            {
              id: 'f992b025-d818-48e3-92a6-4c540de5f28f',
              name: 'Utility Identification Flags',
              tradeId: '33 05 26.19',
            },
            {
              id: 'f01e5155-931f-47e4-8b84-3e5e8c18e40f',
              name: 'Utility Identification Trace Wires',
              tradeId: '33 05 26.23',
            },
          ],
          tradeId: '33 05 00',
        },
        {
          id: '391b496f-ac7e-49b6-b251-4c67b4ab79d0',
          name: 'Schedules for Utilities',
          children: [
            {
              id: 'b87eaf00-d20d-4445-b59b-0787bf3895d4',
              name: 'Schedules for Utilities',
              tradeId: '33 06 00',
            },
            {
              id: '1aa8ee28-ac99-4d66-9f52-5e12063fe0b4',
              name: 'Schedules for Water Utilities',
              tradeId: '33 06 10',
            },
            {
              id: 'ab3cf3c3-712b-41e4-9941-a47681a059a0',
              name: 'Schedules for Wells',
              tradeId: '33 06 20',
            },
            {
              id: '8996db56-ce48-4265-b3a2-d1caabb1e871',
              name: 'Schedules for Sanitary Sewerage Utilities',
              tradeId: '33 06 30',
            },
            {
              id: '47225c2b-d965-4d8a-835c-01071393972e',
              name: 'Schedules for Storm Drainage Utilities',
              tradeId: '33 06 40',
            },
            {
              id: 'e948d9df-0286-48bf-8a98-e611daf002fc',
              name: 'Storm Drainage Schedule',
              tradeId: '33 06 40.13',
            },
            {
              id: '840b48d9-d8ed-47df-bbae-5ed360d347b6',
              name: 'Schedules for Fuel Distribution Utilities',
              tradeId: '33 06 50',
            },
            {
              id: 'ad849ef6-5468-47fd-89b9-7bbd27ac0c8c',
              name: 'Schedules for Hydronic and Steam Energy Utilities',
              tradeId: '33 06 60',
            },
            {
              id: '60f33939-6093-4c64-9979-5100eb2ddffc',
              name: 'Schedules for Electrical Utilities',
              tradeId: '33 06 70',
            },
            {
              id: '756b03ee-9a3b-4c4e-b450-cad30a05a6b3',
              name: 'Schedules for Communications Utilities',
              tradeId: '33 06 80',
            },
          ],
          tradeId: '33 06 00',
        },
        {
          id: 'bac5899d-fec6-420f-a46a-16bccf3df988',
          name: 'Commissioning of Utilities',
          children: [
            {
              id: 'feab92d1-e17c-436c-9767-19e9dce9cb53',
              name: 'Commissioning of Utilities',
              tradeId: '33 08 00',
            },
            {
              id: 'fd336878-ae4d-477b-a00c-d6692c605130',
              name: 'Commissioning of Water Utilities',
              tradeId: '33 08 10',
            },
            {
              id: 'e612d724-3e7b-4733-8217-6f6db4108e49',
              name: 'Commissioning of Wells',
              tradeId: '33 08 20',
            },
            {
              id: '3d4eb8d6-5ca0-4acd-b4ae-a60a1fa141b7',
              name: 'Commissioning of Sanitary Sewerage Utilities',
              tradeId: '33 08 30',
            },
            {
              id: 'bd5295a5-921b-4b4c-8d08-5eaa904c8541',
              name: 'Commissioning of Storm Drainage Utilities',
              tradeId: '33 08 40',
            },
            {
              id: 'caf8d477-fed8-471d-99c2-794ad73cbb28',
              name: 'Commissioning of Fuel Distribution Utilities',
              tradeId: '33 08 50',
            },
            {
              id: 'f6a80198-53e0-4287-bea2-8dcff9dd9442',
              name: 'Commissioning of Hydronic and Steam Energy Utilities',
              tradeId: '33 08 60',
            },
            {
              id: '9cc6a130-65a4-4d08-b51f-220c335f0fb4',
              name: 'Commissioning of Electrical Utilities',
              tradeId: '33 08 70',
            },
            {
              id: '32ff4d14-392d-4493-b8a7-a3d976f20d4e',
              name: 'Commissioning of Communications Utilities',
              tradeId: '33 08 80',
            },
          ],
          tradeId: '33 08 00',
        },
        {
          id: 'cb6d6175-bb07-40e7-b451-8151d9aefb6d',
          name: 'Instrumentation and Control for Utilities',
          children: [
            {
              id: '526b2f02-19bf-4ff6-a4e9-edb051c339d4',
              name: 'Instrumentation and Control for Utilities',
              tradeId: '33 09 00',
            },
            {
              id: 'dc8824d2-5387-4978-ba37-1539bc01933f',
              name: 'Instrumentation and Control for Water Utilities',
              tradeId: '33 09 10',
            },
            {
              id: 'bcca46c0-1345-4af8-b4ec-7b0a9ce41c6c',
              name: 'Instrumentation and Control for Wells',
              tradeId: '33 09 20',
            },
            {
              id: '7b582b85-f22e-4fd9-828d-7af8f4307080',
              name: 'Instrumentation and Control for Sanitary Sewerage Utilities',
              tradeId: '33 09 30',
            },
            {
              id: '9661c828-d902-41ef-825d-25193fe88991',
              name: 'Instrumentation and Control for Storm Drainage Utilities',
              tradeId: '33 09 40',
            },
            {
              id: '4e28f619-dcda-4a8a-9053-99c00832487e',
              name: 'Instrumentation and Control for Fuel Distribution Utilities',
              tradeId: '33 09 50',
            },
            {
              id: '4858a1e8-2d05-4285-831e-fcd01ace6860',
              name: 'Instrumentation and Control for Hydronic and Steam Energy Utilities',
              tradeId: '33 09 60',
            },
            {
              id: '58069098-8998-4e09-862f-d8e643333ebb',
              name: 'Instrumentation and Control for Electrical Utilities',
              tradeId: '33 09 70',
            },
            {
              id: '3b1b6909-1db6-48d2-bafc-b77bef7cc97b',
              name: 'Instrumentation and Control for Communications Utilities',
              tradeId: '33 09 80',
            },
          ],
          tradeId: '33 09 00',
        },
        {
          id: 'ffcfb503-9951-4e38-b88e-86785c6a663b',
          name: 'Water Utilities',
          children: [],
          tradeId: '33 10 00',
        },
        {
          id: '498732ab-8251-4d6a-aa89-2016f02f2a55',
          name: 'Water Utility Distribution Piping',
          children: [
            {
              id: '4f2e8bdc-a69f-45d3-9617-137711176263',
              name: 'Water Utility Distribution Piping',
              tradeId: '33 11 00',
            },
            {
              id: 'e17f867e-7394-420f-8e5e-0740a7470344',
              name: 'Public Water Utility Distribution Piping',
              tradeId: '33 11 13',
            },
            {
              id: '7ee34386-fcaa-4aa1-bb06-dbbabfbb7c0a',
              name: 'Ductile Iron Public Water Utility Distribution Piping',
              tradeId: '33 11 13.13',
            },
            {
              id: '560460a7-3c09-478d-908d-87bd27a48565',
              name: 'Cast Iron Public Water Utility Distribution Piping',
              tradeId: '33 11 13.16',
            },
            {
              id: '5f34a7bb-9c92-48d6-8cda-15d2b86d0171',
              name: 'Plastic Public Water Utility Distribution Piping',
              tradeId: '33 11 13.23',
            },
            {
              id: 'e3574386-d2de-46e9-95cc-a2a7514cc873',
              name: 'Galvanized Steel Public Water Utility Distribution Piping',
              tradeId: '33 11 13.26',
            },
            {
              id: '438b5b28-ab45-434f-b301-ae6ac0ab0e75',
              name: 'Concrete Public Water Utility Distribution Piping',
              tradeId: '33 11 13.33',
            },
            {
              id: 'b20f3325-b540-4b87-bb3e-1324dd1e863d',
              name: 'Site Water Utility Distribution Piping',
              tradeId: '33 11 16',
            },
            {
              id: '7a4bc888-628b-4e81-9843-347b08ea48b8',
              name: 'Fire Suppression Utility Water Distribution Piping',
              tradeId: '33 11 19',
            },
          ],
          tradeId: '33 11 00',
        },
        {
          id: '4d97a3e4-ea90-4c1a-80fa-756f48fba97f',
          name: 'Water Utility Distribution Equipment',
          children: [
            {
              id: '07b518c7-2e1f-4f90-b537-6dd48d95f9cb',
              name: 'Water Utility Distribution Equipment',
              tradeId: '33 12 00',
            },
            {
              id: 'f5808f15-9a56-4981-996b-0eab35d5feee',
              name: 'Water Service Connections',
              tradeId: '33 12 13',
            },
            {
              id: '57d72bbd-85aa-49c8-8ff7-3a356657e7cd',
              name: 'Water Supply Backflow Preventer Assemblies',
              tradeId: '33 12 13.13',
            },
            {
              id: '2c6b7808-4f98-4342-bced-d28db88c5171',
              name: 'Water Utility Distribution Valves',
              tradeId: '33 12 16',
            },
            {
              id: '3e047ee0-37b5-4b70-bfd8-7051675d9ea0',
              name: 'Water Utility Distribution Fire Hydrants',
              tradeId: '33 12 19',
            },
            {
              id: 'd677ba40-4992-4a65-a84e-7760d8db5450',
              name: 'Water Utility Pumping Stations',
              tradeId: '33 12 23',
            },
            {
              id: '3d895f01-4ecb-41a4-ae05-813460cb6261',
              name: 'Water Utility Metering',
              tradeId: '33 12 33',
            },
          ],
          tradeId: '33 12 00',
        },
        {
          id: '75f43e67-5b27-4336-b053-0fa480b5b85d',
          name: 'Disinfecting of Water Utility Distribution',
          children: [],
          tradeId: '33 13 00',
        },
        {
          id: '2eecc6e3-b4e8-494b-af17-7976a7cb8b3b',
          name: 'Water Utility Storage Tanks',
          children: [
            {
              id: '9739acf9-912c-47a6-9577-caafe7d03dec',
              name: 'Water Utility Storage Tanks',
              tradeId: '33 16 00',
            },
            {
              id: '163bb03b-4f6b-412a-aebd-e55a2088d8b7',
              name: 'Aboveground Water Utility Storage Tanks',
              tradeId: '33 16 13',
            },
            {
              id: '34922374-87f8-472d-9621-925a9318bab7',
              name: 'Steel Aboveground Water Utility Storage Tanks',
              tradeId: '33 16 13.13',
            },
            {
              id: '9afcc9e8-141d-4610-96c0-7616a847d50a',
              name: 'Prestressed Concrete Aboveground Water Utility Storage Tanks',
              tradeId: '33 16 13.16',
            },
            {
              id: 'bba6e332-7350-4d88-ad88-a406b9f70721',
              name: 'Plastic Aboveground Water Utility Storage Tanks',
              tradeId: '33 16 13.19',
            },
            {
              id: 'e7077114-5d02-40b4-9761-ba52b774336b',
              name: 'Underground Water Utility Storage Tanks',
              tradeId: '33 16 16',
            },
            {
              id: '2db9c1be-3f26-438b-8c11-d054108a0860',
              name: 'Elevated Water Utility Storage Tanks',
              tradeId: '33 16 19',
            },
          ],
          tradeId: '33 16 00',
        },
        {
          id: 'e8f71878-b6c7-4232-a93b-e9a646df5a50',
          name: 'Wells',
          children: [],
          tradeId: '33 20 00',
        },
        {
          id: '1f5b098f-af9a-4946-8d1a-9987fdb5affe',
          name: 'Water Supply Wells',
          children: [
            {
              id: 'ad3b8b7e-2006-4b01-8c20-01d3af7f8e07',
              name: 'Water Supply Wells',
              tradeId: '33 21 00',
            },
            {
              id: '3d860518-5e18-4bf2-afd2-e2a6356c7dae',
              name: 'Public Water Supply Wells',
              tradeId: '33 21 13',
            },
            {
              id: '947546bf-c33e-411b-8402-58bc9df09060',
              name: 'Irrigation Water Wells',
              tradeId: '33 21 16',
            },
          ],
          tradeId: '33 21 00',
        },
        {
          id: 'd75f4764-3eb4-4fa1-b28b-2eddfc25d64a',
          name: 'Test Wells',
          children: [],
          tradeId: '33 22 00',
        },
        {
          id: 'f900262e-d542-4916-869d-297c081a6e11',
          name: 'Extraction Wells',
          children: [],
          tradeId: '33 23 00',
        },
        {
          id: '05a3b8a8-619c-43e1-9d4e-d4c06f5d9b79',
          name: 'Monitoring Wells',
          children: [
            {
              id: 'fe348519-ddaf-4d57-ad6a-0edef39cccfb',
              name: 'Monitoring Wells',
              tradeId: '33 24 00',
            },
            {
              id: '101740e3-fe46-413d-b6f8-7d749f9dd86d',
              name: 'Groundwater Monitoring Wells',
              tradeId: '33 24 13',
            },
          ],
          tradeId: '33 24 00',
        },
        {
          id: '74a7315d-e51e-40ac-b8ff-36a9432892eb',
          name: 'Recharge Wells',
          children: [],
          tradeId: '33 25 00',
        },
        {
          id: '4eb5bba3-e507-415f-ba3b-8a8328c53fe2',
          name: 'Relief Wells',
          children: [],
          tradeId: '33 26 00',
        },
        {
          id: '9f727df5-a877-4af5-99c4-a49f654be782',
          name: 'Well Abandonment',
          children: [],
          tradeId: '33 29 00',
        },
        {
          id: 'a598aafe-bdc8-4922-b4f8-e4c862156489',
          name: 'Sanitary Sewerage Utilities',
          children: [],
          tradeId: '33 30 00',
        },
        {
          id: '34962f16-ebdf-4a2f-93ac-6c2e729322a0',
          name: 'Sanitary Utility Sewerage Piping',
          children: [
            {
              id: '4ea88c48-9d67-4e25-970d-2cedbf40323f',
              name: 'Sanitary Utility Sewerage Piping',
              tradeId: '33 31 00',
            },
            {
              id: 'ebf28321-7c29-4474-90ee-651588a13b06',
              name: 'Public Sanitary Utility Sewerage Piping',
              tradeId: '33 31 13',
            },
            {
              id: '0f09c6ef-992b-41c8-a412-b4aec20238a7',
              name: 'Industrial Waste Utility Sewerage Piping',
              tradeId: '33 31 16',
            },
          ],
          tradeId: '33 31 00',
        },
        {
          id: 'db46a04e-0e44-4a19-9f2d-53eb0fb55d2a',
          name: 'Wastewater Utility Pumping Stations',
          children: [
            {
              id: '7ab3801a-e44e-44d6-bbc7-5dd4560b9b76',
              name: 'Wastewater Utility Pumping Stations',
              tradeId: '33 32 00',
            },
            {
              id: 'ba37e2f9-ce5e-465d-95c5-984fb620274a',
              name: 'Packaged Utility Lift Stations',
              tradeId: '33 32 13',
            },
            {
              id: '8b4ff405-4af0-45f4-99de-c3e078754af9',
              name: 'Packaged Sewage Lift Stations, Wet Well Type',
              tradeId: '33 32 13.13',
            },
            {
              id: 'ecc7c060-b82e-40e8-a7d3-70ae0f949a33',
              name: 'Packaged Utility Wastewater Pumping Stations',
              tradeId: '33 32 16',
            },
            {
              id: '2af38757-fd50-4023-ab89-5ee0cea92aa9',
              name: 'Packaged Sewage Grinder Pumping Units',
              tradeId: '33 32 16.13',
            },
            {
              id: 'd9b446da-6c16-490d-abbb-3baf4ea174ce',
              name: 'Public Utility Wastewater Pumping Stations',
              tradeId: '33 32 19',
            },
          ],
          tradeId: '33 32 00',
        },
        {
          id: '256f28c0-d471-4583-89ad-25c7e0d007f4',
          name: 'Low Pressure Utility Sewerage',
          children: [
            {
              id: '2bc43702-4a7b-4f43-ba4e-59ad76ea4fea',
              name: 'Low Pressure Utility Sewerage',
              tradeId: '33 33 00',
            },
            {
              id: 'bc3b9173-9b5b-4cf3-9792-a1c449ca723c',
              name: 'Sanitary Utility Sewerage',
              tradeId: '33 33 13',
            },
            {
              id: 'ca6d3a96-5061-449a-af5e-e06a6336caf5',
              name: 'Combined Utility Sewerage',
              tradeId: '33 33 16',
            },
          ],
          tradeId: '33 33 00',
        },
        {
          id: 'c602ad36-7f7c-4be1-919c-e4b25e678a8e',
          name: 'Sanitary Utility Sewerage Force Mains',
          children: [
            {
              id: 'db912fb1-f7fe-40b6-bedb-fedd7e914145',
              name: 'Sanitary Utility Sewerage Force Mains',
              tradeId: '33 34 00',
            },
            {
              id: '1aae16ec-6fa4-4c25-862d-6c2f31019ee2',
              name: 'Sanitary Utility Sewerage Inverted Siphons',
              tradeId: '33 34 13',
            },
          ],
          tradeId: '33 34 00',
        },
        {
          id: 'f6f03913-5de5-485d-8af4-7ba9c90728b6',
          name: 'Utility Septic Tanks',
          children: [
            {
              id: 'c9a465bc-d893-4b7c-a109-a306d2772100',
              name: 'Utility Septic Tanks',
              tradeId: '33 36 00',
            },
            {
              id: '3c79ebd4-3342-4553-a105-1291a7053f69',
              name: 'Utility Septic Tank and Effluent Wet Wells',
              tradeId: '33 36 13',
            },
            {
              id: 'a8e0570f-7ff9-4fdc-a155-300763ae481b',
              name: 'Concrete Utility Septic Tank',
              tradeId: '33 36 13.13',
            },
            {
              id: 'f3ba9753-2ce3-48b2-ac7e-2eb92495c25f',
              name: 'Fiberglass Utility Septic Tank',
              tradeId: '33 36 13.16',
            },
            {
              id: '5ed65b42-156d-41df-bec4-613b15c21d00',
              name: 'Polyethylene Utility Septic Tank',
              tradeId: '33 36 13.19',
            },
            {
              id: '768c4168-10a3-42b3-9fbe-0f1cf0f90248',
              name: 'Utility Septic Tank Effluent Pumps',
              tradeId: '33 36 16',
            },
            {
              id: 'a3b69247-fd89-49e3-9361-3ece87799a81',
              name: 'Utility Septic Tank High-Head Effluent Pump',
              tradeId: '33 36 16.13',
            },
            {
              id: '72acffaa-cc5e-4711-9791-d5d7b136c1f9',
              name: 'Utility Septic Tank Effluent Pump Package',
              tradeId: '33 36 16.16',
            },
            {
              id: 'ed58e23a-5516-4f0b-b069-7d9b01e87712',
              name: 'Utility Septic Tank Effluent Filter',
              tradeId: '33 36 19',
            },
            {
              id: '157df484-d707-4acb-bff5-bab2a5d2c2d3',
              name: 'Utility Septic Tank Effluent Tube Filter',
              tradeId: '33 36 19.13',
            },
            {
              id: '39fde93f-76ee-4bdc-b005-09541cf5787f',
              name: 'Utility Septic Tank Effluent Textile Media Filter',
              tradeId: '33 36 19.16',
            },
            {
              id: '3985bb62-0ac0-4f7d-bc9d-d473623aad18',
              name: 'Utility Septic Tank Drainage Field',
              tradeId: '33 36 33',
            },
            {
              id: 'f5abb527-5cc2-454c-ab1f-9e8fa51ceb54',
              name: 'Utility Septic Tank Tile Drainage Field',
              tradeId: '33 36 33.13',
            },
            {
              id: 'c262de8c-29a7-4b5c-9561-98d215468928',
              name: 'Utility Septic Tank Shallow Pressurized Dispersal System',
              tradeId: '33 36 33.16',
            },
          ],
          tradeId: '33 36 00',
        },
        {
          id: 'ba8a1527-a58b-4fa7-847a-f81d561b4e68',
          name: 'Treatment Lagoons',
          children: [
            {
              id: '96c61f14-1644-411e-a2fb-bf238577f3d1',
              name: 'Treatment Lagoons',
              tradeId: '33 38 00',
            },
            {
              id: '258d09f1-1c7d-4367-aeeb-3f6127f5e104',
              name: 'Municipal Wastewater Treatment Lagoons',
              tradeId: '33 38 13',
            },
            {
              id: 'd3685761-defa-4006-856f-6517ceb65496',
              name: 'Industrial Waste Lagoons',
              tradeId: '33 38 16',
            },
            {
              id: '759c7c49-2432-4b64-89fb-d539c6214ad9',
              name: 'Agricultural Waste Lagoons',
              tradeId: '33 38 23',
            },
            {
              id: 'ced87131-dba4-4ac7-b64e-8652c726ea19',
              name: 'Residential Sewerage Lagoons',
              tradeId: '33 38 26',
            },
          ],
          tradeId: '33 38 00',
        },
        {
          id: '027b374f-cd3e-4101-adbc-39c8a5e090ef',
          name: 'Sanitary Utility Sewerage Structures',
          children: [
            {
              id: 'bf3413fd-e47f-4b73-b29b-6334f2d6c1bd',
              name: 'Sanitary Utility Sewerage Structures',
              tradeId: '33 39 00',
            },
            {
              id: '1ed11b0d-7799-466f-82eb-d120fb47660c',
              name: 'Sanitary Utility Sewerage Manholes, Frames and Covers',
              tradeId: '33 39 13',
            },
            {
              id: 'cc0e95fd-1e65-421b-b920-5ba875215414',
              name: 'Sanitary Utility Sewerage Cleanouts',
              tradeId: '33 39 23',
            },
          ],
          tradeId: '33 39 00',
        },
        {
          id: '20dd61f4-671a-497b-92fe-3efc6041749c',
          name: 'Storm Drainage Utilities',
          children: [],
          tradeId: '33 40 00',
        },
        {
          id: '4e84df40-509a-43bd-84e7-907580ee5805',
          name: 'Storm Utility Drainage Piping',
          children: [
            {
              id: '9bc76e8a-5bf4-4792-ba88-f173dca4a48e',
              name: 'Storm Utility Drainage Piping',
              tradeId: '33 41 00',
            },
            {
              id: '2ef75aa0-11d0-4cb9-87d9-b968a0d6fda1',
              name: 'Public Storm Utility Drainage Piping',
              tradeId: '33 41 13',
            },
          ],
          tradeId: '33 41 00',
        },
        {
          id: '48492e06-824f-4b78-bc6a-f69de904e582',
          name: 'Culverts',
          children: [
            {
              id: '47332078-84f7-43ba-ae40-2a9dfeff784f',
              name: 'Culverts',
              tradeId: '33 42 00',
            },
            {
              id: '9664fe19-368b-453e-b6c6-8e5c9da4b313',
              name: 'Pipe Culverts',
              tradeId: '33 42 13',
            },
            {
              id: 'dcb14cbc-79eb-4aa0-9c91-a540551a210e',
              name: 'Public Pipe Culverts',
              tradeId: '33 42 13.13',
            },
            {
              id: '521b86a4-409f-4807-8156-98f4c6a8cb59',
              name: 'Concrete Culverts',
              tradeId: '33 42 16',
            },
            {
              id: '4c246b56-6b4a-47ac-9d4e-2b8c07eeb67c',
              name: 'Precast Concrete Culverts',
              tradeId: '33 42 16.13',
            },
            {
              id: '8914cd1b-ee20-414e-bc01-d173d5e5e9b4',
              name: 'Cast-in-Place Concrete Culverts',
              tradeId: '33 42 16.16',
            },
          ],
          tradeId: '33 42 00',
        },
        {
          id: 'b0b4eaa4-4b87-4c78-b490-7fb5aa2efd12',
          name: 'Storm Utility Water Drains',
          children: [
            {
              id: '67d199d1-8797-40b4-bc75-9c8df4f8a154',
              name: 'Storm Utility Water Drains',
              tradeId: '33 44 00',
            },
            {
              id: '5cead9b7-fa3f-468b-9d27-1e495d58df3b',
              name: 'Utility Area Drains',
              tradeId: '33 44 13',
            },
            {
              id: 'c9a1876a-8e3d-4b7c-887d-c0afeb73c1da',
              name: 'Catchbasins',
              tradeId: '33 44 13.13',
            },
            {
              id: 'f7a47245-9673-4d61-877d-90f74feac899',
              name: 'Utility Trench Drains',
              tradeId: '33 44 16',
            },
            {
              id: 'dee53537-ed24-4c67-b731-001fb979543d',
              name: 'Utility Storm Water Treatment',
              tradeId: '33 44 19',
            },
            {
              id: '45cc9082-3a3d-44d2-bd4d-f2f01a5b5aab',
              name: 'In-Line Utility Storm Water Filters',
              tradeId: '33 44 19.13',
            },
            {
              id: '9e870059-18bb-403f-8ba1-ace9078eb3b5',
              name: 'Catch Basin Insert Utility Storm Water Filters',
              tradeId: '33 44 19.16',
            },
            {
              id: '4e40f270-a2d5-4b31-9c11-3f7df9d2022a',
              name: 'Utility Oil and Gas Separators',
              tradeId: '33 44 19.19',
            },
          ],
          tradeId: '33 44 00',
        },
        {
          id: '3ea88c92-d184-4aab-8be2-2b8325ff98ed',
          name: 'Storm Utility Drainage Pumps',
          children: [],
          tradeId: '33 45 00',
        },
        {
          id: '433f0db0-761b-46d6-8403-a52f09f32335',
          name: 'Subdrainage',
          children: [
            {
              id: 'b04c9144-3195-4481-98b1-4e61ccfd30cf',
              name: 'Subdrainage',
              tradeId: '33 46 00',
            },
            {
              id: 'f12ec4d3-e551-4e40-9c5d-4d018002f3cc',
              name: 'Foundation Drainage',
              tradeId: '33 46 13',
            },
            {
              id: '80b216e8-a289-409e-9e94-3b455f10484a',
              name: 'Foundation Drainage Piping',
              tradeId: '33 46 13.13',
            },
            {
              id: '86647d98-3cbc-4f6b-9d75-308f7061a718',
              name: 'Geocomposite Foundation Drainage',
              tradeId: '33 46 13.16',
            },
            {
              id: '44114821-dcb5-4e9f-91ab-0fb4914edaa4',
              name: 'Subdrainage Piping',
              tradeId: '33 46 16',
            },
            {
              id: '0041b127-072d-4d37-a500-5305143bcd12',
              name: 'Subdrainage Piping',
              tradeId: '33 46 16.13',
            },
            {
              id: '24bbff3d-2546-44d8-b6c4-4906bf09dad1',
              name: 'Geocomposite Subdrainage',
              tradeId: '33 46 16.16',
            },
            {
              id: '0013b001-160d-4551-982a-9030cfb244ba',
              name: 'Pipe Underdrains',
              tradeId: '33 46 16.19',
            },
            {
              id: '9cd356ce-593c-4b7b-aafd-e3f31b91b764',
              name: 'Underslab Drainage',
              tradeId: '33 46 19',
            },
            {
              id: '91fb6bf9-cb34-47c9-910d-882a3bd73614',
              name: 'Underslab Drainage Piping',
              tradeId: '33 46 19.13',
            },
            {
              id: '77968b17-a3f1-40f2-b631-722fee7427ac',
              name: 'Geocomposite Underslab Drainage',
              tradeId: '33 46 19.16',
            },
            {
              id: '714f9ffa-b76e-498a-8818-950849b88d8e',
              name: 'Drainage Layers',
              tradeId: '33 46 23',
            },
            {
              id: 'e7233a9f-d902-4d4b-916b-18e80a3737b8',
              name: 'Gravel Drainage Layers',
              tradeId: '33 46 23.16',
            },
            {
              id: '02e779bf-adb2-46bd-aebf-2adea98f6943',
              name: 'Geosynthetic Drainage Layers',
              tradeId: '33 46 23.19',
            },
            {
              id: 'fbfc5c51-2864-497b-91a3-eab328e95815',
              name: 'Geotextile Subsurface Drainage Filtration',
              tradeId: '33 46 26',
            },
            {
              id: '0eb2d86b-0a38-4918-98ad-36d99e37d07c',
              name: 'Retaining Wall Drainage',
              tradeId: '33 46 33',
            },
          ],
          tradeId: '33 46 00',
        },
        {
          id: '423a8f70-86fc-4ab7-89b2-64c7265f530f',
          name: 'Ponds and Reservoirs',
          children: [
            {
              id: '3708505d-b3b0-4c0d-bbcb-e966476450e5',
              name: 'Ponds and Reservoirs',
              tradeId: '33 47 00',
            },
            {
              id: 'fdb9d52b-8a79-4746-887e-5d10e6630a9f',
              name: 'Pond and Reservoir Liners',
              tradeId: '33 47 13',
            },
            {
              id: '8d0ec02a-4d4c-427e-b759-4255a3eae1b7',
              name: 'Pond Liners',
              tradeId: '33 47 13.13',
            },
            {
              id: 'ee038683-a5e5-4cfe-bb88-994f152ca738',
              name: 'Reservoir Liners',
              tradeId: '33 47 13.53',
            },
            {
              id: 'fecbb776-a0ee-4a6b-bcdc-4f8835c26700',
              name: 'Pond and Reservoir Covers',
              tradeId: '33 47 16',
            },
            {
              id: '7e024d6d-fda8-4756-aa39-fb42e86fa2d2',
              name: 'Pond Covers',
              tradeId: '33 47 16.13',
            },
            {
              id: '6b114a94-f04b-4aea-9d2c-8a9df7358ad0',
              name: 'Reservoir Covers',
              tradeId: '33 47 16.53',
            },
            {
              id: '934b99ef-e258-45b7-9801-0b182f87b87a',
              name: 'Water Ponds and Reservoirs',
              tradeId: '33 47 19',
            },
            {
              id: '434c8f1e-6cf5-49bb-85d0-d2ca8d8d519b',
              name: 'Water Distribution Ponds',
              tradeId: '33 47 19.13',
            },
            {
              id: '83cf02fc-5f8e-409d-a7c8-c4ec777da963',
              name: 'Water Retainage Reservoirs',
              tradeId: '33 47 19.16',
            },
            {
              id: 'a582eed2-2328-4d20-a35c-3d9d02798621',
              name: 'Cooling Water Ponds',
              tradeId: '33 47 19.23',
            },
            {
              id: 'a4325bf5-2e7d-4bbc-96f4-718b84cf8e66',
              name: 'Fire-Protection Water Ponds',
              tradeId: '33 47 19.33',
            },
            {
              id: '1e3981fb-1f78-4a64-8928-c9f11a06fe87',
              name: 'Storm Drainage Ponds and Reservoirs',
              tradeId: '33 47 26',
            },
            {
              id: '4af7f0c6-b8f3-4387-88c2-b02d99222fc9',
              name: 'Stabilization Ponds',
              tradeId: '33 47 26.13',
            },
            {
              id: '2008c719-e15e-411b-a577-4f081def0fd5',
              name: 'Retention Basins',
              tradeId: '33 47 26.16',
            },
            {
              id: '83af51ff-1d04-40cd-84af-c4ef5b5ed604',
              name: 'Leaching Pits',
              tradeId: '33 47 26.19',
            },
          ],
          tradeId: '33 47 00',
        },
        {
          id: 'cb92942d-e2f4-4086-b120-82091a39fa12',
          name: 'Storm Drainage Structures',
          children: [
            {
              id: '653fc0ed-a0fe-4f89-a3b4-cf1231d648fc',
              name: 'Storm Drainage Structures',
              tradeId: '33 49 00',
            },
            {
              id: '8140b2b1-60dc-4f9b-8b34-c4e595cebaef',
              name: 'Storm Drainage Manholes, Frames and Covers',
              tradeId: '33 49 13',
            },
            {
              id: '5006be28-4dc2-40c7-9c1c-9dc07b8b41fc',
              name: 'Storm Drainage Water Detention Structures',
              tradeId: '33 49 22',
            },
            {
              id: 'bc2dd1a3-5992-4808-9141-e3a7d06e9588',
              name: 'Storm Drainage Water Retention Structures',
              tradeId: '33 49 23',
            },
            {
              id: '9eb18f56-fca3-40d9-a68e-9384b04faefb',
              name: 'Underground Storm Drainage Water Retention Tanks',
              tradeId: '33 49 23.13',
            },
            {
              id: 'dc7dcfaf-d638-4a1d-9c9d-554077c10d38',
              name: 'Storm Drainage Water Storage Structures',
              tradeId: '33 49 24',
            },
          ],
          tradeId: '33 49 00',
        },
        {
          id: '16dd25b0-81c5-44b8-a67f-b41152f8a583',
          name: 'Fuel Distribution Utilities',
          children: [],
          tradeId: '33 50 00',
        },
        {
          id: '9b597292-f77d-4997-a77c-54b88721d764',
          name: 'Natural-Gas Distribution',
          children: [
            {
              id: 'f89ec768-bfea-4a19-bfb9-c962b022f0d6',
              name: 'Natural-Gas Distribution',
              tradeId: '33 51 00',
            },
            {
              id: '4fe7e802-89ce-4f5e-b65a-c432c3bca0bc',
              name: 'Natural-Gas Piping',
              tradeId: '33 51 13',
            },
            {
              id: 'cb57a2f1-cbfb-44a3-91f6-89ccbc05398a',
              name: 'Natural-Gas Metering',
              tradeId: '33 51 33',
            },
          ],
          tradeId: '33 51 00',
        },
        {
          id: '8a5af56e-2aab-4158-bb1b-ff1432d49df0',
          name: 'Liquid Fuel Distribution',
          children: [
            {
              id: '1bc417ba-4fb0-4614-b63b-4e0367390921',
              name: 'Liquid Fuel Distribution',
              tradeId: '33 52 00',
            },
            {
              id: '8fd599c6-c632-4212-983c-f94f389b1db8',
              name: 'Fuel-Oil Distribution',
              tradeId: '33 52 13',
            },
            {
              id: '55d9db0a-372e-4e10-9b8c-851b5809bf06',
              name: 'Fuel-Oil Piping',
              tradeId: '33 52 13.13',
            },
            {
              id: 'fc3858ba-878f-42cf-b0f9-54ffe28d46de',
              name: 'Fuel-Oil Pumps',
              tradeId: '33 52 13.23',
            },
            {
              id: 'c75a1e69-4d6c-4109-bdeb-2df84d29a55d',
              name: 'Gasoline Distribution',
              tradeId: '33 52 16',
            },
            {
              id: '8b26a740-6c94-4526-96ab-82b132a042c7',
              name: 'Gasoline Piping',
              tradeId: '33 52 16.13',
            },
            {
              id: 'a140f528-9b57-4834-9c1a-2cf8416e7b90',
              name: 'Gasoline Pumps',
              tradeId: '33 52 16.23',
            },
            {
              id: '0ad892b7-fc73-4e14-abbc-fa2eeff45cd5',
              name: 'Diesel Fuel Distribution',
              tradeId: '33 52 19',
            },
            {
              id: 'dc6fd886-b299-419a-985e-f641b1404b54',
              name: 'Diesel Fuel Piping',
              tradeId: '33 52 19.13',
            },
            {
              id: '3c4bc1d6-f4de-4df4-b81d-d81d029feaa1',
              name: 'Diesel Fuel Pumps',
              tradeId: '33 52 19.23',
            },
            {
              id: '6aa7bdab-b683-4d7c-a8bb-da60f5b18916',
              name: 'Aviation Fuel Distribution',
              tradeId: '33 52 43',
            },
            {
              id: '905c4f44-b7ae-4bd2-914d-3b6ae74eb5ab',
              name: 'Aviation Fuel Piping',
              tradeId: '33 52 43.13',
            },
            {
              id: '4394c18f-7840-4b40-b75f-3e505e7c3093',
              name: 'Aviation Fuel Connections',
              tradeId: '33 52 43.16',
            },
            {
              id: '642e1faa-ed48-46e8-997e-2b9b9b032d7e',
              name: 'Aviation Fuel Grounding',
              tradeId: '33 52 43.19',
            },
            {
              id: 'e51da64d-16d4-4bec-8518-eeb7e3eb747a',
              name: 'Aviation Fuel Pumps',
              tradeId: '33 52 43.23',
            },
          ],
          tradeId: '33 52 00',
        },
        {
          id: '406d81d6-ea95-49ed-9758-21db0c95e9f3',
          name: 'Fuel-Storage Tanks',
          children: [
            {
              id: 'bb7a5739-9d7b-4cce-92f3-cba92a787bc3',
              name: 'Fuel-Storage Tanks',
              tradeId: '33 56 00',
            },
            {
              id: 'f3061383-c309-45c1-b449-8597f81a283d',
              name: 'Aboveground Fuel-Storage Tanks',
              tradeId: '33 56 13',
            },
            {
              id: 'acacb033-1436-408d-b02f-fa2afcf62c17',
              name: 'Underground Fuel-Storage Tanks',
              tradeId: '33 56 16',
            },
            {
              id: '85400dc1-d0ba-44e1-a350-3cc9e85e57f5',
              name: 'Aviation Fuel-Storage Tanks',
              tradeId: '33 56 43',
            },
            {
              id: '38dd42d1-2107-4118-b204-8221317a3a8b',
              name: 'Aboveground Aviation Fuel-Storage Tanks',
              tradeId: '33 56 43.13',
            },
            {
              id: '84f03a63-1d71-4691-ad28-0d1800d60828',
              name: 'Underground Aviation Fuel-Storage Tanks',
              tradeId: '33 56 43.16',
            },
            {
              id: '1c537d12-1900-48ff-8f8a-a38f4e013a2d',
              name: 'Compressed Gases Storage Tanks',
              tradeId: '33 56 53',
            },
          ],
          tradeId: '33 56 00',
        },
        {
          id: '878f7bab-a296-472d-890e-18fbd91557c2',
          name: 'Hydronic and Steam Energy Utilities',
          children: [],
          tradeId: '33 60 00',
        },
        {
          id: '7276fe1e-6c49-4409-83f3-ccc2aa8a7c79',
          name: 'Hydronic Energy Distribution',
          children: [
            {
              id: 'd83b5faf-2576-4864-9394-2fe2fac220c5',
              name: 'Hydronic Energy Distribution',
              tradeId: '33 61 00',
            },
            {
              id: '46d48df1-2cd3-4fb9-b2f4-1fa64c06a1a6',
              name: 'Underground Hydronic Energy Distribution',
              tradeId: '33 61 13',
            },
            {
              id: '48688512-a763-4a0d-9884-62dfc8b33feb',
              name: 'Aboveground Hydronic Energy Distribution',
              tradeId: '33 61 23',
            },
            {
              id: '1581cb76-d7bd-404f-b3e0-5e1b22d72ad7',
              name: 'Hydronic Energy Distribution Metering',
              tradeId: '33 61 33',
            },
          ],
          tradeId: '33 61 00',
        },
        {
          id: '695a5345-6c8c-48a4-b3f5-d25e01f34ee8',
          name: 'Steam Energy Distribution',
          children: [
            {
              id: '9ce4c2d5-1c8a-461b-b8f3-70f2700220c5',
              name: 'Steam Energy Distribution',
              tradeId: '33 63 00',
            },
            {
              id: 'e11ff979-62bc-4074-8b3e-4616466261d5',
              name: 'Underground Steam and Condensate Distribution Piping',
              tradeId: '33 63 13',
            },
            {
              id: '0a133c02-83f0-4036-92e0-298b717eca6f',
              name: 'Aboveground Steam and Condensate Distribution Piping',
              tradeId: '33 63 23',
            },
            {
              id: 'ead7fee6-6fc1-47c2-8892-dc51c8c34f1e',
              name: 'Steam Energy Distribution Metering',
              tradeId: '33 63 33',
            },
          ],
          tradeId: '33 63 00',
        },
        {
          id: '82ff9b61-65ec-48f7-bcac-1830ca29d5de',
          name: 'Electrical Utilities',
          children: [],
          tradeId: '33 70 00',
        },
        {
          id: '0137a469-1ef8-4bf7-9fef-80872ec42468',
          name: 'Electrical Utility Transmission and Distribution',
          children: [
            {
              id: '4f197054-476f-48e6-bd9d-ecb84ac9a774',
              name: 'Electrical Utility Transmission and Distribution',
              tradeId: '33 71 00',
            },
            {
              id: 'af5e459f-aaca-4bac-b230-c595367e0116',
              name: 'Electrical Utility Towers',
              tradeId: '33 71 13',
            },
            {
              id: 'f62de22f-2983-47cf-83ec-c935b8a3b373',
              name: 'Precast Concrete Electrical Utility Towers',
              tradeId: '33 71 13.13',
            },
            {
              id: '90c32787-a4a3-4210-93e0-b444bdbff369',
              name: 'Steel Electrical Utility Towers',
              tradeId: '33 71 13.23',
            },
            {
              id: 'bfd9f5a6-b107-4bca-9be7-5f9528171224',
              name: 'Wood Electrical Utility Towers',
              tradeId: '33 71 13.33',
            },
            {
              id: 'fbb03575-989e-4d0e-8086-4506f43cd16f',
              name: 'Electrical Utility Poles',
              tradeId: '33 71 16',
            },
            {
              id: '9d84cdea-011e-41cf-b103-70b2039e98b3',
              name: 'Precast Concrete Electrical Utility Poles',
              tradeId: '33 71 16.13',
            },
            {
              id: '0b8be98b-aa7f-43f2-aea3-ed0ae477d4e2',
              name: 'Steel Electrical Utility Poles',
              tradeId: '33 71 16.23',
            },
            {
              id: 'd57dd018-485c-4196-a604-5e4823b66e97',
              name: 'Wood Electrical Utility Poles',
              tradeId: '33 71 16.33',
            },
            {
              id: '85888521-ebe6-4f16-9363-168d2e733992',
              name: 'Electrical Underground Ducts and Manholes',
              tradeId: '33 71 19',
            },
            {
              id: '45778ad4-57df-4c60-a5d2-51fb08095fad',
              name: 'Electrical Manholes and Handholes',
              tradeId: '33 71 19.13',
            },
            {
              id: '023bcbe6-549b-489a-bfc9-9e8872fa7464',
              name: 'Insulators and Fittings',
              tradeId: '33 71 23',
            },
            {
              id: '0a4ee745-1b6f-46d1-ab9f-75ea578d57ff',
              name: 'Suspension Insulators',
              tradeId: '33 71 23.13',
            },
            {
              id: 'd2bd6db6-15f8-44d4-8587-c24fcd805e59',
              name: 'Post Insulators',
              tradeId: '33 71 23.16',
            },
            {
              id: 'be0b2574-768e-4a77-b8fb-77d8155dc208',
              name: 'Potheads',
              tradeId: '33 71 23.23',
            },
            {
              id: '6a5b2fb6-e816-493d-858e-0c4100da2406',
              name: 'Transmission and Distribution Equipment',
              tradeId: '33 71 26',
            },
            {
              id: '042f214d-de60-45ae-84c1-d213357366f0',
              name: 'Capacitor Banks',
              tradeId: '33 71 26.13',
            },
            {
              id: '14a0e948-4cd5-41c3-84ff-fcae8ac8060c',
              name: 'Coupling Capacitors',
              tradeId: '33 71 26.16',
            },
            {
              id: '3b2ab486-a6c1-443b-ab7b-bf644c3a50a1',
              name: 'Current Transformers',
              tradeId: '33 71 26.23',
            },
            {
              id: 'df20fe95-e4ad-4431-829e-784d1540cbb1',
              name: 'Potential Transformers',
              tradeId: '33 71 26.26',
            },
            {
              id: '8f4eebd6-36a6-418d-82fb-439d6c0f4b40',
              name: 'Extra-High-Voltage Wiring',
              tradeId: '33 71 36',
            },
            {
              id: '3636081f-22ea-4a19-a147-8a4ac5c4fd70',
              name: 'Overhead Extra-High-Voltage Wiring',
              tradeId: '33 71 36.13',
            },
            {
              id: '0a936ee0-3d88-430f-bed0-1afba07449e4',
              name: 'High-Voltage Wiring',
              tradeId: '33 71 39',
            },
            {
              id: 'e7604c1e-c87a-44b8-b03d-22f7fab08eb8',
              name: 'Overhead High-Voltage Wiring',
              tradeId: '33 71 39.13',
            },
            {
              id: '4a268853-09e4-496a-8bfb-1f612c322a21',
              name: 'Underground High-Voltage Wiring',
              tradeId: '33 71 39.23',
            },
            {
              id: '119ed9c5-6cf2-4137-82ce-2ec5321a62b4',
              name: 'Underwater High-Voltage Wiring',
              tradeId: '33 71 39.33',
            },
            {
              id: '3fd3276a-fa13-44ea-a0d2-76b13fa20421',
              name: 'Medium-Voltage Wiring',
              tradeId: '33 71 49',
            },
            {
              id: '839e10d5-4136-4276-9f8a-77f21aa697d2',
              name: 'Overhead Medium-Voltage Wiring',
              tradeId: '33 71 49.13',
            },
            {
              id: 'd4a01a86-e751-49c7-bef0-b7f7a78b65b0',
              name: 'Underground Medium-Voltage Wiring',
              tradeId: '33 71 49.23',
            },
            {
              id: '8d9839cb-9894-40f4-9f9f-293c681560ff',
              name: 'Underwater Medium-Voltage Wiring',
              tradeId: '33 71 49.33',
            },
            {
              id: '06a127e0-84a4-4382-a467-3682940f6a26',
              name: 'Direct-Current Transmission',
              tradeId: '33 71 53',
            },
            {
              id: '8c65d636-1a1f-4bb7-8767-3210384c0007',
              name: 'Electrical Utility Services',
              tradeId: '33 71 73',
            },
            {
              id: '345d72ab-2f3e-4e7a-a262-97442a4351ac',
              name: 'Electric Meters',
              tradeId: '33 71 73.33',
            },
            {
              id: 'd5257152-075b-49ee-9d4a-aff43c38b094',
              name: 'Transmission and Distribution Specialties',
              tradeId: '33 71 83',
            },
          ],
          tradeId: '33 71 00',
        },
        {
          id: 'ac968d7b-58bc-445d-bf30-4ba0955f37c9',
          name: 'Utility Substations',
          children: [
            {
              id: '1148dbe3-30e0-45f8-bc39-052bd094af05',
              name: 'Utility Substations',
              tradeId: '33 72 00',
            },
            {
              id: '7151bb2c-7f0b-4c59-b0c3-b3faa515ad32',
              name: 'Deadend Structures',
              tradeId: '33 72 13',
            },
            {
              id: 'c6d7a1e7-b993-4bd9-b785-9d23a098fa74',
              name: 'Structural Bus Supports',
              tradeId: '33 72 23',
            },
            {
              id: '5826fc00-3ded-4a66-a12d-fbfd5fe3213c',
              name: 'Bus Support Insulators',
              tradeId: '33 72 23.13',
            },
            {
              id: 'ab3310e5-20f9-48d1-be94-805c037ac5ff',
              name: 'Substation Bus Assemblies',
              tradeId: '33 72 26',
            },
            {
              id: '2f0d1fff-6869-44a7-9698-a6af779b123e',
              name: 'Aluminum Substation Bus Assemblies',
              tradeId: '33 72 26.13',
            },
            {
              id: 'afdb7ed2-70ee-4131-bd0a-14f8243c7430',
              name: 'Copper Substation Bus Assemblies',
              tradeId: '33 72 26.16',
            },
            {
              id: '0d05b580-0149-489d-b6c5-30af5abcab68',
              name: 'Control House Equipment',
              tradeId: '33 72 33',
            },
            {
              id: 'cec8746a-6442-4e04-96f0-67d1d0a94468',
              name: 'Relays',
              tradeId: '33 72 33.13',
            },
            {
              id: 'daeaea0a-f152-49fd-bc56-f6743c2bd6ff',
              name: 'Substation Control Panels',
              tradeId: '33 72 33.16',
            },
            {
              id: 'f5218d03-3bfe-4493-aa78-455209117280',
              name: 'Power-Line Carriers',
              tradeId: '33 72 33.23',
            },
            {
              id: 'ec967755-35f2-4a68-b7cd-e62d7ac77e10',
              name: 'Substation Metering',
              tradeId: '33 72 33.26',
            },
            {
              id: 'f4e58b8d-43c9-4633-a900-a6e22ae0418c',
              name: 'Raceway and Boxes for Utility Substations',
              tradeId: '33 72 33.33',
            },
            {
              id: 'fc5e87f2-bda9-43b8-b52f-510905f32718',
              name: 'Cable Trays for Utility Substations',
              tradeId: '33 72 33.36',
            },
            {
              id: 'dbb0f8b1-c049-49ad-8b47-968026dc0750',
              name: 'Substation Backup Batteries',
              tradeId: '33 72 33.43',
            },
            {
              id: '6127e607-8391-43c6-8775-fbf4c5b1fc72',
              name: 'Substation Converter Stations',
              tradeId: '33 72 33.46',
            },
            {
              id: '314a4f48-b789-493f-a77c-bb00eca394f0',
              name: 'Substation Control Wiring',
              tradeId: '33 72 43',
            },
          ],
          tradeId: '33 72 00',
        },
        {
          id: '5dab6676-3a99-4aad-86f4-7650e194111b',
          name: 'Utility Transformers',
          children: [
            {
              id: 'a36c2ad9-6c13-4f90-af15-ce1f2b3cd14d',
              name: 'Utility Transformers',
              tradeId: '33 73 00',
            },
            {
              id: '01ce9b22-9516-4131-8d80-42ec6d332d4e',
              name: 'Liquid-Filled Utility Transformers',
              tradeId: '33 73 13',
            },
            {
              id: '293ff0b6-69c4-47f8-8692-1284e0ba55a4',
              name: 'Dry-Type Utility Transformers',
              tradeId: '33 73 23',
            },
          ],
          tradeId: '33 73 00',
        },
        {
          id: '08c2e640-c050-45e0-9822-3e3eda01b2e3',
          name: 'High-Voltage Switchgear and Protection Devices',
          children: [
            {
              id: '88fdc986-fe5f-42a3-86ad-287a14cfaf0a',
              name: 'High-Voltage Switchgear and Protection Devices',
              tradeId: '33 75 00',
            },
            {
              id: '7389983d-9978-4790-ab1b-4b528bde6645',
              name: 'Air High-Voltage Circuit Breaker',
              tradeId: '33 75 13',
            },
            {
              id: '33125a75-40f3-4519-b9e6-eb0f8c0177df',
              name: 'Oil High-Voltage Circuit Breaker',
              tradeId: '33 75 16',
            },
            {
              id: '778df9e3-201d-4ac2-805e-870afdbf888a',
              name: 'Gas High-Voltage Circuit Breaker',
              tradeId: '33 75 19',
            },
            {
              id: '8fd1c31f-068c-465a-a87e-8d909cc001e3',
              name: 'Vacuum High-Voltage Circuit Breaker',
              tradeId: '33 75 23',
            },
            {
              id: '2a98d087-d7a0-4866-8a2c-eb8b58fa1ae5',
              name: 'High-Voltage Utility Fuses',
              tradeId: '33 75 36',
            },
            {
              id: '8f837b03-d2a5-4a1e-ad0e-30e6f45d9c15',
              name: 'High-Voltage Surge Arresters',
              tradeId: '33 75 39',
            },
            {
              id: '15fa0f60-eeac-40f7-843f-7afb376a1799',
              name: 'Shunt Reactors',
              tradeId: '33 75 43',
            },
          ],
          tradeId: '33 75 00',
        },
        {
          id: 'e1ac15f5-2965-4e49-a6b7-c8a5ed58889e',
          name: 'Medium-Voltage Utility Switchgear and Protection Devices',
          children: [
            {
              id: '63c8d1e8-10cd-43f7-95d7-c76f781cafa5',
              name: 'Medium-Voltage Utility Switchgear and Protection Devices',
              tradeId: '33 77 00',
            },
            {
              id: '66a14f97-8575-4578-8240-e89b9bcd24e3',
              name: 'Air Medium-Voltage Circuit Breaker',
              tradeId: '33 77 13',
            },
            {
              id: 'b8188b2b-9a78-4e8d-97c3-2577dbf31a2e',
              name: 'Oil Medium-Voltage Circuit Breaker',
              tradeId: '33 77 16',
            },
            {
              id: 'b1c60ea8-9928-40db-ae87-0cf68fccdbc5',
              name: 'Gas Medium-Voltage Circuit Breaker',
              tradeId: '33 77 19',
            },
            {
              id: '244a9f0f-7ad7-49fc-b550-dd65ec291488',
              name: 'Vacuum Medium-Voltage Circuit Breaker',
              tradeId: '33 77 23',
            },
            {
              id: '570ea016-d878-47fa-a0a9-b1c439e5d735',
              name: 'Medium-Voltage Utility Fusible Interrupter Switchgear',
              tradeId: '33 77 26',
            },
            {
              id: '5ea5b6a6-1eb1-4880-bb38-bb9df242cee9',
              name: 'Medium-Voltage Utility Cutouts',
              tradeId: '33 77 33',
            },
            {
              id: '34d910a1-4178-43fb-85e2-d820aa76981e',
              name: 'Medium-Voltage Utility Fuses',
              tradeId: '33 77 36',
            },
            {
              id: '8a7bdf8f-9425-4ccc-a268-5f67ac4166cf',
              name: 'Medium-Voltage Utility Surge Arresters',
              tradeId: '33 77 39',
            },
            {
              id: '16406803-0b15-477f-9277-fe4756eeafbd',
              name: 'Medium-Voltage Utility Reclosers',
              tradeId: '33 77 53',
            },
          ],
          tradeId: '33 77 00',
        },
        {
          id: 'f36e0caf-846f-4d43-8727-651741d13f61',
          name: 'Site Grounding',
          children: [
            {
              id: 'f99b45ab-6103-4571-966e-cca8dccf5b78',
              name: 'Site Grounding',
              tradeId: '33 79 00',
            },
            {
              id: 'bfe97777-ac25-4cc5-a056-e92e5605ae27',
              name: 'Site Improvements Grounding',
              tradeId: '33 79 13',
            },
            {
              id: 'aeba3991-d77b-450b-a76f-5117b9549350',
              name: 'Electric Fence Grounding',
              tradeId: '33 79 13.13',
            },
            {
              id: 'f35ae3a5-5a12-4795-8693-0c2aeb4ab16d',
              name: 'Tower Grounding',
              tradeId: '33 79 16',
            },
            {
              id: '92c55061-8143-4ed5-b6b6-9be438eb6583',
              name: 'Communications Tower Grounding',
              tradeId: '33 79 16.13',
            },
            {
              id: '492d82f0-0ef5-4637-91e8-cfe64cce625e',
              name: 'Antenna Tower Grounding',
              tradeId: '33 79 16.16',
            },
            {
              id: '1c638d35-ce0b-4428-9b20-e93084071047',
              name: 'Utilities Grounding',
              tradeId: '33 79 19',
            },
            {
              id: '9906a54c-1dca-4f56-8ff3-022ba4418160',
              name: 'Electrical Utilities Grounding',
              tradeId: '33 79 19.13',
            },
            {
              id: 'a2872b1a-7e81-4510-a6cb-11400d974f9b',
              name: 'Communications Utilities Grounding',
              tradeId: '33 79 19.16',
            },
            {
              id: 'bdd3b2e4-b39f-4d7e-b635-1acf22ecfeef',
              name: 'Utility Substation Grounding',
              tradeId: '33 79 23',
            },
            {
              id: '58ddeed2-9ebe-4324-8c1b-319594f462cf',
              name: 'Site Grounding Conductors',
              tradeId: '33 79 83',
            },
            {
              id: '6b2dfb9c-ca8b-4c83-8dfe-f699db1bbdf8',
              name: 'Grounding Wire, Bar and Rod',
              tradeId: '33 79 83.13',
            },
            {
              id: 'dfb69341-10b3-47ae-a78c-866160a125c3',
              name: 'Chemical Rod',
              tradeId: '33 79 83.16',
            },
            {
              id: '3ef2e39f-bf0d-4508-b1ae-94e401f75bef',
              name: 'Conductive Concrete',
              tradeId: '33 79 83.23',
            },
            {
              id: 'beb181eb-cff1-48d2-9f81-032694adfbf8',
              name: 'Earth Grounding Enhancement',
              tradeId: '33 79 83.33',
            },
            {
              id: '1f71ae03-95ee-4d02-b99b-7ee30d98de61',
              name: 'Deep Earth Grounding',
              tradeId: '33 79 83.43',
            },
            {
              id: 'b2cb77bc-26f7-421b-8050-1b8743e17f8a',
              name: 'Site Lightning Protection',
              tradeId: '33 79 93',
            },
            {
              id: '7eb192a2-0132-4be4-a8c2-b503e9385131',
              name: 'Lightning Strike Counters',
              tradeId: '33 79 93.13',
            },
            {
              id: 'f9f56ed2-0b42-49a4-8bdd-4475d07d0f4f',
              name: 'Lightning Strike Warning Devices',
              tradeId: '33 79 93.16',
            },
          ],
          tradeId: '33 79 00',
        },
        {
          id: '19e009a5-e297-4181-b322-37475712154c',
          name: 'Communications Utilities',
          children: [],
          tradeId: '33 80 00',
        },
        {
          id: 'dc616e95-fbc1-4129-8e23-07a09b7f102f',
          name: 'Communications Structures',
          children: [
            {
              id: '107dc24e-750a-4d7e-a7a5-aa54d4523422',
              name: 'Communications Structures',
              tradeId: '33 81 00',
            },
            {
              id: '5388cbe3-1fda-4020-b166-837518968043',
              name: 'Communications Transmission Towers',
              tradeId: '33 81 13',
            },
            {
              id: 'fbfc28dd-09ea-421c-9781-ae80b2131381',
              name: 'Antenna Towers',
              tradeId: '33 81 16',
            },
            {
              id: 'e80ddd5e-f2dd-42b2-bf43-59bbfe2207b4',
              name: 'Communications Utility Poles',
              tradeId: '33 81 19',
            },
            {
              id: 'd4f550ea-26ee-48e1-b5d1-017bfbfe8b18',
              name: 'Aerial Cable Installation Hardware',
              tradeId: '33 81 23',
            },
            {
              id: '6a062c90-404a-4023-b7c1-4dfeab1f24d5',
              name: 'Communications Underground Ducts, Manholes and Handholes',
              tradeId: '33 81 26',
            },
            {
              id: '0da67cf1-b485-4675-a749-d82f29923d0f',
              name: 'Communications Vaults, Pedestals and Enclosures',
              tradeId: '33 81 29',
            },
            {
              id: '6d8ed043-e310-4dcc-990d-82bf07b97624',
              name: 'Communications Blowers, Fans and Ventilation',
              tradeId: '33 81 33',
            },
          ],
          tradeId: '33 81 00',
        },
        {
          id: '4fa6e1d7-e332-4c3e-9429-e177d25b198b',
          name: 'Communications Distribution',
          children: [
            {
              id: '1e4f078f-6c51-4d2e-bb94-1be47fc65874',
              name: 'Communications Distribution',
              tradeId: '33 82 00',
            },
            {
              id: 'bc3d095f-7785-4fdb-b211-bbfca76f8e57',
              name: 'Copper Communications Distribution Cabling',
              tradeId: '33 82 13',
            },
            {
              id: 'bc2e3d6d-93a6-4573-b578-006ec429409b',
              name: 'Copper Splicing and Terminations',
              tradeId: '33 82 13.13',
            },
            {
              id: '363d5fa0-83eb-41a2-9d07-59a1a619df17',
              name: 'Optical Fiber Communications Distribution Cabling',
              tradeId: '33 82 23',
            },
            {
              id: 'dfe64cf8-9f31-49fa-b784-c2e861fcf1df',
              name: 'Optical Fiber Splicing and Terminations',
              tradeId: '33 82 23.13',
            },
            {
              id: '39b44c2a-3ad9-4a44-a129-63da284b56bf',
              name: 'Coaxial Communications Distribution Cabling',
              tradeId: '33 82 33',
            },
            {
              id: 'abd7eb60-4a75-4eca-9806-111600792a34',
              name: 'Coaxial Splicing and Terminations',
              tradeId: '33 82 33.13',
            },
            {
              id: 'cd6bcfe1-cba9-4d29-8bd2-834066cbf6a3',
              name: 'Grounding and Bonding for Communications Distribution',
              tradeId: '33 82 43',
            },
            {
              id: '2441f817-9cc8-4f24-99a7-9704008ae94a',
              name: 'Cable Pressurization Equipment',
              tradeId: '33 82 46',
            },
            {
              id: '9e10b6c7-c1dc-47ef-aefb-fff343216b5b',
              name: 'Cleaning, Lubrication and Restoration Chemicals',
              tradeId: '33 82 53',
            },
          ],
          tradeId: '33 82 00',
        },
        {
          id: 'f6989c00-1751-4df7-87e8-c9344c98419c',
          name: 'Wireless Communications Distribution',
          children: [
            {
              id: 'c4262529-22af-47e4-9f82-01c08c8519c6',
              name: 'Wireless Communications Distribution',
              tradeId: '33 83 00',
            },
            {
              id: '76b3189c-7b29-4f7c-8f11-2504b0d6f60a',
              name: 'Laser Transmitters and Receivers',
              tradeId: '33 83 13',
            },
            {
              id: '3d3b5779-8bec-4423-ac5e-66bef2a5c4c1',
              name: 'Microwave Transmitters and Receivers',
              tradeId: '33 83 16',
            },
            {
              id: '45cc09be-5761-440f-bee1-29eec0f25478',
              name: 'Infrared Transmitters and Receivers',
              tradeId: '33 83 19',
            },
            {
              id: '7d011981-4389-4fd0-9835-88e90e959284',
              name: 'UHF/VHF Transmitters and Antennas',
              tradeId: '33 83 23',
            },
          ],
          tradeId: '33 83 00',
        },
      ],
      tradeId: '33 00 00',
    },
    {
      id: '35a17068-fe19-4028-b341-852ee801818f',
      name: 'Transportation',
      children: [
        {
          id: '7ae4eaba-8c8e-4e56-84ed-02eb9885a67c',
          name: 'Transportation',
          children: [],
          tradeId: '34 00 00',
        },
        {
          id: '0084cbea-8ca3-416c-a39b-0f7537b058ca',
          name: 'Operation and Maintenance of Transportation',
          children: [
            {
              id: 'b86d3ca9-889b-4537-aee3-965971bd70df',
              name: 'Operation and Maintenance of Transportation',
              tradeId: '34 01 00',
            },
            {
              id: '898e1638-82c1-42af-8159-0b09ce813b56',
              name: 'Operation and Maintenance of Roadways',
              tradeId: '34 01 13',
            },
            {
              id: 'eda9d510-a69f-4197-a163-d60109c36552',
              name: 'Operation and Maintenance of Railways',
              tradeId: '34 01 23',
            },
            {
              id: '42685e9e-aa0b-4b3d-89b4-5cb2e753003d',
              name: 'Track Removal and Salvage',
              tradeId: '34 01 23.13',
            },
            {
              id: '1384b58a-b5d0-4fbe-83a9-529f6ac4e2f9',
              name: 'Track Crosstie Replacement',
              tradeId: '34 01 23.81',
            },
            {
              id: '96391d38-4110-4d71-9160-c2d80d86f82d',
              name: 'Operation and Maintenance of Airfields',
              tradeId: '34 01 33',
            },
            {
              id: '587ec3cd-20fc-44d9-b108-22b63c658c85',
              name: 'Operation and Maintenance of Bridges',
              tradeId: '34 01 43',
            },
          ],
          tradeId: '34 01 00',
        },
        {
          id: 'e2917c8b-5fff-4763-b2f2-5bceebc9eda5',
          name: 'Common Work Results for Transportation',
          children: [
            {
              id: 'a232a99a-d5ff-4ae9-aca0-8abbef5f99e0',
              name: 'Common Work Results for Transportation',
              tradeId: '34 05 00',
            },
            {
              id: '87f8b3f1-4ff0-411f-ae43-4bd876e7bd9f',
              name: 'Common Work Results for Roadways',
              tradeId: '34 05 13',
            },
            {
              id: '66644abd-d028-4340-a60f-c5508bf9b531',
              name: 'Common Work Results for Railways',
              tradeId: '34 05 23',
            },
            {
              id: 'd145f876-52d6-4bda-9d2d-b81d8b6efd5e',
              name: 'Common Work Results for Airports',
              tradeId: '34 05 33',
            },
            {
              id: 'fa0bf660-9db4-4de1-a279-d72fd305b91f',
              name: 'Common Work Results for Bridges',
              tradeId: '34 05 43',
            },
          ],
          tradeId: '34 05 00',
        },
        {
          id: '34e062ce-15c1-4266-b8ba-1ac553ad8eed',
          name: 'Schedules for Transportation',
          children: [
            {
              id: '7cd6cb07-a1e1-4ce2-b9bc-ec9d1717ae37',
              name: 'Schedules for Transportation',
              tradeId: '34 06 00',
            },
            {
              id: '46db6e54-9231-41c9-89aa-89204d67352c',
              name: 'Schedules for Roadways',
              tradeId: '34 06 13',
            },
            {
              id: 'b3b526ad-edfb-4a78-87d3-2105161de661',
              name: 'Schedules for Railways',
              tradeId: '34 06 23',
            },
            {
              id: 'e3a7a44a-9a43-4a46-b7f7-5b2b0b4a1058',
              name: 'Schedules for Airfields',
              tradeId: '34 06 33',
            },
            {
              id: '1ef91658-238e-4168-9fe8-7442699c4392',
              name: 'Schedules for Bridges',
              tradeId: '34 06 43',
            },
          ],
          tradeId: '34 06 00',
        },
        {
          id: 'f5049382-577b-43ed-bba9-cad4ef3a1f72',
          name: 'Commissioning of Transportation',
          children: [
            {
              id: 'f4de2724-f83f-4b46-b562-d8941c2c5d88',
              name: 'Commissioning of Transportation',
              tradeId: '34 08 00',
            },
            {
              id: 'c19a03c2-fe6b-44d1-b72a-20f3e201ba0f',
              name: 'Commissioning of Roadways',
              tradeId: '34 08 13',
            },
            {
              id: '3c82e16e-8c3a-45e4-932f-d9e467f9d671',
              name: 'Commissioning of Railways',
              tradeId: '34 08 23',
            },
            {
              id: 'c401d860-a137-4f1a-8dba-a4d4e8d587e5',
              name: 'Commissioning of Airfields',
              tradeId: '34 08 33',
            },
            {
              id: '7356a55e-4236-4504-8c82-1e6ed9ce8d5e',
              name: 'Commissioning of Bridges',
              tradeId: '34 08 43',
            },
          ],
          tradeId: '34 08 00',
        },
        {
          id: 'e821e542-3dc6-4fbf-9c08-49ad385a1460',
          name: 'Guideways/Railways',
          children: [],
          tradeId: '34 10 00',
        },
        {
          id: '881e9b00-27a7-4ab6-b061-5459f49eee1d',
          name: 'Rail Tracks',
          children: [
            {
              id: 'c66201c9-1c14-4110-a623-637928d1c521',
              name: 'Rail Tracks',
              tradeId: '34 11 00',
            },
            {
              id: 'cfbb3f92-f8f2-4d7d-b9c9-27a86489fd23',
              name: 'Track Rails',
              tradeId: '34 11 13',
            },
            {
              id: '54c2e13e-d19d-4714-9712-6e2f05e5070a',
              name: 'Light Rail Track',
              tradeId: '34 11 13.13',
            },
            {
              id: '19390b72-660b-4723-b77c-3b1349aabe07',
              name: 'Heavy Rail Track',
              tradeId: '34 11 13.23',
            },
            {
              id: 'cce4e7b8-9698-4eda-b67d-2ddd04493547',
              name: 'Welded Track Rails',
              tradeId: '34 11 16',
            },
            {
              id: '08e41829-62e5-405a-a862-348135d9e4cf',
              name: 'In-Track Butt-Welded Track Rail',
              tradeId: '34 11 16.13',
            },
            {
              id: 'b3c632b5-a5a8-44e4-873c-7da4c20b2de2',
              name: 'Pressure-Welded Track Rail',
              tradeId: '34 11 16.16',
            },
            {
              id: 'b7492e56-3e33-4924-8d39-04c4d71db6c7',
              name: 'Thermite-Welded Track Rail',
              tradeId: '34 11 16.19',
            },
            {
              id: 'a304ee84-bf9e-48ca-8e8d-dd9a40a5ea9e',
              name: 'Track Rail Joints',
              tradeId: '34 11 19',
            },
            {
              id: '18000494-adaf-4c02-a82a-feb185c5cf9c',
              name: 'Special Trackwork',
              tradeId: '34 11 23',
            },
            {
              id: 'b2c88638-2d28-4713-a257-2cb1fdbb6d73',
              name: 'Ballasted Special Track Rail',
              tradeId: '34 11 23.13',
            },
            {
              id: '5ebbfe20-0bc6-45c4-b42e-7ecc49466747',
              name: 'Direct-Fixation Track',
              tradeId: '34 11 23.16',
            },
            {
              id: '2531f58d-6dee-4c14-938a-6c13739a85d8',
              name: 'Running Rail',
              tradeId: '34 11 23.23',
            },
            {
              id: 'af5d20ac-faa1-4fb7-b1a0-45087a9a72e2',
              name: 'Precurved Running Rail',
              tradeId: '34 11 23.26',
            },
            {
              id: 'ba420aa4-9b4b-4595-b5c4-20eee95f14c3',
              name: 'Ballasted Track Rail',
              tradeId: '34 11 26',
            },
            {
              id: 'c9ed4477-ab48-471a-91ea-930ffe7c0e76',
              name: 'Track Rail Ballast',
              tradeId: '34 11 26.13',
            },
            {
              id: '7a8ba903-f086-4d85-9b6b-977f0f9d35c2',
              name: 'Track Rail Subballast',
              tradeId: '34 11 26.16',
            },
            {
              id: 'caf23c97-d3d6-4692-b9fe-b79cb76ec790',
              name: 'Embedded Track Rail',
              tradeId: '34 11 29',
            },
            {
              id: '42ed67a6-863a-48e8-9fe6-225eec5cd4ac',
              name: 'Track Cross Ties',
              tradeId: '34 11 33',
            },
            {
              id: '79aa7b78-ad1f-40dd-8551-c1197fbe5bf5',
              name: 'Concrete Track Cross Ties',
              tradeId: '34 11 33.13',
            },
            {
              id: '0b5264b1-496b-4e06-a30b-432f794d0c9b',
              name: 'Timber Track Cross Ties',
              tradeId: '34 11 33.16',
            },
            {
              id: 'b0625649-eb05-49f3-84fb-60123ad2176e',
              name: 'Resilient Track Cross Ties',
              tradeId: '34 11 33.19',
            },
            {
              id: 'e81b012d-9fc7-4a47-a1fc-75ccfc54eabf',
              name: 'Track Rail Fasteners',
              tradeId: '34 11 36',
            },
            {
              id: '114b8792-0ee8-4a6b-a5a8-ecbca3950af8',
              name: 'Direct-Fixation Fasteners',
              tradeId: '34 11 36.13',
            },
            {
              id: 'f6d96ce7-cd2e-4289-bc5d-7e9c5324a2a7',
              name: 'Track Collector Pans',
              tradeId: '34 11 39',
            },
            {
              id: '9cb1747f-e171-41ef-8547-ff193c199557',
              name: 'Fiberglass Track Collector Pans',
              tradeId: '34 11 39.13',
            },
            {
              id: '90be8be3-633a-43fd-afb5-93a7c390e3d9',
              name: 'Track Appurtenances and Accessories',
              tradeId: '34 11 93',
            },
          ],
          tradeId: '34 11 00',
        },
        {
          id: '27937bc8-bba6-4b29-a928-23f37c6552ef',
          name: 'Monorails',
          children: [
            {
              id: '31050786-d299-4a7e-bfe8-65c57396956f',
              name: 'Monorails',
              tradeId: '34 12 00',
            },
            {
              id: 'dc2e5f55-27aa-4703-a353-7f23387ae492',
              name: 'Elevated Monorails',
              tradeId: '34 12 13',
            },
            {
              id: 'bea2200a-9e1d-49a1-9c2d-bb3a6bc884db',
              name: 'On-Grade Monorails',
              tradeId: '34 12 16',
            },
            {
              id: '5527cf07-125c-4822-b496-d10a89e6e242',
              name: 'Below-Grade Monorails',
              tradeId: '34 12 19',
            },
            {
              id: '16bc1eb1-09ef-43d7-a2e5-15090f4aeb04',
              name: 'Maglev Monorail',
              tradeId: '34 12 23',
            },
            {
              id: '64d8e7a5-fc5b-4bbd-94e0-d602e3dffe58',
              name: 'Monorail Track',
              tradeId: '34 12 63',
            },
          ],
          tradeId: '34 12 00',
        },
        {
          id: 'b5a86a7e-387b-450c-9e47-75526694cedc',
          name: 'Funiculars',
          children: [
            {
              id: '801ae842-7abb-4a60-87ea-a762941eefc5',
              name: 'Funiculars',
              tradeId: '34 13 00',
            },
            {
              id: '33578633-8d4a-43c6-a458-de6b27cd0940',
              name: 'Inclined Railway',
              tradeId: '34 13 13',
            },
          ],
          tradeId: '34 13 00',
        },
        {
          id: 'af0ce82d-14df-4732-9d40-ade948b990b4',
          name: 'Cable Transportation',
          children: [
            {
              id: '3b26c730-4b95-47ab-a637-42db4d9fde4e',
              name: 'Cable Transportation',
              tradeId: '34 14 00',
            },
            {
              id: '920dc3f2-42c4-4b17-9e75-421c3b21fce7',
              name: 'Aerial Tramways',
              tradeId: '34 14 13',
            },
            {
              id: '97a32413-0eb0-4443-ad7a-57512ec4b9b1',
              name: 'Gondolas',
              tradeId: '34 14 19',
            },
            {
              id: '8a4df789-9daa-4e70-9424-1dafca840bc8',
              name: 'Funitels',
              tradeId: '34 14 26',
            },
            {
              id: '6c09cd36-f504-40f7-a9cf-d2f65f478875',
              name: 'Chairlifts',
              tradeId: '34 14 33',
            },
            {
              id: 'eee7fbc9-51c5-4d00-95a1-3345eadd1323',
              name: 'Surface Lifts',
              tradeId: '34 14 39',
            },
            {
              id: '7a606e60-6e37-46bc-b8a6-64d05a3ad0b9',
              name: 'Ropeway Tows',
              tradeId: '34 14 46',
            },
            {
              id: 'e422b875-95ac-4563-8d9f-3b1f3852ed3d',
              name: 'Cable Car Systems',
              tradeId: '34 14 53',
            },
          ],
          tradeId: '34 14 00',
        },
        {
          id: 'b0434580-838f-491a-9434-b6a62dd5b6ab',
          name: 'Traction Power',
          children: [],
          tradeId: '34 20 00',
        },
        {
          id: '5ed2378e-12bc-4ccf-9304-b75d0cadf74f',
          name: 'Traction Power Distribution',
          children: [
            {
              id: 'a4af3323-24d7-44e8-b887-de7da9d27cec',
              name: 'Traction Power Distribution',
              tradeId: '34 21 00',
            },
            {
              id: '3b2a830d-6cf3-4341-8ba5-431b5710c91a',
              name: 'High Power Static Frequency Converters',
              tradeId: '34 21 13',
            },
            {
              id: 'ee7d7d01-25b1-4d29-89b8-5e6bc551608e',
              name: 'Traction Power Substations',
              tradeId: '34 21 16',
            },
            {
              id: '4317e7bb-c5b7-4a5a-8d85-6fe85bd7258e',
              name: 'AC Traction Power Substations',
              tradeId: '34 21 16.13',
            },
            {
              id: '57fce5f6-0802-4ca5-834a-327ffb055583',
              name: 'DC Traction Power Substations',
              tradeId: '34 21 16.16',
            },
            {
              id: '01c5c232-c4a8-43ab-97bc-a3ea0e313a09',
              name: 'Traction Power Switchgear',
              tradeId: '34 21 19',
            },
            {
              id: '8a90df3b-7753-4a2c-a01b-e2fbbac25374',
              name: 'AC Traction Power Switchgear',
              tradeId: '34 21 19.13',
            },
            {
              id: '8624c7e9-7f89-45f2-8bdd-3355bfc4dfc1',
              name: 'DC Traction Power Switchgear',
              tradeId: '34 21 19.16',
            },
            {
              id: '318b7291-38c2-4749-8d17-72ec484ffbf2',
              name: 'Frequency Changer',
              tradeId: '34 21 19.23',
            },
            {
              id: '7025126b-f220-439f-a895-b67a5c76cf1d',
              name: 'Traction Power Transformer-Rectifier Units',
              tradeId: '34 21 23',
            },
          ],
          tradeId: '34 21 00',
        },
        {
          id: 'c160767e-cdc6-4791-a6e9-b31695af8ad6',
          name: 'Overhead Traction Power',
          children: [
            {
              id: 'fed20512-ca83-4bee-8741-2042a2c97cf4',
              name: 'Overhead Traction Power',
              tradeId: '34 23 00',
            },
            {
              id: 'bf89769a-2100-49c0-b499-b8d8d11ade77',
              name: 'Traction Power Poles',
              tradeId: '34 23 13',
            },
            {
              id: '5af0c174-1743-40e3-9c0e-e1af87a58e91',
              name: 'Overhead Cable Suspension',
              tradeId: '34 23 16',
            },
            {
              id: 'b04f2518-7753-4110-845c-7e0756b5cc44',
              name: 'Overhead Traction Power Cables',
              tradeId: '34 23 23',
            },
          ],
          tradeId: '34 23 00',
        },
        {
          id: '4ab63f3a-e620-4882-8063-91b31e8395fd',
          name: 'Third Rail Traction Power',
          children: [
            {
              id: 'b1d1cad9-aeed-498c-842c-e1e03fc6a53c',
              name: 'Third Rail Traction Power',
              tradeId: '34 24 00',
            },
            {
              id: '6e344cce-cecd-46d3-963d-4229262269f9',
              name: 'Bottom-Contact Third Rail',
              tradeId: '34 24 13',
            },
            {
              id: '5b2aadad-7e92-4a9c-9661-79016ad37d17',
              name: 'Side-Contact Third Rail',
              tradeId: '34 24 16',
            },
            {
              id: 'fdd15f41-95c2-4aa0-94d5-38518408e944',
              name: 'Top-Contact Third Rail',
              tradeId: '34 24 19',
            },
          ],
          tradeId: '34 24 00',
        },
        {
          id: 'bff8e2fa-24b6-4d47-80b8-9d08c2dac6d4',
          name: 'Transportation Signaling and Control Equipment',
          children: [],
          tradeId: '34 40 00',
        },
        {
          id: '8029b4e5-9723-41d8-a346-1e417fad8eee',
          name: 'Roadway Signaling and Control Equipment',
          children: [
            {
              id: '41e4bad6-4409-4e85-9ee2-6cebc384a95b',
              name: 'Roadway Signaling and Control Equipment',
              tradeId: '34 41 00',
            },
            {
              id: '3303c03e-ee7b-4c5e-ae84-50f475c69ea3',
              name: 'Traffic Signals',
              tradeId: '34 41 13',
            },
            {
              id: '5f0e59d2-1037-44f2-95a8-23595a477266',
              name: 'Traffic Control Equipment',
              tradeId: '34 41 16',
            },
            {
              id: 'e87aeee1-7bd4-4303-b5ed-785e154df043',
              name: 'Roadway Monitoring Equipment',
              tradeId: '34 41 23',
            },
          ],
          tradeId: '34 41 00',
        },
        {
          id: '281615be-7a71-4e0f-ab12-58c33e8318b8',
          name: 'Railway Signaling and Control Equipment',
          children: [
            {
              id: 'bce48474-4d01-4877-a91b-6d1337bdd527',
              name: 'Railway Signaling and Control Equipment',
              tradeId: '34 42 00',
            },
            {
              id: '58cc2b91-6ebc-4a87-84da-9fb4722c30fc',
              name: 'Railway Signals',
              tradeId: '34 42 13',
            },
            {
              id: 'b13b1da8-875f-4cf9-8071-ee4bbf9414a0',
              name: 'General Railway Signal Requirements',
              tradeId: '34 42 13.13',
            },
            {
              id: 'bf643d32-d118-4f0a-b786-2b0b14c2959a',
              name: 'Signal Solid State Coded Track Circuits',
              tradeId: '34 42 13.16',
            },
            {
              id: 'dc23149a-aa0e-40d5-9498-ef706074a562',
              name: 'Train Control Wires and Cables',
              tradeId: '34 42 16',
            },
            {
              id: '51b2a786-5e12-4dce-8a33-a23cfd7c9d43',
              name: 'Vital Interlocking Logic Controllers',
              tradeId: '34 42 19',
            },
            {
              id: '8b1944aa-e978-4ec6-88bd-a158a75a5e84',
              name: 'Railway Control Equipment',
              tradeId: '34 42 23',
            },
            {
              id: '3e6a8462-f257-4de3-91f1-c96757614070',
              name: 'Mainline Train Control Room Equipment',
              tradeId: '34 42 23.13',
            },
            {
              id: 'b6918aef-e899-411c-9656-3fc8209e9a09',
              name: 'Yard Train Control Room Equipment',
              tradeId: '34 42 23.16',
            },
            {
              id: '8dadc860-5387-402a-8f9a-6be52d18b7cf',
              name: 'Integrated Control Equipment',
              tradeId: '34 42 23.19',
            },
            {
              id: 'f090d934-15fc-4211-92a5-dbae0c402fff',
              name: 'Interlocking Railway Control Equipment',
              tradeId: '34 42 23.23',
            },
            {
              id: 'b754a12e-90d3-4d86-a0c5-e2ce53f08ed8',
              name: 'Rail Network Equipment',
              tradeId: '34 42 26',
            },
            {
              id: '052b9050-7f4a-4860-ad95-e61dfd2b0668',
              name: 'Station Agent Equipment',
              tradeId: '34 42 29',
            },
            {
              id: 'c46ec7bf-a68b-4df5-8843-15c3bbc1eb9d',
              name: 'Yard Management Equipment',
              tradeId: '34 42 33',
            },
            {
              id: '9a9ecc08-a6d5-4a87-9d67-549053d13e5f',
              name: 'Supervisory Control and Data Acquisition',
              tradeId: '34 42 36',
            },
          ],
          tradeId: '34 42 00',
        },
        {
          id: 'fa8bc64d-67c1-4a1a-84f5-6868fc389af7',
          name: 'Airfield Signaling and Control Equipment',
          children: [
            {
              id: '452860b8-1f57-428b-8b5a-32bab49d8e4d',
              name: 'Airfield Signaling and Control Equipment',
              tradeId: '34 43 00',
            },
            {
              id: 'fcbca861-57cb-4977-a6c0-201a83d8f7d7',
              name: 'Airfield Signals',
              tradeId: '34 43 13',
            },
            {
              id: '3d4ad875-8d72-481f-bb30-74418d71f1bb',
              name: 'Airfield Runway Identification Lights',
              tradeId: '34 43 13.13',
            },
            {
              id: '9e566115-5fb8-4cf5-b620-d5c2abd77575',
              name: 'Airfield Runway and Taxiway Inset Lighting',
              tradeId: '34 43 13.16',
            },
            {
              id: 'bcb488b0-64df-46b2-8522-6f60ad7ef19b',
              name: 'Airfield Landing Equipment',
              tradeId: '34 43 16',
            },
            {
              id: 'a9a9a81d-65fe-4a9b-b63d-ad2a9f08bca6',
              name: 'Microwave Airfield Landing Equipment',
              tradeId: '34 43 16.13',
            },
            {
              id: 'f49da757-9a15-4222-9490-e4d5acf01438',
              name: 'Instrument Airfield Landing Equipment',
              tradeId: '34 43 16.16',
            },
            {
              id: '27de2d0c-4fdf-499d-8deb-de77cd35a110',
              name: 'Airfield Visual-Approach Slope Indicator Equipment',
              tradeId: '34 43 16.19',
            },
            {
              id: '9d2b211d-c1a4-4744-9564-2307af738103',
              name: 'Airfield Short-Approach Lighting Equipment',
              tradeId: '34 43 16.23',
            },
            {
              id: '4f9b70bf-a91e-4d0a-8303-c007298452bd',
              name: 'Airfield Omni-Directional-Approach Lighting Equipment',
              tradeId: '34 43 16.26',
            },
            {
              id: '2b367853-53a6-4c1c-8c42-803c3f2829bc',
              name: 'Airfield Low-Intensity-Approach Lighting Equipment',
              tradeId: '34 43 16.29',
            },
            {
              id: 'aebc2115-c4ea-40f5-bae3-c1e34d5e60be',
              name: 'Airfield High-Intensity-Approach Lighting Equipment',
              tradeId: '34 43 16.33',
            },
            {
              id: '68fe2541-5bdb-4f94-9f6b-57a148a4c6ab',
              name: 'Airfield Precision-Approach Path Indicator Equipment',
              tradeId: '34 43 16.36',
            },
            {
              id: '79861fff-cc2f-44e7-91c4-3713d4dd840a',
              name: 'Airfield Traffic Control Tower Equipment',
              tradeId: '34 43 19',
            },
            {
              id: '71e5f84d-5d17-4f37-9593-52faedba757a',
              name: 'Weather Observation Equipment',
              tradeId: '34 43 23',
            },
            {
              id: '02f4da64-8c6a-41aa-adbf-66de9a735fae',
              name: 'Automatic Weather Observation Equipment',
              tradeId: '34 43 23.13',
            },
            {
              id: '315e04f6-e610-439c-ab29-387d099dfe9b',
              name: 'Airfield Wind Cones',
              tradeId: '34 43 23.16',
            },
            {
              id: '2e2b7fd5-0986-4ecb-ab24-a288468f8b03',
              name: 'Airfield Control Equipment',
              tradeId: '34 43 26',
            },
            {
              id: '4fd65888-1801-48d6-9dd7-74bbdea02c9a',
              name: 'Airfield Lighting Control Equipment',
              tradeId: '34 43 26.13',
            },
            {
              id: '7ae17314-0398-49ef-9d16-2317bed27c81',
              name: 'Airfield Lighting PLC Control Equipment',
              tradeId: '34 43 26.16',
            },
            {
              id: '704fb665-e582-4eae-b513-7c28b382904f',
              name: 'Airfield Lighting Regulator Assembly',
              tradeId: '34 43 26.19',
            },
          ],
          tradeId: '34 43 00',
        },
        {
          id: '145f0c20-1bad-46ed-a656-e89cb46e05e7',
          name: 'Bridge Signaling and Control Equipment',
          children: [
            {
              id: '7f91739c-316f-4cf1-a9db-82eec05297cc',
              name: 'Bridge Signaling and Control Equipment',
              tradeId: '34 48 00',
            },
            {
              id: 'c7ab55d3-eba0-4d57-aa2f-e21ef433b5df',
              name: 'Operating Bridge Signals',
              tradeId: '34 48 13',
            },
            {
              id: '385fb068-fa74-4235-9613-7c8471ae3912',
              name: 'Operating Bridge Control Equipment',
              tradeId: '34 48 16',
            },
          ],
          tradeId: '34 48 00',
        },
        {
          id: 'c13bfeaf-8bb3-44b6-ba48-713be6cabbad',
          name: 'Transportation Fare Collection Equipment',
          children: [],
          tradeId: '34 50 00',
        },
        {
          id: '6c376a43-bb63-4dec-a74f-dfd0c10f944b',
          name: 'Vehicle Fare Collection',
          children: [
            {
              id: '99ce6c48-6a23-4a12-860a-db1c98db8694',
              name: 'Vehicle Fare Collection',
              tradeId: '34 52 00',
            },
            {
              id: '45f420d9-62ea-4494-85ea-f618baaebeb3',
              name: 'Vehicle Ticketing Equipment',
              tradeId: '34 52 16',
            },
            {
              id: '2cbcd672-4050-4be2-9f98-6d51fe95f7d2',
              name: 'Vehicle Ticket Vending Machines',
              tradeId: '34 52 16.13',
            },
            {
              id: '42e453fd-6186-43c1-844f-0530c3c44be7',
              name: 'Vehicle Fare Collection Equipment',
              tradeId: '34 52 26',
            },
            {
              id: '587969a8-5f58-44ff-a463-fe21e0a5bf4d',
              name: 'Vehicle Coin Fare Collection Equipment',
              tradeId: '34 52 26.13',
            },
            {
              id: '0fa151a7-434b-45d7-89c8-99aaf795a3cd',
              name: 'Vehicle Electronic Fare Collection Equipment',
              tradeId: '34 52 26.16',
            },
            {
              id: 'd88b3c23-dcbe-47b7-b72a-8003b18621c8',
              name: 'Vehicle Fare Gates',
              tradeId: '34 52 33',
            },
          ],
          tradeId: '34 52 00',
        },
        {
          id: 'ca54700e-9148-4da7-8984-eece0b4ada3e',
          name: 'Passenger Fare Collection',
          children: [
            {
              id: 'ac47cd0d-97d9-42f1-adfe-e01c1b7dc33a',
              name: 'Passenger Fare Collection',
              tradeId: '34 54 00',
            },
            {
              id: '5ea66ba5-f8ca-47b5-aa2e-5f74052cfd79',
              name: 'Passenger Ticketing Equipment',
              tradeId: '34 54 16',
            },
            {
              id: '88787b72-1c08-4833-ad3f-8d6e30aebd83',
              name: 'Passenger Ticket Vending Machines',
              tradeId: '34 54 16.13',
            },
            {
              id: 'ba941b12-caa2-4c15-95fc-a9d1c819d909',
              name: 'Passenger Addfare Machines',
              tradeId: '34 54 16.16',
            },
            {
              id: 'acf8efa0-6197-40db-a7c1-45075785e3f0',
              name: 'Passenger Intermodal Transfer Machines',
              tradeId: '34 54 16.23',
            },
            {
              id: '6fbb004e-72a6-4aa5-af84-6689e7ad383b',
              name: 'Passenger Fare Collection Equipment',
              tradeId: '34 54 26',
            },
            {
              id: 'fb8d1c37-37bb-464e-be57-eb3f9ab371c8',
              name: 'Passenger Coin Fare Collection Equipment',
              tradeId: '34 54 26.13',
            },
            {
              id: '6e6ab37b-eae4-4807-8293-03bbfbe63b53',
              name: 'Passenger Electronic Fare Collection Equipment',
              tradeId: '34 54 26.16',
            },
            {
              id: 'cd1722ee-9bd4-4553-8228-a303f09a4575',
              name: 'Passenger Fare Gates',
              tradeId: '34 54 33',
            },
          ],
          tradeId: '34 54 00',
        },
        {
          id: '9c9fa9de-e4c9-46a0-b053-2a124569c6e1',
          name: 'Transportation Construction and Equipment',
          children: [],
          tradeId: '34 70 00',
        },
        {
          id: '87c5b957-b40f-4f31-8622-d2c5ae922ece',
          name: 'Roadway Construction',
          children: [
            {
              id: 'fafed975-ac73-479d-9608-da548620b46e',
              name: 'Roadway Construction',
              tradeId: '34 71 00',
            },
            {
              id: '26a4d557-789e-4af5-9344-5195faa35589',
              name: 'Vehicle Barriers',
              tradeId: '34 71 13',
            },
            {
              id: '9cdc0521-cf01-4915-afd8-617700cdc832',
              name: 'Vehicle Median Barriers',
              tradeId: '34 71 13.13',
            },
            {
              id: '5f50b46d-4c68-4198-9f31-a887b5f6ced6',
              name: 'Vehicle Crash Barriers',
              tradeId: '34 71 13.16',
            },
            {
              id: 'e4975cf1-49f3-4961-96b5-ebf7d10d4a51',
              name: 'Vehicle Traffic Barriers',
              tradeId: '34 71 13.19',
            },
            {
              id: '213267ae-3676-4c93-b5db-f2287fb19c2b',
              name: 'Vehicle Guide Rails',
              tradeId: '34 71 13.26',
            },
            {
              id: 'b38dbd2d-4c7c-42ac-a5ed-2b66a3fe810f',
              name: 'Vehicle Barrier Fenders',
              tradeId: '34 71 13.29',
            },
            {
              id: '377c7dce-4dd3-491c-b76d-7a0cac22fdee',
              name: 'Impact Attenuating Devices',
              tradeId: '34 71 16',
            },
            {
              id: '10a97d5b-9de5-4431-ada2-f8e7ccd65ed2',
              name: 'Vehicle Delineators',
              tradeId: '34 71 19',
            },
            {
              id: '434d10ee-fa9d-4406-8927-eb1342d14123',
              name: 'Fixed Vehicle Delineators',
              tradeId: '34 71 19.13',
            },
            {
              id: '0415aa51-2aff-485a-bc16-9d3c211c5d82',
              name: 'Flexible Vehicle Delineators',
              tradeId: '34 71 19.16',
            },
          ],
          tradeId: '34 71 00',
        },
        {
          id: 'd5f73a71-bbdc-4f21-962f-a6807bac2703',
          name: 'Railway Construction',
          children: [
            {
              id: '180388e2-264a-4b16-8082-7e8fd956479d',
              name: 'Railway Construction',
              tradeId: '34 72 00',
            },
            {
              id: 'a1c68c0e-b250-454f-bed5-3c363c64696d',
              name: 'Railway Line',
              tradeId: '34 72 13',
            },
            {
              id: '5dd6b90e-f0c2-4193-8b9f-6c6fa59c948f',
              name: 'Railway Siding',
              tradeId: '34 72 16',
            },
          ],
          tradeId: '34 72 00',
        },
        {
          id: '60560d76-bd0e-44aa-8b52-56358f017065',
          name: 'Airfield Construction',
          children: [
            {
              id: '813efd26-2bb1-4a87-9c2b-37305d1923ce',
              name: 'Airfield Construction',
              tradeId: '34 73 00',
            },
            {
              id: '35f8864a-18c9-4e3c-ae1e-3cf476102ce9',
              name: 'Aircraft Tiedowns',
              tradeId: '34 73 13',
            },
            {
              id: '9c59ee62-80cf-4681-b7cc-ab467b565455',
              name: 'Airfield Grounding',
              tradeId: '34 73 16',
            },
            {
              id: '33493083-db48-4b8d-9745-c685fb86fc99',
              name: 'Aircraft Static Grounding',
              tradeId: '34 73 16.13',
            },
            {
              id: '03072e0e-3b90-443b-8862-2c70fb0bb43d',
              name: 'Jet Blast Barriers',
              tradeId: '34 73 19',
            },
            {
              id: '5f71113d-7dea-4b0c-8533-0ab2c3c06c57',
              name: 'Manufactured Airfield Control Towers',
              tradeId: '34 73 23',
            },
            {
              id: '4bcb467a-1289-48fb-9cc1-5b7b38256377',
              name: 'Manufactured Helipads',
              tradeId: '34 73 26',
            },
          ],
          tradeId: '34 73 00',
        },
        {
          id: '37094f01-2277-459d-b624-f11247e662ec',
          name: 'Roadway Equipment',
          children: [
            {
              id: '2d68047a-fe8c-4114-b17d-2d2a7d20cdbd',
              name: 'Roadway Equipment',
              tradeId: '34 75 00',
            },
            {
              id: '1a4a5648-805a-4e6c-844e-693dd29e066f',
              name: 'Operable Roadway Equipment',
              tradeId: '34 75 13',
            },
            {
              id: 'ef019e53-4d5a-49bb-a755-68f3916fe1e2',
              name: 'Active Vehicle Barriers',
              tradeId: '34 75 13.13',
            },
          ],
          tradeId: '34 75 00',
        },
        {
          id: '1cd624c9-0864-4721-b160-f851fe85982c',
          name: 'Railway Equipment',
          children: [
            {
              id: '60723a81-91ab-425e-9dd5-ae9b6e35e5e5',
              name: 'Railway Equipment',
              tradeId: '34 76 00',
            },
            {
              id: 'de782638-5959-4f8a-afab-89fbc735b1a1',
              name: 'Roadway Crossing Control Equipment',
              tradeId: '34 76 13',
            },
          ],
          tradeId: '34 76 00',
        },
        {
          id: 'ecfa9ee1-485f-4af0-bad0-caf5d5b72989',
          name: 'Transportation Equipment',
          children: [
            {
              id: '2e1fa6ad-ccac-416c-a9d1-aee6438c6890',
              name: 'Transportation Equipment',
              tradeId: '34 77 00',
            },
            {
              id: 'c57bbee3-4b00-4301-9f50-4adcda5a048b',
              name: 'Passenger Boarding Bridges',
              tradeId: '34 77 13',
            },
            {
              id: '15f35010-c61f-4520-9b9f-0c1186194142',
              name: 'Fixed Aircraft Passenger Boarding Bridges',
              tradeId: '34 77 13.13',
            },
            {
              id: '7991cf0d-981d-426b-b5cb-0f8d6e525d7a',
              name: 'Movable Aircraft Passenger Boarding Bridges',
              tradeId: '34 77 13.16',
            },
            {
              id: '7a9ba095-9fc9-43fd-8813-ce1aa1abfca0',
              name: 'Ship Passenger Boarding Bridges',
              tradeId: '34 77 13.23',
            },
            {
              id: '8eb4b3a4-f1f7-4f25-a8c8-68edd1c9e8f2',
              name: 'Baggage Scanning Equipment',
              tradeId: '34 77 33',
            },
            {
              id: 'e3bcff9d-dd51-49da-9460-b4d50bf88175',
              name: 'Baggage Scales',
              tradeId: '34 77 36',
            },
            {
              id: '891f94f2-35a5-441f-93d8-71e559e1f49e',
              name: 'Baggage Conveying Equipment',
              tradeId: '34 77 39',
            },
          ],
          tradeId: '34 77 00',
        },
        {
          id: 'bc76a4d9-52fc-4b53-b7a8-39062f7d7cd7',
          name: 'Bridges',
          children: [],
          tradeId: '34 80 00',
        },
        {
          id: '3c262e06-90f3-410c-8dec-f2cb292bd824',
          name: 'Bridge Machinery',
          children: [
            {
              id: 'fd8ddf41-7715-46a0-8330-3cdd0dd97135',
              name: 'Bridge Machinery',
              tradeId: '34 81 00',
            },
            {
              id: '0643bc8c-d5b8-4ee2-92a7-d1ff2eb662d8',
              name: 'Single-Swing Bridge Machinery',
              tradeId: '34 81 13',
            },
            {
              id: 'c4109446-c75c-4b1e-a99d-ab0f9723467a',
              name: 'Double-Swing Bridge Machinery',
              tradeId: '34 81 16',
            },
            {
              id: 'd753dc81-9b18-4ff5-9518-4e6430d0f32d',
              name: 'Cantilever Bridge Machinery',
              tradeId: '34 81 19',
            },
            {
              id: '547b91ce-9c08-4c90-abf1-8bd83aa479b4',
              name: 'Lift Bridge Machinery',
              tradeId: '34 81 23',
            },
            {
              id: '4d0f2bb7-609f-4245-8ccd-9a1fc54d3814',
              name: 'Sliding Bridge Machinery',
              tradeId: '34 81 26',
            },
            {
              id: '1fff89b5-103e-41ab-adad-b59f5d5e4680',
              name: 'Pontoon Bridge Machinery',
              tradeId: '34 81 29',
            },
            {
              id: 'c1cda686-e9de-43dc-989d-9dd32f67d58b',
              name: 'Bascule Bridge Machinery',
              tradeId: '34 81 32',
            },
          ],
          tradeId: '34 81 00',
        },
        {
          id: '663914ad-9c41-4964-a557-f9825018d20c',
          name: 'Bridge Specialties',
          children: [
            {
              id: 'b5111cb2-b51d-4dfd-bf2e-e5de56076e14',
              name: 'Bridge Specialties',
              tradeId: '34 82 00',
            },
            {
              id: '751d9f38-624b-4ee8-bc19-bc211eaf3177',
              name: 'Bridge Vibration Dampers',
              tradeId: '34 82 13',
            },
            {
              id: '511303e7-6842-485b-9db8-e9ca579781f6',
              name: 'Visco Elastic Bridge Vibration Dampers',
              tradeId: '34 82 13.13',
            },
            {
              id: '63767975-08e1-47ac-a3d3-00e8fd4a56a7',
              name: 'Tuned-Mass Bridge Vibration Dampers',
              tradeId: '34 82 13.16',
            },
            {
              id: 'e5778d0a-a4c5-44fa-b9da-04638cd6fb8c',
              name: 'Bridge Pier Protection',
              tradeId: '34 82 19',
            },
            {
              id: '4ac32026-858b-428b-8b23-1e470783d95f',
              name: 'Bridge Pier Ice Shields',
              tradeId: '34 82 19.13',
            },
          ],
          tradeId: '34 82 00',
        },
      ],
      tradeId: '34 00 00',
    },
    {
      id: 'f0181bc3-62df-4873-9719-82fc45e41066',
      name: 'Waterway and Marine Construction',
      children: [
        {
          id: 'fd442810-ec97-444c-b7ce-1706286369e3',
          name: 'Waterway and Marine Construction',
          children: [],
          tradeId: '35 00 00',
        },
        {
          id: 'e3b6daeb-1ee0-4389-a55b-ec4bf5a12bd2',
          name: 'Operation and Maintenance of Waterway and Marine Construction',
          children: [
            {
              id: '5e79599f-a7a1-447d-8d58-e808a64ecd91',
              name: 'Operation and Maintenance of Waterway and Marine Construction',
              tradeId: '35 01 00',
            },
            {
              id: '4af739c7-1fb7-45a8-adea-0efbda33bb21',
              name: 'Operation and Maintenance of Coastal Construction',
              tradeId: '35 01 30',
            },
            {
              id: '19792d7f-98f5-4a09-9c23-2bfa1fb8df24',
              name: 'Operation and Maintenance of Waterway Construction',
              tradeId: '35 01 40',
            },
            {
              id: '98882873-d8b0-4841-a464-89b7927e2195',
              name: 'Waterway Dredging',
              tradeId: '35 01 40.51',
            },
            {
              id: '1c10d7b4-8abb-43d5-b497-d4eed287351e',
              name: 'Preservation of Water Courses',
              tradeId: '35 01 40.92',
            },
            {
              id: '61dca13b-aeb3-487e-9eb3-3925f1badd0e',
              name: 'Operation and Maintenance of Marine Construction',
              tradeId: '35 01 50',
            },
            {
              id: 'd0a01e94-c920-4edd-80cb-bce7b67d6546',
              name: 'Marine Dredging',
              tradeId: '35 01 50.51',
            },
            {
              id: '6adca22d-9d5f-4df9-a9a5-dd82eac5c009',
              name: 'Channel Excavation, Cleaning and Deepening',
              tradeId: '35 01 50.71',
            },
            {
              id: '45711941-ccaa-4cb3-857a-1d0af0c1a591',
              name: 'Operation and Maintenance of Dams',
              tradeId: '35 01 70',
            },
          ],
          tradeId: '35 01 00',
        },
        {
          id: 'b8eee617-703a-420d-aeaf-130854c8945c',
          name: 'Common Work Results for Waterway and Marine Construction',
          children: [
            {
              id: 'fb3b9874-0495-4690-a5b2-6ad8da53efcb',
              name: 'Common Work Results for Waterway and Marine Construction',
              tradeId: '35 05 00',
            },
            {
              id: '23ed2a9e-5321-4158-a34d-576a2c81183a',
              name: 'Common Work Results for Coastal Construction',
              tradeId: '35 05 30',
            },
            {
              id: '524adea9-b000-4d0b-9de2-eb00b4d5bc14',
              name: 'Common Work Results for Waterway Construction',
              tradeId: '35 05 40',
            },
            {
              id: 'a81095b8-4a2f-4189-bc7e-faed76210e5d',
              name: 'Common Work Results for Marine Construction',
              tradeId: '35 05 50',
            },
            {
              id: 'c6c7b941-6b6d-411a-96f4-7a1c49d37d67',
              name: 'Common Work Results for Dams',
              tradeId: '35 05 70',
            },
          ],
          tradeId: '35 05 00',
        },
        {
          id: 'ec461e41-012f-466d-b300-8c401b5d7f26',
          name: 'Schedules for Waterway and Marine Construction',
          children: [
            {
              id: 'f9aa41fe-10c0-454b-81ee-a13681c3b11d',
              name: 'Schedules for Waterway and Marine Construction',
              tradeId: '35 06 00',
            },
            {
              id: 'd8abd34d-c87d-4281-a053-dc8d51c5e890',
              name: 'Schedules for Coastal Construction',
              tradeId: '35 06 30',
            },
            {
              id: '29555857-f3c6-49e8-b181-d29d2b021ed0',
              name: 'Schedules for Waterway Construction',
              tradeId: '35 06 40',
            },
            {
              id: '81bef31a-2fa9-40fc-ae14-2495e8a03abe',
              name: 'Schedules for Marine Construction',
              tradeId: '35 06 50',
            },
            {
              id: '0fed3ee9-186a-4ea8-8231-19678072cf1e',
              name: 'Schedules for Dams',
              tradeId: '35 06 70',
            },
          ],
          tradeId: '35 06 00',
        },
        {
          id: 'dd43b66a-eb06-4f3d-910b-90a1a538e611',
          name: 'Commissioning of Waterway and Marine Construction',
          children: [
            {
              id: 'b7b3267c-78b1-4dfd-b1df-82f46a4edc9a',
              name: 'Commissioning of Waterway and Marine Construction',
              tradeId: '35 08 00',
            },
            {
              id: '4472a7ca-c6b4-493b-9ff3-edfb00256571',
              name: 'Commissioning of Coastal Construction',
              tradeId: '35 08 30',
            },
            {
              id: '893a0751-4f0a-41b8-8a2a-cb11d090a8ea',
              name: 'Commissioning of Waterway Construction',
              tradeId: '35 08 40',
            },
            {
              id: '95c3be5f-364b-45d7-a601-4ffcff50c49e',
              name: 'Commissioning of Marine Construction',
              tradeId: '35 08 50',
            },
            {
              id: 'a7b4b8a1-d405-483a-955d-59c75d5eb42a',
              name: 'Commissioning of Dams',
              tradeId: '35 08 70',
            },
          ],
          tradeId: '35 08 00',
        },
        {
          id: '3e292f93-d097-4212-98ce-f9381689f583',
          name: 'Waterway and Marine Signaling and Control Equipment',
          children: [],
          tradeId: '35 10 00',
        },
        {
          id: '5ac14ee9-830a-4573-a161-11db9216c413',
          name: 'Signaling and Control Equipment for Waterways',
          children: [
            {
              id: 'f74bf1bf-db80-4715-802e-8f8f53176021',
              name: 'Signaling and Control Equipment for Waterways',
              tradeId: '35 11 00',
            },
            {
              id: 'a90d1345-a508-40b8-bbff-f687616f1ded',
              name: 'Signaling Equipment for Waterways',
              tradeId: '35 11 13',
            },
            {
              id: '887a1d9f-f735-4880-bb10-f0bbc93ec3e8',
              name: 'Control Equipment for Waterways',
              tradeId: '35 11 53',
            },
          ],
          tradeId: '35 11 00',
        },
        {
          id: '15956142-52b8-4d34-a36b-a4c1ee318c25',
          name: 'Marine Signaling and Control Equipment',
          children: [
            {
              id: '544e06b3-21aa-47bf-9d49-6a73da8c84c4',
              name: 'Marine Signaling and Control Equipment',
              tradeId: '35 12 00',
            },
            {
              id: '87bea8d5-f80f-402e-a503-0b1de4056b91',
              name: 'Marine Signaling Equipment',
              tradeId: '35 12 13',
            },
            {
              id: 'ca582890-0835-429b-b818-b89b17e117e4',
              name: 'Lighthouse Equipment',
              tradeId: '35 12 13.13',
            },
            {
              id: '1bb8b44d-40e7-44d5-bec8-aa44e1da6c05',
              name: 'Marine Navigation Equipment',
              tradeId: '35 12 33',
            },
            {
              id: 'f8682a0f-715a-4b41-91cd-e1b19bf4de5c',
              name: 'Marine Control Equipment',
              tradeId: '35 12 53',
            },
          ],
          tradeId: '35 12 00',
        },
        {
          id: '07137d21-378c-470f-8489-85d1ce11f821',
          name: 'Signaling and Control Equipment for Dams',
          children: [
            {
              id: '718ad90c-d5cf-4d0b-948e-53f5c01315ce',
              name: 'Signaling and Control Equipment for Dams',
              tradeId: '35 13 00',
            },
            {
              id: '0c115acf-eded-4fe0-8579-cabbd790cb52',
              name: 'Signaling Equipment for Dams',
              tradeId: '35 13 13',
            },
            {
              id: '699cf7d3-b7d7-4d13-937b-b9e51e07e3a4',
              name: 'Control Equipment for Dams',
              tradeId: '35 13 53',
            },
          ],
          tradeId: '35 13 00',
        },
        {
          id: '3bf170da-9c6d-44a4-8c68-2df6dba2dbe4',
          name: 'Waterway and Marine Construction and Equipment',
          children: [
            {
              id: '7f5f1f75-8c69-4a8e-a0b1-f4d503a29797',
              name: 'Waterway and Marine Construction and Equipment',
              tradeId: '35 20 00',
            },
            {
              id: 'fe7874cf-bc98-41a0-93e1-0eed64b72d88',
              name: 'Hydraulic Fabrications',
              tradeId: '35 20 13',
            },
            {
              id: '0de61558-d239-4800-8bae-7ed7013ce665',
              name: 'Hydraulic Bifurcation Panels',
              tradeId: '35 20 13.13',
            },
            {
              id: 'acf96623-d666-4625-b8d8-21850b424aca',
              name: 'Hydraulic Bulkheads',
              tradeId: '35 20 13.16',
            },
            {
              id: '0628f632-c8b3-4dc4-a904-340b5441d6eb',
              name: 'Hydraulic Manifolds',
              tradeId: '35 20 13.19',
            },
            {
              id: '7cabdf5b-9461-4205-ac75-a0a5daa0c905',
              name: 'Hydraulic Penstocks',
              tradeId: '35 20 13.23',
            },
            {
              id: '149f7a83-69e4-40d3-80f9-4a90052c00f5',
              name: 'Hydraulic Trashracks',
              tradeId: '35 20 13.26',
            },
            {
              id: 'a799aca3-922c-4839-8308-520b662f5bd8',
              name: 'Hydraulic Gates',
              tradeId: '35 20 16',
            },
            {
              id: '6eec0996-94f2-42d9-acce-560b0cddd3af',
              name: 'Hydraulic Spillway Crest Gates',
              tradeId: '35 20 16.13',
            },
            {
              id: '73652367-1c60-4647-b67e-0f582548e9c8',
              name: 'Hydraulic Head Gates',
              tradeId: '35 20 16.19',
            },
            {
              id: 'e2a76cca-e936-461b-a06f-80611299dcaa',
              name: 'Hydraulic Sluice Gates',
              tradeId: '35 20 16.26',
            },
            {
              id: 'b4c75a9b-a784-4a94-b7ac-0f20afca6043',
              name: 'Hydraulic Miter Gates',
              tradeId: '35 20 16.33',
            },
            {
              id: 'ff56da0c-a6ab-45b8-be34-a7620de865d1',
              name: 'Hydraulic Sector Gates',
              tradeId: '35 20 16.39',
            },
            {
              id: 'd94bb159-f1f8-4f4b-be2e-31d376599b03',
              name: 'Hydraulic Tainter Gates and Anchorages',
              tradeId: '35 20 16.46',
            },
            {
              id: '507fc101-4b65-48b5-a4ec-3fd50d42422e',
              name: 'Hydraulic Vertical Lift Gates',
              tradeId: '35 20 16.53',
            },
            {
              id: '01e310cc-0c2a-4085-b360-eccf864b8b3d',
              name: 'Hydraulic Closure Gates',
              tradeId: '35 20 16.59',
            },
            {
              id: '0220946b-fc67-48c7-8f85-0514e0bb1ca4',
              name: 'Hydraulic Valves',
              tradeId: '35 20 19',
            },
            {
              id: '14f5474c-740d-46b9-b7a3-5e02903be394',
              name: 'Hydraulic Butterfly Valves',
              tradeId: '35 20 19.13',
            },
            {
              id: '998205a2-61b7-4fd5-b824-d3f99693b043',
              name: 'Hydraulic Regulating Valves',
              tradeId: '35 20 19.23',
            },
            {
              id: '67d65f58-9213-4de3-94ed-9e09c83dc107',
              name: 'Dredging',
              tradeId: '35 20 23',
            },
            {
              id: '139c6734-643d-415d-92c8-f4c45d7ac5c0',
              name: 'Mechanical Dredging',
              tradeId: '35 20 23.13',
            },
            {
              id: '3e5f2af9-5c9b-4f43-bf20-5831c76fd9db',
              name: 'Hydraulic Dredging',
              tradeId: '35 20 23.23',
            },
            {
              id: '6488d7a6-673f-4afc-a250-47e21f047560',
              name: 'Integrated Dredging and Dewatering',
              tradeId: '35 20 23.33',
            },
          ],
          tradeId: '35 20 00',
        },
        {
          id: '32cce6fb-dc66-42ea-8abc-550b5f15a7a6',
          name: 'Coastal Construction',
          children: [],
          tradeId: '35 30 00',
        },
        {
          id: '359a078e-aa20-4efe-9e28-23229c67500f',
          name: 'Shoreline Protection',
          children: [
            {
              id: 'adee2041-6737-4f61-87cb-620ade1ffae9',
              name: 'Shoreline Protection',
              tradeId: '35 31 00',
            },
            {
              id: '56d431c9-7f78-42ea-bd5f-3fec440107f2',
              name: 'Seawalls',
              tradeId: '35 31 16',
            },
            {
              id: 'a7470a2e-c1ae-4dfd-a492-e79adf7f811c',
              name: 'Concrete Seawalls',
              tradeId: '35 31 16.13',
            },
            {
              id: '0250fa1e-697c-431e-bd3b-6369ca9c0213',
              name: 'Segmental Seawalls',
              tradeId: '35 31 16.16',
            },
            {
              id: 'aad26f46-4dc3-424e-b62f-fda4befa5f2f',
              name: 'Steel Sheet Piling Seawalls',
              tradeId: '35 31 16.19',
            },
            {
              id: '46317228-d762-435a-8919-e8581b6df3ac',
              name: 'Timber Seawalls',
              tradeId: '35 31 16.23',
            },
            {
              id: '86546904-f285-4361-aab1-872f27063194',
              name: 'Stone Seawalls',
              tradeId: '35 31 16.40',
            },
            {
              id: 'a24cfa71-9e00-43cf-9b6a-0924ad1aa5b7',
              name: 'Revetments',
              tradeId: '35 31 19',
            },
            {
              id: 'e19feec8-7a18-402d-941f-2643d4cecb7a',
              name: 'Sacked Cement-Sand Revetments',
              tradeId: '35 31 19.13',
            },
            {
              id: '204916dc-ebb8-4caf-a4a6-4efb625e62e4',
              name: 'Concrete Unit Masonry Revetments',
              tradeId: '35 31 19.16',
            },
            {
              id: '048d84fa-206a-4155-b68b-c834fdca7d87',
              name: 'Gabion Revetments',
              tradeId: '35 31 19.36',
            },
            {
              id: '71f31b2e-c22e-4f7c-933e-0ebe8d878bb7',
              name: 'Stone Revetments',
              tradeId: '35 31 19.40',
            },
            {
              id: 'ba176a24-d0f8-4875-9dc4-7aa6a98778d4',
              name: 'Breakwaters',
              tradeId: '35 31 23',
            },
            {
              id: '31a031a3-6def-45eb-af4c-45601888d707',
              name: 'Rubble Mound Breakwaters',
              tradeId: '35 31 23.13',
            },
            {
              id: '603c6180-3631-4cc1-a20d-384fcbb0bfaa',
              name: 'Precast Breakwater Modules',
              tradeId: '35 31 23.16',
            },
            {
              id: 'be18cdce-d1b7-4c86-86f1-b7f459c737fe',
              name: 'Jetties',
              tradeId: '35 31 26',
            },
            {
              id: 'e3ff621f-b92a-4e8f-8894-8baafd90859f',
              name: 'Concrete Jetties',
              tradeId: '35 31 26.13',
            },
            {
              id: 'd2ca0695-6140-4881-8ad5-59fd19caed33',
              name: 'Concrete Unit Masonry Jetties',
              tradeId: '35 31 26.16',
            },
            {
              id: '9916ab89-f69f-4fc9-ad8e-fa536663ac24',
              name: 'Gabion Jetties',
              tradeId: '35 31 26.36',
            },
            {
              id: '53c90485-d8d6-41ba-8149-df4d4d502416',
              name: 'Stone Jetties',
              tradeId: '35 31 26.40',
            },
            {
              id: '8bc9d576-05fa-45fd-b8af-75720931221f',
              name: 'Groins',
              tradeId: '35 31 29',
            },
            {
              id: 'c55cba47-e0e4-435f-86c3-3bbfd791f441',
              name: 'Concrete Groins',
              tradeId: '35 31 29.13',
            },
            {
              id: 'a8286229-c0aa-4efe-8b74-ad93730df82b',
              name: 'Concrete Unit Masonry Groins',
              tradeId: '35 31 29.16',
            },
            {
              id: '7dcdaf6a-bf51-4418-8fae-59c673cc3f11',
              name: 'Steel Groins',
              tradeId: '35 31 29.26',
            },
            {
              id: '1500b45c-70d1-4e45-be66-d28453518ec1',
              name: 'Gabion Groins',
              tradeId: '35 31 29.36',
            },
            {
              id: '098e73e7-85ce-4349-b172-f453c5730710',
              name: 'Stone Groins',
              tradeId: '35 31 29.40',
            },
          ],
          tradeId: '35 31 00',
        },
        {
          id: '7f028723-95ad-46a5-b9ad-c5a62f2af090',
          name: 'Artificial Reefs',
          children: [
            {
              id: '58f49154-5edb-4a38-b268-7cd10556d418',
              name: 'Artificial Reefs',
              tradeId: '35 32 00',
            },
            {
              id: '3d4a705e-263c-42b5-b92d-bb3272d2fd05',
              name: 'Scrap Material Artificial Reefs',
              tradeId: '35 32 13',
            },
            {
              id: '4a12869a-4f2c-4602-af78-93c2bd918ab9',
              name: 'Scrap Concrete Artificial Reefs',
              tradeId: '35 32 13.13',
            },
            {
              id: '12445800-61a5-4cd2-bb6c-4fc865acc525',
              name: 'Scrap Steel Artificial Reefs',
              tradeId: '35 32 13.19',
            },
            {
              id: 'e4305c04-05cd-47fc-92fe-83e4ce10c8cc',
              name: 'Sunken Ship Artificial Reefs',
              tradeId: '35 32 13.33',
            },
            {
              id: '4bae2347-7db6-491b-a22b-8df16cf04014',
              name: 'Constructed Artificial Reefs',
              tradeId: '35 32 16',
            },
            {
              id: '8b8d8cb7-c90f-4800-87b0-3dbb15b4796b',
              name: 'Constructed Concrete Artificial Reefs',
              tradeId: '35 32 16.13',
            },
            {
              id: '83b555a1-e165-4b1a-bbe8-7ad287e8c877',
              name: 'Constructed Steel Artificial Reefs',
              tradeId: '35 32 16.19',
            },
          ],
          tradeId: '35 32 00',
        },
        {
          id: 'd8fd5840-e766-48ed-85ad-a7b74a83bee3',
          name: 'Waterway Construction and Equipment',
          children: [],
          tradeId: '35 40 00',
        },
        {
          id: '18dbbd00-b9f8-42ac-9bc1-0fc9b6c17f5a',
          name: 'Levees',
          children: [
            {
              id: 'acc62f8c-de78-4b7a-8d3b-b773109850b3',
              name: 'Levees',
              tradeId: '35 41 00',
            },
            {
              id: 'ca9b4b56-a31c-4a36-aab6-0428e55a6452',
              name: 'Landside Levee Berms',
              tradeId: '35 41 13',
            },
            {
              id: '3cb3d135-ba9d-478a-b8d4-6b21a92cd866',
              name: 'Stability Landside Levee Berms',
              tradeId: '35 41 13.13',
            },
            {
              id: 'd9006d9a-a90a-4e36-a1f5-557582cb50dc',
              name: 'Seepage Landside Levee Berms',
              tradeId: '35 41 13.16',
            },
            {
              id: 'fc1f1099-71e3-44b3-8271-ac76d160137c',
              name: 'Levee Cutoff Trenches',
              tradeId: '35 41 16',
            },
            {
              id: '2089c3a9-c823-47a7-aaed-3377835c301e',
              name: 'Levee Relief Wells',
              tradeId: '35 41 19',
            },
          ],
          tradeId: '35 41 00',
        },
        {
          id: '95c959fc-3a12-447b-a94d-2af95a38fbab',
          name: 'Waterway Bank Protection',
          children: [
            {
              id: 'f935679a-b24a-4f8d-9ca5-3033c1c36c81',
              name: 'Waterway Bank Protection',
              tradeId: '35 42 00',
            },
            {
              id: '6b016aa5-e346-4454-b08d-f1ba80d68ee6',
              name: 'Piling Bank Protection',
              tradeId: '35 42 13',
            },
            {
              id: '77456195-6613-448f-a05a-8fd4a4776bad',
              name: 'Steel Sheet Piling Bank Protection',
              tradeId: '35 42 13.19',
            },
            {
              id: 'c9ed8a45-e57f-4f46-96e9-c57cb65f053f',
              name: 'Timber Piling Bank Protection',
              tradeId: '35 42 13.23',
            },
            {
              id: 'cf274e93-3f6e-4265-b60a-2bfc81d3f48e',
              name: 'Plastic Piling Bank Protection',
              tradeId: '35 42 13.26',
            },
            {
              id: '4748b647-6d3e-4015-b072-9cd46c95d270',
              name: 'Grout-Bag Bank Protection',
              tradeId: '35 42 29',
            },
            {
              id: '3a88ee6e-03d9-42ce-9c2d-81161a9e56be',
              name: 'Soil Reinforcement Bank Protection',
              tradeId: '35 42 34',
            },
            {
              id: 'e1fd9053-4aa6-42b2-adf8-4e0333dfbe25',
              name: 'Slope Protection Bank Protection',
              tradeId: '35 42 35',
            },
            {
              id: 'dc0ff522-4b98-435b-aafb-16c96f26799b',
              name: 'Gabion Bank Protection',
              tradeId: '35 42 36',
            },
            {
              id: '6722029e-7532-4694-b7c3-8aa4a7693aae',
              name: 'Riprap Bank Protection',
              tradeId: '35 42 37',
            },
            {
              id: '728389bc-5947-45ef-8ef2-39049cfb8faf',
              name: 'Wall Bank Protection',
              tradeId: '35 42 53',
            },
            {
              id: '4f68b2bc-e106-4602-bcf0-9c1a26b05330',
              name: 'Concrete Unit Masonry Wall Bank Protection',
              tradeId: '35 42 53.16',
            },
            {
              id: '6030da15-206e-47b5-826e-346ab7b6012e',
              name: 'Segmental Wall Bank Protection',
              tradeId: '35 42 53.19',
            },
            {
              id: '45f54803-c8d8-40cf-beee-1a856194ea4f',
              name: 'Stone Wall Bank Protection',
              tradeId: '35 42 53.40',
            },
          ],
          tradeId: '35 42 00',
        },
        {
          id: '29a15554-51b5-43c1-86b1-01b3ffd7c083',
          name: 'Waterway Scour Protection',
          children: [
            {
              id: '059d719f-35d9-40d9-967e-f7990a8e8e72',
              name: 'Waterway Scour Protection',
              tradeId: '35 43 00',
            },
            {
              id: 'c748bee8-b0cf-49e1-bb7c-b7d4d97109ca',
              name: 'Grout-Bag Scour Protection',
              tradeId: '35 43 29',
            },
            {
              id: '98c44a6e-4481-4313-8496-414326245ff9',
              name: 'Soil Reinforcement Scour Protection',
              tradeId: '35 43 34',
            },
            {
              id: '34036f6f-f856-4f02-9dd6-82acbd1098e3',
              name: 'Slope Protection Scour Protection',
              tradeId: '35 43 35',
            },
            {
              id: 'c9550fdf-fd70-416b-9437-32448a39988c',
              name: 'Gabion Scour Protection',
              tradeId: '35 43 36',
            },
            {
              id: '789c42e1-5a60-43be-9abb-2b1a5d27998b',
              name: 'Riprap Scour Protection',
              tradeId: '35 43 37',
            },
            {
              id: '80b40b31-2117-4ede-b962-bf31b0370273',
              name: 'Wall Scour Protection',
              tradeId: '35 43 53',
            },
            {
              id: '41a9d9dd-8a56-4565-b208-82116dae2f6a',
              name: 'Concrete Unit Masonry Wall Scour Protection',
              tradeId: '35 43 53.13',
            },
            {
              id: '6bb9c7ec-0585-4b33-a76d-f8220c8301c2',
              name: 'Segmental Wall Scour Protection',
              tradeId: '35 43 53.16',
            },
            {
              id: '9cb083cf-f69b-439a-89da-2c50056eeb95',
              name: 'Stone Wall Scour Protection',
              tradeId: '35 43 53.40',
            },
          ],
          tradeId: '35 43 00',
        },
        {
          id: 'bacb0077-7ef1-4cb8-be0d-a2a805daa8fa',
          name: 'Waterway Structures',
          children: [
            {
              id: 'cd198657-22cb-417d-af45-f5e227826875',
              name: 'Waterway Structures',
              tradeId: '35 49 00',
            },
            {
              id: '39cd0cc9-9d10-4380-869b-44dd87fcd827',
              name: 'Floodwalls',
              tradeId: '35 49 13',
            },
            {
              id: '0f397eac-db76-4208-8159-db3039aaf754',
              name: 'Concrete Floodwalls',
              tradeId: '35 49 13.13',
            },
            {
              id: 'c8491a78-aa98-4e49-a5b9-ef7df18e710c',
              name: 'Masonry Floodwalls',
              tradeId: '35 49 13.16',
            },
            {
              id: '9e577d67-aee6-4124-bc64-e38d78e1b86a',
              name: 'Waterway Locks',
              tradeId: '35 49 23',
            },
            {
              id: 'af46a68d-d20a-48bd-be08-aee26047553d',
              name: 'Concrete Waterway Locks',
              tradeId: '35 49 23.13',
            },
            {
              id: '60bfd0c6-0f63-48d1-936c-06b642e934a4',
              name: 'Piling Waterway Locks',
              tradeId: '35 49 23.23',
            },
            {
              id: '32c7421e-4326-41ae-a147-82c7e54ffa68',
              name: 'Floodgate Machinery',
              tradeId: '35 49 26',
            },
          ],
          tradeId: '35 49 00',
        },
        {
          id: 'c09841c3-1b05-496c-b3a7-4acc2b0ca94d',
          name: 'Marine Construction and Equipment',
          children: [],
          tradeId: '35 50 00',
        },
        {
          id: '8ab98461-fccf-4358-81c8-14dfd4dc8bd2',
          name: 'Floating Construction',
          children: [
            {
              id: '535dc293-ab53-4e45-a750-a380fc65b116',
              name: 'Floating Construction',
              tradeId: '35 51 00',
            },
            {
              id: '53142b3d-ea67-49f7-8c8d-b16a0794fe7a',
              name: 'Floating Piers',
              tradeId: '35 51 13',
            },
            {
              id: 'e992ccb0-604a-4099-89a0-b34fdc8f0fb5',
              name: 'Floating Wood Piers',
              tradeId: '35 51 13.23',
            },
            {
              id: '173b1c49-5094-4695-a161-120a9868c96b',
              name: 'Floating Plastic Piers',
              tradeId: '35 51 13.26',
            },
            {
              id: 'f07822fe-67e6-4eee-b03e-b88b98964493',
              name: 'Pontoons',
              tradeId: '35 51 23',
            },
          ],
          tradeId: '35 51 00',
        },
        {
          id: '0fdbdbed-2647-4777-8a3e-35bfb36d4132',
          name: 'Offshore Platform Construction',
          children: [
            {
              id: 'e6c481ab-4710-4fdc-b36d-3467fe6a5af6',
              name: 'Offshore Platform Construction',
              tradeId: '35 52 00',
            },
            {
              id: '8f543e69-12dd-40a7-9f7d-b1426c1540af',
              name: 'Fixed Offshore Platform Construction',
              tradeId: '35 52 13',
            },
            {
              id: '7e4a6f1b-8e29-48ad-8508-417f4333038c',
              name: 'Semi-Submersible Offshore Platform Construction',
              tradeId: '35 52 23',
            },
            {
              id: 'd8f35589-fa29-4476-83f6-8b270f996c87',
              name: 'Floating Offshore Platform Construction',
              tradeId: '35 52 33',
            },
          ],
          tradeId: '35 52 00',
        },
        {
          id: '0849c4f8-2e67-497c-bcf9-e204d8467719',
          name: 'Underwater Construction',
          children: [
            {
              id: 'be09d6d1-ba62-41b8-871d-6a0573f61d48',
              name: 'Underwater Construction',
              tradeId: '35 53 00',
            },
            {
              id: '3638bcee-b1ca-4430-a905-a20ceff5827a',
              name: 'Underwater Harbor Deepening',
              tradeId: '35 53 23',
            },
            {
              id: '6e4134fd-4cbf-4596-b15a-3c5c6576a8b1',
              name: 'Underwater Pipeline Construction',
              tradeId: '35 53 33',
            },
            {
              id: 'c3410d92-0dbf-4184-a108-f8206d5ef1c0',
              name: 'Underwater Foundation Construction',
              tradeId: '35 53 43',
            },
            {
              id: '17f475a7-f447-410d-9134-83e1da9e6c9c',
              name: 'Underwater Structures Construction',
              tradeId: '35 53 53',
            },
            {
              id: 'b7e4f66d-f66a-4079-a72e-23c0bca4d382',
              name: 'Underwater Waterproofing',
              tradeId: '35 53 63',
            },
          ],
          tradeId: '35 53 00',
        },
        {
          id: '2d55e132-d012-4454-84e5-26fb9dc463af',
          name: 'Marine Specialties',
          children: [
            {
              id: '57b8d96c-bc08-4364-ab2c-30613eea68d9',
              name: 'Marine Specialties',
              tradeId: '35 59 00',
            },
            {
              id: '1132edfa-4191-48ce-9e36-9aedf4ac83ba',
              name: 'Marine Fenders',
              tradeId: '35 59 13',
            },
            {
              id: 'c075263c-e4c7-4bf0-b88b-99523d4fd25c',
              name: 'Prestressed Concrete Marine Fender Piling',
              tradeId: '35 59 13.13',
            },
            {
              id: 'e7ef0aed-285f-4d45-b64d-f56a64716421',
              name: 'Resilient Foam-Filled Marine Fenders',
              tradeId: '35 59 13.16',
            },
            {
              id: '3aa239b3-f13a-4ddf-a882-eb17d099d10f',
              name: 'Rubber Marine Fenders',
              tradeId: '35 59 13.19',
            },
            {
              id: 'c98e62bc-69eb-4a3c-8d78-8ba9bb3fcc02',
              name: 'Buoys',
              tradeId: '35 59 23',
            },
            {
              id: '55a8552b-7f28-4ab6-a978-b6912d76e15d',
              name: 'Mooring Buoys',
              tradeId: '35 59 23.13',
            },
            {
              id: 'd0f88f62-07f6-4217-9a3f-768c1814ee10',
              name: 'Anchor Pendant Buoys',
              tradeId: '35 59 23.16',
            },
            {
              id: '234839dd-701a-4c0c-b0cf-3036b5b7e88c',
              name: 'Navigation Buoys',
              tradeId: '35 59 23.19',
            },
            {
              id: '45968357-16ab-49e4-8bde-9b1ca72ec0e0',
              name: 'Mooring Devices',
              tradeId: '35 59 29',
            },
            {
              id: 'c7fbcc96-7583-4ccd-bc46-52f43ad38bae',
              name: 'Quick-Release Mooring Hooks',
              tradeId: '35 59 29.13',
            },
            {
              id: 'f330e924-cae1-484e-9d31-45e7e2ac9456',
              name: 'Laser Docking Systems',
              tradeId: '35 59 29.16',
            },
            {
              id: '8cd98956-1541-47d1-a8a6-963cbfd7f829',
              name: 'Capstans',
              tradeId: '35 59 29.19',
            },
            {
              id: '8859c6f4-0886-4eb4-ae59-c02e25b67867',
              name: 'Marine Bollards and Cleats',
              tradeId: '35 59 33',
            },
            {
              id: '8b726b14-1910-49c9-9a0e-69229230a0b5',
              name: 'Cast-Steel Marine Bollards and Cleats',
              tradeId: '35 59 33.13',
            },
            {
              id: 'c5f0e04e-c9e2-404c-8f5f-5a1e880b8491',
              name: 'Cast-Iron Marine Bollards and Cleats',
              tradeId: '35 59 33.16',
            },
            {
              id: 'b3acad49-20d0-488b-988c-a90465076a71',
              name: 'Stainless-Steel Marine Bollards and Cleats',
              tradeId: '35 59 33.19',
            },
            {
              id: '7a4716e1-c2c3-4d35-9198-1ded308da257',
              name: 'Plastic Marine Bollards and Cleats',
              tradeId: '35 59 33.23',
            },
            {
              id: '61986b4e-7062-4104-bce1-5c18fe8c285b',
              name: 'Marine Chain and Accessories',
              tradeId: '35 59 93',
            },
            {
              id: 'd33c5681-abe5-4545-aae6-a26e1705c781',
              name: 'Marine Chain',
              tradeId: '35 59 93.13',
            },
            {
              id: '9be44924-2bcd-4a89-9b30-d2d45cba42dd',
              name: 'Marine Shackles',
              tradeId: '35 59 93.16',
            },
            {
              id: 'feaa6467-b3f9-40a9-9f22-7423e599c6be',
              name: 'Marine Chain Tensioners',
              tradeId: '35 59 93.19',
            },
          ],
          tradeId: '35 59 00',
        },
        {
          id: '0356c80d-5a47-45a2-96a2-0185c1cecaef',
          name: 'Dam Construction and Equipment',
          children: [],
          tradeId: '35 70 00',
        },
        {
          id: '8ce0b443-b818-4478-9d86-c08c4b3e8e34',
          name: 'Gravity Dams',
          children: [
            {
              id: 'b676f2b5-c50d-4bee-801c-181ee7b57225',
              name: 'Gravity Dams',
              tradeId: '35 71 00',
            },
            {
              id: '695d8efd-a9f0-41dc-8cc5-d7f3a0b9097b',
              name: 'Concrete Gravity Dams',
              tradeId: '35 71 13',
            },
            {
              id: 'd91f4047-d811-4011-8511-f9e0051b166c',
              name: 'Masonry Gravity Dams',
              tradeId: '35 71 16',
            },
            {
              id: '74e3b8ad-230b-414e-8c30-936a71ac3083',
              name: 'Rockfill Gravity Dams',
              tradeId: '35 71 19',
            },
          ],
          tradeId: '35 71 00',
        },
        {
          id: '06a4be72-717a-4b8b-9c4c-6cacaf0b38cb',
          name: 'Arch Dams',
          children: [
            {
              id: '181ca6cb-4bdc-4a99-a838-94a07800dce8',
              name: 'Arch Dams',
              tradeId: '35 72 00',
            },
            {
              id: 'e0a6da93-500e-48c6-bdf1-b933a4278d76',
              name: 'Concrete Arch Dams',
              tradeId: '35 72 13',
            },
          ],
          tradeId: '35 72 00',
        },
        {
          id: 'e17ac2ad-17d3-4eea-8a36-e7772a4f6f20',
          name: 'Embankment Dams',
          children: [
            {
              id: '00edfc5e-fbcf-400e-ab8d-7b02ce5f83a3',
              name: 'Embankment Dams',
              tradeId: '35 73 00',
            },
            {
              id: '7fbb9e99-b5ae-42fd-9789-80b1de0976e9',
              name: 'Earth Embankment Dam',
              tradeId: '35 73 13',
            },
            {
              id: '4004b586-a349-4fdf-ad01-01d9c82a99cf',
              name: 'Rock Embankment Dams',
              tradeId: '35 73 16',
            },
          ],
          tradeId: '35 73 00',
        },
        {
          id: 'c6d24bce-ea06-44b1-a4ac-01e801292e2d',
          name: 'Buttress Dams',
          children: [
            {
              id: '296ad564-2a0a-4af0-8937-d783fd8c7dec',
              name: 'Buttress Dams',
              tradeId: '35 74 00',
            },
            {
              id: '3df3165b-e1c9-422c-abab-81b2c126788e',
              name: 'Concrete Buttress Dams',
              tradeId: '35 74 13',
            },
          ],
          tradeId: '35 74 00',
        },
        {
          id: 'cb8e3a15-eebc-4563-ad51-6a80e593fe41',
          name: 'Auxiliary Dam Structures',
          children: [
            {
              id: '14d004d1-25a8-4726-bcb0-b2647dc71b07',
              name: 'Auxiliary Dam Structures',
              tradeId: '35 79 00',
            },
            {
              id: '37acc9fd-e36d-4294-82c0-60c19cadfa11',
              name: 'Fish Ladders',
              tradeId: '35 79 13',
            },
            {
              id: 'd90b2912-4388-4c5b-8ddd-7ac304dddff8',
              name: 'Concrete Fish Ladders',
              tradeId: '35 79 13.13',
            },
          ],
          tradeId: '35 79 00',
        },
      ],
      tradeId: '35 00 00',
    },
    {
      id: 'a5ce76f0-e91a-4b6f-a703-df7b4361c47e',
      name: 'Process Integration',
      children: [
        {
          id: 'd929b432-632b-4b1d-bf9d-1af6a79b0b9f',
          name: 'Process Integration',
          children: [],
          tradeId: '40 00 00',
        },
        {
          id: 'b80e78f1-741e-4bc3-9667-1bc2311c409c',
          name: 'Operation and Maintenance of Process Integration',
          children: [
            {
              id: 'e5716912-361f-4983-9faf-81911dbd5d3c',
              name: 'Operation and Maintenance of Process Integration',
              tradeId: '40 01 00',
            },
            {
              id: '80324a97-299c-4299-b7a2-46b7ba4fae93',
              name: 'Operation and Maintenance of Gas and Vapor Process Piping',
              tradeId: '40 01 10',
            },
            {
              id: 'da7c300a-6bc2-4890-bee3-4c95a54cefc8',
              name: 'Operation and Maintenance of Liquids Process Piping',
              tradeId: '40 01 20',
            },
            {
              id: '065507b5-d72a-42d7-a932-9f573e216155',
              name: 'Operation and Maintenance of Solid and Mixed Materials Piping and Chutes',
              tradeId: '40 01 30',
            },
            {
              id: 'e379f20d-50b3-4f1f-b52a-91aa29e70776',
              name: 'Operation and Maintenance of Process Piping and Equipment Protection',
              tradeId: '40 01 40',
            },
          ],
          tradeId: '40 01 00',
        },
        {
          id: 'c7ca0928-42c5-4493-8dbc-22a8ff55e5f8',
          name: 'Common Work Results for Process Integration',
          children: [
            {
              id: '3267563f-7360-4feb-bbb4-73a464f645d8',
              name: 'Common Work Results for Process Integration',
              tradeId: '40 05 00',
            },
            {
              id: 'df6c33d6-101c-48ef-a5a7-d79390df337e',
              name: 'Common Work Results for Process Piping',
              tradeId: '40 05 13',
            },
            {
              id: '0ef1071a-0d3d-42de-965f-94cd89a0281a',
              name: 'Steel Process Piping',
              tradeId: '40 05 13.13',
            },
            {
              id: '805c081e-2275-46b0-8c86-49cad76849b5',
              name: 'Lined or Internally-Coated Steel Process Piping',
              tradeId: '40 05 13.16',
            },
            {
              id: 'a2c84264-8076-4590-9155-3036dacf61d3',
              name: 'Stainless-Steel Process Piping',
              tradeId: '40 05 13.19',
            },
            {
              id: '1aba99bf-d156-4883-a0a0-2ccec146016c',
              name: 'Aluminum Alloys Process Piping',
              tradeId: '40 05 13.23',
            },
            {
              id: '366a91aa-1def-4508-8cd8-a3fe382c87b1',
              name: 'Brass, Bronze, Copper and Copper Alloys Process Piping',
              tradeId: '40 05 13.33',
            },
            {
              id: '193883e2-889f-4390-a185-db016e542f52',
              name: 'Nickel and Nickel Alloys Process Piping',
              tradeId: '40 05 13.43',
            },
            {
              id: 'a81c2baa-4dd8-48ce-bbe3-9232d4c56d0f',
              name: 'Ductile, Malleable and Cast Iron Alloys Process Piping',
              tradeId: '40 05 13.53',
            },
            {
              id: 'ca3ab1b6-2ffd-4110-b106-4c9dcb5af9f5',
              name: 'Titanium and Titanium Alloys Process Piping',
              tradeId: '40 05 13.63',
            },
            {
              id: '115aa7e3-dbf4-46e6-9cbb-7f9bc4d45ffa',
              name: 'Plastic Process Piping',
              tradeId: '40 05 13.73',
            },
            {
              id: '5da008e2-6d0a-4315-aceb-8bb4e0f01991',
              name: 'Fiberglass-Reinforced Plastic and Resins Process Piping',
              tradeId: '40 05 13.76',
            },
            {
              id: 'dcda1e6e-d37e-4c76-b36e-354536789c63',
              name: 'Other Metals Process Piping',
              tradeId: '40 05 13.93',
            },
            {
              id: 'd3422c2b-45bc-4630-9b19-5324ab977a81',
              name: 'Common Work Results for Process Valves',
              tradeId: '40 05 23',
            },
            {
              id: 'dab3d829-6666-4475-ba4a-f334adaae04b',
              name: 'Carbon Steel Process Valves',
              tradeId: '40 05 23.13',
            },
            {
              id: 'ab668482-ea2c-4874-b87b-71e505961b40',
              name: 'Low and Intermediate Alloy Steel Process Valves',
              tradeId: '40 05 23.16',
            },
            {
              id: '12fdb37a-38dd-443c-ad8b-5e0232bf6515',
              name: 'Stainless-Steel Process Valves',
              tradeId: '40 05 23.19',
            },
            {
              id: '1f34a4d4-7bb1-48c3-9658-6fb318338587',
              name: 'Brass and Iron Process Valves',
              tradeId: '40 05 23.33',
            },
            {
              id: '99c3eb8a-c107-4cc7-a60d-4bfffa20ae53',
              name: 'Nickel and Nickel Alloys Steel Process Valves',
              tradeId: '40 05 23.43',
            },
            {
              id: '58476003-b432-486b-912e-87f80799d0b7',
              name: 'Plastic and Plastic Lined Process Valves',
              tradeId: '40 05 23.73',
            },
            {
              id: 'ea49edc3-41bc-4460-85d4-412539eb9300',
              name: 'Other Metals Process Valves',
              tradeId: '40 05 23.93',
            },
          ],
          tradeId: '40 05 00',
        },
        {
          id: '41f21c4b-4bf4-4f5b-a984-622e182ad8cd',
          name: 'Schedules for Process Integration',
          children: [
            {
              id: 'd6b28e7c-5c05-4f14-b6d6-4f6601f81f54',
              name: 'Schedules for Process Integration',
              tradeId: '40 06 00',
            },
            {
              id: '4106f211-450e-4689-bab6-bbe54dd91386',
              name: 'Schedules for Gas and Vapor Process Piping',
              tradeId: '40 06 10',
            },
            {
              id: '114be949-ef40-4c99-b3d0-bb67873ffd37',
              name: 'Schedules for Liquids Process Piping',
              tradeId: '40 06 20',
            },
            {
              id: 'f0d62c4e-ed6e-4785-99ef-59b64a8e0eb5',
              name: 'Schedules for Solid and Mixed Materials Piping and Chutes',
              tradeId: '40 06 30',
            },
            {
              id: 'c10e19e6-cc06-41ce-919d-a2316ae8e7d4',
              name: 'Schedules for Process Piping and Equipment Protection',
              tradeId: '40 06 40',
            },
          ],
          tradeId: '40 06 00',
        },
        {
          id: '6b09c071-c361-420f-bdc6-9a5b18d0147c',
          name: 'Gas and Vapor Process Piping',
          children: [],
          tradeId: '40 10 00',
        },
        {
          id: '2ef4a38c-9338-4e6e-86c2-157edc810cfc',
          name: 'Steam Process Piping',
          children: [
            {
              id: '5e55c668-8c8c-4d6d-8cda-58c9e327bf01',
              name: 'Steam Process Piping',
              tradeId: '40 11 00',
            },
            {
              id: '3508fd4d-9647-4608-bf96-5661cbc4e04e',
              name: 'Low-Pressure Steam Process Piping',
              tradeId: '40 11 13',
            },
            {
              id: 'e8f2844b-0181-42ad-9395-60fede54dd3c',
              name: 'Intermediate-Pressure Steam Process Piping',
              tradeId: '40 11 16',
            },
            {
              id: '7dff1ee2-a22c-4a88-b4d6-5991ea49d179',
              name: 'High-Pressure Steam Process Piping',
              tradeId: '40 11 19',
            },
            {
              id: '5c125345-e7cb-43e5-9c7f-07d19753728f',
              name: 'Condensate Process Piping',
              tradeId: '40 11 23',
            },
          ],
          tradeId: '40 11 00',
        },
        {
          id: '9fedc8be-98aa-40d4-9590-f45dc4c667e1',
          name: 'Compressed Air Process Piping',
          children: [
            {
              id: '5d2f5e5e-b0f2-4026-87f7-9cdb3d577355',
              name: 'Compressed Air Process Piping',
              tradeId: '40 12 00',
            },
            {
              id: '30ae574e-c371-44c8-8714-7514eb8fb4ac',
              name: 'Breathing Compressed Air Process Piping',
              tradeId: '40 12 13',
            },
            {
              id: '3b9fc79c-600f-4fec-9181-53247f5cae66',
              name: 'Non-Breathing Compressed Air Process Piping',
              tradeId: '40 12 16',
            },
          ],
          tradeId: '40 12 00',
        },
        {
          id: 'abb370a7-61cf-422d-aadb-1b4054f4d6fb',
          name: 'Inert Gases Process Piping',
          children: [
            {
              id: 'af94a341-a7a8-4061-b2f9-d73fece22b25',
              name: 'Inert Gases Process Piping',
              tradeId: '40 13 00',
            },
            {
              id: '885d74c2-e4a3-467c-b6a8-ebc1595535aa',
              name: 'Argon Process Piping',
              tradeId: '40 13 13',
            },
            {
              id: '5a739ba0-21fb-4bbc-86d2-8131b864c46e',
              name: 'Carbon-Dioxide Process Piping',
              tradeId: '40 13 16',
            },
            {
              id: 'ac753c11-8502-4e93-8ab7-d4d4e74831b5',
              name: 'Helium Process Piping',
              tradeId: '40 13 19',
            },
            {
              id: 'ad5cd5cb-423e-4e69-b88b-6796526d8197',
              name: 'Krypton Process Piping',
              tradeId: '40 13 23',
            },
            {
              id: '25d4aa7b-f301-4834-b1ce-4ca0baa70998',
              name: 'Neon Process Piping',
              tradeId: '40 13 26',
            },
            {
              id: '8a19224f-5f02-46f4-ae0c-9e44c05602e2',
              name: 'Nitrogen Process Piping',
              tradeId: '40 13 29',
            },
            {
              id: '7dc7d465-e544-46d5-af45-9e224f76a956',
              name: 'Xenon Process Piping',
              tradeId: '40 13 33',
            },
            {
              id: '5f62e5f7-41eb-45a3-8089-a73b27d90095',
              name: 'Mixed Inert Gases Process Piping',
              tradeId: '40 13 93',
            },
          ],
          tradeId: '40 13 00',
        },
        {
          id: '3f09b230-8a9a-48f0-9264-88263f4ec0f6',
          name: 'Fuel Gases Process Piping',
          children: [
            {
              id: '2c7d60f0-2dcd-4413-98ae-21b8228a2d2f',
              name: 'Fuel Gases Process Piping',
              tradeId: '40 14 00',
            },
            {
              id: 'acd4427f-a340-4aa3-bee3-5a35f0d45c3e',
              name: 'Blast Furnace Piping',
              tradeId: '40 14 13',
            },
            {
              id: 'ff557557-d47e-4b40-a065-6cb072c3dad3',
              name: 'Blue (Water) Fuel Gas Piping',
              tradeId: '40 14 16',
            },
            {
              id: '58ffe3e8-683c-4fe1-abcc-fe289b40d895',
              name: 'Butane Piping',
              tradeId: '40 14 19',
            },
            {
              id: '4e732dee-a738-4786-a44f-f0af43d4bcdb',
              name: 'Carbon-Monoxide Piping',
              tradeId: '40 14 23',
            },
            {
              id: '63f9c218-d9b5-4ed1-91b7-bc68db2064dd',
              name: 'Chlorine Fuel Gas Piping',
              tradeId: '40 14 26',
            },
            {
              id: '92f72f2c-cf70-4534-a20d-c7d0a4e43b22',
              name: 'Coke Oven Gas Piping',
              tradeId: '40 14 29',
            },
            {
              id: 'efa3735e-0148-4bef-bd5a-3dfbbed3ad5a',
              name: 'Ethane-Gas Piping',
              tradeId: '40 14 33',
            },
            {
              id: 'f581dbc9-3558-474e-afb5-3a5fd9da5fec',
              name: 'Hydrogen Fuel Gas Piping',
              tradeId: '40 14 36',
            },
            {
              id: '1e2e113b-bebe-4192-a3e9-1843dd0c9abd',
              name: 'Liquid Natural-Gas Process Piping',
              tradeId: '40 14 39',
            },
            {
              id: '51f105f2-7f3a-4e37-94ab-8fe19fcd792d',
              name: 'Methylacetylene-Propadiene Fuel Gas Piping',
              tradeId: '40 14 43',
            },
            {
              id: 'be985c6a-9fcf-4e95-b763-7cbc3b886abf',
              name: 'Natural-Gas Process Piping',
              tradeId: '40 14 49',
            },
            {
              id: '84aa4001-27fe-4172-b9ea-90f1d34bba99',
              name: 'Synthetic Natural-Gas Piping',
              tradeId: '40 14 49.13',
            },
            {
              id: 'f83e1516-0c81-44f7-82a3-f404c18103f9',
              name: 'Propane-Air Mixes Fuel Gas Piping',
              tradeId: '40 14 49.23',
            },
            {
              id: '19d24461-d4a1-402f-9a85-346c1bc6830d',
              name: 'Octane Fuel Gas Piping',
              tradeId: '40 14 53',
            },
            {
              id: 'b09ddce4-17ca-4c53-a9c5-1fc5ae450c78',
              name: 'Propane Fuel Gas Process Piping',
              tradeId: '40 14 59',
            },
            {
              id: 'b5d29f20-dacc-4f61-b6ef-fbd5e135436c',
              name: 'Sewage Fuel Gas Piping',
              tradeId: '40 14 63',
            },
            {
              id: 'b8bf8a52-5bff-4c83-9b42-3280571e314d',
              name: 'Mixed Fuel Gases Piping',
              tradeId: '40 14 93',
            },
          ],
          tradeId: '40 14 00',
        },
        {
          id: '2948b847-8548-4cc4-9ed0-41135d29f0dc',
          name: 'Combustion System Gas Piping',
          children: [
            {
              id: 'abcb855e-a1d8-4634-ac40-d2dd5aa9dde2',
              name: 'Combustion System Gas Piping',
              tradeId: '40 15 00',
            },
            {
              id: '80560ea8-c7fd-42f1-a0a4-b4da935fb56a',
              name: 'Combustion Air Piping',
              tradeId: '40 15 13',
            },
            {
              id: '32bde87f-140c-4368-8d78-adc4f9e0d2fc',
              name: 'Oxygen Combustion System Gas Piping',
              tradeId: '40 15 16',
            },
            {
              id: '84931219-7064-496f-b7dd-38e237677c91',
              name: 'Flue-Gas Combustion System Piping',
              tradeId: '40 15 19',
            },
            {
              id: '9799b2bf-b3e7-41a4-9bee-23c575b338d1',
              name: 'Exothermic-Gas Combustion System Piping',
              tradeId: '40 15 23',
            },
            {
              id: '1cbdf56c-b566-43f5-8020-7b1c70867a7b',
              name: 'Endothermic-Gas Combustion System Piping',
              tradeId: '40 15 26',
            },
            {
              id: 'd6180747-24ee-4ff2-b72a-2921f766007e',
              name: 'Disassociated-Ammonia-Gas Combustion System Piping',
              tradeId: '40 15 29',
            },
          ],
          tradeId: '40 15 00',
        },
        {
          id: '42892a7d-5421-4687-bd76-4b6d6eb1de9c',
          name: 'Specialty and High-Purity Gases Piping',
          children: [
            {
              id: 'a84e9398-270d-439d-927d-eee85dfddd68',
              name: 'Specialty and High-Purity Gases Piping',
              tradeId: '40 16 00',
            },
            {
              id: 'e81f04fb-0d49-495a-a6fd-ff2bf0f780bb',
              name: 'Ammonia Gas Piping',
              tradeId: '40 16 13',
            },
            {
              id: '4645f5dd-3ae1-47e8-b565-f92a295ac1ce',
              name: 'Boron Gas Piping',
              tradeId: '40 16 16',
            },
            {
              id: '0bf71043-4da3-49fd-9df0-6f520b9f8c01',
              name: 'Diborane Gas Piping',
              tradeId: '40 16 26',
            },
            {
              id: '9f8d03cb-2b59-4d21-86ca-a0a8f56e674b',
              name: 'Flourine Gas Piping',
              tradeId: '40 16 29',
            },
            {
              id: '03743660-a1e6-4612-905c-e71fd908d3be',
              name: 'Hydrogen Sulfide Gas Piping',
              tradeId: '40 16 33',
            },
            {
              id: 'a4fb619b-7d85-43a1-b0ff-6314255463d7',
              name: 'Nitrous-Oxide Gas Process Piping',
              tradeId: '40 16 36',
            },
            {
              id: '5861c4af-41b7-40e3-bbae-85c8741866b0',
              name: 'Ozone Gas Piping',
              tradeId: '40 16 39',
            },
            {
              id: 'ca8077b9-45eb-4818-a6b5-42d556734cc6',
              name: 'Phosphine Gas Piping',
              tradeId: '40 16 43',
            },
            {
              id: '7f7eb937-69be-4fe9-8562-a0234f64d20b',
              name: 'Silane Gas Piping',
              tradeId: '40 16 46',
            },
            {
              id: '9338b066-2599-4856-9e63-a103b4e1355f',
              name: 'Sulphur-Dioxide Gas Piping',
              tradeId: '40 16 49',
            },
            {
              id: '36dc4cb0-3ea1-4c08-a1dc-724ba64caeea',
              name: 'Specialty Gas Mixtures Piping',
              tradeId: '40 16 53',
            },
            {
              id: '301d62e9-f637-46e9-8d57-35dd11a75d8a',
              name: 'High-Purity Gas Piping Components',
              tradeId: '40 16 56',
            },
          ],
          tradeId: '40 16 00',
        },
        {
          id: '8e6b9a4f-dc1b-448f-b87a-c99a99ccf5d6',
          name: 'Welding and Cutting Gases Piping',
          children: [
            {
              id: '3d58c348-e91d-47aa-b3fa-5d120d887cb5',
              name: 'Welding and Cutting Gases Piping',
              tradeId: '40 17 00',
            },
            {
              id: '09f50cfa-8ff1-4105-a30d-a249e153045d',
              name: 'Acetylene Welding and Cutting Piping',
              tradeId: '40 17 13',
            },
            {
              id: 'a64ebebf-6d43-4df4-9348-1a5bbdd716e7',
              name: 'Acetylene-Hydrogen Mix Welding and Cutting Piping',
              tradeId: '40 17 16',
            },
            {
              id: '20d723ce-5994-4904-9fff-918877c51574',
              name: 'Methylacetylene-Propadiene Welding and Cutting Piping',
              tradeId: '40 17 19',
            },
            {
              id: 'ea62427a-5d84-4cb6-9b8f-91a3f00fce4f',
              name: 'Oxygen Welding and Cutting Piping',
              tradeId: '40 17 23',
            },
            {
              id: '2ab36b41-4656-4f54-85c0-252319f6e3a9',
              name: 'Inert Gas Welding and Cutting Piping',
              tradeId: '40 17 26',
            },
          ],
          tradeId: '40 17 00',
        },
        {
          id: 'a222a3ad-f251-427c-932b-d8bb9629cdd3',
          name: 'Vacuum Systems Process Piping',
          children: [
            {
              id: 'fc1f7a59-4acb-462c-a4a1-91379101428c',
              name: 'Vacuum Systems Process Piping',
              tradeId: '40 18 00',
            },
            {
              id: '8ddbf930-6d1e-4d4a-8cda-2853e66642c7',
              name: 'Low-Vacuum Systems Process Piping',
              tradeId: '40 18 13',
            },
            {
              id: '3370e128-7c1c-4e32-a67f-d1f0bd02fde0',
              name: 'High-Vacuum Systems Process Piping',
              tradeId: '40 18 16',
            },
          ],
          tradeId: '40 18 00',
        },
        {
          id: 'c2c73edf-2127-4e0c-b634-a711f1e78305',
          name: 'Liquids Process Piping',
          children: [],
          tradeId: '40 20 00',
        },
        {
          id: '68256834-e179-4e7b-ba8b-bde9bdef3d74',
          name: 'Liquid Fuel Process Piping',
          children: [
            {
              id: 'a28ec829-6f27-4810-8f3c-e2dec47ba146',
              name: 'Liquid Fuel Process Piping',
              tradeId: '40 21 00',
            },
            {
              id: '1f557d5f-1274-4542-800a-1bbf71268c27',
              name: 'Bio Fuels Process Piping',
              tradeId: '40 21 13',
            },
            {
              id: 'd1f8d8c9-b13a-42b5-a082-d6134559b5f4',
              name: 'Gasoline Process Piping',
              tradeId: '40 21 16',
            },
            {
              id: '1f615737-8fa4-4964-8aa9-88f633de12ac',
              name: 'Diesel Process Piping',
              tradeId: '40 21 19',
            },
            {
              id: 'e785df6b-5412-4567-b07e-a1aa13685885',
              name: 'Fuel-Oils Process Piping',
              tradeId: '40 21 23',
            },
            {
              id: '2ab36fcb-b35f-46e8-9756-6f04bf6e7691',
              name: 'No. 2 Fuel-Oil Process Piping',
              tradeId: '40 21 23.13',
            },
            {
              id: '45ab1960-1071-434b-8991-c41ebab70f64',
              name: 'No. 4 Fuel-Oil Process Piping',
              tradeId: '40 21 23.16',
            },
            {
              id: '6bce2807-e5af-404b-9448-96cb3d6883e2',
              name: 'No. 5 Fuel-Oil Process Piping',
              tradeId: '40 21 23.19',
            },
            {
              id: '049dcb4f-ffee-4775-a36a-7eda7d54c62f',
              name: 'No. 6 Fuel-Oil Process Piping',
              tradeId: '40 21 23.23',
            },
            {
              id: '5e0008b2-1f08-4205-b9bc-55c4966c57e0',
              name: 'Kerosene Process Piping',
              tradeId: '40 21 23.26',
            },
            {
              id: '24161f97-cd8c-46b6-b49a-31c47a8a674e',
              name: 'Tar Process Piping',
              tradeId: '40 21 23.29',
            },
          ],
          tradeId: '40 21 00',
        },
        {
          id: 'fca61b3f-91be-49ff-a43a-e61c110c6e4b',
          name: 'Petroleum Products Piping',
          children: [
            {
              id: '791c6d8d-5a28-4a9f-9ba0-5c9e29b3b42f',
              name: 'Petroleum Products Piping',
              tradeId: '40 22 00',
            },
            {
              id: '5e6d0dfd-8ac6-4cd6-8604-5ac95cc715bd',
              name: 'Heavy-Fractions Petroleum Products Piping',
              tradeId: '40 22 13',
            },
            {
              id: '4ca60ef4-e7de-46a5-a4a3-7c1313fc3309',
              name: 'Light-Fractions Petroleum Products Piping',
              tradeId: '40 22 16',
            },
          ],
          tradeId: '40 22 00',
        },
        {
          id: '469e68ef-2af0-4e15-a8ce-b523fe5bf592',
          name: 'Water Process Piping',
          children: [
            {
              id: '157fa312-db77-4858-bfd4-1e5195ddc83e',
              name: 'Water Process Piping',
              tradeId: '40 23 00',
            },
            {
              id: '69316efc-f0cd-44e2-9b8b-f4378b591c46',
              name: 'Deionized Water Process Piping',
              tradeId: '40 23 13',
            },
            {
              id: 'b2cc7241-8bf1-48be-9d24-a9a8c517daf4',
              name: 'Distilled-Water Process Piping',
              tradeId: '40 23 16',
            },
            {
              id: '3684e018-10b1-4192-ae5d-783a3fa76bff',
              name: 'Process Plant Water Piping',
              tradeId: '40 23 19',
            },
            {
              id: '54a25871-dff8-4afe-89f3-38be96e335af',
              name: 'Potable Water Process Piping',
              tradeId: '40 23 23',
            },
            {
              id: 'd42ce45b-8a0a-4ffd-bfc4-7d78f0a3a932',
              name: 'Recirculated Water Process Piping',
              tradeId: '40 23 29',
            },
            {
              id: 'a9d376ad-18d5-4fdf-bb55-a8aa8c76f743',
              name: 'Reverse-Osmosis Water Process Piping',
              tradeId: '40 23 33',
            },
            {
              id: '1a169c36-2bd7-4850-a038-f4d97d20a013',
              name: 'Sanitary Water Process Piping',
              tradeId: '40 23 36',
            },
          ],
          tradeId: '40 23 00',
        },
        {
          id: '2c93925c-ef24-4774-90da-e681987681b9',
          name: 'Specialty Liquid Chemicals Piping',
          children: [
            {
              id: '96da59cd-cc05-4d1c-98a5-c7f6751ea1e0',
              name: 'Specialty Liquid Chemicals Piping',
              tradeId: '40 24 00',
            },
            {
              id: 'b19d7375-8227-48b6-a73a-3c84976011af',
              name: 'Alcohol Piping',
              tradeId: '40 24 13',
            },
            {
              id: '3440a933-849f-4697-92ab-7c2fcb64fce0',
              name: 'Gel Piping',
              tradeId: '40 24 16',
            },
            {
              id: '12070dd0-a9a0-41cd-81cd-09db016582a6',
              name: 'Slurries Process Piping',
              tradeId: '40 24 19',
            },
            {
              id: '65decad3-f293-4c90-80cd-e8859515f724',
              name: 'Thixotropic Liquid Piping',
              tradeId: '40 24 23',
            },
          ],
          tradeId: '40 24 00',
        },
        {
          id: '3225f27b-87ae-4f63-bb79-fe19bcab046c',
          name: 'Liquid Acids and Bases Piping',
          children: [
            {
              id: '63554ba0-ae99-4714-a83e-5ab366067fcf',
              name: 'Liquid Acids and Bases Piping',
              tradeId: '40 25 00',
            },
            {
              id: 'b37aa42d-a284-4f07-b8e8-f03d3a8f57a8',
              name: 'Liquid Acids Piping',
              tradeId: '40 25 13',
            },
            {
              id: 'c1334824-c24c-40e4-8d29-e685d1e71741',
              name: 'Liquid Bases Piping',
              tradeId: '40 25 16',
            },
          ],
          tradeId: '40 25 00',
        },
        {
          id: 'ce5a79ec-1a0d-45ce-a4ff-a6441f345de7',
          name: 'Liquid Polymer Piping',
          children: [],
          tradeId: '40 26 00',
        },
        {
          id: 'a7fd27fc-6730-43fc-9d8b-e7b6d23d6ce7',
          name: 'Solid and Mixed Materials Piping and Chutes',
          children: [],
          tradeId: '40 30 00',
        },
        {
          id: '7edc8a5c-de38-4eb8-8540-86e74d7eaf70',
          name: 'Bulk Materials Piping and Chutes',
          children: [
            {
              id: '4c897944-4c04-4629-8c9c-3fea0ed04ccd',
              name: 'Bulk Materials Piping and Chutes',
              tradeId: '40 32 00',
            },
            {
              id: 'b4f3e04c-9356-4e4f-9d58-ca2f4000586f',
              name: 'Abrasive Materials Piping and Chutes',
              tradeId: '40 32 13',
            },
            {
              id: '709cf87b-0032-4a5f-ae18-503dcc8fcd2b',
              name: 'Nonabrasive Materials Piping and Chutes',
              tradeId: '40 32 16',
            },
          ],
          tradeId: '40 32 00',
        },
        {
          id: '42c2b121-313b-4197-bc4f-e68c9af8c3c2',
          name: 'Bulk Materials Valves',
          children: [
            {
              id: '845a3aff-2de7-4bab-8a3e-163e1d7bbf56',
              name: 'Bulk Materials Valves',
              tradeId: '40 33 00',
            },
            {
              id: '3a88d77f-0494-47c3-b395-52a2bbe28cc3',
              name: 'Airlock Bulk Materials Valves',
              tradeId: '40 33 13',
            },
            {
              id: '68438338-d383-4164-8a11-95514fa41c07',
              name: 'Blind Bulk Materials Valves',
              tradeId: '40 33 16',
            },
            {
              id: 'd9d16980-f31a-4206-bd6d-9aef4417501d',
              name: 'Butterfly Bulk Materials Valves',
              tradeId: '40 33 19',
            },
            {
              id: '3c118cfa-0ccb-4552-80aa-e26ed035e6e7',
              name: 'Cone Bulk Materials Valves',
              tradeId: '40 33 23',
            },
            {
              id: '85df24fa-5716-47c6-b8fb-ae1b7fa38821',
              name: 'Diverter Bulk Materials Valves',
              tradeId: '40 33 26',
            },
            {
              id: 'c1507110-14af-47b2-8c3c-1e24d0f50c18',
              name: 'Double or Single Dump Bulk Materials Valves',
              tradeId: '40 33 29',
            },
            {
              id: '22d44794-0541-4260-82c9-0741dcef8177',
              name: 'Knife and Slide Gate Bulk Materials Valves',
              tradeId: '40 33 33',
            },
            {
              id: 'ef3fb665-7e70-4cb5-b8d5-c2af706cb6eb',
              name: 'Pinch Bulk Materials Valves',
              tradeId: '40 33 36',
            },
            {
              id: '20ec608f-d511-4cde-93c4-7cfaec90b96d',
              name: 'Swing Bulk Materials Valves',
              tradeId: '40 33 39',
            },
            {
              id: '77970376-5314-403f-b4f0-42954f4e1165',
              name: 'Specialty Bulk Materials Valves',
              tradeId: '40 33 43',
            },
          ],
          tradeId: '40 33 00',
        },
        {
          id: '0dd86f9b-9df4-4834-8cf3-e3fdb83d58fc',
          name: 'Pneumatic Conveying Lines',
          children: [
            {
              id: '5ef94b7c-8046-4ff7-a6b1-cafca294200b',
              name: 'Pneumatic Conveying Lines',
              tradeId: '40 34 00',
            },
            {
              id: '40ec1f6b-9991-4b54-9559-aa2b832fed59',
              name: 'Dense Phase Pneumatic Conveying Lines',
              tradeId: '40 34 13',
            },
            {
              id: '10e6793e-7811-49c4-b182-0244f567640e',
              name: 'Dilute Phase Pneumatic Conveying Lines',
              tradeId: '40 34 16',
            },
          ],
          tradeId: '40 34 00',
        },
        {
          id: '4b2c7697-7b5e-4d13-8281-6e58525c5cc2',
          name: 'Process Piping and Equipment Protection',
          children: [],
          tradeId: '40 40 00',
        },
        {
          id: '5fdddde6-2e1e-4f64-95ba-54b0942a1dc5',
          name: 'Process Piping and Equipment Heat Tracing',
          children: [
            {
              id: 'c571132c-e16b-4213-9e56-6ddcbb60f0b7',
              name: 'Process Piping and Equipment Heat Tracing',
              tradeId: '40 41 00',
            },
            {
              id: 'b14b943d-f852-449e-983b-7f37480336a1',
              name: 'Process Piping Heat Tracing',
              tradeId: '40 41 13',
            },
            {
              id: '524f515c-8eab-4cf4-8a47-bb259d0f13a0',
              name: 'Process Piping Electrical Resistance Heat Tracing',
              tradeId: '40 41 13.13',
            },
            {
              id: '0067527c-cbe1-42d6-bef0-3de4f402153d',
              name: 'Process Piping Electrical Conductance Heat Tracing',
              tradeId: '40 41 13.16',
            },
            {
              id: 'ece281c5-72e3-4069-8117-1c69905bedaf',
              name: 'Process Piping Gas Heat Tracing',
              tradeId: '40 41 13.19',
            },
            {
              id: '41518b6e-1858-4616-a1ab-f544ce3ff02d',
              name: 'Process Piping Steam Heat Tracing',
              tradeId: '40 41 13.23',
            },
            {
              id: 'cf3f20cc-9a6a-43f6-bb9e-ae60cc1488c4',
              name: 'Process Piping Thermal Fluids Heat Tracing',
              tradeId: '40 41 13.26',
            },
            {
              id: 'f0c42999-eea6-47c6-ae07-ed165ec82b7d',
              name: 'Process Equipment Heat Tracing',
              tradeId: '40 41 23',
            },
            {
              id: '1ff0f924-732a-4256-8895-b44733ad655e',
              name: 'Process Equipment Electrical Resistance Heat Tracing',
              tradeId: '40 41 23.13',
            },
            {
              id: '0feb98b4-88c2-4183-89e2-41d90ef2926f',
              name: 'Process Equipment Electrical Conductance Heat Tracing',
              tradeId: '40 41 23.16',
            },
            {
              id: 'b9dba61a-5443-44c5-9aa7-47c1e2f18e47',
              name: 'Process Equipment Gas Heat Tracing',
              tradeId: '40 41 23.19',
            },
            {
              id: '360c3d2b-d4d1-4218-86ef-43de764d2a26',
              name: 'Process Equipment Steam Heat Tracing',
              tradeId: '40 41 23.23',
            },
            {
              id: '4c5845c5-93f7-4108-8333-debfbafa89d2',
              name: 'Process Equipment Thermal Fluids Heat Tracing',
              tradeId: '40 41 23.26',
            },
          ],
          tradeId: '40 41 00',
        },
        {
          id: 'd46caf21-7970-4dc7-99ba-db64597a3ed5',
          name: 'Process Piping and Equipment Insulation',
          children: [
            {
              id: '23cfee8f-62d9-4133-9ea2-d471fdaf0cdd',
              name: 'Process Piping and Equipment Insulation',
              tradeId: '40 42 00',
            },
            {
              id: '6a6e1524-c07a-447c-9426-ce2020604a65',
              name: 'Process Piping Insulation',
              tradeId: '40 42 13',
            },
            {
              id: '207ff0af-a1c1-49b1-813f-a2f2e6cab160',
              name: 'Cryogenic Temperature Process Piping Insulation',
              tradeId: '40 42 13.13',
            },
            {
              id: 'd1de4899-3b11-4556-9365-3f8d30a2687e',
              name: 'Low Temperature Process Piping Insulation',
              tradeId: '40 42 13.16',
            },
            {
              id: '8ae87dca-9692-450b-9a75-1485a4ca083f',
              name: 'Intermediate Temperature Process Piping Insulation',
              tradeId: '40 42 13.19',
            },
            {
              id: 'ffbbfad1-838a-48e7-8719-ca7dcea2b7b9',
              name: 'High Temperature Process Piping Insulation',
              tradeId: '40 42 13.23',
            },
            {
              id: '3c8f5f3f-9150-445a-8738-14a753a152a5',
              name: 'Process Piping Insulation for Specialty Applications',
              tradeId: '40 42 13.26',
            },
            {
              id: '5c458704-af4d-4167-a3ff-743e6543230e',
              name: 'Process Equipment Insulation',
              tradeId: '40 42 23',
            },
            {
              id: 'c860c055-02d5-4977-91b3-9d216693bce5',
              name: 'Cryogenic Temperature Process Equipment Insulation',
              tradeId: '40 42 23.13',
            },
            {
              id: 'bcb3cae1-5b55-4090-80fb-5c355495840f',
              name: 'Low Temperature Process Equipment Insulation',
              tradeId: '40 42 23.16',
            },
            {
              id: '0276b18a-e1f8-4df9-a609-c19a06996cc9',
              name: 'Intermediate Temperature Process Equipment Insulation',
              tradeId: '40 42 23.19',
            },
            {
              id: '668c019c-ef14-460f-bd3e-08bc6d9319b6',
              name: 'High Temperature Process Equipment Insulation',
              tradeId: '40 42 23.23',
            },
            {
              id: '7cee88be-e4d2-4c0b-af3c-d083dae95bdf',
              name: 'Process Equipment Insulation for Specialty Applications',
              tradeId: '40 42 23.26',
            },
          ],
          tradeId: '40 42 00',
        },
        {
          id: '0edbef7b-60b2-4838-85e0-27837c9c12e3',
          name: 'Process Corrosion Protection',
          children: [
            {
              id: 'a021e600-806a-4ff5-9a0a-692fce70e9ce',
              name: 'Process Corrosion Protection',
              tradeId: '40 46 00',
            },
            {
              id: 'f43419a7-1f80-4858-88fe-c6ff8bde7db9',
              name: 'Coatings and Wrappings for Process Corrosion Protection',
              tradeId: '40 46 16',
            },
            {
              id: 'a2948002-4c2f-49e6-8f9c-1912bd41a2a0',
              name: 'Cathodic Process Corrosion Protection',
              tradeId: '40 46 42',
            },
          ],
          tradeId: '40 46 00',
        },
        {
          id: 'a161b95f-824a-48b3-aa62-b3c887b2ce28',
          name: 'Refractories',
          children: [
            {
              id: '55212f87-b566-42d8-b4c3-b0741202535c',
              name: 'Refractories',
              tradeId: '40 47 00',
            },
            {
              id: '1caeef02-d282-4f0b-8c08-17d25aa6476c',
              name: 'Silica Refractories',
              tradeId: '40 47 13',
            },
            {
              id: '0d1d666e-b6c6-4d28-9aa0-84dfc9d199a5',
              name: 'Alumina Refractories',
              tradeId: '40 47 16',
            },
            {
              id: '166db192-87cb-4226-b16e-43a4353ead6d',
              name: 'Carbon and Graphite Refractories',
              tradeId: '40 47 19',
            },
            {
              id: 'd7fce04d-e12f-4d6a-bb5a-cbc7a575f222',
              name: 'Castable Refractories',
              tradeId: '40 47 23',
            },
            {
              id: '1dc4e07c-53f0-4c04-8cc6-a49e0e6a99e5',
              name: 'Rammed Refractories',
              tradeId: '40 47 26',
            },
            {
              id: '3e7f5de8-965b-402b-96f7-6a9a5e5c27be',
              name: 'Refractory Concrete',
              tradeId: '40 47 29',
            },
          ],
          tradeId: '40 47 00',
        },
        {
          id: 'ca198b2f-7a5e-4640-90f0-4bb76eddf072',
          name: 'Commissioning of Process Systems',
          children: [],
          tradeId: '40 80 00',
        },
        {
          id: 'ebf20be6-8940-43c3-8dc4-003974f6ab99',
          name: 'Instrumentation and Control for Process Systems',
          children: [],
          tradeId: '40 90 00',
        },
        {
          id: '147669dc-70ad-4c74-ac8b-9fc73da4e438',
          name: 'Primary Process Measurement Devices',
          children: [
            {
              id: 'bc0b2237-bc70-48ee-ab04-2876ef239cad',
              name: 'Primary Process Measurement Devices',
              tradeId: '40 91 00',
            },
            {
              id: '3a60350b-5969-472a-9b32-a8a3c2e08626',
              name: 'Chemical Properties Process Measurement Devices',
              tradeId: '40 91 13',
            },
            {
              id: 'aaa9513f-f67b-47ab-a45e-947b34e15146',
              name: 'Ammonia Process Measurement Devices',
              tradeId: '40 91 13.13',
            },
            {
              id: 'e28bbfda-c98a-47c8-9d61-60310770843e',
              name: 'Chlorine Process Measurement Devices',
              tradeId: '40 91 13.16',
            },
            {
              id: '10b4bec5-a49b-438f-bd73-33759e4e6262',
              name: 'Fluoride Process Measurement Devices',
              tradeId: '40 91 13.19',
            },
            {
              id: 'cd7aed2f-6a7b-4d7c-81f7-05fdd64713a0',
              name: 'Gas Analysis Process Measurement Devices',
              tradeId: '40 91 13.23',
            },
            {
              id: 'aef483e6-6b82-4062-b447-535eae56ca52',
              name: 'Gas Chromatograph Process Measurement Devices',
              tradeId: '40 91 13.26',
            },
            {
              id: 'a326c1da-0c7f-4c2d-8f18-e99ab7b37f88',
              name: 'pH Level Process Measurement Devices',
              tradeId: '40 91 13.29',
            },
            {
              id: '876806af-c5b1-49ff-8f70-398f6eb9d2e0',
              name: 'Electromagnetic Process Measurement Devices',
              tradeId: '40 91 16',
            },
            {
              id: '0e11849b-2ed5-412d-9605-ef93496404cd',
              name: 'Amperes Process Measurement Devices',
              tradeId: '40 91 16.13',
            },
            {
              id: '27262763-11f7-48d4-be91-e3c0ed156a9f',
              name: 'Capacitance Process Measurement Devices',
              tradeId: '40 91 16.16',
            },
            {
              id: '01d8349b-7e28-46a5-a10f-f8311a969036',
              name: 'Conductivity Process Measurement Devices',
              tradeId: '40 91 16.19',
            },
            {
              id: '9f76f0e5-f65a-4261-a3ef-d7ba7816c8b6',
              name: 'Inductance Process Measurement Devices',
              tradeId: '40 91 16.23',
            },
            {
              id: '3841b47f-29c0-4d11-9ce4-c84e106a3fe5',
              name: 'Lumens Process Measurement Devices',
              tradeId: '40 91 16.26',
            },
            {
              id: 'd1facf96-fe11-4321-8755-6a1f5dd5dcf0',
              name: 'Magnetic Field Process Measurement Devices',
              tradeId: '40 91 16.29',
            },
            {
              id: '93772749-a5d7-4a8a-bde2-10b6336326b4',
              name: 'Electrical Power Process Measurement Devices',
              tradeId: '40 91 16.33',
            },
            {
              id: 'cd8378d8-ca4b-44c7-902c-7554d314d741',
              name: 'Radiation Process Measurement Devices',
              tradeId: '40 91 16.36',
            },
            {
              id: 'bb9a2ec6-a16e-4464-8be8-d0678fa37073',
              name: 'Electrical Resistance Process Measurement Devices',
              tradeId: '40 91 16.39',
            },
            {
              id: '67aafaf2-97c3-4f1a-a472-f8a2e4964103',
              name: 'Ultraviolet Sensors',
              tradeId: '40 91 16.43',
            },
            {
              id: 'c8513b6b-0f8c-44d7-b4aa-888f73397e46',
              name: 'Voltage Process Measurement Devices',
              tradeId: '40 91 16.46',
            },
            {
              id: 'e86e7cee-ba70-4193-b763-459ca0487d73',
              name: 'Physical Properties Process Measurement Devices',
              tradeId: '40 91 19',
            },
            {
              id: 'a95c4811-ede1-4b40-ad47-b0a383b1d9fe',
              name: 'Density Process Measurement Devices',
              tradeId: '40 91 19.13',
            },
            {
              id: '990d5b46-7910-4601-90fb-ff17b3f74e74',
              name: 'Humidity Process Measurement Devices',
              tradeId: '40 91 19.16',
            },
            {
              id: '236d5058-d871-43b1-946c-344eff89bb43',
              name: 'Mass Process Measurement Devices',
              tradeId: '40 91 19.19',
            },
            {
              id: '8351d186-eb17-4d3e-9ca9-57cd7d7f0496',
              name: 'Particle Counters Process Measurement Devices',
              tradeId: '40 91 19.23',
            },
            {
              id: '617cfcf1-614a-46e5-8cc5-0f9d543caf00',
              name: 'Gas Pressure Process Measurement Devices',
              tradeId: '40 91 19.26',
            },
            {
              id: '26e7b8fd-103a-4295-a80f-f41e4f2d5e0e',
              name: 'Liquid Pressure Process Measurement Devices',
              tradeId: '40 91 19.29',
            },
            {
              id: '80284b56-ff5b-4c3c-ba51-33d20eadd5d3',
              name: 'Stress/Strain Process Measurement Devices',
              tradeId: '40 91 19.33',
            },
            {
              id: '58b5f76a-39e3-40fe-afa8-a31ab7259d88',
              name: 'Temperature Process Measurement Devices',
              tradeId: '40 91 19.36',
            },
            {
              id: '5736417f-23ef-44a0-a91e-e6177e3d8d96',
              name: 'Vapor Pressure Process Measurement Devices',
              tradeId: '40 91 19.39',
            },
            {
              id: 'c2f00474-b62d-4489-bb83-a84c9a66e167',
              name: 'Weight Process Measurement Devices',
              tradeId: '40 91 19.43',
            },
            {
              id: '9ab7053f-2d42-4b0e-bb24-d62a316d0b78',
              name: 'Miscellaneous Properties Measurement Devices',
              tradeId: '40 91 23',
            },
            {
              id: '4d8da949-7788-498f-85d3-c8857467e39c',
              name: 'Acceleration Process Measurement Devices',
              tradeId: '40 91 23.13',
            },
            {
              id: '7e1e8bfc-20e4-414b-9f52-d1a4df58224f',
              name: 'Angle Process Measurement Devices',
              tradeId: '40 91 23.16',
            },
            {
              id: '262ca004-92bc-4d91-97d8-bb0b006cf2ed',
              name: 'Color Process Measurement Devices',
              tradeId: '40 91 23.19',
            },
            {
              id: '4185cb83-f2cc-4982-b2e6-42e19ee11611',
              name: 'Count Process Measurement Devices',
              tradeId: '40 91 23.23',
            },
            {
              id: '65ccb312-a700-4ea6-a05d-94376ebf5498',
              name: 'Distance Process Measurement Devices',
              tradeId: '40 91 23.26',
            },
            {
              id: '161795a0-649e-454d-b9e7-23a71ace115d',
              name: 'Energy Process Measurement Devices',
              tradeId: '40 91 23.29',
            },
            {
              id: 'c56a9e7f-6bc0-47b1-b9be-ed6ef787c605',
              name: 'Flow Process Measurement Devices',
              tradeId: '40 91 23.33',
            },
            {
              id: '01e69e3f-d14e-4559-9102-6ba65857e73c',
              name: 'Level Process Measurement Devices',
              tradeId: '40 91 23.36',
            },
            {
              id: '29496500-47be-4168-8722-49161a3edc65',
              name: 'Physical Resistance Process Measurement Devices',
              tradeId: '40 91 23.39',
            },
            {
              id: '1ad3ac1e-6939-4abc-9197-168507345664',
              name: 'RPM Process Measurement Devices',
              tradeId: '40 91 23.43',
            },
            {
              id: '9c453e40-1682-474b-8f53-035bc1cd29eb',
              name: 'Time Process Measurement Devices',
              tradeId: '40 91 23.46',
            },
            {
              id: '8354fb8e-8cc2-43e6-bc1c-5ac9ddcc441e',
              name: 'Turbidity Process Measurement Devices',
              tradeId: '40 91 23.49',
            },
            {
              id: 'bf28da17-37c3-435c-96be-1371bf389e5d',
              name: 'Velocity Process Measurement Devices',
              tradeId: '40 91 23.53',
            },
          ],
          tradeId: '40 91 00',
        },
        {
          id: 'f96945b4-7156-401a-b813-e3a830ea91e2',
          name: 'Primary Control Devices',
          children: [
            {
              id: 'c914a678-c9e2-4fef-9979-5a35f39d1401',
              name: 'Primary Control Devices',
              tradeId: '40 92 00',
            },
            {
              id: 'ad5e469e-53a4-4a31-9c14-3aa4d1b55179',
              name: 'Primary Control Valves',
              tradeId: '40 92 13',
            },
            {
              id: '207aaf1c-ff6c-4ad0-bc20-1db5023e77fa',
              name: 'Electrically-Operated Primary Control Valves',
              tradeId: '40 92 13.13',
            },
            {
              id: '1edf9f6f-211e-450c-8fbd-a4ed44af8455',
              name: 'Hydraulically-Operated Primary Control Valves',
              tradeId: '40 92 13.16',
            },
            {
              id: '1930a102-227d-4557-b1d8-c053488c003a',
              name: 'Pneumatically-Operated Primary Control Valves',
              tradeId: '40 92 13.19',
            },
            {
              id: '17553aca-effa-475f-9655-18a9d164042b',
              name: 'Pressure-Relief Primary Control Valves',
              tradeId: '40 92 13.23',
            },
            {
              id: '01069894-e2e8-44a0-82ed-1da526b4bb98',
              name: 'Solenoid Primary Control Valves',
              tradeId: '40 92 13.26',
            },
            {
              id: '863860db-fefe-4e10-8373-ec3504d36d8b',
              name: 'Specialty Primary Control Valves',
              tradeId: '40 92 13.29',
            },
            {
              id: '8ce1d316-b3bd-42ea-8770-f64c3c8f0248',
              name: 'Current-To-Pressure Converters',
              tradeId: '40 92 29',
            },
            {
              id: 'fc2ec37c-9888-41fd-92bd-61c1d880c9e5',
              name: 'Self-Contained Flow Controllers',
              tradeId: '40 92 33',
            },
            {
              id: '2bef16c1-4373-44bd-b265-875f7c6d124e',
              name: 'Linear Actuators and Positioners',
              tradeId: '40 92 36',
            },
            {
              id: '5008017a-2aaa-40c2-8278-d455a89f6d81',
              name: 'Self-Contained Pressure Regulators',
              tradeId: '40 92 39',
            },
            {
              id: '56d76d78-1230-4465-a477-c8a463694b59',
              name: 'Rotary Actuators',
              tradeId: '40 92 43',
            },
            {
              id: 'a3d483a0-c63e-4e25-a61f-02c95678ae26',
              name: 'Saturable Core Reactors',
              tradeId: '40 92 46',
            },
            {
              id: '49648609-e45e-4863-8c36-dc858c6ba322',
              name: 'Variable Frequency Drives',
              tradeId: '40 92 49',
            },
            {
              id: '03ffb536-c61b-4790-97d6-55b64e0a55ea',
              name: 'Voltage-To-Pressure Converters',
              tradeId: '40 92 53',
            },
          ],
          tradeId: '40 92 00',
        },
        {
          id: 'dc48a65f-abd0-4106-b2f5-622e2cfe342d',
          name: 'Analog Controllers/Recorders',
          children: [
            {
              id: '33810b72-5e86-4fdf-be34-6a37be352b42',
              name: 'Analog Controllers/Recorders',
              tradeId: '40 93 00',
            },
            {
              id: '3011af58-8eb9-4e9f-b63f-9e792aa643cf',
              name: 'Analog Controllers',
              tradeId: '40 93 13',
            },
            {
              id: 'ecb07fad-6dc1-4a33-aee2-01ab2a2585af',
              name: 'Electronic Analog Controllers',
              tradeId: '40 93 13.13',
            },
            {
              id: 'fc002e3b-b56e-4788-8846-453a498bee42',
              name: 'Electro-Hydraulic Analog Controllers',
              tradeId: '40 93 13.16',
            },
            {
              id: 'd5cd005b-403c-4a78-a809-0708a2a6193d',
              name: 'Electro-Pneumatic Analog Controllers',
              tradeId: '40 93 13.19',
            },
            {
              id: '380aa516-46d2-4e9a-abf5-cff271103816',
              name: 'Hydraulic Analog Controllers',
              tradeId: '40 93 13.23',
            },
            {
              id: 'b96fca84-0013-419b-8de6-7d3b7f1c319a',
              name: 'Pneumatic Analog Controllers',
              tradeId: '40 93 13.26',
            },
            {
              id: 'c17765ea-468a-4484-930f-38aa075e8a50',
              name: 'Chart Recorders',
              tradeId: '40 93 23',
            },
          ],
          tradeId: '40 93 00',
        },
        {
          id: 'cd39305a-5342-4d05-b3f0-891ebfe5a3e7',
          name: 'Digital Process Controllers',
          children: [
            {
              id: '6370a65b-cd2f-4ba0-a226-10795ea603fe',
              name: 'Digital Process Controllers',
              tradeId: '40 94 00',
            },
            {
              id: 'd0f630fa-0f48-4f70-bd5a-7b1cab4db933',
              name: 'Digital Process Control Computers',
              tradeId: '40 94 13',
            },
            {
              id: 'bfa355f2-1b9e-4072-846e-1699c68ce846',
              name: 'Host Digital Process Control Computers',
              tradeId: '40 94 13.13',
            },
            {
              id: 'a60867eb-0829-4d2b-9d77-9d82d921eb66',
              name: 'Personal Digital Process Control Computers',
              tradeId: '40 94 13.16',
            },
            {
              id: 'ae8aee55-6e6c-4834-9b83-d0ded84f01f6',
              name: 'Personal Digital Assistant Digital Process Control Computers',
              tradeId: '40 94 13.19',
            },
            {
              id: '1921a925-3339-4f12-8891-7f0bba4443a8',
              name: 'Distributed Process Control Systems',
              tradeId: '40 94 23',
            },
            {
              id: '45f579df-ad50-4175-a942-b2d59de8966a',
              name: 'Human – Machine Interfaces',
              tradeId: '40 94 33',
            },
            {
              id: '2ec9d3c1-edec-4afc-a67e-ebb64324fc37',
              name: 'Programmable Logic Process Controllers',
              tradeId: '40 94 43',
            },
          ],
          tradeId: '40 94 00',
        },
        {
          id: 'fcdd98c6-2b68-481d-8508-e01c53b8bb71',
          name: 'Process Control Hardware',
          children: [
            {
              id: '051533e3-7bb1-4e6f-8cc6-adaff6de3dd7',
              name: 'Process Control Hardware',
              tradeId: '40 95 00',
            },
            {
              id: '3feb5541-12f3-40d6-b292-c60c330ba0cd',
              name: 'Process Control Panels and Hardware',
              tradeId: '40 95 13',
            },
            {
              id: '8069d47b-883d-4304-9969-c606cfe1089b',
              name: 'Local Process Control Panels and Hardware',
              tradeId: '40 95 13.13',
            },
            {
              id: '78c215d2-3339-4501-bdd8-90afe9be7067',
              name: 'Main Process Control Panels and Hardware',
              tradeId: '40 95 13.23',
            },
            {
              id: 'bfec526c-15e8-4b07-9b45-7f20f5f061d0',
              name: 'Process Control Display Devices',
              tradeId: '40 95 20',
            },
            {
              id: '6dc92667-3550-4bf7-82d6-31cdfd0506a1',
              name: 'Process Control Input/Output Modules',
              tradeId: '40 95 23',
            },
            {
              id: '8b7cd6cd-650b-436c-bce0-80a276043004',
              name: 'Process Control Instrument Air Piping and Devices',
              tradeId: '40 95 26',
            },
            {
              id: '6c91d0f9-dbcf-4efb-b556-25df6b311eca',
              name: 'Process Control Networks',
              tradeId: '40 95 33',
            },
            {
              id: '2f6c5016-bd78-45f6-8277-c2dbe10d7d21',
              name: 'Cabled Process Control Networks',
              tradeId: '40 95 33.13',
            },
            {
              id: 'f320e095-9b6e-4287-9110-cfddd2bde66d',
              name: 'Fiber Optic Process Control Networks',
              tradeId: '40 95 33.23',
            },
            {
              id: 'cf16bfa1-e203-40c7-b666-02cd0ab707f6',
              name: 'Wireless Process Control Networks',
              tradeId: '40 95 33.33',
            },
            {
              id: 'e1912da4-74ba-4b38-9faa-e02006ff998e',
              name: 'Process Control Hardware Interfaces',
              tradeId: '40 95 43',
            },
            {
              id: '32ba4618-50a6-4b06-8866-c3f4a1ca64bc',
              name: 'Process Control Mounting Racks and Supports',
              tradeId: '40 95 46',
            },
            {
              id: '36ad0187-3f92-44cc-a52e-829524587633',
              name: 'Process Control Routers',
              tradeId: '40 95 49',
            },
            {
              id: '0e559f9b-1e56-4a4f-9938-a340bccfddc6',
              name: 'Process Control Switches',
              tradeId: '40 95 53',
            },
            {
              id: 'c9e72daf-9e32-423a-9726-f09d4ec9c090',
              name: 'Process Control Transformers',
              tradeId: '40 95 56',
            },
            {
              id: '4e7afe98-e320-4c2b-8d1a-f902f1cabe15',
              name: 'Process Control Wireless Equipment',
              tradeId: '40 95 63',
            },
            {
              id: '44de2d98-9095-429d-adcf-f1772fe76b80',
              name: 'Process Control Wireless Transmitters',
              tradeId: '40 95 63.13',
            },
            {
              id: 'f7f3b87a-cf60-42ef-8725-2c78c3e57a70',
              name: 'Process Control Wireless Receivers',
              tradeId: '40 95 63.16',
            },
            {
              id: 'e4b3ba56-de5b-4b15-b6f3-093aa007ad13',
              name: 'Process Control Wireless Repeaters',
              tradeId: '40 95 63.19',
            },
            {
              id: '6a19f963-8c58-49e1-89e8-4a433e0c0742',
              name: 'Process Control Wiring',
              tradeId: '40 95 73',
            },
            {
              id: '2aa60d2e-2e0e-4a29-ad57-3de7fdd6868d',
              name: 'Process Control Cable',
              tradeId: '40 95 73.23',
            },
            {
              id: 'e478ce2e-a5b3-4f71-b553-f4bad06018e9',
              name: 'Process Control Conduit, Raceway and Supports',
              tradeId: '40 95 73.33',
            },
            {
              id: '6c969d43-d64b-4ccc-a840-1e0134f727a4',
              name: 'Process Control Junction Boxes',
              tradeId: '40 95 73.43',
            },
          ],
          tradeId: '40 95 00',
        },
        {
          id: '15375c0e-bc3e-4dbb-81ce-8b62adf36556',
          name: 'Process Control Software',
          children: [
            {
              id: '7ee11aff-68a1-4a5e-84d5-c56f12ca6d20',
              name: 'Process Control Software',
              tradeId: '40 96 00',
            },
            {
              id: '92163f79-d0ef-4d2f-93ba-a5d85da43370',
              name: 'Process Control Software Architecture',
              tradeId: '40 96 10',
            },
            {
              id: 'da0a5dde-858a-4274-acf5-6addde5850ed',
              name: 'Process Control Software Input/Output Lists',
              tradeId: '40 96 15',
            },
            {
              id: 'f5b207b8-3b61-4fbe-8fa6-eba0b5023e01',
              name: 'Process Control Software Instrument Lists',
              tradeId: '40 96 20',
            },
            {
              id: '6d87405d-015c-44a9-8b75-fee4a4c884ca',
              name: 'Process Control Software Logic Diagrams',
              tradeId: '40 96 25',
            },
            {
              id: '03735c02-b78b-4e43-826f-1c36752c03d1',
              name: 'Process Control Software Loop Diagrams',
              tradeId: '40 96 30',
            },
            {
              id: '31cfad3a-efbe-4996-af61-6df097926130',
              name: 'Process Control Software Programming',
              tradeId: '40 96 35',
            },
            {
              id: 'e2b3c2b2-cb9a-474a-a5f9-29b8fa5a1bec',
              name: 'Process Control Software Reports',
              tradeId: '40 96 40',
            },
          ],
          tradeId: '40 96 00',
        },
        {
          id: '6f9d87a2-cb8a-4ef8-8b8a-75ba6e4ae4fb',
          name: 'Process Control Auxiliary Devices',
          children: [
            {
              id: '7d613ce7-0f82-4fe3-9d7b-80406ece9ae6',
              name: 'Process Control Auxiliary Devices',
              tradeId: '40 97 00',
            },
            {
              id: 'd77529b4-fe4c-44a3-8700-ccbebf31cfb9',
              name: 'Process Control Annunciators',
              tradeId: '40 97 10',
            },
            {
              id: '47aabbcc-bb5f-4a2f-a8f1-61d8b708b7fe',
              name: 'Process Control Gages',
              tradeId: '40 97 15',
            },
            {
              id: 'f6b06f60-df6f-44c3-bc35-08cd52b050c1',
              name: 'Process Control Rotameters',
              tradeId: '40 97 20',
            },
            {
              id: '8dd0cce7-40ae-4c6c-bb8c-62b3b65c1fbf',
              name: 'Process Control Potentiometers',
              tradeId: '40 97 25',
            },
            {
              id: 'a80adb08-97ab-47ba-bc17-3cb7e2abe7d6',
              name: 'Process Control Test Equipment',
              tradeId: '40 97 30',
            },
          ],
          tradeId: '40 97 00',
        },
      ],
      tradeId: '40 00 00',
    },
    {
      id: '770495d2-55e2-4b40-ae1e-07dace72955f',
      name: 'Material Processing and Handling Equipment',
      children: [
        {
          id: '8e0e36a7-2e68-4728-ab0f-f3b3b083b10d',
          name: 'Material Processing and Handling Equipment',
          children: [],
          tradeId: '41 00 00',
        },
        {
          id: 'e75d1724-d2f6-4941-98f5-08e067d23ee6',
          name: 'Operation and Maintenance of Material Processing and Handling Equipment',
          children: [
            {
              id: 'da2ddc5c-6d6f-4091-a4c0-e97eb61e5e11',
              name: 'Operation and Maintenance of Material Processing and Handling Equipment',
              tradeId: '41 01 00',
            },
            {
              id: '3be4a9ad-ac21-4101-a18e-26f51e659111',
              name: 'Operation and Maintenance of Bulk Material Processing Equipment',
              tradeId: '41 01 10',
            },
            {
              id: 'a4a4c19e-0dd4-4b67-bb72-ffa2bdb6ca80',
              name: 'Operation and Maintenance of Piece Material Handling Equipment',
              tradeId: '41 01 20',
            },
            {
              id: 'bf28a129-dbca-433d-a964-a7484f8416f2',
              name: 'Operation and Maintenance of Manufacturing Equipment',
              tradeId: '41 01 30',
            },
            {
              id: '648d3be8-3b05-4e25-8e4f-d8718c172486',
              name: 'Operation and Maintenance of Container Processing and Packaging',
              tradeId: '41 01 40',
            },
            {
              id: '19ee104c-b7e0-4688-aab7-07d268a2548f',
              name: 'Operation and Maintenance of Material Storage',
              tradeId: '41 01 50',
            },
            {
              id: 'c8cf0a3c-c866-471e-acab-a1b5ab9a4821',
              name: 'Operation and Maintenance of Mobile Plant Equipment',
              tradeId: '41 01 60',
            },
          ],
          tradeId: '41 01 00',
        },
        {
          id: '4af629bd-bb2f-43b5-953f-70a5ee30a2dd',
          name: 'Schedules for Material Processing and Handling Equipment',
          children: [
            {
              id: 'ccb3ecd8-0ccd-41a4-aef6-e8a16ca1b4d6',
              name: 'Schedules for Material Processing and Handling Equipment',
              tradeId: '41 06 00',
            },
            {
              id: '674aebe0-aea4-4235-8406-685ca4f1f5bf',
              name: 'Schedules for Bulk Material Processing Equipment',
              tradeId: '41 06 10',
            },
            {
              id: '46eac529-c8ef-436c-a04e-7669719be434',
              name: 'Schedules for Piece Material Handling Equipment',
              tradeId: '41 06 20',
            },
            {
              id: '33f0190d-a073-471d-b4f1-a51fc6a6f32e',
              name: 'Schedules for Manufacturing Equipment',
              tradeId: '41 06 30',
            },
            {
              id: 'c6bc6ace-837c-47ec-8220-943f747426a1',
              name: 'Schedules for Container Processing and Packaging',
              tradeId: '41 06 40',
            },
            {
              id: 'c0f2fd4b-cb5a-4e46-9dcc-31c22520db69',
              name: 'Schedules for Material Storage',
              tradeId: '41 06 50',
            },
            {
              id: '86352c9f-3f9c-42ed-96b8-8414bf50c262',
              name: 'Schedules for Mobile Plant Equipment',
              tradeId: '41 06 60',
            },
          ],
          tradeId: '41 06 00',
        },
        {
          id: '80a6e72d-04df-471a-a737-72d02a93b262',
          name: 'Commissioning of Material Processing and Handling Equipment',
          children: [
            {
              id: '544cba06-7642-4894-bd51-9429e6857575',
              name: 'Commissioning of Material Processing and Handling Equipment',
              tradeId: '41 08 00',
            },
            {
              id: 'ca175b1d-8706-43c6-bb70-35ad3330f3ff',
              name: 'Commissioning of Bulk Material Processing Equipment',
              tradeId: '41 08 10',
            },
            {
              id: '35ac71de-ffc7-4f34-b4c6-8918a70c8e44',
              name: 'Commissioning of Piece Material Handling Equipment',
              tradeId: '41 08 20',
            },
            {
              id: 'f4bb733d-cbd8-4adf-bbaf-53a8330b4601',
              name: 'Commissioning of Manufacturing Equipment',
              tradeId: '41 08 30',
            },
            {
              id: 'b222b07f-ff0f-4b24-8eaf-0bc3ce1cc7f3',
              name: 'Commissioning of Container Processing and Packaging',
              tradeId: '41 08 40',
            },
            {
              id: 'fd5a3e71-ff65-4ca9-a595-49631ae8b4d0',
              name: 'Commissioning of Material Storage',
              tradeId: '41 08 50',
            },
            {
              id: '1c7f8b4d-6340-469d-9fbb-63b474ef2421',
              name: 'Commissioning of Mobile Plant Equipment',
              tradeId: '41 08 60',
            },
          ],
          tradeId: '41 08 00',
        },
        {
          id: '1fbdc783-89dc-41c2-9d51-8165763841c1',
          name: 'Bulk Material Processing Equipment',
          children: [],
          tradeId: '41 10 00',
        },
        {
          id: '63b16b16-02ed-495f-a387-ed190dd22541',
          name: 'Bulk Material Sizing Equipment',
          children: [
            {
              id: '7bef4ed3-b941-419f-908c-031fede94bae',
              name: 'Bulk Material Sizing Equipment',
              tradeId: '41 11 00',
            },
            {
              id: '5defdae8-1539-4349-aeed-2240c4e675da',
              name: 'Bulk Material Agglomerators',
              tradeId: '41 11 13',
            },
            {
              id: '90f3c0aa-ac74-4419-9df1-bc9b8babbaee',
              name: 'Bulk Material Air Mill Classifiers',
              tradeId: '41 11 16',
            },
            {
              id: '1627e62a-94c4-4a34-b3ef-c8aa7d07b03d',
              name: 'Bulk Material Centrifuges',
              tradeId: '41 11 19',
            },
            {
              id: '50104a22-c56c-4e7d-bdbd-1f64ac9bca61',
              name: 'Bulk Material Crushers',
              tradeId: '41 11 23',
            },
            {
              id: '3f655f5d-7543-420b-bbc1-3797982b17dc',
              name: 'Bulk Material Cyclones',
              tradeId: '41 11 26',
            },
            {
              id: '4460ee94-c18a-4b34-aa19-826b0b09bca9',
              name: 'Bulk Material Fluid Bed Separators',
              tradeId: '41 11 29',
            },
            {
              id: 'b39bae2a-7fca-4715-a3b6-d2c24100b058',
              name: 'Bulk Material Grinders',
              tradeId: '41 11 33',
            },
            {
              id: '38846f83-2e8e-48b9-b0fa-e5646112b247',
              name: 'Bulk Material Homogenizers',
              tradeId: '41 11 36',
            },
            {
              id: '30218399-3482-47f1-ad38-7258d1431c08',
              name: 'Bulk Material Lump Breakers',
              tradeId: '41 11 39',
            },
            {
              id: '09411924-d961-4066-80a1-4443fb34ae90',
              name: 'Bulk Material Mills',
              tradeId: '41 11 43',
            },
            {
              id: '6ca66524-845b-4f0c-b91b-08309816452e',
              name: 'Bulk Material Pulverizers',
              tradeId: '41 11 46',
            },
            {
              id: 'bb2b9459-c9de-4af8-8dad-5110a9d65f69',
              name: 'Bulk Material Screens',
              tradeId: '41 11 49',
            },
            {
              id: '91af84a2-f23b-4add-a244-26981bf852bf',
              name: 'Bulk Material Shredders',
              tradeId: '41 11 53',
            },
            {
              id: 'a2a85e93-afb5-494c-881f-f4197553bc7c',
              name: 'Bulk Material Sieves',
              tradeId: '41 11 56',
            },
          ],
          tradeId: '41 11 00',
        },
        {
          id: '97ffb09d-b0c7-4315-804a-8502c09be606',
          name: 'Bulk Material Conveying Equipment',
          children: [
            {
              id: 'b8d01341-0f5c-4b86-9e84-a74397d47bf7',
              name: 'Bulk Material Conveying Equipment',
              tradeId: '41 12 00',
            },
            {
              id: '494fd544-ab2a-4c4d-addb-e0aa445812ac',
              name: 'Bulk Material Conveyors',
              tradeId: '41 12 13',
            },
            {
              id: '24331d43-3e90-44a6-9fab-201260faa0fa',
              name: 'Airslide Bulk Material Conveyors',
              tradeId: '41 12 13.13',
            },
            {
              id: 'eae39fbd-2d3b-431c-aaed-65985ca23ec6',
              name: 'Auger Bulk Material Conveyors',
              tradeId: '41 12 13.16',
            },
            {
              id: '644c61a6-1e38-4fbc-80c0-3add12308675',
              name: 'Belt Bulk Material Conveyors',
              tradeId: '41 12 13.19',
            },
            {
              id: '99c96356-44cb-46b4-abc4-486db3f56814',
              name: 'Container Bulk Material Conveyors',
              tradeId: '41 12 13.23',
            },
            {
              id: '3892c09d-c608-4864-a9ed-08262a90a3dd',
              name: 'Drag Chain Bulk Material Conveyors',
              tradeId: '41 12 13.26',
            },
            {
              id: '49b44a95-582d-42f7-a320-22e519399ab7',
              name: 'Hopper Bulk Material Conveyors',
              tradeId: '41 12 13.29',
            },
            {
              id: '9e94ee8e-201a-47e2-b0c9-1d5040676556',
              name: 'Reciprocating Bulk Material Conveyors',
              tradeId: '41 12 13.33',
            },
            {
              id: '2d29ba52-cd27-4e19-ae82-e3551993d82d',
              name: 'Screw Bulk Material Conveyors',
              tradeId: '41 12 13.36',
            },
            {
              id: 'c3f11c6d-bccd-48b1-aee1-df2fb9bb1d94',
              name: 'Stacking and Reclaim Bulk Material Conveyors',
              tradeId: '41 12 13.39',
            },
            {
              id: '1a32b179-72c1-49dd-83eb-ae2546abfe97',
              name: 'Trough Bulk Material Conveyors',
              tradeId: '41 12 13.43',
            },
            {
              id: 'fb691edc-3440-4e39-a222-19886c62db7d',
              name: 'Tube Bulk Material Conveyors',
              tradeId: '41 12 13.46',
            },
            {
              id: 'b3efc66a-5ac2-4397-b775-da70ad4702b9',
              name: 'Vibratory Bulk Material Conveyors',
              tradeId: '41 12 13.49',
            },
            {
              id: 'fda5f7c5-eef6-402d-8474-e7bf70fc8072',
              name: 'Weigh-Belt Bulk Material Conveyors',
              tradeId: '41 12 13.53',
            },
            {
              id: '7c57aaa0-3170-4f7f-818f-f6c4c32a29a9',
              name: 'Bucket Elevators',
              tradeId: '41 12 16',
            },
            {
              id: '0bc5b175-2fd1-4ac4-a5e6-e69d1fefa64f',
              name: 'Pneumatic Conveyors',
              tradeId: '41 12 19',
            },
            {
              id: 'f3d6f31a-60a0-43e1-8d70-8d064e4fd1af',
              name: 'Dense Phase Pneumatic Conveyors',
              tradeId: '41 12 19.13',
            },
            {
              id: 'ba52ab52-48d7-4917-8e52-85870772f368',
              name: 'Dilute Phase Pneumatic Conveyors',
              tradeId: '41 12 19.16',
            },
          ],
          tradeId: '41 12 00',
        },
        {
          id: '122c7320-032f-467a-b575-ad1ec7af2cf9',
          name: 'Bulk Material Feeders',
          children: [
            {
              id: '1ed87df0-ed28-4dee-9706-48d6c46e8076',
              name: 'Bulk Material Feeders',
              tradeId: '41 13 00',
            },
            {
              id: 'ec38c5cb-80cf-48fd-8de9-e2b5127a2883',
              name: 'Bin Activators/Live Bin Bottoms',
              tradeId: '41 13 13',
            },
            {
              id: 'b11212df-2512-40de-9198-dc097860a216',
              name: 'Feeders',
              tradeId: '41 13 23',
            },
            {
              id: 'cc1f953d-702c-48ed-bc13-7e88b4648c7d',
              name: 'Airlock Bulk Material Feeders',
              tradeId: '41 13 23.13',
            },
            {
              id: 'e1e905e3-08ea-4737-a79f-aa3950c7d30f',
              name: 'Apron Bulk Material Feeders',
              tradeId: '41 13 23.16',
            },
            {
              id: 'e178f9d6-8519-4266-a88b-3b9627b23313',
              name: 'Rotary-Valve Bulk Material Feeders',
              tradeId: '41 13 23.19',
            },
            {
              id: '4780f6ab-8fd7-4ad4-810a-c32fb15f41d3',
              name: 'Screw Bulk Material Feeders',
              tradeId: '41 13 23.23',
            },
            {
              id: '6eb430ea-95d0-4912-af64-b047c8059339',
              name: 'Vibratory Bulk Material Feeders',
              tradeId: '41 13 23.26',
            },
            {
              id: 'b4f6bf8a-2d8c-4c0a-8873-0afa4fb52a21',
              name: 'Volumetric Bulk Material Feeders',
              tradeId: '41 13 23.29',
            },
            {
              id: 'edd54416-6625-403a-a4ea-60ded82e356c',
              name: 'Weigh Bulk Material Feeders',
              tradeId: '41 13 23.33',
            },
          ],
          tradeId: '41 13 00',
        },
        {
          id: 'e367e190-ab78-43e9-80a7-7e0479e77642',
          name: 'Batching Equipment',
          children: [
            {
              id: '2dbc5fcb-fcd9-4b87-9586-0e4025f53e8d',
              name: 'Batching Equipment',
              tradeId: '41 14 00',
            },
            {
              id: 'bb541218-fe73-4cc4-9032-8c08526631b3',
              name: 'Bag-Handling Batching Equipment',
              tradeId: '41 14 13',
            },
            {
              id: 'f9818515-da1b-4e8f-a46a-5743bbdaba67',
              name: 'Batch Cars/Transports',
              tradeId: '41 14 16',
            },
            {
              id: '45458e60-db3d-47c5-9698-f296716a356f',
              name: 'Batch Hoppers',
              tradeId: '41 14 19',
            },
            {
              id: '4469113d-d9c6-41cd-9e9e-45774e2b54de',
              name: 'Bulk Bag-Handling Batching Equipment',
              tradeId: '41 14 23',
            },
            {
              id: 'b8e6234f-4318-4227-bb3e-bc734eb2e547',
              name: 'Blenders',
              tradeId: '41 14 26',
            },
            {
              id: '5ffa8975-912e-4e7b-add6-2b0fa5f830c3',
              name: 'Drum-Handling Batching Equipment',
              tradeId: '41 14 29',
            },
            {
              id: '01f51262-5533-46bc-b0a7-4e8e27daffce',
              name: 'Mixers',
              tradeId: '41 14 33',
            },
            {
              id: '67489ad2-af7f-498c-9734-24e56d8f9cff',
              name: 'Weigh Scales',
              tradeId: '41 14 36',
            },
          ],
          tradeId: '41 14 00',
        },
        {
          id: 'b2a8a9b6-bf3a-49da-859c-eee02b9aec74',
          name: 'Piece Material Handling Equipment',
          children: [],
          tradeId: '41 20 00',
        },
        {
          id: '51a57b0a-ae2a-4388-b535-a93e9ad104d9',
          name: 'Conveyors',
          children: [
            {
              id: '2bd3bb06-da58-4802-b643-b31a61675b3a',
              name: 'Conveyors',
              tradeId: '41 21 00',
            },
            {
              id: 'a9279795-fae2-4928-852d-cf43cf9985ed',
              name: 'Automatic Guided Vehicle Systems',
              tradeId: '41 21 13',
            },
            {
              id: 'bab8711c-175c-4a91-94d1-7c0d95f50140',
              name: 'Piece Material Conveyors',
              tradeId: '41 21 23',
            },
            {
              id: '4a365737-2841-418c-9843-7fa9ed6ee6fa',
              name: 'Belt Piece Material Conveyors',
              tradeId: '41 21 23.13',
            },
            {
              id: 'c86bfe15-858c-4744-8097-6f9aa2f5a931',
              name: 'Container Piece Material Conveyors',
              tradeId: '41 21 23.16',
            },
            {
              id: '95ea50a0-4a85-4aa0-be62-33a87c30ae1f',
              name: 'Drag-Chain Piece Material Conveyors',
              tradeId: '41 21 23.19',
            },
            {
              id: 'c1ada6c0-93c4-4364-8871-af14441ee946',
              name: 'Hopper Piece Material Conveyors',
              tradeId: '41 21 23.23',
            },
            {
              id: 'f540f9d6-bd36-4b2e-b4ad-c7273314b43d',
              name: 'Monorail Piece Material Conveyors',
              tradeId: '41 21 23.26',
            },
            {
              id: '7447278d-6ec3-45d9-a23e-4f59f35b5044',
              name: 'Power and Free Piece Material Conveyors',
              tradeId: '41 21 23.29',
            },
            {
              id: '589b24bb-b803-4107-824f-68e7245fb66e',
              name: 'Reciprocating Piece Material Conveyors',
              tradeId: '41 21 23.33',
            },
            {
              id: '437d816a-e12d-4664-a3e4-c80b74cca795',
              name: 'Roller Piece Material Conveyors',
              tradeId: '41 21 23.36',
            },
            {
              id: '04010967-7ba8-498e-b7a9-e5f1f87affdd',
              name: 'Vibratory Piece Material Conveyors',
              tradeId: '41 21 23.39',
            },
            {
              id: 'aa0da1ba-5e4b-400c-9bca-b53e8965a136',
              name: 'Walking-Beam Piece Material Conveyors',
              tradeId: '41 21 23.43',
            },
            {
              id: '2d33a588-126a-4968-9d65-0abf15f60d73',
              name: 'Weigh-Belt Piece Material Conveyors',
              tradeId: '41 21 23.46',
            },
            {
              id: 'ea9383a8-e2b1-4f31-be56-8ad70a12ba0c',
              name: 'Postal Conveyors',
              tradeId: '41 21 23.53',
            },
            {
              id: 'b74290cc-317c-45b5-9611-338490e9af30',
              name: 'Piece Material Diverter Gates',
              tradeId: '41 21 26',
            },
            {
              id: 'fba50822-10a0-4a1b-b41a-60cdae6f7d70',
              name: 'Piece Material Gravity Slides',
              tradeId: '41 21 29',
            },
            {
              id: 'bda83083-b234-4292-a0bf-57879c83e38d',
              name: 'Piece Material Transfer Cars',
              tradeId: '41 21 33',
            },
            {
              id: '9c72d788-4913-48ed-a7b4-101fe8ea9846',
              name: 'Piece Material Turntables',
              tradeId: '41 21 36',
            },
            {
              id: '21e304c9-79bc-4bff-a968-6b6d0133f7c5',
              name: 'Piece Material Feeders',
              tradeId: '41 21 39',
            },
            {
              id: 'd0d7f96b-f99d-4efa-ac4b-54ad30fee903',
              name: 'Piece Material Vibratory Feeders',
              tradeId: '41 21 39.13',
            },
          ],
          tradeId: '41 21 00',
        },
        {
          id: 'a770b1e8-4e3a-4364-9862-626220e6ccf6',
          name: 'Cranes and Hoists',
          children: [
            {
              id: 'cea52aff-b03b-4891-bd77-5ec9eca367c8',
              name: 'Cranes and Hoists',
              tradeId: '41 22 00',
            },
            {
              id: 'ad14d14c-3df9-4bfa-9c65-b8113ec2286d',
              name: 'Cranes',
              tradeId: '41 22 13',
            },
            {
              id: '294d59ae-1401-4ea2-91f6-18d515eab077',
              name: 'Bridge Cranes',
              tradeId: '41 22 13.13',
            },
            {
              id: 'c1c03ccb-f053-4211-bc49-bac76d0971fb',
              name: 'Gantry Cranes',
              tradeId: '41 22 13.16',
            },
            {
              id: '771c1ef8-74ec-4349-a067-0862d2533d4e',
              name: 'Jib Cranes',
              tradeId: '41 22 13.19',
            },
            {
              id: '7fbd665d-c06f-4b04-899e-7b045905480a',
              name: 'Mobile Cranes',
              tradeId: '41 22 13.23',
            },
            {
              id: '9ed4b537-7513-4989-aa62-9951e3c0ebce',
              name: 'Tower Cranes',
              tradeId: '41 22 13.26',
            },
            {
              id: '9e90addf-5dc0-4883-a766-169526d60904',
              name: 'Specialty Cranes',
              tradeId: '41 22 13.29',
            },
            {
              id: '21cc69d8-bca3-4594-87dd-832c83f5aab1',
              name: 'Hoists',
              tradeId: '41 22 23',
            },
            {
              id: 'c3c9c9c2-1bdb-4c50-80d9-d99cd1ba47f9',
              name: 'Fixed Hoists',
              tradeId: '41 22 23.13',
            },
            {
              id: '7faf1fc6-d652-4eee-9361-200ad0aee621',
              name: 'Portable Hoists',
              tradeId: '41 22 23.16',
            },
            {
              id: '1b61eb0f-52d0-4aed-83d1-e31305bec7c6',
              name: 'Monorail Hoists',
              tradeId: '41 22 23.19',
            },
            {
              id: 'ab90efbd-2c92-4cc3-99d1-b37daf64741f',
              name: 'Specialty Hoists',
              tradeId: '41 22 23.23',
            },
            {
              id: 'c4cedfec-1e77-4047-92b5-a623a4a04cab',
              name: 'Derricks',
              tradeId: '41 22 33',
            },
          ],
          tradeId: '41 22 00',
        },
        {
          id: '1611e2d8-fbf4-41f0-9eb6-9198fd5ce318',
          name: 'Lifting Devices',
          children: [
            {
              id: '5a93a551-fcd8-4769-8b90-87d5710bda68',
              name: 'Lifting Devices',
              tradeId: '41 23 00',
            },
            {
              id: 'de60c8c0-2ace-45f0-bf63-1280a8884d9f',
              name: 'Clamps',
              tradeId: '41 23 13',
            },
            {
              id: '0ce5cd48-8737-4888-832c-72c25f286074',
              name: 'Grabs',
              tradeId: '41 23 16',
            },
            {
              id: 'eeccf925-aaf5-4a04-a5f8-1215499b9b36',
              name: 'Hooks',
              tradeId: '41 23 19',
            },
            {
              id: 'b5198e0e-641d-4ca8-a588-6760536f0017',
              name: 'Lifts',
              tradeId: '41 23 23',
            },
            {
              id: 'a5ccd49a-b1c9-45d9-917c-b4e71a6270ed',
              name: 'Slings',
              tradeId: '41 23 26',
            },
            {
              id: '647127ab-69b3-48b1-af05-a6b28a0e6495',
              name: 'Spreader Bars/Beams',
              tradeId: '41 23 29',
            },
            {
              id: 'a6d7bd8c-20d0-4d3d-8029-a6fb42018db6',
              name: 'Tongs',
              tradeId: '41 23 33',
            },
          ],
          tradeId: '41 23 00',
        },
        {
          id: '285b9278-b571-4fa5-af9c-7350d0aeefc0',
          name: 'Specialty Material Handling Equipment',
          children: [
            {
              id: '258edf02-5342-4fe8-b39e-9d9664949a75',
              name: 'Specialty Material Handling Equipment',
              tradeId: '41 24 00',
            },
            {
              id: '7810c7db-a516-459b-8d43-f50b669370cd',
              name: 'Aeration Devices',
              tradeId: '41 24 13',
            },
            {
              id: 'ae77d3e6-756e-44ee-9f81-e33a4ded01b7',
              name: 'Bin Vibrators',
              tradeId: '41 24 16',
            },
            {
              id: 'bf8dd45d-2bd8-4060-aea8-39f1f8720385',
              name: 'Dehydrators',
              tradeId: '41 24 19',
            },
            {
              id: '7e105f07-6f70-4996-9d8b-70a287f2753f',
              name: 'Hydrators',
              tradeId: '41 24 23',
            },
            {
              id: '956c5920-8014-4c61-9d91-1f800f681d37',
              name: 'Hydraulic Power Systems',
              tradeId: '41 24 26',
            },
            {
              id: '74102ba1-9cc8-47ad-aea4-abec07d31236',
              name: 'Lubrication Systems',
              tradeId: '41 24 29',
            },
            {
              id: '1a35b482-26c8-418b-b603-8958560f1dee',
              name: 'Magnetic Separators',
              tradeId: '41 24 33',
            },
            {
              id: '81dc3f7d-3f21-42c6-84f4-9e8a8a62e8bd',
              name: 'Metal Detectors',
              tradeId: '41 24 36',
            },
            {
              id: 'edcafa3e-923b-4a70-a56a-4ef8c43e51d8',
              name: 'Railcar Movers',
              tradeId: '41 24 39',
            },
            {
              id: '55a5cd45-684d-47e6-bd86-f5234b64344f',
              name: 'Turnheads/Distributors',
              tradeId: '41 24 43',
            },
            {
              id: '6089f526-3922-4334-bc67-6f2451f01ff1',
              name: 'Sorting Machines',
              tradeId: '41 24 46',
            },
            {
              id: 'e472d349-5dfc-43a6-b33e-46e15bbf30ae',
              name: 'Postal Sorting Machines',
              tradeId: '41 24 46.13',
            },
          ],
          tradeId: '41 24 00',
        },
        {
          id: '40054ee7-eed4-485e-8dc6-c2d3080f6a4a',
          name: 'Manufacturing Equipment',
          children: [],
          tradeId: '41 30 00',
        },
        {
          id: '4a09a9b9-9307-4bfa-9062-4e4c856e14ef',
          name: 'Manufacturing Lines and Equipment',
          children: [
            {
              id: '64fff406-195b-43f2-8556-6053850e52ce',
              name: 'Manufacturing Lines and Equipment',
              tradeId: '41 31 00',
            },
            {
              id: '89b5f378-937d-4d77-ae81-72101a165a3e',
              name: 'Manufacturing Lines',
              tradeId: '41 31 13',
            },
            {
              id: '32ac33e7-e188-4173-885b-4c8ea8fa9028',
              name: 'Assembly Lines',
              tradeId: '41 31 13.13',
            },
            {
              id: '0d9f77d7-0848-4ed6-ac31-6cb6462aa134',
              name: 'Casting Lines',
              tradeId: '41 31 13.16',
            },
            {
              id: '7d2cd855-c2b0-4af6-83d6-a79ec1101b63',
              name: 'Coating Lines',
              tradeId: '41 31 13.19',
            },
            {
              id: 'd832ca66-fd47-4fbc-86ec-4d15a17cdaec',
              name: 'Converting Lines',
              tradeId: '41 31 13.23',
            },
            {
              id: '3a5ba9ef-f6f6-4786-b419-7e8c01114f37',
              name: 'Disassembly Lines',
              tradeId: '41 31 13.26',
            },
            {
              id: 'a80120e0-d50d-4223-852d-c65dc79b9151',
              name: 'Extrusion Lines',
              tradeId: '41 31 13.29',
            },
            {
              id: '3035df0d-85b9-4d68-8a04-74c5369eaadb',
              name: 'Machining Lines',
              tradeId: '41 31 13.33',
            },
            {
              id: 'd0a8b7d7-beda-44d5-be7b-7b43cc4120d8',
              name: 'Molding Lines',
              tradeId: '41 31 13.36',
            },
            {
              id: '63344ed8-1568-491b-9a87-6f8fe85f72a4',
              name: 'Finishing/Painting Lines',
              tradeId: '41 31 13.39',
            },
            {
              id: '12b0e358-45ef-4109-a17c-170a338c74a5',
              name: 'Painting Lines',
              tradeId: '41 31 13.43',
            },
            {
              id: 'a6c8d61d-3ba8-4bc3-b979-44bc60b029fc',
              name: 'Pickling Lines',
              tradeId: '41 31 13.46',
            },
            {
              id: 'f57979b7-5e27-4991-860f-a9c6879de4e4',
              name: 'Plating Lines',
              tradeId: '41 31 13.49',
            },
            {
              id: '68c848f5-b8b3-42f8-b56a-9fe7c19d6524',
              name: 'Polishing Lines',
              tradeId: '41 31 13.53',
            },
            {
              id: '794ea3d7-a315-4464-b07c-ce56367bc20d',
              name: 'Press Lines',
              tradeId: '41 31 13.56',
            },
            {
              id: '89635225-0b36-4235-8e85-12f7a8c010de',
              name: 'Rolling/Calendaring Lines',
              tradeId: '41 31 13.59',
            },
            {
              id: '6334111d-9a00-4e74-bd1f-43efb7240ec7',
              name: 'Web Processing Lines',
              tradeId: '41 31 13.63',
            },
            {
              id: '251c3129-332a-41e5-b279-6fe6ff091ec9',
              name: 'Pick and Place Systems',
              tradeId: '41 31 16',
            },
            {
              id: '1ea64f6a-032e-4ee8-9a60-7b14c6761163',
              name: 'Manufacturing-Line Robots',
              tradeId: '41 31 19',
            },
            {
              id: '8bdbbab2-3a51-4f94-9a6f-5fc2bb271781',
              name: 'Specialty Assembly Machines',
              tradeId: '41 31 23',
            },
          ],
          tradeId: '41 31 00',
        },
        {
          id: 'b24416f3-7018-4743-925b-f019cabfbf5a',
          name: 'Forming Equipment',
          children: [
            {
              id: '87e1852c-f788-455e-82a2-2266773fe379',
              name: 'Forming Equipment',
              tradeId: '41 32 00',
            },
            {
              id: '1236a41d-5f86-4505-ac01-577b7fd45a3e',
              name: 'Bending Equipment',
              tradeId: '41 32 13',
            },
            {
              id: '98e642b2-7b84-43cb-9f3e-dd3e414990ac',
              name: 'Blow-Molding Equipment',
              tradeId: '41 32 16',
            },
            {
              id: '764bb7e3-b7a6-407f-9ff8-1cd42d2e974c',
              name: 'Brake-Forming Equipment',
              tradeId: '41 32 19',
            },
            {
              id: 'ae3d03f1-e883-4e3b-bedc-1f2f9c5590ba',
              name: 'Cold-Forming Equipment',
              tradeId: '41 32 23',
            },
            {
              id: '72f36223-45a8-4222-b4f3-b586e984737a',
              name: 'Die-Casting Equipment',
              tradeId: '41 32 26',
            },
            {
              id: '31e20074-2cbd-44af-8392-36d1f2a4e039',
              name: 'Drawing Equipment',
              tradeId: '41 32 29',
            },
            {
              id: '4fe97221-5db8-4570-8e46-e1f0e13fbf94',
              name: 'Electroforming Equipment',
              tradeId: '41 32 33',
            },
            {
              id: 'e6e2bce6-9ee7-4bd7-b6a8-b1db9f1d47a8',
              name: 'Forging Equipment',
              tradeId: '41 32 36',
            },
            {
              id: 'ff0048ff-3669-4319-8055-c5f9e9db65fe',
              name: 'Extruding Equipment',
              tradeId: '41 32 39',
            },
            {
              id: '0928b53d-7a63-49f2-9a9f-e336a19583c1',
              name: 'Metal-Spinning Equipment',
              tradeId: '41 32 43',
            },
            {
              id: '46c73753-9b08-4b2c-b60e-a74078d1c78d',
              name: 'Piercing Equipment',
              tradeId: '41 32 46',
            },
            {
              id: 'b95c05ee-c8aa-492d-bd9c-de5c5f113b9e',
              name: 'Powder Metal-Forming Equipment',
              tradeId: '41 32 49',
            },
            {
              id: 'd37ceeae-f323-4776-badd-c283f85d5868',
              name: 'Pressing Equipment',
              tradeId: '41 32 53',
            },
            {
              id: 'a4e2fd49-fbcf-4e6c-a5c8-2d3565dd8fe8',
              name: 'Roll-Forming Equipment',
              tradeId: '41 32 56',
            },
            {
              id: '636ee5d2-cd7a-4a35-bd4a-5766155cec4b',
              name: 'Shearing Equipment',
              tradeId: '41 32 59',
            },
            {
              id: 'fa9fd7c7-bb8b-4b54-930c-c5ad5437fca9',
              name: 'Spinning Equipment',
              tradeId: '41 32 63',
            },
            {
              id: '6b7bfeee-f9db-4ec9-8b60-fab8333ecfc8',
              name: 'Stretching/Leveling Equipment',
              tradeId: '41 32 66',
            },
            {
              id: '8a235003-cd1f-46f0-9643-2b1df03e5689',
              name: 'Swaging Equipment',
              tradeId: '41 32 69',
            },
          ],
          tradeId: '41 32 00',
        },
        {
          id: '8d65630b-538f-4e63-87e3-887d48ffcc70',
          name: 'Machining Equipment',
          children: [
            {
              id: 'd9d5ed0a-5c62-4b11-9dfc-b949d299ed81',
              name: 'Machining Equipment',
              tradeId: '41 33 00',
            },
            {
              id: '6720df6c-1fed-48d2-a0be-76e2179bc6d0',
              name: 'Automatic Screw Machining Equipment',
              tradeId: '41 33 13',
            },
            {
              id: 'dd43d306-30a3-4dc6-8dfc-b65d7f4ae5af',
              name: 'Boring Equipment',
              tradeId: '41 33 16',
            },
            {
              id: '4cad4b5a-1bcd-4807-8a3e-a46201373c98',
              name: 'Broaching Equipment',
              tradeId: '41 33 19',
            },
            {
              id: 'a19d012a-e0e2-4eeb-9cb5-7e55cf24d37d',
              name: 'Drilling Equipment',
              tradeId: '41 33 23',
            },
            {
              id: '6f64eb84-f4b4-4687-a8b9-7b14eebdacde',
              name: 'Electro-Discharge Machining Equipment',
              tradeId: '41 33 26',
            },
            {
              id: '499ae63c-c56a-4867-85cf-528c7c95d9bf',
              name: 'Grinding Equipment',
              tradeId: '41 33 29',
            },
            {
              id: '0e27dd23-1e94-4384-a38c-b32e4682886d',
              name: 'Hobbing Equipment',
              tradeId: '41 33 33',
            },
            {
              id: '0cdc6012-18c6-4775-b51b-fcbb195f29cf',
              name: 'Lapping Equipment',
              tradeId: '41 33 36',
            },
            {
              id: '2e7f88d8-619c-4ddf-b539-bc47772932b1',
              name: 'Lathe Equipment',
              tradeId: '41 33 39',
            },
            {
              id: 'f763d3f9-2a16-48a0-9362-ec0e6c5dad16',
              name: 'Leveling Equipment',
              tradeId: '41 33 43',
            },
            {
              id: 'f9becd2d-3b42-4d32-a902-f265120841dd',
              name: 'Machining Center Equipment',
              tradeId: '41 33 46',
            },
            {
              id: '03632d9f-6a6e-4eab-88c5-c5bef867d68a',
              name: 'Milling Equipment',
              tradeId: '41 33 53',
            },
            {
              id: '2ec886ce-7d8b-4438-8018-42246cbe8784',
              name: 'Horizontal Milling Equipment',
              tradeId: '41 33 53.13',
            },
            {
              id: '681959ee-61c1-4cf3-adf7-ad962d45c445',
              name: 'Vertical Milling Equipment',
              tradeId: '41 33 53.16',
            },
            {
              id: '56cf4603-554b-41e8-ad05-2b5924b2d9b9',
              name: 'Multi-Axis Machine Equipment',
              tradeId: '41 33 60',
            },
            {
              id: 'e3dc6e4c-43dc-44ec-a124-aa653c3a2c14',
              name: 'Planing Equipment',
              tradeId: '41 33 63',
            },
            {
              id: 'aaedf259-e240-4fd1-b944-115794f5ba8c',
              name: 'Reaming Equipment',
              tradeId: '41 33 66',
            },
            {
              id: '9746d022-0256-4ca9-a3d2-4b7aa297cb2d',
              name: 'Routing Equipment',
              tradeId: '41 33 69',
            },
            {
              id: 'c201f5b1-55ed-4d38-837a-ecec973367f8',
              name: 'Sawing Equipment',
              tradeId: '41 33 73',
            },
            {
              id: '14a6f4b9-d862-446c-a4ec-2bb0f9091383',
              name: 'Shaping Equipment',
              tradeId: '41 33 76',
            },
            {
              id: '9d3aaa25-0099-4e62-99e7-9b32057eacd8',
              name: 'Threading Equipment',
              tradeId: '41 33 79',
            },
          ],
          tradeId: '41 33 00',
        },
        {
          id: '17e3c057-3c4b-4908-868c-e0fee19e25e1',
          name: 'Finishing Equipment',
          children: [
            {
              id: 'c3101638-ad91-4df5-a4ae-9a147d1131e5',
              name: 'Finishing Equipment',
              tradeId: '41 34 00',
            },
            {
              id: 'd29ba60a-f2ba-4bc8-871f-a75f49f54d0c',
              name: 'Anodizing Equipment',
              tradeId: '41 34 13',
            },
            {
              id: 'c8792226-d80c-463b-8389-d597363badc7',
              name: 'Barrel Tumbling Equipment',
              tradeId: '41 34 16',
            },
            {
              id: '55d6d699-460f-4792-8018-722fbd3c49e2',
              name: 'Coating Equipment',
              tradeId: '41 34 23',
            },
            {
              id: 'd8719f0c-63e9-4cfc-baff-91d1ac1a1980',
              name: 'Diffusion Coating Equipment',
              tradeId: '41 34 23.13',
            },
            {
              id: '7dcab3bc-a5d0-43da-b8c3-05cda49bcc7f',
              name: 'Dipping Coating Equipment',
              tradeId: '41 34 23.16',
            },
            {
              id: '5e116783-6533-46ca-96d0-a23ddbe4a262',
              name: 'Film Coating Equipment',
              tradeId: '41 34 23.19',
            },
            {
              id: '12e35d82-01ef-47c3-94f2-9d136a871e05',
              name: 'Phosphatizing Coating Equipment',
              tradeId: '41 34 23.23',
            },
            {
              id: 'a8e2942f-dd2c-4c84-9369-227c89a1c56d',
              name: 'Plasma Coating Equipment',
              tradeId: '41 34 23.26',
            },
            {
              id: '9feddc88-d823-4469-b4e7-47d4e93ad6bb',
              name: 'Hardface Welding Coating Equipment',
              tradeId: '41 34 23.29',
            },
            {
              id: '896e6a74-39d5-4658-ad7e-04e9de14463b',
              name: 'Spray Painting Booth',
              tradeId: '41 34 23.33',
            },
            {
              id: 'eec952de-fa9b-4fc1-83d0-7c35476ebfd3',
              name: 'Deburring Equipment',
              tradeId: '41 34 26',
            },
            {
              id: '375e7893-322e-44f9-acb2-6636d3d7d2e5',
              name: 'Electroplating Equipment',
              tradeId: '41 34 36',
            },
            {
              id: '712899b4-7cf8-4c16-9971-36f35c6ff7c0',
              name: 'Grinding Equipment',
              tradeId: '41 34 46',
            },
            {
              id: '9ea0e5e4-c949-427a-b70f-24e21bc203a9',
              name: 'Honing Equipment',
              tradeId: '41 34 49',
            },
            {
              id: '35e778b1-4d8f-466f-ab88-f6f4e17bf8d1',
              name: 'Lapping Equipment',
              tradeId: '41 34 53',
            },
            {
              id: '3fcee46d-b6ba-416f-9a1f-14164976a952',
              name: 'Shot Peening Equipment',
              tradeId: '41 34 56',
            },
            {
              id: 'e1deaa63-619e-4ebc-aae7-e0ceee04ea52',
              name: 'Superfinishing/Polishing Equipment',
              tradeId: '41 34 59',
            },
          ],
          tradeId: '41 34 00',
        },
        {
          id: 'f5f656aa-d38c-4173-92b3-891d3cc7f3e4',
          name: 'Dies and Molds',
          children: [
            {
              id: '0b87ffb3-80d8-473a-afd1-f854887bbde5',
              name: 'Dies and Molds',
              tradeId: '41 35 00',
            },
            {
              id: '0a4d986d-6db0-49b2-97ec-1c2e8d847e5f',
              name: 'Dies',
              tradeId: '41 35 13',
            },
            {
              id: '8fa7ee82-3bdd-4d27-bc90-96b613287edf',
              name: 'Drawing Dies',
              tradeId: '41 35 13.13',
            },
            {
              id: '77520e18-236a-476d-b6a0-b0121b71f549',
              name: 'Extrusion Dies',
              tradeId: '41 35 13.16',
            },
            {
              id: '914795d0-bca8-4c3b-97c8-a50b1d63c51e',
              name: 'Press Dies',
              tradeId: '41 35 13.19',
            },
            {
              id: '51464a5b-4ec1-4ca8-8291-2d5f1eacf48c',
              name: 'Rotary Dies',
              tradeId: '41 35 13.23',
            },
            {
              id: 'b59b8f05-e407-4930-a797-ca1def0cecaf',
              name: 'Rule Dies',
              tradeId: '41 35 13.26',
            },
            {
              id: '30399036-3f14-4604-a7be-1bfcd7e364e2',
              name: 'Molds',
              tradeId: '41 35 33',
            },
          ],
          tradeId: '41 35 00',
        },
        {
          id: 'a6d8526e-16cb-43c4-9df6-a17dc00b1ce6',
          name: 'Assembly and Testing Equipment',
          children: [
            {
              id: '5c548d71-cdd0-48e2-b8aa-1af7135f9fde',
              name: 'Assembly and Testing Equipment',
              tradeId: '41 36 00',
            },
            {
              id: '96d1b234-3e41-4099-82a4-594b3d9d3bb5',
              name: 'Applicators',
              tradeId: '41 36 13',
            },
            {
              id: 'd3d45462-23fa-4e10-a471-a8cc59906a21',
              name: 'Adhesive Applicators',
              tradeId: '41 36 13.13',
            },
            {
              id: 'b58287f6-5ba5-4181-9eae-5e1287f45da2',
              name: 'Lubricant Applicators',
              tradeId: '41 36 13.16',
            },
            {
              id: '9e11c260-9172-4823-b8b2-6333b18c0567',
              name: 'Sealer Applicators',
              tradeId: '41 36 13.19',
            },
            {
              id: 'fcbb68cc-d0cf-4957-817d-714928504613',
              name: 'Fixtures and Jigs',
              tradeId: '41 36 16',
            },
            {
              id: '0cf488ec-e7c2-441e-ae38-c67e25fd519c',
              name: 'Joining Equipment',
              tradeId: '41 36 19',
            },
            {
              id: '6225408d-7e96-4cde-8b4b-add2563f68cb',
              name: 'Adhesive Joining Equipment',
              tradeId: '41 36 19.13',
            },
            {
              id: 'c61578d3-3796-4b4f-9c80-ba78f111bb32',
              name: 'Arc-Welding Equipment',
              tradeId: '41 36 19.16',
            },
            {
              id: '83a5e122-a591-4655-a4e7-3ba11310b9c7',
              name: 'Brazing Equipment',
              tradeId: '41 36 19.19',
            },
            {
              id: '2ba19c23-85af-4a7e-a3cc-61ecd88a1296',
              name: 'Resistance-Welding Equipment',
              tradeId: '41 36 19.23',
            },
            {
              id: 'bf7da242-7ab8-408f-82d3-5b48d24b7591',
              name: 'Riveting Equipment',
              tradeId: '41 36 19.26',
            },
            {
              id: 'a1218a4a-78e3-4c95-91d5-a252cc7e8125',
              name: 'Sintering Equipment',
              tradeId: '41 36 19.29',
            },
            {
              id: 'd78ddc28-c4fe-4a9e-b976-513c9b854e98',
              name: 'Soldering Equipment',
              tradeId: '41 36 19.33',
            },
            {
              id: '13321f41-bb59-43c9-adca-369d7aab53bc',
              name: 'Cutting Equipment',
              tradeId: '41 36 23',
            },
            {
              id: '1241f69e-972d-48b5-b36f-bb6e56e1e8e9',
              name: 'Cutting Torches',
              tradeId: '41 36 23.13',
            },
            {
              id: '4898d71b-8379-40ae-8dd8-be906a5d66eb',
              name: 'High-Pressure Water Cutting Equipment',
              tradeId: '41 36 23.16',
            },
            {
              id: '25c43450-0e8d-46ee-a75c-369ceb86b29f',
              name: 'Laser Cutting Equipment',
              tradeId: '41 36 23.19',
            },
            {
              id: '18c3405d-ac14-4334-9153-3ad0d08d7e49',
              name: 'Plasma Cutting Equipment',
              tradeId: '41 36 23.23',
            },
            {
              id: '73c6afb2-2944-4335-98fc-0e242e44e00e',
              name: 'Process Tools',
              tradeId: '41 36 26',
            },
            {
              id: '205b5412-acda-4688-b5cc-e2cbec32f513',
              name: 'Air Process Tools',
              tradeId: '41 36 26.13',
            },
            {
              id: '08820872-d880-4770-a9a0-066636607a9b',
              name: 'Electric Process Tools',
              tradeId: '41 36 26.16',
            },
            {
              id: '311feeab-5a85-4ba9-9ccb-9bef0d6bad7f',
              name: 'Hydraulic Process Tools',
              tradeId: '41 36 26.19',
            },
            {
              id: 'f5fa2102-f8ae-4d5c-85c0-d2c6e3cf0958',
              name: 'Manual Process Tools',
              tradeId: '41 36 26.23',
            },
            {
              id: '2f6a1afe-a7f1-48c6-a66f-9a803b6105e1',
              name: 'Manufacturing Measurement and Testing Equipment',
              tradeId: '41 36 29',
            },
            {
              id: '5424fa2c-2e6c-4b37-a2ab-ee8f41e68328',
              name: 'Gages, Rules and Blocks',
              tradeId: '41 36 29.13',
            },
            {
              id: '586257d3-b976-428a-aafc-bfb9ef8c7c98',
              name: 'Penetrant Measurement and Testing Equipment',
              tradeId: '41 36 29.16',
            },
            {
              id: '2ae080f6-9e02-429e-89b7-a0e0ccbb49c8',
              name: 'Laser Measurement and Testing Equipment',
              tradeId: '41 36 29.19',
            },
            {
              id: '37b31a81-8f61-483d-b616-5a59d212a21d',
              name: 'Magnaflux Measurement and Testing Equipment',
              tradeId: '41 36 29.23',
            },
            {
              id: '58ac861d-9f6a-4a24-b67e-d22b6d2c43b8',
              name: 'Optical Comparators',
              tradeId: '41 36 29.26',
            },
            {
              id: '142e6b76-4a4a-4333-8421-f24fa9a734c6',
              name: 'Profilometers',
              tradeId: '41 36 29.29',
            },
            {
              id: '7f5217a5-4803-4b2f-8e39-944a17734ab9',
              name: 'Radiograph Measurement and Testing Equipment',
              tradeId: '41 36 29.33',
            },
            {
              id: '7818ca7a-e8a9-47e0-8eeb-2aaa2ba0f5a6',
              name: 'Surface Tables',
              tradeId: '41 36 29.36',
            },
            {
              id: '84175a9d-3c9e-4f23-a11e-091ab36e5a15',
              name: 'Ultrasonic Measurement and Testing Equipment',
              tradeId: '41 36 29.39',
            },
            {
              id: '62c2c6ba-d977-402c-9e9d-f9ff1211695f',
              name: 'Test Weigh Scales',
              tradeId: '41 36 29.43',
            },
          ],
          tradeId: '41 36 00',
        },
        {
          id: '6ab390d3-eeda-443c-b4cb-7735416f9ba3',
          name: 'Container Processing and Packaging',
          children: [],
          tradeId: '41 40 00',
        },
        {
          id: '04102a2b-b9fe-4cbe-b9ea-105846cd4492',
          name: 'Container Filling and Sealing',
          children: [
            {
              id: 'cf72ea23-9cb7-4ffe-b2de-9e342a536441',
              name: 'Container Filling and Sealing',
              tradeId: '41 41 00',
            },
            {
              id: '2063a0e5-dda0-4438-b2c8-e47015908082',
              name: 'Bulk Container Fillers/Packers',
              tradeId: '41 41 13',
            },
            {
              id: '69dd673b-549e-45ba-8201-e09c7ae63c61',
              name: 'Container Cappers',
              tradeId: '41 41 16',
            },
            {
              id: '9d890261-2947-4322-91fb-3c8af209cc37',
              name: 'Container Fillers',
              tradeId: '41 41 19',
            },
            {
              id: '6db290d7-3fc1-4d2b-834b-e2b24783e820',
              name: 'Bag Fillers',
              tradeId: '41 41 19.13',
            },
            {
              id: 'bd9c42d1-685c-4732-87e2-47e35dd07886',
              name: 'Box Fillers',
              tradeId: '41 41 19.16',
            },
            {
              id: '71fc47d7-29d7-4e8f-9d55-269ad3d0f870',
              name: 'Bottle Fillers',
              tradeId: '41 41 19.19',
            },
            {
              id: 'bcb74a9c-7585-41ff-8b32-af8230bc21dd',
              name: 'Container Sealers',
              tradeId: '41 41 23',
            },
          ],
          tradeId: '41 41 00',
        },
        {
          id: '9af45602-6f84-4c2e-8e84-465495a95384',
          name: 'Container Packing Equipment',
          children: [
            {
              id: '3486398e-9692-4ac9-9d35-c8311444c345',
              name: 'Container Packing Equipment',
              tradeId: '41 42 00',
            },
            {
              id: '229c62ab-9f96-43ec-8a8c-edf856719ffb',
              name: 'Box Packing Equipment',
              tradeId: '41 42 13',
            },
            {
              id: '918d02a7-709d-422a-a2b5-18f9931d602f',
              name: 'Box Makers',
              tradeId: '41 42 13.13',
            },
            {
              id: 'ef677e55-f899-4331-bfad-105d2a8ed13a',
              name: 'Box Packers',
              tradeId: '41 42 13.16',
            },
            {
              id: '7f4b9f7c-14bc-4ecc-a926-8a583df514d8',
              name: 'Bulk Material Loaders',
              tradeId: '41 42 16',
            },
            {
              id: 'bc3ba4e8-976c-47d0-8a71-770438ab9c83',
              name: 'Container Bulk Material Loaders',
              tradeId: '41 42 16.13',
            },
            {
              id: '1c7260d8-a311-4fef-b768-e0cab22d116e',
              name: 'Truck Bulk Material Loaders',
              tradeId: '41 42 16.23',
            },
            {
              id: '6c0ce45a-4833-49f5-9170-a814289025ca',
              name: 'Railcar Bulk Material Loaders',
              tradeId: '41 42 16.26',
            },
            {
              id: '8db2892c-c5bc-4e98-a096-fd989533dab3',
              name: 'Ship Bulk Material Loaders',
              tradeId: '41 42 16.29',
            },
            {
              id: '3d38b27a-19c5-4026-9e79-4b2082791cbe',
              name: 'Barge Bulk Material Loaders',
              tradeId: '41 42 16.33',
            },
            {
              id: 'e9ddc5d7-bf1f-42c5-9ce8-a00efc02d04f',
              name: 'Carton Packers',
              tradeId: '41 42 19',
            },
            {
              id: 'a723a82a-388c-44ab-ba82-fc672d0f5f51',
              name: 'Carton Sealers',
              tradeId: '41 42 23',
            },
            {
              id: 'cfa1eca0-a773-4383-84ec-498499abd321',
              name: 'Carton Shrink Wrappers',
              tradeId: '41 42 26',
            },
            {
              id: '6f6c6b11-dd6b-4e3d-b81a-0d6e1228d4d2',
              name: 'Carton Stackers',
              tradeId: '41 42 29',
            },
          ],
          tradeId: '41 42 00',
        },
        {
          id: '24424d4e-1ade-4a39-8a90-296d0becbab5',
          name: 'Shipping Packaging',
          children: [
            {
              id: 'd8b73b99-382a-4ae1-8ea1-874e6df0835d',
              name: 'Shipping Packaging',
              tradeId: '41 43 00',
            },
            {
              id: 'a5ad8eee-5431-4515-a383-25dbf582c5d7',
              name: 'Banding/Strapping Equipment',
              tradeId: '41 43 13',
            },
            {
              id: 'fc5582aa-bcf1-47df-83ff-b90c4d9f416b',
              name: 'Barcode Equipment',
              tradeId: '41 43 16',
            },
            {
              id: 'c64bf9b7-ab71-4507-b063-e0992f8194f6',
              name: 'Barcode Readers',
              tradeId: '41 43 16.13',
            },
            {
              id: '8d01477b-1b99-4c75-bf22-6a21667d258a',
              name: 'Barcode Printers',
              tradeId: '41 43 16.16',
            },
            {
              id: '696a1e27-c80e-4dcc-9841-242842d434b9',
              name: 'Labeling Equipment',
              tradeId: '41 43 19',
            },
            {
              id: 'be82880f-5054-4c43-8565-593d65f4d061',
              name: 'Pallet Stacking/Wrapping Equipment',
              tradeId: '41 43 23',
            },
          ],
          tradeId: '41 43 00',
        },
        {
          id: 'fa4d0828-fd0e-43af-af4b-fb9078b83ef0',
          name: 'Material Storage',
          children: [],
          tradeId: '41 50 00',
        },
        {
          id: '69e2ca5f-84d8-45fc-888c-89f501533100',
          name: 'Automatic Material Storage',
          children: [
            {
              id: '84443e15-989e-4969-9ec2-6c1695c9982b',
              name: 'Automatic Material Storage',
              tradeId: '41 51 00',
            },
            {
              id: 'b67f57cb-857c-4efe-b9dc-477e9b2e62a6',
              name: 'Automatic Storage/Automatic Retrieval Systems',
              tradeId: '41 51 13',
            },
          ],
          tradeId: '41 51 00',
        },
        {
          id: '6694c8d5-3279-47a4-852d-0f4b789a3e05',
          name: 'Bulk Material Storage',
          children: [
            {
              id: '5e755ffa-36d6-4d4a-9240-0172f31e082a',
              name: 'Bulk Material Storage',
              tradeId: '41 52 00',
            },
            {
              id: '0ee98451-4b3f-451f-b610-8e7643f5f1b0',
              name: 'Bins and Hoppers',
              tradeId: '41 52 13',
            },
            {
              id: '962d5a64-f25c-49bb-bade-2c57758f4db4',
              name: 'Fixed Bins and Hoppers',
              tradeId: '41 52 13.13',
            },
            {
              id: 'b4610b24-14a4-49ff-9cc3-90da7de88a21',
              name: 'Portable Bins and Hoppers',
              tradeId: '41 52 13.23',
            },
            {
              id: 'cd64795b-1317-47ef-a9d4-9c665bcc5a43',
              name: 'Bulk Material Containers',
              tradeId: '41 52 13.33',
            },
            {
              id: '8b1788e9-757e-4d50-9de4-209a71f6de4a',
              name: 'Returnable Bins and Hoppers',
              tradeId: '41 52 13.43',
            },
            {
              id: '8d550b99-55bc-465e-a804-e51b2602831d',
              name: 'Throwaway Bins and Hoppers',
              tradeId: '41 52 13.53',
            },
            {
              id: 'ecfa672a-8590-4e60-8dc5-0362b14ccbf9',
              name: 'Silos',
              tradeId: '41 52 16',
            },
            {
              id: 'df831b0a-f295-4141-a9aa-9b840fb4a8e8',
              name: 'Concrete Silos',
              tradeId: '41 52 16.13',
            },
            {
              id: '4abec7f3-ce9b-4036-8362-ac00d7788b24',
              name: 'Concrete Masonry Unit Silos',
              tradeId: '41 52 16.16',
            },
            {
              id: 'f4f4e594-ae15-4e3a-887e-15850450eba2',
              name: 'Steel Silos',
              tradeId: '41 52 16.19',
            },
            {
              id: 'a3ee800a-30b5-4102-82d8-c0f5f10918ac',
              name: 'Material Storage Tanks',
              tradeId: '41 52 19',
            },
            {
              id: '099ddb3f-7280-4cd8-ac73-d7ccf2609d5e',
              name: 'Horizontal Material Storage Tanks',
              tradeId: '41 52 19.13',
            },
            {
              id: '1e8b8ca0-d26e-4784-a4fa-88885c230f11',
              name: 'Vertical Material Storage Tanks',
              tradeId: '41 52 19.23',
            },
            {
              id: '467f189d-ca33-4555-a872-d4f83c300a51',
              name: 'Portable Material Storage Tanks',
              tradeId: '41 52 19.33',
            },
          ],
          tradeId: '41 52 00',
        },
        {
          id: '7480fad1-97cf-4b33-b6fe-216a385bee4b',
          name: 'Storage Equipment and Systems',
          children: [
            {
              id: 'f458cd5c-1492-45a4-9ebf-98e0d49a8bdb',
              name: 'Storage Equipment and Systems',
              tradeId: '41 53 00',
            },
            {
              id: 'c837d039-dfba-4d4a-af95-738613250dcb',
              name: 'Storage Cabinets',
              tradeId: '41 53 13',
            },
            {
              id: 'ea7c2274-a2ea-4c3e-9d98-6cc7baf6ed8e',
              name: 'Container Storage Systems',
              tradeId: '41 53 16',
            },
            {
              id: 'e7f38f79-f80f-4344-9020-d361f57ae92f',
              name: 'Flat Files',
              tradeId: '41 53 19',
            },
            {
              id: '59019f89-7910-4742-9524-f408c3836470',
              name: 'Storage Racks',
              tradeId: '41 53 23',
            },
            {
              id: 'd7415bb8-5bbe-494f-8e3c-a5aeedb54ea5',
              name: 'Mobile Storage Racks',
              tradeId: '41 53 23.13',
            },
            {
              id: '087cb2e5-a1ce-485e-b9a8-6703fc79652f',
              name: 'Mezzanine Storage Systems',
              tradeId: '41 53 26',
            },
          ],
          tradeId: '41 53 00',
        },
        {
          id: 'ca07fb8d-d3b7-4d8d-a039-5b108f046d12',
          name: 'Mobile Plant Equipment',
          children: [],
          tradeId: '41 60 00',
        },
        {
          id: 'b46a71b0-d4c4-4726-ac0b-7b82a4b1030a',
          name: 'Mobile Earth Moving Equipment',
          children: [
            {
              id: '09f77680-361a-4211-b17d-6174d2134c7f',
              name: 'Mobile Earth Moving Equipment',
              tradeId: '41 61 00',
            },
            {
              id: '44db6f01-753a-48f1-9f8e-c2acfcc31729',
              name: 'Backhoes',
              tradeId: '41 61 13',
            },
            {
              id: '6d9bc2fe-b694-44c7-be33-e263f8c970d5',
              name: 'Bulldozers',
              tradeId: '41 61 16',
            },
            {
              id: 'd19c1df9-9f5f-4429-adcd-6ee85345341a',
              name: 'Compactors',
              tradeId: '41 61 19',
            },
            {
              id: 'dd45dff7-cf37-4acf-a02d-68eb9c1054ea',
              name: 'Excavators',
              tradeId: '41 61 23',
            },
            {
              id: 'd6598d96-3730-4d04-b338-6d01690b815d',
              name: 'Graders',
              tradeId: '41 61 26',
            },
            {
              id: '25094881-03e4-4338-a1e1-cbe542c7193c',
              name: 'Payloaders',
              tradeId: '41 61 29',
            },
            {
              id: 'b5c6f159-5ab1-4488-b97c-221fe7092e65',
              name: 'Trenchers',
              tradeId: '41 61 33',
            },
          ],
          tradeId: '41 61 00',
        },
        {
          id: 'd3525071-419b-4ea4-9e97-3343e80cc8a3',
          name: 'Trucks',
          children: [
            {
              id: '051f0a7e-7ac1-4053-bd9a-148e7300e929',
              name: 'Trucks',
              tradeId: '41 62 00',
            },
            {
              id: '2eb1d636-cba5-40fc-aeac-7a63c2ab1c1f',
              name: 'Cement Mixer Trucks',
              tradeId: '41 62 13',
            },
            {
              id: 'e96e6947-f588-44fa-b483-45a67d8950e2',
              name: 'Dump Trucks',
              tradeId: '41 62 16',
            },
            {
              id: '6cf63421-b212-4a23-b8b0-1e2bf54c4267',
              name: 'Flatbed Trucks',
              tradeId: '41 62 19',
            },
            {
              id: 'b4dde3a9-b6f1-451d-8a20-9716b78bac77',
              name: 'Forklift Trucks',
              tradeId: '41 62 23',
            },
            {
              id: 'c53f1378-c79a-433d-82a7-ecea45692b40',
              name: 'Pickup Trucks',
              tradeId: '41 62 26',
            },
            {
              id: '9a245382-5d51-4669-902a-4bcf55402d35',
              name: 'Tank Trucks',
              tradeId: '41 62 29',
            },
          ],
          tradeId: '41 62 00',
        },
        {
          id: 'c0fd8564-94f4-41ed-be89-5a88aa5fd45e',
          name: 'General Vehicles',
          children: [
            {
              id: '204afb2e-e061-4149-a223-b70682e7acb5',
              name: 'General Vehicles',
              tradeId: '41 63 00',
            },
            {
              id: 'd8d7b476-5fe1-4056-8dcf-06df97f8b188',
              name: 'Bicycles',
              tradeId: '41 63 13',
            },
            {
              id: '030c481c-4786-496f-949a-f289f7ca6ee7',
              name: 'Carts',
              tradeId: '41 63 16',
            },
            {
              id: '6e7f65dd-5b59-4fe9-aeca-b570152f3ff3',
              name: 'Maintenance Vehicles',
              tradeId: '41 63 19',
            },
            {
              id: '5a412dd1-8f3e-4dc2-8228-395fae5b9b94',
              name: 'Utility Vehicles',
              tradeId: '41 63 23',
            },
            {
              id: '0f982cd2-6dd7-49bc-8aa5-3374ab78bbcf',
              name: 'Vans',
              tradeId: '41 63 26',
            },
            {
              id: 'd7582226-6664-4261-ab1b-80297b402c15',
              name: 'Wagons',
              tradeId: '41 63 29',
            },
          ],
          tradeId: '41 63 00',
        },
        {
          id: '167cfda7-61b5-4fe3-9d46-1585c55f93bf',
          name: 'Rail Vehicles',
          children: [
            {
              id: '39f58efe-fc0b-45fa-ac1d-3df7f889d015',
              name: 'Rail Vehicles',
              tradeId: '41 64 00',
            },
            {
              id: '5be220fb-c77c-4b67-9023-f6f4397b7e1a',
              name: 'Locomotives',
              tradeId: '41 64 13',
            },
            {
              id: 'cce6e3e6-f696-40fe-860c-11f88b0f42a8',
              name: 'Diesel Locomotives',
              tradeId: '41 64 13.13',
            },
            {
              id: '8894427a-cb90-45c5-a093-aa340efef36d',
              name: 'Electric Locomotives',
              tradeId: '41 64 13.23',
            },
            {
              id: '2f2ee75e-6741-405a-9343-aafe77608c80',
              name: 'Mobile Railcar Movers',
              tradeId: '41 64 16',
            },
          ],
          tradeId: '41 64 00',
        },
        {
          id: '4a34ca7e-5689-4f91-86f8-32f034bb08c9',
          name: 'Mobile Support Equipment',
          children: [
            {
              id: '952d2bab-c07b-40b4-b128-933d747fb384',
              name: 'Mobile Support Equipment',
              tradeId: '41 65 00',
            },
            {
              id: '8600b06d-35a9-4f93-9f4e-6082f08ede34',
              name: 'Mobile Air Compressors',
              tradeId: '41 65 13',
            },
            {
              id: 'abf9f866-9a5e-4ee3-8d52-9d3802e805bc',
              name: 'Mobile Generators',
              tradeId: '41 65 16',
            },
            {
              id: '29234961-10cc-43d7-ac23-0f9d6d08d985',
              name: 'Mobile Welders',
              tradeId: '41 65 19',
            },
          ],
          tradeId: '41 65 00',
        },
        {
          id: 'ca49e115-a925-4749-983f-0c4c0dceceea',
          name: 'Miscellaneous Mobile Equipment',
          children: [
            {
              id: '1f2d37ef-a6e5-4f4e-a691-ff7390ba8b04',
              name: 'Miscellaneous Mobile Equipment',
              tradeId: '41 66 00',
            },
            {
              id: '46e6ce4d-b676-4453-8cb5-d0587fc28c6e',
              name: 'Mobile Boring and Drilling Rigs',
              tradeId: '41 66 13',
            },
            {
              id: '33449286-92cd-4acb-9ff0-d5556e00e072',
              name: 'Mobile Lifts and Cherrypickers',
              tradeId: '41 66 16',
            },
            {
              id: '4e6e5c8e-4573-4b57-bad2-a8b2994ef21a',
              name: 'Mobile Paving Equipment',
              tradeId: '41 66 19',
            },
            {
              id: '748c672a-8138-40bb-a974-53cc19ed09ce',
              name: 'Mobile Sweepers/Vacuums',
              tradeId: '41 66 23',
            },
          ],
          tradeId: '41 66 00',
        },
        {
          id: 'd1d44fec-2291-41ce-bf4d-046073b6aa9a',
          name: 'Plant Maintenance Equipment',
          children: [
            {
              id: 'c60e7499-7680-472b-b5ce-64bd292d59d0',
              name: 'Plant Maintenance Equipment',
              tradeId: '41 67 00',
            },
            {
              id: '94e51886-5244-429b-a205-8e6746d69636',
              name: 'Plant Lube Oil System',
              tradeId: '41 67 13',
            },
            {
              id: '840e7e27-c615-412b-8b60-42800a62f377',
              name: 'Plant Fall Protection Equipment',
              tradeId: '41 67 16',
            },
            {
              id: 'd8f97c7d-3f8a-4437-b368-a5198c4820b7',
              name: 'Plant Safety Equipment',
              tradeId: '41 67 19',
            },
            {
              id: '107f8179-85c2-40b8-a392-953ca0d31f4f',
              name: 'Plant Maintenance Tools',
              tradeId: '41 67 23',
            },
            {
              id: 'df5a9f58-cb5b-442b-a2fb-956ad4e2137d',
              name: 'Plant Maintenance Washing Equipment',
              tradeId: '41 67 26',
            },
          ],
          tradeId: '41 67 00',
        },
      ],
      tradeId: '41 00 00',
    },
    {
      id: 'f2f8e69a-6bdb-4b65-b7aa-3eb818d0407b',
      name: 'Process Heating, Cooling and Drying Equipment',
      children: [
        {
          id: '8245eaad-bda1-4c89-a93b-478ba6816a30',
          name: 'Process Heating, Cooling and Drying Equipment',
          children: [],
          tradeId: '42 00 00',
        },
        {
          id: 'c610583e-9591-4bac-b56f-e34af5991f97',
          name: 'Operation and Maintenance of Process Heating, Cooling and Drying Equipment',
          children: [
            {
              id: 'b9ce6d20-60be-46e5-81bf-d15220751574',
              name: 'Operation and Maintenance of Process Heating, Cooling and Drying Equipment',
              tradeId: '42 01 00',
            },
            {
              id: '90c5702a-fa64-4527-b3b9-8a890a43e1b2',
              name: 'Operation and Maintenance of Process Heating Equipment',
              tradeId: '42 01 10',
            },
            {
              id: '43248a93-7335-4998-9202-05b37d8a33ca',
              name: 'Operation and Maintenance of Process Cooling Equipment',
              tradeId: '42 01 20',
            },
            {
              id: 'f6936824-7386-421e-b60c-1f7fbf8bbdbe',
              name: 'Operation and Maintenance of Process Drying Equipment',
              tradeId: '42 01 30',
            },
          ],
          tradeId: '42 01 00',
        },
        {
          id: 'e6afcd3f-384c-4a5c-bbe4-41536d8f94a1',
          name: 'Schedules for Process Heating, Cooling and Drying Equipment',
          children: [
            {
              id: 'c3747661-5d6f-4dc7-a004-215c782d6575',
              name: 'Schedules for Process Heating, Cooling and Drying Equipment',
              tradeId: '42 06 00',
            },
            {
              id: 'ee27e2c1-eeed-4a6b-8a31-c67feb2b04fe',
              name: 'Schedules for Process Heating Equipment',
              tradeId: '42 06 10',
            },
            {
              id: '709f1b75-2641-4daf-af3f-7d8e238569b6',
              name: 'Schedules for Process Cooling Equipment',
              tradeId: '42 06 20',
            },
            {
              id: 'aa835bfb-14eb-45fe-a804-3ab84320410f',
              name: 'Schedules for Process Drying Equipment',
              tradeId: '42 06 30',
            },
          ],
          tradeId: '42 06 00',
        },
        {
          id: '747e355f-fabb-4511-854e-37483c43fb2b',
          name: 'Commissioning of Process Heating, Cooling and Drying Equipment',
          children: [
            {
              id: 'f0c0a586-42eb-42ec-9a76-4eae341e67f7',
              name: 'Commissioning of Process Heating, Cooling and Drying Equipment',
              tradeId: '42 08 00',
            },
            {
              id: '1fd5eae2-e2bd-4ad9-8a43-db229e265012',
              name: 'Commissioning of Heating Equipment',
              tradeId: '42 08 10',
            },
            {
              id: '281d372b-4ba3-4c1a-ad5f-07e38d461a95',
              name: 'Commissioning of Cooling Equipment',
              tradeId: '42 08 20',
            },
            {
              id: '530853bc-3894-4d6f-9a08-e6555b1da7cf',
              name: 'Commissioning of Drying Equipment',
              tradeId: '42 08 30',
            },
          ],
          tradeId: '42 08 00',
        },
        {
          id: '828a0688-bb91-498b-96ff-c3a1a1497cb3',
          name: 'Process Heating Equipment',
          children: [],
          tradeId: '42 10 00',
        },
        {
          id: '0bad801d-2055-453e-8366-f3429a19c67b',
          name: 'Process Boilers',
          children: [
            {
              id: '80f4e2dc-f4d8-479d-83f7-e70c0bec42a7',
              name: 'Process Boilers',
              tradeId: '42 11 00',
            },
            {
              id: '01ac8baa-2171-4aae-94da-a0efe5f60e5f',
              name: 'Low-Pressure Process Boilers',
              tradeId: '42 11 13',
            },
            {
              id: '69d990fd-004a-4fb3-8bb3-880cac78775f',
              name: 'Intermediate-Pressure Process Boilers',
              tradeId: '42 11 16',
            },
            {
              id: '1e4a0b82-4481-4f34-a855-878ab5eb0680',
              name: 'High-Pressure Process Boilers',
              tradeId: '42 11 19',
            },
            {
              id: '4a9b8d2d-cc3a-456d-a8b4-3217435d2d27',
              name: 'Specialty Process Boilers',
              tradeId: '42 11 23',
            },
          ],
          tradeId: '42 11 00',
        },
        {
          id: '2c10ecf7-7e17-4d5e-8e48-0f6720d2bda1',
          name: 'Process Heaters',
          children: [
            {
              id: '065cb86e-644f-4010-a04e-59c8c17bf3a1',
              name: 'Process Heaters',
              tradeId: '42 12 00',
            },
            {
              id: '3a903576-6bd3-473d-9cfd-8f97f54504d9',
              name: 'Electric Process Heaters',
              tradeId: '42 12 13',
            },
            {
              id: 'c366cdde-ed6e-4210-abe9-ff8ea8b833e7',
              name: 'Fuel-Fired Process Heaters',
              tradeId: '42 12 16',
            },
            {
              id: 'aae7a861-582d-4374-8fd9-952f24ea8cd2',
              name: 'Thermoelectric Process Heaters',
              tradeId: '42 12 19',
            },
            {
              id: '9b74e5ad-7dec-40ed-a4e4-206b357816d1',
              name: 'Solar Process Heaters',
              tradeId: '42 12 23',
            },
            {
              id: 'b2bf699c-3c01-494a-9abb-f5f264d83928',
              name: 'Specialty Process Heaters',
              tradeId: '42 12 26',
            },
          ],
          tradeId: '42 12 00',
        },
        {
          id: '2e029835-52b9-4fe6-80a7-8bdb1bebc373',
          name: 'Industrial Heat Exchangers and Recuperators',
          children: [
            {
              id: 'e2bd29cd-5ab2-4863-a77b-e8b22779aa11',
              name: 'Industrial Heat Exchangers and Recuperators',
              tradeId: '42 13 00',
            },
            {
              id: 'de33bb0e-6d83-4003-b1b1-f9da9677ccca',
              name: 'Industrial Gas-to-Gas Heat Exchangers',
              tradeId: '42 13 13',
            },
            {
              id: '447efe02-be45-42c1-946c-89e8baa64844',
              name: 'Industrial Liquid-to-Gas/Gas-to-Liquid Heat Exchangers',
              tradeId: '42 13 16',
            },
            {
              id: '6c3daf0e-1482-4645-8d18-051260da7936',
              name: 'Industrial Liquid-to-Liquid Heat Exchangers',
              tradeId: '42 13 19',
            },
            {
              id: '968b3882-1086-4e50-a6d8-42528657f119',
              name: 'Industrial Gas Radiation Heat Exchangers',
              tradeId: '42 13 23',
            },
            {
              id: '9fb31759-1ccd-4e75-9e6e-f30279db7b4d',
              name: 'Industrial Solar Radiation Heat Exchangers',
              tradeId: '42 13 26',
            },
          ],
          tradeId: '42 13 00',
        },
        {
          id: '5fbae558-274a-46c4-afb4-ada1ecf78bb8',
          name: 'Industrial Furnaces',
          children: [
            {
              id: 'e4f69876-641d-472b-8097-2240cfb813e0',
              name: 'Industrial Furnaces',
              tradeId: '42 14 00',
            },
            {
              id: '437dac28-2de1-4461-bba4-948e81c50c29',
              name: 'Annealing Furnaces',
              tradeId: '42 14 13',
            },
            {
              id: '9d4df171-3c2b-419a-be35-7c1c4605df7e',
              name: 'Atmosphere Generators',
              tradeId: '42 14 16',
            },
            {
              id: 'e7dea005-d047-46a1-b520-deb1b7e6c2a8',
              name: 'Industrial Baking Furnaces',
              tradeId: '42 14 19',
            },
            {
              id: 'a094e96a-6f3f-4c2a-981c-5fd42592f5fe',
              name: 'Industrial Brazing Furnaces',
              tradeId: '42 14 23',
            },
            {
              id: '03693fad-7092-45f6-9061-a3d2d38a2b1a',
              name: 'Industrial Calcining Furnaces',
              tradeId: '42 14 26',
            },
            {
              id: '01630574-45cd-4084-b67b-1d71372e6b33',
              name: 'Industrial Heat-Treating Furnaces',
              tradeId: '42 14 29',
            },
            {
              id: 'd0a2af28-9af1-445b-80df-6db70a401b43',
              name: 'Industrial Melting Furnaces',
              tradeId: '42 14 33',
            },
            {
              id: '19f80231-0c9f-457b-a691-4836ec0af4b8',
              name: 'Ceramics and Glass Melting Furnaces',
              tradeId: '42 14 33.13',
            },
            {
              id: '7b3d3ef6-7473-4616-a589-bb52c3443203',
              name: 'Ferrous Melting Furnaces',
              tradeId: '42 14 33.16',
            },
            {
              id: '6335f050-4b22-420c-abfd-d2bf809d4f6a',
              name: 'Non-Ferrous Melting Furnaces',
              tradeId: '42 14 33.19',
            },
            {
              id: 'd70239b3-6d01-4c93-b593-b6d6519ff12c',
              name: 'Primary Refining Furnaces',
              tradeId: '42 14 36',
            },
            {
              id: 'bfbb46b0-aad2-430d-991f-02400bef1618',
              name: 'Reactor Furnaces',
              tradeId: '42 14 43',
            },
            {
              id: 'd89b1d6b-ef69-47a0-a8b0-7d02ef65403c',
              name: 'Industrial Reheat Furnaces',
              tradeId: '42 14 46',
            },
            {
              id: '6335071d-b4bf-4880-8428-b5ac109b8c88',
              name: 'Industrial Sintering Furnaces',
              tradeId: '42 14 53',
            },
            {
              id: 'f3a7719a-9e3a-44bd-a576-951cb2933579',
              name: 'Industrial Vacuum Furnaces',
              tradeId: '42 14 56',
            },
          ],
          tradeId: '42 14 00',
        },
        {
          id: 'd00e699f-b7e6-4c60-aefa-d496fb509118',
          name: 'Industrial Ovens',
          children: [
            {
              id: '15acfde9-75e0-4384-89f7-8593b7365772',
              name: 'Industrial Ovens',
              tradeId: '42 15 00',
            },
            {
              id: '1ac0d887-8fdf-40a9-b5a1-00336b3f421b',
              name: 'Industrial Drying Ovens',
              tradeId: '42 15 13',
            },
            {
              id: '09be9258-0325-416f-8bfa-74e0a88c1519',
              name: 'Industrial Curing Ovens',
              tradeId: '42 15 16',
            },
            {
              id: '3b0db30b-781e-4a3f-9d76-6770fac9d5fb',
              name: 'Industrial Specialty Ovens',
              tradeId: '42 15 19',
            },
          ],
          tradeId: '42 15 00',
        },
        {
          id: '5352ef65-398f-4676-b04f-290e719d5512',
          name: 'Process Cooling Equipment',
          children: [],
          tradeId: '42 20 00',
        },
        {
          id: '017912c5-141f-4010-8619-185b94360b7c',
          name: 'Process Cooling Towers',
          children: [
            {
              id: '6a579e35-78c4-4dd8-aa54-28459dfc99c6',
              name: 'Process Cooling Towers',
              tradeId: '42 21 00',
            },
            {
              id: 'ae1f0f49-593d-4fd6-a401-4bf2358f7255',
              name: 'Open-Circuit Process Cooling Towers',
              tradeId: '42 21 13',
            },
            {
              id: 'b9e712fa-950b-4a32-b82e-1ee15f91342d',
              name: 'Closed-Circuit Process Cooling Towers',
              tradeId: '42 21 16',
            },
          ],
          tradeId: '42 21 00',
        },
        {
          id: '7cb92a10-eef0-45ea-bb91-d7f6a89d4ca7',
          name: 'Process Chillers and Coolers',
          children: [
            {
              id: '1b6351b8-f757-4269-bdb4-1b4fff76e4f4',
              name: 'Process Chillers and Coolers',
              tradeId: '42 22 00',
            },
            {
              id: '2040db02-4ed9-47bf-8fb4-b77e990c2b3b',
              name: 'Centrifugal Process Chillers and Coolers',
              tradeId: '42 22 13',
            },
            {
              id: '8f1af4c3-163e-4e8c-8e54-633b26cac812',
              name: 'Reciprocating Process Chillers and Coolers',
              tradeId: '42 22 16',
            },
            {
              id: 'f97b782e-1a4b-466d-937b-d325daa3acd8',
              name: 'Refrigerant Process Chillers and Coolers',
              tradeId: '42 22 19',
            },
            {
              id: '07d0fee1-f8dd-446b-8402-87cb1460fa8d',
              name: 'Rotary Process Chillers and Coolers',
              tradeId: '42 22 23',
            },
            {
              id: '311a486c-b2c0-4335-b28a-29295b1dcc7e',
              name: 'Thermoelectric Process Chillers and Coolers',
              tradeId: '42 22 26',
            },
          ],
          tradeId: '42 22 00',
        },
        {
          id: 'dfedae94-b800-4fe0-913f-fa4016aec3ec',
          name: 'Process Condensers and Evaporators',
          children: [
            {
              id: '19703bfe-74ef-466f-bc48-121f78174e98',
              name: 'Process Condensers and Evaporators',
              tradeId: '42 23 00',
            },
            {
              id: '12d5b731-141e-4200-a586-ff73c0b37092',
              name: 'Process Condensers',
              tradeId: '42 23 13',
            },
            {
              id: 'fd829d9b-90a5-4ba0-8ec3-3a4d24c679fc',
              name: 'Process Cooling Evaporators',
              tradeId: '42 23 16',
            },
            {
              id: '7eab688f-a2af-402b-bbd4-e2ab51b34eea',
              name: 'Process Humidifiers',
              tradeId: '42 23 19',
            },
          ],
          tradeId: '42 23 00',
        },
        {
          id: 'b320a44d-daab-4b4a-9396-c6c9a08acd1e',
          name: 'Process Drying Equipment',
          children: [],
          tradeId: '42 30 00',
        },
        {
          id: 'b4a3697d-d28d-4cc1-808e-8ab22b4123a7',
          name: 'Gas Dryers and Dehumidifiers',
          children: [
            {
              id: 'f09ff005-7f43-4afa-8670-0ff3c97acefb',
              name: 'Gas Dryers and Dehumidifiers',
              tradeId: '42 31 00',
            },
            {
              id: 'bd559740-6950-4fca-99dc-104d1fab1bc9',
              name: 'Drying Evaporators',
              tradeId: '42 31 13',
            },
            {
              id: '2be12959-9509-4263-9460-d3734cd72d7c',
              name: 'Desiccant Equipment',
              tradeId: '42 31 16',
            },
            {
              id: 'a73eabe8-b13a-4c03-9722-a465c62e34f4',
              name: 'Regenerative Dryers',
              tradeId: '42 31 19',
            },
            {
              id: '6bb6d3c8-4d79-4fd8-b824-e3e3ac2eea2b',
              name: 'Refrigerant Dryers',
              tradeId: '42 31 23',
            },
          ],
          tradeId: '42 31 00',
        },
        {
          id: '88dabf71-bcbf-4a1b-b188-baf23e2c57b9',
          name: 'Material Dryers',
          children: [
            {
              id: '974f9e5a-964a-43ac-9a48-9f2ab54dd843',
              name: 'Material Dryers',
              tradeId: '42 32 00',
            },
            {
              id: '87623403-f42f-4cc7-a78b-256298b51713',
              name: 'Centrifugal Material Dryers',
              tradeId: '42 32 13',
            },
            {
              id: '51d4a32a-d736-4ab2-af5c-8ed14c7fd580',
              name: 'Conveyor Material Dryers',
              tradeId: '42 32 16',
            },
            {
              id: 'b90f0a64-ec47-4a54-baf0-b70639be8a0a',
              name: 'Flash Material Dryers',
              tradeId: '42 32 19',
            },
            {
              id: 'e9cca79a-b93f-41a3-89ca-16c7e72e28cf',
              name: 'Fluid-Bed Material Dryers',
              tradeId: '42 32 23',
            },
            {
              id: 'ff1175e2-76bd-46c9-ae98-9a2c1eb898bd',
              name: 'Material Roasters',
              tradeId: '42 32 26',
            },
            {
              id: '81607b06-ee7f-4ebc-be09-907b219f8943',
              name: 'Rotary-Kiln Material Dryers',
              tradeId: '42 32 29',
            },
            {
              id: 'b678a785-bea6-4889-a471-263b918273d2',
              name: 'Spray Material Dryers',
              tradeId: '42 32 33',
            },
            {
              id: '73c40d58-8cd4-48ac-8517-15d2a2be0bf9',
              name: 'Tower Material Dryers',
              tradeId: '42 32 36',
            },
            {
              id: 'd53e0374-bd7c-4c2a-90cc-55dc9b749bf6',
              name: 'Vacuum Material Dryers',
              tradeId: '42 32 39',
            },
            {
              id: '1972a12c-75be-4d61-9ec5-c6b3e19681c7',
              name: 'Specialty Material Dryers',
              tradeId: '42 32 43',
            },
          ],
          tradeId: '42 32 00',
        },
      ],
      tradeId: '42 00 00',
    },
    {
      id: 'cd8189ad-888b-4bb0-bd58-55648f78202a',
      name: 'Process Gas and Liquid Handling, Purification and Storage Equipment',
      children: [
        {
          id: '601cbf33-793f-4e64-93a9-8b9ead85b998',
          name: 'Process Gas and Liquid Handling, Purification and Storage Equipment',
          children: [],
          tradeId: '43 00 00',
        },
        {
          id: '3b4085e2-bd1d-4b88-9a64-b641e2ad4049',
          name: 'Operation and Maintenance of Process Gas and Liquid Handling, Purification and Storage Equipment',
          children: [
            {
              id: 'b28439d4-29a4-4ad9-af5e-1041148c9ac7',
              name: 'Operation and Maintenance of Process Gas and Liquid Handling, Purification and Storage Equipment',
              tradeId: '43 01 00',
            },
            {
              id: 'ae01d48b-e113-46bf-92a3-aa59d512b4ce',
              name: 'Operation and Maintenance of Gas Handling Equipment',
              tradeId: '43 01 10',
            },
            {
              id: 'bbac0507-4eb0-44af-b63b-34a5a8c474c3',
              name: 'Gas Blowers Maintenance and Rehabilitation',
              tradeId: '43 01 10.13',
            },
            {
              id: '0058c30d-927d-4f29-97a4-bcb3d69a2275',
              name: 'Gas Compressors Maintenance and Rehabilitation',
              tradeId: '43 01 10.16',
            },
            {
              id: '504b5281-c097-4cb5-a2ce-b3085085aaaf',
              name: 'Operation and Maintenance of Liquid Handling Equipment',
              tradeId: '43 01 20',
            },
            {
              id: '83cce9f1-811e-41b3-b1b5-00c27db2fc47',
              name: 'Liquid Pumps Maintenance and Rehabilitation',
              tradeId: '43 01 20.13',
            },
            {
              id: '58a5201f-e8eb-47b3-8fd9-f55b2b5c7bf2',
              name: 'Liquid Process Equipment Maintenance and Rehabilitation',
              tradeId: '43 01 20.16',
            },
            {
              id: '705603ea-c264-4491-b478-c0a6a4d44b18',
              name: 'Operation and Maintenance of Gas and Liquid Hi-Purification Equipment',
              tradeId: '43 01 30',
            },
            {
              id: 'd411dad4-54f4-44ee-8858-f13cb1f2967a',
              name: 'Operation and Maintenance of Gas and Liquid Storage',
              tradeId: '43 01 40',
            },
            {
              id: '5505b8ad-bc5b-4cb5-8957-0b49d773f3d1',
              name: 'Non-pressurized Tanks Cleaning, Maintenance and Rehabilitation',
              tradeId: '43 01 40.13',
            },
            {
              id: '26f65f9e-e82a-40f0-93ad-6552910de685',
              name: 'Pressurized Tanks Cleaning, Maintenance and Rehabilitation',
              tradeId: '43 01 40.16',
            },
          ],
          tradeId: '43 01 00',
        },
        {
          id: 'f567403a-2171-44d5-910a-602fb3e39a23',
          name: 'Common Work Results for Process Gas and Liquid Handling, Purification and Storage Equipment',
          children: [
            {
              id: '203b0edc-71cd-4bb5-a17f-8e716aaafe0d',
              name: 'Common Work Results for Process Gas and Liquid Handling, Purification and Storage Equipment',
              tradeId: '43 05 00',
            },
            {
              id: 'b45b200f-c715-405d-8d3b-cdc8757d3c51',
              name: 'Common Work Results for Gas Handling Equipment',
              tradeId: '43 05 10',
            },
            {
              id: '1c090a85-93e4-4e0c-98d8-ac6b7f816af9',
              name: 'Common Work Results for Liquid Handling Equipment',
              tradeId: '43 05 20',
            },
            {
              id: 'a49f810c-3fef-449b-86c0-33b3784b722f',
              name: 'Common Work Results for Gas and Liquid Hi-Purification Equipment',
              tradeId: '43 05 30',
            },
            {
              id: 'cf4ada97-7603-4399-ae4c-f6bf759be99c',
              name: 'Common Work Results for Gas and Liquid Storage',
              tradeId: '43 05 40',
            },
          ],
          tradeId: '43 05 00',
        },
        {
          id: 'd2335f04-8695-465c-9adb-640020c6d367',
          name: 'Schedules for Process Gas and Liquid Handling, Purification and Storage Equipment',
          children: [
            {
              id: 'a818159e-e2fd-4b5d-9140-626418574c52',
              name: 'Schedules for Process Gas and Liquid Handling, Purification and Storage Equipment',
              tradeId: '43 06 00',
            },
            {
              id: 'f37186c6-b33c-478c-a2c2-a2d39e6cef35',
              name: 'Schedules for Gas Handling Equipment',
              tradeId: '43 06 10',
            },
            {
              id: '3788f671-49e7-41cb-9b54-aea77e5963e4',
              name: 'Schedules for Liquid Handling Equipment',
              tradeId: '43 06 20',
            },
            {
              id: '70676b50-5d57-4961-9536-8fb209afbae3',
              name: 'Schedules for Gas and Liquid Hi-Purification Equipment',
              tradeId: '43 06 30',
            },
            {
              id: '01745fc4-828d-4f57-9409-5e2a9817e189',
              name: 'Schedules for Gas and Liquid Storage',
              tradeId: '43 06 40',
            },
          ],
          tradeId: '43 06 00',
        },
        {
          id: '6a15bc8b-abd5-4402-9dd3-08f3de0c9de9',
          name: 'Commissioning of Process Gas and Liquid Handling, Purification and Storage Equipment',
          children: [
            {
              id: '9f59b1d9-3e56-4074-b8be-7e1e59880eaf',
              name: 'Commissioning of Process Gas and Liquid Handling, Purification and Storage Equipment',
              tradeId: '43 08 00',
            },
            {
              id: '391ac488-0ede-4d21-8b54-cc120139563f',
              name: 'Commissioning of Gas Handling Equipment',
              tradeId: '43 08 10',
            },
            {
              id: 'bc7b77c0-f17a-44e1-a6eb-bbe10cbd39bf',
              name: 'Commissioning of Liquid Handling Equipment',
              tradeId: '43 08 20',
            },
            {
              id: '514edaa5-d0e2-45ad-b730-6ff2c472af9b',
              name: 'Commissioning of Gas and Liquid Purification Equipment',
              tradeId: '43 08 30',
            },
            {
              id: '8008da80-7b96-469b-aebb-fcf01eb5b26b',
              name: 'Commissioning of Gas and Liquid Storage',
              tradeId: '43 08 40',
            },
          ],
          tradeId: '43 08 00',
        },
        {
          id: '330e19ca-cc79-4b02-869b-33562e835fff',
          name: 'Gas Handling Equipment',
          children: [],
          tradeId: '43 10 00',
        },
        {
          id: '098d7b26-799e-4385-8e9e-ca0aa8c5022e',
          name: 'Gas Fans, Blowers, Pumps and Boosters',
          children: [
            {
              id: 'b9b3427c-543a-4133-bfb6-a36306b0a350',
              name: 'Gas Fans, Blowers, Pumps and Boosters',
              tradeId: '43 11 00',
            },
            {
              id: '460d49b3-f507-4435-8ede-d1170bbaa14e',
              name: 'Direct Drive Single Stage Centrifugal Blowers',
              tradeId: '43 11 11',
            },
            {
              id: '89a498ff-7360-4923-aca9-04c76ef24067',
              name: 'Integrally Geared Single Stage Centrifugal Blowers',
              tradeId: '43 11 12',
            },
            {
              id: '201b0350-0cff-4db5-b03d-f36301aaf8e6',
              name: 'Separately Geared Single Stage Centrifugal Blowers',
              tradeId: '43 11 13',
            },
            {
              id: 'ad4b35f5-b4cf-4934-b933-2784f97ea99e',
              name: 'Direct Drive Integral Shaft Single Stage Centrifugal Blowers',
              tradeId: '43 11 14',
            },
            {
              id: '89dcf2e4-966f-405d-9c20-fb2a1c0ed09c',
              name: 'Horizontally Split Multistage Centrifugal Blowers',
              tradeId: '43 11 17',
            },
            {
              id: '6822cdf1-1a23-44b0-896f-6a0c793503c4',
              name: 'Vertically Split Multistage Centrifugal Blowers',
              tradeId: '43 11 18',
            },
            {
              id: '116a7a80-e818-4313-9228-29d3c572880d',
              name: 'Centrifugal Fans',
              tradeId: '43 11 19',
            },
            {
              id: '005f8f46-156b-473f-826c-1b01cf50aaa8',
              name: 'Axial Blowers',
              tradeId: '43 11 23',
            },
            {
              id: '53d49750-433f-4c72-870c-9ff00849f316',
              name: 'Axial Fans',
              tradeId: '43 11 26',
            },
            {
              id: '94fddc88-d32f-4e7d-b4e4-3b6d39239abb',
              name: 'Rotary Helical Screw Blowers',
              tradeId: '43 11 31',
            },
            {
              id: '790a77fb-9d86-4f06-959b-aec9bd8edcd4',
              name: 'Rotary Lobe Blowers',
              tradeId: '43 11 33',
            },
            {
              id: 'bbf40f90-d448-41e8-baed-31bf548fecd4',
              name: 'Regenerative Rotary Blowers',
              tradeId: '43 11 34',
            },
            {
              id: '2c837eb4-fe8f-4cdb-832a-afb7468e8446',
              name: 'Rotary Vane Blowers',
              tradeId: '43 11 36',
            },
            {
              id: '90b20208-f476-4e6c-a5fe-7f6d916bc7e5',
              name: 'Gas-handling Venturi Jet Pumps',
              tradeId: '43 11 41',
            },
            {
              id: '9eb6b577-92e0-4d30-a4d2-06e392902fa1',
              name: 'Gas-handling Vacuum Pumps',
              tradeId: '43 11 43',
            },
            {
              id: '50f387a6-aa7b-44d0-b275-c1242238d78c',
              name: 'Gas Boosters',
              tradeId: '43 11 46',
            },
          ],
          tradeId: '43 11 00',
        },
        {
          id: '547a8c93-d2bc-40b2-b589-351f7329e8dd',
          name: 'Gas Compressors',
          children: [
            {
              id: '1ec5833b-823a-44e3-8563-25f7a7b9ed06',
              name: 'Gas Compressors',
              tradeId: '43 12 00',
            },
            {
              id: '6b85ff95-b600-481e-8219-66a4fb8c1b6d',
              name: 'Centrifugal Compressors',
              tradeId: '43 12 11',
            },
            {
              id: '755f7eb7-ffa7-444e-bb52-50cfe79b83d2',
              name: 'Diagonal or Mixed-flow Compressors',
              tradeId: '43 12 13',
            },
            {
              id: '1179d4a0-084a-46bf-952f-5f05b7c762f5',
              name: 'Axial-flow Compressors',
              tradeId: '43 12 16',
            },
            {
              id: '34d823b5-f393-40da-ba3c-224649d22a36',
              name: 'Single-acting Reciprocating Compressors',
              tradeId: '43 12 33',
            },
            {
              id: '999df4ee-e998-44c6-9680-956a7fa11495',
              name: 'Double-acting Reciprocating Compressors',
              tradeId: '43 12 34',
            },
            {
              id: '3b5f3641-3c94-4e68-ab94-02e970c861eb',
              name: 'Diaphragm Reciprocating Compressors',
              tradeId: '43 12 37',
            },
            {
              id: '56c37668-8b0d-4a4c-9a6a-e0f6a51cb127',
              name: 'Rotary Screw Compressors',
              tradeId: '43 12 51',
            },
            {
              id: '122b828f-16c8-4808-ad16-3a776fa9ffa4',
              name: 'Rotary Vane Compressors',
              tradeId: '43 12 53',
            },
            {
              id: '96f0cd92-bcba-460f-93b0-69c081f4916a',
              name: 'Rotary Liquid-ring Compressors',
              tradeId: '43 12 56',
            },
            {
              id: '3a94aa6b-abf0-475e-a5ca-8365fce8adae',
              name: 'Rotary Scroll Compressors',
              tradeId: '43 12 57',
            },
          ],
          tradeId: '43 12 00',
        },
        {
          id: '53e6f0f1-8453-461e-abc8-af66d8dd7015',
          name: 'Gas Process Equipment',
          children: [
            {
              id: '2189b704-27e0-4b0e-92b9-e438f34e84b2',
              name: 'Gas Process Equipment',
              tradeId: '43 13 00',
            },
            {
              id: 'afe2b1a2-a8b5-4e23-b6d3-42a71315921e',
              name: 'Gas Blenders',
              tradeId: '43 13 13',
            },
            {
              id: '0d3cd98b-0016-437d-861b-081047c3568d',
              name: 'Gas Mixers',
              tradeId: '43 13 19',
            },
            {
              id: 'c0517805-6497-4312-b096-3f9a89f91ad0',
              name: 'Gas Pressure Regulators',
              tradeId: '43 13 23',
            },
            {
              id: 'd52b9f97-8739-4fee-8fb2-a7e540dc9791',
              name: 'Gas Separation Equipment',
              tradeId: '43 13 31',
            },
            {
              id: '3ebc8960-8cbd-4b12-91c7-d91a60cce768',
              name: 'Gas Dehydration Equipment',
              tradeId: '43 13 33',
            },
            {
              id: '56c6bce3-c0c1-4e9b-b0c7-55d329c857a0',
              name: 'Combined Gas Separation and Dehydration Equipment',
              tradeId: '43 13 36',
            },
            {
              id: '98dd9685-db07-49b9-9f96-6f4edcff1d60',
              name: 'Gas Recovery and Condensing Equipment',
              tradeId: '43 13 39',
            },
            {
              id: '51cf887c-a659-4bdb-819a-9073d88e5ef7',
              name: 'Waste Gas Burner System',
              tradeId: '43 13 43',
            },
            {
              id: '92d0bd2f-fb77-4a57-96bf-bebdb6232c65',
              name: 'Gas Control and Safety Equipment',
              tradeId: '43 13 46',
            },
          ],
          tradeId: '43 13 00',
        },
        {
          id: 'a94224e5-6c25-4209-a787-6e599919a073',
          name: 'Process Air and Gas Filters',
          children: [
            {
              id: '5abef66c-cde7-41c7-88e6-e2d1f90d7c45',
              name: 'Process Air and Gas Filters',
              tradeId: '43 15 00',
            },
            {
              id: '9c564cef-d5dc-48dc-8a06-838db5140c96',
              name: 'Blower Intake and Turbine Air Filters',
              tradeId: '43 15 13',
            },
            {
              id: 'd1f9ad14-6379-43eb-904b-4b96657424c5',
              name: 'Static Prefilters',
              tradeId: '43 15 13.13',
            },
            {
              id: '796a60ce-35ba-43e3-a44c-65ccc434d262',
              name: 'Static Final Filters',
              tradeId: '43 15 13.16',
            },
            {
              id: '7a80d845-7757-4634-924d-0a4aec38fd12',
              name: 'Static HEPA Filters',
              tradeId: '43 15 13.19',
            },
            {
              id: '2736ddd2-b9c4-4b6a-b34e-96a3d186ce5d',
              name: 'Pulse Filters',
              tradeId: '43 15 13.23',
            },
            {
              id: 'f8e4fd7c-5c77-4e38-80dd-fb87c5766288',
              name: 'Grease Filters',
              tradeId: '43 15 33',
            },
            {
              id: 'f8d13722-58c4-483a-bf72-213354a18176',
              name: 'Mist Eliminators',
              tradeId: '43 15 43',
            },
            {
              id: 'a9793f72-f9c2-4cdc-a280-c65698daf904',
              name: 'High-temperature Air Filters',
              tradeId: '43 15 63',
            },
            {
              id: '9bc5bc51-9cb1-4316-bc4f-4d3f904e5d7c',
              name: 'Multiple-application Air Filters',
              tradeId: '43 15 73',
            },
            {
              id: 'e71ef9fd-7157-4be8-840b-050d39944707',
              name: 'Air Filter Media',
              tradeId: '43 15 73.11',
            },
            {
              id: '4df485b8-8966-4806-8244-a971cc99f96a',
              name: 'Permanent Washable Filters',
              tradeId: '43 15 73.12',
            },
            {
              id: 'dc1e6d30-3ef9-489e-9a2b-cdddadc900c6',
              name: 'Poly-ring Air Filters',
              tradeId: '43 15 73.13',
            },
            {
              id: '819546a4-5a3a-4e13-84e4-a4ccb0d4ec3c',
              name: 'Rigid Cell Filters',
              tradeId: '43 15 73.15',
            },
            {
              id: '6d867900-874b-405e-8755-97b4040a4ad6',
              name: 'Automatic Roll-type Air Filters',
              tradeId: '43 15 73.17',
            },
            {
              id: '927330b7-bcc1-4ca9-87a9-846356e9240f',
              name: 'Chemical Media for Air and Gas Filters',
              tradeId: '43 15 76',
            },
          ],
          tradeId: '43 15 00',
        },
        {
          id: '16962f90-0427-4f71-953e-72c7fd53ef0b',
          name: 'Liquid Handling Equipment',
          children: [],
          tradeId: '43 20 00',
        },
        {
          id: '9b6e0597-8ec8-4180-b4fb-131660611bec',
          name: 'Liquid Pumps',
          children: [
            {
              id: 'e1b0b9e7-434f-49ac-a725-04b2723b9c69',
              name: 'Liquid Pumps',
              tradeId: '43 21 00',
            },
            {
              id: 'ee0a2958-1522-4986-beda-6ea5bce87d37',
              name: 'Centrifugal Liquid Pumps',
              tradeId: '43 21 13',
            },
            {
              id: 'a89bda9b-6bed-4f15-88a8-d5e61e8ec6c1',
              name: 'Diaphragm Liquid Pumps',
              tradeId: '43 21 16',
            },
            {
              id: '3fc528e3-3eb2-4cab-ae0d-866e4b7eea46',
              name: 'Dispensing Liquid Pumps',
              tradeId: '43 21 19',
            },
            {
              id: '490586d1-602c-4185-930f-6c330bcf8b61',
              name: 'Drum Liquid Pumps',
              tradeId: '43 21 23',
            },
            {
              id: '85e84fd6-5115-4da8-b543-871e8d2757ae',
              name: 'Gear Liquid Pumps',
              tradeId: '43 21 26',
            },
            {
              id: 'bf2111a0-c4a1-41ec-9661-acc513fff2b5',
              name: 'Metering Liquid Pumps',
              tradeId: '43 21 29',
            },
            {
              id: '86f033a3-f80d-4219-86bb-9317349f3515',
              name: 'Piston/Plunger Liquid Pumps',
              tradeId: '43 21 33',
            },
            {
              id: '4fb5009d-865a-49d2-a320-bba21bcb2546',
              name: 'Positive Displacement Liquid Pumps',
              tradeId: '43 21 36',
            },
            {
              id: 'b11017c1-45d9-462d-b8c4-b7ec62e4a0d7',
              name: 'Submersible Liquid Pumps',
              tradeId: '43 21 39',
            },
            {
              id: '0dca8994-0f8a-4acf-a62b-d7fbeca045e3',
              name: 'Sump Liquid Pumps',
              tradeId: '43 21 43',
            },
            {
              id: '8dca9208-6f51-49bb-ab03-39385b37d580',
              name: 'Vane Liquid Pumps',
              tradeId: '43 21 46',
            },
          ],
          tradeId: '43 21 00',
        },
        {
          id: 'de3d4c71-7728-4550-a2b1-5626abb25867',
          name: 'Liquid Process Equipment',
          children: [
            {
              id: 'd19946ac-6460-4145-871a-707db074cba2',
              name: 'Liquid Process Equipment',
              tradeId: '43 22 00',
            },
            {
              id: 'f56c9e8d-4e47-4ff0-a116-9cef2fca9777',
              name: 'Liquid Aeration Devices',
              tradeId: '43 22 13',
            },
            {
              id: '7c21f2cb-1e05-48aa-9581-e11c2e6cd4cc',
              name: 'Liquid Agitators',
              tradeId: '43 22 16',
            },
            {
              id: 'dfbedb92-1317-4351-b809-1d85b890c928',
              name: 'Liquid Blenders',
              tradeId: '43 22 19',
            },
            {
              id: '38b1178b-beb9-4768-85e0-7c9ede98a549',
              name: 'Liquid Centrifuges',
              tradeId: '43 22 23',
            },
            {
              id: '7b929b11-c835-4468-8e17-ff9616c3bd92',
              name: 'Liquid Deaerators',
              tradeId: '43 22 26',
            },
            {
              id: '2b667772-b7bc-4b92-9712-f796b02eb0ef',
              name: 'Drum Handling Liquid Process Equipment',
              tradeId: '43 22 29',
            },
            {
              id: 'd3052d11-e053-402b-9332-b4aab73a40f5',
              name: 'Liquid Emulsifiers',
              tradeId: '43 22 33',
            },
            {
              id: '6f08b3a1-fd54-449f-bc67-6b3bc31d071e',
              name: 'Liquid Evaporators',
              tradeId: '43 22 36',
            },
            {
              id: 'f46aef03-2829-4b4d-bea3-364569cb5ed5',
              name: 'Liquid Feeders',
              tradeId: '43 22 39',
            },
            {
              id: '93f6b0a8-3fbe-4f3f-a775-d7f257d52719',
              name: 'Liquid Process Mixers',
              tradeId: '43 22 56',
            },
            {
              id: 'dcd32201-a79f-4656-bc2c-77c2dbc16388',
              name: 'Liquid Process Pressure Regulators',
              tradeId: '43 22 59',
            },
            {
              id: 'c323b4d0-365a-48b4-a014-c329f5f1d25f',
              name: 'Liquid Separation Towers',
              tradeId: '43 22 63',
            },
            {
              id: 'd1ff66b8-1ba1-4e07-adbc-236685269e6b',
              name: 'Liquid Weigh Systems',
              tradeId: '43 22 66',
            },
            {
              id: '12ed29a7-b66a-48df-b22d-053d6d89901b',
              name: 'Liquid Grease Receiving and Dewatering Systems',
              tradeId: '43 22 69',
            },
            {
              id: 'baedfa4a-0db4-4668-bb6c-21cb50c338de',
              name: 'Liquid Fillers',
              tradeId: '43 22 73',
            },
            {
              id: '26fecbf9-b838-48e7-871b-aa7669ec421e',
              name: 'Liquid Screeners',
              tradeId: '43 22 76',
            },
            {
              id: '74b82606-451a-4fe5-a2b1-1c66d7d3b808',
              name: 'Liquid Clarifiers',
              tradeId: '43 22 79',
            },
            {
              id: 'ce1b68d9-49ae-41cb-b59b-e4eac22c7b6c',
              name: 'Liquid Classifiers',
              tradeId: '43 22 83',
            },
            {
              id: '1b8d143f-3b76-4741-ad4e-d7e3f9f7b20c',
              name: 'Liquid Homogenizers',
              tradeId: '43 22 86',
            },
            {
              id: '5ba50286-3e5d-466a-9cc5-f389e9d7a999',
              name: 'Liquid Presses',
              tradeId: '43 22 89',
            },
            {
              id: '025b3018-c21f-4967-85ab-8dd805497220',
              name: 'Liquid Versators',
              tradeId: '43 22 96',
            },
            {
              id: '84ab20d3-1508-4535-b1ae-6e0e0fea4b73',
              name: 'Liquid Votators',
              tradeId: '43 22 99',
            },
          ],
          tradeId: '43 22 00',
        },
        {
          id: 'da96075d-69ad-4e45-bc29-5e428873d0d9',
          name: 'Process Liquid Filters',
          children: [
            {
              id: 'caca84dc-ebb1-4ee4-b387-7577d1c8f86d',
              name: 'Process Liquid Filters',
              tradeId: '43 27 00',
            },
            {
              id: 'ab10e1aa-44ff-4947-9285-6bb259e2b5a5',
              name: 'Cyclonic Liquid Filters',
              tradeId: '43 27 13',
            },
            {
              id: 'acab91ee-1705-4c94-bfc0-98c64464f7ff',
              name: 'Centrifugal Horizontal Pressure Leaf Liquid Filters',
              tradeId: '43 27 16',
            },
            {
              id: '143d4aaf-c47c-494c-92dd-97560239568b',
              name: 'Press Liquid Filters',
              tradeId: '43 27 19',
            },
            {
              id: '020378f6-0b98-478a-8370-1551617e5dd9',
              name: 'Liquid Bag Filters',
              tradeId: '43 27 23',
            },
            {
              id: '7c231999-4959-4003-8e78-02478c09116f',
              name: 'Mechanically Cleaned Liquid Filters',
              tradeId: '43 27 33',
            },
            {
              id: '2f240a03-4963-4a0f-ac03-943f94f287ac',
              name: 'Tubular Backwashing Liquid Filters',
              tradeId: '43 27 43',
            },
            {
              id: 'e3098194-76fd-4d4d-a076-2a593d025160',
              name: 'Vacuum Belt Liquid Filters',
              tradeId: '43 27 53',
            },
            {
              id: 'f1ae62e8-c37d-4261-ae3c-3691837bc370',
              name: 'Woven Media Liquid Filters',
              tradeId: '43 27 63',
            },
            {
              id: '3b647833-a068-4c70-91e4-2b01e5e58022',
              name: 'Disc Liquid Filters',
              tradeId: '43 27 63.13',
            },
            {
              id: '10f33c92-c907-4b07-984e-70ae18c9689c',
              name: 'Rotating Drum Liquid Filters',
              tradeId: '43 27 63.23',
            },
            {
              id: '1dfdf17a-c66b-48b5-9138-8adda89abd3a',
              name: 'Layer Liquid Filters',
              tradeId: '43 27 63.33',
            },
            {
              id: 'fbdf0b44-1238-488c-9cb6-d5032e74f31d',
              name: 'Candle Liquid Filters',
              tradeId: '43 27 63.43',
            },
            {
              id: '01dd25ff-e096-4041-9420-e9c6734ceeb9',
              name: 'Vertical Leaf Liquid Filters',
              tradeId: '43 27 63.53',
            },
            {
              id: '9b747f5a-6e02-48ef-bb18-7705034ee879',
              name: 'Nonwoven Media Liquid Filters',
              tradeId: '43 27 73',
            },
            {
              id: '226f5197-c567-44a1-b123-abc800ecd911',
              name: 'Surface Liquid Filters',
              tradeId: '43 27 73.13',
            },
            {
              id: '66168b75-42f9-4743-a75a-2200508b9aa0',
              name: 'Depth Liquid Filters',
              tradeId: '43 27 73.23',
            },
          ],
          tradeId: '43 27 00',
        },
        {
          id: '94f0cf0b-1bac-4e08-af48-9a535a00077c',
          name: 'Gas and Liquid Purification Equipment',
          children: [],
          tradeId: '43 30 00',
        },
        {
          id: '702e1ca7-5d56-49a8-b8d8-0dea68cbb23e',
          name: 'Gas and Liquid Purification Filtration Equipment',
          children: [
            {
              id: 'fafb44df-9c24-4534-866d-b87bd4eb07d0',
              name: 'Gas and Liquid Purification Filtration Equipment',
              tradeId: '43 31 00',
            },
            {
              id: 'e1832b1b-2ecc-41c0-9442-c27ea1dac3b1',
              name: 'Gas and Liquid Purification Filters',
              tradeId: '43 31 13',
            },
            {
              id: '9b163e09-b0d0-4801-9bcb-199d311a2c40',
              name: 'Activated Carbon-Gas and Liquid Purification Filters',
              tradeId: '43 31 13.13',
            },
            {
              id: '6b35d582-375f-4ab6-835e-500714fd5aa5',
              name: 'Gas and Liquid Purification Filter Presses',
              tradeId: '43 31 13.16',
            },
            {
              id: '90e5514e-33c2-49cb-8722-bc5ac2bb04f6',
              name: 'High-Purity Cartridge Gas and Liquid Purification Filters',
              tradeId: '43 31 13.19',
            },
            {
              id: 'fdbedea6-0e10-4ba1-952c-cb77b2b08969',
              name: 'Membrane Diaphragm Gas and Liquid Purification Filters',
              tradeId: '43 31 13.23',
            },
            {
              id: '6d327566-c0b4-467b-bfe3-ab0f0bcd5f97',
              name: 'Multimedia Gas and Liquid Purification Filters',
              tradeId: '43 31 13.26',
            },
            {
              id: 'f6cab3b9-036d-4ec7-bd0d-27f9ff10ae01',
              name: 'Pretreatment Cartridge Gas and Liquid Purification Filters',
              tradeId: '43 31 13.29',
            },
            {
              id: 'badbbe60-62d0-486f-b554-a6c6c2739476',
              name: 'Ultrafilter Units',
              tradeId: '43 31 13.33',
            },
          ],
          tradeId: '43 31 00',
        },
        {
          id: 'c7f0dced-7541-47c3-8db7-1a4678305f8d',
          name: 'Gas and Liquid Purification Process Equipment',
          children: [
            {
              id: 'a76518d7-4485-4053-aeca-51e147a51078',
              name: 'Gas and Liquid Purification Process Equipment',
              tradeId: '43 32 00',
            },
            {
              id: '87f638a2-4b1f-4856-81c8-9a6b49d02ae7',
              name: 'Gas and Liquid Purification Process Beds',
              tradeId: '43 32 13',
            },
            {
              id: 'ad979264-62f3-49db-9260-9da294bb5f99',
              name: 'Anion-Gas and Liquid Purification Process Beds',
              tradeId: '43 32 13.13',
            },
            {
              id: 'd3d04267-aa52-4b2d-a976-0c3e1e9cff6b',
              name: 'Cation-Gas and Liquid Purification Process Beds',
              tradeId: '43 32 13.16',
            },
            {
              id: '69804ab6-27c5-4752-91cb-c60f475ff4b9',
              name: 'Gas and Liquid Purification Process Clarifier Systems',
              tradeId: '43 32 23',
            },
            {
              id: '541179d1-8bd4-4780-b0f0-5503019d928b',
              name: 'Gas and Liquid Purification Decarbonators',
              tradeId: '43 32 26',
            },
            {
              id: 'b27740b7-32ca-4c35-b70a-14abb61ba8fc',
              name: 'Electronic Deionization Purification Units',
              tradeId: '43 32 29',
            },
            {
              id: '69c555ea-e8e1-4240-b59f-ba9f8eae0cc6',
              name: 'External Regeneration Systems',
              tradeId: '43 32 33',
            },
            {
              id: 'd9064387-4459-40a3-ad77-4319f2b12821',
              name: 'Mixed-Bed Ion-Exchange Vessels',
              tradeId: '43 32 36',
            },
            {
              id: '460a8180-a4c4-4a37-b3dd-a45eb770d7c2',
              name: 'Gas and Liquid Purification Mixed Beds',
              tradeId: '43 32 39',
            },
            {
              id: '5ca2a974-39ad-44fa-83b1-929f6da791fe',
              name: 'Externally Regenerable Gas and Liquid Purification Mixed Beds',
              tradeId: '43 32 39.13',
            },
            {
              id: 'c1c0f554-443e-469a-bac5-2a687123c81d',
              name: 'In-Situ Regenerable Gas and Liquid Purification Mixed Beds',
              tradeId: '43 32 39.16',
            },
            {
              id: '7f9db1ba-eff7-4993-b469-8284422cea00',
              name: 'Packed-Bed Ion-Exchange Vessels',
              tradeId: '43 32 53',
            },
            {
              id: 'd236a969-cf55-4a48-b869-c8fa10addfb5',
              name: 'Reverse-Osmosis Purification Units',
              tradeId: '43 32 56',
            },
            {
              id: '750ce772-ee9d-4412-b14b-ee30594470f9',
              name: 'Gas and Liquid Purification Scrubbers',
              tradeId: '43 32 59',
            },
            {
              id: '44b16733-ea6a-4ed6-8b6f-f0eeb128bd1b',
              name: 'Ultraviolet Sterilizers',
              tradeId: '43 32 63',
            },
            {
              id: 'd66206d2-9487-4107-8785-cfcdfd201d70',
              name: 'Vacuum Degasifiers',
              tradeId: '43 32 66',
            },
            {
              id: '000bc7ff-4df0-435e-be37-53addfd544f1',
              name: 'Chemical Feed Systems',
              tradeId: '43 32 69',
            },
            {
              id: '1c54c6a3-ca8c-41bf-a62b-63b1a7702e65',
              name: 'Ozonation Equipment',
              tradeId: '43 32 73',
            },
            {
              id: '937d4bc8-f377-4885-b2f9-3ed7e5463e57',
              name: 'Chlorination Equipment',
              tradeId: '43 32 76',
            },
          ],
          tradeId: '43 32 00',
        },
        {
          id: '559b0e31-3a51-4c9a-8f42-3a6b58a1295c',
          name: 'Gas and Liquid Storage',
          children: [],
          tradeId: '43 40 00',
        },
        {
          id: 'eee98ce7-d379-44f6-8198-a2bad0809c03',
          name: 'Non-pressurized Tanks and Vessels',
          children: [
            {
              id: 'e9fb851d-0db4-475e-9847-58d90c4108fb',
              name: 'Non-pressurized Tanks and Vessels',
              tradeId: '43 41 00',
            },
            {
              id: '9b24e984-0da6-4f7b-b7b6-3dae9c48e127',
              name: 'Bolted Steel Tanks',
              tradeId: '43 41 11',
            },
            {
              id: 'd4034562-904d-49fb-92c8-4f1f71225749',
              name: 'Welded Steel Tanks',
              tradeId: '43 41 13',
            },
            {
              id: '90e98109-a917-43ea-96f2-8c1a04b771cc',
              name: 'Glass-lined Welded Steel Tanks',
              tradeId: '43 41 13.13',
            },
            {
              id: '40df0fbb-cd5f-4f55-9f17-1b60700d2ac9',
              name: 'Rubber-lined Welded Steel Tanks',
              tradeId: '43 41 13.23',
            },
            {
              id: '13186ef3-fdea-4a1f-a6a9-3e596772ca13',
              name: 'Epoxy-lined Welded Steel Tanks',
              tradeId: '43 41 13.33',
            },
            {
              id: '6fb13fc9-3be0-44f1-acd1-bb44b4b2a344',
              name: 'Ceramic-lined Welded Steel Tanks',
              tradeId: '43 41 13.43',
            },
            {
              id: 'fd085020-d7f7-41eb-923e-aed9ee70167e',
              name: 'Stainless Steel Tanks',
              tradeId: '43 41 23',
            },
            {
              id: '5219a49a-7dd2-46bb-b135-19d7ac59f3e6',
              name: 'Aluminum Tanks',
              tradeId: '43 41 26',
            },
            {
              id: '5effe28f-1547-46cb-9837-9f6e5174ee9c',
              name: 'Metallic Specialty Tanks',
              tradeId: '43 41 31',
            },
            {
              id: '49a0ea5a-e546-4ac0-baa1-c51a972d96e6',
              name: 'Polyvinyl Chloride Tanks',
              tradeId: '43 41 41',
            },
            {
              id: '4fd27b38-eb4f-4601-bfdf-cf916f23f64d',
              name: 'Polyethylene Tanks',
              tradeId: '43 41 43',
            },
            {
              id: '4fc71c4e-a27a-4fd3-871b-a7c41d6c72c5',
              name: 'Fiberglass Reinforced Plastic Tanks',
              tradeId: '43 41 45',
            },
            {
              id: '34f6d1df-e9a5-40aa-a7e2-ea06589bb6b7',
              name: 'Wood Stave Tanks',
              tradeId: '43 41 53',
            },
            {
              id: 'a249c48f-d7c1-40f8-81ce-2ad041d7ac56',
              name: 'Precast Concrete Tanks',
              tradeId: '43 41 63',
            },
            {
              id: '38d975e4-f00f-4dd3-b51c-68476a397662',
              name: 'Ceramic Tanks',
              tradeId: '43 41 73',
            },
            {
              id: 'b6f0177f-af0e-4fd8-8a76-6a7695343b91',
              name: 'Non-metallic Specialty Tanks',
              tradeId: '43 41 83',
            },
          ],
          tradeId: '43 41 00',
        },
        {
          id: 'ad965393-b8f3-46e3-b854-086c14312db8',
          name: 'Pressurized Tanks and Vessels',
          children: [
            {
              id: '3354abcc-c4b5-451d-b6cd-55729a2533cb',
              name: 'Pressurized Tanks and Vessels',
              tradeId: '43 42 00',
            },
            {
              id: '0e3344ab-d2cd-4b64-9168-6c58a6446d75',
              name: 'Cast Iron Pressure Tanks',
              tradeId: '43 42 11',
            },
            {
              id: '5aabfa8c-e38b-4eb3-92ed-30e7c22c76c3',
              name: 'Ductile Iron Pressure Tanks',
              tradeId: '43 42 13',
            },
            {
              id: '4028cb72-ede5-4dc0-8741-435275201505',
              name: 'Welded Steel Pressure Tanks',
              tradeId: '43 42 21',
            },
            {
              id: '169d9edf-20f1-4e4a-bd27-c45f8afa9889',
              name: 'Glass-lined Steel Pressure Tanks',
              tradeId: '43 42 23',
            },
            {
              id: '6fb97e2c-0fe8-4008-8747-adfb4ecbf7a6',
              name: 'Welded Steel Gas Storage Sphere',
              tradeId: '43 42 26',
            },
            {
              id: 'd64e7012-d21f-4519-af5d-01dcf5729edb',
              name: 'Stainless Steel Pressure Tanks',
              tradeId: '43 42 33',
            },
            {
              id: '00071a5f-37e0-4c5e-8e40-9c15895ef7b5',
              name: 'Aluminum Pressure Tanks',
              tradeId: '43 42 36',
            },
            {
              id: '40b8d9c2-72c2-4350-8458-6f3f38021be5',
              name: 'Metallic Specialty Pressure Tanks',
              tradeId: '43 42 41',
            },
            {
              id: '07d057aa-342a-42ed-be97-e7c67cb65e70',
              name: 'Fiberglass Reinforced Plastic Pressure Tanks',
              tradeId: '43 42 53',
            },
            {
              id: '9fd35134-6ac5-4cd9-ae5f-df98cbfaccc6',
              name: 'Non-metallic Specialty Pressure Tanks',
              tradeId: '43 42 83',
            },
          ],
          tradeId: '43 42 00',
        },
      ],
      tradeId: '43 00 00',
    },
    {
      id: 'b9d60ebe-e7a8-4d7c-b610-fec05e47e679',
      name: 'Pollution and Waste Control Equipment',
      children: [
        {
          id: 'd3126c69-039e-4718-a792-4e3f213e15ea',
          name: 'Pollution and Waste Control Equipment',
          children: [],
          tradeId: '44 00 00',
        },
        {
          id: 'b08498c1-b3ac-4f7d-b1b8-aa0946cb8743',
          name: 'Operation and Maintenance of Pollution and Waste Control Equipment',
          children: [
            {
              id: '1d5d3f82-6057-47a4-9ed8-fc98f96effaf',
              name: 'Operation and Maintenance of Pollution and Waste Control Equipment',
              tradeId: '44 01 00',
            },
            {
              id: '00854d7e-659a-476f-b1d3-0beea708647e',
              name: 'Operation and Maintenance of Air Pollution Control',
              tradeId: '44 01 10',
            },
            {
              id: 'da19c911-5b22-4bc8-ae01-5bed677729f5',
              name: 'Operation and Maintenance of Noise Pollution Control',
              tradeId: '44 01 20',
            },
            {
              id: '90f1d052-f5d3-4b30-9c76-8cdab89be7c5',
              name: 'Operation and Maintenance of Odor Control Equipment',
              tradeId: '44 01 30',
            },
            {
              id: 'a61b044b-8de4-4bb7-94a4-0588e011b240',
              name: 'Operation and Maintenance of Water Pollution Control Equipment',
              tradeId: '44 01 40',
            },
            {
              id: '66360d44-b54f-4c92-9a5d-55f401026398',
              name: 'Operation and Maintenance of Solid Waste Control and Reuse',
              tradeId: '44 01 50',
            },
            {
              id: 'bf7ad546-d107-42c8-819d-2aabdc3d01a5',
              name: 'Operation and Maintenance of Waste Thermal Processing Equipment',
              tradeId: '44 01 60',
            },
          ],
          tradeId: '44 01 00',
        },
        {
          id: 'f0da14aa-ebf5-4c22-8c33-595f27f3250b',
          name: 'Common Work Results for Pollution and Waste Control Equipment',
          children: [],
          tradeId: '44 05 00',
        },
        {
          id: 'd93c6226-edbc-4788-ad0f-8ba2fda707cb',
          name: 'Schedules for Pollution and Waste Control Equipment',
          children: [
            {
              id: 'e0193727-c298-450b-a781-f2d6f66f3c31',
              name: 'Schedules for Pollution and Waste Control Equipment',
              tradeId: '44 06 00',
            },
            {
              id: 'd29338c7-7c39-406a-a0e1-d1746ee4295e',
              name: 'Schedules for Air Pollution Control',
              tradeId: '44 06 10',
            },
            {
              id: '1b0437c5-9cfc-4b9f-a2fa-9bd284f5633e',
              name: 'Schedules for Noise Pollution Control',
              tradeId: '44 06 20',
            },
            {
              id: 'a3e758f4-6a35-4455-aed9-5629e8464f40',
              name: 'Schedules for Odor Control Equipment',
              tradeId: '44 06 30',
            },
            {
              id: 'eae8324e-a370-4b5f-ba8d-fbf6e12594be',
              name: 'Schedules for Water Pollution Control Equipment',
              tradeId: '44 06 40',
            },
            {
              id: '36823c91-8f83-4ae2-b3c0-4f7627ae49be',
              name: 'Schedules for Solid Waste Control and Reuse',
              tradeId: '44 06 50',
            },
            {
              id: '7b48456a-0164-483a-bfd4-7d1e468b9456',
              name: 'Schedules for Waste Thermal Processing Equipment',
              tradeId: '44 06 60',
            },
          ],
          tradeId: '44 06 00',
        },
        {
          id: '7bcf4612-9421-4e7d-b866-f6f86bf32510',
          name: 'Commissioning of Pollution and Waste Control Equipment',
          children: [
            {
              id: '2b59bbf9-55e0-45fe-bd13-19eb45de4b41',
              name: 'Commissioning of Pollution and Waste Control Equipment',
              tradeId: '44 08 00',
            },
            {
              id: 'f22e1f36-665b-47d9-83f9-cb8c6dd9bb33',
              name: 'Commissioning of Air Pollution Control',
              tradeId: '44 08 10',
            },
            {
              id: '2d5bd77d-d965-4cae-baf7-f535b2ed11ca',
              name: 'Commissioning of Noise Pollution Control',
              tradeId: '44 08 20',
            },
            {
              id: 'd59a8cbc-11b0-4f3c-9adb-f8a18b6d9941',
              name: 'Commissioning of Odor Control Equipment',
              tradeId: '44 08 30',
            },
            {
              id: 'f2ec9bfb-76b9-4812-b0c5-2ecfa03119ca',
              name: 'Commissioning Water Pollution Control Equipment',
              tradeId: '44 08 40',
            },
            {
              id: 'b3d94c25-2e95-4f16-92a9-54bd515ab65e',
              name: 'Commissioning Solid Waste Control and Reuse',
              tradeId: '44 08 50',
            },
            {
              id: '8b212829-2bcd-4166-b3c2-667ec2b0571b',
              name: 'Commissioning of Waste Thermal Processing Equipment',
              tradeId: '44 08 60',
            },
          ],
          tradeId: '44 08 00',
        },
        {
          id: '80005e54-82e3-4fb7-a578-1b5acb14557a',
          name: 'Air Pollution Control',
          children: [],
          tradeId: '44 10 00',
        },
        {
          id: '5669e6de-34ca-46d6-9c36-d403b70cfe07',
          name: 'Particulate Control Equipment',
          children: [
            {
              id: 'b55e2c1e-d9ec-45bc-a8e1-095c2bc9a9d1',
              name: 'Particulate Control Equipment',
              tradeId: '44 11 00',
            },
            {
              id: 'c1470677-83bc-4a6c-b0f1-b124bf367197',
              name: 'Fugitive Dust Control',
              tradeId: '44 11 13',
            },
            {
              id: 'f85b5c60-3298-4101-9e9c-2ff32d82fae0',
              name: 'Fugitive Dust Barrier Systems',
              tradeId: '44 11 16',
            },
            {
              id: 'a78af1a5-258d-4f0b-ac6f-55f83ce154d7',
              name: 'Atmospheric Air Quality Monitoring Equipment',
              tradeId: '44 11 19',
            },
            {
              id: '9bf8d271-aab4-4924-b97f-c9552ebe6858',
              name: 'Gravitational Separators',
              tradeId: '44 11 20',
            },
            {
              id: '97445a48-6d78-400c-9d54-df3b75b3bf36',
              name: 'Venturi Scrubbing Equipment',
              tradeId: '44 11 31',
            },
            {
              id: '0f1c15e1-91b0-4a8b-9891-14323d6f9412',
              name: 'Spray Tower/Chamber Scrubbing Equipment',
              tradeId: '44 11 33',
            },
            {
              id: 'f5b4b262-3964-4924-8050-9825ad939765',
              name: 'Cyclone Scrubbing Equipment',
              tradeId: '44 11 36',
            },
            {
              id: '2b46de0e-c262-42e4-b047-c076f2756526',
              name: 'Pulse Jet Fabric Filtration Equipment',
              tradeId: '44 11 51',
            },
            {
              id: '33f60544-5fb0-47e6-b0da-647ffa8cdb41',
              name: 'Reverse Flow Fabric Filtration Equipment',
              tradeId: '44 11 53',
            },
            {
              id: 'c8484781-0014-4dad-bdab-8f1f1721ca0a',
              name: 'Shake/Deflate Fabric Filtration Equipment',
              tradeId: '44 11 54',
            },
            {
              id: 'd0917135-6d14-422a-9de7-1fc782504595',
              name: 'Cartridge Filtration Equipment',
              tradeId: '44 11 56',
            },
            {
              id: 'e93c549e-d91a-4df8-b05f-b02737b51473',
              name: 'Disposable Dry Filtration Equipment',
              tradeId: '44 11 59',
            },
            {
              id: '0144fa9f-123f-44bc-afa0-a705ed8d62b3',
              name: 'Cyclone Dust Collection Equipment',
              tradeId: '44 11 71',
            },
            {
              id: 'de309c90-e92e-4f79-9056-d78929aec4a0',
              name: 'Multicylone Dust Collection Equipment',
              tradeId: '44 11 73',
            },
            {
              id: 'b8b92e23-d26a-4359-aa24-37cd5031e10e',
              name: 'Mist Elimination Equipment',
              tradeId: '44 11 76',
            },
            {
              id: '9b19fa21-bde4-4b56-a1bc-7cebddb2998d',
              name: 'Wet Electrostatic Precipitator Equipment',
              tradeId: '44 11 93',
            },
            {
              id: 'de50e62e-85b1-4705-9e27-df6d7a26e970',
              name: 'Dry Plate Electrostatic Precipitator Equipment',
              tradeId: '44 11 96',
            },
          ],
          tradeId: '44 11 00',
        },
        {
          id: 'a1709014-806b-438a-a9dd-5f0fdf86d733',
          name: 'Gaseous Air Pollution Control Equipment',
          children: [
            {
              id: '45422270-329e-4c99-b32e-44511406ff52',
              name: 'Gaseous Air Pollution Control Equipment',
              tradeId: '44 13 00',
            },
            {
              id: 'bb10941d-9a3f-496a-ba5d-e9787a05295b',
              name: 'Spray Tower/Chamber Absorption Equipment',
              tradeId: '44 13 11',
            },
            {
              id: '1dbe828b-633e-467c-93cf-6966c660b61e',
              name: 'Packed Tower/Chamber Absorption Equipment',
              tradeId: '44 13 13',
            },
            {
              id: '45a34d82-ef7d-4271-9f5f-c7427358a81f',
              name: 'Tray Tower Absorption Equipment',
              tradeId: '44 13 14',
            },
            {
              id: 'da6a25ac-bfe6-47f6-a826-a4660290b2d2',
              name: 'Jet Bubbling Reactor Equipment',
              tradeId: '44 13 16',
            },
            {
              id: '8a1aa548-3fe5-4194-afa2-f6a3d3a829e5',
              name: 'Venturi Absorption Equipment',
              tradeId: '44 13 17',
            },
            {
              id: '000c244c-3842-497c-8e5f-f4a28fd3c899',
              name: 'Vacuum Extraction Systems',
              tradeId: '44 13 20',
            },
            {
              id: 'ffb9c76b-e776-4e11-a418-57ac866738a2',
              name: 'Powdered Injection Adsorption Equipment',
              tradeId: '44 13 31',
            },
            {
              id: 'd2dde76b-3930-47db-afce-abe39c594c0d',
              name: 'Fluidized Bed Adsorption Equipment',
              tradeId: '44 13 33',
            },
            {
              id: '188ef4a9-3831-4dc7-bd13-6ba18e07349e',
              name: 'Fixed Bed Adsorption Equipment',
              tradeId: '44 13 34',
            },
            {
              id: '210f8cfc-9539-4697-9fd1-6377c4a4b1a5',
              name: 'Rotary Bed Adsorption Equipment',
              tradeId: '44 13 36',
            },
            {
              id: 'be33ee4f-933f-4cb8-871e-02afcc70e0db',
              name: 'Radial Bed Adsorption Equipment',
              tradeId: '44 13 39',
            },
            {
              id: '379f92cd-124e-40a7-bc3b-e2d5f73337ac',
              name: 'Thermal Oxidation Equipment',
              tradeId: '44 13 51',
            },
            {
              id: '45e363ed-0446-42cc-b7d1-7ccd336a0da6',
              name: 'Catalytic Oxidation Equipment',
              tradeId: '44 13 53',
            },
            {
              id: '1f561316-03c3-4a73-a829-67ee5396cec4',
              name: 'Flare Oxidation Equipment',
              tradeId: '44 13 56',
            },
            {
              id: 'baad7fbf-36a8-48b7-990d-35d890ec1040',
              name: 'Claus Sulfur Recovery Equipment',
              tradeId: '44 13 59',
            },
            {
              id: '1e8567fe-8a96-4997-95be-30897d40c81b',
              name: 'Thermal Reduction Equipment',
              tradeId: '44 13 71',
            },
            {
              id: '743e3dbd-4eea-4ee3-9292-c5a7a5f1a165',
              name: 'Catalytic Reduction Equipment',
              tradeId: '44 13 73',
            },
            {
              id: '5ddd39b2-61c1-4d19-9d80-de1e77c97667',
              name: 'Non-catalytic Reduction Equipment',
              tradeId: '44 13 76',
            },
            {
              id: '5d757503-4860-4401-823d-1bf2c761ca1e',
              name: 'Selective Non-catalytic Reduction Equipment',
              tradeId: '44 13 77',
            },
            {
              id: 'd2fc6e6d-7b94-4c29-9894-ad8f6d45bfd9',
              name: 'Condensation Systems',
              tradeId: '44 13 81',
            },
            {
              id: '2ba4a846-d99b-40cf-92ca-41a99db0fa17',
              name: 'Contact Condensing Equipment',
              tradeId: '44 13 83',
            },
            {
              id: 'f748caa8-b0ab-4179-a1a8-9758a6d6d650',
              name: 'Solvent Recovery Equipment',
              tradeId: '44 13 86',
            },
            {
              id: '18bf5a03-03ca-449f-a853-15e221e36071',
              name: 'Biofilter Media',
              tradeId: '44 13 91',
            },
            {
              id: '1e489faa-0637-447d-accb-34d8717d42aa',
              name: 'Fixed Bed Biofilter Equipment',
              tradeId: '44 13 93',
            },
            {
              id: 'ce8171f2-9915-44b3-bdf0-148899309ef9',
              name: 'Fixed Bed Biotrickling Filter Equipment',
              tradeId: '44 13 96',
            },
            {
              id: '6d84dd59-3941-4dea-a31b-a3d9b1eaa3e4',
              name: 'Fixed Bed Bioscrubbing Equipment',
              tradeId: '44 13 97',
            },
          ],
          tradeId: '44 13 00',
        },
        {
          id: 'c369a613-a6ad-43a1-8d4d-5551370648ca',
          name: 'Noise Pollution Control',
          children: [],
          tradeId: '44 20 00',
        },
        {
          id: 'ca9a2d52-e3cd-4a7f-b8ea-32ea05a5c97b',
          name: 'Noise Pollution Control Equipment',
          children: [
            {
              id: '2f59c6c1-fb4b-4bae-877a-e5b1a3822dea',
              name: 'Noise Pollution Control Equipment',
              tradeId: '44 21 00',
            },
            {
              id: '29f3f0c7-3b4f-413d-8ade-7c8e1e24046e',
              name: 'Fixed Noise Abatement Barriers',
              tradeId: '44 21 13',
            },
            {
              id: 'b742e453-e503-4be4-9374-6ab975b381ef',
              name: 'Flexible Noise Abatement Barriers',
              tradeId: '44 21 16',
            },
            {
              id: '789a3114-f9ec-4e84-98f4-a74775d678b1',
              name: 'Portable Noise Abatement Barriers',
              tradeId: '44 21 19',
            },
            {
              id: 'abd9dc38-c617-4786-8326-b432b590325e',
              name: 'Noise Pollution Silencers',
              tradeId: '44 21 23',
            },
            {
              id: 'b58cd07d-f983-4eb6-acf9-2cacec7b306f',
              name: 'Frequency Cancellers',
              tradeId: '44 21 26',
            },
          ],
          tradeId: '44 21 00',
        },
        {
          id: 'acf62f21-0f72-4651-811b-0fb533a3e7d1',
          name: 'Odor Control',
          children: [],
          tradeId: '44 30 00',
        },
        {
          id: '1aa034d3-5c16-4106-b33b-2a9ce4cbb91e',
          name: 'Odor Treatment Equipment',
          children: [
            {
              id: '8d78d256-7199-4011-a335-7a564c793c6c',
              name: 'Odor Treatment Equipment',
              tradeId: '44 31 00',
            },
            {
              id: '68df11b0-3eed-44b5-a46d-6bcd079310be',
              name: 'Wet Chemical Packed Tower Odor Control Equipment',
              tradeId: '44 31 11',
            },
            {
              id: 'df7885fe-da55-44e9-9ee4-c396f43679cc',
              name: 'Chemical Mist System Odor Control Equipment',
              tradeId: '44 31 13',
            },
            {
              id: 'c870d4b9-c59a-4d1b-96c3-9264166e7049',
              name: 'Activated Carbon Adsorption Odor Control Equipment',
              tradeId: '44 31 16',
            },
            {
              id: 'faafe830-6462-431b-b38a-f315c183d878',
              name: 'Packaged Odor Control Systems',
              tradeId: '44 31 19',
            },
            {
              id: '76623501-9b9c-4c33-ab59-9d3648e63e5d',
              name: 'Odor Control Biofilters',
              tradeId: '44 31 21',
            },
            {
              id: '4f8fc337-7d34-40ad-90a3-cf9db080f04d',
              name: 'Odor Control Biofilter Aeration Floor',
              tradeId: '44 31 28',
            },
            {
              id: 'ef6ca1c9-776e-4f17-aff1-fe9cd9f10847',
              name: 'Odor Control Biofilter Media',
              tradeId: '44 31 29',
            },
            {
              id: '5e4616e9-28c4-43c0-b2f0-eabfa40df428',
              name: 'Odor Control Biotrickling Filter Equipment',
              tradeId: '44 31 31',
            },
            {
              id: '232081bd-bf51-4e5c-a640-844ac71b0829',
              name: 'Odor Control Bioscrubbing Equipment',
              tradeId: '44 31 33',
            },
            {
              id: '21c490b2-7d16-450a-a54d-678d868a659c',
              name: 'Odor Control Vapor Combustion Equipment',
              tradeId: '44 31 41',
            },
            {
              id: 'de144e76-eaeb-4d57-aa03-7512eebabc15',
              name: 'Adjusting and Balancing Odor Control Systems',
              tradeId: '44 31 83',
            },
          ],
          tradeId: '44 31 00',
        },
        {
          id: 'e1e5a4f2-ceed-464c-a736-517ab3e0ff5f',
          name: 'Odor Dispersing and Masking/Counteracting Equipment',
          children: [
            {
              id: 'd86b69b5-a233-4db1-91c1-e675b529b6e3',
              name: 'Odor Dispersing and Masking/Counteracting Equipment',
              tradeId: '44 32 00',
            },
            {
              id: '027539ac-aa1a-4a9d-b6f9-8227ec9b2bbc',
              name: 'Odor Dispersing Exhaust Stacks',
              tradeId: '44 32 13',
            },
            {
              id: '66548779-b2c1-4f8b-ae45-471c7b898b6a',
              name: 'Fan-equipped Odor Dispersing Exhaust Stacks',
              tradeId: '44 32 13.13',
            },
            {
              id: '11decd71-8589-4d6b-8381-675ae9a5c70c',
              name: 'Odor Masking/Counteracting Equipment',
              tradeId: '44 32 23',
            },
          ],
          tradeId: '44 32 00',
        },
        {
          id: 'd47e526a-5c79-42e4-a53f-12d2c2411f97',
          name: 'Water Pollution Control Equipment',
          children: [],
          tradeId: '44 40 00',
        },
        {
          id: 'b25a35cc-5f42-44b6-9af3-5bf5fd09a7fe',
          name: 'Water Pollution Containment and Cleanup Equipment',
          children: [
            {
              id: '2ba5d177-d20c-4c2f-a02f-575180df4cbf',
              name: 'Water Pollution Containment and Cleanup Equipment',
              tradeId: '44 41 00',
            },
            {
              id: '2b1ad709-baef-4d43-ab11-30f21f6d4b2a',
              name: 'Spill Cleanup',
              tradeId: '44 41 13',
            },
            {
              id: '688c82dc-8590-4730-9739-3f5a0bde2e49',
              name: 'Containment Booms',
              tradeId: '44 41 21',
            },
            {
              id: '65fd7cf8-06aa-4268-b26f-5379e41e6710',
              name: 'Marine Spill Accessories',
              tradeId: '44 41 23',
            },
            {
              id: 'b5991266-e94e-4b96-a93d-16157ffbdc5d',
              name: 'Spill Decks',
              tradeId: '44 41 31',
            },
            {
              id: 'c34d63de-1a20-4ccd-ae7c-1ae14bbd0472',
              name: 'Drum Containment Units',
              tradeId: '44 41 32',
            },
            {
              id: 'df50e457-5180-4868-a04e-4a167afb5a4e',
              name: 'Containment Pallets',
              tradeId: '44 41 33',
            },
            {
              id: '6351a3df-76eb-4a8c-b2d1-96168257559e',
              name: 'Prefabricated Spill Containment Curbing',
              tradeId: '44 41 34',
            },
            {
              id: '9061d8bc-795a-48fb-b7e8-0fa49f283651',
              name: 'Prefabricated Spill Containment Berms',
              tradeId: '44 41 36',
            },
            {
              id: 'f81204df-d3dc-4ce8-9906-1b25b5987faf',
              name: 'Prefabricated Berms for Tank Containment',
              tradeId: '44 41 37',
            },
            {
              id: '4813a288-e460-491a-a068-1303bf65d492',
              name: 'Collapsible Storage Tanks',
              tradeId: '44 41 41',
            },
          ],
          tradeId: '44 41 00',
        },
        {
          id: '22d5f2f3-1691-457e-a109-8511558774b1',
          name: 'Solid Waste Control and Reuse',
          children: [],
          tradeId: '44 50 00',
        },
        {
          id: 'dcd45155-dd61-46aa-8f2a-c1fdda6e2a09',
          name: 'Solid Waste Collection, Transfer and Hauling Equipment',
          children: [
            {
              id: 'c0c30158-f9dc-461e-b957-5847ff8badd1',
              name: 'Solid Waste Collection, Transfer and Hauling Equipment',
              tradeId: '44 51 00',
            },
            {
              id: 'f00cb070-a537-45e5-b388-b377d09cf3ba',
              name: 'Solid Waste Portable Containers',
              tradeId: '44 51 13',
            },
            {
              id: '147a2d29-0f89-48fa-bb54-5e5d54fa6bc4',
              name: 'Solid Waste Stationary Containers',
              tradeId: '44 51 16',
            },
            {
              id: '9e0d7a6f-df34-401a-8c5a-bccced6e5604',
              name: 'Solid Waste Transfer Trailers',
              tradeId: '44 51 23',
            },
          ],
          tradeId: '44 51 00',
        },
        {
          id: '84df646b-090a-4aad-bc52-222fcde7e259',
          name: 'Solid Waste Processing Equipment',
          children: [
            {
              id: '14acf6d6-ea26-4467-ba92-bae75e3b15e5',
              name: 'Solid Waste Processing Equipment',
              tradeId: '44 53 00',
            },
            {
              id: '036dedda-778a-4e29-a4be-ae988aec6763',
              name: 'Live-floor Storage Bins',
              tradeId: '44 53 11',
            },
            {
              id: '9543ddd7-fd1e-420f-be00-1c9fc55bd3da',
              name: 'Refuse Cranes and Accessories',
              tradeId: '44 53 13',
            },
            {
              id: '9f13e3d0-20c6-4911-99be-c9d6aa9d4c88',
              name: 'Solid Waste Screens',
              tradeId: '44 53 16',
            },
            {
              id: 'f50f7ed0-51db-4fcb-90c2-9d48c60b8f09',
              name: 'Car Crushers',
              tradeId: '44 53 21',
            },
            {
              id: '71c74232-9db6-4815-a2d2-f3a313636695',
              name: 'Shears and Guillotines',
              tradeId: '44 53 23',
            },
            {
              id: '4eeb829e-87b8-4337-93b2-2f9bd20d5f4f',
              name: 'Shredders and Grinders',
              tradeId: '44 53 26',
            },
            {
              id: '847f3fda-a053-423f-ac06-30e5c39b788b',
              name: 'Perforators',
              tradeId: '44 53 27',
            },
            {
              id: '7b93e4a3-cfc5-447d-81b8-a40d65dca8fa',
              name: 'Bag Breakers',
              tradeId: '44 53 31',
            },
            {
              id: '3344f510-10d5-4b8c-af0a-f0e56396048f',
              name: 'Granulators',
              tradeId: '44 53 33',
            },
            {
              id: '96eed6c5-7b04-42f9-bd13-d7e3024f217d',
              name: 'Crumb Rubber Systems',
              tradeId: '44 53 36',
            },
            {
              id: '37bcc3dd-e8f8-4b09-b2e0-046d5ec54763',
              name: 'Briquetters',
              tradeId: '44 53 39',
            },
            {
              id: '390c9fa1-6d31-4e26-acf4-b222de98282a',
              name: 'Vibratory Table Separation Equipment',
              tradeId: '44 53 41',
            },
            {
              id: '02b14fcf-81c0-452a-8439-3d2ce16ac01c',
              name: 'Ferrous Metals Separation Equipment',
              tradeId: '44 53 43',
            },
            {
              id: 'fc1c2a7b-d4c0-40bb-9c3e-808e70064284',
              name: 'Eddy Current Separators',
              tradeId: '44 53 44',
            },
            {
              id: 'aa16c9b0-e7d5-4092-af9b-b7c65ff5f828',
              name: 'Single-stream Materials Separation Equipment',
              tradeId: '44 53 46',
            },
            {
              id: '92d57023-d134-4512-87d9-fba8850a82d1',
              name: 'Ballistic Separators',
              tradeId: '44 53 49',
            },
            {
              id: '395dfc38-1ec3-4800-a9a8-3e8661bc2021',
              name: 'Air Classifying Equipment',
              tradeId: '44 53 51',
            },
            {
              id: '3242ac81-8920-491c-9c89-a86591987a0e',
              name: 'Air Knives',
              tradeId: '44 53 52',
            },
            {
              id: '18fe9a00-d6b4-413e-acb4-59658c5d536a',
              name: 'Optical Sorting Equipment',
              tradeId: '44 53 53',
            },
            {
              id: 'b283339f-b0c9-4bbb-b133-b75d1bc93c08',
              name: 'Packaged Sorting Stations',
              tradeId: '44 53 56',
            },
            {
              id: 'cea2f30c-c994-434d-8d0d-9e5d9da77a57',
              name: 'Packaged Reclaimer Stations',
              tradeId: '44 53 59',
            },
            {
              id: '2cb60242-24a3-434d-b9a0-62284256b66a',
              name: 'Solid Waste Compactors',
              tradeId: '44 53 61',
            },
            {
              id: 'ff44f601-6842-4edc-9393-e07899845801',
              name: 'Solid Waste Baling Equipment',
              tradeId: '44 53 63',
            },
            {
              id: '22bf5baa-ea67-4279-bffb-b273a7c126f2',
              name: 'Bagging Equipment',
              tradeId: '44 53 64',
            },
            {
              id: 'fff263b8-f657-411c-ad9c-0b94abfdb0e7',
              name: 'Wrappers',
              tradeId: '44 53 66',
            },
            {
              id: '578c8f44-fc50-47ef-af40-2f837ff0dec0',
              name: 'Solid Waste Liquid Extraction Equipment',
              tradeId: '44 53 73',
            },
          ],
          tradeId: '44 53 00',
        },
        {
          id: 'b7ebd630-2c96-46e2-8ab7-3ff9dba2c967',
          name: 'Composting Equipment',
          children: [
            {
              id: '9025bfdb-05f1-4546-a974-227368cfcc8a',
              name: 'Composting Equipment',
              tradeId: '44 55 00',
            },
            {
              id: '2ea4774d-fe06-4323-a067-ed8147cd2c82',
              name: 'Electromechanical Composters',
              tradeId: '44 55 11',
            },
            {
              id: '2e4429b7-0f0a-40b2-bb54-8b089806840f',
              name: 'Compost Screening Equipment',
              tradeId: '44 55 13',
            },
            {
              id: '7b9cb6d3-d29d-487a-95e5-f260d1ecdf52',
              name: 'Compost Shredding Equipment',
              tradeId: '44 55 16',
            },
            {
              id: '0a46c75e-d758-40b6-ac3c-e72adffdf16b',
              name: 'Compost Pile Turning Equipment',
              tradeId: '44 55 23',
            },
            {
              id: 'c4a5128c-68f3-4829-9f9f-71908d406e5f',
              name: 'Compost Mixing and Blending Equipment',
              tradeId: '44 55 26',
            },
            {
              id: 'e2b1349f-b3a2-474b-b6e8-1d4bd27cc53b',
              name: 'In-vessel Composing Equipment',
              tradeId: '44 55 33',
            },
            {
              id: 'd7c05fec-f592-41b5-970b-7ba938e68ced',
              name: 'Compost Pad System',
              tradeId: '44 55 43',
            },
            {
              id: '87ff89f9-e7ca-46e6-962a-e8d02adb4528',
              name: 'Compost Liquid Extraction Equipment',
              tradeId: '44 55 73',
            },
          ],
          tradeId: '44 55 00',
        },
        {
          id: '2c6fe803-4380-4e38-b16a-97f947fa64af',
          name: 'Waste Thermal Processing Equipment',
          children: [],
          tradeId: '44 60 00',
        },
        {
          id: '649c3def-010e-4ebf-b699-c74344a8cf25',
          name: 'Waste-to-Energy Plants',
          children: [
            {
              id: 'd738a4c9-5663-491a-a365-934b34a7efbe',
              name: 'Waste-to-Energy Plants',
              tradeId: '44 61 00',
            },
            {
              id: '2f5247d0-00aa-4944-8306-72ed4978c34e',
              name: 'Waste Receiving, Management and Feed Equipment',
              tradeId: '44 61 13',
            },
            {
              id: 'c7aac731-4e70-4958-ae1b-41b6dc41f9b4',
              name: 'Mass Burn Combustion Equipment',
              tradeId: '44 61 16',
            },
            {
              id: 'f8974d5c-9b90-4932-a16f-1c96d8965500',
              name: 'Refuse-derived Fuel Feed Equipment',
              tradeId: '44 61 23',
            },
            {
              id: '3c1e98ac-87c4-4640-aa3e-b9fec94d4add',
              name: 'Refuse-derived Fuel Combustion Equipment',
              tradeId: '44 61 26',
            },
            {
              id: '1dd80a92-bf12-4c4f-a906-5700e4603c38',
              name: 'Modular Combustion System Equipment',
              tradeId: '44 61 29',
            },
            {
              id: '68da06d0-24f4-49e3-b0fa-c8eb6920e3e5',
              name: 'Ash Handling Equipment',
              tradeId: '44 61 31',
            },
            {
              id: 'c1920bd0-6a04-405f-90fa-cc9c34a43865',
              name: 'Post-combustion Ferrous Metals Recovery Equipment',
              tradeId: '44 61 33',
            },
            {
              id: '0fe6de55-90ec-4ce4-a804-89058145fafe',
              name: 'Post-combustion Non-ferrous Metals Recovery Equipment',
              tradeId: '44 61 36',
            },
          ],
          tradeId: '44 61 00',
        },
        {
          id: '6c8a1936-d666-40c2-8b96-805e50535224',
          name: 'Fluidized Bed Combustion Equipment',
          children: [],
          tradeId: '44 62 00',
        },
        {
          id: '34d1d554-e216-4cd4-ba56-c77d868f4ee0',
          name: 'Rotary Kiln Incinerators',
          children: [],
          tradeId: '44 63 00',
        },
        {
          id: '762aaff2-c66a-4dc3-a4d6-5f9490b65cda',
          name: 'Gasification Equipment',
          children: [
            {
              id: 'b9c86bb2-ce6c-4184-bc33-b32fffe887f8',
              name: 'Gasification Equipment',
              tradeId: '44 64 00',
            },
            {
              id: 'bd450b00-cdae-4186-83af-34c3092e12d3',
              name: 'Counter-current Fixed Bed Gasification Equipment',
              tradeId: '44 64 13',
            },
            {
              id: '2b87565a-47d7-475c-bceb-3739ddcfb380',
              name: 'Co-current Fixed Bed Gasification Equipment',
              tradeId: '44 64 23',
            },
            {
              id: '0bbd46a9-aacb-4103-bcf3-eb461a9a9f4d',
              name: 'Fluidized Bed Gasification Equipment',
              tradeId: '44 64 33',
            },
            {
              id: 'c8c8d414-d221-4b85-a5e0-3bbff9deaf30',
              name: 'Entrained Flow Gasification Equipment',
              tradeId: '44 64 43',
            },
            {
              id: '39e5d862-4cdf-4352-aff0-11f108549949',
              name: 'Plasma Arc Gasification Equipment',
              tradeId: '44 64 53',
            },
          ],
          tradeId: '44 64 00',
        },
        {
          id: 'fa1224fd-3855-4bfd-a5c8-ec70ee142c4b',
          name: 'Pyrolysis Equipment',
          children: [],
          tradeId: '44 65 00',
        },
        {
          id: 'ad00a30b-2597-461e-a31a-33f2bd752726',
          name: 'Hazardous Waste and Medical Waste Incinerators',
          children: [],
          tradeId: '44 66 00',
        },
        {
          id: '269a5656-312e-43c6-a9f5-6f812c675f7d',
          name: 'Heat Recovery Equipment for Waste Thermal Processing',
          children: [],
          tradeId: '44 67 00',
        },
        {
          id: '45d329c0-9112-456b-ae4a-f4507b785206',
          name: 'Synthesis Gas Cleanup and Handling Equipment',
          children: [],
          tradeId: '44 68 00',
        },
      ],
      tradeId: '44 00 00',
    },
    {
      id: 'c85b30d9-183e-45b9-a4ea-4e92e20d439c',
      name: 'Industry-Specific Manufacturing Equipment',
      children: [
        {
          id: 'f7248088-8303-49f6-a504-f754406b77ef',
          name: 'Industry-Specific Manufacturing Equipment',
          children: [],
          tradeId: '45 00 00',
        },
        {
          id: 'bc49560d-495d-449c-a57c-1d9cbe05faaa',
          name: 'Commissioning of Industry-Specific Manufacturing Equipment',
          children: [],
          tradeId: '45 08 00',
        },
        {
          id: 'a68ccbfa-00a0-4baf-aa1a-dd0e8a56afaf',
          name: 'Oil and Gas Extraction Equipment',
          children: [
            {
              id: '48380b9a-e2b2-462e-a2d2-e435f884393a',
              name: 'Oil and Gas Extraction Equipment',
              tradeId: '45 11 00',
            },
            {
              id: 'e4e52626-e7ab-425b-b390-5ce993c9efb6',
              name: 'Operation and Maintenance of Oil and Gas Extraction Equipment',
              tradeId: '45 11 01',
            },
            {
              id: '4d2e51c9-54bf-4593-9ec1-426401f9da81',
              name: 'Schedules for Oil and Gas Extraction Equipment',
              tradeId: '45 11 06',
            },
            {
              id: '80633498-92df-4be1-8563-31a1273176ef',
              name: 'User-Defined Oil and Gas Extraction Equipment',
              tradeId: '45 11 10',
            },
          ],
          tradeId: '45 11 00',
        },
        {
          id: 'f7bb2ea4-6739-44f0-bef9-1e8d54c0c7b2',
          name: 'Mining Machinery and Equipment',
          children: [
            {
              id: 'dd021e8e-8c14-4b41-ac62-1ea81567d448',
              name: 'Mining Machinery and Equipment',
              tradeId: '45 13 00',
            },
            {
              id: 'b58c54e3-b67e-4e19-8486-379fb8c9ffd0',
              name: 'Operation and Maintenance of Mining Machinery and Equipment',
              tradeId: '45 13 01',
            },
            {
              id: '20ac825b-92fd-49aa-90d3-6480b775ed08',
              name: 'Schedules for Mining Machinery and Equipment',
              tradeId: '45 13 06',
            },
            {
              id: 'b57428d1-b2fa-4c48-99a4-b15731480b7d',
              name: 'User-Defined Mining Machinery and Equipment',
              tradeId: '45 13 10',
            },
          ],
          tradeId: '45 13 00',
        },
        {
          id: '1205bdaf-0016-4e8d-a3b3-89c656ac346b',
          name: 'Food Manufacturing Equipment',
          children: [
            {
              id: '4b5ad41e-2a56-42f4-afba-27bfa9cd4848',
              name: 'Food Manufacturing Equipment',
              tradeId: '45 15 00',
            },
            {
              id: 'aecf4e9d-4c20-44c3-ac5a-7af2086f5212',
              name: 'Operation and Maintenance of Food Manufacturing Equipment',
              tradeId: '45 15 01',
            },
            {
              id: '5b48a927-3db3-4b8c-82c5-575f50f858db',
              name: 'Schedules for Food Manufacturing Equipment',
              tradeId: '45 15 06',
            },
            {
              id: '80132ad8-b9ed-48b5-91ef-45d452123ba0',
              name: 'User-Defined Food Manufacturing Equipment',
              tradeId: '45 15 10',
            },
          ],
          tradeId: '45 15 00',
        },
        {
          id: '286eddaf-fda3-4d76-b55d-209466787f32',
          name: 'Beverage and Tobacco Manufacturing Equipment',
          children: [
            {
              id: 'ecde7b75-752b-4a6a-9c3b-2d9e4dce8db5',
              name: 'Beverage and Tobacco Manufacturing Equipment',
              tradeId: '45 17 00',
            },
            {
              id: 'c0c40fed-33ac-4d55-957d-f082c46d3753',
              name: 'Operation and Maintenance of Beverage and Tobacco Manufacturing Equipment',
              tradeId: '45 17 01',
            },
            {
              id: 'be34fe56-9b32-404b-b00b-f3343db5858f',
              name: 'Schedules for Beverage and Tobacco Manufacturing Equipment',
              tradeId: '45 17 06',
            },
            {
              id: 'efc0dede-0745-473b-841d-d3939917e767',
              name: 'User-Defined Beverage and Tobacco Manufacturing Equipment',
              tradeId: '45 17 10',
            },
          ],
          tradeId: '45 17 00',
        },
        {
          id: '7d53f2c1-c5cc-4b8c-9f3a-6d66b84e3f7d',
          name: 'Textiles and Apparel Manufacturing Equipment',
          children: [
            {
              id: '14404be2-496e-4d32-abfb-0469838f76f2',
              name: 'Textiles and Apparel Manufacturing Equipment',
              tradeId: '45 19 00',
            },
            {
              id: 'ac0e36c6-60b7-43d5-a974-341feae328f1',
              name: 'Operation and Maintenance of Textiles and Apparel Manufacturing Equipment',
              tradeId: '45 19 01',
            },
            {
              id: '17830551-333a-4937-9b72-1aef5fe09713',
              name: 'Schedules for Textiles and Apparel Manufacturing Equipment',
              tradeId: '45 19 06',
            },
            {
              id: '3e5c11a9-5572-4c68-9cc0-764c20c6acfc',
              name: 'User-Defined Textiles and Apparel Manufacturing Equipment',
              tradeId: '45 19 10',
            },
          ],
          tradeId: '45 19 00',
        },
        {
          id: '860f9d5b-4614-4986-8a3b-6fd5b4ec5a93',
          name: 'Leather and Allied Product Manufacturing Equipment',
          children: [
            {
              id: '194acba9-900c-479b-9714-20e77a29820b',
              name: 'Leather and Allied Product Manufacturing Equipment',
              tradeId: '45 21 00',
            },
            {
              id: '8883db0e-6e87-4dc0-9bcb-5a2c67c34d1e',
              name: 'Operation and Maintenance of Leather and Allied Product Manufacturing Equipment',
              tradeId: '45 21 01',
            },
            {
              id: 'd2198c9a-cd2d-4869-bc55-49e7fb643655',
              name: 'Schedules for Leather and Allied Product Manufacturing Equipment',
              tradeId: '45 21 06',
            },
            {
              id: '8c46732f-33ef-4bee-8d3f-85587f8f2f66',
              name: 'User-Defined Leather and Allied Product Manufacturing Equipment',
              tradeId: '45 21 10',
            },
          ],
          tradeId: '45 21 00',
        },
        {
          id: '797276a4-c3bd-4673-b978-c9923445a4a1',
          name: 'Wood Product Manufacturing Equipment',
          children: [
            {
              id: 'cc39d9a2-ea73-4414-87f4-8101cab8aa43',
              name: 'Wood Product Manufacturing Equipment',
              tradeId: '45 23 00',
            },
            {
              id: '1d9bf8e7-9701-40c4-a5be-cadf52eee604',
              name: 'Operation and Maintenance of Wood Product Manufacturing Equipment',
              tradeId: '45 23 01',
            },
            {
              id: 'ac27b52b-8434-464c-a373-d07e39693e30',
              name: 'Schedules for Wood Product Manufacturing Equipment',
              tradeId: '45 23 06',
            },
            {
              id: '3781329b-f35b-4568-90c5-ef7a06ddbadc',
              name: 'User-Defined Wood Product Manufacturing Equipment',
              tradeId: '45 23 10',
            },
          ],
          tradeId: '45 23 00',
        },
        {
          id: 'b63c60b2-f817-462b-b2af-b9fd1e1e40ab',
          name: 'Paper Manufacturing Equipment',
          children: [
            {
              id: '4d4e82db-083d-4523-96a3-cd22fdab69fe',
              name: 'Paper Manufacturing Equipment',
              tradeId: '45 25 00',
            },
            {
              id: '049c8c52-76f8-46a0-8d50-00101bfabfc3',
              name: 'Operation and Maintenance of Paper Manufacturing Equipment',
              tradeId: '45 25 01',
            },
            {
              id: 'd7ca2fe1-4edc-4c40-81c1-d7e255419a11',
              name: 'Schedules for Paper Manufacturing Equipment',
              tradeId: '45 25 06',
            },
            {
              id: 'a90b791d-5cb4-4e78-bf30-db017966cec7',
              name: 'User-Defined Paper Manufacturing Equipment',
              tradeId: '45 25 10',
            },
          ],
          tradeId: '45 25 00',
        },
        {
          id: '9cb4175d-3731-420c-b4e7-9696e0e983f4',
          name: 'Printing and Related Manufacturing Equipment',
          children: [
            {
              id: '3fba0de2-13d5-4b90-9839-129274c7c486',
              name: 'Printing and Related Manufacturing Equipment',
              tradeId: '45 27 00',
            },
            {
              id: 'e866b067-10a8-4424-bd06-14a292397628',
              name: 'Operation and Maintenance of Printing and Related Manufacturing Equipment',
              tradeId: '45 27 01',
            },
            {
              id: 'eee11428-d34b-4189-b3bc-6987f52c52e7',
              name: 'Schedules for Printing and Related Manufacturing Equipment',
              tradeId: '45 27 06',
            },
            {
              id: '6253cd20-9e5a-41c2-8f13-b6a12fbeb8b2',
              name: 'User-Defined Printing and Related Manufacturing Equipment',
              tradeId: '45 27 10',
            },
          ],
          tradeId: '45 27 00',
        },
        {
          id: 'f4aff8f8-cd34-44c7-8680-423590695bcb',
          name: 'Petroleum and Coal Products Manufacturing Equipment',
          children: [
            {
              id: 'eb50e905-685a-4402-884b-938337cc6fdc',
              name: 'Petroleum and Coal Products Manufacturing Equipment',
              tradeId: '45 29 00',
            },
            {
              id: '968c54e3-569d-4116-8883-20aeb2c58f6c',
              name: 'Operation and Maintenance of Petroleum and Coal Products Manufacturing Equipment',
              tradeId: '45 29 01',
            },
            {
              id: '99ce6c7c-72aa-429f-9a69-2c8408a066b7',
              name: 'Schedules for Petroleum and Coal Products Manufacturing Equipment',
              tradeId: '45 29 06',
            },
            {
              id: '9796a320-7c9c-4439-b06a-c74fa25b4147',
              name: 'User-Defined Petroleum and Coal Products Manufacturing Equipment',
              tradeId: '45 29 10',
            },
          ],
          tradeId: '45 29 00',
        },
        {
          id: '9e68b05c-b814-468c-8b06-9688904c3018',
          name: 'Chemical Manufacturing Equipment',
          children: [
            {
              id: '3246805a-e52f-4b85-8668-ba18950f9807',
              name: 'Chemical Manufacturing Equipment',
              tradeId: '45 31 00',
            },
            {
              id: '61c85b06-27a8-4913-a1cb-348299f67e69',
              name: 'Operation and Maintenance of Chemical Manufacturing Equipment',
              tradeId: '45 31 01',
            },
            {
              id: 'f0ee889f-1730-41d3-8b52-0efa2004b3bc',
              name: 'Schedules for Chemical Manufacturing Equipment',
              tradeId: '45 31 06',
            },
            {
              id: '516f4d5b-e3e4-4f62-a73a-5999c44b3ffb',
              name: 'User-Defined Chemical Manufacturing Equipment',
              tradeId: '45 31 10',
            },
          ],
          tradeId: '45 31 00',
        },
        {
          id: '509418f9-95b6-4781-ad72-88310d2b14e6',
          name: 'Plastics and Rubber Manufacturing Equipment',
          children: [
            {
              id: 'ebbb4d0a-0e93-4602-8bb6-499860ec4385',
              name: 'Plastics and Rubber Manufacturing Equipment',
              tradeId: '45 33 00',
            },
            {
              id: 'c5a85b08-6964-4c6e-ae2d-8bd31e87b27b',
              name: 'Operation and Maintenance of Plastics and Rubber Manufacturing Equipment',
              tradeId: '45 33 01',
            },
            {
              id: '5357d1d2-d5e9-4731-a69a-f852ae2fb45b',
              name: 'Schedules for Plastics and Rubber Manufacturing Equipment',
              tradeId: '45 33 06',
            },
            {
              id: 'e907d4be-b406-48f2-8ba0-756d830539bd',
              name: 'User-Defined Plastics and Rubber Manufacturing Equipment',
              tradeId: '45 33 10',
            },
          ],
          tradeId: '45 33 00',
        },
        {
          id: '9a883b20-92fa-4281-9ee1-c204348153ec',
          name: 'Nonmetallic Mineral Product Manufacturing Equipment',
          children: [
            {
              id: '140a96c9-3282-4006-bac3-ec23feb36524',
              name: 'Nonmetallic Mineral Product Manufacturing Equipment',
              tradeId: '45 35 00',
            },
            {
              id: '5bcac26e-9321-4aad-8df2-6e5e2396f42a',
              name: 'Operation and Maintenance of Nonmetallic Mineral Product Manufacturing Equipment',
              tradeId: '45 35 01',
            },
            {
              id: '16cd88bb-0448-4c90-8581-6bc055428d81',
              name: 'Schedules for Nonmetallic Mineral Product Manufacturing Equipment',
              tradeId: '45 35 06',
            },
            {
              id: '3cd9f7d8-ca5a-4f35-8fba-9a7dc5cf0159',
              name: 'User-Defined Nonmetallic Mineral Product Manufacturing Equipment',
              tradeId: '45 35 10',
            },
          ],
          tradeId: '45 35 00',
        },
        {
          id: 'c0e17e39-91c0-47c8-b03b-8705416b1e56',
          name: 'Primary Metal Manufacturing Equipment',
          children: [
            {
              id: '13b6be5b-a373-4a14-aacf-62ea45920258',
              name: 'Primary Metal Manufacturing Equipment',
              tradeId: '45 37 00',
            },
            {
              id: '62c9cb04-6876-473b-93d1-d8af0831a1fc',
              name: 'Operation and Maintenance of Primary Metal Manufacturing Equipment',
              tradeId: '45 37 01',
            },
            {
              id: '3d22a89b-49e2-4bc5-838b-5aa34420b395',
              name: 'Schedules for Primary Metal Manufacturing Equipment',
              tradeId: '45 37 06',
            },
            {
              id: '258eb65a-d69e-4433-8b2b-c46b13508cad',
              name: 'User-Defined Primary Metal Manufacturing Equipment',
              tradeId: '45 37 10',
            },
          ],
          tradeId: '45 37 00',
        },
        {
          id: '3057538d-efe1-4cf0-8d4f-f2811e8f6eae',
          name: 'Fabricated Metal Product Manufacturing Equipment',
          children: [
            {
              id: '31fc1a1e-248b-4e20-8d73-e27b0add9573',
              name: 'Fabricated Metal Product Manufacturing Equipment',
              tradeId: '45 39 00',
            },
            {
              id: '54d75a7a-73f3-44b4-9dc2-6d259eaeeb7b',
              name: 'Operation and Maintenance of Fabricated Metal Product Manufacturing Equipment',
              tradeId: '45 39 01',
            },
            {
              id: '5ccdb19b-efd2-4800-be94-2b31afa22324',
              name: 'Schedules for Fabricated Metal Product Manufacturing Equipment',
              tradeId: '45 39 06',
            },
            {
              id: 'a27c740f-eb56-4d5a-9e47-ea32532427c8',
              name: 'User-Defined Fabricated Metal Product Manufacturing Equipment',
              tradeId: '45 39 10',
            },
          ],
          tradeId: '45 39 00',
        },
        {
          id: 'a1224c9f-cb3d-4b3e-b1a8-85c8957c9382',
          name: 'Machinery Manufacturing Equipment',
          children: [
            {
              id: 'af5d2d63-0866-42fd-8e9e-85df9359782a',
              name: 'Machinery Manufacturing Equipment',
              tradeId: '45 41 00',
            },
            {
              id: 'bcdcb591-556e-4460-ba4d-b360b8f45a87',
              name: 'Operation and Maintenance of Machinery Manufacturing Equipment',
              tradeId: '45 41 01',
            },
            {
              id: 'cf04e965-dddc-4eb2-9533-ec79b2e84167',
              name: 'Schedules for Machinery Manufacturing Equipment',
              tradeId: '45 41 06',
            },
            {
              id: 'fc179fbd-cc2f-493f-acdc-70a598c4d1aa',
              name: 'User-Defined Machinery Manufacturing Equipment',
              tradeId: '45 41 10',
            },
          ],
          tradeId: '45 41 00',
        },
        {
          id: '53e0096a-bca3-44b1-84c7-1ec70db35234',
          name: 'Computer and Electronic Product Manufacturing Equipment',
          children: [
            {
              id: 'cc064ccd-739a-456e-9ae7-2cbd4ab005bf',
              name: 'Computer and Electronic Product Manufacturing Equipment',
              tradeId: '45 43 00',
            },
            {
              id: '668cc45b-2b09-4dbc-aae7-e6f47c9f0036',
              name: 'Operation and Maintenance of Computer and Electronic Product Manufacturing Equipment',
              tradeId: '45 43 01',
            },
            {
              id: 'a63a3520-84c2-400f-a4c2-91d7a0cb521e',
              name: 'Schedules for Computer and Electronic Product Manufacturing Equipment',
              tradeId: '45 43 06',
            },
            {
              id: '8a5a7820-96f0-44d1-8d36-84aa52f36c9d',
              name: 'User-Defined Computer and Electronic Product Manufacturing Equipment',
              tradeId: '45 43 10',
            },
          ],
          tradeId: '45 43 00',
        },
        {
          id: '49418774-4f66-4f76-8b8b-aeeb3a649028',
          name: 'Electrical Equipment, Appliance and Component Manufacturing Equipment',
          children: [
            {
              id: '170d0c65-3e20-4a4f-91be-d9c518eed11c',
              name: 'Electrical Equipment, Appliance and Component Manufacturing Equipment',
              tradeId: '45 45 00',
            },
            {
              id: '5292c1c3-e289-4098-b631-1baa22dc7860',
              name: 'Operation and Maintenance of Electrical Equipment, Appliance and Component Manufacturing Equipment',
              tradeId: '45 45 01',
            },
            {
              id: '3fb70562-f9a9-49c3-b5b1-ced8bdf25cd2',
              name: 'Schedules for Electrical Equipment, Appliance and Component Manufacturing Equipment',
              tradeId: '45 45 06',
            },
            {
              id: '3408f523-df6e-4093-b8fc-569cc61adef2',
              name: 'User-Defined Electrical Equipment, Appliance and Component Manufacturing Equipment',
              tradeId: '45 45 10',
            },
          ],
          tradeId: '45 45 00',
        },
        {
          id: 'da2d6d77-3860-4f49-9af8-7ac8c19dbac3',
          name: 'Transportation Manufacturing Equipment',
          children: [
            {
              id: '19cde5b5-5746-4dd1-a3cc-ce041a55215c',
              name: 'Transportation Manufacturing Equipment',
              tradeId: '45 47 00',
            },
            {
              id: '71d45b99-6748-4515-87ef-fd5287ff4e7a',
              name: 'Operation and Maintenance of Transportation Manufacturing Equipment',
              tradeId: '45 47 01',
            },
            {
              id: '25b1fc3b-7ede-444f-958a-8a130495dbc0',
              name: 'Schedules for Transportation Manufacturing Equipment',
              tradeId: '45 47 06',
            },
            {
              id: '5e25237e-4d2e-4c07-8a6b-14636cf635d2',
              name: 'User-Defined Transportation Manufacturing Equipment',
              tradeId: '45 47 10',
            },
          ],
          tradeId: '45 47 00',
        },
        {
          id: '55724b55-bec9-4326-9027-c2943629c3ce',
          name: 'Furniture and Related Product Manufacturing Equipment',
          children: [
            {
              id: 'c1dee7cc-82af-4a87-b181-11d178976b2b',
              name: 'Furniture and Related Product Manufacturing Equipment',
              tradeId: '45 49 00',
            },
            {
              id: '28684823-e901-4f96-baa4-b6ea83905093',
              name: 'Operation and Maintenance of Furniture and Related Product Manufacturing Equipment',
              tradeId: '45 49 01',
            },
            {
              id: 'a9b2f789-8580-4da4-8392-4d2cc914b8e7',
              name: 'Schedules for Furniture and Related Product Manufacturing Equipment',
              tradeId: '45 49 06',
            },
            {
              id: '80d0e453-7d82-4391-8f11-32e84111afa5',
              name: 'User-Defined Furniture and Related Product Manufacturing Equipment',
              tradeId: '45 49 10',
            },
          ],
          tradeId: '45 49 00',
        },
        {
          id: '7579437e-d647-4a26-b4d0-6d4c10e5d443',
          name: 'Other Manufacturing Equipment',
          children: [
            {
              id: '62d68589-f615-43ae-ab03-0c35948993a9',
              name: 'Other Manufacturing Equipment',
              tradeId: '45 51 00',
            },
            {
              id: 'a59cc695-af44-4b73-9f47-935dd7543f56',
              name: 'Operation and Maintenance of Other Manufacturing Equipment',
              tradeId: '45 51 01',
            },
            {
              id: '4bafff63-c4a5-4d0e-8fa7-74704fc45544',
              name: 'Schedules for Other Manufacturing Equipment',
              tradeId: '45 51 06',
            },
            {
              id: '9091e353-86f7-4a96-bbeb-f86c9788954e',
              name: 'User-Defined Other Manufacturing Equipment',
              tradeId: '45 51 10',
            },
          ],
          tradeId: '45 51 00',
        },
      ],
      tradeId: '45 00 00',
    },
    {
      id: 'aad96087-57ff-435a-b00c-ef93c6a6d390',
      name: 'Water and Wastewater Equipment',
      children: [
        {
          id: 'a959ae20-4afa-4239-bf18-4efc5047ce81',
          name: 'Water and Wastewater Equipment',
          children: [],
          tradeId: '46 00 00',
        },
        {
          id: '4af0b49f-4c3a-4517-aadf-34b52c058dc3',
          name: 'Operation and Maintenance of Water and Wastewater Equipment',
          children: [
            {
              id: '75b6a0a5-b027-4a91-8a09-13b6368140cb',
              name: 'Operation and Maintenance of Water and Wastewater Equipment',
              tradeId: '46 01 00',
            },
            {
              id: '4e9d09db-c6b5-4636-aa50-30d7588458ff',
              name: 'Operation and Maintenance of Packaged Treatment Equipment',
              tradeId: '46 01 07',
            },
            {
              id: 'd43f72d1-53e0-47bd-a420-84349e14a45e',
              name: 'Operation and Maintenance of Preliminary Treatment Equipment',
              tradeId: '46 01 20',
            },
            {
              id: '775c2473-d1ed-4e75-9a93-2d2a64433d1b',
              name: 'Operation and Maintenance of Chemical Feed Equipment',
              tradeId: '46 01 30',
            },
            {
              id: '9a46b4cf-31a2-4980-ae6c-018336e6c12e',
              name: 'Operation and Maintenance of Clarification and Mixing Equipment',
              tradeId: '46 01 40',
            },
            {
              id: 'd80b57ab-ccc9-4cad-8416-98d656d061da',
              name: 'Operation and Maintenance of Secondary Treatment Equipment',
              tradeId: '46 01 50',
            },
            {
              id: 'e4ec2037-285c-4389-834d-9220eb4f5525',
              name: 'Operation and Maintenance of Advanced Treatment Equipment',
              tradeId: '46 01 60',
            },
            {
              id: '43a49736-1b70-4244-bd71-7c7d177afc6c',
              name: 'Operation and Maintenance of Residuals Handling and Treatment',
              tradeId: '46 01 70',
            },
          ],
          tradeId: '46 01 00',
        },
        {
          id: '4f0d594e-0482-44d3-b6e8-737e6611c695',
          name: 'Common Work Results for Water and Wastewater Equipment',
          children: [],
          tradeId: '46 05 00',
        },
        {
          id: '5555be11-f057-4b76-8790-f66cf2851bad',
          name: 'Schedules for Water and Wastewater Equipment',
          children: [
            {
              id: '3c0f546b-9b67-4375-aa21-ce2a162e4ffa',
              name: 'Schedules for Water and Wastewater Equipment',
              tradeId: '46 06 00',
            },
            {
              id: 'a9fdd7b3-7234-4275-b03f-42a4634d83b2',
              name: 'Schedules for Packaged Treatment Equipment',
              tradeId: '46 06 07',
            },
            {
              id: 'c857e169-a6db-4d79-8b9b-256541366599',
              name: 'Schedules for Preliminary Treatment Equipment',
              tradeId: '46 06 20',
            },
            {
              id: '4073a564-dc1a-4db1-bafa-ea9d06ce3476',
              name: 'Schedules for Chemical Feed Equipment',
              tradeId: '46 06 30',
            },
            {
              id: 'ab7b533d-f7f4-41f3-82c0-221004569df9',
              name: 'Schedules for Clarification and Mixing Equipment',
              tradeId: '46 06 40',
            },
            {
              id: 'c0d6fc03-30a8-44d1-8026-bb7175798a2c',
              name: 'Schedules for Secondary Treatment Equipment',
              tradeId: '46 06 50',
            },
            {
              id: 'a03daf73-a481-4d1c-81d3-0560c2991f47',
              name: 'Schedules for Advanced Treatment Equipment',
              tradeId: '46 06 60',
            },
            {
              id: 'a10c685b-7817-4e4d-96ee-ce616e132298',
              name: 'Schedules for Residuals Handling and Treatment',
              tradeId: '46 06 70',
            },
          ],
          tradeId: '46 06 00',
        },
        {
          id: '723446d0-90ba-46c2-899b-f7627f8038b8',
          name: 'Packaged Water and Wastewater Treatment Equipment',
          children: [
            {
              id: '7c4dd522-bcb0-4906-9ed6-5fdef1bb1b05',
              name: 'Packaged Water and Wastewater Treatment Equipment',
              tradeId: '46 07 00',
            },
            {
              id: '77b31487-18c7-4c3b-a90a-542e2b96f2a8',
              name: 'Packaged Water Treatment Equipment',
              tradeId: '46 07 13',
            },
            {
              id: 'dc2d4497-5b02-433e-ae4b-59be2837d56c',
              name: 'Packaged Wastewater Treatment Equipment',
              tradeId: '46 07 53',
            },
          ],
          tradeId: '46 07 00',
        },
        {
          id: 'bc3dc5f8-b406-40f5-98f9-128b60405058',
          name: 'Commissioning of Water and Wastewater Equipment',
          children: [
            {
              id: 'b7806b31-96be-4288-821e-d37b4004189f',
              name: 'Commissioning of Water and Wastewater Equipment',
              tradeId: '46 08 00',
            },
            {
              id: '7960f244-6fae-47ab-ab3a-b8e6ad379a5a',
              name: 'Commissioning of Packaged Treatment Equipment',
              tradeId: '46 08 07',
            },
            {
              id: '43c18cae-71e6-4d97-9fcf-6cdb755b65ce',
              name: 'Commissioning of Preliminary Treatment Equipment',
              tradeId: '46 08 20',
            },
            {
              id: 'a946f880-9154-4ce9-8f79-bc34b46cfcb5',
              name: 'Commissioning of Chemical Feed Equipment',
              tradeId: '46 08 30',
            },
            {
              id: '0397d808-a06f-4d43-9812-49b304994b9d',
              name: 'Commissioning of Clarification and Mixing Equipment',
              tradeId: '46 08 40',
            },
            {
              id: '04f33f88-bf53-459c-a188-45de53ec8276',
              name: 'Commissioning of Secondary Treatment Equipment',
              tradeId: '46 08 50',
            },
            {
              id: '2c981b2e-0fc4-4a33-bcb8-8dece0f5d5a2',
              name: 'Commissioning of Advanced Treatment Equipment',
              tradeId: '46 08 60',
            },
            {
              id: 'c18194e5-4cfb-4e72-8f9c-55ec667d978f',
              name: 'Commissioning of Residuals Handling and Treatment',
              tradeId: '46 08 70',
            },
          ],
          tradeId: '46 08 00',
        },
        {
          id: '061c73ab-4b54-4f12-ba8c-2bcf5c22c8fc',
          name: 'Water and Wastewater Preliminary Treatment Equipment',
          children: [],
          tradeId: '46 20 00',
        },
        {
          id: 'ebd315f2-71c1-4163-8555-ab8889d38ca1',
          name: 'Screening Equipment',
          children: [
            {
              id: 'ec6dd23e-84cc-49c8-a761-9f679f6e9dce',
              name: 'Screening Equipment',
              tradeId: '46 21 00',
            },
            {
              id: 'f72224e8-8566-43ed-88be-f87bf658c139',
              name: 'Climber-type Bar Screens',
              tradeId: '46 21 11',
            },
            {
              id: 'ccfd7750-5616-44d8-8036-6220d7340af6',
              name: 'Chain-and-Rake Bar Screens',
              tradeId: '46 21 13',
            },
            {
              id: 'b10f78b6-3499-41ad-b90d-cdc3ad31e07a',
              name: 'Flexible Rake Bar Screens',
              tradeId: '46 21 16',
            },
            {
              id: '57935272-07b2-4a51-9613-5413d3d719eb',
              name: 'Catenary Bar Screens',
              tradeId: '46 21 17',
            },
            {
              id: '77f183f9-f970-4e5b-ad78-09f020f1ff4d',
              name: 'Continuous Belt Screens',
              tradeId: '46 21 19',
            },
            {
              id: 'cd741187-1d4a-4c88-bfce-174b8b8da1ab',
              name: 'Cylindrical Bar Screens',
              tradeId: '46 21 23',
            },
            {
              id: '505a6c92-c076-4620-84f2-aabfcb0ba630',
              name: 'Step Screens',
              tradeId: '46 21 26',
            },
            {
              id: 'd73b4430-c502-4108-8f28-47d147e110d4',
              name: 'Rotary Drum Screens',
              tradeId: '46 21 33',
            },
            {
              id: '23d2c408-62a4-4617-b1b9-5b7c1a31f7cf',
              name: 'Spiral Screens',
              tradeId: '46 21 39',
            },
            {
              id: '91fa4792-9fbd-4a1e-b588-950c1aaa8d6d',
              name: 'Band Screens',
              tradeId: '46 21 43',
            },
            {
              id: 'e1e703dc-6e34-499d-b34c-283f6f4d0c39',
              name: 'Disc Screens',
              tradeId: '46 21 46',
            },
            {
              id: '52cf509b-7476-4ee6-baa8-2e2700b90f16',
              name: 'Traveling Water Screens',
              tradeId: '46 21 51',
            },
            {
              id: 'e998d0dc-ce98-44df-add5-6d95de957a39',
              name: 'Perforated Plate Screens',
              tradeId: '46 21 53',
            },
            {
              id: '950d8eb2-b1f0-460e-b9e3-3e8435afcb55',
              name: 'Wedge Wire Screens',
              tradeId: '46 21 56',
            },
            {
              id: '768e478e-0fa3-4c05-93af-b7373ee04728',
              name: 'Element Screens',
              tradeId: '46 21 57',
            },
            {
              id: '5dcef905-eb6f-4033-a3c3-7997df5d2350',
              name: 'Trash Raking Equipment',
              tradeId: '46 21 71',
            },
            {
              id: 'e65573c6-1b5c-4216-9ba6-491c8759d883',
              name: 'Screenings Washing and Compacting Equipment',
              tradeId: '46 21 73',
            },
            {
              id: '035ac8e0-d380-4c07-8ec0-310cdf4bcae0',
              name: 'Vacuum Screenings Conveying Equipment',
              tradeId: '46 21 76',
            },
            {
              id: '8858fc5b-5e05-4ba6-845c-c182d314e442',
              name: 'Screenings Storage Containers',
              tradeId: '46 21 79',
            },
            {
              id: '4c159910-9782-4d6e-b265-22de31de3411',
              name: 'Septage Receiving Equipment',
              tradeId: '46 21 83',
            },
          ],
          tradeId: '46 21 00',
        },
        {
          id: 'e1af4a3c-b8bf-4baf-8ff5-166bd474a4db',
          name: 'Grit Removal and Handling Equipment',
          children: [
            {
              id: '3fecc85f-acc6-4cd2-8efb-70c2e9b5b7d5',
              name: 'Grit Removal and Handling Equipment',
              tradeId: '46 23 00',
            },
            {
              id: 'bc927ece-8774-42c5-b205-821eed1484e5',
              name: 'Chain-and-Bucket Grit Removal Equipment',
              tradeId: '46 23 13',
            },
            {
              id: '7f5325a3-a31f-4e0c-be1d-28a7859a1e59',
              name: 'Chain-and-Flight Grit Removal Equipment',
              tradeId: '46 23 16',
            },
            {
              id: '306be764-098a-4312-b327-ad2bdb5c6a39',
              name: 'Vortex Grit Removal Equipment',
              tradeId: '46 23 23',
            },
            {
              id: '57081fe6-dad9-4aed-9d05-2e2c75d1ada2',
              name: 'Cyclone Degritters',
              tradeId: '46 23 27',
            },
            {
              id: '7616d453-4c53-42bf-a64d-608d7baad8f4',
              name: 'Aerated Grit Removal Equipment',
              tradeId: '46 23 33',
            },
            {
              id: '3e22eef0-192a-4ba3-8105-ef3e22688e09',
              name: 'Inline Baffled Grit Removal Equipment',
              tradeId: '46 23 43',
            },
            {
              id: 'b307313b-968a-45bb-8e66-8117e86a5a69',
              name: 'Traveling Bridge Grit Removal Equipment',
              tradeId: '46 23 53',
            },
            {
              id: '9fc1401d-9710-4d71-b872-ac14cb933aa1',
              name: 'Grit Classifying and Washing Equipment',
              tradeId: '46 23 63',
            },
            {
              id: '44091bf5-a571-45be-8544-73d8348ce8b3',
              name: 'Grit Storage Containers',
              tradeId: '46 23 66',
            },
          ],
          tradeId: '46 23 00',
        },
        {
          id: '16c4e83a-c511-4722-b61c-8ca6951be83e',
          name: 'Grinding and Shredding Equipment',
          children: [
            {
              id: '60e25698-f6fb-4e49-a2c8-02170cf805ca',
              name: 'Grinding and Shredding Equipment',
              tradeId: '46 24 00',
            },
            {
              id: 'ab209a95-4b3e-4f9c-aa90-d973627d4f4e',
              name: 'Macerators',
              tradeId: '46 24 13',
            },
            {
              id: 'd455650e-a1d4-4c39-8a62-a69340c0d3e3',
              name: 'Comminutors',
              tradeId: '46 24 16',
            },
            {
              id: '01463938-b740-4866-b607-46a5883bd958',
              name: 'Inline Grinders',
              tradeId: '46 24 23',
            },
            {
              id: 'd2bc3092-a757-4e12-adf6-778c421c97cc',
              name: 'Open-channel Grinders',
              tradeId: '46 24 33',
            },
            {
              id: '98efffc3-ae64-4511-a006-530d3b082d44',
              name: 'Modular Grinding-Screening-Compacting Equipment',
              tradeId: '46 24 36',
            },
          ],
          tradeId: '46 24 00',
        },
        {
          id: '0fd094b8-42b5-4e21-ba56-b2afcdb8d96f',
          name: 'Oil and Grease Separation and Removal Equipment',
          children: [
            {
              id: '87265df3-f210-4622-b53b-8f82a7044e63',
              name: 'Oil and Grease Separation and Removal Equipment',
              tradeId: '46 25 00',
            },
            {
              id: '0971275a-277b-4cea-86d7-a1a1dfbd8247',
              name: 'Coalescing Oil-Water Separators',
              tradeId: '46 25 13',
            },
            {
              id: '3008c52f-ee71-45f8-aa7f-98f808335675',
              name: 'API Oil-Water Separators',
              tradeId: '46 25 16',
            },
            {
              id: 'ed60d1f7-ef89-4e02-8b33-2a3aa7525053',
              name: 'Grease Traps',
              tradeId: '46 25 23',
            },
            {
              id: 'ade4192b-f5ce-4db0-afa0-bd5a6844dfa3',
              name: 'Dissolved Air Flotation Grease and Oil Separation Equipment',
              tradeId: '46 25 33',
            },
            {
              id: '52671b83-b403-459b-b842-780b505e5d27',
              name: 'Helical Scum Skimming and Removal Equipment',
              tradeId: '46 25 41',
            },
            {
              id: 'ee981563-cb77-4805-b518-cd4db5ebd3ea',
              name: 'Tipping Trough Scum Skimming and Removal Equipment',
              tradeId: '46 25 43',
            },
            {
              id: '372ad0b0-9b0c-4fb0-adb1-57c056edc6dc',
              name: 'Chain and Flight Scum Collection and Removal Equipment',
              tradeId: '46 25 46',
            },
            {
              id: 'f0856046-f0a6-4208-be44-1a512d4e7fc4',
              name: 'Floating Scum Skimming and Removal Equipment',
              tradeId: '46 25 47',
            },
          ],
          tradeId: '46 25 00',
        },
        {
          id: '5d7b4232-ebd9-459c-adce-0d8935e17c7f',
          name: 'Water and Wastewater Chemical Feed Equipment',
          children: [],
          tradeId: '46 30 00',
        },
        {
          id: '53511024-3694-4a46-a987-4c38153afd5c',
          name: 'Gas Chemical Feed Equipment',
          children: [
            {
              id: 'b5016c1e-decb-4a87-a285-14ba6d182087',
              name: 'Gas Chemical Feed Equipment',
              tradeId: '46 31 00',
            },
            {
              id: '2c977e92-3e70-4b19-868e-14cde0222e97',
              name: 'Chlorine Gas Feed Equipment',
              tradeId: '46 31 11',
            },
            {
              id: 'a760ae42-6f6b-4143-a8db-14c72d32b9f7',
              name: 'Sulfur Dioxide Gas Feed Equipment',
              tradeId: '46 31 13',
            },
            {
              id: 'd7582475-731b-41c5-83cd-fe42fea931d5',
              name: 'Ammonia Gas Feed Equipment',
              tradeId: '46 31 16',
            },
            {
              id: 'e92daaed-384d-4c65-9088-cc0ab4aff770',
              name: 'Gas Storage, Weighing and Leak Detection Equipment',
              tradeId: '46 31 23',
            },
            {
              id: '85d9dd5f-bb41-4a92-9143-cbdb8ff16b32',
              name: 'Emergency Gas Treatment Systems',
              tradeId: '46 31 26',
            },
            {
              id: '6b013688-fd81-4d8c-9b04-4aeb54a3f222',
              name: 'Chlorine Dioxide Reactors',
              tradeId: '46 31 33',
            },
            {
              id: '076ae935-3373-4753-8444-415f7cf2e1c7',
              name: 'Carbon Dioxide Gas Feed Equipment',
              tradeId: '46 31 43',
            },
            {
              id: '41a963a3-336f-4243-a36e-85eb3c9ed054',
              name: 'Ozone Generating and Feed Equipment',
              tradeId: '46 31 53',
            },
            {
              id: '8c9788a2-882f-41b2-9ed6-f4f9bc088f26',
              name: 'Liquid Oxygen Storage and Feed Equipment',
              tradeId: '46 31 56',
            },
            {
              id: 'f89eb72c-66d3-4eed-a574-79edcad8a634',
              name: 'Cleaning Requirements for Oxygen Service',
              tradeId: '46 31 59',
            },
            {
              id: 'd61ded14-4f23-4c1e-9a69-c4dcf30c4e41',
              name: 'Gas Chemical Feed Accessories and Safety Equipment',
              tradeId: '46 31 83',
            },
          ],
          tradeId: '46 31 00',
        },
        {
          id: '60e69ab6-1e43-49fc-9d79-ee31c594162e',
          name: 'Liquid Chemical Feed Equipment',
          children: [
            {
              id: 'a1a9aada-5d23-4f40-8483-fc9d15cf37e6',
              name: 'Liquid Chemical Feed Equipment',
              tradeId: '46 33 00',
            },
            {
              id: 'ce9869ff-10ef-4a86-aa75-0b5f76ad08e7',
              name: 'Sodium Hypochlorite Generating Equipment',
              tradeId: '46 33 13',
            },
            {
              id: 'cd5aac12-4a8f-44a6-9db4-87dda412d727',
              name: 'Liquid Chemical Weighing Equipment',
              tradeId: '46 33 23',
            },
            {
              id: '10a85193-dfe3-4e95-bb0e-860a6cf56f52',
              name: 'Polymer Blending and Feed Equipment',
              tradeId: '46 33 33',
            },
            {
              id: '1ab8f0ca-19f9-4eb8-a04f-ebe646dd8943',
              name: 'Liquid Chemical Feed System Coordination and Integration',
              tradeId: '46 33 41',
            },
            {
              id: '7c6b9c04-40e2-4ece-aeae-dc73f5b12f97',
              name: 'Diaphragm-type Metering Pumps',
              tradeId: '46 33 42',
            },
            {
              id: '38719332-a146-4548-a115-b14b18fb0c7d',
              name: 'Peristaltic Metering Pumps',
              tradeId: '46 33 44',
            },
            {
              id: '95a2e5ee-6173-4833-98cd-cf29b974bacf',
              name: 'Progressing Cavity Metering Pumps',
              tradeId: '46 33 46',
            },
            {
              id: '9d69fde0-53e7-4f50-9860-e4bd1c2b94b4',
              name: 'Lobe Metering Pumps',
              tradeId: '46 33 48',
            },
            {
              id: '0d5b77a0-6259-4e0c-83fa-487c25e1b192',
              name: 'Drum Pumps',
              tradeId: '46 33 53',
            },
            {
              id: 'dd21e9fe-f182-4e49-839b-7c21a384b420',
              name: 'Liquid Chemical Transfer Pumps',
              tradeId: '46 33 66',
            },
            {
              id: '664da1cc-9068-463a-8e90-69015c7db8b9',
              name: 'Liquid Chemical Diffusers',
              tradeId: '46 33 73',
            },
            {
              id: '4f1ae2d4-3ace-447a-a9eb-9803ceed3aed',
              name: 'Liquid Chemical Feed Accessories and Safety Equipment',
              tradeId: '46 33 83',
            },
          ],
          tradeId: '46 33 00',
        },
        {
          id: '97b834ac-1d6b-4788-8b45-5091add6c5fc',
          name: 'Dry Chemical Feed Equipment',
          children: [
            {
              id: '9e100284-0a9b-485a-99c3-4aac9db76147',
              name: 'Dry Chemical Feed Equipment',
              tradeId: '46 36 00',
            },
            {
              id: '4a17ba39-fa51-48b3-b616-332e4dc12f90',
              name: 'Dry Chemical Feed System Coordination and Integration',
              tradeId: '46 36 11',
            },
            {
              id: '2227fe48-852d-47f9-957c-4d5640f41cca',
              name: 'Storage Silos',
              tradeId: '46 36 13',
            },
            {
              id: '5ecdf82a-cae4-4011-aa6d-e44995b922a6',
              name: 'Dry Chemical Weighing Equipment',
              tradeId: '46 36 23',
            },
            {
              id: '818ea64b-4068-489d-b8a0-d8678aa1bf5b',
              name: 'Volumetric Feed Equipment',
              tradeId: '46 36 33',
            },
            {
              id: 'a3e7b066-c093-4639-a018-ee05fff034c2',
              name: 'Gravimetric Feed Equipment',
              tradeId: '46 36 36',
            },
            {
              id: '4960bbe5-9472-48b7-86c8-2563908f71dd',
              name: 'Lime Slaking Equipment',
              tradeId: '46 36 43',
            },
            {
              id: '1f49c799-3242-464f-96c2-b4a3bb30a8c8',
              name: 'Chemical Tablet Feeding Equipment',
              tradeId: '46 36 53',
            },
            {
              id: 'f2f938a4-2b7d-4dd7-8988-2889bd616fb2',
              name: 'Dry Chemical Feed Accessories and Safety Equipment',
              tradeId: '46 36 83',
            },
          ],
          tradeId: '46 36 00',
        },
        {
          id: '04c377a5-803b-40a2-9d1a-fe856844a123',
          name: 'Water and Wastewater Clarification and Mixing Equipment',
          children: [],
          tradeId: '46 40 00',
        },
        {
          id: '043931cb-09ba-4908-98bb-7e561be88094',
          name: 'Mixing Equipment',
          children: [
            {
              id: '7dc49d1a-315e-4991-b108-4ace86425512',
              name: 'Mixing Equipment',
              tradeId: '46 41 00',
            },
            {
              id: 'd8f04aa2-f3ac-4154-812f-a1d9c0061070',
              name: 'Rapid Mixers',
              tradeId: '46 41 11',
            },
            {
              id: '7c379588-63ea-48c2-b012-780e415185a4',
              name: 'Inline Blender-type Rapid Mixers',
              tradeId: '46 41 13',
            },
            {
              id: '778c7f12-28e3-4701-a535-23209598d611',
              name: 'Induction-type Rapid Mixing Equipment',
              tradeId: '46 41 16',
            },
            {
              id: 'e1a2029e-55bf-43d8-a0e2-314311d0b591',
              name: 'Inline Static Mixers',
              tradeId: '46 41 17',
            },
            {
              id: '4e081597-0f77-40e2-a5e2-3765d2be1f5d',
              name: 'Jet Mixing Equipment',
              tradeId: '46 41 21',
            },
            {
              id: 'dcd8a79e-4559-4e03-a3b4-af014ba9e0ad',
              name: 'Submersible Mixers',
              tradeId: '46 41 23',
            },
            {
              id: 'e903bca5-1d11-476b-8f87-7be1a0c5b622',
              name: 'Floating Mechanical Mixers',
              tradeId: '46 41 26',
            },
            {
              id: '4a5093fe-3537-43b5-9f01-aadf08e4948c',
              name: 'Paddle Mixers',
              tradeId: '46 41 27',
            },
            {
              id: 'a8a0ad37-46d6-415d-a77b-1ebe9a257c79',
              name: 'Pin Mixers',
              tradeId: '46 41 29',
            },
            {
              id: 'e4c623be-d5c0-473c-b338-bb73fe1ecd8f',
              name: 'Vertical Reel Flocculation Equipment',
              tradeId: '46 41 31',
            },
            {
              id: 'aab0c3f1-4a79-4c15-aa93-fcc7414a8e0b',
              name: 'Horizontal Reel Flocculation Equipment',
              tradeId: '46 41 33',
            },
            {
              id: 'bf329da8-d4ba-4538-802e-f8198d9f0f9a',
              name: 'Vertical Turbine Flocculation Equipment',
              tradeId: '46 41 34',
            },
            {
              id: 'b8507fbc-1d0d-4f21-9fdb-24000d7e0b20',
              name: 'Walking-beam Flocculation Equipment',
              tradeId: '46 41 36',
            },
            {
              id: '18065ad1-16af-43c8-ab4d-3aba6e49d2df',
              name: 'Horizontal Oscillating Flocculation Equipment',
              tradeId: '46 41 38',
            },
            {
              id: '10c0e6f2-f931-4c73-ab16-31822982890f',
              name: 'Top-entering Tank Mixers',
              tradeId: '46 41 41',
            },
            {
              id: 'c59911da-dec8-4392-91d5-97e3fee2ee73',
              name: 'Side-entry Tank Mixers',
              tradeId: '46 41 43',
            },
            {
              id: '08333022-c98e-47c5-a31b-7915d782fa53',
              name: 'Portable Tank Mixers',
              tradeId: '46 41 46',
            },
          ],
          tradeId: '46 41 00',
        },
        {
          id: 'c288abdc-90ce-4935-a01e-3b0ef0c6792d',
          name: 'Clarifier Equipment',
          children: [
            {
              id: '4ec14111-c967-4475-ae4a-701b641d8ae9',
              name: 'Clarifier Equipment',
              tradeId: '46 43 00',
            },
            {
              id: '36147114-fa2f-4a8e-a0a3-c01df0dc74e5',
              name: 'Chain-and-Flight Clarifier Equipment',
              tradeId: '46 43 11',
            },
            {
              id: 'f3342368-9c15-4162-b21a-6ba790942447',
              name: 'Traveling Bridge Clarifier Equipment',
              tradeId: '46 43 14',
            },
            {
              id: '456473ed-1b80-4883-bc0a-af220209dd68',
              name: 'Differential Head Clarifier Equipment',
              tradeId: '46 43 16',
            },
            {
              id: 'fff1f006-1f8d-4da2-b9fa-5ecedabcdd12',
              name: 'Oscillating Scraper-type Clarifier Equipment',
              tradeId: '46 43 18',
            },
            {
              id: '155501f8-df33-46ce-96c7-d7488985709e',
              name: 'Circular Clarifier Equipment',
              tradeId: '46 43 21',
            },
            {
              id: '3f403bb0-4471-4f84-9b0d-53bb7ccfae34',
              name: 'Solids Contact Clarifier Equipment',
              tradeId: '46 43 53',
            },
            {
              id: 'd185f675-14a1-43d9-8301-e5725e912f89',
              name: 'Flocculating Clarifier, Pulsating Sludge Blanket Type',
              tradeId: '46 43 61',
            },
            {
              id: 'e6e09b1a-850f-4b4a-8f37-36cde777f59a',
              name: 'Dissolved Air Flotation Equipment for Water Treatment',
              tradeId: '46 43 63',
            },
            {
              id: '1e196750-4256-438e-8f56-fc44fdf2725e',
              name: 'Ballasted High-rate Clarifier Equipment',
              tradeId: '46 43 66',
            },
            {
              id: '3d8265d9-ce90-48dd-b718-2f5a69288b79',
              name: 'High rate Clarification/Thickening Equipment',
              tradeId: '46 43 67',
            },
            {
              id: 'e9b317eb-dc14-43d5-9fa8-07b6669d0d69',
              name: 'Tube Settlers',
              tradeId: '46 43 73',
            },
            {
              id: '9715cc2e-a3ba-486f-a7bc-d63c58da2c85',
              name: 'Inclined Plate Settlers',
              tradeId: '46 43 76',
            },
          ],
          tradeId: '46 43 00',
        },
        {
          id: '1bd30204-0a24-4920-9ce3-279a9e3ebcdc',
          name: 'Sediment Removal Equipment',
          children: [
            {
              id: '1624dd4f-668a-40fc-b7ab-181ea40d4eeb',
              name: 'Sediment Removal Equipment',
              tradeId: '46 46 00',
            },
            {
              id: '2b3de9b3-bba6-4214-8de9-51a5ac02a9c0',
              name: 'Tipping Sediment Flushing Tanks',
              tradeId: '46 46 13',
            },
            {
              id: '6155e200-c33c-4c36-92c7-4ce072a36f98',
              name: 'Flushing Gates',
              tradeId: '46 46 16',
            },
            {
              id: '8c6c53fe-d19c-4d4b-865e-d983d11f0b16',
              name: 'Water Cannon',
              tradeId: '46 46 23',
            },
            {
              id: 'b39989e9-40d5-49ac-91e4-bba64aff3f73',
              name: 'Nozzle Systems',
              tradeId: '46 46 26',
            },
          ],
          tradeId: '46 46 00',
        },
        {
          id: '60421935-c749-4533-a4f8-3fa8c3caa5d5',
          name: 'Water and Wastewater Secondary Treatment Equipment',
          children: [],
          tradeId: '46 50 00',
        },
        {
          id: '2de98fcb-b84b-4bc6-9090-26ae5975590e',
          name: 'Air and Gas Diffusion Equipment',
          children: [
            {
              id: 'c034283c-520b-43d6-9d49-7954655d2afa',
              name: 'Air and Gas Diffusion Equipment',
              tradeId: '46 51 00',
            },
            {
              id: '2692b00f-734c-4e38-82dc-6f734da2a687',
              name: 'Fixed Mechanical Aerators',
              tradeId: '46 51 11',
            },
            {
              id: '961f847a-1643-4ed2-852b-0b57f5b21009',
              name: 'Floating Mechanical Aerators',
              tradeId: '46 51 13',
            },
            {
              id: '0d7ce56f-17f4-455f-8ec6-f164fc7e81b0',
              name: 'Submersible Aspirating Aerator Equipment',
              tradeId: '46 51 16',
            },
            {
              id: 'a4d6f209-73a9-4040-96ab-c359cd9a8d51',
              name: 'Jet Aeration Equipment',
              tradeId: '46 51 17',
            },
            {
              id: '202f42af-82ed-46f4-ab5f-53967ffa74e0',
              name: 'Coarse Bubble Diffusers',
              tradeId: '46 51 21',
            },
            {
              id: '9c711a19-f915-429d-a596-d230e254ba5b',
              name: 'Swing-type Channel Aeration Equipment',
              tradeId: '46 51 23',
            },
            {
              id: 'ef7a8a8e-f442-45ea-b0c6-9b3fbd1fd459',
              name: 'Shear Box Diffusers',
              tradeId: '46 51 26',
            },
            {
              id: '66fe3b14-fe93-4be1-bb0f-d9cec09def54',
              name: 'Flexible Membrane Tube Diffusers',
              tradeId: '46 51 31',
            },
            {
              id: 'c80c7280-9ae0-4d29-a8e4-046f5966c437',
              name: 'Flexible Membrane Disc Diffusers',
              tradeId: '46 51 33',
            },
            {
              id: '5ead81e1-f861-4978-924d-c0bb1a918315',
              name: 'Ceramic Disc Fine Bubble Diffusers',
              tradeId: '46 51 36',
            },
            {
              id: '38fc9a63-5356-4f46-b071-ab9d8d718e4c',
              name: 'Floating Membrane Diffusers',
              tradeId: '46 51 43',
            },
            {
              id: '9abf25ff-b98c-4892-af0d-081ea6d5adfb',
              name: 'Membrane Diffusers',
              tradeId: '46 51 46',
            },
            {
              id: 'c2552880-f4be-4609-877b-f32922b885a0',
              name: 'Cascading Aerators',
              tradeId: '46 51 53',
            },
            {
              id: '46b43d41-9d4f-48d0-9db7-39513edaa9fd',
              name: 'Pure-oxygen Generating Equipment',
              tradeId: '46 51 63',
            },
          ],
          tradeId: '46 51 00',
        },
        {
          id: '84477798-4918-42c8-bd8a-5f2ef68e7ed7',
          name: 'Biological Treatment Systems',
          children: [
            {
              id: '9833fa1b-d85b-461e-9fb1-bcd84ddc0bdd',
              name: 'Biological Treatment Systems',
              tradeId: '46 53 00',
            },
            {
              id: 'ca7ccec6-0a79-484a-b88d-3172e6d43e94',
              name: 'Rotating Biological Contactors',
              tradeId: '46 53 13',
            },
            {
              id: '6eee5b50-b700-4627-8d8c-429f4eb40ede',
              name: 'Trickling Filter Rotary Distributor Equipment',
              tradeId: '46 53 23',
            },
            {
              id: 'debbfb3d-25cb-4e84-9c07-5c1ac521970c',
              name: 'Trickling Filter Media',
              tradeId: '46 53 24',
            },
            {
              id: '476f0647-d64f-449e-8154-a541c1c97f53',
              name: 'Bio-towers',
              tradeId: '46 53 26',
            },
            {
              id: 'ccfb9e33-cb9c-4c5f-9ca8-2af973caf223',
              name: 'Moving-bed Biological Reactors',
              tradeId: '46 53 33',
            },
            {
              id: '027171f6-1ce3-437f-9cc4-879a4d5b6919',
              name: 'Integrated Fixed-film Activated Sludge Equipment',
              tradeId: '46 53 36',
            },
            {
              id: '5f596e43-420a-4d14-bc73-80afc19c74a5',
              name: 'Intermittent Sand Filters for Wastewater Treatment',
              tradeId: '46 53 41',
            },
            {
              id: '58325a06-c9f2-40f6-a6e7-86bc5d95e92d',
              name: 'Deep-bed Denitrification Filters',
              tradeId: '46 53 43',
            },
            {
              id: 'adcadb14-d48b-44f5-8dce-75ebad795f98',
              name: 'Biologically Activated Filters',
              tradeId: '46 53 46',
            },
            {
              id: 'b85a7934-f6b2-4888-9b48-e0d3ea88c55d',
              name: 'Membrane Biological Reactors',
              tradeId: '46 53 49',
            },
            {
              id: '48e64346-62f1-4c99-b1eb-9d4def22d41a',
              name: 'Sequencing Batch Reactors',
              tradeId: '46 53 53',
            },
            {
              id: '246284f8-d20c-4df7-83ae-0b4140da4d92',
              name: 'Oxidation Ditch Equipment',
              tradeId: '46 53 61',
            },
            {
              id: 'dec39262-2a91-497e-b262-0a61b11809b0',
              name: 'Vertical Loop Reactors',
              tradeId: '46 53 63',
            },
          ],
          tradeId: '46 53 00',
        },
        {
          id: 'b051babc-1c98-4975-986d-1e6b188b72d4',
          name: 'Water and Wastewater Advanced Treatment Equipment',
          children: [],
          tradeId: '46 60 00',
        },
        {
          id: '721a8233-88b6-410b-811c-0a03b8534d3f',
          name: 'Filtration Equipment',
          children: [
            {
              id: '3414900b-a68c-4955-9087-1311397fa3d1',
              name: 'Filtration Equipment',
              tradeId: '46 61 00',
            },
            {
              id: '8907c28c-b242-4d1d-9cca-fdc2e55826b9',
              name: 'Filter Media',
              tradeId: '46 61 13',
            },
            {
              id: '4e35a440-1812-43f2-9f8f-61b048ee15c8',
              name: 'Filter Surface Wash Agitators',
              tradeId: '46 61 16',
            },
            {
              id: '4a973ae4-a858-4d01-880a-5c3fb550e995',
              name: 'Filter Air Scour Equipment',
              tradeId: '46 61 17',
            },
            {
              id: 'a0edfe00-1d7d-486f-86ee-08929772765c',
              name: 'Wash Water Troughs',
              tradeId: '46 61 19',
            },
            {
              id: 'd0b16d57-f5cc-4b86-9cf9-8ccdf7ad83af',
              name: 'Pressure Filters',
              tradeId: '46 61 21',
            },
            {
              id: '3a8c22eb-1920-4e4f-873c-b87ba3f3b966',
              name: 'Gravity Filters',
              tradeId: '46 61 23',
            },
            {
              id: '3c495dd9-937e-4ac3-81e2-2f51840e8247',
              name: 'High-rate Sand Filters',
              tradeId: '46 61 26',
            },
            {
              id: 'e0696c76-19d8-4dd8-a62c-c2115c77ea90',
              name: 'Traveling Bridge Filters',
              tradeId: '46 61 29',
            },
            {
              id: 'e166b596-4784-45e2-b448-b5752a041731',
              name: 'Microfiltration and Ultrafiltration Membrane Equipment',
              tradeId: '46 61 33',
            },
            {
              id: 'bc00a276-933a-46f8-8d1a-ecae438fb43f',
              name: 'Disc Cloth Filters',
              tradeId: '46 61 41',
            },
            {
              id: '97adb3e3-6dd6-471f-a303-a03025695f2e',
              name: 'Rotary Drum Cloth Filters',
              tradeId: '46 61 43',
            },
            {
              id: 'a07e609e-9e9a-4a60-9fa9-a4b885532c1a',
              name: 'Automatic Backwash Cloth Filter Equipment',
              tradeId: '46 61 46',
            },
            {
              id: 'eb6a654c-9957-4735-947e-0088c7e61a70',
              name: 'Cartridge Filters',
              tradeId: '46 61 53',
            },
            {
              id: '0d781e41-d96b-4723-b384-9803b40912a8',
              name: 'Bag Filters',
              tradeId: '46 61 63',
            },
            {
              id: 'dc92f353-84b9-4d6b-b452-f78d761a103f',
              name: 'Automatic Straining Equipment',
              tradeId: '46 61 73',
            },
          ],
          tradeId: '46 61 00',
        },
        {
          id: '2babb0db-9715-4e68-b470-faee0eb83f7b',
          name: 'Demineralization Equipment',
          children: [
            {
              id: '9c272360-6ef4-4f2d-9c99-699f3395b509',
              name: 'Demineralization Equipment',
              tradeId: '46 63 00',
            },
            {
              id: '27c13c7d-6da5-4b7b-8ff8-79b54d1c191a',
              name: 'Ion-exchange Vessel Media',
              tradeId: '46 63 11',
            },
            {
              id: 'f4a300b7-4a2b-4b19-8762-b6eadfe7e538',
              name: 'Mixed Bed Ion-exchange Vessel Systems',
              tradeId: '46 63 13',
            },
            {
              id: 'e9de6e24-1cb5-46b2-9df2-35fa20cbeb11',
              name: 'Packed Bed Ion-exchange Vessel Systems',
              tradeId: '46 63 16',
            },
            {
              id: 'a7ad2050-1f57-4e97-8236-100d615956ba',
              name: 'Electrodialysis Reversal Equipment',
              tradeId: '46 63 17',
            },
            {
              id: 'c733ce96-0c1c-4c31-85fb-2e742b996346',
              name: 'Reverse-Osmosis and Nanofiltration Membrane Equipment',
              tradeId: '46 63 23',
            },
            {
              id: '4ba5b9a7-5888-4424-a2f8-0e3cdb4b744d',
              name: 'Multiple-effect Distillation Equipment',
              tradeId: '46 63 31',
            },
            {
              id: '504c92a3-9a98-4c2d-8a59-86afcb4eb2d4',
              name: 'Desalination Mechanical Vapor Compression Equipment',
              tradeId: '46 63 33',
            },
            {
              id: 'ee1228be-365c-4e73-a996-30dfc3d58179',
              name: 'Desalination Thermal Vapor Compression Equipment',
              tradeId: '46 63 34',
            },
            {
              id: '4afe79db-3ed7-45d1-a53c-3a1e8013935d',
              name: 'Desalination Multi-stage Flash Equipment',
              tradeId: '46 63 36',
            },
            {
              id: '1afd3f23-6212-41be-a6ac-759e5c439e96',
              name: 'Desalination Falling Film Evaporators',
              tradeId: '46 63 41',
            },
            {
              id: 'e8df0f4d-623a-477e-ad7f-a6ca79a16f9c',
              name: 'Desalination Rising Film Evaporators',
              tradeId: '46 63 43',
            },
            {
              id: '6b35e6ea-8dd1-4017-803b-e2feb5c5b0a5',
              name: 'Desalination Forced-circulation Crystallizing Equipment',
              tradeId: '46 63 53',
            },
            {
              id: '8ee3f846-68df-4d4b-9dd6-27c8aee219fd',
              name: 'Desalination Spray Dry Evaporation Equipment',
              tradeId: '46 63 63',
            },
            {
              id: 'd1248432-4547-439a-af58-33a2bead2373',
              name: 'Demineralization Energy Recovery Equipment',
              tradeId: '46 63 73',
            },
          ],
          tradeId: '46 63 00',
        },
        {
          id: '9936a069-5c75-4081-babd-59ff1fb93314',
          name: 'Ultraviolet Equipment',
          children: [
            {
              id: '1aaac5e0-f0f7-46a5-b757-950a51393670',
              name: 'Ultraviolet Equipment',
              tradeId: '46 66 00',
            },
            {
              id: '5c0d07a1-f563-4df6-a3ff-c48b6a4a6b52',
              name: 'Closed-vessel Low-pressure/Low-intensity Ultraviolet Treatment Equipment',
              tradeId: '46 66 13',
            },
            {
              id: '480756ea-edfb-4836-a2fc-6579893722e1',
              name: 'Closed-vessel Low-pressure/High-intensity Ultraviolet Treatment Equipment',
              tradeId: '46 66 16',
            },
            {
              id: '01c365fc-0f95-4ea6-8ff8-3be552b480e8',
              name: 'Closed-vessel Medium-pressure Ultraviolet Treatment Equipment',
              tradeId: '46 66 23',
            },
            {
              id: '44642d8b-be9f-4d5a-828f-c49ec932b5be',
              name: 'Open-channel Low-pressure/Low-intensity Ultraviolet Treatment Equipment',
              tradeId: '46 66 53',
            },
            {
              id: 'a8eba99a-2717-405e-bb03-86f8b6b0f9c5',
              name: 'Open-channel Low-pressure/High-intensity Ultraviolet Treatment Equipment',
              tradeId: '46 66 56',
            },
            {
              id: '7857b1fe-6e83-492c-af42-cafd70ac1125',
              name: 'Open-channel Medium-pressure Ultraviolet Treatment Equipment',
              tradeId: '46 66 63',
            },
          ],
          tradeId: '46 66 00',
        },
        {
          id: '4033bc9d-218d-46f4-8097-a4c269389e2e',
          name: 'Water and Wastewater Residuals Handling and Treatment',
          children: [],
          tradeId: '46 70 00',
        },
        {
          id: 'a03743a5-7e4f-4b1d-8e1c-5c89c3be0d31',
          name: 'Residuals Thickening Equipment',
          children: [
            {
              id: 'e4f3b26a-794f-48e0-8b1c-a3f3e764aab8',
              name: 'Residuals Thickening Equipment',
              tradeId: '46 71 00',
            },
            {
              id: '2e2cc250-74b7-4f6b-a189-1b20da19fe11',
              name: 'Circular Gravity Thickeners',
              tradeId: '46 71 13',
            },
            {
              id: '13d8bf32-02b7-4ddc-8c9b-0e784add6f55',
              name: 'Gravity Belt Thickeners',
              tradeId: '46 71 16',
            },
            {
              id: '68eb3b37-5d87-4c9b-ac6d-389400937936',
              name: 'Dissolved Air Flotation Thickening Equipment',
              tradeId: '46 71 23',
            },
            {
              id: '6fd0f22f-7dc8-4992-a5a4-ddd9d976e1af',
              name: 'Rotary Drum Thickening Equipment',
              tradeId: '46 71 33',
            },
            {
              id: '9a4519fe-537d-407c-bd1a-fa22a06dd112',
              name: 'Centrifuge Thickening Equipment',
              tradeId: '46 71 36',
            },
            {
              id: '3653a004-d007-4592-b8f6-d15000eb4cc9',
              name: 'Disc Thickeners',
              tradeId: '46 71 43',
            },
            {
              id: '393b3a41-dcbd-45c4-99c7-c5cdeef01e22',
              name: 'Thickening Screw Press',
              tradeId: '46 71 46',
            },
            {
              id: '8e3fe239-8e23-42e9-901e-0b48cc761227',
              name: 'Scum Concentrator Equipment',
              tradeId: '46 71 53',
            },
          ],
          tradeId: '46 71 00',
        },
        {
          id: 'ea9f88d6-8de8-424c-b7b7-a6946ede4c36',
          name: 'Residuals Stabilization',
          children: [
            {
              id: 'fbda30e9-c44b-4513-8d72-61d835821c08',
              name: 'Residuals Stabilization',
              tradeId: '46 73 00',
            },
            {
              id: '4aa4c79c-5639-484f-88c7-7d02f03ed934',
              name: 'Radial Beam Fixed Digester Covers',
              tradeId: '46 73 11',
            },
            {
              id: 'ca97e1b2-1d5d-4b3d-b95b-56721bffd0fb',
              name: 'Dual Deck Truss-type Fixed Digester Covers',
              tradeId: '46 73 12',
            },
            {
              id: '02785d82-622a-4e49-8114-c98485c65b62',
              name: 'Radial Beam Floating Digester Covers',
              tradeId: '46 73 14',
            },
            {
              id: '9b808da4-8309-4a44-b473-ac8b002048b5',
              name: 'Dual Deck Truss-type Floating Digester Covers',
              tradeId: '46 73 16',
            },
            {
              id: '709ad48b-e94a-4435-909e-b84a906848b8',
              name: 'Radial Beam Floating Gas-holding Digester Covers',
              tradeId: '46 73 17',
            },
            {
              id: '17cabb98-7f06-46f7-9063-78095a994f3a',
              name: 'Dual Deck Truss-type Floating Gas-holding Digester Covers',
              tradeId: '46 73 18',
            },
            {
              id: '92229fa7-5c3c-480c-a6ff-0702673d4f50',
              name: 'Digester Appurtenances',
              tradeId: '46 73 19',
            },
            {
              id: '00189896-53a9-41bc-a95a-824bce56e735',
              name: 'Aerobic Digester Aeration Equipment',
              tradeId: '46 73 21',
            },
            {
              id: 'eb3de9bf-fdc6-46b9-b669-48f2a79acfb7',
              name: 'Autothermal Thermophilic Aerobic Digestion Equipment',
              tradeId: '46 73 24',
            },
            {
              id: 'bee22f7d-f6df-4478-b457-8b38da5df3f2',
              name: 'Egg-shaped Digesters',
              tradeId: '46 73 26',
            },
            {
              id: '32367bdb-f0ce-4bf5-b3a1-eb30edb662eb',
              name: 'External Draft Tube Digester Mixing System',
              tradeId: '46 73 31',
            },
            {
              id: '71a1240d-37f9-4718-b6ab-c996723e2d42',
              name: 'Internal Draft Tube Digester Mixing System',
              tradeId: '46 73 32',
            },
            {
              id: '228206e3-9ee1-4ab3-8544-9dabacf65a92',
              name: 'Confined Gas Mixing System',
              tradeId: '46 73 33',
            },
            {
              id: 'c1844768-0d15-424a-9efb-03f6e0bbe9a2',
              name: 'Unconfined Gas Mixing System',
              tradeId: '46 73 34',
            },
            {
              id: '5eff6728-d240-40fb-9b77-45cc60f587b3',
              name: 'Digester Heating Equipment',
              tradeId: '46 73 41',
            },
            {
              id: '38dca5a0-b773-4ec3-8e35-d25ebbd713b6',
              name: 'Residuals Pasteurization Equipment',
              tradeId: '46 73 63',
            },
          ],
          tradeId: '46 73 00',
        },
        {
          id: '11fddba6-bf0b-4b47-9cc8-03dee56cac4a',
          name: 'Residuals Dewatering Equipment',
          children: [
            {
              id: '8528407d-03c3-4b96-8c38-31f9ba6c4fc7',
              name: 'Residuals Dewatering Equipment',
              tradeId: '46 76 00',
            },
            {
              id: 'f8fe6131-bbfa-45ef-bbc8-3e7d3fbd72a0',
              name: 'Vacuum Filters',
              tradeId: '46 76 13',
            },
            {
              id: '41391c52-d9d3-431a-99ed-d65a25c0ff5e',
              name: 'Belt Filter Presses',
              tradeId: '46 76 21',
            },
            {
              id: 'b9ac52b7-8dd7-4da2-b9d9-225072ed4317',
              name: 'Plate-and-Frame Filter Presses',
              tradeId: '46 76 23',
            },
            {
              id: 'cd9c5689-264d-43ab-a0df-6158764da593',
              name: 'Rotary Presses',
              tradeId: '46 76 26',
            },
            {
              id: 'eca6ca8e-6b78-4c00-968c-33c080796ff7',
              name: 'Screw Presses',
              tradeId: '46 76 27',
            },
            {
              id: '7ae5ab80-584a-452e-8765-d2b6ef99a6a4',
              name: 'Dewatering Centrifuges',
              tradeId: '46 76 33',
            },
            {
              id: 'fbe1cf28-2bf6-4232-b7f0-93244c53d29e',
              name: 'Belt Dryers',
              tradeId: '46 76 53',
            },
            {
              id: 'f40c93f3-1762-4b16-b1ba-4c6a65c6c03a',
              name: 'Direct-heat Residuals Drying Equipment',
              tradeId: '46 76 60',
            },
            {
              id: '0995abcb-48ab-4eda-b35e-fb9ff2778451',
              name: 'Indirect-heat Residuals Drying Equipment',
              tradeId: '46 76 70',
            },
          ],
          tradeId: '46 76 00',
        },
        {
          id: 'dcc858d8-ae81-4166-8c64-5c983bf4959a',
          name: 'Thermal Treatment of Residuals',
          children: [
            {
              id: 'c79a543a-e1cf-435f-a44c-cd7654a7d305',
              name: 'Thermal Treatment of Residuals',
              tradeId: '46 78 00',
            },
            {
              id: 'faec84a0-11d5-4b1b-bd1f-7f8c9ed669f8',
              name: 'Multiple-hearth Sludge Incinerators',
              tradeId: '46 78 13',
            },
            {
              id: 'e71ee73a-16c6-41d4-ad30-80e1390461a1',
              name: 'Fluidized-bed Sludge Incinerators',
              tradeId: '46 78 23',
            },
            {
              id: 'd3f9dbe4-e45d-4656-b93b-fc7692b2403b',
              name: 'Ash Handling Equipment',
              tradeId: '46 78 33',
            },
            {
              id: '77dbcca0-4e3b-42bc-8094-e382d4363b38',
              name: 'Recuperative Air Preheating Equipment',
              tradeId: '46 78 41',
            },
            {
              id: '9c3e9914-f3de-40b6-9ed5-f73ecdb96930',
              name: 'Regenerative Thermal Oxidizers',
              tradeId: '46 78 46',
            },
            {
              id: '0f3308de-d1ae-422f-8980-56d504328222',
              name: 'Waste Heat Recovery Boilers',
              tradeId: '46 78 47',
            },
            {
              id: 'c791e0a3-cbc2-4352-add2-35352b68c8eb',
              name: 'Waste Heat Recovery Heat Exchangers',
              tradeId: '46 78 49',
            },
            {
              id: 'ee7c9665-f1f2-45ea-b114-31ff56d0e1e2',
              name: 'Thermal Oxidation Equipment',
              tradeId: '46 78 73',
            },
          ],
          tradeId: '46 78 00',
        },
      ],
      tradeId: '46 00 00',
    },
    {
      id: '8497cec1-38cd-4c60-a24c-230a73fbcd23',
      name: 'Electrical Power Generation',
      children: [
        {
          id: 'accb8586-4eeb-436e-ab04-b3df40317e20',
          name: 'Electrical Power Generation',
          children: [],
          tradeId: '48 00 00',
        },
        {
          id: '707f9ddd-57a7-45f0-bf9c-622bc196a7a9',
          name: 'Operation and Maintenance for Electrical Power Generation',
          children: [
            {
              id: '477a802f-ed5a-4d9b-97fb-fba4097b092f',
              name: 'Operation and Maintenance for Electrical Power Generation',
              tradeId: '48 01 00',
            },
            {
              id: 'c87b330a-9f69-4eee-9ea6-9628ade2eeaa',
              name: 'Operation and Maintenance of Electrical Power Generation Equipment',
              tradeId: '48 01 10',
            },
            {
              id: '7dca4c47-23a8-42d7-b31f-6c882e1f135f',
              name: 'Operation and Maintenance of Electrical Power Generation Testing',
              tradeId: '48 01 70',
            },
          ],
          tradeId: '48 01 00',
        },
        {
          id: '76db7d92-a082-438e-b5a0-7f29cdd7b71d',
          name: 'Common Work Results for Electrical Power Generation',
          children: [],
          tradeId: '48 05 00',
        },
        {
          id: 'a1f9c94c-8188-4601-8bb9-328f544f8577',
          name: 'Schedules for Electrical Power Generation',
          children: [
            {
              id: 'f182fb75-edfd-4b33-a997-2a7ed78b6519',
              name: 'Schedules for Electrical Power Generation',
              tradeId: '48 06 00',
            },
            {
              id: '4d18a310-f0d7-423f-8b58-2443b4f077ae',
              name: 'Schedules for Electrical Power Generation Equipment',
              tradeId: '48 06 10',
            },
            {
              id: '6f4a0ef8-9d1e-4d91-a891-efb98d3cb8e4',
              name: 'Schedules for Electrical Power Generation Testing',
              tradeId: '48 06 70',
            },
          ],
          tradeId: '48 06 00',
        },
        {
          id: 'e5683f7c-04a4-47d5-80a5-0aa43ffb95a9',
          name: 'Commissioning of Electrical Power Generation',
          children: [],
          tradeId: '48 08 00',
        },
        {
          id: '825bc303-5681-477f-9e47-d9d1fda7b3ae',
          name: 'Instrumentation and Control for Electrical Power Generation',
          children: [],
          tradeId: '48 09 00',
        },
        {
          id: 'baa3ed6b-5a98-4680-bfa0-8fbd80f9ce53',
          name: 'Electrical Power Generation Equipment',
          children: [],
          tradeId: '48 10 00',
        },
        {
          id: 'c631ea42-5b81-4373-9511-d783fa86efe8',
          name: 'Fossil Fuel Plant Electrical Power Generation Equipment',
          children: [
            {
              id: '2364bfad-fc82-44c8-86d2-1df470675974',
              name: 'Fossil Fuel Plant Electrical Power Generation Equipment',
              tradeId: '48 11 00',
            },
            {
              id: '6feedcce-f087-41ac-9e22-30ee9de7a6dd',
              name: 'Fossil Fuel Electrical Power Plant Boilers',
              tradeId: '48 11 13',
            },
            {
              id: '571ec0b5-5bb5-4734-92ff-12681ad22ba4',
              name: 'Fossil Fuel Electrical Power Plant Condensers',
              tradeId: '48 11 16',
            },
            {
              id: 'c1070af8-54fc-43f6-a691-f364e6c43461',
              name: 'Fossil Fuel Electrical Power Plant Steam Turbines',
              tradeId: '48 11 19',
            },
            {
              id: 'c13b078a-a0eb-4c8c-b66f-55295e696476',
              name: 'Fossil Fuel Electrical Power Plant Gas Turbines',
              tradeId: '48 11 23',
            },
            {
              id: 'aaa8776d-e8bf-4e0a-837c-8cf8b9f584d2',
              name: 'Fossil Fuel Electrical Power Plant Generators',
              tradeId: '48 11 26',
            },
          ],
          tradeId: '48 11 00',
        },
        {
          id: '8706e28b-1b20-40b6-a152-c47f0f506b87',
          name: 'Nuclear Fuel Plant Electrical Power Generation Equipment',
          children: [
            {
              id: '9eb9f8d3-3f84-4f3f-98a4-6acc1d47f802',
              name: 'Nuclear Fuel Plant Electrical Power Generation Equipment',
              tradeId: '48 12 00',
            },
            {
              id: '0252ec73-b117-42ac-9bb0-632a15d8ccba',
              name: 'Nuclear Fuel Reactors',
              tradeId: '48 12 13',
            },
            {
              id: 'deacf8f4-55ee-4427-b667-dfa2fb661b86',
              name: 'Nuclear Fuel Fission Reactors',
              tradeId: '48 12 13.13',
            },
            {
              id: '1c5d1a3e-7b8b-4c95-970f-00171ecbb95f',
              name: 'Nuclear Fuel Fusion Reactors',
              tradeId: '48 12 13.16',
            },
            {
              id: 'cb26bc52-1e07-4f71-aba6-171753191133',
              name: 'Nuclear Fuel Electrical Power Plant Steam Generators',
              tradeId: '48 12 23',
            },
            {
              id: 'f283c65d-6f2a-488b-b8a6-cd9c7e903675',
              name: 'Nuclear Fuel Electrical Power Plant Condensers',
              tradeId: '48 12 26',
            },
            {
              id: '851b5db8-77b1-4f51-bf9f-65ed19fef769',
              name: 'Nuclear Fuel Electrical Power Plant Turbines',
              tradeId: '48 12 29',
            },
            {
              id: '720f877e-ee55-469b-8150-9f62b71aaee8',
              name: 'Nuclear Fuel Electrical Power Generators',
              tradeId: '48 12 33',
            },
          ],
          tradeId: '48 12 00',
        },
        {
          id: 'e0c0f895-b004-4172-80d4-076f56d4da6f',
          name: 'Hydroelectric Plant Electrical Power Generation Equipment',
          children: [
            {
              id: '18e69035-abb9-407a-a5ba-cb7231869a86',
              name: 'Hydroelectric Plant Electrical Power Generation Equipment',
              tradeId: '48 13 00',
            },
            {
              id: 'ea223e47-0ffa-4a1c-91c2-76c4e3cafb0b',
              name: 'Hydroelectric Power Plant Water Turbines',
              tradeId: '48 13 13',
            },
            {
              id: '083da069-5cda-440c-ba68-3a214012c074',
              name: 'Hydroelectric Power Plant Electrical Power Generators',
              tradeId: '48 13 16',
            },
          ],
          tradeId: '48 13 00',
        },
        {
          id: '402b8163-f1e1-4caa-9944-f15d24fb2280',
          name: 'Solar Energy Electrical Power Generation Equipment',
          children: [
            {
              id: '91b653e1-12cf-4735-8591-2222b71bb574',
              name: 'Solar Energy Electrical Power Generation Equipment',
              tradeId: '48 14 00',
            },
            {
              id: '2e786b60-6b28-4d2d-8f93-54b5b379e274',
              name: 'Solar Energy Collectors',
              tradeId: '48 14 13',
            },
            {
              id: '2c9ed4b6-3dfa-41fc-beba-2578ea716e81',
              name: 'Amorphous Solar Energy Collectors',
              tradeId: '48 14 13.13',
            },
            {
              id: 'ac6e326c-cd8a-4723-adb0-39fade626c8e',
              name: 'Plate Cell Solar Energy Collectors',
              tradeId: '48 14 13.16',
            },
            {
              id: '6bce9934-7343-4fc9-835b-2a36348a433d',
              name: 'Vacuum Tube Solar Energy Collectors',
              tradeId: '48 14 13.19',
            },
          ],
          tradeId: '48 14 00',
        },
        {
          id: '86c485f5-8c90-4493-be56-682cf4d41b96',
          name: 'Wind Energy Electrical Power Generation Equipment',
          children: [
            {
              id: '76d98e9c-dd08-4d05-bc70-a105b59e8f2b',
              name: 'Wind Energy Electrical Power Generation Equipment',
              tradeId: '48 15 00',
            },
            {
              id: '13351c4f-a083-4ec4-aed9-9647873ba72e',
              name: 'Wind Turbines',
              tradeId: '48 15 13',
            },
            {
              id: '064bf245-fe13-46e5-8650-0738910a7dcb',
              name: 'Wind Energy Electrical Power Generators',
              tradeId: '48 15 16',
            },
          ],
          tradeId: '48 15 00',
        },
        {
          id: 'f2f988d6-068e-45bc-a31a-fff3d03ab5bc',
          name: 'Geothermal Energy Electrical Power Generation Equipment',
          children: [
            {
              id: '1bdbffb5-cc2f-4b2f-9aa8-a639d2239fa6',
              name: 'Geothermal Energy Electrical Power Generation Equipment',
              tradeId: '48 16 00',
            },
            {
              id: '141d481d-cfbc-45be-9bfe-4356b2fd2502',
              name: 'Geothermal Energy Heat Pumps',
              tradeId: '48 16 13',
            },
            {
              id: '6dd5424c-6d3e-4cd3-a004-edee955ac916',
              name: 'Geothermal Energy Condensers',
              tradeId: '48 16 16',
            },
            {
              id: 'cb05e69f-c421-49a0-a36f-77a7690e4c34',
              name: 'Geothermal Energy Steam Turbines',
              tradeId: '48 16 19',
            },
            {
              id: 'af81205c-aa73-44c4-847a-fadb578e3ea3',
              name: 'Geothermal Energy Electrical Power Generators',
              tradeId: '48 16 23',
            },
          ],
          tradeId: '48 16 00',
        },
        {
          id: 'a8412183-24e9-4183-abd0-f7e0bcceed33',
          name: 'Electrochemical Energy Electrical Power Generation Equipment',
          children: [
            {
              id: '0b031265-5f90-4b16-811b-90707eae5f57',
              name: 'Electrochemical Energy Electrical Power Generation Equipment',
              tradeId: '48 17 00',
            },
            {
              id: '80043cbb-78ea-4a5f-a852-60582bbb9bc9',
              name: 'Electrical Power Generation Batteries',
              tradeId: '48 17 13',
            },
          ],
          tradeId: '48 17 00',
        },
        {
          id: '9e97c0b2-5ba8-4729-ad0b-5878277b6d36',
          name: 'Fuel Cell Electrical Power Generation Equipment',
          children: [
            {
              id: '804da2bd-fa84-476e-a83b-e1ba2d577e55',
              name: 'Fuel Cell Electrical Power Generation Equipment',
              tradeId: '48 18 00',
            },
            {
              id: 'ba3556fc-c515-4cf5-968b-14d2fae26610',
              name: 'Electrical Power Generation Fuel Cells',
              tradeId: '48 18 13',
            },
            {
              id: 'f1d10d02-5a3c-46ad-aba3-eaa3f2da34c0',
              name: 'Hydrogen Control Equipment',
              tradeId: '48 18 16',
            },
          ],
          tradeId: '48 18 00',
        },
        {
          id: '07faf9e8-b389-40c8-b190-150581618d14',
          name: 'Electrical Power Control Equipment',
          children: [
            {
              id: '50c691f9-fccd-452b-be9f-e9c870dd01b9',
              name: 'Electrical Power Control Equipment',
              tradeId: '48 19 00',
            },
            {
              id: '609a7228-a201-4acb-8ec6-dc406e2f39ee',
              name: 'Electrical Power Generation Battery Charging Equipment',
              tradeId: '48 19 13',
            },
            {
              id: '87cf77e3-7110-438f-b333-f9a263eb9e74',
              name: 'Electrical Power Generation Inverters',
              tradeId: '48 19 16',
            },
            {
              id: 'f93d5b1f-57df-4794-b334-9d428d077bf9',
              name: 'Electrical Power Generation Solar Tracking Equipment',
              tradeId: '48 19 19',
            },
            {
              id: '3d69e667-56e3-4190-9595-76c80c0f95c4',
              name: 'Electrical Power Generation Transformers',
              tradeId: '48 19 23',
            },
            {
              id: '430f5b75-37b5-4f82-8406-8910026a7134',
              name: 'Electrical Power Generation Voltage Regulators',
              tradeId: '48 19 26',
            },
          ],
          tradeId: '48 19 00',
        },
        {
          id: '9eb8ca75-1511-42f6-8059-367d27843a50',
          name: 'Electrical Power Generation Testing',
          children: [],
          tradeId: '48 70 00',
        },
        {
          id: '3448c97f-c476-418c-be6c-becabdb92bef',
          name: 'Electrical Power Generation Test Equipment',
          children: [
            {
              id: 'cd7818fd-5bb8-49ec-baec-09508acb0e45',
              name: 'Electrical Power Generation Test Equipment',
              tradeId: '48 71 00',
            },
            {
              id: 'b7b09e1f-66a3-4d84-9fbe-d645a5f5d311',
              name: 'Electrical Power Generation Corona Test Equipment',
              tradeId: '48 71 13',
            },
            {
              id: 'cee037cd-aa48-4ad4-92db-ff5331856f5f',
              name: 'Electrical Power Generation Current Test Equipment',
              tradeId: '48 71 16',
            },
            {
              id: '7793ce1a-c0cc-4184-827b-d0a86c9ae920',
              name: 'Electrical Power Generation Power Test Equipment',
              tradeId: '48 71 19',
            },
            {
              id: '6a9256f6-57f9-4ff5-abff-3116ca82095f',
              name: 'Electrical Power Generation Resistance Test Equipment',
              tradeId: '48 71 23',
            },
            {
              id: 'f2addcf6-3010-4bbd-b657-b913b23f45a2',
              name: 'Electrical Power Generation Voltage Test Equipment',
              tradeId: '48 71 26',
            },
          ],
          tradeId: '48 71 00',
        },
      ],
      tradeId: '48 00 00',
    },
  ],
};
export const STUDLY_ACCESS_ROLES = {
  CAD: {
    // Company Admin
    submittals: ['view', 'create', 'edit', 'delete'],
    rfis: ['view', 'create', 'edit', 'delete'],
    planRoom: ['view', 'create', 'edit', 'delete'],
    meetingMinutes: ['view', 'create', 'edit', 'delete'],
    dailyLogs: ['view', 'create', 'edit', 'delete'],
    documents: ['view', 'upload', 'edit', 'delete'],
    projectSettings: ['edit'],
  },
  PWU: {
    // Power User
    submittals: ['view', 'create', 'edit', 'delete'],
    rfis: ['view', 'create', 'edit', 'delete'],
    planRoom: ['view', 'create', 'edit', 'delete'],
    meetingMinutes: ['view', 'create', 'edit', 'delete'],
    dailyLogs: ['view', 'create'],
    documents: ['view', 'upload', 'edit', 'delete'],
    projectSettings: ['edit'],
  },
  FIU: {
    // Field User
    submittals: ['view'],
    rfis: ['view'],
    planRoom: ['view'],
    meetingMinutes: [],
    dailyLogs: ['view', 'create'],
    documents: ['view'],
    projectSettings: [],
  },
  SCO: {
    // Sub Contractor
    submittals: ['view'],
    rfis: [],
    planRoom: [],
    meetingMinutes: [],
    dailyLogs: [],
    documents: [],
    projectSettings: [],
  },
  ARC: {
    // Architect
    submittals: ['view', 'respond'],
    rfis: ['view', 'respond'],
    planRoom: [],
    meetingMinutes: [],
    dailyLogs: [],
    documents: [],
    projectSettings: [],
  },
  ENG: {
    // Engineer
    submittals: ['view', 'respond'],
    rfis: ['view', 'respond'],
    planRoom: [],
    meetingMinutes: [],
    dailyLogs: [],
    documents: [],
    projectSettings: [],
  },
  COM: {
    // Construction Manager
    submittals: ['view'],
    rfis: ['view'],
    planRoom: [],
    meetingMinutes: [],
    dailyLogs: [],
    documents: [],
    projectSettings: [],
  },
  ASC: {
    // Associate
    submittals: ['view'],
    rfis: ['view'],
    planRoom: [],
    meetingMinutes: [],
    dailyLogs: [],
    documents: [],
    projectSettings: [],
  },
};

export const USER_TYPES_STUDLY = {
  SYS: 'System',
  SUB: 'Subscriber',
};

export const SYSTEM_STAFF_ROLE_STUDLY = {
  SSA: 'System Admin',
  SMA: 'Manager',
};

export const SUBSCRIBER_USER_ROLE_STUDLY = {
  CAD: 'Company Admin',
  PWU: 'Power User',
  FIU: 'Field User',
  ARC: 'Architect',
  ENG: 'Engineer',
  SCO: 'Sub Contractor',
  COM: 'Construction Manager',
  ASC: 'Associate',
};

export const STATUS_WORKFLOW_STUDLY = {
  DRF: 'Draft',
  SUB: 'Submitted',
  REV: 'Reviewed',
  RFR: 'Reviewed for record',
  APR: 'Approved (APR)',
  MCN: 'Make Corrections Noted (MCN)',
  MCNR: 'Make Corrections and Resubmit (MCNR)',
  RJT: 'Rejected (RJT)',
  CST: 'Custom',
  SSC: 'Sent to Subcontractor',
};

export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);
export const getValueByKey = (object, key) => object[key];

export const getUserRoleKeyByValue = (value) =>
  Object.keys(SUBSCRIBER_USER_ROLE_STUDLY).find(
    (key) => SUBSCRIBER_USER_ROLE_STUDLY[key] === value
  );
export const getUserRoleValueByKey = (key) => SUBSCRIBER_USER_ROLE_STUDLY[key];

// get Key by value "Submitted"=>SUB
export const getStatusKeyByValue = (value) => getKeyByValue(STATUS_WORKFLOW_STUDLY, value);
// get Key by value "Sub Contractor"=>SCO
export const getRoleKeyByValue = (value) => getKeyByValue(SUBSCRIBER_USER_ROLE_STUDLY, value);

// get value by Key "SUB"=>"Submitted"
export const getStatusValueByKey = (value) => getValueByKey(STATUS_WORKFLOW_STUDLY, value);
// get value by Key "Sub Contractor"=>"SCO"
export const getRoleValueByKey = (value) => getValueByKey(SUBSCRIBER_USER_ROLE_STUDLY, value);

export const USER_TYPE = ['System', 'Subscriber'];

export const SYSTEM_ROLES = ['System Admin', 'Manager'];
export const SUBSCRIBER_ROLES = [
  'Company Admin',
  'Power User',
  'Field User',
  'Architect',
  'Engineer',
  'Sub Contractor',
  'Construction Manager',
  'Associate',
];

export const STATUS_WORKFLOW = [
  'Draft',
  'Submitted',
  'Reviewed',
  'Reviewed for record',
  'Approved (APR)',
  'Make Corrections Noted (MCN)',
  'Make Corrections and Resubmit (MCNR)',
  'Rejected (RJT)',
  'Custom',
  'Sent to Subcontractor',
];

export const STATUS_RFIS = ['Draft', 'Submitted', 'Reviewed'];
export const FILTER_CATEGORIES_PLANROOM = [
  'Architectural',
  'Mechanical',
  'Electrical',
  'Plumbing',
  'Structural',
];
export const FILTER_CATEGORIES_MEETINGROOM = ['Draft', 'Minutes'];
export const FILTER_CATEGORIES_MEETINGNOTES = ['Active', 'Inactive'];

export const PROJECT_TEMPLATE_OPTIONS = [
  { value: 'default', label: 'CSI Code Template', icon: 'mdi:crown-outline' },
  { value: 'template1', label: 'Template 1' },
  { value: 'template2', label: 'Template 2' },
  { value: 'template3', label: 'Template 3' },
  { value: 'create', label: 'Create New Template', icon: 'material-symbols:add-circle-outline' },
];

export const PROJECT_DEFAULT_TEMPLATE = [
  {
    name: 'Demolition',
    tradeId: uuidv4(),
    _id: uuidv4(),
  },
  {
    name: 'Construction',
    _id: uuidv4(),
    tradeId: uuidv4(),
  },
  {
    name: 'Electrical',
    _id: uuidv4(),
    tradeId: uuidv4(),
  },
  {
    name: 'Framing and Drywall',
    _id: uuidv4(),
    tradeId: uuidv4(),
  },
  {
    name: 'Flooring',
    _id: uuidv4(),
    tradeId: uuidv4(),
  },
];

export const PROJECT_TEMPLATES = [
  { name: 'default', trades: [...PROJECT_DEFAULT_TEMPLATE] },
  {
    name: 'template1',
    trades: [
      {
        name: 'Construction',
        _id: uuidv4(),
        tradeId: uuidv4(),
      },
      {
        name: 'Electrical',
        _id: uuidv4(),
        tradeId: uuidv4(),
      },
    ],
  },
  {
    name: 'template2',
    trades: [
      {
        name: 'Flooring',
        _id: uuidv4(),
        tradeId: uuidv4(),
      },
    ],
  },
  {
    name: 'template3',
    trades: [
      {
        name: 'Electrical',
        _id: uuidv4(),
        tradeId: uuidv4(),
      },
    ],
  },
];

export const PROJECTS = [
  {
    name: 'project1',
    trades: [...PROJECT_DEFAULT_TEMPLATE],
    workflow: {
      name: 'project workflow 1',
      statuses: ['Draft'],
      returnDate: addDays(new Date(), 1),
    },
    // submittals: [],
    _id: uuidv4(),
  },
  {
    name: 'project2',
    trades: [...PROJECT_DEFAULT_TEMPLATE],
    workflow: {
      name: 'project workflow 2',
      statuses: ['Draft', 'Submitted'],
      returnDate: addDays(new Date(), 5),
    },
    // submittals: [],
    _id: uuidv4(),
  },
  {
    name: 'project3',
    trades: [...PROJECT_DEFAULT_TEMPLATE],
    workflow: {
      name: 'project workflow 3',
      statuses: ['Draft', 'Submitted'],
      returnDate: addDays(new Date(), 10),
    },
    // submittals: [],
    _id: uuidv4(),
  },
];
export const PROJECT_WORKFLOWS = [
  {
    name: 'default',
    statuses: ['Draft', 'Submitted', 'reviewed'],
    returnDate: addDays(new Date(), 7),
  },
  {
    name: 'project workflow 1',
    statuses: ['Draft', 'Submitted'],
    returnDate: addDays(new Date(), 1),
  },
  {
    name: 'project workflow 2',
    statuses: ['Draft'],
    returnDate: addDays(new Date(), 5),
  },
  {
    name: 'project workflow 3',
    statuses: ['Submitted'],
    returnDate: addDays(new Date(), 10),
  },
];

export const PROJECT_STATUS_TREE = {
  name: 'Draft',
  children: [
    { name: 'Submitted' },
    {
      name: 'Reviewed',
      children: [
        { name: 'Reviewed for record' },
        { name: 'Approved (APR)' },
        { name: 'Make Corrections Noted (MCN)' },
        { name: 'Make Corrections and Resubmit (MCNR)' },
        { name: 'Rejected (RJT)' },
        { name: 'Custom' },
      ],
    },
    { name: 'Sent to Subcontractor' },
  ],
};

export const PROJECT_WORKFLOW_BOARD_DATA = {
  board: {
    columns: {
      '1-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1': {
        id: '1-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
        name: 'To Do',
        taskIds: [
          '1-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
          '2-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
          '3-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
          '4-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
          '5-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
          '6-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
          '7-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
          '8-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
          '9-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
          '10-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
        ],
      },
      '2-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2': {
        id: '2-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
        name: 'In Progress',
        taskIds: [],
      },
    },
    tasks: {
      '1-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1': {
        id: '1-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
        status: 'To Do',
        priority: 'high',
        name: 'Draft',
      },
      '2-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2': {
        id: '2-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
        status: 'To Do',
        priority: 'high',
        name: 'Submitted',
      },
      '3-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3': {
        id: '3-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
        status: 'To Do',
        priority: 'medium',
        name: 'Reviewed',
      },
      '4-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4': {
        id: '4-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
        status: 'To Do',
        priority: 'low',
        name: 'Reviewed for record',
      },
      '5-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5': {
        id: '5-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
        status: 'To Do',
        priority: 'low',
        name: 'Approved (APR)',
      },
      '6-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6': {
        id: '6-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
        status: 'To Do',
        priority: 'medium',
        name: 'Make Corrections Noted (MCN)',
      },
      '7-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7': {
        id: '7-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
        status: 'To Do',
        priority: 'medium',
        name: 'Make Corrections and Resubmit (MCNR)',
      },
      '8-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8': {
        id: '8-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
        status: 'To Do',
        priority: 'high',
        name: 'Rejected (RJT)',
      },
      '9-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9': {
        id: '9-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
        status: 'To Do',
        priority: 'low',
        name: 'Custom',
      },
      '10-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10': {
        id: '10-task-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
        status: 'To Do',
        priority: 'medium',
        name: 'Sent to Subcontractor',
      },
    },
    ordered: [
      '1-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
      '2-column-e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
    ],
  },
};

export const PROJECT_SUBCONTRACTORS = [
  { _id: uuidv4(), name: 'John' },
  { _id: uuidv4(), name: 'Adam' },
  { _id: uuidv4(), name: 'Joseph' },
];

export const PROJECT_INVITE_USER_ROLES = [
  { value: 'Power User', label: 'Power User', _id: uuidv4() },
  { value: 'Field User', label: 'Field User', _id: uuidv4() },
  { value: 'Architect', label: 'Architect', _id: uuidv4() },
  { value: 'Engineer', label: 'Engineer', _id: uuidv4() },
  { value: 'Sub Contractor', label: 'Sub Contractor', _id: uuidv4() },
  { value: 'Construction Manager', label: 'Construction Manager', _id: uuidv4() },
  { value: 'Associate', label: 'Associate', _id: uuidv4() },
];
export const PROJECT_INVITE_INTERNAL_USER_ROLES = [
  { value: 'Power User', label: 'Power User', _id: uuidv4() },
  { value: 'Field User', label: 'Field User', _id: uuidv4() },
];
export const PROJECT_INVITE_EXTERNAL_USER_ROLES = [
  { value: 'Architect', label: 'Architect', _id: uuidv4() },
  { value: 'Engineer', label: 'Engineer', _id: uuidv4() },
  { value: 'Construction Manager', label: 'Construction Manager', _id: uuidv4() },
  { value: 'Associate', label: 'Associate', _id: uuidv4() },
];

export const USER_LIST_OPTIONS = [
  {
    firstName: 'test',
    lastName: 'user',
    email: 'testuser@mailinator.com',
    id: '65f96494034a59d2de77d7c2',
  },
  {
    firstName: 'test',
    lastName: 'user1',
    email: 'testuser1@mailinator.com',
    id: '65f96493334a59d2def7d7c2',
  },
  {
    firstName: 'test',
    lastName: 'user2',
    email: 'testuser2@mailinator.com',
    id: '65f96494032229d2def7d7c2',
  },
];

export const PROJECT_INVITE_USERS_INTERNAL = [...Array(8)].map((_, index) => {
  const status = ['Joined', 'Invited'][index % 2 === 0 || index % 3 === 1];
  return {
    id: _mock.id(index),
    firstName: _mock.firstName(index),
    lastName: _mock.lastName(index),
    email: _mock.email(index),
    // role: _mock.role(index),
    // status
  };
});

export const PROJECT_INVITE_USERS_EXTERNAL = [...Array(16)].slice(7).map((_, index) => ({
  id: _mock.id(index),
  name: _mock.role(index),
  email: _mock.email(index),
  role: _mock.role(index),
}));

export const PROJECT_SHARED_PERSONS = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  email: _mock.email(index),
  avatarUrl: _mock.image.avatar(index),
  permission: index % 2 ? 'view' : 'edit',
}));
